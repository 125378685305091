import {Box, HBox} from '../../view';
import React, {LegacyRef, useCallback, useMemo, useRef} from 'react';
import {TextInput, TextInputProps, ViewStyle} from 'react-native';

import {Cross} from '../../icon';
import If from '../../../conditional/If';
import {PTextInputProps} from './type';
import {Touchable} from '../../touchable';
import {isWeb} from '@buncha/utils/common';
import {scaleFonts} from '../../../../appStyles/style/font';
import {styles} from './style';
import {useColorThemeType} from '../../../../appStyles/hooks/theme';
import {useTextProps} from './hooks';

/**
 * @example
 function renderInput() {
  const [text, setText] = useState('');
  // avoid rendering as only first time function reference created
  const setTextMemoToAvoidRender = useCallback(
    (text_: string) => setText(text_),
    [],
  );
  return (
    <TextInputGroup.PTextInput
      colorThemeType="default"
      onChangeText={setTextMemoToAvoidRender}
      leftComponent={useMemo(
        () => (
          <TouchableOpacity>
            <Text>Hi Lefddrt</Text>
          </TouchableOpacity>
        ),
        [],
      )}
      rightComponent={useMemo(
        () => (
          <Text>Addddd</Text>
        ),
        [],
      )}
    />;
    <Text>{text}</Text>
  );
}
 */
const PTextInput: React.FC<PTextInputProps> = React.forwardRef((props, ref) => {
  const propsToSpread: TextInputProps = useTextProps(props);
  const colorTheme = useColorThemeType();
  const viewStyle = props.containerStyle as ViewStyle;
  const containerStyle: ViewStyle[] = [
    styles.containerDefault,
    {
      borderColor: colorTheme?.background.gray[200],
    },
    viewStyle,
  ];

  let timeOutId: any = useRef();
  const removeErrorTheme = props?.clearErrorTheme;
  const onChangeText = useCallback(
    (value: string) => {
      if (typeof props.onChangeText === 'function') props.onChangeText(value);
    },
    [props],
  );

  const clearText = useCallback(() => {
    onChangeText('');
    if (removeErrorTheme) removeErrorTheme();
  }, [onChangeText, removeErrorTheme]);

  const clearInput = useMemo(() => {
    if (!props.clearInput || !props.textValue) return null;
    return (
      <Touchable onPress={clearText} style={styles.clearTouchable}>
        <Cross
          accessible={true}
          accessibilityLabel="Clear"
          width={scaleFonts(18)}
          height={scaleFonts(18)}
          viewBox="0 0 15 15"
          fill={props?.closeIcon || colorTheme?.text.blue[300]}
        />
      </Touchable>
    );
  }, [
    clearText,
    colorTheme?.text.blue,
    props.clearInput,
    props?.closeIcon,
    props.textValue,
  ]);

  const debounce = useCallback(
    (query: string, delay: number) => {
      if (timeOutId.current) {
        clearInterval(timeOutId.current);
        timeOutId.current = null;
      }
      timeOutId.current = setTimeout(() => {
        onChangeText(query);
      }, delay);
    },
    [onChangeText],
  );

  const setQuery = useCallback(
    (value: string) => {
      if (props.debounce) {
        const delay = props.debounceTime ?? 1000;
        return debounce(value, delay);
      }
      onChangeText(value);
    },
    [debounce, onChangeText, props.debounce, props.debounceTime],
  );
  let {leftComponent, rightComponent} = props;
  rightComponent =
    rightComponent && (!props.clearInput || !props.textValue)
      ? rightComponent
      : clearInput;

  return (
    <HBox colorThemeType={props.colorThemeType} style={containerStyle}>
      <If condition={leftComponent}>
        <Box>{leftComponent}</Box>
      </If>
      <TextInput
        {...propsToSpread}
        onChangeText={setQuery}
        ref={ref as LegacyRef<TextInput>}
        keyboardAppearance={'default'}
        clearButtonMode="never"
        style={[propsToSpread?.style, additionalWebStyle]}
      />
      <If condition={rightComponent}>
        <Box>{rightComponent}</Box>
      </If>
    </HBox>
  );
});

const additionalWebStyle = isWeb() ? styles.webInputBoxStyle : {};

export default React.memo(PTextInput);
