import {IntercomContextValues, IntercomProps} from 'react-use-intercom';
import mixpanel, {Mixpanel} from 'mixpanel-browser';

import {Config} from '../../utils/types';
import {getConfig} from '../../utils/appInfo';
import {smartLook} from './smartlook';

export interface IntercomType {
  trackEvent: (event: string, metaData?: object) => void;
  boot: (props?: IntercomProps) => void;
  update: (props?: Partial<any>) => void;
}

// eslint-disable-next-line no-shadow
export enum AnalyticsPlatform {
  'mixpanel' = 'mixpanel',
  'intercom' = 'intercom',
  'all' = 'all',
}

class AppAnalytics {
  private mixpanel?: Mixpanel;

  private intercom?: IntercomContextValues;

  constructor() {
    this.mixpanel = undefined;
    this.intercom = undefined;
  }

  setMixPanelEvent(name: string) {
    if (this.mixpanel)
      try {
        this.mixpanel.track(name);
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
  }

  setMixPanelEventWithProps(name: string, props: any) {
    if (this.mixpanel)
      try {
        this.mixpanel.track(name, props);
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
  }

  setIntercomEventWithProps(name: string, props?: any) {
    if (this.intercom)
      try {
        this.intercom.trackEvent(name, props);
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
  }

  eventWithProps(
    name: string,
    props: any,
    platform = AnalyticsPlatform.mixpanel,
  ) {
    props = {...props, module: 'shopper'};

    switch (platform) {
      case AnalyticsPlatform.intercom:
        this.setIntercomEventWithProps(name, props);
        break;
      case AnalyticsPlatform.all:
        this.setMixPanelEventWithProps(name, props);
        this.setIntercomEventWithProps(name, props);
        break;
      default:
        this.setMixPanelEventWithProps(name, props);
        break;
    }
  }

  event(name: string, _platform = AnalyticsPlatform.mixpanel) {
    this.setMixPanelEvent(name);
  }

  alias(userId: number) {
    if (this.mixpanel) this.mixpanel.alias(`${userId}`);
  }

  identify(userId: number) {
    if (this.mixpanel) this.mixpanel.identify(`${userId}`);
  }

  setIdentifiedUserAttribute(user: any) {
    const id = user.id.toString() || '';
    const name = `${user.firstName} ${user.lastName}`;
    this.setMixPanelUser(user);
    smartLook.setUserIdentity(id, {
      name,
      email: user.email,
      mostRecentAddress: '',
      isLockedOut: '',
      isDriver: '',
      id: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      avatar: '',
    });

    if (this.intercom) {
      const formattedUser = this.getUser(user);
      this.intercom.boot({
        ...formattedUser,
        verticalPadding: 60,
        hideDefaultLauncher: true,
      });
      this.intercom.update(formattedUser);
    }
  }

  setMixPanelUser(user: any) {
    if (this.mixpanel) {
      const id = `${user.id}`;
      this.mixpanel.alias(id);
      this.mixpanel.identify(id);
      this.mixpanel.people.set({
        $email: user.email,
        $first_name: user.firstName,
        $last_name: user.lastName,
        $phone: user.phoneNumber,
        'User ID': id,
        ZoneName: user.isGuestUser
          ? user.mostRecentAddress?.name
          : user.mostRecentAddress?.neighborhood?.name,
        isGuest: user.isGuestUser,
      });
    }
  }

  getUser(user: any) {
    return {
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      phone: user.phoneNumber,
      customAttributes: {
        ZoneName: user.mostRecentAddress?.neighborhood?.name,
        isLockedOut: JSON.stringify(user.isLockedOut),
        Address: user.mostRecentAddress?.name,
      },
      userId: `UserId${user.id}`,
    };
  }

  bootIntercom() {
    if (this.intercom) this.intercom.boot();
  }

  displayMessenger() {
    return this.intercom?.show();
  }

  setupForServerConfiguration(intercom?: IntercomContextValues): any {
    mixpanel.init(getConfig(Config.mixpanelKey));
    this.mixpanel = mixpanel;
    this.intercom = intercom;
  }

  logout() {
    this.intercom?.hardShutdown();
  }
}

const Analytics = new AppAnalytics();

export {Analytics};
