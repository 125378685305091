export default class TypePolicies {
  getTypePolicy() {
    return {
      CartForRun: this.cart(),
      ReferralHistory: this.rewardHistory(),
    };
  }

  rewardHistory() {
    return {
      fields: {
        getReferralHistory: {
          merge(_: any, incoming: any) {
            return incoming;
          },
        },
      },
    };
  }

  cart() {
    return {
      fields: {
        items: {
          merge(_: any, incoming: any) {
            return incoming;
          },
        },
      },
    };
  }
}
