import {Appearance, Platform} from 'react-native';
import {Config, EnvConfig} from './types';
import {getRandomNumbers, isWeb} from './common';

import {NetworkConfiguration} from '../config/graphQL/type';
import {StorageKeys} from '../config/storage/type';
import config from 'react-native-config';
import {getUniqueId} from 'react-native-device-info';
import {shouldAnalyticsLoad} from '../config/analytics/utils';
import {storage} from './../config/storage/index';

const webConfig: EnvConfig = {
  DEPLOY_TYPE: process.env.REACT_APP_DEPLOY_TYPE,
  DEV_TOOL: process.env.REACT_APP_DEV_TOOL,
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  MIXPANEL_KEY: process.env.REACT_APP_MIXPANEL_KEY,
  MIXPANEL_PROD_KEY: process.env.REACT_APP_MIXPANEL_PROD_KEY,
  SMARTLOOK_KEY: process.env.REACT_APP_SMARTLOOK_KEY,
  INTERCOM_ID: process.env.REACT_APP_INTERCOM_ID,
  INTERCOM_PROD_ID: process.env.REACT_APP_INTERCOM_PROD_ID,
  WEB_ENV: process.env.REACT_APP_WEB_ENV,
  VERSION_NAME: process.env.REACT_APP_VERSION_NAME,
  AWS_LAMBDA_MAINTENANCE_URL: process.env.REACT_APP_AWS_LAMBDA_MAINTENANCE_URL,
};

/**
 * @param networkConfig NetworkConfiguration
 * @description set NetworkConfiguration to DEPLOY_TYPE env variable
 */
export function setDeployTypeEnv(networkConfig: NetworkConfiguration) {
  if (!networkConfig) process.env.DEPLOY_TYPE = NetworkConfiguration.production;
  else process.env.DEPLOY_TYPE = networkConfig;
}

/**
 * @returns NetworkConfiguration
 */
export function getDeployType() {
  return process.env.DEPLOY_TYPE || NetworkConfiguration.production;
}

/**
 * @param configKey string
 * @returns string
 */
export function getConfig(configKey: Config) {
  if (isWeb()) return webConfig[configKey] || '';
  return config[configKey] || '';
}

/**
 *
 * @returns string
 */
export function getOs() {
  return Platform.OS.toString();
}

/**
 *
 * @returns system theme
 */
export function getAppearance() {
  return Appearance.getColorScheme() === 'dark' ? 'dark' : 'light';
}

/**
 * true - show devtools
 * false - hide devttols
 * @returns boolean
 */
export function showDevTools() {
  return getConfig(Config.devTool) === 'debug';
}

/**
 *
 * @param networkConfig NetworkConfiguration
 * @returns string
 * Url to connect
 */
export function UrlForNetworkConfiguration(
  networkConfig: NetworkConfiguration,
) {
  if (isWeb()) return getConfig(Config.webENV);
  setDeployTypeEnv(networkConfig);
  switch (networkConfig) {
    case NetworkConfiguration.developement1:
      return getConfig(Config.dev1);
    case NetworkConfiguration.developement2:
      return getConfig(Config.dev2);
    case NetworkConfiguration.developement3:
      return getConfig(Config.dev3);
    case NetworkConfiguration.qa:
      return getConfig(Config.qa);
    case NetworkConfiguration.qanew:
      return getConfig(Config.qanew);
    case NetworkConfiguration.staging:
      return getConfig(Config.stage);
    case NetworkConfiguration.preproduction:
      return getConfig(Config.preprod);
    case NetworkConfiguration.production:
      return getConfig(Config.prod);
    default:
      return getConfig(Config.prod);
  }
}

export async function getUUID(): Promise<string> {
  if (isWeb()) {
    const deviceUUid = await storage.getItem(StorageKeys.deviceUUId);
    if (deviceUUid) return Promise.resolve(String(deviceUUid));
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        // eslint-disable-next-line no-bitwise
        let r = (d + getRandomNumbers() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        // eslint-disable-next-line no-bitwise
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      },
    );
    storage.setItem(StorageKeys.deviceUUId, uuid);
    return Promise.resolve(uuid);
  }

  return await getUniqueId();
}

export function getChatKey() {
  if (shouldAnalyticsLoad('strict')) return getConfig(Config.chatKey);
  return getConfig(Config.chatTestKey);
}

// buncha-shopper://debug?serverUrl=http://runerraapi-staging
// android-app://com.buncha-shopper/buncha-shopper/debug/staging

export function getUrlForShopperForm() {
  switch (process.env.DEPLOY_TYPE) {
    case NetworkConfiguration.developement1:
      return getConfig(Config.hiringFormDev);
    case NetworkConfiguration.developement2:
      return '';
    case NetworkConfiguration.developement3:
      return '';
    case NetworkConfiguration.qa:
      return getConfig(Config.hiringFormQA);
    case NetworkConfiguration.qanew:
      return getConfig(Config.hiringFormQA);
    case NetworkConfiguration.staging:
      return getConfig(Config.hiringFormStage);
    case NetworkConfiguration.preproduction:
      return '';
    case NetworkConfiguration.production:
      return getConfig(Config.hiringFormProd);
    default:
      return getConfig(Config.hiringFormProd);
  }
}
