import {Box, HBox} from '@buncha/components/core/view';

import DetailsInput from '../DetailsInput';
import If from '@buncha/components/conditional/If';
import QuantityInput from '../quantityInput';
import React from 'react';
import {StyleSheet} from 'react-native';
import UPCUpdateSection from '../UPCUpdateSection';
import {UpdatePage} from '@buncha/itemDetails/types/ItemScanner';
import {useItemScannerContext} from '@buncha/itemDetails/hooks/ItemScanner';

const UpdateSection = (props: {isUPCMode: boolean}) => {
  const {state} = useItemScannerContext();
  const {isUPCMode} = props;
  const buyerData = state.buyerData;
  const allFields = buyerData?.[0]?.whatPageToShow === UpdatePage.carousel;

  return (
    <Box style={styles.updateBox}>
      <If condition={isUPCMode}>
        <UPCUpdateSection />
      </If>
      <If condition={!isUPCMode}>
        <HBox>
          <If condition={allFields}>
            <DetailsInput />
          </If>
          <If condition={!allFields}>
            <QuantityInput />
          </If>
        </HBox>
      </If>
    </Box>
  );
};

const styles = StyleSheet.create({
  updateBox: {
    height: '100%',
    justifyContent: 'space-between',
  },
});

export default React.memo(UpdateSection);
