import {
  CaptureItemParams,
  Sensitivity,
  UpdatePage,
} from '@buncha/itemDetails/types/ItemScanner';
import {HomeScreens, ShopperScreens} from '@buncha/home/navigation/types';
import {
  ItemScannerAction,
  useItemScannerContext,
  useUPCOrder,
  useUpdateItemDetails,
} from '../../../../itemDetails/hooks/ItemScanner';
import React, {useCallback, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '@buncha/config/redux/hooks';

import {Analytics} from '@buncha/config/analytics/analytics';
import AppModal from '@buncha/common/components/CustomModal/AppModal';
import {BottomButton} from '../../../../components/composites/Button';
import {Box} from '../../../../components/core/view';
import {EventType} from '@buncha/config/analytics/type';
import {H3Text} from '@buncha/components/core/text';
import If from '../../../../components/conditional/If';
import {ItemScannerGlobalActions} from '@buncha/itemDetails/reducer';
import {StyleSheet} from 'react-native';
import font from '@buncha/appStyles/style/font';
import {navigation} from '@buncha/config/navigation';
import {useColorThemeType} from '../../../../appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';

const ScanAgainButton = (props: {isUPCMode: boolean}) => {
  const {state, dispatch} = useItemScannerContext();
  const theme = useColorThemeType();
  const {updateItemDetails, updateLoading} = useUpdateItemDetails();
  const route = useRoute();
  const routeParams: any = route?.params;
  const runId = routeParams?.runId;
  const returnPage = routeParams?.returnPage;
  const {markItemAsScanned, markingItem} = useUPCOrder(dispatch);
  const {itemLocationData, upcOrderItem, selectedCatalogStoreForUPCRun} =
    useAppSelector(gState => gState.itemScanner);
  const appDispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const showButton =
    !state.scannerActive ||
    (props?.isUPCMode && state?.upcCrowdSourceProcessCompleted);
  const scannerExpanded = state.scannerExpanded;
  const remainingCustomers = state.remainingCustomers;

  const item = state.item;

  const buyerData = state.buyerData;
  const allFields = buyerData?.[0]?.whatPageToShow === UpdatePage.carousel;

  const startScanner = useCallback(() => {
    if (dispatch) dispatch(ItemScannerAction.setScannerActive(true));
  }, [dispatch]);

  const buttonDisabled = useMemo(() => {
    if (scannerExpanded) return false;
    if (remainingCustomers) return true;
    return false;
  }, [remainingCustomers, scannerExpanded]);

  const navigateToRun = useCallback(() => {
    if (returnPage === ShopperScreens.OrderPackingPage)
      return navigation.navigate(HomeScreens.Shopper, {
        screen: ShopperScreens.OrderPackingPage,
        params: {runId},
      });
    navigation.navigate(HomeScreens.Shopper, {
      screen: ShopperScreens.BatchDetailsPage,
      params: {runId},
    });
  }, [returnPage, runId]);

  const handlAnalytics = useCallback(() => {
    if (!item || !state.buyerData) return;
    const sensitivity = state.buyerData?.[0]?.sensitivity;
    const sensitivityText =
      sensitivity &&
      [
        Sensitivity.priceAndWeightSensitive,
        Sensitivity.priceSensitive,
        Sensitivity.weightSensitive,
      ].includes(sensitivity)
        ? 'Weight sensitive'
        : 'Quantity sensitive';
    if (item?.isCustomItem)
      return Analytics.eventWithProps('Update Price', {
        type: EventType.cta,
        runId: runId,
        orderId: item?.order?.id,
        orderItemId: routeParams?.orderItemId,
        orderItemName: item?.name,
        storeId: item?.order?.run?.store.id,
        pageType: 'Custom Item',
      });
    return Analytics.eventWithProps('Update Price', {
      type: EventType.cta,
      runId: runId,
      orderId: item?.order?.id,
      orderItemId: routeParams?.orderItemId,
      orderItemName: item?.name,
      storeId: item?.order?.run?.store.id,
      pageType: sensitivityText,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, state.buyerData]);

  const params = useMemo(() => {
    return state.formData
      .filter(data => !data.dropped)
      .map(data => {
        return {
          orderItemId: data.orderItemId,
          quantity: data.quantity,
          price: Number((Number(data?.totalPrice) * 100).toFixed(0)),
          weightInfo: data.weightInfo?.filter(weight => weight.weight),
        };
      });
  }, [state.formData]);

  const navigateToBatchPage = useCallback(() => {
    navigation.navigate(HomeScreens.Shopper, {
      screen: ShopperScreens.BatchDetailsPage,
      params: {
        isUPCMode: true,
        upcOrderId: selectedCatalogStoreForUPCRun?.id,
        runId: 0,
      },
    });
    if (appDispatch) appDispatch(ItemScannerGlobalActions.clearItemState());
  }, [appDispatch, selectedCatalogStoreForUPCRun?.id]);

  const modalConfirmation = useCallback(() => {
    const markingData: Omit<CaptureItemParams, 'isUpcOrder'> = {
      orderItemId: upcOrderItem?.id ?? 0,
      aisle: itemLocationData?.locationDescription?.aisle ?? '',
      storeContext: itemLocationData?.locationDescription?.context ?? '',
      locationImage: itemLocationData?.imageUrl,
      price: Math.floor(itemLocationData?.price ?? 0),
      esIndex: selectedCatalogStoreForUPCRun?.esIndex ?? '',
    };
    markItemAsScanned(markingData, navigateToBatchPage);
  }, [
    itemLocationData?.imageUrl,
    itemLocationData?.locationDescription?.aisle,
    itemLocationData?.locationDescription?.context,
    itemLocationData?.price,
    markItemAsScanned,
    navigateToBatchPage,
    selectedCatalogStoreForUPCRun?.esIndex,
    upcOrderItem?.id,
  ]);

  const handleButtonPress = useCallback(async () => {
    if (props?.isUPCMode) {
      toggleModal();
    } else {
      if (scannerExpanded) return startScanner();
      const res = await updateItemDetails(params);
      handlAnalytics();
      if (res) navigateToRun();
    }
  }, [
    handlAnalytics,
    navigateToRun,
    params,
    props?.isUPCMode,
    scannerExpanded,
    startScanner,
    toggleModal,
    updateItemDetails,
  ]);

  const getButtonText = useMemo(() => {
    if (props.isUPCMode) return 'Continue';
    if (scannerExpanded) return 'Retry Scanning';
    if (!params?.length) return 'Proceed';
    if (!allFields) return 'Update Quantity';
    return 'Update Price and Quantity';
  }, [allFields, params?.length, props.isUPCMode, scannerExpanded]);

  const getBody = useMemo(() => {
    return (
      <H3Text style={[styles.childTypo]}>
        Are you sure you want to update UPC data for this item ?
      </H3Text>
    );
  }, []);

  return (
    <React.Fragment>
      <If condition={showButton}>
        <Box style={styles.floatingBox}>
          <BottomButton
            disabled={
              buttonDisabled ||
              updateLoading ||
              markingItem ||
              state.scanAgainButtonLoading
            }
            loadingText="Updating..."
            isLoading={updateLoading || markingItem}
            disabledBgColor={theme?.text.green[200]}
            showDividers={true}
            onPress={handleButtonPress}
            title={getButtonText}
            style={styles.buttonStyle}
          />
        </Box>
        <AppModal
          isOpen={open}
          onClose={toggleModal}
          topCTAHandelr={modalConfirmation}
          bottomCTAHandelr={toggleModal}
          title={'Are you sure ?'}
          modalBody={getBody}
          ctaBottomTitle={'Cancel'}
          ctaTopTitle={'Yes, Confirm'}
          topCTAColor={theme?.background.green[100]}
          loading={updateLoading || markingItem}
        />
      </If>
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  floatingBox: {
    // position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  jCC: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonStyle: {
    marginBottom: 10,
  },
  rowFlex: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  headerTypo: {
    fontFamily: font.fonts['Mortise-X-Bold'],
    ...font.FontSize.fontH2,
  },
  childContainer: {
    marginTop: 10,
  },
  childTypo: {
    ...font.FontSize.fontH6,
  },
});

export default React.memo(ScanAgainButton);
