import {DefaultContext} from '../../utils/types';
import React from 'react';
import {State} from '../types/ItemScanner';

export const initialState: State = {
  item: undefined,
  upcImagesModal: false,
  scannerActive: false,
  upcImages: [],
  scannerExpanded: true,
  buyerData: [],
  formData: [],
  remainingCustomers: 0,
  upcOrderItem: undefined,
  upcScannedImages: [],
  upcCrowdSourceProcessCompleted: false,
  scanAgainButtonLoading: false,
};

const defaultVal: DefaultContext<State> = {
  state: initialState,
  dispatch: null,
};

export const ItemScannerContext = React.createContext(defaultVal);
