import {StyleSheet, Text} from 'react-native';

import CustomText from './CustomText';
import {CustomTextProps} from './type';
import React from 'react';
import font from '../../../appStyles/style/font';
import {useFontTheme} from '../../../appStyles/hooks/theme';

const H3Text: React.FC<CustomTextProps<Text>> = React.forwardRef(
  (props, ref) => {
    const fontTheme = useFontTheme(props.fontTheme || 'muli');
    const propsToSpread = {
      ...props,
      style: [styles.H3Text, {fontFamily: fontTheme[700].normal}, props.style],
    };
    return (
      <CustomText ref={ref} {...propsToSpread}>
        {props.children}
      </CustomText>
    );
  },
);

const styles = StyleSheet.create({
  H3Text: {
    ...font.FontSize.fontH3,
  },
});

export default React.memo(H3Text);
