/* eslint-disable no-shadow */
import {ColorThemeType, FontThemeType} from '../../appStyles/style/type';

import {HiringApplicationState} from '@buncha/common/types/common';
import {ReactNode} from 'react';
import {ToastType} from '../../components/composites/notification/type';
import {TutorialsMode} from '@buncha/home/types';
import {WhenIWorkData} from '@buncha/config/graphQL/type';

export enum MapChoice {
  'appleMaps' = 'appleMaps',
  'googleMaps' = 'googleMaps',
  'none' = 'none',
}

export enum UserTypes {
  user = 'user',
  runOnwer = 'runOnwer',
  dispatcher = 'dispatcher',
  shopper = 'shopper',
  driver = 'driver',
  hiring = 'hiring',
}

export interface UserAuthData {
  smsCode?: string;
  phoneNumber?: string;
  loginToken?: string;
  createUserToken?: string;
  otpSent?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  wToken?: string;
}

export interface ChatUser {
  id: string;
  name: string;
  email: [string];
  phone: [string];
  photoUrl: string;
  role: string;
  welcomeMessage: string;
}
export interface UserStub {
  id?: number;
  firstName: string;
  lastName: string;
  avatar: string;
  email: string;
  phoneNumber: string;
  userTypes: string[];
  isActive: boolean;
  WhenIWorkInfo: WhenIWorkData;
  mapChoice: MapChoice;
  completedOrderCount: number;
  fullfillmentHiringApplicationState: {
    id: number;
    status: HiringApplicationState;
  };
  chatUser: ChatUser;
  tutorials: Tutorials[];
}

export interface Tutorials {
  id: number;
  name: TutorialsMode;
}

export interface OnBoardingState {
  currentUser?: UserStub;
  userAuthData?: UserAuthData;
  notificationToastInfo?: NotificationToastInfo;
  constants?: Constants;
  unreadMemoCount: number;
  unreadMemoModal: boolean;
  downTime: boolean;
}

export interface Constants {
  ITEM_UNITS: any;
  MAX_WEIGHT_FIELDS: any;
  SKIP_UPC_FLOW: string;
  ENABLED_ALL_DRIVER_TAB: string;
}

export interface NotificationToastInfo {
  show: boolean;
  details: NotificationToastDetails;
}

export interface NotificationToastDetails {
  title: string;
  duration?: number;
  colorThemeType?: ColorThemeType;
  fontThemeType?: FontThemeType;
  style?: any;
  containerStyle?: any;
  orientation?: string;
  type?: ToastType;
  /**
   * Leftside Icon to display
   */
  leftIcon?: ReactNode;
  /**
   * Rightside Icon to display
   */
  rightIcon?: ReactNode;
}

export interface RegisterShopperParams {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  createUserToken: string;
  userTypes: UserTypes[];
}
