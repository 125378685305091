import {
  BatchInfoFragment,
  Buyer,
  DeliveryLocationForDeliveryDetails,
  DeliveryLocationForOrderDetails,
  DeliveryNote,
  DriverItem,
  FullUser,
  Memo,
  MostRecentAddress,
  Order,
  OrderItems,
  Participant,
  PastOrderData,
  PastOrders,
  RunDetail,
  RunForDriver,
  SeaSpiderRun,
  SearchRelevance,
  ShiftsFragment,
  ShiftsListFragment,
} from './fragment';

import {gql} from '@apollo/client';

export const CURRENT_USER = gql`
  query CurrentUserQuery {
    currentUser {
      ...FullUser
    }
  }
  ${FullUser}
`;

export const GET_ASSIGN_SHIFT = gql`
  query getAssignShift {
    getAssignShift {
      active {
        ...ShiftsListFragment
      }
      pending {
        ...ShiftsListFragment
      }
    }
  }
  ${ShiftsListFragment}
`;

export const GET_ACTIVE_ASSIGN_SHIFT = gql`
  query getAssignShift {
    getAssignShift {
      active {
        ...ShiftsListFragment
      }
    }
  }
  ${ShiftsListFragment}
`;

export const GET_NEXT_ASSIGN_SHIFT = gql`
  query getAssignShift {
    getAssignShift {
      upcoming {
        ...ShiftsListFragment
      }
    }
  }
  ${ShiftsListFragment}
`;

export const GET_BATCH_COUNT = gql`
  query getBatchCount($startDate: String, $shiftByStore: [ShiftByStoreInput]) {
    getBatchCount(startDate: $startDate, shiftByStore: $shiftByStore) {
      shiftIds
      storeMasterId
      batches
    }
  }
`;

export const GET_RECEIPTS_FOR_RUN = gql`
  query getRun($runId: Int!) {
    getRun(runId: $runId) {
      id
      receiptImageUrls
    }
  }
`;

export const GET_CATALOG_ITEM = gql`
  query getCatalogItemForShopper(
    $orderItemId: Int
    $isUpcOrder: Boolean = false
  ) {
    getCatalogItemForShopper(
      orderItemId: $orderItemId
      isUpcOrder: $isUpcOrder
    ) {
      name
      id
      itemQuantity
      price
      quantity
      options
      itemImageUrl
      totalupdatedQuantityByShopper
      options
      itemImageUrl
      itemImageUrls {
        largeImages
      }
      category
      cartItem {
        isCustomItem
      }
    }
  }
`;

export const GET_CATALOG_ITEM_TO_FETCH_UPDATED_QUANTITY = gql`
  query getCatalogItemForShopper($orderItemId: Int) {
    getCatalogItemForShopper(orderItemId: $orderItemId) {
      id
      quantity
      totalupdatedQuantityByShopper
    }
  }
`;

export const GET_BUYERS_FOR_ITEM = gql`
  query itemWithBuyersAndSub($orderItemId: Int) {
    itemWithBuyersAndSub(orderItemId: $orderItemId) {
      id
      firstName
      lastName
      phoneNumber
      orderItemId
      orderId
      quantity
      substitutedBy
      hasSubstitutionRequest
      substitutionChoice
      updatedPricePerQuantity
      updatedQuantity
      order {
        buyerChatInfo {
          isVisible
          conversationId
          expiresIn
        }
        buyer {
          isVip
        }
      }
      itemNote
      substitutionNote
      substitutionOptions {
        id
        name
      }
    }
  }
`;

export const GET_CONSTANTS = gql`
  query getConstants {
    getConstants
  }
`;

export const GET_VERSION_CHECK_QUERY = gql`
  query versionCheck {
    versionCheck {
      data {
        header
        subHeader
      }
      showBottomSheet
    }
  }
`;

export const GET_ABOUT_BATCHES = gql`
  query getBatches($startDate: String) {
    getBatches(startDate: $startDate) {
      startedShift {
        ...BatchInfoFragment
      }
      notStartedShift {
        ...BatchInfoFragment
      }
      inspectVan
    }
  }
  ${BatchInfoFragment}
`;

export const GET_AVAIALBLE_SHIFTS = gql`
  query getAvailableShifts {
    getAvailableShifts {
      days {
        key
        shifts {
          ...ShiftsFragment
        }
      }
    }
  }
  ${ShiftsFragment}
`;

export const GET_ORDERS_FOR_ORDER_ASSIGNEE = gql`
  query getOrdersForOrderAssignee(
    $runId: Int!
    $sortBy: itemsSortBy
    $option: itemsForSection
  ) {
    getOrdersForOrderAssignee(runId: $runId, sortBy: $sortBy, option: $option) {
      groupedKey
      orderItems {
        ...OrderItems
      }
      keyTotalEntries
      metaData {
        completedOrderCount
        orderId
        buyer {
          id
          isVip
          phoneNumber
          firstName
          lastName
        }
        order {
          ...Order
        }
      }
    }
  }
  ${OrderItems}
  ${Order}
`;

export const GET_ORDERS_FOR_ORDER_ASSIGNEE_COUNT = gql`
  query getOrdersForOrderAssignee(
    $runId: Int!
    $sortBy: itemsSortBy
    $option: itemsForSection
  ) {
    getOrdersForOrderAssignee(runId: $runId, sortBy: $sortBy, option: $option) {
      keyTotalEntries
    }
  }
`;

export const GET_BATCH_DETAIL_RUN = gql`
  query getRun($runId: Int!) {
    getRun(runId: $runId) {
      ...RunDetail
    }
  }
  ${RunDetail}
`;

export const SHOPPING_TIME = gql`
  query shoppingTime($runId: Int) {
    shoppingTime(runId: $runId) {
      goal
      remainingTime
    }
  }
`;

export const GET_ORDER_ITEM_BY_ID_SCAN = gql`
  query getOrderItemById($orderItemId: Int) {
    getOrderItemById(orderItemId: $orderItemId) {
      name
      price
      isPriceUpdatable
      itemImageUrl
      upcImages
      catalogItemId
      isCustomItem
      order {
        id
        run {
          store {
            id
          }
        }
      }
      upcStatus
      itemUnit
      shopperItemChangesForShopper {
        updatedQuantity
        weightInfo {
          id
          unit
          weight
        }
        updatedPricePerQuantity
      }
    }
  }
`;

export const GENERATE_DYNAMIC_TEXTS = gql`
  query generateDynamicTexts(
    $orderItemId: Int
    $customMessageKey: String
    $orderId: Int
    $runId: Int
  ) {
    generateDynamicTexts(
      orderItemId: $orderItemId
      customMessageKey: $customMessageKey
      orderId: $orderId
      runId: $runId
    )
  }
`;

export const GET_ORDER_ITEM_BY_ID = gql`
  query getOrderItemById($orderItemId: Int) {
    getOrderItemById(orderItemId: $orderItemId) {
      id
      name
      quantity
      itemImageUrl
      substitutionNote
      catalogItemId
      tags
      price
      substitutionChoice
      shopperItemChangesForShopper {
        updatedQuantity
        weightInfo {
          id
          unit
          weight
        }
        updatedPricePerQuantity
      }
      substitutionOptions {
        name
        price
        itemImageUrl
        id
        isCustomItem
        catalogItemId
        orderItem {
          position
          substitutedBy
          orderId
        }
      }
      order {
        id
        run {
          id
          store {
            id
            elasticSearchIndex
          }
        }
      }
    }
  }
`;

export const GET_RECOMMENDATIONS_FOR_SUBSTITUTION = gql`
  query recommendItemsForSubstitutes(
    $storeId: Int
    $searchQuery: String
    $category: String
    $subCategory: String
    $catalogItemId: Int
  ) {
    recommendItemsForSubstitutes(
      storeId: $storeId
      searchQuery: $searchQuery
      category: $category
      subCategory: $subCategory
      catalogItemId: $catalogItemId
    ) {
      items {
        name
        id
        price
        quantity
        itemImageUrl
        indexPosition
        itemSource
        flags {
          weightSensitive
          priceSensitive
        }
      }
    }
  }
`;

export const GET_ORDER_ITEM_BY_ID_SUBSTITUTE = gql`
  query getOrderItemById($orderItemId: Int) {
    getOrderItemById(orderItemId: $orderItemId) {
      ...OrderItems
    }
  }
  ${OrderItems}
`;

export const GET_ITEM_STATUS_COUNT = gql`
  query getItemStatusCount($runId: Int!, $itemStatuses: [OrderItemStatus]) {
    getItemStatusCount(runId: $runId, itemStatuses: $itemStatuses)
  }
`;

export const ITEM_CHECK_BEFORE_PACKING = gql`
  query itemCheckBeforePacking(
    $orderId: Int
    $getForAllAssignedOrders: Boolean
    $runId: Int
  ) {
    itemCheckBeforePacking(
      orderId: $orderId
      getForAllAssignedOrders: $getForAllAssignedOrders
      runId: $runId
    ) {
      recentOrderItem {
        ...OrderItems
      }
      substituteDroppedByShopper {
        ...OrderItems
      }
      substitutePickedByShopper {
        ...OrderItems
      }
    }
  }
  ${OrderItems}
`;

export const GET_STRIP_OAUTH_SIGN_IN_URL = gql`
  query getConfig($version: String) {
    getConfig(version: $version) {
      stripeOauthSignInUrl
    }
  }
`;

export const SEARCH_CATALOG = gql`
  query searchCatalog(
    $storeId: Int
    $searchQuery: String
    $from: Int
    $size: Int
    $category: String
    $runId: Int
    $catalogItemId: Int
  ) {
    searchCatalog(
      storeId: $storeId
      searchQuery: $searchQuery
      from: $from
      size: $size
      category: $category
      runId: $runId
      catalogItemId: $catalogItemId
    ) {
      totalResult
      items {
        id
        name
        price
        tags
        description
        itemImageUrl
        itemType
        maxQuantity
        indexPosition
        itemSource
        itemQuantity
        tags
        flags {
          weightSensitive
          priceSensitive
        }
      }
      searchRelevance {
        ...SearchRelevance
      }
    }
  }
  ${SearchRelevance}
`;

export const GET_ORDERS_FOR_DELIVERY = gql`
  query getOrdersForDelivery(
    $lon: Float
    $lat: Float
    $runId: Int
    $deliveryStatuses: [DeliveryLocationStatus]
  ) {
    getOrdersForDelivery(
      lon: $lon
      lat: $lat
      runId: $runId
      deliveryStatuses: $deliveryStatuses
    ) {
      id
      buyer {
        ...Buyer
      }
      run {
        id
        lat
        lon
      }
      orderAssignee {
        assignee {
          id
          firstName
          lastName
        }
      }
      deliveryLocation {
        id
        deliveryStatus
        userAddress {
          ...MostRecentAddress
        }
        deliveryNote {
          ...DeliveryNote
        }
      }
    }
  }
  ${Buyer}
  ${DeliveryNote}
  ${MostRecentAddress}
`;

export const GET_ORDER_FOR_ORDER_DETAILS = gql`
  query getOrder($orderId: String, $runId: Int) {
    getOrder(orderId: $orderId, runId: $runId) {
      id
      buyer {
        ...Buyer
      }
      deliveryInstruction
      deliveryLocation {
        ...DeliveryLocationForOrderDetails
      }
      itemsForDriver {
        ...DriverItem
      }
      run {
        ...RunForDriver
      }
    }
  }
  ${Buyer}
  ${DeliveryLocationForOrderDetails}
  ${DriverItem}
  ${RunForDriver}
`;

export const GET_ORDER_FOR_DELIVERY_DETAILS = gql`
  query getOrder($orderId: String, $runId: Int) {
    getOrder(orderId: $orderId, runId: $runId) {
      id
      buyer {
        ...Buyer
      }
      deliveryInstruction
      deliveryLocation {
        ...DeliveryLocationForDeliveryDetails
      }
      orderHasAlcoholicItems
      run {
        isExternalRun
      }
    }
  }
  ${Buyer}
  ${DeliveryLocationForDeliveryDetails}
`;

export const GET_RUN_FOR_ITEM_DETAIL = gql`
  query getRun($runId: Int!) {
    getRun(runId: $runId) {
      id
      store {
        id
        elasticSearchIndex
      }
    }
  }
`;

export const GET_PDF_RECEIPT = gql`
  query getPdfReceipt($runId: Int!) {
    getPdfReceipt(runId: $runId)
  }
`;

export const GET_BUYER_INFO = gql`
  query getOrder($orderId: String, $runId: Int) {
    getOrder(orderId: $orderId, runId: $runId) {
      buyer {
        firstName
        phoneNumber
      }
      buyerChatInfo {
        conversationId
        isVisible
      }
    }
  }
`;

export const GET_RUNS_AND_ORDERS_FOR_SHOPPER = gql`
  query getRunsAndOrdersForShopper($daysCount: Int) {
    getRunsAndOrdersForShopper(daysCount: $daysCount) {
      ...PastOrders
    }
  }
  ${PastOrders}
`;

export const GET_PAST_ORDERS_BY_RUNID = gql`
  query getRunsAndOrdersForShopper($runId: Int) {
    getRunsAndOrdersForShopper(runId: $runId) {
      ...PastOrderData
    }
  }
  ${PastOrderData}
`;

export const GET_BUYERS_ON_MAP = gql`
  query getOrdersForDelivery($lon: Float, $lat: Float, $runId: Int) {
    getOrdersForDelivery(lon: $lon, lat: $lat, runId: $runId) {
      id
      buyer {
        ...Buyer
      }
    }
  }
  ${Buyer}
`;

export const GET_BUYER_CHAT_INFO = gql`
  query CurrentUserQuery {
    currentUser {
      id
      phoneNumber
      chatUser {
        id
        name
        email
        phone
        photoUrl
        role
      }
    }
  }
`;

export const GET_CHAT_DETAILS = gql`
  query getRun($runId: Int!) {
    getRun(runId: $runId) {
      id
      conversationFilterForRunner
      ordersForShopper {
        id
        buyer {
          phoneNumber
        }
      }
    }
  }
`;

export const GET_FULFILLMENT_FORM = gql`
  query getFullfillmentForm($name: FormName) {
    getFullfillmentForm(name: $name) {
      id
      name
      data {
        totalStep
        step {
          name
          questions {
            id
            name
            text
            type
            step
            formId
            props
            options {
              id
              name
              value
              questionId
            }
          }
        }
      }
    }
  }
`;

export const SET_APPLICATION_STATUS = gql`
  mutation setApplicationStatus(
    $application: [SetApplicationStatusInput]
    $status: HiringApplicationState
  ) {
    setApplicationStatus(application: $application, status: $status) {
      application {
        id
        status
      }
    }
  }
`;

export const GET_BUYER_DETAILS = gql`
  query itemWithBuyersAndSub($orderItemId: Int) {
    itemWithBuyersAndSub(orderItemId: $orderItemId) {
      id
      firstName
      lastName
      orderItemId
      quantity
      itemStatus
      sensitivity
      updatedQuantity
      isItemPickedOnScan
      whatPageToShow
      whatToShow {
        fieldName
      }
      weightInfo {
        id
        unit
        weight
      }
      mandatoryFields {
        fieldName
      }
      order {
        deliveryLocation {
          deliveryStatus
        }
      }
    }
  }
`;

export const GET_PAGE_DATA = gql`
  query getPageData($pageName: PageType) {
    getPageData(pageName: $pageName)
  }
`;

// UPC Shift APIs

export const GET_STORES = gql`
  query getCatalogStores {
    getCatalogStores
  }
`;

export const GET_UPC_ORDER = gql`
  query getUpcOrders {
    getUpcOrders {
      id
      storeName
      storeLogo
      esIndex
      shopperId
      orderStatus
    }
  }
`;

export const GET_BAG_COUNT_LEFT = gql`
  query getBagCountLeft {
    getBagCountLeft {
      bagsCount
      text
    }
  }
`;

export const GET_ACCOUNT_SETUP_INFO = gql`
  query getAccountSetupInfo {
    getAccountSetupInfo {
      pageDetails
    }
  }
`;

export const CHECK_SUBMIT_BAG_COUNT = gql`
  query checkSubmittedBagCount($runId: Int) {
    checkSubmittedBagCount(runId: $runId) {
      id
      bagsCount
    }
  }
`;

export const GET_UPC_ORDER_ITEMS = gql`
  query getUpcOrder($upcOrderId: Int!, $option: itemsForSection) {
    getUpcOrder(upcOrderId: $upcOrderId, option: $option) {
      upcOrder {
        id
        storeName
        storeLogo
        esIndex
        shopperId
        orderStatus
      }
      orderItems {
        id
        upcOrderId
        name
        price
        quantity
        itemImageUrl
        tags
        itemStatus
        catalogItemId
        itemUnit
        upcStatus
      }
    }
  }
`;

export const GET_UPC_ORDER_ITEMS_COUNT = gql`
  query getUpcOrder($upcOrderId: Int!, $option: itemsForSection) {
    getUpcOrder(upcOrderId: $upcOrderId, option: $option) {
      orderItems {
        id
      }
    }
  }
`;

export const GET_RECOMMENDATIONS_FOR_SUBSTITUTION_FOR_UPC_SHIFTS = gql`
  query getRecommendItemsForSubstitutesInUpc($upcOrderItemId: Int) {
    getRecommendItemsForSubstitutesInUpc(upcOrderItemId: $upcOrderItemId) {
      items {
        name
        id
        price
        quantity
        itemImageUrl
        indexPosition
        itemSource
        flags {
          weightSensitive
          priceSensitive
        }
      }
    }
  }
`;

export const GET_LOCATION_INFO_HISTORY = gql`
  query getAllItemInformation(
    $orderItemId: Int!
    $isUpcOrder: Boolean = false
    $esIndex: String!
  ) {
    getAllItemInformation(
      orderItemId: $orderItemId
      isUpcOrder: $isUpcOrder
      esIndex: $esIndex
    ) {
      at
      esIndex
      user {
        firstName
        lastName
        id
        phoneNumber
      }
      itemInfo {
        aisle
        catalogItemId
        esIndex
        foundHere
        id
        itemForReplacement
        locationImage
        orderItemId
        price
        reportMissing
        storeContext
        upcOrderItemId
      }
    }
  }
`;

export const CHECK_FOR_FROZEN_ITEMS = gql`
  query checkForFrozenItems($runId: Int) {
    checkForFrozenItems(runId: $runId)
  }
`;

export const GET_MINIMUM_DELIVERY_STATUS = gql`
  query getMinimumDeliveryStatus($runId: Int, $userType: UserType) {
    getMinimumDeliveryStatus(runId: $runId, userType: $userType)
  }
`;

export const GET_MEMOS = gql`
  query getMemos($size: Int, $skip: Int) {
    getMemos(size: $size, skip: $skip) {
      status
      totalLength
      memo {
        ...Memo
      }
    }
  }
  ${Memo}
`;

export const GET_UNREAD_MEMO_COUNT = gql`
  query getMemos($size: Int, $skip: Int) {
    getMemos(size: $size, skip: $skip) {
      status
      totalLength
    }
  }
`;

export const GET_MEMO = gql`
  query getMemo($memoId: Int!) {
    getMemo(memoId: $memoId) {
      sendTo
      readStatus
      memo {
        ...Memo
      }
    }
  }
  ${Memo}
`;

export const SEARCH_CATALOG_FOR_UPC = gql`
  query SearchCatalogForUPC(
    $esIndex: String
    $searchQuery: String
    $from: Int
    $size: Int
    $catalogItemId: Int
  ) {
    searchCatalogForUPC(
      esIndex: $esIndex
      searchQuery: $searchQuery
      from: $from
      size: $size
      catalogItemId: $catalogItemId
    ) {
      totalResult
      items {
        id
        name
        price
        tags
        description
        itemImageUrl
        itemType
        maxQuantity
        indexPosition
        itemSource
        itemQuantity
        tags
        flags {
          weightSensitive
          priceSensitive
        }
      }
    }
  }
`;

export const FETCH_MARSKED_NUMBER = gql`
  query fetchMaskedNumber(
    $orderId: Int
    $runId: Int
    $buyerId: Int
    $assigneeId: Int
  ) {
    fetchMaskedNumber(
      orderId: $orderId
      runId: $runId
      buyerId: $buyerId
      assigneeId: $assigneeId
    )
  }
`;

export const GET_UPC_IDS_FOR_CATEGORIES = gql`
  query getUpcIdsForCatalogItem($storeIndex: String, $catalogItemId: Int) {
    getUpcIdsForCatalogItem(
      storeIndex: $storeIndex
      catalogItemId: $catalogItemId
    )
  }
`;

export const GET_RUN_FOR_SEA_SPIDER = gql`
  query getRunForSeaSpider($runId: Int!) {
    getRun(runId: $runId) {
      ...SeaSpiderRun
    }
  }
  ${SeaSpiderRun}
`;

export const ADD_BAGS_USED_FOR_ORDER = gql`
  mutation addBagsUsedForOrder($orderId: Int!, $bagsUsed: Int!) {
    addBagsUsedForOrder(orderId: $orderId, bagsUsed: $bagsUsed) {
      id
      bagsUsed
    }
  }
`;

export const GET_GROUPED_ORDERS = gql`
  query GetGroupedOrders($runId: Int!) {
    getGroupedOrders(runId: $runId) {
      buyer {
        id
        mostRecentAddress {
          address
          id
        }
        firstName
        lastName
      }
      orders {
        id
        bagsUsed
        deliveryLocation {
          id
          deliveryStatus
        }
        orderAssignee {
          assignee {
            shortName
          }
        }
        run {
          id
          store {
            id
            storeLogoUrl
          }
        }
      }
    }
  }
`;

export const GET_CONVERSATION_PARTICIPANTS = gql`
  query getConversationParticipants(
    $conversationId: String
    $filter: ConversationParticipantFilter
  ) {
    getConversationParticipants(
      conversationId: $conversationId
      filter: $filter
    ) {
      data {
        participant {
          ...Participant
        }
      }
    }
  }
  ${Participant}
`;

export const GET_CONVERSATION_BY_ID = gql`
  query getConversationById($conversationId: String) {
    getConversationById(conversationId: $conversationId) {
      data {
        conversation {
          id
          subject
          custom
          photoUrl
        }
      }
    }
  }
`;

export const GET_ORDER_BUYER_INFO = gql`
  query getOrder($orderId: String, $runId: Int) {
    getOrder(orderId: $orderId, runId: $runId) {
      buyer {
        firstName
      }
    }
  }
`;

export const GET_CHAT_FILTER = gql`
  query getChatFilter($name: ChatFilterName, $payload: ChatFilterPayload) {
    getChatFilter(name: $name, payload: $payload) {
      data
    }
  }
`;
