import {BatchDetailContext, initialState} from '../context/BatchDetailContext';
import {
  BatchDetailContextState,
  ContactBottomSheetProps,
  DeliveryLocationStatus,
  GroupedAssignedItems,
  ReceiptBypassMode,
  UPCOrderData,
  itemsSortBy,
} from '../types';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {useContext, useReducer} from 'react';

const reducer = {
  setSortBarValue: function (
    state: BatchDetailContextState,
    action: PayloadAction<itemsSortBy>,
  ) {
    state.sortBarValue = action.payload;
  },
  setShowSortBarValue: function (
    state: BatchDetailContextState,
    action: PayloadAction<boolean>,
  ) {
    state.showSortBar = action.payload;
  },
  setGroupedAssignedItemsTodo: function (
    state: BatchDetailContextState,
    action: PayloadAction<GroupedAssignedItems[]>,
  ) {
    state.groupedAssignedItems.todo = action.payload;
    state.tabDataCount.todoCount = action.payload[0]?.keyTotalEntries;
    state.globalLoading = false;
    if (!action.payload?.length) state.disableToDo = true;
    else state.disableToDo = false;
  },
  setGroupedAssignedItemsInReview: function (
    state: BatchDetailContextState,
    action: PayloadAction<GroupedAssignedItems[]>,
  ) {
    state.groupedAssignedItems.inReview = action.payload;
    state.tabDataCount.inReviewCount = action.payload[0]?.keyTotalEntries;
    state.globalLoading = false;
  },
  setGroupedAssignedItemsDone: function (
    state: BatchDetailContextState,
    action: PayloadAction<GroupedAssignedItems[]>,
  ) {
    const payload = action.payload;
    state.groupedAssignedItems.done = payload;
    const orderIds: number[] = [];

    payload?.map(value => {
      orderIds.push(value.metaData.orderId);
    });
    state.orderIdsToBeSend = orderIds;
    state.tabDataCount.doneCount = payload[0]?.keyTotalEntries;
    state.globalLoading = false;
  },
  setToDoCount: function (
    state: BatchDetailContextState,
    action: PayloadAction<number>,
  ) {
    state.tabDataCount.todoCount = action.payload;
  },
  setGroupedAssignedItemsAll: function (
    state: BatchDetailContextState,
    action: PayloadAction<GroupedAssignedItems[]>,
  ) {
    const payload = action.payload;
    const orderIds: number[] = [];

    payload?.map(value => {
      const deliveryLocationState =
        value?.metaData?.order.deliveryLocation.deliveryStatus;
      if (state.minimumDeliveryLocationStatus) {
        if (deliveryLocationState === state.minimumDeliveryLocationStatus)
          orderIds.push(value.metaData.orderId);
      } else {
        orderIds.push(value.metaData.orderId);
      }
    });

    state.groupedAssignedItems.all = action.payload;
    state.orderIdsToBeSend = orderIds;
    state.globalLoading = false;
  },
  clearGroupedAssignedItems: function (state: BatchDetailContextState) {
    state.groupedAssignedItems = {
      todo: [],
      inReview: [],
      done: [],
      all: [],
    };
    state.globalLoading = true;
  },
  setRemainingItemCount: function (
    state: BatchDetailContextState,
    action: PayloadAction<number[]>,
  ) {
    state.remainingItemCount = action.payload;
    const count = action.payload?.length ? action.payload[0] : undefined;
    if (count === 0) state.disableToDo = true;
    else state.disableToDo = false;
  },
  setOrderAssignee: function (
    state: BatchDetailContextState,
    action: PayloadAction<any>,
  ) {
    state.orderAssignee = action.payload;
  },
  setReceipts: function (
    state: BatchDetailContextState,
    action: PayloadAction<string[]>,
  ) {
    state.receipts = action.payload;
  },
  setContactBottomSheetProps: function (
    state: BatchDetailContextState,
    action: PayloadAction<ContactBottomSheetProps | undefined>,
  ) {
    state.contactSheetProps = action.payload;
  },
  setTutorial: function (
    state: BatchDetailContextState,
    action: PayloadAction<boolean>,
  ) {
    state.tutorial = action.payload;
  },
  setPickedOrderFoundModal: function (
    state: BatchDetailContextState,
    action: PayloadAction<boolean>,
  ) {
    state.hasPickedOrderModal = action.payload;
  },
  setOpenUploadReceiptModal: function (
    state: BatchDetailContextState,
    action: PayloadAction<boolean>,
  ) {
    state.openUploadReceiptModal = action.payload;
  },
  setUPCOrderData: function (
    state: BatchDetailContextState,
    action: PayloadAction<UPCOrderData>,
  ) {
    state.upcOrderData = action.payload;
    state.globalLoading = false;
  },
  setUPCItemsTodo: function (
    state: BatchDetailContextState,
    action: PayloadAction<number>,
  ) {
    state.tabDataCount.todoCount = action.payload;
    if (!action.payload) state.disableToDo = true;
    else state.disableToDo = false;
  },
  setUPCItemsDone: function (
    state: BatchDetailContextState,
    action: PayloadAction<number>,
  ) {
    state.tabDataCount.doneCount = action.payload;
  },
  setDisablePickingRadioButton: function (
    state: BatchDetailContextState,
    action: PayloadAction<boolean>,
  ) {
    state.disablePickingRadioButton = action.payload;
  },
  getMinimumDeliveryStatus: function (
    state: BatchDetailContextState,
    action: PayloadAction<DeliveryLocationStatus>,
  ) {
    state.minimumDeliveryLocationStatus = action.payload;
  },
  setSkippedReceiptFlow: function (
    state: BatchDetailContextState,
    action: PayloadAction<ReceiptBypassMode>,
  ) {
    state.hasSkipReceipt = action.payload;
  },
  updateDoneSectionWithUpdated: function (
    state: BatchDetailContextState,
    action: PayloadAction<{
      orderId: number;
      orderItemId: number;
      updatedQty: number;
    }>,
  ) {
    const {orderId, orderItemId, updatedQty} = action.payload;
    const order = state.groupedAssignedItems.done.find(
      item => item.metaData.orderId === orderId,
    );

    const orderItem = order?.orderItems?.find(item => item?.id === orderItemId);

    if (orderItem) {
      orderItem.quantity = updatedQty;
      if (orderItem?.shopperItemChangesForShopper)
        orderItem.shopperItemChangesForShopper.updatedQuantity = updatedQty;
    }
  },
};

const slice = createSlice({
  initialState,
  name: 'batchDetail',
  reducers: reducer,
});

export function useBatchDetailReducer() {
  const batchDetailReducer = useReducer(slice.reducer, initialState);
  return batchDetailReducer;
}

export function useBatchDetailContext() {
  return useContext(BatchDetailContext);
}

export const batchDetailActionsLocal = slice.actions;
