import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const BinIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        data-name="Icon ionic-md-close-circle"
        d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
        transform="translate(-3.375 -3.375)"
      />
    </Icon>
  );
};

export default BinIcon;
