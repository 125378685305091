import {Box, HBox} from '@buncha/components/core/view';
import {H3Text, H6Text} from '@buncha/components/core/text';
import React, {useCallback} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {BuyerData} from '@buncha/itemDetails/types/ItemScanner';
import If from '@buncha/components/conditional/If';
import UpdateDetailsSection from '../updateDetailsSection';
import {styles} from './style';
import {useItemScannerContext} from '@buncha/itemDetails/hooks/ItemScanner';
import HourGlass from '@buncha/components/core/icon/HourGlass';
import Boxes from '@buncha/components/core/icon/Boxes';
import UpdatePriceCarousal from '@buncha/components/composites/Carousal/UpdatePriceCarousal';

const DetailsInput = () => {
  const {state} = useItemScannerContext();
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const buyerData = state.buyerData;

  const getDetailsCell = useCallback((item: BuyerData) => {
    return <UpdateDetailsSection item={item} />;
  }, []);

  const getCarouselCell = useCallback(
    (item: BuyerData) => {
      const quantity = item?.updatedQuantity || item?.quantity || 0;
      return (
        <Box style={styles.itemContainerBox}>
          <HBox style={styles.detailsContainer}>
            <HBox>
              {/* <ProfileIcon viewBox="-3 -1 25 25" fill={theme?.text.blue[300]} /> */}
              <H3Text
                style={[
                  styles.marRig10,
                  {
                    color: theme?.text.blue[300],
                    fontFamily: muliFont[900].normal,
                  },
                ]}>{`${item.firstName} ${item.lastName || ''}`}</H3Text>
              <HourGlass
                fill={theme?.background.blue[300]}
                height={20}
                width={20}
                viewBox="0 -7 15 25"
              />
            </HBox>
            <HBox>
              <Boxes width="20" height="20" viewBox="0 0 15 15" fill="none" />
              <H6Text
                style={[
                  styles.remainingQuantity,
                  {color: theme?.text.orange[100]},
                ]}>
                Qty for this user: {quantity}
              </H6Text>
            </HBox>
          </HBox>
          <Box>{getDetailsCell(item)}</Box>
        </Box>
      );
    },
    [
      getDetailsCell,
      muliFont,
      theme?.background.blue,
      theme?.text.blue,
      theme?.text.orange,
    ],
  );

  return (
    <React.Fragment>
      <If condition={buyerData?.length === 1}>
        {getDetailsCell(buyerData[0])}
      </If>
      <If condition={buyerData?.length > 1}>
        <UpdatePriceCarousal
          paginationSize={8}
          containerStyle={styles.itemContent}
          imageContainerStyle={styles.itemImage}
          showImageCaption={true}
          imageCaption={''}
          data={buyerData}
          disabledPaginationColor={theme?.text.gray[300]}
          showPagination={true}
          renderItem={getCarouselCell}
          nextButton
        />
      </If>
    </React.Fragment>
  );
};

export default React.memo(DetailsInput);
