import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const AddPhoto: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        d="M30 12C29.4333 12 28.9587 11.808 28.576 11.424C28.192 11.0413 28 10.5667 28 10V8H26C25.4333 8 24.9587 7.80867 24.576 7.426C24.192 7.042 24 6.56667 24 6C24 5.43333 24.192 4.958 24.576 4.574C24.9587 4.19133 25.4333 4 26 4H28V2C28 1.43333 28.192 0.958 28.576 0.574C28.9587 0.191333 29.4333 0 30 0C30.5667 0 31.0413 0.191333 31.424 0.574C31.808 0.958 32 1.43333 32 2V4H34C34.5667 4 35.0413 4.19133 35.424 4.574C35.808 4.958 36 5.43333 36 6C36 6.56667 35.808 7.042 35.424 7.426C35.0413 7.80867 34.5667 8 34 8H32V10C32 10.5667 31.808 11.0413 31.424 11.424C31.0413 11.808 30.5667 12 30 12ZM8 28H28C28.4 28 28.7 27.8167 28.9 27.45C29.1 27.0833 29.0667 26.7333 28.8 26.4L23.3 19.05C23.1 18.7833 22.8333 18.65 22.5 18.65C22.1667 18.65 21.9 18.7833 21.7 19.05L16.5 26L12.8 21.05C12.6 20.7833 12.3333 20.65 12 20.65C11.6667 20.65 11.4 20.7833 11.2 21.05L7.2 26.4C6.93333 26.7333 6.9 27.0833 7.1 27.45C7.3 27.8167 7.6 28 8 28ZM4 36C2.9 36 1.958 35.6087 1.174 34.826C0.391333 34.042 0 33.1 0 32V4C0 2.9 0.391333 1.958 1.174 1.174C1.958 0.391333 2.9 0 4 0H20V10C20 10.5667 20.192 11.0413 20.576 11.424C20.9587 11.808 21.4333 12 22 12H24V14C24 14.5667 24.192 15.0413 24.576 15.424C24.9587 15.808 25.4333 16 26 16H36V32C36 33.1 35.6087 34.042 34.826 34.826C34.042 35.6087 33.1 36 32 36H4Z"
        fill={props?.fill}
      />
    </Icon>
  );
};

export default AddPhoto;
