import {Box, HBox} from '@buncha/components/core/view';
import {ChevronLeftIcon, ChevronRightIcon} from 'native-base';
import {
  Dimensions,
  FlatList,
  Image,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleSheet,
} from 'react-native';
import React, {useCallback, useMemo, useRef} from 'react';

import {BottomButton} from '../Button';
import {CarousalProps} from './types';
import {H3Text} from '@buncha/components/core/text';
import If from '@buncha/components/conditional/If';
import Pagination from '../Pagination/Pagination';
import font from '@buncha/appStyles/style/font';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const {width} = Dimensions.get('window');

const Carousal = (props: CarousalProps) => {
  const {data, showImageCaption, imageCaption, showPagination} = props;
  const flatListRef = useRef<FlatList<any>>(null);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const theme = useColorThemeType();

  const getPagination = useMemo(() => {
    return (
      <Pagination
        paginationSize={props.paginationSize}
        disabledPaginationColor={props.disabledPaginationColor}
        key={activeIndex}
        data={data}
        activeIndex={activeIndex}
      />
    );
  }, [activeIndex, data, props.disabledPaginationColor, props.paginationSize]);

  const onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const totalWidth = event.nativeEvent.layoutMeasurement.width;
      const scrollOffset = event.nativeEvent.contentOffset.x;
      const currentSlide = Math.ceil(scrollOffset / totalWidth);
      setActiveIndex(currentSlide);
    },
    [],
  );

  const scroll = useCallback((index: number) => {
    flatListRef.current?.scrollToOffset({
      animated: true,
      offset: index * width,
    });
  }, []);

  const renderItem = ({item, index}: {item: any; index: number}) => (
    <Box
      key={index}
      style={{
        width: width * 0.9,
      }}>
      <Box style={props.containerStyle}>
        <Image
          source={{uri: item}}
          style={props.imageContainerStyle}
          resizeMode="contain"
        />
      </Box>
    </Box>
  );

  const onBackScroll = useCallback(() => {
    scroll(activeIndex ? activeIndex - 1 : 0);
  }, [activeIndex, scroll]);

  const onNextScroll = useCallback(() => {
    scroll(activeIndex < data?.length ? activeIndex + 1 : activeIndex);
  }, [activeIndex, data?.length, scroll]);

  return (
    <Box style={styles.container}>
      <FlatList
        horizontal
        ref={flatListRef}
        pagingEnabled
        indicatorStyle="black"
        data={data}
        keyExtractor={(item, index) => index.toString()}
        renderItem={renderItem}
        contentContainerStyle={styles.contentContainer}
        showsHorizontalScrollIndicator={false}
        onScroll={onScroll}
      />
      <If condition={showImageCaption}>
        <Box style={[styles.imageCaptionContainer]}>
          <H3Text style={[styles.captionTypo]}>{imageCaption}</H3Text>
        </Box>
      </If>
      <If condition={showPagination}>{getPagination}</If>
      <If condition={props.nextButton}>
        <HBox style={styles.buttonContainers}>
          <BottomButton
            disabled={activeIndex === 0}
            style={styles.height}
            containerStyle={styles.buttonContainer}
            onPress={onBackScroll}
            title={'Prev'}
            titleStyle={styles.titleStyle}
            titleColor={theme?.text?.green?.[100]}
            borderWidth={1}
            borderColor={theme?.text?.green?.[100]}
            borderDisableColor={theme?.text.green[200]}
            bgColor={theme?.background.white[100]}
            leftComponent={
              <Box style={styles.iconStyle}>
                <ChevronLeftIcon
                  style={{color: theme?.background.green[100]}}
                />
              </Box>
            }
          />
          <BottomButton
            disabled={activeIndex === data?.length - 1}
            containerStyle={styles.buttonContainer}
            titleStyle={styles.titleStyle}
            style={styles.height}
            onPress={onNextScroll}
            title={'Next'}
            rightComponent={
              <Box style={styles.iconStyle}>
                <ChevronRightIcon
                  style={{color: theme?.background.white[100]}}
                />
              </Box>
            }
          />
        </HBox>
      </If>
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
  },
  contentContainer: {
    paddingHorizontal: 16,
  },
  imageCaptionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    flex: 1,
  },
  captionTypo: {
    ...font.FontSize.fontH6,
    opacity: 0.5,
  },
  buttonContainers: {maxWidth: width, marginTop: 10},
  titleStyle: {marginBottom: 1},
  buttonContainer: {
    width: '45%',
  },
  height: {
    height: 45,
  },
  iconStyle: {marginRight: 5},
});

export default React.memo(Carousal);
