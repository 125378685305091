import {
  GET_CATALOG_ITEM,
  GET_LOCATION_INFO_HISTORY,
} from '@buncha/config/graphQL/documentNode/queries';
import {ItemLocationContext, initialState} from '../context/ItemLocation';
import {ItemLocationState, LocationHistory} from '../types/ItemLocation';
import {
  MARK_ITEM_AS_FOUND_OR_MISSING,
  UPLOAD_IMAGE,
} from '@buncha/config/graphQL/documentNode/mutation';
import {OrderItem, UploadImageUrlResponse} from '../types/ItemScanner';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {useContext, useReducer, useState} from 'react';

import {ItemDetailsAction} from './ItemDetails';
import {ToastType} from '@buncha/components/composites/notification/type';
import {getRandomNumbers} from '@buncha/utils/common';
import {gqlService} from '@buncha/config/graphQL';
import {useToastMessage} from '@buncha/components/composites/notification';

const reducer = {
  setItemData: function (
    state: ItemLocationState,
    action: PayloadAction<OrderItem>,
  ) {
    state.item = action.payload;
  },
  setLocationHistory: function (
    state: ItemLocationState,
    action: PayloadAction<LocationHistory[]>,
  ) {
    state.locationData = action.payload;
  },
  setItemLocationData: function (
    state: ItemLocationState,
    action: PayloadAction<any>,
  ) {
    state.itemLocationInformation = action.payload;
  },
};

const slice = createSlice({
  initialState,
  name: 'ItemLocationContext',
  reducers: reducer,
});

export function useItemLocationReducer() {
  const reducerInfo = useReducer(slice.reducer, initialState);
  return reducerInfo;
}

export function useItemLocationContext() {
  return useContext(ItemLocationContext);
}

export const ItemLocationAction = slice.actions;

export function useGetOrderItem(dispatch: any) {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loadingItem, setLoadingItem] = useState(false);

  const getOrderItem = async (orderItemId: number) => {
    try {
      setLoadingItem(true);
      const res = await gqlService?.query({
        query: GET_CATALOG_ITEM,
        fetchPolicy: 'network-only',
        variables: {
          orderItemId: orderItemId,
          isUpcOrder: false,
        },
      });
      if (res?.data && dispatch)
        dispatch(
          ItemLocationAction.setItemData(res?.data?.getCatalogItemForShopper),
        );
    } catch (err: any) {
      showErrorToast(err.message);
    } finally {
      setLoadingItem(false);
    }
  };

  return {loadingItem, getOrderItem};
}

export function useLocationUpdate(dispatch: any) {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loadingItem, setLoadingItem] = useState(false);

  const markItem = async (
    itemInfoId: number,
    foundHere: boolean,
    reportMissing: boolean,
    callback: any,
  ) => {
    try {
      setLoadingItem(true);
      const res = await gqlService?.mutation({
        mutation: MARK_ITEM_AS_FOUND_OR_MISSING,
        fetchPolicy: 'network-only',
        variables: {
          itemInfoId: itemInfoId,
          foundHere: foundHere,
          reportMissing: reportMissing,
        },
      });
      if (res?.data && dispatch) {
        dispatch(
          ItemDetailsAction.setItemLocationData(
            res?.data?.markItemAsFoundOrMissing,
          ),
        );
        callback();
      }
    } catch (err: any) {
      showErrorToast(err.message);
    } finally {
      setLoadingItem(false);
    }
  };

  return {loadingItem, markItem};
}

export function useLocationFetcher(dispatch: any) {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loadingItem, setLoadingItem] = useState(false);

  const getLocationHistory = async (orderItemId: number, esIndex: string) => {
    try {
      setLoadingItem(true);
      const res = await gqlService?.query({
        query: GET_LOCATION_INFO_HISTORY,
        fetchPolicy: 'network-only',
        variables: {
          orderItemId: orderItemId,
          isUpcOrder: false,
          esIndex: esIndex,
        },
      });
      if (res?.data && dispatch)
        dispatch(
          ItemLocationAction.setLocationHistory(
            res?.data?.getAllItemInformation,
          ),
        );
    } catch (err: any) {
      showErrorToast(err.message);
    } finally {
      setLoadingItem(false);
    }
  };

  return {loadingItem, getLocationHistory};
}

export function useImageUpload() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [imageUploading, setImageUploading] = useState(false);

  const uploadLocationImage = async (base64: string) => {
    try {
      setImageUploading(true);
      const file = {
        uri: `${getRandomNumbers().toString(36).slice(2, 7)}.jpg`,
        base64: base64,
      };
      const res = await gqlService?.mutation<UploadImageUrlResponse>({
        mutation: UPLOAD_IMAGE,
        fetchPolicy: 'network-only',
        variables: {file},
      });
      if (res) return res?.data?.itemImageUpload?.uri;
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setImageUploading(false);
    }
  };

  return {
    uploadLocationImage,
    imageUploading,
  };
}
