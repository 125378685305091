import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  useInitializeUPCShift,
  useUPCShiftContext,
} from '@buncha/UPCShifts/hooks/UPCShiftsCreation';

import {Box} from '@buncha/components/core/view';
import CustomDropdown from '@buncha/UPCShifts/components/CustomDropdown';
import {H5Text} from '@buncha/components/core/text';
import {StoreData} from '@buncha/UPCShifts/types';
import StoreLogo from '@buncha/common/components/StoreLogo';
import {StyleSheet} from 'react-native';
import {Touchable} from '@buncha/components/core/touchable';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const StoreSelectorSection = (props: {
  selectedValue: StoreData;
  selectStoreValue: any;
}) => {
  const theme = useColorThemeType('main');
  const [dropDownVisible, setDropDownVisible] = useState<boolean>(false);
  const {state, dispatch} = useUPCShiftContext();
  const {getAvailableStores} = useInitializeUPCShift(dispatch);

  useEffect(() => {
    getAvailableStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDropdown = useCallback(() => {
    setDropDownVisible(!dropDownVisible);
  }, [dropDownVisible]);

  const selectStoreValue = useCallback(
    (storeData: StoreData) => {
      props.selectStoreValue(storeData);
      toggleDropdown();
    },
    [props, toggleDropdown],
  );

  const renderDropdown = useMemo(() => {
    return state?.storeList?.map((storeData: StoreData) => {
      return (
        <Touchable
          style={[
            styles.storeContainer,
            {
              borderColor: theme?.text?.gray?.[300],
            },
          ]}
          key={storeData?.Index}
          onPress={() => selectStoreValue(storeData)}>
          <StoreLogo
            boxStyle={styles.boxStyle}
            imageBoxStyle={styles.imageStyle}
            storeLogoUrl={storeData.StoreLogo}
          />
          <Box style={[styles.jcc]}>
            <H5Text
              style={[
                styles.storeTypo,
                {
                  color: theme?.text?.blue[300],
                },
              ]}>
              {storeData?.Store}
            </H5Text>
          </Box>
        </Touchable>
      );
    });
  }, [
    selectStoreValue,
    state?.storeList,
    theme?.text?.blue,
    theme?.text?.gray,
  ]);

  return (
    <Box>
      <H5Text style={[styles.headerTypo, {color: theme?.text?.blue[300]}]}>
        Select Store
      </H5Text>
      <Box>
        <CustomDropdown
          label="Select Store"
          data={state?.storeList}
          selectedValue={props?.selectedValue}
          open={dropDownVisible}
          onPress={(storeData: StoreData) => selectStoreValue(storeData)}
          onOpen={toggleDropdown}>
          {renderDropdown}
        </CustomDropdown>
      </Box>
    </Box>
  );
};

export default React.memo(StoreSelectorSection);

const styles = StyleSheet.create({
  headerTypo: {
    textAlign: 'left',
  },
  minimumItemCellTextBox: {
    paddingVertical: 15,
  },
  storeContainer: {
    padding: 10,
    borderBottomWidth: 0.25,
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  storeTypo: {
    textAlign: 'left',
    marginLeft: 20,
  },
  boxStyle: {
    height: 37,
    width: 37,
  },
  imageStyle: {
    height: 30,
    width: 30,
  },
  jcc: {
    justifyContent: 'center',
  },
});
