import {Dimensions, StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  parentBox: {
    paddingHorizontal: 20,
    flex: 1,
  },
  webHeader: {
    padding: 30,
  },
  buttonStyle: {
    marginTop: 15,
  },
  title: {
    textAlign: 'left',
    marginTop: 15,
  },
  spinnerContainer: {
    justifyContent: 'center',
    height: Dimensions?.get('window').height * 0.8,
  },
  bottomText: {
    textAlign: 'left',
  },
  infoIcon: {
    marginRight: 5,
  },
  footerBox: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 25,
    paddingTop: 5,
  },
  bottomBox: {
    borderWidth: 1,
    borderRadius: 20,
  },
});
