import {Box, HBox} from '../../../components/core/view';
import {
  ContactBottomSheetProps,
  DynamicTextType,
  OrderItemStatus,
  OrderItems,
} from '../../../batchDetails/types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  batchDetailActionsLocal,
  useBatchDetailContext,
} from '../../../batchDetails/hooks/batchDetail';

import BatchOrderItem from '../BatchOrderItem';
import {GestureResponderEvent} from 'react-native/Libraries/Types/CoreEventTypes';
import If from '../../../components/conditional/If';
import MinusTickIcon from '../../../components/core/icon/MinusTickIcon';
import RightTickIcon from '../../../components/core/icon/RightTickIcon';
import {Touchable} from '../../../components/core/touchable';
import {isWeb} from '@buncha/utils/common';
import {styles} from './style';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

interface PackOrderItemProps {
  packingFinished: boolean;
  orderItem: OrderItems;
  onClick: (orderItem: OrderItems) => void;
  dropItem: (orderItem: OrderItems) => void;
  unpackItem: (orderItem: OrderItems) => void;
}

function PackOrderItem(props: PackOrderItemProps) {
  const {packingFinished, orderItem, onClick, dropItem, unpackItem} = props;
  const [stateChanged, setStateChanged] = useState(true);
  const theme = useColorThemeType();
  const {state, dispatch} = useBatchDetailContext();

  const opacityStyle = useMemo(() => {
    return {
      opacity: packingFinished ? 0.5 : 1,
    };
  }, [packingFinished]);

  const onItemClick = useCallback(
    (clickedOrderItem: OrderItems) => {
      if (clickedOrderItem.itemStatus === OrderItemStatus.dropped)
        return unpackItem(orderItem);
      onClick(clickedOrderItem);
      setStateChanged(!stateChanged);
    },
    [onClick, orderItem, stateChanged, unpackItem],
  );

  const getRightIcon = useCallback(
    (item: any) => {
      switch (item.itemStatus) {
        case OrderItemStatus.dropped:
          return (
            <MinusTickIcon
              width={24}
              height={24}
              viewBox="-3 -1 25 25"
              fill={theme?.background.red[100]}
            />
          );
        case OrderItemStatus.packed:
          if (stateChanged)
            return (
              <RightTickIcon
                width={24}
                height={24}
                viewBox="-5 0 25 20"
                fill={theme?.background.blue[100]}
              />
            );
          return (
            <Box
              style={[
                styles.hollowCircle,
                styles.circleBox,
                {
                  borderColor: theme?.background.gray[100],
                },
              ]}
            />
          );
        case OrderItemStatus.notPacked:
          if (stateChanged)
            return (
              <RightTickIcon
                width={24}
                height={24}
                viewBox="-3 -1 25 25"
                fill={theme?.background.blue[100]}
              />
            );
          return (
            <Box
              style={[
                styles.hollowCircle,
                styles.circleBox,
                {
                  borderColor: theme?.background.gray[100],
                },
              ]}
            />
          );
        default:
          return (
            <Box
              style={[
                styles.hollowCircle,
                {
                  borderColor: theme?.background.gray[100],
                },
              ]}
            />
          );
      }
    },
    [
      stateChanged,
      theme?.background.blue,
      theme?.background.gray,
      theme?.background.red,
    ],
  );

  const handleContactSheetClose = useCallback(
    (func: Function) => {
      return () => {
        if (dispatch)
          dispatch(
            batchDetailActionsLocal.setContactBottomSheetProps(undefined),
          );
        func();
      };
    },
    [dispatch],
  );

  const handleShowContactSheet = useCallback(
    (item: OrderItems) => {
      const params: ContactBottomSheetProps = {
        orderItemId: item.id,
        buttonText: 'Drop',
        onButtonClick: handleContactSheetClose(() => dropItem(item)),
        type: DynamicTextType.dropItem,
      };
      if (dispatch)
        dispatch(batchDetailActionsLocal.setContactBottomSheetProps(params));
    },
    [dispatch, dropItem, handleContactSheetClose],
  );

  useEffect(() => {
    if (orderItem.itemStatus === OrderItemStatus.notPacked)
      setStateChanged(false);
    else setStateChanged(true);
  }, [orderItem]);

  return (
    <Box>
      <BatchOrderItem
        esIndex={state?.upcOrderData?.upcOrder?.esIndex ?? ''}
        orderItem={orderItem}
        rightIcon={
          <HBox>
            <Touchable
              style={[styles.touchableStyle, opacityStyle]}
              disabled={packingFinished}
              activeOpacity={1}
              onPress={(event: GestureResponderEvent) => {
                event.preventDefault();
                onItemClick(orderItem);
              }}>
              {getRightIcon(orderItem)}
            </Touchable>
          </HBox>
        }
        onClick={() => {
          return;
        }}
        disableOnclick={!isWeb()}
      />
      <If condition={orderItem.itemStatus !== OrderItemStatus.dropped}>
        <Touchable
          disabled={props?.packingFinished}
          style={[styles.dropContainer, opacityStyle]}
          onPress={() => handleShowContactSheet(orderItem)}>
          <MinusTickIcon
            width={17}
            height={17}
            viewBox="-3 -1 25 25"
            fill={theme?.background.red[100]}
          />
        </Touchable>
      </If>
    </Box>
  );
}

export default React.memo(PackOrderItem);
