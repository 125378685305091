import React, {useCallback, useState} from 'react';

import DeliveryNotesModal from '../deliveryNotesModal';
import {H6Text} from '../../../../components/core/text';
import {HBox} from '../../../../components/core/view';
import NotesIcon from '../../../../components/core/icon/Notes';
import {StyleSheet} from 'react-native';
import {Touchable} from '../../../../components/core/touchable';
import {useColorThemeType} from '../../../../../src/appStyles/hooks/theme';

interface DriverDeliveryNotesProps {
  deliveryLocationId: number;
  driverNotes: string;
}

const DriverDeliveryNotes: React.FC<DriverDeliveryNotesProps> = props => {
  const deliveryLocationId = props?.deliveryLocationId || 0;
  const driverNotes = props?.driverNotes || '';
  const theme = useColorThemeType('main');
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);
  return (
    <React.Fragment>
      <Touchable style={styles.touchable} onPress={toggleModal}>
        <HBox>
          <NotesIcon
            style={styles.icon}
            viewBox="0 -1 20 20"
            fill={theme?.icon?.gray?.[100]}
          />
          <H6Text style={styles.text}>Add notes</H6Text>
        </HBox>
      </Touchable>
      <DeliveryNotesModal
        deliveryLocationId={deliveryLocationId}
        driverNotes={driverNotes}
        isOpen={openModal}
        onClose={toggleModal}
      />
    </React.Fragment>
  );
};

export default React.memo(DriverDeliveryNotes);

const styles = StyleSheet.create({
  touchable: {
    marginTop: 20,
    alignSelf: 'flex-start',
  },
  iconBox: {borderWidth: 1, justifyContent: 'flex-start'},
  icon: {
    marginRight: 10,
  },
  text: {
    textDecorationLine: 'underline',
  },
});
