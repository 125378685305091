import {
  GET_CONSTANTS,
  GET_PAST_ORDERS_BY_RUNID,
  GET_RUNS_AND_ORDERS_FOR_SHOPPER,
  GET_UNREAD_MEMO_COUNT,
  GET_VERSION_CHECK_QUERY,
} from '../../config/graphQL/documentNode/queries';
import {
  GetUnReadMemoCountResponse,
  HomeStateContextState,
  PastOrder,
  RunsAndOrders,
  UserAttendance,
  VersionCheck,
  VersionCheckResponse,
} from '../types';
import {HomeContext, initialState} from '../context/homeContext';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import React, {useCallback, useContext, useReducer, useState} from 'react';

import {Analytics} from '../../config/analytics/analytics';
import {ApiState} from '@buncha/common/types/common';
import {MemoStatus} from '@buncha/memos/types';
import {ToastType} from '@buncha/components/composites/notification/type';
import {gqlService} from '../../config/graphQL';
import {onBoardingActions} from '@buncha/onBoarding/reducer';
import {useToastMessage} from '@buncha/components/composites/notification';

const reducer = {
  setUserAttendance: function (
    state: HomeStateContextState,
    action: PayloadAction<UserAttendance>,
  ) {
    state.userAttendance = action.payload;
  },
};

const slice = createSlice({
  initialState,
  name: 'homeContext',
  reducers: reducer,
});

export function useHomeReducer() {
  const homeReducer = useReducer(slice.reducer, initialState);
  return homeReducer;
}

export function useHomeContext() {
  return useContext(HomeContext);
}

export const homeActionsLocal = slice.actions;

export function usePastOrders() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [runsAndOrders, setRunsAndOrders] = useState<RunsAndOrders[]>([]);
  const getPastOrders = async () => {
    try {
      setLoading(true);
      const response = await gqlService?.query({
        query: GET_RUNS_AND_ORDERS_FOR_SHOPPER,
        variables: {daysCount: 20},
        fetchPolicy: 'network-only',
      });
      const data = response?.data.getRunsAndOrdersForShopper;
      setLoading(false);

      Analytics.eventWithProps('get Past Orders', {
        type: 'Past Order',
        pastOrderCount: data?.length || 0,
      });
      setRunsAndOrders(data);
    } catch (err: any) {
      setError(err.message);
      setLoading(false);
    }
  };

  return {getPastOrders, loading, error, runsAndOrders};
}

export function usePastOrderByRun() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [orders, setOrders] = useState<PastOrder[]>([]);

  const getPastOrdersByRun = async (runId?: number) => {
    if (!runId) return;
    try {
      setLoading(true);
      const response = await gqlService?.query({
        query: GET_PAST_ORDERS_BY_RUNID,
        variables: {runId},
        fetchPolicy: 'network-only',
      });
      const data = response?.data.getRunsAndOrdersForShopper;
      setLoading(false);
      setOrders(data[0].orders);
    } catch (err: any) {
      setError(err.message);
      setLoading(false);
    }
  };

  return {getPastOrdersByRun, loading, error, orders};
}

export function useSetConstants(dispatch: React.Dispatch<any> | null) {
  const setConstants = async () => {
    try {
      const response = await gqlService?.query({
        query: GET_CONSTANTS,
        fetchPolicy: 'network-only',
      });
      if (dispatch)
        dispatch(onBoardingActions.setConstants(response?.data?.getConstants));
      const configData = response?.data?.getConstants?.RETRY_CONFIG;
      if (configData) gqlService?.setRetryConfigData(configData);

      return response?.data?.getConstants;
    } catch (err: any) {}
  };
  return {setConstants};
}

export function useVersionCheck(): [() => void, ApiState<VersionCheck>] {
  const [state, setState] = useState<ApiState<VersionCheck>>({
    data: undefined,
    error: undefined,
    loading: false,
  });
  const func = useCallback(async () => {
    try {
      setState({loading: true, error: undefined, data: state.data});
      const result = await gqlService?.query<VersionCheckResponse>({
        query: GET_VERSION_CHECK_QUERY,
        fetchPolicy: 'no-cache',
      });
      setState({
        loading: false,
        error: undefined,
        data: result?.data?.versionCheck,
      });
    } catch (err: any) {
      setState({loading: false, error: err, data: undefined});
    }
  }, [state.data]);
  return [func, state];
}

export function useGetUnreadMemoCount(dispatch: React.Dispatch<any> | null) {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const getUnReadMemoCount = async () => {
    try {
      const response = await gqlService?.query<GetUnReadMemoCountResponse>({
        query: GET_UNREAD_MEMO_COUNT,
        fetchPolicy: 'network-only',
        variables: {size: 1, skip: 0},
      });
      const fetchedMemos = response?.data?.getMemos || [];
      const unReadCount =
        fetchedMemos.find(memoData => memoData.status === MemoStatus.unread)
          ?.totalLength || 0;
      if (dispatch) {
        dispatch(onBoardingActions.setUnreadMemoCount(unReadCount));
        dispatch(onBoardingActions.setUnreadMemoModal(Boolean(unReadCount)));
      }
    } catch (error: any) {
      showErrorToast(error?.message);
    }
  };

  return {getUnReadMemoCount};
}

export function useLogger() {
  // not needed for web
}
