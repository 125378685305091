import {Component, ProfileOptionProps} from './types';
import React, {useCallback, useMemo} from 'react';

import {Box} from '../../../components/core/view';
import {Divider} from 'native-base';
import MainComponent from '../MainComponent';

const ProfileOptions = (props: ProfileOptionProps) => {
  const {listData} = props;

  const renderComponent = useCallback((component: Component) => {
    return <MainComponent component={component} />;
  }, []);

  const mainComponent = useCallback(
    (component: Component) => {
      const isDivider = Boolean(component.divider);
      if (isDivider) return <Divider />;
      return renderComponent(component);
    },
    [renderComponent],
  );

  const RenderItems = useMemo(() => {
    return listData?.map((component: Component) => {
      return mainComponent(component);
    });
  }, [mainComponent, listData]);

  return (
    <Box>
      <Box>{RenderItems}</Box>
    </Box>
  );
};

export default React.memo(ProfileOptions);
