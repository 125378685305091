import {Box} from 'native-base';
import If from '@buncha/components/conditional/If';
import React from 'react';
import {StyleSheet} from 'react-native';
import TickIcon from '@buncha/components/core/icon/TickIcon';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const TimeLine = (props: {checked: boolean}) => {
  const {checked} = props;
  const theme = useColorThemeType('main');
  return (
    <Box style={[styles.parentContainer]}>
      <Box>
        <If condition={!checked}>
          <Box
            style={[
              styles.circleContainer,
              {borderColor: theme?.text.gray[100]},
            ]}
          />
        </If>
        <If condition={checked}>
          <Box
            style={[
              styles.circleContainer,
              {
                borderColor: theme?.text.green[100],
                backgroundColor: theme?.text.green[100],
              },
            ]}>
            <TickIcon viewBox="0 0 13 10" height={13} width={15} />
          </Box>
        </If>
      </Box>
    </Box>
  );
};

export default React.memo(TimeLine);

const styles = StyleSheet.create({
  parentContainer: {
    flex: 1,
    marginTop: 20,
  },
  circleContainer: {
    width: 14,
    height: 14,
    borderRadius: 15,
    // backgroundColor: 'red',
    borderWidth: 1,
    justifyContent: 'center',
  },
});
