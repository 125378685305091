import React, {useCallback, useEffect} from 'react';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {Analytics} from '../../../config/analytics/analytics';
import {HBox} from '../../../components/core/view';
import If from '../../../components/conditional/If';
import {PSmallText} from '../../../components/core/text';
import SupportAgent from '../../../components/core/icon/SupportAgent';
import {Touchable} from '../../../components/core/touchable';
import {styles} from './styles';
import {useGetBagCountLeft} from '../../../home/hooks/AssinedShift';
import {useIsFocused} from '@react-navigation/native';

function BagCountBanner() {
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const [getBagCountLeft, state] = useGetBagCountLeft();
  const isFocused = useIsFocused();

  const openIntercom = useCallback(() => {
    Analytics.displayMessenger();
  }, []);

  useEffect(() => {
    if (isFocused) getBagCountLeft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return (
    <If condition={state.data?.text}>
      <Touchable
        onPress={(e: any) => {
          e.preventDefault();
          openIntercom();
        }}>
        <HBox
          style={[
            styles.mainContainer,
            {
              backgroundColor: theme?.background.yellow[200],
            },
          ]}>
          <PSmallText
            style={[
              styles.textStyle,
              {
                fontFamily: muliFont[600].normal,
                color: theme?.text.blue[300],
              },
            ]}>
            {state.data?.text}
          </PSmallText>
          <SupportAgent
            width={20}
            height={20}
            viewBox={'0 0 20 20'}
            fill={theme?.icon.blue[300]}
            style={styles.iconStyle}
          />
        </HBox>
      </Touchable>
    </If>
  );
}

export default React.memo(BagCountBanner);
