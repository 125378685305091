import {Box, HBox} from '../../../components/core/view';
import {ChevronDownIcon, ChevronUpIcon} from 'native-base';
import React, {useCallback} from 'react';
import {
  batchDetailActionsLocal,
  useBatchDetailContext,
} from '../../../batchDetails/hooks/batchDetail';

import {Analytics} from '../../../config/analytics/analytics';
import {EventType} from '../../../config/analytics/type';
import {H6Text} from '../../../components/core/text';
import HorizontalBars from '../../../components/core/icon/HorizontalBars';
import {Info} from '@buncha/components/core/icon';
import {Touchable} from '../../../components/core/touchable';
import {itemsSortBy} from '../../../batchDetails/types';
import {styles} from './styles';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

const SortBar = (props: {upcMode?: boolean}) => {
  const theme = useColorThemeType();
  const {state, dispatch} = useBatchDetailContext();
  const currentSortValue = state.sortBarValue;
  const defaultSort = currentSortValue === itemsSortBy.category;
  const changeSort = useCallback(() => {
    Analytics.eventWithProps('Change Sort', {
      type: EventType.cta,
      currentSortValue: currentSortValue || itemsSortBy.category,
      changeSortValueTo: defaultSort
        ? itemsSortBy.customer
        : itemsSortBy.category,
    });
    if (dispatch) {
      dispatch(batchDetailActionsLocal.clearGroupedAssignedItems());
      if (defaultSort)
        dispatch(batchDetailActionsLocal.setSortBarValue(itemsSortBy.customer));
      else
        dispatch(batchDetailActionsLocal.setSortBarValue(itemsSortBy.category));
    }
  }, [currentSortValue, defaultSort, dispatch]);

  const getRightIcon = useCallback(() => {
    if (defaultSort)
      return (
        <Box>
          <ChevronDownIcon
            size={3}
            color={theme?.text.gray[100]}
            style={[styles.sortIcon]}
          />
          <ChevronUpIcon
            size={3}
            color={theme?.text.gray[100]}
            style={[styles.sortIcon]}
          />
        </Box>
      );
    return (
      <Box>
        <ChevronUpIcon
          size={3}
          color={theme?.text.gray[100]}
          style={[styles.sortIcon]}
        />
        <ChevronDownIcon
          size={3}
          color={theme?.text.gray[100]}
          style={[styles.sortIcon]}
        />
      </Box>
    );
  }, [defaultSort, theme?.text.gray]);

  const getText = useCallback(() => {
    if (state.sortBarValue === itemsSortBy.category) return 'Category';
    return 'Customer';
  }, [state.sortBarValue]);

  if (props.upcMode)
    return (
      <HBox
        style={[
          styles.mainContainer,
          styles.UPCmainContainer,
          {
            borderColor: theme?.background.gray[100],
            backgroundColor: theme?.background.gray[200],
          },
        ]}>
        <Info viewBox="0 -4.5 28 28" fill={theme?.background.gray[100]} />
        <H6Text>Scan item UPCs to help us track their location</H6Text>
      </HBox>
    );
  return (
    <Touchable onPress={changeSort}>
      <Box
        style={[
          styles.mainContainer,
          {
            borderColor: theme?.background.gray[100],
            backgroundColor: theme?.background.gray[200],
          },
        ]}>
        <Box style={[styles.subContainer]}>
          <HBox>
            <HBox style={[styles.sortContainer]}>
              <HorizontalBars
                width={24}
                height={24}
                fill={theme?.background.gray[100]}
                viewBox="0 -5 30 20"
              />
              <H6Text style={[styles.sortText]}>Sort: {getText()}</H6Text>
            </HBox>
            <HBox>{getRightIcon()}</HBox>
          </HBox>
        </Box>
      </Box>
    </Touchable>
  );
};

export default React.memo(SortBar);
