import {ChevronDownIcon, ChevronUpIcon} from 'native-base';
import React, {useCallback, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '@buncha/config/redux/hooks';

import {Accordian} from '../accordian';
import {FileUploadData} from '@buncha/config/fileUpload/type';
import FileUploadHeader from './FileUploadHeader';
import If from '@buncha/components/conditional/If';
import {StorageKeys} from '@buncha/config/storage/type';
import {StyleSheet} from 'react-native';
import UploaderChild from './UploaderChild';
import {homeActions} from '@buncha/home/reducer';
import isEmpty from 'lodash/isEmpty';
import {storage} from '@buncha/config/storage';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const ImageUploader = () => {
  const theme = useColorThemeType();
  const appDispatch = useAppDispatch();
  const fileUploadData = useAppSelector(gbState => gbState.home.fileUploadData);

  const getImageUploadData = useCallback(async () => {
    const data = await storage.getItem(StorageKeys.FileUploadData);
    if (data) {
      const pasredData: FileUploadData = JSON.parse(data);
      appDispatch(homeActions.setFileUplaodData(pasredData));
    }
  }, [appDispatch]);

  useEffect(() => {
    getImageUploadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <If condition={!isEmpty(fileUploadData)}>
      <Accordian
        containerStyle={[
          styles.mainContainer,
          {backgroundColor: theme?.background.yellow[100]},
        ]}
        childContainerStyle={styles.childContainer}
        isExpanded={false}
        header={<FileUploadHeader />}
        children={<UploaderChild />}
        style={{backgroundColor: theme?.background.yellow[100]}}
        iconEnable={<ChevronUpIcon color={theme?.text.blue[300]} />}
        iconDisable={<ChevronDownIcon color={theme?.text.blue[300]} />}
      />
    </If>
  );
};

export default React.memo(ImageUploader);

const styles = StyleSheet.create({
  mainContainer: {
    paddingHorizontal: 15,
    paddingVertical: 1,
  },
  childContainer: {
    maxHeight: 170,
  },
});
