import {
  DeliveryLocationStatus,
  TutorialName,
} from '../../../../batchDetails/types';
import {Dimensions, StyleSheet} from 'react-native';
import React, {useCallback} from 'react';

import {Analytics} from '../../../../config/analytics/analytics';
import {Box} from '../../../../../src/components/core/view';
import {DriverScreens} from '../../../../../src/driver/navigation/type';
import {EventType} from '../../../../config/analytics/type';
import {HomeScreens} from '../../../../../src/home/navigation/types';
import {MainScreens} from '../../../../navigation/types';
import {PButton} from '../../../../../src/components/core/Button';
import {SpinnerComponent} from '../../../../components/core/loader';
import {ToastType} from '../../../../components/composites/notification/type';
import {getWidth} from '../../../../../src/utils/common';
import {navigation} from '../../../../../src/config/navigation';
import {useColorThemeType} from '../../../../../src/appStyles/hooks/theme';
import {useFinishTutorialForRunner} from '../../../../home/hooks/Tutorials';
import {useRoute} from '@react-navigation/native';
import {useSetDeliveryLocationStatus} from '@buncha/driver/hooks/deliveryDetails';
import {useSubmitDeliveryBagsCount} from '../../../../driver/hooks/driverOrders';
import {useToastMessage} from '../../../../../src/components/composites/notification';

interface ConfirmDeliveryButtonProps {
  globalDisabled?: boolean;
  deliveryLocationId: number;
  runId: number;
  bagReturnCount: number;
  bagUsedCount: number;
  orderId: number;
  isExternalRun?: boolean;
}

const ConfirmDeliveryButton: React.FC<ConfirmDeliveryButtonProps> = props => {
  const {globalDisabled, bagReturnCount, bagUsedCount, orderId, isExternalRun} =
    props;
  const theme = useColorThemeType('main');
  const runId = props?.runId || 0;
  const routeParams: any = useRoute().params;
  const tutorial = routeParams?.tutorial === 'true';
  const [showSuccessToast] = useToastMessage(ToastType.Success);
  const {setOrderAsDelivered, loading} = useSetDeliveryLocationStatus();
  const [submitDeliveryBagsCount] = useSubmitDeliveryBagsCount();
  const {finishTutorialForRunner, tutorialLoading} =
    useFinishTutorialForRunner();
  const deliveryLocationId = props?.deliveryLocationId || 0;

  const submitBagCount = useCallback(() => {
    submitDeliveryBagsCount(bagUsedCount, bagReturnCount, runId, orderId);
  }, [bagReturnCount, bagUsedCount, orderId, runId, submitDeliveryBagsCount]);

  const markOrderAsDelivered = useCallback(async () => {
    if (tutorial) {
      await finishTutorialForRunner(TutorialName.driverGuide);
      return navigation.navigate(MainScreens.Home, {
        screen: HomeScreens.Tutorials,
      });
    }
    if (deliveryLocationId) {
      if (!isExternalRun) submitBagCount();
      const id = await setOrderAsDelivered(
        deliveryLocationId,
        runId,
        DeliveryLocationStatus.delivered,
      );
      if (id) {
        showSuccessToast('Order has been delivered');
        Analytics.eventWithProps('Confirm delivery', {
          type: EventType.cta,
          previousStatus: DeliveryLocationStatus.onTheWay,
          deliveryLocationId: deliveryLocationId,
          status: DeliveryLocationStatus.delivered,
          userType: 'driver',
        });
        navigation.navigate(HomeScreens.Driver, {
          screen: DriverScreens.DriverOrders,
          params: {runId: runId},
        });
      }
    }
  }, [
    deliveryLocationId,
    finishTutorialForRunner,
    isExternalRun,
    runId,
    setOrderAsDelivered,
    showSuccessToast,
    submitBagCount,
    tutorial,
  ]);
  return (
    <Box style={[styles.parentBox, {borderColor: theme?.text?.gray?.[200]}]}>
      <PButton
        style={styles.bottomCTA}
        title={`Confirm Delivery${tutorial ? ' (Finish tutorial)' : ''}`}
        isLoading={loading || tutorialLoading}
        onPress={markOrderAsDelivered}
        disabled={loading || tutorialLoading || globalDisabled}
        loadingIndicator={<SpinnerComponent />}
      />
    </Box>
  );
};

export default React.memo(ConfirmDeliveryButton);

const styles = StyleSheet.create({
  parentBox: {
    paddingTop: 15,
    borderTopWidth: 1,
    // position: 'absolute',
    bottom: 0,
    width: getWidth(Dimensions?.get('window')?.width),
  },
  bottomCTA: {
    marginBottom: 20,
    width: '90%',
    alignSelf: 'center',
    borderRadius: 50,
  },
});
