import React, {useEffect, useMemo} from 'react';
import {ScrollView, StyleSheet} from 'react-native';

import {Box} from '../../components/core/view';
import {Config} from '../../utils/types';
import {CustomText} from '../../components/core/text';
import Header from '../../components/composites/Header/Header';
import HomeHeader from '../../home/component/HomeHeader';
import If from '../../components/conditional/If';
import {PageType} from '../types/profile';
import ProfileHeader from '../components/profileHeader/ProfileHeader';
import ProfileOptions from '../components/ProfileOptions/ProfileOptions';
import ProfileSkeleton from '../components/ProfileSkeleton';
import {getConfig} from '../../utils/appInfo';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {useGetPageData} from '../hooks/profile';

function Profile() {
  const [getPageData, pageDataState] = useGetPageData();
  const cpVersion = useAppSelector(
    gbState => gbState.home.codepushLatestVersion,
  );

  const pageData = pageDataState.data ?? [];
  const headerData = pageData[0]?.headerData;
  const listData = pageData[0]?.listData;

  useEffect(() => {
    getPageData(PageType.ShopperUserProfilePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVersionTest = useMemo(() => {
    if (cpVersion) return `v ${getConfig(Config.versionName)} (${cpVersion})`;
    return `v ${getConfig(Config.versionName)}`;
  }, [cpVersion]);

  return (
    <Header hideBottomSafeArea hideUploadFile>
      <HomeHeader headerTitle="Profile" nameOnly={true} />
      <Box style={[styles.versionContainer]}>
        <CustomText>{getVersionTest}</CustomText>
      </Box>
      <If condition={pageDataState.loading}>
        <ProfileSkeleton />
      </If>
      <If condition={!pageDataState.loading}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          removeClippedSubviews={true}
          overScrollMode="never">
          <Box style={[styles.parentContainer]}>
            <ProfileHeader headerData={headerData} />
            <Box>
              <ProfileOptions listData={listData} />
            </Box>
          </Box>
        </ScrollView>
      </If>
    </Header>
  );
}

export default React.memo(Profile);

const styles = StyleSheet.create({
  parentContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  versionContainer: {
    marginTop: 20,
    position: 'absolute',
    top: 50,
    right: 10,
    zIndex: 2,
  },
});
