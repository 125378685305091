import {Box} from '@buncha/components/core/view';
import {LatLong} from '@buncha/AboutShift/types';
import React from 'react';

interface MapProps {
  storeCoordinates?: LatLong;
}

const ShopperVanLocationMap = (_props: MapProps) => {
  return <Box />;
};

export default React.memo(ShopperVanLocationMap);
