import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const Cross: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        data-name="Icon ionic-md-close-circle"
        d="M10.375 3.375a7 7 0 1 0 7 7 6.976 6.976 0 0 0-7-7zm3.5 9.52-.98.98-2.52-2.52-2.52 2.52-.98-.98 2.52-2.52-2.52-2.52.98-.98 2.52 2.52 2.52-2.52.98.98-2.52 2.52z"
        transform="translate(-3.375 -3.375)"
      />
    </Icon>
  );
};

export default Cross;
