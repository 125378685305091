import * as React from 'react';

import Svg, {Path, SvgProps} from 'react-native-svg';

const LinkOut = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.25 8.75 17.5 2.5m0 0h-4.167m4.167 0v4.167m0 5v4.166a1.666 1.666 0 0 1-1.667 1.667H4.167A1.667 1.667 0 0 1 2.5 15.833V4.167A1.667 1.667 0 0 1 4.167 2.5h4.166"
    />
  </Svg>
);
export default LinkOut;
