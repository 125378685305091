import {Box, Divider, Skeleton} from 'native-base';
import {H3Text, H5Text, H6Text} from '../../../../components/core/text';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {isWeb, sendSMS} from '../../../../utils/common';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../appStyles/hooks/theme';
import {
  useFetchMaskedNumber,
  useGenerateDynamicTexts,
  useGetBuyerInfoByOrderId,
} from '../../../../itemDetails/hooks/ItemDetails';

import {Analytics} from '@buncha/config/analytics/analytics';
import BottomSheet from '../../../../components/composites/bottomSheet';
import {Buyer} from '../../../../../src/driver/types/common';
import {Buyers} from '../../../../itemDetails/types/itemDetails';
import ChatConfirmation from './ChatConfirmation';
import {CommonScreens} from '@buncha/common/navigation/types';
import {ConversationBuilder} from '@talkjs/react-native';
import {CustomFlatList} from '../../../../components/core/list';
import {EventType} from '@buncha/config/analytics/type';
import {HBox} from '../../../../components/core/view';
import If from '../../../../../src/components/conditional/If';
import {Linking} from 'react-native';
import {MainScreens} from '@buncha/navigation/types';
import {PButton} from '../../../../components/core/Button';
import TemplateCell from './TemplateCell';
import {navigation} from '@buncha/config/navigation';
import {styles} from './style';
import {useRoute} from '@react-navigation/native';

interface ContactBottomSheetProps {
  buyerInfo?: Buyers | Buyer | {firstName: string; phoneNumber: number};
  isOpen: boolean;
  onClose: () => void;
  orderItemId?: number;
  showSMSTemplates: boolean;
  buttonText?: string;
  onButtonClick?: () => Promise<any>;
  type?: string;
  orderId?: number;
  runId?: number;
  conversation?: ConversationBuilder;
  hasSubstitute?: boolean;
}

interface RouteParamsType {
  storeIndex?: string;
  catalogItemId?: number;
  orderItemId?: number;
}

const ContactBottomSheet: React.FC<ContactBottomSheetProps> = props => {
  const theme = useColorThemeType('main');
  const mortise = useFontTheme('mortise');
  const muli = useFontTheme('muli');
  const route = useRoute();
  const routeParams: RouteParamsType | undefined = route?.params;
  const orderItemId = routeParams?.orderItemId || props?.orderItemId;

  const {fetchMaskedNumber, info} = useFetchMaskedNumber();

  const [activeTemplate, setActiveTemplate] = useState<number>();
  const [chatModalText, setChatModalText] = useState('');

  const {loading, generateDynamicTexts, dynamicTexts} =
    useGenerateDynamicTexts();
  const {buyerDetail, buyerChatInfo, buyerLoading, getBuyerInfo} =
    useGetBuyerInfoByOrderId();
  const [buttonLoading, setButtonLoading] = useState(false);
  const buyerInfo = props?.buyerInfo;
  let firstName = buyerInfo?.firstName || buyerDetail?.firstName;
  const lastName = buyerDetail?.lastName;

  const [phoneNumber, setPhoneNumber] = useState(
    Number(buyerInfo?.phoneNumber || buyerDetail?.phoneNumber),
  );
  const {buttonText, onButtonClick, orderId, conversation} = props;
  const type = props.type || 'default';
  const muliFont = useFontTheme('muli');
  const showCustomMessage =
    !buyerChatInfo?.isVisible &&
    !buyerChatInfo?.conversationId &&
    !buyerLoading;

  const placeCallToCustomer = useCallback(() => {
    Linking.openURL(`tel:${phoneNumber}`);
    Analytics.eventWithProps('Place call to customer', {
      type: EventType.cta,
    });
  }, [phoneNumber]);

  const closeChatModal = useCallback(() => {
    setChatModalText('');
  }, []);

  const getNumber = useCallback(async () => {
    await fetchMaskedNumber({
      runId: props?.runId || 0,
      orderId: props?.orderId || 0,
    });
  }, [fetchMaskedNumber, props?.orderId, props?.runId]);

  useEffect(() => {
    getNumber();
  }, []);

  useEffect(() => {
    if (info?.data?.fetchMaskedNumber)
      setPhoneNumber(Number(info?.data?.fetchMaskedNumber));
    else
      setPhoneNumber(
        Number(buyerInfo?.phoneNumber || buyerDetail?.phoneNumber),
      );
  }, [buyerDetail?.phoneNumber, buyerInfo?.phoneNumber, info?.data]);

  const sendTextToCustomer = useCallback(
    (message?: string) => {
      if (conversation) {
        conversation.sendMessage(message || '');
        props.onClose();
      } else if (
        buyerChatInfo?.conversationId &&
        buyerChatInfo?.isVisible &&
        !isWeb()
      ) {
        props.onClose();
        navigation.navigate(MainScreens.Common, {
          screen: CommonScreens.Chat,
          params: {
            conversationId: buyerChatInfo?.conversationId,
            name: `${firstName || ''} ${lastName || ''}`,
            message: message,
            phoneNumber,
            runId: props?.runId,
          },
        });
      } else {
        sendSMS(phoneNumber, message || ' ');
      }
      Analytics.eventWithProps('Send text to customer', {
        type: EventType.cta,
      });
      closeChatModal();
    },

    [
      buyerChatInfo?.conversationId,
      buyerChatInfo?.isVisible,
      closeChatModal,
      conversation,
      firstName,
      lastName,
      phoneNumber,
      props,
    ],
  );

  const handleButtonClick = useCallback(async () => {
    if (!onButtonClick) return;
    setButtonLoading(true);
    await onButtonClick();
    setButtonLoading(false);
  }, [onButtonClick]);

  const openChatModal = useCallback((message?: string) => {
    if (!message) return;
    setChatModalText(message);
  }, []);

  const renderText = useCallback(
    ({item, index}: {item: string; index: number}) => {
      return (
        <TemplateCell
          active={index === activeTemplate}
          makeActive={() => setActiveTemplate(index)}
          text={item}
          sendTextToCustomer={openChatModal}
        />
      );
    },
    [activeTemplate, openChatModal],
  );

  const renderMessages = useMemo(() => {
    if (loading) return <H5Text>Loading</H5Text>;
    if (!dynamicTexts?.length) return;
    return (
      <CustomFlatList
        style={[styles.flatlist]}
        data={dynamicTexts}
        renderItem={renderText}
      />
    );
  }, [dynamicTexts, loading, renderText]);

  useEffect(() => {
    generateDynamicTexts(orderItemId, type, orderId, props?.runId || 0);
    if (orderId && !buyerInfo) getBuyerInfo(props?.runId || 0, orderId);
    Analytics.eventWithProps('Generate Dynamic Texts', {
      type: EventType.api,
      orderId: orderId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItemId]);

  return (
    <BottomSheet open={props?.isOpen} onClose={props?.onClose}>
      <ChatConfirmation
        closeModal={closeChatModal}
        message={chatModalText}
        open={Boolean(chatModalText)}
        sendTextToCustomer={sendTextToCustomer}
      />
      <Box style={styles.parentBox}>
        <If condition={props?.hasSubstitute}>
          <H6Text
            style={[
              styles.textAlign,
              styles.textWarning,
              {
                fontFamily: muli?.[700]?.normal,
                color: theme?.text?.red?.[100],
              },
            ]}>
            This user has substitutions.
          </H6Text>
        </If>
        <H3Text
          style={[
            styles.textAlign,
            {
              fontFamily: mortise?.[700]?.normal,
              color: theme?.text?.blue?.[300],
            },
          ]}>
          Send Quick Message to <If condition={!buyerLoading}>{firstName}</If>
          <If condition={buyerLoading}>
            <Skeleton style={styles.skeleton} />
          </If>
        </H3Text>
        <If condition={!conversation}>
          <H6Text
            style={[
              styles.textAlign,
              styles.subtitleText,
              {
                fontFamily: muli?.[700]?.normal,
                color: theme?.text?.gray?.[100],
              },
            ]}>
            To contact the customer, select one of the following options.
          </H6Text>
          <PButton
            title={'Call'}
            style={[styles.callCTA]}
            onPress={placeCallToCustomer}
          />
          <Divider />
          <If condition={showCustomMessage}>
            <H6Text style={[styles.textAlign, styles.subtitleText]}>
              Add a custom message
            </H6Text>
            <PButton
              title={'Custom message'}
              style={[styles.textCTA, {borderColor: theme?.text?.green?.[100]}]}
              bgColor={theme?.background?.white?.[100]}
              titleColor={theme?.text?.green?.[100]}
              onPress={() => sendTextToCustomer()}
            />
          </If>
        </If>
        <If condition={loading}>
          <H6Text style={[styles.textAlign, styles.subtitleText]}>
            Loading...
          </H6Text>
        </If>
        <If condition={props?.showSMSTemplates}>
          <If condition={dynamicTexts?.length}>
            <If condition={!conversation}>
              <H6Text style={[styles.textAlign, styles.subtitleText]}>
                or choose from the template
              </H6Text>
            </If>
            {renderMessages}
          </If>
        </If>
        <If condition={buttonText && onButtonClick}>
          <HBox>
            <PButton
              title={'Cancel'}
              style={[styles.actionButton]}
              titleStyle={[
                styles.buttonSize,
                {
                  fontFamily: muliFont[600].normal,
                },
              ]}
              titleColor={theme?.text?.blue?.[300]}
              borderWidth={0.5}
              borderColor={theme?.text?.blue?.[300]}
              bgColor={theme?.background.white[100]}
              onPress={props.onClose}
            />
            <PButton
              title={buttonText}
              style={[styles.actionButton]}
              titleStyle={[
                styles.buttonSize,
                {
                  fontFamily: muliFont[700].normal,
                },
              ]}
              isLoading={buttonLoading}
              changeStyleOnLoading
              loadingText={'Loading'}
              onPress={handleButtonClick}
            />
          </HBox>
        </If>
      </Box>
    </BottomSheet>
  );
};

export default React.memo(ContactBottomSheet);
