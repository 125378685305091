import {
  FINISH_RUNNER_TUTORIAL,
  START_RUNNER_TUTORIAL,
} from '@buncha/config/graphQL/documentNode/mutation';
import {
  GetItemStatusCountResponse,
  TutorialName,
} from '@buncha/batchDetails/types';

import {GET_ITEM_STATUS_COUNT} from '@buncha/config/graphQL/documentNode/queries';
import {OrderItemStatus} from '@buncha/common/types/common';
import {StartTutorialResponse} from '../types';
import {ToastType} from '@buncha/components/composites/notification/type';
import {UserTypes} from '@buncha/onBoarding/types';
import {gqlService} from '@buncha/config/graphQL';
import {useState} from 'react';
import {useToastMessage} from '@buncha/components/composites/notification';

export function useStartRunnerTutorial() {
  const [loading, setLoading] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Alert);

  const startRunnerTutorial = async (userType: UserTypes) => {
    setLoading(true);
    try {
      const res = await gqlService?.mutation<StartTutorialResponse>({
        mutation: START_RUNNER_TUTORIAL,
        variables: {userType},
        fetchPolicy: 'network-only',
      });
      return res?.data?.startTutorialForRunner;
    } catch (err: any) {
      showErrorToast(err.message);
    } finally {
      setLoading(false);
    }
  };

  return {loading, startRunnerTutorial};
}

export function usePackedItemCount() {
  const [showErrorToast] = useToastMessage(ToastType.Alert);
  const [countLoading, setLoading] = useState(false);

  const getPackedItemsCount = async (runId: number) => {
    setLoading(true);
    try {
      const response = await gqlService?.query<GetItemStatusCountResponse>({
        query: GET_ITEM_STATUS_COUNT,
        variables: {
          runId: runId,
          itemStatuses: [OrderItemStatus.packed, OrderItemStatus.notPacked],
        },
        fetchPolicy: 'network-only',
      });
      setLoading(false);
      return response?.data?.getItemStatusCount;
    } catch (error: any) {
      showErrorToast(error.message);
      setLoading(false);
    }
  };

  return {getPackedItemsCount, countLoading};
}

export function useFinishTutorialForRunner() {
  const [tutorialLoading, setLoading] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Alert);

  const finishTutorialForRunner = async (name: TutorialName) => {
    setLoading(true);
    try {
      await gqlService?.mutation({
        mutation: FINISH_RUNNER_TUTORIAL,
        variables: {name},
        fetchPolicy: 'network-only',
      });
    } catch (err: any) {
      showErrorToast(err.message);
    } finally {
      setLoading(false);
    }
  };

  return {tutorialLoading, finishTutorialForRunner};
}
