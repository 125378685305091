import {OrderItem} from './ItemScanner';
import {OrderItem as OrderItemHome} from '@buncha/home/types';

/* eslint-disable no-shadow */
export enum SubstitutionChoice {
  byShopper = 'byShopper',
  byBuyer = 'byBuyer',
  noSubstitution = 'noSubstitution',
  none = 'none',
}

export enum SubstitutionMode {
  approvedThroughApp = 'approvedThroughApp',
  approvedOverCallAfterRequestSend = 'approvedOverCallAfterRequestSend',
  approvedOverCallBeforeRequestSend = 'approvedOverCallBeforeRequestSend',
}

export interface ItemLocationInformation {
  aisle: string;
  catalogItemId: number;
  esIndex: string;
  foundHere: string;
  id: number;
  itemForReplacement: any;
  locationImage: string;
  orderItemId: number;
  price: number;
  reportMissing: number;
  storeContext: string;
  upcOrderItemId: number;
}

export interface SubstitutionOptions {
  id: number;
  name: string;
}

export interface Buyers {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  orderItemId: number;
  orderId: number;
  quantity: number;
  substitutedBy: any;
  hasSubstitutionRequest: boolean;
  substitutionChoice: SubstitutionChoice;
  substitutionOptions: SubstitutionOptions[];
  updatedPricePerQuantity: number;
  updatedQuantity: number;
  order: {
    buyerChatInfo: {
      isVisible: boolean;
      conversationId: string;
      expiresIn: string;
    };
    buyer?: {
      isVip?: boolean;
    };
  };
  itemNote: string | null;
  substitutionNote: string;
}

export interface State {
  item?: OrderItem;
  buyersForItem: Buyers[];
  reasonsForDropping: any[];
  itemNotes: string;
  hasAnyBuyerInstructions: boolean;
  itemLocationInformation?: ItemLocationInformation;
  orderItemIds: number[];
  constants: any;
}

export interface StoreForItemDetail {
  id: number;
  elasticSearchIndex: string;
}

export interface RunForItemDetail {
  id: number;
  store: StoreForItemDetail;
}

export interface GetRunForItemDetailResponse {
  getRun: RunForItemDetail;
}

export interface DynamicTextsProps {
  buttonText?: string;
  onButtonClick?: () => any;
  type?: string;
  orderId?: number;
}

export interface GetBuyersForItemsResponse {
  itemWithBuyersAndSub: Buyers[];
}

export interface BuyerChatInfo {
  conversationId: string;
  isVisible: boolean;
}

export interface FetchMaskedNumberVariable {
  orderId: number;
  runId: number;
}

export interface FetchMaskedNumberResponse {
  fetchMaskedNumber: string | null;
}

export interface UpdateOrderItemQtyVariables {
  orderItemId: number;
  itemQuantity: number;
}

export interface UpdateOrderItemQuantityResponse {
  updateOrderItemQuantity: OrderItemHome;
}
