// eslint-disable-next-line no-shadow
export enum OnBoardingScreens {
  Login = 'Login',
  SignUp = 'SignUp',
  Onboarding = 'Onboarding',
  Lockout = 'Lockout',
  Splash = 'Splash',
  VerificationCode = 'VerificationCode',
  ShopperDriverApplication = 'ShopperDriverApplication',
  ShopperDriverForm = 'ShopperDriverForm',
}

export interface RetryConfig {
  apiDelay: number;
  maxApiCount: number;
}
