import {Dimensions, StyleSheet} from 'react-native';
import React, {useMemo} from 'react';

import {Box} from '../../../../../src/components/core/view';
import BuyerDetailCell from '../../common/buyerDetailCell';
import DeliveryInstructions from '../../common/nameAndAddressDetails/deliveryInstructions';
import DeliveryNote from '../../common/nameAndAddressDetails/deliveryNote';
import {Divider} from 'native-base';
import DriverOrderCellFooter from './driverOrderCellFooter';
import If from '@buncha/components/conditional/If';
import OrderStatusCell from './orderStatusCell';
import {OrdersForDelivery} from '../../../../../src/driver/types/driverOrders';
import {getWidth} from '../../../../../src/utils/common';
import {useColorThemeType} from '../../../../../src/appStyles/hooks/theme';

interface DriverOrdersCellProps {
  order: OrdersForDelivery;
  isFirstItem: boolean;
}

const DriverOrderCell: React.FC<DriverOrdersCellProps> = props => {
  const theme = useColorThemeType('main');
  const order = props?.order;
  const buyerDetails = order?.buyer;
  const buyerAddress = order?.deliveryLocation?.userAddress;
  const deliveryLocationStatus = order?.deliveryLocation?.deliveryStatus || '';
  const lat = buyerAddress?.lat || 0;
  const lon = buyerAddress?.lon || 0;
  const orderId = order?.id || 0;
  const runId = props?.order?.run?.id || 0;
  const deliveryInstruction =
    order?.deliveryInstruction || buyerAddress?.deliveryInstruction || '';
  const deliveryNote = order?.deliveryLocation?.deliveryNote;

  const getOpecity = useMemo(() => {
    if (props?.isFirstItem) return 1;
    return 0.5;
  }, [props?.isFirstItem]);

  return (
    <Box
      style={[
        styles.parentBox,
        {borderColor: theme?.background?.gray?.[100], opacity: getOpecity},
      ]}>
      <OrderStatusCell
        deliveryLocationStatus={deliveryLocationStatus}
        runId={runId}
        orderId={order?.id}
      />
      <BuyerDetailCell
        buyerDetails={buyerDetails}
        orderId={orderId}
        buyerAddress={buyerAddress}
        runId={runId}
      />
      <If condition={deliveryInstruction}>
        <DeliveryInstructions
          deliveryInstruction={deliveryInstruction}
          dropPoint={buyerAddress?.dropPoint}
        />
        <Divider />
      </If>
      <If condition={deliveryNote}>
        <DeliveryNote deliveryNote={deliveryNote} />
        <Divider />
      </If>
      <DriverOrderCellFooter
        lat={lat}
        lon={lon}
        orderId={orderId}
        deliveryLocationStatus={deliveryLocationStatus}
        runId={runId}
        isFirstItem={props?.isFirstItem}
      />
    </Box>
  );
};

export default React.memo(DriverOrderCell);

const styles = StyleSheet.create({
  parentBox: {
    borderWidth: 1,
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 20,
    width: getWidth(Dimensions.get('window').width * 0.88),
    alignSelf: 'center',
    marginVertical: 10,
  },
});
