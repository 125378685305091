import {StyleSheet} from 'react-native';
import {getWidth} from '../../../utils/common';

export const styles = StyleSheet.create({
  headerText: {
    marginBottom: 5,
  },
  buttonContainer: {
    marginHorizontal: 15,
  },
  CTATop: {
    marginBottom: 10,
    borderRadius: 50,
  },
  CTABottom: {
    marginBottom: 10,
    borderRadius: 50,
  },
  CTAIconTop: {
    marginRight: 15,
  },
  modalBox: {
    width: getWidth(350),
  },
});
