/* eslint-disable no-console */

import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  InMemoryCache,
  MutationOptions,
  NormalizedCacheObject,
  QueryOptions,
  SubscriptionOptions,
  createHttpLink,
  split,
} from '@apollo/client';
import {Config, Source} from '../../utils/types';
import {Linking, Platform} from 'react-native';
import {NetworkConfiguration, SessionIdOperation} from './type';
import {
  OnBoardingScreens,
  RetryConfig,
} from '../../onBoarding/navigation/types';
import {getDevice, getModel, getSystemVersion} from 'react-native-device-info';
import {getRandomNumbers, isWeb} from '@buncha/utils/common';

import {Analytics} from '../analytics/analytics';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {ErrorSource} from '../Error/service';
import {StorageKeys} from '../storage/type';
import TypePolicies from './typePolicy';
import {WebSocketLink} from '@apollo/client/link/ws';
import {appInfo} from '../../utils';
import {dispatch} from '../redux';
import {errorService} from '../Error';
import {getMainDefinition} from '@apollo/client/utilities';
import {getUUID} from '@buncha/utils/appInfo';
import {navigation} from '../navigation';
import {notification} from '../notification';
import {onBoardingActions} from '../../onBoarding/reducer';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {storage} from '../storage';
import {viewToast} from '../../components/composites/notification';

let gqlService: GraphQLService | undefined;
export class GraphQLService {
  private readonly client!: ApolloClient<NormalizedCacheObject>;

  private readonly url!: string;

  static alreadyNavigate = false;

  loginToken = '';

  retryConfig: RetryConfig | undefined;

  wToken = '';

  sessionId = '';

  user = '';

  currentUser: number | undefined;

  constructor(urlpath: string) {
    const isWebMode = isWeb();
    const webENV =
      process.env.REACT_APP_WEB_ENV ?? 'https://staging.gobuncha.com/graphql';
    const url = urlpath.trim();
    this.url = url;
    const policy = new TypePolicies();
    const cache = new InMemoryCache({
      typePolicies: policy.getTypePolicy(),
    });

    const httpLink = GraphQLService.initHttpLink(isWebMode ? webENV : url);

    if (!this.sessionId) this.setSessionId(SessionIdOperation.createNew);

    const authLink = setContext(async (_, {headers}) => {
      const token = this.loginToken;
      const wToken = this.wToken;
      const authorization = token ? `Bearer ${token}` : '';

      return {
        headers: {
          ...headers,
          authorization: authorization,
          sessionId: this.sessionId,
          version: appInfo.getConfig(Config.versionName),
          'device-id': await getUUID(),
          'os-version': getSystemVersion(),
          'mobile-device':
            Platform.OS === 'android' ? await getDevice() : getModel(),
          'w-token': wToken,
          source:
            typeof window?.location !== 'undefined' &&
            window.location &&
            window.location.origin.includes('merchant')
              ? Source.merchant
              : Source.fulfillment,
          'action-by': this?.user,
        },
      };
    });

    const link = ApolloLink.from([this.createErrorLink(), authLink, httpLink]);

    this.client = new ApolloClient({
      cache: cache,
      link: link,
      name: appInfo.getOs(),
      version: appInfo.getConfig(Config.versionName),
      queryDeduplication: false,
      connectToDevTools: appInfo.showDevTools(),
      assumeImmutableResults: true,
    });
  }

  getUrl() {
    return this.url;
  }

  setCurrentUser(userId: number | undefined) {
    this.currentUser = userId;
  }

  async updateRetryData() {
    const data = await storage.getItem(StorageKeys.retryConfig);
    if (data) this.setRetryConfigData(JSON.parse(data));
  }

  setSessionId(sessionIdOp: SessionIdOperation) {
    switch (sessionIdOp) {
      case SessionIdOperation.createNew:
        this.sessionId = getRandomNumbers().toString(32).slice(2);
        break;
      case SessionIdOperation.clearSessionId:
        this.sessionId = '';
        break;
      default:
    }
  }

  static createCombineLink(wsLink: WebSocketLink, httpLink: ApolloLink) {
    return split(
      ({query}) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    );
  }

  static initHttpLink(url: string) {
    return createHttpLink({
      uri: url,
    });
  }

  createErrorLink() {
    return onError(data => {
      const {graphQLErrors, networkError} = data;
      let unauthenticated = false;
      if (graphQLErrors)
        for (let err of graphQLErrors)
          if (err.extensions.code === 'UNAUTHENTICATED') unauthenticated = true;
          else console.log('err ===> ', networkError, err);

      if (unauthenticated) {
        this.handleUnauthenticated();
      } else {
        if (networkError) console.log(`[Network error]: ${networkError}`);
        errorService.report(data, ErrorSource.graphql);
      }
    });
  }

  getClient() {
    return this.client;
  }

  handleUnauthenticated() {
    dispatch(onBoardingActions.clearState());
    const onBoardingScreens = [
      OnBoardingScreens.Onboarding,
      OnBoardingScreens.Splash,
      OnBoardingScreens.Login,
    ];
    const routeName = navigation.getCurrenRouteName();
    const hideToast = onBoardingScreens.includes(
      routeName as OnBoardingScreens,
    );
    if (hideToast) {
      if (routeName !== OnBoardingScreens.Splash) return null;
      if (GraphQLService.alreadyNavigate) return;
      GraphQLService.alreadyNavigate = true;
      return;
    }
    //show Toast for unauthentication;
    viewToast(
      {
        title: 'Please Login Again',
        description:
          'Your current app session has expired. Please login again to continue.',
        placement: 'top',
      },
      4000,
    );
  }

  async setLoginCredential(loginToken?: string) {
    if (loginToken !== this.loginToken && loginToken) {
      this.loginToken = loginToken;
      try {
        await storage.setItem(StorageKeys.loginToken, loginToken);
        notification.requestUserPermission();
      } catch (error: any) {
        console.log('error ===>', error);
      }
    }
  }

  setRetryConfigData(config: RetryConfig | any) {
    if (!config) return;
    this.retryConfig = isNaN(config?.apiDelay) ? JSON.parse(config) : config;

    try {
      storage.setItem(StorageKeys.retryConfig, JSON.stringify(config));
    } catch (error: any) {
      console.log('error ==>', error);
    }
  }

  async setWhenIWorkLoginCredential(wToken: string) {
    this.wToken = wToken;
    try {
      await AsyncStorage.setItem('@wToken', wToken);
    } catch (error: any) {
      console.log('error', error);
      console.log('error ==>', error);
    }
  }

  async getLoginToken() {
    try {
      const urlData = (await Linking.getInitialURL()) || '';
      const token: string[] = urlData.split('tn=');
      let loginToken = (await storage.getItem(StorageKeys.loginToken)) || '';
      const hasTokenParam = token?.length > 1 && token[1].length;
      if (hasTokenParam) loginToken = token[1];
      dispatch(onBoardingActions.setLoginToken(loginToken));
    } catch (error: any) {
      console.log('error ==>', error);
    }
  }

  async setWhenIWorkToken() {
    try {
      const wToken = (await AsyncStorage.getItem('@wToken')) || '';
      dispatch(onBoardingActions.setWhenIWorkToken(wToken));
    } catch (error: any) {
      console.log('error', error);
      console.log('error ==>', error);
    }
  }

  sendEvent(info: any, error: any, count: number) {
    const apiData: any = info?.query?.definitions?.[0];
    Analytics.eventWithProps(`error_${count + 1}time_api_called`, {
      apiSource: 'shopper',
      apiName: apiData?.name?.value,
      // initialError: JSON.stringify(error),
      retryCount: count + 1,
      apiPayload: info?.variables,
      type: 'Error',
      error: error?.name,
      errorType: error?.networkError?.name,
      errorCode: error?.networkError?.statusCode,
      errorInfo: JSON.stringify(error),
      oprationName: error?.operation?.operationName,
      variables: JSON.stringify(error?.operation?.variables),
      errorSource: ErrorSource.graphql,
      response: JSON.stringify(error?.response),
      operation: JSON.stringify(error?.operation),
      graphQLErrors: JSON.stringify(error?.graphQLErrors),
      networkError: JSON.stringify(error?.networkError),
    });
    throw new Error(
      'We apologize, but we’re experiencing technical difficulties fetching the data at the moment. Please connect with customer support or operations team.',
    );
  }

  async recursiveQuery<returnType>(
    info: QueryOptions,
    count = 0,
  ): Promise<FetchResult<returnType>> {
    if (!this.retryConfig) await this.updateRetryData();
    const retryCount = (this.retryConfig?.maxApiCount || 1) - 1;
    try {
      const response = await this.client.query<returnType>(info);

      return response;
    } catch (error: any) {
      if (
        error?.networkError &&
        (!error?.networkError?.statusCode ||
          error?.networkError?.statusCode >= 500)
      ) {
        if (count < retryCount) {
          this.retryEvent(info, error, count);
          await new Promise(f => setTimeout(f, this.retryConfig?.apiDelay));
          return await this.recursiveQuery(info, count + 1);
        }
        this.sendEvent(info, error, count);
      }
      if (error.networkError)
        throw new Error(
          'We apologize, but we’re experiencing technical difficulties fetching the data at the moment. Please connect with customer support or operations team.',
        );
      throw error;
    }
  }

  setUser(userId: string) {
    storage.setItem(StorageKeys.user, userId);
    this.user = userId;
  }

  async query<returnType = any>(info: QueryOptions) {
    const response = await this.recursiveQuery<returnType>(info);
    return response;
  }

  watchQuery<returnType = any>(info: QueryOptions) {
    const response = this.client.watchQuery<returnType>(info);
    return response;
  }

  retryEvent(info: any, error: any, count: number) {
    const apiData: any = info?.query?.definitions?.[0];
    Analytics.eventWithProps(`api_retry_error_${count + 1}time_api_called`, {
      apiSource: 'shopper',
      apiName: apiData?.name?.value,
      // initialError: JSON.stringify(error),
      retryCount: count + 1,
      apiPayload: info?.variables,
      type: 'Error',
      error: error?.name,
      errorType: error?.networkError?.name,
      errorCode: error?.networkError?.statusCode,
      errorInfo: JSON.stringify(error),
      oprationName: error?.operation?.operationName,
      variables: JSON.stringify(error?.operation?.variables),
      errorSource: ErrorSource.graphql,
      response: JSON.stringify(error?.response),
      operation: JSON.stringify(error?.operation),
      graphQLErrors: JSON.stringify(error?.graphQLErrors),
      networkError: JSON.stringify(error?.networkError),
    });
  }

  async recursiveMutation<returnType = any>(
    info: MutationOptions,
    count = 0,
  ): Promise<FetchResult<returnType> | null> {
    if (!this.retryConfig) await this.updateRetryData();
    const retryCount = (this.retryConfig?.maxApiCount || 1) - 1;
    try {
      const response = await this.client.mutate<returnType>(info);

      return response;
    } catch (error: any) {
      if (
        error?.networkError &&
        (!error?.networkError?.statusCode ||
          error?.networkError?.statusCode >= 500)
      ) {
        if (count < retryCount) {
          this.retryEvent(info, error, count);
          await new Promise(f => setTimeout(f, this.retryConfig?.apiDelay));
          return await this.recursiveMutation(info, count + 1);
        }
        this.sendEvent(info, error, count);
      }
      if (error.networkError)
        throw new Error(
          'We apologize, but we’re experiencing technical difficulties fetching the data at the moment. Please connect with customer support or operations team.',
        );
      throw error;
    }
  }

  async mutation<returnType = any>(
    info: MutationOptions,
  ): Promise<FetchResult<returnType> | null> {
    const response = await this.recursiveMutation(info);
    return response;
  }

  subscription<returnType = any>(info: SubscriptionOptions) {
    const response = this.client.subscribe<returnType>(info);
    return response;
  }
}

export function initGraphQLService(networkConfig: NetworkConfiguration) {
  const url = appInfo.UrlForNetworkConfiguration(networkConfig);
  gqlService = new GraphQLService(url);
  return gqlService;
}

export {gqlService};
