import React, {useMemo} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import DoubleTick from '@buncha/components/core/icon/DoubleTick';
import {H6Text} from '@buncha/components/core/text';
import {HBox} from '@buncha/components/core/view';
import If from '@buncha/components/conditional/If';
import {StyleSheet} from 'react-native';

interface StepTextProps {
  text: string;
  active: boolean;
  scanned: boolean;
  imageSaved: boolean;
}

const StepText = (props: StepTextProps) => {
  const {text, active, scanned, imageSaved} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');

  const color = useMemo(() => {
    if (scanned || active) return theme?.text.blue[300];
    return theme?.text.gray[100];
  }, [active, scanned, theme?.text.blue, theme?.text.gray]);

  return (
    <HBox style={styles.container}>
      <H6Text
        style={{
          color: color,
          fontFamily: muliFont[700].normal,
        }}>
        {text}
      </H6Text>
      <If condition={scanned}>
        <HBox style={styles.scanned}>
          <DoubleTick />
          <If condition={!imageSaved}>
            <H6Text style={{color: theme?.text.gray[100]}}>Scanned</H6Text>
          </If>
          <If condition={imageSaved}>
            <H6Text style={{color: theme?.text.gray[100]}}>Image saved</H6Text>
          </If>
        </HBox>
      </If>
    </HBox>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 5,
    paddingBottom: 5,
    marginHorizontal: 10,
  },
  scanned: {
    marginLeft: 10,
  },
});

export default React.memo(StepText);
