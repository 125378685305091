import {AssignedShift, Shifts} from '../../../home/types';
import React, {useCallback, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '@buncha/config/redux/hooks';

import {Analytics} from '../../../config/analytics/analytics';
import {Box} from '../../../components/core/view';
import {CustomFlatList} from '../../../components/core/list';
import {Divider} from 'native-base';
import {EventType} from '../../../config/analytics/type';
import {HomeScreens} from '../../../home/navigation/types';
import {MainScreens} from '../../../navigation/types';
import PendingShiftItems from '../PendingShiftItems';
import {Touchable} from '../../../components/core/touchable';
import {navigation} from '../../../config/navigation';
import {onBoardingActions} from '@buncha/onBoarding/reducer';
import {styles} from './styles';

interface PendingShiftListProps {
  pendingShifts: AssignedShift[];
}

function PendingShiftList(props: PendingShiftListProps) {
  const {pendingShifts} = props;
  const appDispatch = useAppDispatch();

  const unreadMemoCount = useAppSelector(
    gState => gState.onboarding.unreadMemoCount,
  );

  const onClick = useCallback(
    (id: string, key: string) => {
      if (unreadMemoCount) {
        appDispatch(onBoardingActions.setUnreadMemoModal(true));
        return;
      }
      Analytics.eventWithProps('Navigate to about shift', {
        type: EventType.cta,
        shiftId: id,
        shiftKey: key,
      });
      navigation.navigate(MainScreens.Home, {
        screen: HomeScreens.AboutShift,
        params: {
          shiftId: id,
          shiftKey: key,
        },
      });
    },
    [appDispatch, unreadMemoCount],
  );

  const renderItem = useCallback(
    ({item, id, key}: {item: Shifts; id: string; key: string}) => {
      return (
        <Touchable onPress={() => onClick(id, key)}>
          <PendingShiftItems shift={item} />
        </Touchable>
      );
    },
    [onClick],
  );

  const separator = useCallback(() => {
    return <Divider style={[styles.divider]} />;
  }, []);

  const renderShifts = useCallback(
    ({item}: {item: AssignedShift}) => {
      return (
        <CustomFlatList
          showsVerticalScrollIndicator={false}
          data={item.shifts}
          renderItem={data =>
            renderItem({item: data.item, id: item.id, key: item.key})
          }
          ItemSeparatorComponent={separator}
        />
      );
    },
    [renderItem, separator],
  );

  useEffect(() => {
    if (pendingShifts?.length)
      Analytics.eventWithProps('Pending Shifts', {
        type: EventType.page,
        pendingShiftsCount: pendingShifts?.length,
      });
  }, [pendingShifts?.length]);

  return (
    <Box>
      <CustomFlatList
        ListHeaderComponent={<Box style={styles.mainContainer} />}
        data={pendingShifts}
        renderItem={renderShifts}
        ItemSeparatorComponent={separator}
        ListFooterComponent={<Box style={styles.mainContainer} />}
      />
    </Box>
  );
}

export default React.memo(PendingShiftList);
