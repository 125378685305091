import {ColorTheme} from '../style/type';
import React from 'react';
import {Theme} from './type';

export const defaultTheme: Theme = {
  colorTheme: ColorTheme.bunchaLight,
  fontTheme: 'muli',
};

export const BunchaTheme = React.createContext(defaultTheme);
