import {Box, HBox} from '@buncha/components/core/view';
import {H6Text} from '@buncha/components/core/text';

import ImageWithQuantityPill from '@buncha/common/components/ImageWithQuantityPill';
import React from 'react';
import {StyleSheet} from 'react-native';
import {Touchable} from '@buncha/components/core/touchable';
import {formatCurrency} from '@buncha/utils/common';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const AddedSubstitution = (props: {onChange: any}) => {
  const {itemLocationData} = useAppSelector(gState => gState.itemScanner);

  const theme = useColorThemeType('main');
  return (
    <HBox style={[styles.parentContainer]}>
      <HBox>
        <ImageWithQuantityPill
          imageLink={itemLocationData?.substituionData?.imageUrl}
          quantity={0}
        />
        <Box style={[styles.typoContainer]}>
          <H6Text
            numberOfLines={1}
            style={[
              styles.itemNameTypo,
              {
                color: theme?.text?.blue[300],
              },
            ]}>
            {itemLocationData?.substituionData?.name}
          </H6Text>
          <H6Text
            // numberOfLines={2}
            style={[
              styles.itemNameTypo,
              {
                color: theme?.text?.blue[300],
              },
            ]}>
            {formatCurrency(itemLocationData?.substituionData?.price)}
          </H6Text>
        </Box>
      </HBox>
      <Touchable onPress={() => props.onChange()}>
        <H6Text
          style={[
            styles.typo,
            {
              color: theme?.text?.green[100],
            },
          ]}>
          Change
        </H6Text>
      </Touchable>
    </HBox>
  );
};

export default React.memo(AddedSubstitution);

const styles = StyleSheet.create({
  parentContainer: {
    width: '85%',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  typoContainer: {
    width: '45%',
    marginLeft: 10,
    marginRight: 15,
    overflow: 'hidden',
  },
  itemNameTypo: {
    textAlign: 'left',
  },
  typo: {
    fontWeight: '800',
  },
});
