import {
  BottomSheetProps,
  UPCItemLocationStatus,
} from '@buncha/itemDetails/types/ItemScanner';
import {Dimensions, StyleSheet, View} from 'react-native';
import {H3Text, H4Text} from '@buncha/components/core/text';
import React, {useCallback, useMemo, useState} from 'react';
import {checkPriceDecimals, getWidth, isWeb} from '@buncha/utils/common';
import font, {scaleFonts} from '@buncha/appStyles/style/font';

import {BottomButton} from '@buncha/components/composites/Button';
import BottomSheetInput from './BottomSheetInput';
import {Box} from 'native-base';
import Fonts from '@buncha/resource/fonts';
import {SpinnerComponent} from '@buncha/components/core/loader';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const PriceBottomSheet = (props: BottomSheetProps) => {
  const {onSheetClose} = props;
  const [price, setPrice] = useState('');
  const {itemLocationData} = useAppSelector(gstate => gstate.itemScanner);

  const width = Number(getWidth(Dimensions.get('screen').width));
  const theme = useColorThemeType('main');

  const onPriceChange = useCallback((text: string) => {
    if (checkPriceDecimals(text))
      if (
        (Boolean(Number(text)) || text.length === 0 || Number(text) === 0) &&
        Number(text) <= 250
      )
        setPrice(text);
  }, []);

  const getDisabledStatus = useMemo(() => {
    return !price.length;
  }, [price.length]);

  const returnBorderColor = useMemo(() => {
    if (price.length) return theme?.text.blue[300];
    return theme?.text?.gray[200];
  }, [price.length, theme?.text.blue, theme?.text?.gray]);

  const priceBoxColor = price.length
    ? theme?.text.blue[300]
    : theme?.text?.gray?.[100];

  const enterItemLocation = useCallback(() => {
    const locationData: UPCItemLocationStatus = {
      ...itemLocationData,
      price: Number(price) * 100,
    };
    props?.onPress(locationData);
    onSheetClose();
  }, [itemLocationData, onSheetClose, price, props]);

  return (
    <Box style={{backgroundColor: theme?.text.white[100]}}>
      <Box style={[styles.headerContainer]}>
        <H3Text
          style={[
            styles.headerText,
            {
              color: theme?.text.blue[300],
              fontFamily: font.fonts['Mortise-X-Bold'],
            },
          ]}>
          Item Price Inquiry
        </H3Text>
        <H4Text style={[styles.subHeaderTypo]}>
          Provide the price at which you purchased the item or specify the price
          of this item.
        </H4Text>
      </Box>

      <View style={[styles.inputContainer]}>
        <BottomSheetInput
          debounce={false}
          colorThemeType="default"
          clearButtonMode="while-editing"
          autoFocus
          placeholder="0.00"
          clearInput={true}
          value={price}
          keyboardType="numeric"
          header="Enter the price"
          leftComponent={
            <H4Text
              style={[
                styles.inputLeftComponent,
                {
                  color: priceBoxColor,
                },
              ]}>
              $
            </H4Text>
          }
          containerStyle={[{borderColor: returnBorderColor}]}
          style={[
            {
              backgroundColor: theme?.background?.white[100],
              fontSize: scaleFonts(16),
              color: theme?.text?.blue[300],
            },
          ]}
          onChangeText={onPriceChange}
        />
        <BottomButton
          style={[
            styles.buttonContainer,
            {width: isWeb() ? width * 0.75 : width * 0.95},
          ]}
          title={'Update price'}
          disabled={getDisabledStatus}
          onPress={enterItemLocation}
          isLoading={false}
          loadingIndicator={<SpinnerComponent />}
        />
      </View>
    </Box>
  );
};

export default React.memo(PriceBottomSheet);

const styles = StyleSheet.create({
  modal: {
    height: 500,
    padding: 20,
  },
  headerText: {
    textAlign: 'left',
    fontSize: scaleFonts(22),
  },
  subHeaderTypo: {
    textAlign: 'left',
    marginTop: 5,
    fontSize: scaleFonts(15),
  },
  textInputContainer: {
    marginHorizontal: 10,
  },
  textInput: {
    backgroundColor: 'transparent',
    ...font.FontSize.fontH4,
  },
  errorMessageContainer: {
    top: 1,
  },
  errorTypo: {
    fontFamily: Fonts.muliSemiBold,
  },
  inputContainer: {
    marginTop: 20,
    // paddingBottom: 20,
  },
  buttonContainer: {
    marginBottom: 0,
  },
  eyeIcon: {
    marginRight: 5,
  },
  headerContainer: {
    marginLeft: 15,
  },
  dividerContainer: {
    marginTop: 5,
    marginBottom: 15,
  },
  inputLeftComponent: {
    paddingLeft: 20,
  },
});
