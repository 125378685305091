import React, {useCallback, useState} from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';

import {Analytics} from '@buncha/config/analytics/analytics';
import BinIcon from '../../../../components/core/icon/BinIcon';
import {Box} from 'native-base';
import DropItemModalBody from '../DropItemModalBody/DropItemModalBody';
import {EventType} from '@buncha/config/analytics/type';
import {H3Text} from '../../../../components/core/text';
import font from '../../../../appStyles/style/font';
import {useColorThemeType} from '../../../../appStyles/hooks/theme';

interface DropItemProps {
  orderItemId: number;
  showContactSheet: Function;
}

const DropItem: React.FC<DropItemProps> = props => {
  const theme = useColorThemeType('main');
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleContactSheet = useCallback(() => {
    Analytics.eventWithProps('Drop item for this user', {
      orderItemId: props?.orderItemId,
      type: EventType.cta,
    });
    props.showContactSheet(openModal);
  }, [openModal, props]);

  return (
    <React.Fragment>
      <DropItemModalBody
        orderItemId={props?.orderItemId}
        onClose={closeModal}
        open={showModal}
      />
      <TouchableOpacity style={[styles.Container]} onPress={handleContactSheet}>
        <BinIcon viewBox="-5 -4 20 20" fill={theme?.background.red[100]} />
        <Box style={[styles.jcc]}>
          <H3Text
            style={[styles.nameTypo, {color: theme?.background.red[100]}]}>
            Remove item from this order
          </H3Text>
        </Box>
      </TouchableOpacity>
    </React.Fragment>
  );
};

export default DropItem;

const styles = StyleSheet.create({
  Container: {
    flexDirection: 'row',
    flexGrow: 1,
    paddingVertical: 10,
  },
  rowFlex: {
    flexDirection: 'row',
  },
  nameTypo: {
    ...font.FontSize.fontH6,
    textAlign: 'left',
  },
  childTypo: {
    ...font.FontSize.fontP10,
  },
  jcc: {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 2,
  },
});
