import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  itemImage: {
    height: 75,
    width: 75,
    overflow: 'hidden',
  },
  quantityArea: {
    marginTop: 48,
    justifyContent: 'center',
  },
});
