import {Box, HBox} from '../../../components/core/view';
import {
  DeliveryLocationStatus,
  TutorialName,
} from '../../../batchDetails/types';
import {H3Text, H4Text} from '../../../components/core/text';
import React, {useCallback} from 'react';

import {Analytics} from '@buncha/config/analytics/analytics';
import {Divider} from 'native-base';
import {EventType} from '@buncha/config/analytics/type';
import {HomeScreens} from '../../../../src/home/navigation/types';
import If from '../../../components/conditional/If';
import {MainScreens} from '../../../../src/navigation/types';
import {SpinnerComponent} from '../../../components/core/loader';
import {StyleSheet} from 'react-native';
import {Touchable} from '../../../../src/components/core/touchable';
import font from '../../../appStyles/style/font';
import {navigation} from '../../../../src/config/navigation';
import {useAppSelector} from '../../../config/redux/hooks';
import {useColorThemeType} from '../../../appStyles/hooks/theme';
import {useFinishTutorialForRunner} from '@buncha/home/hooks/Tutorials';
import {useSetDeliveryLocationStatusOfAssignedOrders} from '../../../batchDetails/hooks/OrderOperation';

interface AssignmentBottmSheetProps {
  runId: number;
  closeSheet: any;
  tutorial: boolean;
  orderIds: number[];
  dispatch: React.Dispatch<any> | null;
}

const AssignmentBottmSheet = (props: AssignmentBottmSheetProps) => {
  const theme = useColorThemeType('main');
  const [setDeliveryLocationStatusOfAssignedOrders, orderState] =
    useSetDeliveryLocationStatusOfAssignedOrders(props?.dispatch);
  const {finishTutorialForRunner, tutorialLoading} =
    useFinishTutorialForRunner();
  const globalState = useAppSelector(gState => gState.home);
  const shiftId = globalState.batchShiftId;
  const shiftKey = globalState.batchShiftKey;

  const tutorial = props.tutorial;

  const closeModal = useCallback(() => {
    props.closeSheet();
    if (tutorial)
      return navigation.navigate(MainScreens.Home, {
        screen: HomeScreens.Tutorials,
      });
    navigation.navigate(HomeScreens.AboutShift, {
      shiftId: shiftId,
      shiftKey: shiftKey,
    });
  }, [props, shiftId, shiftKey, tutorial]);

  const assignOrder = useCallback(async () => {
    if (tutorial) {
      await finishTutorialForRunner(TutorialName.shopperGuide);
      return closeModal();
    }
    await setDeliveryLocationStatusOfAssignedOrders(
      props.runId,
      DeliveryLocationStatus.driverAssigned,
      props.orderIds,
      closeModal,
    );
    Analytics.eventWithProps('Driver assigned', {
      type: EventType.cta,
      deliveryLocationStatus: DeliveryLocationStatus.driverAssigned,
    });
  }, [
    closeModal,
    finishTutorialForRunner,
    props.orderIds,
    props.runId,
    setDeliveryLocationStatusOfAssignedOrders,
    tutorial,
  ]);
  return (
    <Box style={{backgroundColor: theme?.background.white[100]}}>
      <H3Text style={[styles.headerTypo, {color: theme?.text.blue[300]}]}>
        Packing completed !
      </H3Text>
      <HBox style={styles.dividerBox}>
        <Divider style={styles.separatorStyle} />
      </HBox>
      <If condition={orderState.loading || tutorialLoading}>
        <SpinnerComponent />
      </If>
      <If condition={!orderState.loading && !tutorialLoading}>
        <Box style={styles.listContainer}>
          <Touchable style={[styles.listCell]} onPress={assignOrder}>
            <H3Text style={[styles.listTypo, {color: theme?.text.blue[100]}]}>
              Pass task to driver
            </H3Text>
            <If condition={tutorial}>
              <H4Text>(Finish tutorial)</H4Text>
            </If>
          </Touchable>
          <HBox>
            <Divider />
          </HBox>
          <Touchable
            style={[styles.listCell]}
            onPress={() => {
              props?.closeSheet();
            }}>
            <H3Text style={[styles.listTypo, {color: theme?.text.red[100]}]}>
              Cancel
            </H3Text>
          </Touchable>
        </Box>
      </If>
    </Box>
  );
};

export default React.memo(AssignmentBottmSheet);

const styles = StyleSheet.create({
  headerTypo: {
    fontFamily: font.fonts['Mortise-Bold'],
    // textAlign: 'left',
  },
  listCell: {
    padding: 20,
  },
  listTypo: {
    fontFamily: font.fonts.muliSemiBold,
  },
  loaderStyle: {
    marginTop: '10%',
  },
  separatorStyle: {
    marginVertical: 15,
  },
  listContainer: {
    marginHorizontal: 15,
  },
  dividerBox: {marginHorizontal: -10},
});
