import React, {PropsWithChildren} from 'react';

import {View} from 'react-native';
import {ZoomProps} from '.';

export default function Zoom(
  props: PropsWithChildren<ZoomProps>,
): React.ReactNode {
  const {children} = props;

  return <View collapsable={false}>{children}</View>;
}
