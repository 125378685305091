import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../appStyles/hooks/theme';

import {H6Text} from '../../../../../components/core/text';
import {HBox} from '../../../../../components/core/view';
import React from 'react';
import {StyleSheet} from 'react-native';

interface DeliveryInstructionsProps {
  deliveryInstruction: string | undefined;
  dropPoint: string | undefined;
}

const DeliveryInstructions: React.FC<DeliveryInstructionsProps> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const deliveryInstruction = props?.deliveryInstruction || '';
  const dropPoint = props?.dropPoint || '';
  return (
    <HBox style={styles.parentBox}>
      <H6Text
        style={[
          styles.textAlignment,
          {
            color: theme?.text?.blue?.[300],
            fontFamily: muli?.[700]?.normal,
          },
        ]}>
        Delivery Instructions:{' '}
        <H6Text
          style={[
            styles.textAlignment,
            styles.flexBox,
            {
              color: theme?.text?.gray?.[100],
              fontFamily: muli?.[600]?.normal,
            },
          ]}>
          Apt no: {dropPoint}, {deliveryInstruction}
        </H6Text>
      </H6Text>
    </HBox>
  );
};

export default React.memo(DeliveryInstructions);

const styles = StyleSheet.create({
  textAlignment: {
    textAlign: 'left',
  },
  flexBox: {
    flex: 1,
  },
  parentBox: {
    paddingVertical: 10,
    justifyContent: 'flex-start',
  },
});
