import {CustomText, H3Text} from '../../../components/core/text';

import {BottomButton} from '../../../components/composites/Button';
import {Box} from '../../../components/core/view';
import React, {useEffect} from 'react';
import {StyleSheet} from 'react-native';
import font from '../../../appStyles/style/font';
import {getWidth} from '../../../utils/common';
import {useBatchDetailContext} from '../../../batchDetails/hooks/batchDetail';
import {useColorThemeType} from '../../../appStyles/hooks/theme';
import {Analytics} from '@buncha/config/analytics/analytics';
import {EventType} from '@buncha/config/analytics/type';

interface PassOrderModalProps {
  onContinueClick: any;
  title: string;
  subtitle: string;
  ctaTitle: string;
}

const PassOrderModal = (props: PassOrderModalProps) => {
  const theme = useColorThemeType('main');
  const {state} = useBatchDetailContext();

  useEffect(() => {
    Analytics.eventWithProps('Attention Modal', {
      type: EventType.modal,
      orderAssignText: state?.orderAssignee || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box style={[styles.parentContainer]}>
      <Box>
        <H3Text style={[[styles.headerTypo, {color: theme?.text.blue[300]}]]}>
          {props?.title}
        </H3Text>
      </Box>
      <CustomText style={[styles.ChildBody]}>{props.subtitle}</CustomText>
      <Box style={[styles.buttonContainer]}>
        <BottomButton title={props?.ctaTitle} onPress={props.onContinueClick} />
      </Box>
    </Box>
  );
};

export default React.memo(PassOrderModal);

const styles = StyleSheet.create({
  parentContainer: {
    width: getWidth('100%'),
    padding: 20,
  },
  headerTypo: {
    fontFamily: font.fonts['Mortise-Bold'],
  },
  ChildBody: {
    marginTop: 15,
  },
  buttonContainer: {
    marginTop: 10,
  },
});
