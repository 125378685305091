import {StyleSheet} from 'react-native';
import font from '@buncha/appStyles/style/font';

export const styles = StyleSheet.create({
  mainSubContainer: {
    justifyContent: 'flex-start',
    flex: 1,
    marginTop: 10,
  },
  textContainer: {
    marginLeft: 10,
    marginRight: 5,
    flex: 1,
  },
  textHeader: {
    textAlign: 'left',
    flex: 1,
  },
  priceText: {
    textAlign: 'left',
    marginVertical: 5,
  },
  locationTypo: {
    textDecorationLine: 'underline',
  },
  locationContainer: {
    paddingTop: 10,
  },
  editQyt: {
    textAlign: 'left',
    paddingRight: 5,
    fontFamily: font.fonts.muliSemiBold,
  },
  editQtyBox: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
