import {Dimensions, StyleSheet} from 'react-native';

import {getWidth} from '../../../../utils/common';

const {width} = Dimensions.get('window');

const SPACING = 5;
const ITEM_LENGTH = width * 0.8; // Item is a square. Therefore, its height and width are of the same length.
const BORDER_RADIUS = 20;

export const styles = StyleSheet.create({
  container: {backgroundColor: 'white'},
  child: {
    width: getWidth(width - 200),
    justifyContent: 'center',
    marginRight: 40,
    height: 450,
  },
  itemContent: {
    marginRight: 5,
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: BORDER_RADIUS + SPACING * 2,
    zIndex: 2,
  },
  itemImage: {
    width: getWidth('100%'),
    height: ITEM_LENGTH,
    borderRadius: BORDER_RADIUS,
  },
  imageCaptionContainer: {
    width: getWidth(width),
    flexDirection: 'row',
    justifyContent: 'center',
  },
  pillBody: {
    flexDirection: 'row',
    position: 'absolute',
    right: 0,
    top: 40,
    zIndex: 1,
  },
  bodyContainer: {
    padding: 10,
    paddingHorizontal: 20,
    flex: 1,
  },
  contentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  childContainer: {marginHorizontal: 20},
});
