import {View, ViewStyle} from 'react-native';

import {BoxProps} from './type';
import If from '../../conditional/If';
import React from 'react';
import {SpinnerComponent} from '../loader';

const Box: React.FC<BoxProps<View>> = React.forwardRef((props, ref) => {
  const defaultStyle: ViewStyle = {
    backgroundColor: 'transparent',
  };
  const propsToSpread = {...props};
  propsToSpread.style = [defaultStyle, props.style];
  const loadingSkeleton = props.loadingSkeleton || <SpinnerComponent />;

  if (props.loading) return <React.Fragment>{loadingSkeleton}</React.Fragment>;
  return (
    <View ref={ref} {...propsToSpread}>
      <If condition={!props?.loading}>{props.children}</If>
    </View>
  );
});

export default React.memo(Box);
