import {Config} from '../../utils/types';
import SmartlookClient from 'smartlook-client';
import {getConfig} from '../../utils/appInfo';
import isEmpty from 'lodash/isEmpty';

class Smartlook {
  setupAndStartRecording() {
    const apiKey = getConfig(Config.smartlookKey);
    SmartlookClient.init(apiKey);
    SmartlookClient.record({numbers: true});
  }

  setUserIdentity(id: string, userInfo?: undefined | any): void {
    SmartlookClient.identify(id, userInfo);
  }

  trackCustomEvent(eventName: string, properties: object = {}): void {
    if (!isEmpty(properties)) SmartlookClient.track(eventName, {...properties});
    else SmartlookClient.track(eventName, {});
  }
}

const smartLook = new Smartlook();

export {smartLook};
