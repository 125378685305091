import React, {useCallback} from 'react';

import {Analytics} from '../../../src/config/analytics/analytics';
import {BackHeaderLeft} from '@buncha/common/components/BackHeaderLeft';
import {Box} from '../../../src/components/core/view';
import {DriverScreens} from '../navigation/type';
import DriverTabs from '../components/driverOrders/driverTabs';
import Header from '../../../src/components/composites/Header/Header';
import {HomeScreens} from '../../../src/home/navigation/types';
import {MainScreens} from '@buncha/navigation/types';
import MapOverviewIcon from '../../../src/components/core/icon/MapOverviewIcon';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import ScreenHeader from '../../common/components/screenHeader';
import {StyleSheet} from 'react-native';
import {Touchable} from '../../../src/components/core/touchable';
import {isWeb} from '@buncha/utils/common';
import {navigation} from '../../../src/config/navigation';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {useColorThemeType} from '../../../src/appStyles/hooks/theme';

type Props = NativeStackScreenProps<any, any>;

const DriverOrders: React.FC<Props> = () => {
  const theme = useColorThemeType('main');

  const navigateToMapOverview = useCallback(() => {
    navigation.navigate(HomeScreens.Driver, {
      screen: DriverScreens.MapOverview,
    });
    Analytics.eventWithProps('Navigate to map overview page', {
      type: 'CTA',
      userType: 'driver',
    });
  }, []);

  const globalState = useAppSelector(gState => gState.home);
  const shiftId = globalState.batchShiftId;
  const shiftKey = globalState.batchShiftKey;

  const goBack = useCallback(() => {
    navigation.navigate(MainScreens.Home, {
      screen: HomeScreens.AboutShift,
      params: {
        shiftId: shiftId,
        shiftKey: shiftKey,
      },
    });
  }, [shiftId, shiftKey]);

  return (
    <Header
      headerRightComponent={
        !isWeb() && (
          <Touchable onPress={navigateToMapOverview} style={styles.touchable}>
            <MapOverviewIcon
              fill={theme?.icon?.blue?.[300]}
              viewBox=" 0 0 20 20"
            />
          </Touchable>
        )
      }
      headerColor={theme?.background?.blue?.[300]}
      headerLeftComponent={<BackHeaderLeft onClick={goBack} />}>
      <ScreenHeader title={'Tasks'} />
      <Box style={styles.parentBox}>
        <DriverTabs />
      </Box>
    </Header>
  );
};

export default DriverOrders;

const styles = StyleSheet.create({
  parentBox: {
    paddingHorizontal: 20,
    flex: 1,
  },
  webHeader: {
    padding: 30,
  },
  touchable: {
    marginRight: 20,
  },
});
