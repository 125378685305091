import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  divider: {
    marginVertical: 15,
  },
  actionButton: {
    borderRadius: 50,
    marginTop: 10,
  },
  deliveryBox: {
    marginTop: 5,
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '90%',
  },
  text: {textAlign: 'left', marginHorizontal: 10},
  loader: {marginVertical: 20},
});
