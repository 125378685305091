import React, {useCallback} from 'react';
import {
  navigateVanLoadActions,
  useNavigateVanLoadContext,
} from '@buncha/batchDetails/hooks/NavigateVanLoad';

import {Box} from '@buncha/components/core/view';
import {CustomFlatList} from '@buncha/components/core/list';
import OrderListCell from './OrderListCell';
import {OrderListStyle} from './styles';
import {OrdersForShopper} from '@buncha/batchDetails/types';

interface OrderListProps {
  orders: OrdersForShopper[];
}

const OrderList = (props: OrderListProps) => {
  const {dispatch} = useNavigateVanLoadContext();

  const checkBoxHandler = useCallback(
    (orderId: number, hasChecked: boolean) => {
      if (dispatch)
        dispatch(
          navigateVanLoadActions.setBagCountUpdateStatus({
            orderId: orderId,
            isBagCountUpdated: hasChecked,
          }),
        );
    },
    [dispatch],
  );

  const renderItem = useCallback(
    ({item}: {item: OrdersForShopper; index: number}) => {
      return <OrderListCell order={item} checkBoxHandler={checkBoxHandler} />;
    },
    [checkBoxHandler],
  );

  return (
    <Box style={OrderListStyle.flexBox}>
      <CustomFlatList
        data={props?.orders}
        renderItem={renderItem}
        style={OrderListStyle.mainContainer}
      />
    </Box>
  );
};

export default React.memo(OrderList);
