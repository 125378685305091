import {CustomText, H5Text} from '@buncha/components/core/text';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  useGetRunforSeaSpider,
  useNavigateVanLoadReducer,
} from '../hooks/NavigateVanLoad';

import {Analytics} from '@buncha/config/analytics/analytics';
import {BackHeaderLeft} from '@buncha/common/components/BackHeaderLeft';
import {BottomButton} from '@buncha/components/composites/Button';
import {Box} from '@buncha/components/core/view';
import {DeliveryLocationStatus} from '../types';
import {Divider} from 'native-base';
import {EventType} from '@buncha/config/analytics/type';
import Header from '@buncha/components/composites/Header/Header';
import {HomeScreens} from '@buncha/home/navigation/types';
import {MainScreens} from '@buncha/navigation/types';
import {NavigateVanLoadContext} from '../context/NavigateVanLoad';
import OrderList from '../component/VanLoad/OrderList/OrderList';
import ShopperVanLocationMap from '../component/VanLoad/ShopperVanLocationMap';
import {SpinnerComponent} from '@buncha/components/core/loader';
import {StyleSheet} from 'react-native';
import {UserStub} from '@buncha/onBoarding/types';
import VanLoadHeader from '../component/VanLoad/VanLoadHeader';
import {isEmpty} from 'lodash';
import {navigation} from '@buncha/config/navigation';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {useFontTheme} from '@buncha/appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';
import {useSetDeliveryLocationStatusOfAssignedOrders} from '../hooks/OrderOperation';

const NavigateVanLoadPage = () => {
  const [state, dispatch] = useNavigateVanLoadReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);
  const {getRunForSeaSpider, fetchingRuns} = useGetRunforSeaSpider(dispatch);
  const [driverNames, setDriverNames] = useState('');
  const [driverList, setDriverList] = useState<UserStub[]>([]);
  const muliFont = useFontTheme('muli');

  const route = useRoute();

  const masterRun = state?.run?.masterRun;
  const masterStore = masterRun?.store;
  const storeLatLon = masterStore?.getLatLongFromAddress;
  const ordersForShopper = state?.run?.ordersForShopper;

  const routeParams: {runId?: number} | undefined = route.params;
  const runId = routeParams?.runId || 0;

  const globalState = useAppSelector(gState => gState.home);
  const shiftId = globalState.batchShiftId;
  const shiftKey = globalState.batchShiftKey;

  const getOrderIds = useMemo(() => {
    const orderIds: number[] = [];
    if (ordersForShopper?.length)
      ordersForShopper.forEach(order => orderIds.push(order.id));
    return orderIds;
  }, [ordersForShopper]);

  const [setDeliveryLocationStatusOfAssignedOrders, orderState] =
    useSetDeliveryLocationStatusOfAssignedOrders(null);

  const onPressHandler = useCallback(async () => {
    await setDeliveryLocationStatusOfAssignedOrders(
      runId,
      DeliveryLocationStatus.awaitingHandover,
      getOrderIds,
      () => navigation.goBack(),
    );
    navigation.navigate(HomeScreens.AboutShift, {
      shiftId: shiftId,
      shiftKey: shiftKey,
    });
  }, [
    getOrderIds,
    runId,
    setDeliveryLocationStatusOfAssignedOrders,
    shiftId,
    shiftKey,
  ]);

  useEffect(() => {
    if (runId) getRunForSeaSpider(runId);
    Analytics.eventWithProps('Navigate To Van Load Screen', {
      type: EventType.page,
      runId: runId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId]);

  useEffect(() => {
    if (state?.run) getMultipleDrivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.run]);

  const getMultipleDrivers = useCallback(() => {
    if (ordersForShopper?.length) {
      const drivers: UserStub[] = [];
      let driverName = '';

      if (ordersForShopper.length === 1) {
        const secondaryAssignee =
          ordersForShopper[0].orderAssignee.assigneeSecondary;
        if (secondaryAssignee) {
          driverName = `${secondaryAssignee?.firstName} ${secondaryAssignee?.lastName}`;
          drivers.push(secondaryAssignee);
        }
      } else {
        ordersForShopper.forEach(order => {
          const assignee = order.orderAssignee;
          const secondaryAssignee = assignee?.assigneeSecondary;

          if (
            secondaryAssignee &&
            !drivers.some(driver => driver.id === secondaryAssignee.id)
          ) {
            drivers.push(secondaryAssignee);
            const name = `${secondaryAssignee.firstName} ${secondaryAssignee.lastName}, `;
            driverName = driverName.concat(name);
          }
        });
      }

      // Remove the trailing comma if it exists
      if (driverName.endsWith(', ')) driverName = driverName.slice(0, -2);

      setDriverNames(driverName || '');
      setDriverList(drivers);
    }
  }, [ordersForShopper]);

  const areAllValuesTrue = useCallback(
    (obj: {[key: number]: boolean}): boolean => {
      if (isEmpty(obj)) return false;
      return Object.values(obj).every(value => value === true);
    },
    [],
  );

  const goBack = useCallback(() => {
    navigation.navigate(MainScreens.Home, {
      screen: HomeScreens.AboutShift,
      params: {
        shiftId: shiftId,
        shiftKey: shiftKey,
      },
    });
  }, [shiftId, shiftKey]);

  return (
    <NavigateVanLoadContext.Provider value={provider}>
      <Header
        style={styles.parentBox}
        headerLeftComponent={<BackHeaderLeft onClick={goBack} />}
        loading={fetchingRuns}>
        <Box style={styles.container}>
          <VanLoadHeader driverName={driverNames} driverList={driverList} />
          <ShopperVanLocationMap storeCoordinates={storeLatLon} />
          <H5Text style={[styles.titleText, styles.travelText]}>
            Travel to{' '}
            <H5Text style={{fontFamily: muliFont[900].normal}}>
              {masterStore?.name}
            </H5Text>{' '}
            and start loading the van for deliveries.
          </H5Text>
          <Divider style={styles.divider} />
          <CustomText style={styles.titleText}>
            {`Please transport and load ${
              ordersForShopper?.length || 0
            } order(s) onto the delivery van, then mark the checkbox as done.`}
          </CustomText>
        </Box>
        <OrderList orders={ordersForShopper || []} />
        <Divider style={styles.divider} />
        <BottomButton
          title={'Done'}
          disabled={
            !areAllValuesTrue(state.bagCountUpdateStatus) ||
            orderState?.loading ||
            driverList?.length <= 0
          }
          onPress={onPressHandler}
          loadingIndicator={<SpinnerComponent />}
        />
      </Header>
    </NavigateVanLoadContext.Provider>
  );
};

export default React.memo(NavigateVanLoadPage);

const styles = StyleSheet.create({
  parentBox: {
    flex: 1,
  },
  container: {
    marginHorizontal: 20,
  },
  titleText: {
    textAlign: 'left',
  },
  divider: {
    marginVertical: 10,
  },
  travelText: {
    marginTop: 10,
  },
});
