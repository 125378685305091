import {Dimensions, StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  containerStyles: {
    zIndex: 2,
    height: '100%',
  },
  scanHeader: {
    justifyContent: 'flex-start',
  },
  scanText: {
    flexGrow: 0.8,
  },
  image: {
    height: 75,
    width: 75,
  },
  highlightText: {
    borderRadius: 20,
    padding: 5,
    position: 'absolute',
    bottom: 0,
    alignSelf: 'center',
    zIndex: 3,
  },
  imageBox: {position: 'relative'},
  detailsContainer: {
    justifyContent: 'flex-start',
    marginBottom: 5,
    paddingHorizontal: 20,
  },
  textContainer: {
    alignItems: 'flex-start',
    // borderWidth: 1,
    marginLeft: 10,
    flex: 1,
  },
  unableToScanBox: {
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 5,
    width: '100%',
  },
  infoBox: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 20,
    marginRight: 10,
  },
  infoText: {
    marginLeft: 5,
  },
  itemNameText: {
    maxWidth: Dimensions.get('window').width - 120,
    textAlign: 'left',
    marginBottom: 10,
  },
  unableToScanText: {
    textAlign: 'left',
    marginLeft: 10,
    textDecorationLine: 'underline',
  },
  flexBox: {
    flex: 1,
  },
  accordian: {
    padding: 10,
    marginBottom: 5,
  },
  bottomAccordian: {
    padding: 10,
    marginTop: 5,
  },
  bottomCta: {
    // borderTopWidth: 0.5,
  },
});
