import {useColorThemeType, useFontTheme} from '../../appStyles/hooks/theme';

import {Box} from 'native-base';
import {CustomText} from '../../components/core/text';
import React from 'react';
import {StyleSheet} from 'react-native';

interface StartShiftCardProps {
  time: string;
}

function StartShiftCard(props: StartShiftCardProps) {
  const {time} = props;
  const defaultTheme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  return (
    <Box
      style={{
        backgroundColor: defaultTheme?.icon.yellow[300],
      }}>
      <CustomText
        style={[
          styles.title,
          {
            fontFamily: muliFont[600].normal,
            color: defaultTheme?.text.blue[300],
          },
        ]}>
        Start shopping at {time}
      </CustomText>
    </Box>
  );
}

export default React.memo(StartShiftCard);

const styles = StyleSheet.create({
  title: {
    paddingVertical: 5,
  },
});
