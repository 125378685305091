import {Platform, StyleSheet} from 'react-native';
import React, {useState} from 'react';

import {Box} from '../../../components/core/view';
import {Picker} from '@react-native-picker/picker';
import {getWidth} from '../../../utils/common';
import {useColorThemeType} from '../../../../src/appStyles/hooks/theme';

interface DropdownProps {
  onChange: Function;
  selectedValue: any;
  data: any;
  key: string;
  value: string;
}

const Dropdown = (props: DropdownProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState();
  const theme = useColorThemeType('main');

  return (
    <Box style={[styles.pickerContainer]}>
      <Picker
        style={{
          backgroundColor:
            Platform.OS === 'android'
              ? theme?.background?.gray?.[200]
              : undefined,
        }}
        dropdownIconColor={theme?.background?.gray?.[200]}
        selectedValue={selectedLanguage}
        onValueChange={(itemValue: any) => setSelectedLanguage(itemValue)}>
        {props?.data?.map((ele: any) => {
          return (
            <Picker.Item
              key={ele.toString()}
              label={ele[props.value]}
              value={ele[props.value]}
            />
          );
        })}
      </Picker>
    </Box>
  );
};

export default React.memo(Dropdown);

const styles = StyleSheet.create({
  pickerContainer: {
    width: getWidth('100%'),
    borderStyle: 'solid',
    borderColor: 'black',
    height: 130,
    padding: 6,
    overflow: 'hidden',
    justifyContent: 'center',
    display: 'flex',
    top: -25,
  },
});
