import {CustomText, H6Text} from '@buncha/components/core/text';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {Box} from '@buncha/components/core/view';
import MaintenanceIcon from '@buncha/components/core/icon/MaintenanceIcon';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React from 'react';
import {StyleSheet} from 'react-native';
import {scaleFonts} from '@buncha/appStyles/style/font';

type Props = NativeStackScreenProps<any, any>;

const DownTime: React.FC<Props> = () => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  return (
    <Box style={styles.parentBox}>
      <MaintenanceIcon
        fill={theme?.icon?.blue?.[300]}
        height={128}
        width={128}
        viewBox="-1 -2 128 132"
      />
      <Box>
        <H6Text
          style={{
            color: theme?.text?.blue?.[300],
            fontFamily: muli?.[700]?.normal,
          }}>
          Application Under Maintenance
        </H6Text>
        <CustomText
          style={{
            color: theme?.text?.gray?.[100],
            fontFamily: muli?.[600]?.normal,
          }}>
          Our application is currently undergoing maintenance. Please check back
          in a little while for the latest updates and a better experience.
          Thank you for your patience!
        </CustomText>
      </Box>
    </Box>
  );
};

export default React.memo(DownTime);

export const styles = StyleSheet.create({
  parentBox: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    gap: scaleFonts(15),
    alignItems: 'center',
  },
});
