import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  default: {
    borderRadius: 9,
    height: 52,
    justifyContent: 'center',
  },
  titleBox: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingComponent: {
    marginLeft: 5,
  },
});
