import {BottomSheetProps, WhenIWorkLoginCredentials} from '../../types';
import {Dimensions, StyleSheet, TextInput, View} from 'react-native';
import {H3Text, H4Text, H6Text} from '../../../components/core/text';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import font, {scaleFonts} from '../../../appStyles/style/font';
import {getWidth, isWeb} from '../../../utils/common';

import {Analytics} from '../../../config/analytics/analytics';
import {BottomButton} from '../../../components/composites/Button';
import {Box} from '../../../components/core/view';
import CrossEyeIcon from '../../../components/core/icon/CrossEyeIcon';
import {EventType} from '../../../config/analytics/type';
import EyeIcon from '../../../components/core/icon/EyeIcon';
import Fonts from '../../../resource/fonts';
import If from '../../../components/conditional/If';
import {SpinnerComponent} from '../../../components/core/loader';
import {Touchable} from '../../../components/core/touchable';
import WhenIWorkInput from '../WhenIWorkInput';
import {useAppDispatch} from '../../../config/redux/hooks';
import {useColorThemeType} from '../../../appStyles/hooks/theme';
import {useWhenIWork} from '../../../home/hooks/WhenIWork';

const WhenIWorkBottomSheet = (props: BottomSheetProps) => {
  const {onSheetClose} = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showPass, setShowPass] = useState(false);
  const emailRef = useRef<TextInput>(null);
  const passwordRef = useRef<TextInput>(null);
  const dispatch = useAppDispatch();

  const width = Number(getWidth(Dimensions.get('screen').width));
  const theme = useColorThemeType('main');

  const {loginToWhenIWork, loggingIn, loggingInError, clearErrorMessage} =
    useWhenIWork(dispatch);
  const onEmailChange = useCallback((val: string) => {
    setEmail(val);
  }, []);

  useEffect(() => {
    if (loggingInError) setErrorMsg(loggingInError);
  }, [loggingInError]);

  const getDisabledStatus = useMemo(() => {
    return Boolean(errorMsg.length) || !email.length || !password.length;
  }, [email.length, errorMsg.length, password.length]);

  const returnBorderColor = useMemo(() => {
    if (errorMsg) return theme?.text.red[100];
    return theme?.text?.gray[200];
  }, [errorMsg, theme?.text]);

  const returnPasswordBorderColor = useMemo(() => {
    if (loggingInError) return theme?.text.red[100];
    return theme?.text?.gray[200];
  }, [loggingInError, theme?.text]);

  const validateEmail = useCallback(
    (val: string) => {
      setErrorMsg('');
      if (val?.length > 0) {
        let emailRegex =
          // eslint-disable-next-line no-useless-escape
          /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/;
        if (emailRegex.test(val)) {
          onEmailChange(val);
          setErrorMsg('');
          return true;
        }
        onEmailChange(val);
        setErrorMsg('Invalid email');
      }
      return false;
    },
    [onEmailChange],
  );

  const clearErrorColor = useCallback(() => {
    if (validateEmail(email)) {
      setErrorMsg('');
      clearErrorMessage();
    }
  }, [clearErrorMessage, email, validateEmail]);

  const closeSheetAndRefresh = useCallback(() => {
    if (props?.refreshScreen) props?.refreshScreen();
    onSheetClose();
  }, [onSheetClose, props]);

  const loginToWhenIWorkHandler = useCallback(() => {
    Analytics.eventWithProps('WhenIWork Login', {
      type: EventType.cta,
      email: email,
    });
    const loginParams: WhenIWorkLoginCredentials = {
      email: email,
      password: password,
    };
    loginToWhenIWork(loginParams, closeSheetAndRefresh);
  }, [closeSheetAndRefresh, email, loginToWhenIWork, password]);

  const passwordHandler = useCallback(
    (val: string) => {
      setPassword(val);
      clearErrorColor();
    },
    [clearErrorColor],
  );

  const rightComponentHandler = useMemo(() => {
    return (
      <Touchable style={styles.eyeIcon} onPress={() => setShowPass(!showPass)}>
        <If condition={showPass}>
          <CrossEyeIcon
            fill={theme?.background.blue[300]}
            height={30}
            width={30}
            viewBox="0 -3 30 30"
          />
        </If>
        <If condition={!showPass}>
          <EyeIcon
            fill={theme?.background.blue[300]}
            height={30}
            width={30}
            viewBox="0 -3 30 30"
          />
        </If>
      </Touchable>
    );
  }, [showPass, theme?.background.blue]);

  return (
    <Box style={{backgroundColor: theme?.text.white[100]}}>
      <H3Text
        style={[
          styles.headerText,
          {
            color: theme?.text.blue[300],
            fontFamily: font.fonts['Mortise-X-Bold'],
          },
        ]}>
        Connect with When I Work
      </H3Text>
      <H4Text style={[styles.subHeaderTypo]}>
        {
          'Looks like you have been logged out of this session. \nPlease login again to continue'
        }
      </H4Text>

      <View style={[styles.inputContainer]}>
        <WhenIWorkInput
          onFocus={() => {
            clearErrorColor();
          }}
          debounce={true}
          defaultValue={email}
          autoCorrect={false}
          colorThemeType="default"
          clearButtonMode="while-editing"
          autoFocus
          placeholder="Email"
          ref={emailRef}
          clearInput={true}
          textValue={email}
          containerStyle={[{borderColor: returnBorderColor}]}
          style={[
            {
              backgroundColor: theme?.background?.white[100],
              fontSize: scaleFonts(16),
              color: theme?.text?.blue[300],
            },
          ]}
          onChangeText={validateEmail}
        />
        <WhenIWorkInput
          onFocus={() => {
            clearErrorColor();
          }}
          debounce={false}
          defaultValue={password}
          autoCorrect={false}
          colorThemeType="default"
          clearButtonMode="never"
          autoFocus
          placeholder="Password"
          ref={passwordRef}
          clearInput={false}
          textValue={password}
          containerStyle={[{borderColor: returnPasswordBorderColor}]}
          secureTextEntry={!showPass}
          style={[
            {
              backgroundColor: theme?.background?.white[100],
              fontSize: scaleFonts(16),
              color: theme?.text?.blue[300],
            },
          ]}
          onChangeText={passwordHandler}
          rightComponent={rightComponentHandler}
        />
        <View style={styles.errorMessageContainer}>
          <If condition={Boolean(errorMsg.length)}>
            <H6Text
              style={[
                styles.errorTypo,
                {
                  ...font.FontSize.fontP10,
                  color: theme?.text.red[100],
                },
              ]}>
              {errorMsg}
            </H6Text>
          </If>
        </View>
        <BottomButton
          style={[
            styles.buttonContainer,
            {width: isWeb() ? width * 0.75 : width * 0.95},
          ]}
          title="Login"
          disabled={getDisabledStatus}
          onPress={loginToWhenIWorkHandler || loggingIn}
          isLoading={loggingIn}
          loadingIndicator={<SpinnerComponent />}
        />
      </View>
    </Box>
  );
};

export default React.memo(WhenIWorkBottomSheet);

const styles = StyleSheet.create({
  modal: {
    height: 500,
    padding: 20,
  },
  headerText: {
    textAlign: 'left',
    fontSize: scaleFonts(22),
  },
  subHeaderTypo: {
    textAlign: 'left',
    marginTop: 10,
    fontSize: scaleFonts(15),
  },
  textInputContainer: {
    marginHorizontal: 10,
  },
  textInput: {
    backgroundColor: 'transparent',
    ...font.FontSize.fontH4,
  },
  errorMessageContainer: {
    top: 1,
  },
  errorTypo: {
    fontFamily: Fonts.muliSemiBold,
  },
  inputContainer: {
    marginTop: 20,
    // paddingBottom: 20,
  },
  buttonContainer: {
    marginBottom: 0,
  },
  eyeIcon: {
    marginRight: 5,
  },
});
