import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const BorderCrossIcon = (props: IconProps) => (
  <Icon {...props}>
    <Path
      fill={props.fill}
      d="M7.5 0A7.493 7.493 0 0 0 0 7.5C0 11.648 3.353 15 7.5 15c4.148 0 7.5-3.352 7.5-7.5C15 3.353 11.648 0 7.5 0Zm0 13.5c-3.308 0-6-2.693-6-6 0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6 0 3.307-2.693 6-6 6Zm2.693-9.75L7.5 6.442 4.808 3.75 3.75 4.808 6.442 7.5 3.75 10.193l1.058 1.057L7.5 8.557l2.693 2.693 1.057-1.057L8.557 7.5l2.693-2.692-1.057-1.058Z"
    />
  </Icon>
);
export default BorderCrossIcon;
