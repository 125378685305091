import {Box, HBox} from '../../../components/core/view';
import {H4Text, H6Text} from '../../../components/core/text';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import React from 'react';
import {Shifts} from '../../../home/types';
import StoreLogo from '../../../common/components/StoreLogo';
import {StyleSheet} from 'react-native';

interface ShiftBatchItemProps {
  shift: Shifts;
}

function ShiftBatchItem(props: ShiftBatchItemProps) {
  const {shift} = props;
  const defaultTheme = useColorThemeType();
  const muliFont = useFontTheme('muli');

  return (
    <HBox style={styles.mainContainer}>
      <StoreLogo storeLogoUrl={shift?.store?.storeLogoUrl} />
      <Box style={styles.contentContainer}>
        <H4Text
          style={[
            styles.titleText,
            {
              color: defaultTheme?.text.blue[300],
              fontFamily: muliFont[900].normal,
            },
          ]}>
          {shift?.store?.name}
        </H4Text>
        <HBox style={[styles.subTextBox]}>
          <H6Text
            style={[
              styles.runSubtext,
              {
                fontFamily: muliFont[700].normal,
                color: defaultTheme?.text.blue[300],
              },
            ]}>
            {shift?.time}
          </H6Text>
        </HBox>
      </Box>
    </HBox>
  );
}

export default React.memo(ShiftBatchItem);

const styles = StyleSheet.create({
  mainContainer: {
    justifyContent: 'flex-start',
  },
  contentContainer: {
    marginLeft: 10,
  },
  titleText: {
    textAlign: 'left',
    // cursor: 'default',
  },
  dotSeparator: {
    marginTop: -10,
    marginHorizontal: 10,
  },
  runSubtext: {
    textAlign: 'left',
    // cursor: 'default',
  },
  subTextBox: {
    justifyContent: 'flex-start',
  },
});
