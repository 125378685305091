import {ActivityIndicator, StyleSheet} from 'react-native';
import React, {useCallback, useEffect} from 'react';
import {
  RecommendationsForSubParams,
  RecommendedItemCell,
} from '../../../../../src/itemDetails/types/itemReplacement';

import {Box} from '../../../../../src/components/core/view';
import {CustomFlatList} from '../../../../../src/components/core/list';
import {Divider} from 'native-base';
import {H5Text} from '../../../../../src/components/core/text';
import ReplacementItemCell from '../replacementItemCell';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {useGetRecommendedItemsForSubstitution} from '../../../../../src/itemDetails/hooks/itemReplacement';

interface RecommendedItemsProps {
  catalogItemId: number;
  category: string;
  searchQuery: string;
  storeId: number;
  subCategory: string;
  isUPCMode: boolean;
  orderItemId?: number;
  orderId?: number;
  runId?: number;
  quantity?: number;
}

const RecommendedItems: React.FC<RecommendedItemsProps> = props => {
  const catalogItemId = props?.catalogItemId || 0;
  const category = props?.category || '';
  const searchQuery = props?.searchQuery || '';
  const storeId = props?.storeId || 0;
  const subCategory = props?.subCategory || '';
  const {upcOrderItem} = useAppSelector(gState => gState.itemScanner);

  const {
    getRecommendedItemsForSubstitution,
    loading,
    data,
    getRecommendedItemsForSubstitutionInUPC,
  } = useGetRecommendedItemsForSubstitution();

  const renderItem = useCallback(
    (subItem: {item: RecommendedItemCell}) => {
      return (
        <ReplacementItemCell
          orderItemId={props?.orderItemId}
          orderId={props?.orderId}
          runId={props?.runId}
          quantity={props?.quantity}
          itemInfo={subItem?.item}
          isUPCMode={props?.isUPCMode}
        />
      );
    },
    [
      props?.isUPCMode,
      props?.orderId,
      props?.orderItemId,
      props?.quantity,
      props?.runId,
    ],
  );
  const paramsToSend: RecommendationsForSubParams = {
    catalogItemId: catalogItemId,
    searchQuery: searchQuery,
    category: category,
    storeId: storeId,
    subCategory: subCategory,
  };
  useEffect(() => {
    if (props?.isUPCMode)
      getRecommendedItemsForSubstitutionInUPC(upcOrderItem?.id ?? 1);
    else if (paramsToSend?.storeId)
      getRecommendedItemsForSubstitution(paramsToSend);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsToSend?.storeId]);

  const separator = useCallback(() => <Divider />, []);

  if (loading) return <ActivityIndicator />;
  return (
    <Box style={styles.parentBox}>
      <H5Text style={styles.text} alignText="left">
        Recommended Items
      </H5Text>
      <CustomFlatList
        scrollEnabled={true}
        data={data}
        showsVerticalScrollIndicator={false}
        renderItem={renderItem}
        ItemSeparatorComponent={separator}
      />
    </Box>
  );
};

export default React.memo(RecommendedItems);

const styles = StyleSheet.create({
  parentBox: {
    flex: 1,
  },
  text: {
    paddingBottom: 5,
  },
});
