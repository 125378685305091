import React from 'react';

interface VerifyIdCellProps {
  orderId: string;
  photoIdUrl: string;
}

const VerifyIdCell: React.FC<VerifyIdCellProps> = () => {
  return <React.Fragment />;
};

export default React.memo(VerifyIdCell);
