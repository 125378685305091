import * as React from 'react';

import {ClipPath, Defs, G, Path} from 'react-native-svg';

import {Icon} from '.';
import {IconProps} from './type';

const Schedule: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Defs>
        <ClipPath id="a">
          <Path
            data-name="Rectangle 6481"
            d="M0 0h24.508v24.507H0z"
            fill="none"
          />
        </ClipPath>
      </Defs>
      <G data-name="Group 10650" clipPath="url(#a)">
        <Path
          data-name="Path 15952"
          d="M6.774 24.507h-2.21A4.5 4.5 0 010 19.937V6.779a4.475 4.475 0 013.727-4.494 5.236 5.236 0 01.7-.069 2.3 2.3 0 014.594-.031h6.474c.006-.088.016-.18.031-.275a2.258 2.258 0 012.435-1.9 2.3 2.3 0 012.121 2.2 4.5 4.5 0 014.424 4.583v3.135a2.3 2.3 0 01-2.432 2.42h-1.466c-.991 0-2.017-.006-3.018.012a5.3 5.3 0 00-3.467 1.4 5.351 5.351 0 00-1.723 3.33 10.888 10.888 0 00-.046 1.146v3.811A2.3 2.3 0 019.9 24.501H6.774M6.638 4.518l-1.009.066-.142.008c-.18.007-.354.007-.522.007a5.153 5.153 0 00-.828.041 2.069 2.069 0 00-1.745 2.141v13.154a2.389 2.389 0 00.018.362 2.094 2.094 0 002.161 1.817h5.384v-3.89a12.728 12.728 0 01.062-1.407 7.793 7.793 0 017.527-6.852c1.026-.018 2.064-.015 3.068-.012h1.501V6.797a2.666 2.666 0 00-.018-.37 2.13 2.13 0 00-2.174-1.834H17.7v-.018H6.638z"
        />
        <Path
          data-name="Path 15953"
          d="M18.663 24.021a5.529 5.529 0 115.549-5.5 5.525 5.525 0 01-5.549 5.5m-1.087-6.354v.782a1.161 1.161 0 00.351.852c.359.356.711.719 1.074 1.07a1.105 1.105 0 101.557-1.567c-.254-.268-.609-.5-.736-.814a3.225 3.225 0 01-.032-1.1 1.108 1.108 0 10-2.215-.026c-.007.268 0 .537 0 .806"
        />
      </G>
    </Icon>
  );
};

export default Schedule;
