import AsyncStorage from '@react-native-async-storage/async-storage';

export class Storage {
  async setItem(key: string, value: string) {
    await AsyncStorage.setItem(key, value);
  }

  async getItem(key: string) {
    return await AsyncStorage.getItem(key);
  }

  async delete(key: string) {
    await AsyncStorage.removeItem(key);
  }

  async clearAll() {
    await AsyncStorage.clear();
  }
}
