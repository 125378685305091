import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {Box} from '@buncha/components/core/view';
import {CustomText} from '@buncha/components/core/text';
import React from 'react';
import {StyleSheet} from 'react-native';

interface Props {
  text: string;
}

const UserNameUpperPill = ({text}: Props) => {
  const theme = useColorThemeType('main');
  const muliFont = useFontTheme('muli');
  return (
    <Box
      style={[
        styles.parent,
        {
          backgroundColor: theme?.background.yellow[100],
        },
      ]}>
      <CustomText
        style={[
          styles.typo,
          {
            fontFamily: muliFont[700].normal,
            color: theme?.text.blue[300],
          },
        ]}>
        {text}
      </CustomText>
    </Box>
  );
};

export default React.memo(UserNameUpperPill);

const styles = StyleSheet.create({
  parent: {
    borderRadius: 50,
    paddingHorizontal: 10,
    paddingVertical: 2,
    marginBottom: 2,
  },
  typo: {
    textAlign: 'left',
    marginRight: 5,
  },
});
