import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  parentBox: {
    justifyContent: 'flex-start',
    marginVertical: 10,
  },
  imageBox: {
    marginRight: 20,
    position: 'relative',
  },
  image: {
    height: 75,
    width: 75,
  },
  qtyBox: {
    justifyContent: 'center',
    paddingVertical: 5,
    position: 'absolute',
    bottom: 0,
    zIndex: 10,
    minWidth: '80%',
    alignSelf: 'center',
    borderRadius: 50,
  },
  textBox: {
    flex: 1,
  },
  replaceCTA: {
    flex: 0.5,
    borderRadius: 50,
    height: 35,
    marginLeft: 20,
  },
  namePriceBox: {
    flex: 0.5,
    flexGrow: 1,
  },
  priceText: {
    marginTop: 5,
  },
});
