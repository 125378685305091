import {AddIcon, Divider, MinusIcon} from 'native-base';
import {Box, HBox} from '@buncha/components/core/view';
import {
  BuyerData,
  FormDataUpdate,
  MandatoryFieldNames,
} from '@buncha/itemDetails/types/ItemScanner';
import {CustomText, H4Text, H5Text} from '@buncha/components/core/text';
import {
  ItemScannerAction,
  useItemScannerContext,
} from '@buncha/itemDetails/hooks/ItemScanner';
import React, {useCallback, useMemo} from 'react';
import {checkPriceDecimals, getWidth} from '@buncha/utils/common';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {CustomFlatList} from '@buncha/components/core/list';
import {Dimensions} from 'react-native';
import DropItem from '../dropItem';
import If from '@buncha/components/conditional/If';
import {PTextInput} from '@buncha/components/core/input/TextInput';
import {Touchable} from '@buncha/components/core/touchable';
import font from '@buncha/appStyles/style/font';
import {styles} from './style';

const QuantityInput = () => {
  const {state, dispatch} = useItemScannerContext();
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');

  const buyerData = state.buyerData;

  const quantityMandatory = useMemo(
    () =>
      Boolean(
        state?.buyerData?.[0]?.mandatoryFields?.find(
          field => field.fieldName === MandatoryFieldNames.quantityFound,
        ),
      ),
    [state?.buyerData],
  );

  const quantityUpdate = useCallback(
    (orderItemId: number, quantity: string) => {
      const qty = Number(quantity);
      if (checkPriceDecimals(quantity) && dispatch && qty < 100)
        dispatch(
          ItemScannerAction.setFormData({
            orderItemId: orderItemId,
            update: FormDataUpdate.quantity,
            updateValue: qty,
          }),
        );
    },
    [dispatch],
  );

  const handleDrop = useCallback(
    (orderItemId: number, val: boolean) => {
      if (dispatch)
        dispatch(
          ItemScannerAction.setFormData({
            orderItemId: orderItemId,
            update: FormDataUpdate.dropped,
            updateValue: val,
          }),
        );
    },
    [dispatch],
  );

  const modifyQty = useCallback(
    (orderItemId: number, quantity: number) => {
      const val = quantity ? quantity.toString() : '';
      quantityUpdate(orderItemId, val);
    },
    [quantityUpdate],
  );

  const remainingQuantityTextColor = useCallback(
    (remainingQuantity: number, qty: number) => {
      if (remainingQuantity === qty) return theme?.text.gray[100];
      else if (remainingQuantity) return theme?.text.red[100];
      return theme?.text.green[100];
    },
    [theme?.text.gray, theme?.text.green, theme?.text.red],
  );

  const getQuantityCell = useCallback(
    (info: {item: BuyerData; index: number}) => {
      const {item} = info;

      const updateDataFilter = state.formData.find(
        data => data.orderItemId === item.orderItemId,
      );
      const updateData = updateDataFilter?.quantity
        ? updateDataFilter
        : {...updateDataFilter};
      const pointerEvents = updateData?.dropped ? 'none' : undefined;
      const qty = item?.updatedQuantity || item?.quantity || 0;
      let remainingQuantity = qty - (updateData?.quantity || 0);

      const qtyValue = updateData?.quantity ?? 0;
      return (
        <Box>
          <Box>
            <HBox
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                opacity: updateData?.dropped ? 0.5 : 1,
                marginBottom: 5,
              }}>
              <H5Text
                style={{
                  color: theme?.text.blue[300],
                  fontFamily: muliFont[700].normal,
                }}>
                {`${item.firstName} ${item.lastName || ''}`}
              </H5Text>
              <Box
                style={[
                  styles.quantityContainer,
                  styles.quantityPill,
                  {
                    borderColor: theme?.background.blue[300],
                  },
                ]}>
                <HBox style={styles.quantityContainer}>
                  <Touchable
                    activeOpacity={1}
                    hitSlop={{top: 25, bottom: 25, left: 15, right: 15}}
                    disabled={!updateData?.quantity}
                    onPress={() => modifyQty(item.orderItemId, qtyValue - 1)}>
                    <MinusIcon
                      size={3}
                      color={
                        !updateData?.quantity
                          ? theme?.background.gray[100]
                          : theme?.background.blue[300]
                      }
                    />
                  </Touchable>
                  <PTextInput
                    placeholder="0"
                    placeholderTextColor={theme?.text.gray[300]}
                    pointerEvents={pointerEvents}
                    debounce={false}
                    textAlign="center"
                    value={
                      updateData?.quantity ? updateData.quantity.toString() : ''
                    }
                    onChangeText={val => quantityUpdate(item.orderItemId, val)}
                    keyboardType="number-pad"
                    returnKeyType="done"
                    containerStyle={[styles.inputStyle]}
                    style={{
                      fontFamily: muliFont[700].normal,
                      ...font.FontSize.fontH4,
                      color: theme?.text.blue[300],
                    }}
                  />
                  <Touchable
                    activeOpacity={1}
                    hitSlop={{top: 25, bottom: 25, left: 15, right: 15}}
                    onPress={() => modifyQty(item.orderItemId, qtyValue + 1)}>
                    <AddIcon size={3} color={theme?.background.blue[300]} />
                  </Touchable>
                </HBox>
              </Box>
            </HBox>
            <HBox style={styles.bottomBox}>
              <DropItem
                undrop={Boolean(updateData?.dropped)}
                orderItemId={item.orderItemId}
                orderStatus={item.order.deliveryLocation.deliveryStatus}
                handleDrop={(val: boolean) => handleDrop(item.orderItemId, val)}
              />
              <CustomText
                style={[
                  styles.remainingText,
                  // eslint-disable-next-line react-native/no-inline-styles
                  {
                    color: remainingQuantityTextColor(remainingQuantity, qty),
                    fontFamily: remainingQuantity
                      ? muliFont[700].normal
                      : muliFont[900].normal,
                    opacity: updateData?.dropped ? 0.5 : 1,
                  },
                ]}>
                <If condition={remainingQuantity}>
                  <If condition={remainingQuantity > 0}>
                    {remainingQuantity} items remaining
                  </If>
                  <If condition={remainingQuantity < 0}>
                    Added {Math.abs(remainingQuantity)} item(s) extra
                  </If>
                </If>
                <If condition={!remainingQuantity}>All picked</If>
              </CustomText>
            </HBox>
          </Box>
        </Box>
      );
    },
    [
      handleDrop,
      modifyQty,
      muliFont,
      quantityUpdate,
      remainingQuantityTextColor,
      state.formData,
      theme?.background.blue,
      theme?.background.gray,
      theme?.text.blue,
      theme?.text.gray,
    ],
  );

  const separator = useCallback(() => {
    return (
      <Divider style={[styles.divider, {borderColor: theme?.text.gray[200]}]} />
    );
  }, [theme?.text.gray]);

  return (
    <Box
      style={[
        styles.containerBox,
        {
          width: getWidth(Dimensions.get('screen').width),
        },
      ]}>
      <H4Text
        style={[
          styles.quantityText,
          {
            color: theme?.text.blue[300],
            fontFamily: muliFont[700].normal,
          },
        ]}>
        Quantity found
        <If condition={quantityMandatory}>
          <H5Text style={{color: theme?.text.red[100]}}>*</H5Text>
        </If>
      </H4Text>
      <CustomFlatList
        data={buyerData}
        renderItem={getQuantityCell}
        ItemSeparatorComponent={separator}
        style={styles.flatList}
        contentContainerStyle={styles.flatListContainer}
      />
    </Box>
  );
};

export default React.memo(QuantityInput);
