import React, {useCallback} from 'react';

import {Box} from '@buncha/components/core/view';
import {CustomFlatList} from '@buncha/components/core/list';
import {Order} from '@buncha/driver/types/vanLoadAt';
import StoreCheckBox from './StoreCheckBox';
import {StoreCheckListStyle} from './styles';

interface Props {
  orders: Order[];
  index: number;
}

const StoreCheckList = (props: Props) => {
  const {orders} = props;
  const renderItem = useCallback(({item}: {item: Order; index: number}) => {
    const store = item?.run?.store;
    const orderStatus = item?.deliveryLocation?.deliveryStatus;
    return (
      <StoreCheckBox
        storeLogoUrl={store?.storeLogoUrl}
        usedBag={item?.bagsUsed}
        deliveryLocationId={item.deliveryLocation?.id}
        runId={item?.run?.id}
        orderStatus={orderStatus}
      />
    );
  }, []);

  const separator = useCallback(() => {
    return <Box style={StoreCheckListStyle.separator} />;
  }, []);

  return (
    <Box style={StoreCheckListStyle.mainContainer}>
      <CustomFlatList
        data={orders}
        renderItem={renderItem}
        horizontal
        showsHorizontalScrollIndicator={false}
        ItemSeparatorComponent={separator}
      />
    </Box>
  );
};

export default React.memo(StoreCheckList);
