import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  actionButton: {
    borderRadius: 50,
    marginTop: 10,
  },
  buttonContainer: {
    marginBottom: 0,
  },
  text: {
    textAlign: 'left',
  },
});
