import {Box} from '../../../../components/core/view';
import {CustomText} from '../../../../components/core/text';
import React from 'react';
import {styles} from './styles';
import {useColorThemeType} from '../../../../appStyles/hooks/theme';
import {useItemDetailsContext} from '../../../../itemDetails/hooks/ItemDetails';

function LocationInStore() {
  const theme = useColorThemeType('main');
  const {state} = useItemDetailsContext();
  return (
    <Box
      style={[
        styles.bodyContainer,
        {backgroundColor: theme?.background.yellow[100]},
      ]}>
      <CustomText
        style={[styles.locationTypo, {color: theme?.text.blue[300]}]}
        numberOfLines={1}>
        {state.item?.category}
      </CustomText>
    </Box>
  );
}

LocationInStore.propTypes = {};

export default React.memo(LocationInStore);
