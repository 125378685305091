import {Box, HBox} from '../../../components/core/view';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {H3Text} from '../../../components/core/text';
import PaperBag from '../../../components/core/icon/PaperBag';
import React from 'react';
import {styles} from './styles';

function EmptyTodo() {
  const mortiseFont = useFontTheme('mortise');
  const theme = useColorThemeType();
  return (
    <Box style={[styles.mainContainer]}>
      <HBox style={[styles.iconContainer]}>
        <PaperBag
          height={150}
          width={87}
          fill={theme?.background.blue[300]}
          viewBox="0 0 112 120"
        />
      </HBox>
      <H3Text
        style={[
          styles.text,
          {
            fontFamily: mortiseFont[700].normal,
            color: theme?.text.blue[300],
          },
        ]}>
        Shopping Completed!
      </H3Text>
    </Box>
  );
}

export default React.memo(EmptyTodo);
