import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  imageContainer: {
    alignContent: 'flex-start',
  },
  nameContainer: {
    marginLeft: 10,
  },
  nameText: {
    textAlign: 'left',
    marginBottom: 10,
  },
  itemContainer: {
    marginTop: 10,
  },
  itemImage: {
    height: 35,
    width: 35,
    overflow: 'hidden',
  },
  touchableStyle: {
    padding: 5,
  },
});
