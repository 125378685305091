import {
  CONNECT_STRIPE_ACCOUNT,
  DELETE_ACCOUNT,
  IS_ACCOUNT_DELETABLE,
  LOGOUT,
} from '../../config/graphQL/documentNode/mutation';
import {
  DeleteAccountReturnType,
  GetPageDataResponse,
  GetStripeLoginAuthDataReturnType,
  IsAccountDeletableReturnType,
  PageType,
} from '../types/profile';
import {
  GET_PAGE_DATA,
  GET_STRIP_OAUTH_SIGN_IN_URL,
} from '../../config/graphQL/documentNode/queries';
import {getConfig, getUUID} from '../../utils/appInfo';
import {useCallback, useState} from 'react';

import {Analytics} from '../../config/analytics/analytics';
import {ApiState} from '../../common/types/common';
import {Config} from '../../utils/types';
import {PageComponent} from '../components/ProfileOptions/types';
import {SessionIdOperation} from '@buncha/config/graphQL/type';
import {ToastType} from '../../components/composites/notification/type';
import {branch} from '../../config/branch';
import {gqlService} from '../../config/graphQL';
import {logger} from '@buncha/config/logger';
import {onBoardingActions} from '../../onBoarding/reducer';
import {smartLook} from '../../config/analytics';
import {useToastMessage} from '../../components/composites/notification';

export function useLogoutAccount(dispatch: any) {
  const [loading, setLoading] = useState(false);

  const getLogoutMutation = async () => {
    const uuid = await getUUID();
    try {
      setLoading(true);
      await gqlService?.mutation({
        mutation: LOGOUT,
        variables: {
          deviceUuid: uuid,
        },
        fetchPolicy: 'network-only',
      });
      gqlService?.setLoginCredential('');
      dispatch(onBoardingActions.clearState());
      gqlService?.setSessionId(SessionIdOperation.createNew);
      setLoading(false);
      branch.logout();
      Analytics.logout();
      smartLook.stopRecording();
      logger.stopLogging();
    } catch (err: any) {
      setLoading(false);
    }
  };

  return {getLogoutMutation, loading};
}

export function useStripe() {
  const [loadingStripeUrl, setLoadingStripeUrl] = useState(false);
  const [errorToast] = useToastMessage(ToastType.Error);
  const getStripeLoginAuthData = async () => {
    try {
      setLoadingStripeUrl(true);
      const res = await gqlService?.query<GetStripeLoginAuthDataReturnType>({
        query: GET_STRIP_OAUTH_SIGN_IN_URL,
        fetchPolicy: 'network-only',
        variables: {
          version: getConfig(Config.versionName),
        },
      });
      return res?.data?.getConfig?.stripeOauthSignInUrl;
    } catch (err: any) {
      errorToast(err?.message);
    } finally {
      setLoadingStripeUrl(false);
    }
  };

  const connectUserStripAccount = async (code: string, callback: Function) => {
    try {
      const res = await gqlService?.mutation({
        mutation: CONNECT_STRIPE_ACCOUNT,
        variables: {
          code: code,
        },
        fetchPolicy: 'network-only',
      });
      if (res?.data) callback();
    } catch (error: any) {
      errorToast(error.message);
    }
  };

  return {getStripeLoginAuthData, loadingStripeUrl, connectUserStripAccount};
}

export function useUserManagement(dispatch: any) {
  const [deletingAccount, setDeletingAccount] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(false);
  const [errorToast] = useToastMessage(ToastType.Error);

  const checkForAccountDeletion = async (callback: Function) => {
    try {
      setCheckingStatus(true);
      const response = await gqlService?.mutation<IsAccountDeletableReturnType>(
        {
          mutation: IS_ACCOUNT_DELETABLE,
          fetchPolicy: 'network-only',
        },
      );

      if (response?.data?.isUserDeletable)
        callback(response?.data?.isUserDeletable);
    } catch (error: any) {
      errorToast(error.message);
    } finally {
      setCheckingStatus(false);
    }
  };

  const deleteUserAccount = async (callback: Function) => {
    try {
      setDeletingAccount(true);
      const response = await gqlService?.mutation<DeleteAccountReturnType>({
        mutation: DELETE_ACCOUNT,
        fetchPolicy: 'network-only',
      });
      if (response?.data?.deleteCurrentUser?.isDeleted) {
        gqlService?.setLoginCredential('');
        dispatch(onBoardingActions.clearState());
        Analytics.logout();
        smartLook.stopRecording();
        callback();
      } else {
        throw new Error('Failed to delete user account');
      }
    } catch (error: any) {
      errorToast(error.message);
    } finally {
      setDeletingAccount(false);
    }
  };

  return {
    deletingAccount,
    checkForAccountDeletion,
    checkingStatus,
    deleteUserAccount,
  };
}

export function useGetPageData(): [
  (pageName: PageType) => void,
  ApiState<PageComponent[]>,
] {
  const [resultInfo, setResult] = useState<ApiState<PageComponent[]>>({
    loading: false,
    error: undefined,
    data: undefined,
  });
  const [errorToast] = useToastMessage(ToastType.Error);
  const getPageData = useCallback(async (pageName: PageType) => {
    try {
      setResult({loading: true, error: undefined, data: undefined});
      const result = await gqlService?.query<GetPageDataResponse>({
        query: GET_PAGE_DATA,
        variables: {
          pageName: pageName,
        },
        fetchPolicy: 'network-only',
      });
      if (result?.data?.getPageData?.length)
        setResult({
          loading: false,
          error: undefined,
          data: result?.data?.getPageData,
        });
    } catch (err: any) {
      setResult({loading: false, error: err, data: undefined});
      errorToast(err.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [getPageData, resultInfo];
}
