import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  parentBox: {
    flex: 1,
    position: 'relative',
    justifyContent: 'flex-start',
  },
  headerText: {
    marginBottom: 15,
    textAlign: 'left',
  },
  loader: {
    alignSelf: 'center',
  },
  underlinedText: {
    textDecorationLine: 'underline',
  },
  search: {
    height: 55,
    borderRadius: 50,
    marginBottom: 20,
  },
  bottomBox: {
    position: 'absolute',
    bottom: 0,
    alignSelf: 'center',
    width: '100%',
    paddingVertical: 5,
  },
  scrollView: {
    paddingHorizontal: 20,
    flex: 1,
  },
  iconBox: {marginRight: 5},
  modalBody: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});
