import {Dispatch, useCallback, useContext, useReducer, useState} from 'react';
import {
  GET_GROUPED_ORDERS,
  GET_RUN_FOR_SEA_SPIDER,
} from '@buncha/config/graphQL/documentNode/queries';
import {GroupedOrder, VanLoadAtContaxtState} from '../types/vanLoadAt';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {VanLoadAtContext, initialState} from '../context/VanLoadAt';

import {DeliveryLocationStatus} from '../types/common';
import {Run} from '@buncha/AboutShift/types';
import {gqlService} from '@buncha/config/graphQL';

const reducer = {
  setMasterRun: function (
    state: VanLoadAtContaxtState,
    action: PayloadAction<Run>,
  ) {
    state.run = action.payload;
  },
  setGroupedRun: function (
    state: VanLoadAtContaxtState,
    action: PayloadAction<GroupedOrder[]>,
  ) {
    state.groupedOrders = action.payload;
  },
  setUpdateOrderStatusLocal: function (
    state: VanLoadAtContaxtState,
    action: PayloadAction<{
      deliveryLocationId: number;
      updatedStatus: DeliveryLocationStatus;
    }>,
  ) {
    const {deliveryLocationId, updatedStatus} = action.payload;

    if (state.groupedOrders)
      state.groupedOrders = state.groupedOrders.map(group => {
        return {
          ...group,
          orders: group.orders.map(order => {
            if (order?.deliveryLocation?.id === deliveryLocationId)
              return {
                ...order,
                deliveryLocation: {
                  ...order.deliveryLocation,
                  deliveryStatus: updatedStatus,
                },
              };

            return order;
          }),
        };
      });
  },
};

const slice = createSlice({
  initialState,
  name: 'VanLoadAtContext',
  reducers: reducer,
});

export function useVanLoadAtReducer() {
  const reducerInfo = useReducer(slice.reducer, initialState);
  return reducerInfo;
}

export function useVanLoadAtContext() {
  return useContext(VanLoadAtContext);
}

export const VanLoadAtAction = slice.actions;

export function useGetMasterRunForDriver(dispatch: Dispatch<any> | null) {
  const [loading, setLoading] = useState(false);
  const getMasterRun = useCallback(
    async (runId: number) => {
      try {
        setLoading(true);
        const response = await gqlService?.query({
          query: GET_RUN_FOR_SEA_SPIDER,
          fetchPolicy: 'network-only',
          variables: {
            runId: runId,
          },
        });
        if (response?.data) {
          const run = response?.data?.getRun;
          if (dispatch) dispatch(VanLoadAtAction.setMasterRun(run));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [dispatch],
  );
  return {loading, getMasterRun};
}

export function useGetGroupedOrders(dispatch: Dispatch<any> | null) {
  const [loading, setLoading] = useState(false);
  const getGroupedOrder = useCallback(
    async (runId: number) => {
      try {
        setLoading(true);
        const response = await gqlService?.query({
          query: GET_GROUPED_ORDERS,
          fetchPolicy: 'network-only',
          variables: {
            runId: runId,
          },
        });
        if (response?.data) {
          const orders = response?.data?.getGroupedOrders;
          if (dispatch) dispatch(VanLoadAtAction.setGroupedRun(orders));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [dispatch],
  );
  return {loading, getGroupedOrder};
}
