import React, {useCallback, useEffect} from 'react';
import {RefreshControl, RefreshControlProps, StyleSheet} from 'react-native';

import {Box} from '@buncha/components/core/view';
import {CustomFlatList} from '@buncha/components/core/list';
import {Divider} from 'native-base';
import Header from '../../../src/components/composites/Header/Header';
import HeaderComponent from '../components/headerComponent';
import HomeHeader from '../../home/component/HomeHeader';
import If from '@buncha/components/conditional/If';
import LoadingComponent from '../components/LoadingComponent';
import LoadingRow from '../components/LoadingComponent/LoadingRow';
import {Memo} from '../types';
import MemoRow from '../components/MemoRow';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {useGetMemos} from '../hooks';
import {useIsFocused} from '@react-navigation/native';

function Memos() {
  const {getMemos, readMemos, unreadMemos, memosLoading, isAtEnd} =
    useGetMemos();
  const theme = useColorThemeType();
  const isFocus = useIsFocused();

  const refreshMemos = useCallback(() => {
    getMemos(true);
  }, [getMemos]);

  const renderRow = useCallback((info: {item: Memo}) => {
    return <MemoRow memo={info.item} />;
  }, []);

  const handleScroll = useCallback(() => {
    getMemos();
  }, [getMemos]);

  useEffect(() => {
    if (isFocus) refreshMemos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocus]);

  const Separator = useCallback(() => <Divider style={styles.divider} />, []);

  return (
    <Header hideBottomSafeArea>
      <HomeHeader headerTitle={'My Memos'} nameOnly />
      <CustomFlatList
        ItemSeparatorComponent={Separator}
        contentContainerStyle={styles.contentContainer}
        loading={
          memosLoading && Boolean(unreadMemos?.length || readMemos?.length)
        }
        loadMore={!isAtEnd}
        ListEmptyComponent={
          <If condition={!readMemos?.length && !unreadMemos?.length}>
            <LoadingComponent loading={memosLoading} />
          </If>
        }
        refreshControl={
          (
            <RefreshControl
              refreshing={memosLoading}
              onRefresh={refreshMemos}
              tintColor={theme?.background.gray[100]}
              style={styles.refreshControl}
            />
          ) as React.ReactElement<RefreshControlProps>
        }
        ListHeaderComponent={
          <If condition={!memosLoading}>
            <HeaderComponent
              readMemoAvailable={Boolean(readMemos?.length)}
              unreadMemos={unreadMemos}
            />
          </If>
        }
        data={readMemos}
        renderItem={renderRow}
        onEndReachedThreshold={0.1}
        numOfTimesToRender={1}
        loadingSkeleton={
          <If condition={true}>
            <Box style={styles.activityIndicatorContainer}>
              <LoadingRow />
              <LoadingRow />
            </Box>
          </If>
        }
        onEndReachedAtt={handleScroll}
      />
    </Header>
  );
}

export default React.memo(Memos);

const styles = StyleSheet.create({
  refreshControl: {zIndex: 100},
  contentContainer: {
    flexGrow: 1,
    margin: 20,
    paddingBottom: 30,
  },
  activityIndicatorContainer: {
    flexGrow: 1,
    marginVertical: 10,
  },
  divider: {
    marginTop: 15,
  },
});
