import {
  CommonActions,
  NavigationContainerRef,
  StackActions,
} from '@react-navigation/native';

import {NavAction} from './type';

export default class Navigation {
  private NavigationRef: NavigationContainerRef<any> | null;

  constructor() {
    this.NavigationRef = null;
  }

  setNavigationRef(ref: NavigationContainerRef<any> | null) {
    this.NavigationRef = ref;
  }

  navigate(
    screen: string,
    params?: object,
    action: NavAction = NavAction.navigate,
  ) {
    switch (action) {
      case NavAction.pop:
        this.pop();
        break;
      case NavAction.push:
        this.push(screen, params);
        break;
      case NavAction.replace:
        this.replace(screen, params);
        break;
      default:
        this.NavigationRef?.navigate(screen, params);
    }
  }

  push(screen: string, params?: object) {
    this.NavigationRef?.dispatch(StackActions.push(screen, params));
  }

  pop(count = 1) {
    this.NavigationRef?.dispatch(StackActions.pop(count));
  }

  getCurrenRouteName() {
    return this.NavigationRef?.getCurrentRoute()?.name || '';
  }

  getCurrentRoute() {
    return this.NavigationRef?.getCurrentRoute()?.name || '';
  }

  replace(screen: string, params?: object) {
    this.NavigationRef?.dispatch(StackActions.replace(screen, params));
  }

  reset(routeNames: any[]) {
    this.NavigationRef?.dispatch(
      CommonActions.reset({
        index: routeNames.length - 1,
        routes: routeNames,
      }),
    );
  }

  goBack() {
    if (!this.NavigationRef?.canGoBack()) return false;
    this.NavigationRef?.goBack();
    return true;
  }

  canGoBack() {
    return this.NavigationRef?.canGoBack();
  }

  getNavigationRef() {
    return this.NavigationRef;
  }
}
