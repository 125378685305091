import {Dimensions, StyleSheet} from 'react-native';

import {getWidth} from '../../../../../../src/utils/common';

export const styles = StyleSheet.create({
  flatList: {
    marginTop: 10,
  },
  parentBox: {
    width: '100%',
    paddingHorizontal: 10,
    marginTop: 10,
  },
  inputContainer: {
    borderWidth: 1,
    width: '100%',
    borderRadius: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  input: {
    height: 150,
    marginTop: 5,
  },
  buttonBox: {
    marginVertical: 10,
  },
  spinner: {
    position: 'absolute',
    width: getWidth(Dimensions.get('window').width),
    height: Dimensions.get('window').height,
    justifyContent: 'center',
  },
});
