import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import If from '@buncha/components/conditional/If';
import Calender from '@buncha/components/core/icon/Calender';
import {H4Text} from '@buncha/components/core/text';
import {Box, CenterBox} from '@buncha/components/core/view';
import {Divider, Skeleton} from 'native-base';
import React, {Fragment} from 'react';
import {StyleSheet} from 'react-native';
import LoadingRow from './LoadingRow';

interface Props {
  loading: boolean;
}

const LoadingComponent = (props: Props) => {
  const theme = useColorThemeType();

  return (
    <Fragment>
      <If condition={!props.loading}>
        <CenterBox style={styles.emptyContentContainer}>
          <Calender height={199} width={237} viewBox="-2 4.6 245 10" />
          <H4Text
            style={{
              color: theme?.text.blue[300],
            }}>
            Hmm.. It seems like there are no memos available at this moment.
          </H4Text>
        </CenterBox>
      </If>
      <If condition={props.loading}>
        <Box style={styles.mar20}>
          <Skeleton
            style={styles.marBot10}
            startColor={theme?.text.blue[300]}
            width={40}
            height={4}
            borderRadius={5}
          />
          <LoadingRow />
          <Divider style={styles.divider} />
          <LoadingRow />
          <Divider style={styles.divider} />
          <LoadingRow />
        </Box>
      </If>
    </Fragment>
  );
};

export default React.memo(LoadingComponent);

const styles = StyleSheet.create({
  emptyContentContainer: {
    flexGrow: 1,
    paddingHorizontal: 5,
  },
  mar20: {
    marginHorizontal: 10,
    marginBottom: 20,
  },
  marBot10: {marginBottom: 20},
  divider: {
    marginBottom: 20,
    marginTop: 10,
  },
});
