import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const SyncIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path d="M11.375 15.25c-.35.167-.667.154-.95-.038-.283-.191-.425-.504-.425-.937a.85.85 0 0 1 .163-.487c.108-.159.245-.28.412-.363a5.874 5.874 0 0 0 2.5-2.225A5.996 5.996 0 0 0 14 7.95c0-.75-.142-1.48-.425-2.188-.283-.708-.725-1.362-1.325-1.962L12 3.55V5c0 .283-.096.52-.287.712A.968.968 0 0 1 11 6a.965.965 0 0 1-.712-.288A.965.965 0 0 1 10 5V1c0-.283.096-.521.288-.713A.967.967 0 0 1 11 0h4c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 0 1-.288.713A.967.967 0 0 1 15 2h-1.75l.4.35c.817.817 1.413 1.704 1.788 2.662.375.959.562 1.938.562 2.938 0 1.6-.417 3.054-1.25 4.362a7.75 7.75 0 0 1-3.375 2.938ZM1 16a.968.968 0 0 1-.713-.288A.967.967 0 0 1 0 15c0-.283.096-.52.287-.712A.968.968 0 0 1 1 14h1.75l-.4-.35c-.817-.817-1.413-1.704-1.788-2.662A8.008 8.008 0 0 1 0 8.05c0-1.6.417-3.054 1.25-4.363A7.753 7.753 0 0 1 4.625.75c.35-.167.667-.154.95.037.283.192.425.505.425.938a.853.853 0 0 1-.162.487 1.08 1.08 0 0 1-.413.363 5.873 5.873 0 0 0-2.5 2.225A5.996 5.996 0 0 0 2 8.05c0 .75.142 1.479.425 2.187.283.709.725 1.363 1.325 1.963l.25.25V11c0-.283.096-.521.288-.713A.967.967 0 0 1 5 10a.97.97 0 0 1 .713.287A.97.97 0 0 1 6 11v4c0 .283-.096.52-.287.712A.968.968 0 0 1 5 16H1Z" />
    </Icon>
  );
};

export default SyncIcon;
