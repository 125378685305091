import * as React from 'react';

import {ClipPath, Defs, G, Path} from 'react-native-svg';

import Icon from './Icon';
import {IconProps} from './type';

const ReplaceFilled: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <G clipPath="url(#a)">
        <Path
          fill={props.fill}
          d="M8 2H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2Zm12 12h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2ZM16.707 2.293a1 1 0 0 1 .083 1.32l-.083.094L15.414 5H19a3 3 0 0 1 2.995 2.824L22 8v3a1 1 0 0 1-1.993.117L20 11V8a1 1 0 0 0-.883-.993L19 7h-3.585l1.292 1.293a1 1 0 0 1-1.32 1.497l-.094-.083-3-3a.98.98 0 0 1-.28-.872l.036-.146.04-.104c.058-.126.14-.24.245-.334l2.959-2.958a1 1 0 0 1 1.414 0ZM3 12a1 1 0 0 1 .993.883L4 13v3a1 1 0 0 0 .883.993L5 17h3.585l-1.292-1.293a1 1 0 0 1-.083-1.32l.083-.094a1 1 0 0 1 1.32-.083l.094.083 3 3a.98.98 0 0 1 .28.872l-.036.146-.04.104a1.02 1.02 0 0 1-.245.334l-2.959 2.958a1 1 0 0 1-1.497-1.32l.083-.094L8.584 19H5a3 3 0 0 1-2.995-2.824L2 16v-3a1 1 0 0 1 1-1Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Icon>
  );
};

export default ReplaceFilled;
