import {PTextInputProps} from './type';
import {styles} from './style';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../appStyles/hooks/theme';

export function useTextProps(props: PTextInputProps) {
  const colorTheme = useColorThemeType();
  const fontTheme = useFontTheme();
  const fontFamily = fontTheme[400][props.fontThemeType || 'normal'];
  return {
    placeholder: 'Enter Input',
    placeholderTextColor: colorTheme?.text.gray[100],
    autoCorrect: false,
    ...props,
    style: [
      {
        fontFamily,
        backgroundColor: colorTheme?.background.white[100],
        color: colorTheme?.text.gray[200],
        borderColor: colorTheme?.background.gray[200],
        flex: 2,
      },
      styles.default,
      {
        borderWidth: 0,
        borderBottomWidth: 0,
        borderTopWidth: 0,
        borderEndWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStartWidth: 0,
      },
      props.style,
    ],
  };
}
