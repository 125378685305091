import React, {useCallback, useMemo, useState} from 'react';

import AppModal from '../../../../common/components/CustomModal/AppModal';
import {PTextInput} from '../../../../../src/components/core/input/TextInput';
import {styles} from './style';
import {useAddNotesForDeliveryLocation} from '../../../../../src/driver/hooks/deliveryDetails';
import {useColorThemeType} from '../../../../../src/appStyles/hooks/theme';

interface DeliveryNotesModalProps {
  orderId?: number;
  isOpen: boolean;
  onClose: () => void;
  deliveryLocationId: number;
  driverNotes: string;
}

const DeliveryNotesModal: React.FC<DeliveryNotesModalProps> = props => {
  const theme = useColorThemeType('main');
  const {addNotesForDeliveryLocation, loading, notesAdded} =
    useAddNotesForDeliveryLocation();
  const deliveryLocationId = props?.deliveryLocationId || 0;
  const driverNotes = props?.driverNotes || '';
  const [deliveryNotes, setDeliveryNotes] = useState(
    driverNotes || notesAdded || '',
  );

  const addNotes = useCallback(async () => {
    if (!deliveryNotes || !deliveryLocationId) return;
    await addNotesForDeliveryLocation(deliveryLocationId, deliveryNotes);
    props?.onClose();
  }, [addNotesForDeliveryLocation, deliveryLocationId, deliveryNotes, props]);

  const handleDeliveryNotesChange = useCallback(
    (text: string) => setDeliveryNotes(text),
    [],
  );

  const getBorderColor = useMemo(() => {
    if (!deliveryNotes) return theme?.text?.gray?.[200];
    return theme?.text?.blue?.[300];
  }, [deliveryNotes, theme?.text?.blue, theme?.text?.gray]);

  return (
    <AppModal
      isOpen={props?.isOpen}
      onClose={() => props?.onClose()}
      topCTAHandelr={addNotes}
      title={'Add Notes'}
      modalBody={
        <PTextInput
          value={deliveryNotes}
          numberOfLines={10}
          placeholder="Add a note"
          textAlignVertical={'top'}
          multiline={true}
          debounce={false}
          returnKeyType="done"
          style={[styles.input, {color: theme?.text?.blue?.[300]}]}
          onChangeText={handleDeliveryNotesChange}
          containerStyle={[
            styles.inputContainer,
            {
              borderColor: getBorderColor,
            },
          ]}
        />
      }
      ctaBottomTitle={'Cancel'}
      ctaTopTitle={'Add'}
      topCTAColor={theme?.background.green[100]}
      loading={loading}
      disableCtaTop={!deliveryNotes}
    />
  );
};

export default React.memo(DeliveryNotesModal);
