import {HomeScreens, ShopperScreens} from './types';

import {MainScreens} from '../../navigation/types';
import {NavAction} from '../../config/navigation/type';
import {navigation} from '../../config/navigation';

export default {
  [HomeScreens.YourSchedule]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.Home,
      {
        screen: HomeScreens.YourSchedule,
        params,
      },
      navigateAction,
    );
  },
  [HomeScreens.OpenShifts]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.Home,
      {
        screen: HomeScreens.OpenShifts,
        params,
      },
      navigateAction,
    );
  },
  [HomeScreens.AboutShift]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.Home,
      {
        screen: HomeScreens.AboutShift,
        params,
      },
      navigateAction,
    );
  },
  [HomeScreens.Lockout]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.Home,
      {
        screen: HomeScreens.Lockout,
        params,
      },
      navigateAction,
    );
  },
  [HomeScreens.PastRuns]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.Home,
      {
        screen: HomeScreens.PastRuns,
        params,
      },
      navigateAction,
    );
  },
  [HomeScreens.PastOrders]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.Home,
      {
        screen: HomeScreens.PastOrders,
        params,
      },
      navigateAction,
    );
  },
  [HomeScreens.Tutorials]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.Home,
      {
        screen: HomeScreens.Tutorials,
        params,
      },
      navigateAction,
    );
  },
  [HomeScreens.Shopper]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.Home,
      {
        screen: HomeScreens.Shopper,
        params,
      },
      navigateAction,
    );
  },
  [HomeScreens.MemoDetails]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.Home,
      {
        screen: HomeScreens.MemoDetails,
        params,
      },
      navigateAction,
    );
  },
};

export const ShopperNav = {
  [ShopperScreens.BatchDetailsPage]: (
    params?: any,
    navigateAction?: NavAction,
  ) => {
    navigation.navigate(
      MainScreens.Shopper,
      {
        screen: ShopperScreens.BatchDetailsPage,
        params,
      },
      navigateAction,
    );
  },
  [ShopperScreens.AddItemPage]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.Shopper,
      {
        screen: ShopperScreens.AddItemPage,
        params,
      },
      navigateAction,
    );
  },
  [ShopperScreens.ItemDetails]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.Shopper,
      {
        screen: ShopperScreens.ItemDetails,
        params,
      },
      navigateAction,
    );
  },
  [ShopperScreens.OrderPackingPage]: (
    params?: any,
    navigateAction?: NavAction,
  ) => {
    navigation.navigate(
      MainScreens.Shopper,
      {
        screen: ShopperScreens.OrderPackingPage,
        params,
      },
      navigateAction,
    );
  },
  [ShopperScreens.PdfDetailScreen]: (
    params?: any,
    navigateAction?: NavAction,
  ) => {
    navigation.navigate(
      MainScreens.Shopper,
      {
        screen: ShopperScreens.PdfDetailScreen,
        params,
      },
      navigateAction,
    );
  },
  [ShopperScreens.UPCShifts]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.Shopper,
      {
        screen: ShopperScreens.UPCShifts,
        params,
      },
      navigateAction,
    );
  },
};
