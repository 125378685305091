import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  iconContainer: {
    justifyContent: 'center',
  },
  text: {
    marginTop: 5,
  },
});
