import * as mutation from './documentNode/mutation';
import * as query from './documentNode/queries';
import * as subscription from './documentNode/subscription';

import {gqlService, initGraphQLService} from './service';

export const graphQLDocumentNode = {
  query: query,
  mutation: mutation,
  subscription: subscription,
};
export {initGraphQLService, gqlService};
