import {BatchTask, ChatDetails, Run, RunType} from '../../AboutShift/types';

import {ShopperItemChangesForShopper} from '@buncha/itemDetails/types/ItemScanner';
import {Store} from '../../home/types';
import {SubstitutionRequestStatus} from '../../../src/common/types/common';
import {UserStub} from '@buncha/onBoarding/types';

export interface BatchDetailContextState {
  sortBarValue?: itemsSortBy;
  showSortBar: boolean;
  groupedAssignedItems: TabResults;
  tabDataCount: TabDataCount;
  globalLoading: boolean;
  remainingItemCount: number[];
  orderAssignee: any;
  receipts: string[];
  contactSheetProps?: ContactBottomSheetProps;
  tutorial: boolean;
  orderIdsToBeSend: number[];
  hasPickedOrderModal: boolean;
  openUploadReceiptModal: boolean;
  upcOrderData: UPCOrderData;
  disablePickingRadioButton: boolean;
  disableToDo: boolean;
  minimumDeliveryLocationStatus?: DeliveryLocationStatus;
  hasSkipReceipt: ReceiptBypassMode;
}
export interface UPCOrder {
  esIndex: string;
  id: number;
  orderStatus: string;
  shopperId: number;
  storeLogo: string;
  storeName: string;
}

export interface UPCOrderItem {
  id: number;
  upcOrderId: number;
  name: string;
  price: number;
  quantity: number;
  itemImageUrl: string;
  tags: string;
  itemStatus: UpcOrderItemStatus;
  catalogItemId: number;
  itemUnit: string;
  upcStatus: UpcStatus;
}
export interface UPCOrderData {
  upcOrder?: UPCOrder;
  orderItems: UPCOrderItem[];
}
export interface GetReceiptForRunResponse {
  getRun: {
    receiptImageUrls: string[];
  };
}

/* eslint-disable no-shadow */
export enum ReceiptBypassMode {
  showSkip = 'showSkip',
  skipByShopper = 'skipByShopper',
  none = 'none',
}

export enum DeliveryImageType {
  signature = 'signature',
  idProof = 'idProof',
  deliveryImage = 'deliveryImage',
}

export interface UploadReceiptResponse {
  uploadRunReceipt: {
    id: number;
    fileName: string;
    fileUrl: string;
  };
}

export interface AddItemContextState {
  selectedQuantity: number;
  searchResults: AddItemCell[];
  selectedItem?: AddItemCell;
}

export interface TabDataCount {
  todoCount: number;
  inReviewCount: number;
  doneCount: number;
}

export interface BatchTabDataCount {
  key: BatchDetailTabs;
  data: string;
}

export enum BatchDetailTabs {
  'todo' = 'todo',
  'inReview' = 'inReview',
  'done' = 'done',
}

export enum itemsSortBy {
  'customer' = 'customer',
  'category' = 'category',
}

export enum OrderItemStatus {
  'notPicked' = 'notPicked',
  'picked' = 'picked',
  'missing' = 'missing',
  'removedFromCatalogue' = 'removedFromCatalogue',
  'dropped' = 'dropped',
  'packed' = 'packed',
  'notPacked' = 'notPacked',
  'requestRejectedByUser' = 'requestRejectedByUser',
  'refunded' = 'refunded',
  'partiallyRefunded' = 'partiallyRefunded',
  'reOrder' = 'reOrder',
  'alreadyDelivered' = 'alreadyDelivered',
}

export interface CatalogItem {
  id: number;
  name: string;
  itemImageUrl: string;
  price: number;
}

export interface SubstitutionRequest {
  id: number;
  createdAt: Date;
  quantity: number;
  catalogItem: CatalogItem;
}

export interface DeliveryLocation {
  id: number;
  deliveryStatus: DeliveryLocationStatus;
}
export interface Order {
  id: number;
  run: Run;
  bagsUsed: number;
  deliveryLocation: DeliveryLocation;
  buyer: {
    id: number;
    firstName: string;
    phoneNumber: number;
    isVip?: boolean;
  };
}

export interface OrdersForShopper {
  id: number;
  orderAssignee: OrderAssignee;
  buyer: UserStub;
  bagsUsed: number;
}

export interface OrderAssignee {
  assignee: UserStub;
  assigneeSecondary: UserStub;
}

export interface SeaSpiderRun {
  id: number;
  ordersForShopper: OrdersForShopper[];
  masterRun: Run;
}

export interface NavigateVanLoadContextState {
  run: SeaSpiderRun | undefined;
  loading: boolean;
  bagCountUpdateStatus: BagCountUpdateStatus;
}

export interface BagCountUpdateStatus {
  [orderId: number]: boolean;
}
export interface ItemLocationInformation {
  aisle: string;
  storeContext: string;
}
export interface OrderItems {
  id: number;
  name: string;
  price: number;
  quantity: number;
  orderId: number;
  note: string;
  isCustomItem: boolean;
  itemImageUrl: string;
  totalQuantityInThisRun: number;
  itemStatus: OrderItemStatus;
  substitutedBy: JSON;
  substitutionRequest: SubstitutionRequest;
  catalogItemId: number;
  order: Order;
  position: number;
  upcStatus: UpcStatus;
  options: string;
  tags: string;
  shopperItemChangesForShopper: ShopperItemChangesForShopper;
  itemLocationInformation: ItemLocationInformation;
}

export interface TabResults {
  todo: GroupedAssignedItems[];
  inReview: GroupedAssignedItems[];
  done: GroupedAssignedItems[];
  all: GroupedAssignedItems[];
}

export interface GroupedAssignedItems {
  groupedKey: string;
  orderItems: OrderItems[];
  keyTotalEntries: number;
  metaData: MetaData;
}

export interface MetaData {
  completedOrderCount: number;
  orderId: number;
  order: Order;
  buyer: BuyerData;
}

export interface BuyerData {
  id: number;
  isVip: boolean;
  firstName: string;
  lastName: string;
}

export interface RunForBatchDetail {
  id: number;
  locationImageUrl: string;
  locationAddress: string;
  storeName: string;
  getReceipt: string;
  store: Store;
  ordersForShopper: Order[];
  receiptImageUrls: string[];
  masterRun: {
    id: number;
  };
  runType: RunType;
  isMaster: boolean;
}

export enum DeliveryLocationStatus {
  'notStarted' = 'notStarted',
  'onTheWay' = 'onTheWay',
  'readyForPickup' = 'readyForPickup',
  'delivered' = 'delivered',
  'cancelled' = 'cancelled',
  'readyToPack' = 'readyToPack',
  'orderPacked' = 'orderPacked',
  'toBeAssigned' = 'toBeAssigned',
  'driverAssigned' = 'driverAssigned',
  'receiptGenerated' = 'receiptGenerated',
  'refunded' = 'refunded',
  'deliveryFailed' = 'deliveryFailed',
  'awaitingHandover' = 'awaitingHandover',
}

export interface DeliveryLocation {
  id: number;
  deliveryStatus: DeliveryLocationStatus;
}

export interface ItemCheckBeforePacking {
  recentOrderItem: OrderItems[];
  substituteDroppedByShopper: OrderItems[];
  substitutePickedByShopper: OrderItems[];
}

export interface GetOrdersForOrderAssigneeResponse {
  getOrdersForOrderAssignee: GroupedAssignedItems[];
}

export interface GetRunForBatchDetailResponse {
  getRun: RunForBatchDetail;
}

export interface UpdateOrderItemStatusResponse {
  updateOrderItemStatus: OrderItems;
}

export interface GetOrderItemByIdResponse {
  getOrderItemById: OrderItems;
}

export interface SetDeliveryLocationStatusOrdersResponse {
  setDeliveryLocationStatusOfAssignedOrders: DeliveryLocation[];
}

export interface GetItemStatusCountResponse {
  getItemStatusCount: number[];
}

export interface GetMinimumDeliveryStatusResponse {
  getMinimumDeliveryStatus: DeliveryLocationStatus;
}

export interface ItemCheckBeforePackingResponse {
  itemCheckBeforePacking: ItemCheckBeforePacking;
}

export interface UploadVanInspectionImageResponse {
  uploadVanInspectionImages: {
    uri: string;
  };
}

export interface CompleteVanInspectionResponse {
  completeVanInspection: boolean;
}

export interface AddItemCell {
  id: number;
  itemImageUrl: string;
  name: string;
  price: number;
  itemSource: string;
  indexPosition: number;
  tags: string[];
  flags: Flags;
}

export interface Flags {
  priceSensitive: boolean;
  weightSensitive: boolean;
}

export interface SetSubstituitonRequestParams {
  requestIds: number[];
  status: SubstitutionRequestStatus;
  runId: number;
}

export interface SubstitutionItemParams {
  catalogItemId: number;
  isCustomItem: boolean;
  itemImageUrl: string;
  name: string;
  orderId: number;
  orderItemId: number;
  position: number;
  price: number;
  quantity: number;
  substitutedBy: string;
  substitutionItemId: number;
}

export interface GetPDFReceiptResponse {
  getPdfReceipt: string;
}

export interface ContactBottomSheetProps {
  buttonText?: string;
  onButtonClick?: () => any;
  type?: string;
  orderId?: number;
  orderItemId?: number;
}

export interface BatchDetailState {
  batchTaskInfo: BatchTask | undefined;
  chatDetails: ChatDetails | undefined;
}

export enum DynamicTextType {
  driver = 'driver',
  customer = 'customer',
  addItem = 'addItem',
  default = 'default',
  addSubstitute = 'addSubstitute',
  dropItem = 'dropItem',
  noSubs = 'noSubs',
  recommendSubs = 'recommendSubs',
}

export enum UpcStatus {
  verified = 'verified',
  pending = 'pending',
  rejected = 'rejected',
  imageSaved = 'imageSaved',
  scanned = 'scanned',
}

export enum TutorialName {
  shopperGuide = 'shopperGuide',
  driverGuide = 'driverGuide',
  dispatcherGuide = 'dispatcherGuide',
}

export enum UpcOrderItemStatus {
  notPicked = 'notPicked',
  picked = 'picked',
  dropped = 'dropped',
}

export enum itemsForSection {
  todo = 'todo',
  pending = 'pending',
  done = 'done',
  all = 'all',
}

export enum UpcOrderStatus {
  pending = 'pending',
  completed = 'completed',
}
