import React, {useCallback} from 'react';
import {StyleProp, StyleSheet, ViewStyle} from 'react-native';

import {PTextInput} from '../../../../components/core/input/TextInput';
import {PTextInputProps} from '../../../../components/core/input/TextInput/type';
import {useColorThemeType} from '../../../../appStyles/hooks/theme';

interface CustomInputProps extends Omit<PTextInputProps, 'debounce'> {
  value: string;
  onTextChange: Function;
  placeHolder: string;
  debounce?: boolean;
  extraStyles?: StyleProp<
    Omit<ViewStyle, 'flexDirection' | 'justifyContent' | 'display'>
  >;
}

const CustomInput = (props: CustomInputProps) => {
  const {value, onTextChange, placeHolder} = props;
  const extraStyles = props.extraStyles as ViewStyle;

  const theme = useColorThemeType();

  const borderColor = value.length
    ? theme?.text.blue[300]
    : theme?.text?.gray[300];

  const handleChange = useCallback(
    (val: string) => onTextChange(val),
    [onTextChange],
  );

  return (
    <PTextInput
      {...props}
      value={value}
      placeholder={placeHolder}
      placeholderTextColor={theme?.text.gray[100]}
      debounce={false}
      returnKeyType="default"
      style={[
        extraStyles,
        {
          color: theme?.text?.blue?.[300],
        },
      ]}
      onChangeText={handleChange}
      containerStyle={[
        extraStyles,
        styles.inputContainer,
        {
          borderColor,
        },
      ]}
    />
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    borderWidth: 1,
    width: '100%',
    borderRadius: 20,
    marginTop: 20,
  },
});

export default React.memo(CustomInput);
