import {H5Text, H6Text} from '../../../../src/components/core/text';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../src/appStyles/hooks/theme';

import {Box} from '../../../../src/components/core/view';
import {Divider} from 'native-base';
import If from '../../../../src/components/conditional/If';
import React from 'react';
import {Shift} from '../../../../src/openShifts/types/openShifts';
import {StyleSheet} from 'react-native';

interface TakeShiftModalBodyProps {
  storeShift: Shift;
  selectingShift: boolean;
}

const TakeShiftModalBody: React.FC<TakeShiftModalBodyProps> = props => {
  const muli = useFontTheme('muli');
  const theme = useColorThemeType('main');
  const storeShift = props?.storeShift;
  const store = storeShift?.store;
  const storeName = store?.name || '';
  const time = storeShift?.time || '';
  const position = storeShift?.position || '';
  return (
    <Box style={styles.bodyBox}>
      <If condition={!props?.selectingShift}>
        <H5Text
          style={[
            styles.alignment,
            {
              fontFamily: muli?.[700]?.normal,
              color: theme?.text?.blue?.[300],
            },
          ]}>
          {time}
        </H5Text>
        <H5Text
          style={[
            styles.alignment,
            {
              fontFamily: muli?.[700]?.normal,
              color: theme?.text?.blue?.[300],
            },
          ]}>
          at {storeName}
        </H5Text>
        <Divider bgColor={'gray.400'} style={[styles.divider]} />
        <H5Text
          style={[
            styles.alignment,
            {
              fontFamily: muli?.[700]?.normal,
              color: theme?.text?.blue?.[300],
            },
          ]}>
          Designation
        </H5Text>
        <H5Text
          style={[
            styles.alignment,
            {
              fontFamily: muli?.[700]?.normal,
              color: theme?.text?.blue?.[300],
            },
          ]}>
          as {position}
        </H5Text>
        <Divider bgColor={'gray.400'} style={styles.divider} />
      </If>
      <If condition={props?.selectingShift}>
        <H6Text
          style={[
            styles.alignment,
            styles.paragraph,
            {
              fontFamily: muli?.[700]?.normal,
              color: theme?.text?.gray?.[100],
            },
          ]}>
          Taking this OpenShift will assign it to you and appear on your
          schedule. You will responsible for this shift at that point.
        </H6Text>
      </If>
    </Box>
  );
};

export default React.memo(TakeShiftModalBody);
const styles = StyleSheet.create({
  bodyBox: {
    marginTop: 20,
  },
  alignment: {
    textAlign: 'left',
    marginBottom: 10,
  },
  paragraph: {
    textAlign: 'center',
    marginBottom: 20,
  },
  divider: {
    marginBottom: 10,
  },
});
