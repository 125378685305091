import React from 'react';
import Touchable from './Touchable';
import {TouchableProps} from './type';

import {StyleSheet, TouchableOpacity, ViewStyle} from 'react-native';

const CenterTouchable: React.FC<TouchableProps<TouchableOpacity>> =
  React.forwardRef((props, ref) => {
    const propsToSpread = {
      ...props,
      style: {...styles.CenterTouchable, ...(props.style as ViewStyle)},
    };
    return (
      <Touchable {...propsToSpread} ref={ref}>
        {props.children}
      </Touchable>
    );
  });

const styles = StyleSheet.create({
  CenterTouchable: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
});

export default React.memo(CenterTouchable);
