import {StyleSheet} from 'react-native';
import React, {useCallback} from 'react';
import {Touchable} from '@buncha/components/core/touchable';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';
import LocationIcon from '@buncha/components/core/icon/LocationIcon';
import {H6Text} from '@buncha/components/core/text';
import {HBox} from '@buncha/components/core/view';
import {navigation} from '@buncha/config/navigation';
import {ShopperScreens} from '@buncha/home/navigation/types';
import {ItemDetailsScreen} from '@buncha/itemDetails/navigation/types';

const LocationTypo = (props: {
  aisle: string;
  context: string;
  orderItemId: number;
  showBottomLine?: boolean;
  esIndex: string;
}) => {
  const theme = useColorThemeType('main');
  const muliFont = useFontTheme('muli');
  const showBottomLine = true;

  const navigateToItemLocationPage = useCallback(() => {
    navigation.navigate(ShopperScreens.ItemDetails, {
      screen: ItemDetailsScreen.ItemLocationPage,
      params: {
        orderItemId: props.orderItemId,
        esIndex: props.esIndex,
      },
    });
  }, [props.esIndex, props.orderItemId]);

  return (
    <Touchable
      style={[styles.locationContainer]}
      onPress={navigateToItemLocationPage}>
      <HBox>
        <LocationIcon viewBox="-3 0 26 20" fill={theme?.icon?.gray?.[100]} />
        <H6Text
          numberOfLines={1}
          style={[
            styles.textHeader,
            showBottomLine && styles.locationTypo,
            {
              fontFamily: muliFont[700].normal,
              color: theme?.text.gray[100],
            },
          ]}>
          {`Located in ${props?.aisle} on the ${props?.context}`}
        </H6Text>
      </HBox>
    </Touchable>
  );
};

export default React.memo(LocationTypo);

const styles = StyleSheet.create({
  textHeader: {
    textAlign: 'left',
    flex: 1,
  },
  priceText: {
    textAlign: 'left',
    marginVertical: 5,
  },
  locationTypo: {
    textDecorationLine: 'underline',
  },
  locationContainer: {
    paddingTop: 10,
  },
});
