import {Dimensions, StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  divider: {
    marginVertical: 15,
    flex: 1,
    marginLeft: -20,
    width: Dimensions.get('screen').width,
  },
  actionButton: {
    borderRadius: 50,
    height: 52,
    marginTop: 10,
  },
});
