import {Box, HBox} from '@buncha/components/core/view';
import {H3Text, H6Text} from '@buncha/components/core/text';
import React, {useCallback} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import BottomSheet from '@buncha/components/composites/bottomSheet';
import {CallDriverBottomSheetStyle} from './styles';
import {CustomFlatList} from '@buncha/components/core/list';
import {Divider} from 'native-base';
import {Linking} from 'react-native';
import ProfileIcon from '@buncha/components/core/icon/ProfileIcon';
import {Touchable} from '@buncha/components/core/touchable';
import {UserStub} from '@buncha/onBoarding/types';
import font from '@buncha/appStyles/style/font';

interface Props {
  open: boolean;
  onClose: () => void;
  driverList: UserStub[];
}
const CallDriverBottomSheet = (props: Props) => {
  const {open, onClose, driverList} = props;
  const muliFont = useFontTheme('muli');
  const theme = useColorThemeType();

  const callHandler = useCallback((phoneNumber: string) => {
    Linking.openURL(`tel:${phoneNumber}`);
  }, []);

  const renderItem = useCallback(
    ({item}: {item: UserStub; index: number}) => {
      return (
        <HBox style={CallDriverBottomSheetStyle.renderBox}>
          <HBox style={CallDriverBottomSheetStyle.buyerNameBox}>
            <ProfileIcon viewBox="-4 -4 28 28" fill={theme?.text.blue[300]} />
            <H6Text
              style={[
                CallDriverBottomSheetStyle.buyerName,
                {color: theme?.text.blue[300]},
              ]}>
              {item.firstName} {item.lastName}
            </H6Text>
          </HBox>
          <Touchable
            onPress={() => callHandler(item.phoneNumber)}
            style={[
              CallDriverBottomSheetStyle.contactBoxStyled,
              {backgroundColor: theme?.background.blue[100]},
            ]}>
            <H6Text
              style={[
                {
                  fontFamily: muliFont[600].normal,
                  color: theme?.background.white[100],
                },
              ]}>
              Call
            </H6Text>
          </Touchable>
        </HBox>
      );
    },
    [
      callHandler,
      muliFont,
      theme?.background.blue,
      theme?.background.white,
      theme?.text.blue,
    ],
  );

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Box style={CallDriverBottomSheetStyle.mainContainer}>
        <Box style={CallDriverBottomSheetStyle.titleContainer}>
          <H3Text
            style={{
              color: theme?.text.blue[300],
              fontFamily: font.fontTheme.mortise[700].normal,
            }}>
            Contact Driver
          </H3Text>
          <H6Text
            style={{
              color: theme?.text.gray[100],
              fontFamily: font.fontTheme.muli[600].normal,
            }}>
            Select the shopper you want to contact
          </H6Text>
        </Box>
        <Divider style={CallDriverBottomSheetStyle.divider} />
        <CustomFlatList data={driverList} renderItem={renderItem} />
      </Box>
    </BottomSheet>
  );
};

export default React.memo(CallDriverBottomSheet);
