import {
  DeliveryLocationStatus,
  OrderItemStatus,
  UPCOrderItem,
  UpcStatus,
} from '@buncha/batchDetails/types';

import {ShopperScreens} from '@buncha/home/navigation/types';

export interface ScanUPCParams {
  catalogItemId: number;
  storeIndex: string;
  upc?: string;
  images?: string[];
  orderItemId?: number;
  lat?: number;
  isUpcOrder?: boolean;
  lon?: number;
}

export interface UPCItemLocationStatus {
  locationDescription?: {
    aisle: string;
    context: string;
  };
  imageUrl?: string;
  price?: number;
  substituionData?: any;
}

export interface State {
  item?: OrderItem;
  upcImagesModal: boolean;
  scannerActive: boolean;
  upcImages: string[];
  scannerExpanded: boolean;
  buyerData: BuyerData[];
  formData: FormData[];
  remainingCustomers: number;
  upcOrderItem?: OrderItem;
  upcScannedImages: string[];
  upcCrowdSourceProcessCompleted: boolean;
  scanAgainButtonLoading: boolean;
}

export interface PendingUPCOrder {
  id: number;
  storeName: string;
  storeLogo: string;
  esIndex: string;
  shopperId: number;
  orderStatus: OrderStatus;
}
export interface ItemScannerState {
  itemLocationData?: UPCItemLocationStatus;
  selectedCatalogStoreForUPCRun?: PendingUPCOrder;
  upcOrderItem?: UPCOrderItem;
}
export interface FormData {
  orderItemId: number;
  price?: number;
  totalPrice?: number;
  quantity?: number;
  weightInfo?: {
    weight?: number;
    unit?: string;
  }[];
  dropped: boolean;
}

export interface FormUpdate {
  orderItemId: number;
  update: FormDataUpdate;
  updateValue: any;
  weightIndex?: number;
}

// eslint-disable-next-line no-shadow
export enum FormDataUpdate {
  price = 'price',
  quantity = 'quantity',
  weight = 'weight',
  unit = 'unit',
  dropped = 'dropped',
}

// eslint-disable-next-line no-shadow
export enum MandatoryFieldNames {
  weightFound = 'weightFound',
  quantityFound = 'quantityFound',
  pricePerQuantity = 'pricePerQuantity',
}

// eslint-disable-next-line no-shadow
export enum UpdatePage {
  default = 'default',
  carousel = 'carousel',
}

export interface BuyerData {
  id: number;
  firstName: string;
  lastName: string;
  orderItemId: number;
  quantity: number;
  updatedQuantity: number;
  itemStatus: OrderItemStatus;
  sensitivity: Sensitivity;
  isItemPickedOnScan: boolean;
  whatPageToShow: UpdatePage;
  whatToShow: {
    fieldName: MandatoryFieldNames;
  }[];
  weightInfo: {
    id: number;
    unit: string;
    weight: number;
  }[];
  mandatoryFields: {
    fieldName: MandatoryFieldNames;
  }[];
  order: {
    deliveryLocation: {
      deliveryStatus: DeliveryLocationStatus;
    };
  };
}

// eslint-disable-next-line no-shadow
export enum Sensitivity {
  none = 'none',
  priceSensitive = 'priceSensitive',
  weightSensitive = 'weightSensitive',
  priceAndWeightSensitive = 'priceAndWeightSensitive',
}

export interface OrderItem {
  name: string;
  price: number;
  isPriceUpdatable: boolean;
  itemImageUrl: string;
  upcImages: string[];
  catalogItemId: number;
  isCustomItem: boolean;
  order: {
    id: number;
    run: {
      store: {
        id: number;
      };
    };
  };
  upcStatus: UpcStatus;
  itemUnit: string;
  shopperItemChangesForShopper: ShopperItemChangesForShopper;
  category: string;
  id: number;
  itemImageUrls: {
    largeImages: string[];
  };
  itemQuantity: string;
  quantity: number;
  totalupdatedQuantityByShopper: number;
  cartItem: {
    isCustomItem: boolean;
  };
  options: string;
}

export interface ShopperItemChangesForShopper {
  updatedQuantity: number;
  weightInfo: {
    weight: number;
    unit: string;
  };
  updatedPricePerQuantity: number;
}

export interface S3Image {
  id: number;
  fileName: string;
  fileUrl: string;
}

export interface ScanUPCForAllItemsParams {
  upc: string;
  catalogItemId: number;
  storeIndex: string;
  runId: number;
  lon?: number;
  lat?: number;
}

export interface ScanResultForAllItemsResponse {
  scanUPCForAllItems: {
    message: string;
    scanned: boolean;
  };
}

export interface ScanUPCForAllItemsImagesParams {
  images: string[];
  catalogItemId: number;
  storeIndex: string;
  runId: number;
  lon?: number;
  lat?: number;
}

export interface GetBuyerDataResponse {
  itemWithBuyersAndSub: BuyerData[];
}

export interface UploadImageUrlResponse {
  itemImageUpload: {
    uri: string;
  };
}

export interface UploadItemImageResponse {
  updateCustomItemImage: boolean;
}

export interface GetShopperItemChangesResponse {
  updateShopperItemChanges: boolean;
}

export interface ShopperItemChanges {
  orderItemId: number;
  price?: number;
  quantity?: number;
  weightInfo?: {
    weight?: number;
    unit?: string;
  }[];
}

export interface ItemScannerRoute {
  orderItemId?: number;
  storeIndex?: string;
  runId?: number;
  quantity?: number;
  returnPage?: ShopperScreens;
}

export interface UPCScanFormData {
  index: number;
  icons: any;
  header: string;
  bodyText: string;
  bodySubText?: string;
  ctaTitle?: string;
  CTAAction?: any;
  isComplete: boolean;
}

export interface CaptureItemParams {
  isUpcOrder: boolean;
  orderItemId: number;
  aisle?: string;
  storeContext?: string;
  locationImage?: string;
  price?: number;
  esIndex: string;
}

export interface BottomSheetProps {
  onSheetClose: () => any;
  onPress: any;
}

export interface SubstituteInUPCParams {
  upcOrderItemId: number;
  catalogItemId?: number;
  name: string;
  price: number;
  isCustomItem: boolean;
  itemImageUrl: string;
  note: string;
}
// eslint-disable-next-line no-shadow
enum OrderStatus {
  pending = 'pending',
  completed = 'completed',
}
