import {AddItemCell, AddItemContextState} from '../types';
import {AddItemContext, initialState} from '../context/AddItem';
import {
  CHECK_FOR_FROZEN_ITEMS,
  SEARCH_CATALOG,
} from '../../config/graphQL/documentNode/queries';
import {Dispatch, useContext, useReducer, useState} from 'react';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {
  SearchCatalog,
  SearchItemsForSubParams,
  SearchResults,
} from '../../itemDetails/types/itemReplacement';

import {ADD_ITEM} from '../../config/graphQL/documentNode/mutation';
import {AddItemParams} from '../../itemDetails/types/CustomItem';
import {Analytics} from '@buncha/config/analytics/analytics';
import {SearchCatalogResponse} from '../../config/graphQL/type';
import {ToastType} from '../../components/composites/notification/type';
import {gqlService} from '../../config/graphQL';
import {useToastMessage} from '../../components/composites/notification';

const reducer = {
  setSelectedItem: function (
    state: AddItemContextState,
    action: PayloadAction<AddItemCell | undefined>,
  ) {
    state.selectedItem = action.payload;
    state.selectedQuantity = 1;
  },
  setSelectedQuantity: function (
    state: AddItemContextState,
    action: PayloadAction<number>,
  ) {
    state.selectedQuantity = action.payload;
  },
  setSearchResults: function (
    state: AddItemContextState,
    action: PayloadAction<AddItemCell[]>,
  ) {
    state.searchResults = action.payload;
  },
};

const slice = createSlice({
  initialState,
  name: 'addItem',
  reducers: reducer,
});

export function useAddItemReducer() {
  const addItemReducer = useReducer(slice.reducer, initialState);
  return addItemReducer;
}

export function useAddItemContext() {
  return useContext(AddItemContext);
}

export const AddItemActions = slice.actions;

export function useSearchItemsForAddItems(
  dispatch: Dispatch<any> | null,
  state: AddItemContextState,
) {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loading, setLoading] = useState(false);
  const [moreItems, setMoreItems] = useState(true);

  const resetSearchResults = () => {
    if (dispatch) {
      dispatch(AddItemActions.setSelectedItem(undefined));
      dispatch(AddItemActions.setSearchResults([]));
      setMoreItems(true);
    }
  };

  const handleAnalyticsEvents = (
    params: SearchItemsForSubParams,
    resultExists: number,
    searchItems: SearchResults[],
    totalResult: number,
    searchCatalog: SearchCatalog | undefined,
  ) => {
    const searchSource = 'Add Item';
    const eventProps = {
      source: searchSource,
      storeId: params.storeId,
      searchItem: params.searchQuery,
      runId: params.runId,
    };
    if (
      totalResult === 0 ||
      searchCatalog?.searchRelevance?.[0]?.key === 'other'
    )
      Analytics.eventWithProps('Searched but not found', {
        ...eventProps,
        from: params.from,
        size: params.size,
      });
    if (searchCatalog?.searchRelevance?.length && resultExists)
      Analytics.eventWithProps('Searched Result', {
        ...eventProps,
        type: 'CTA',
        top5_searched_name: searchItems
          .map((item: SearchResults) => item.name)
          .slice(0, 5),
      });
  };

  const updateSearchResults = (
    params: SearchItemsForSubParams,
    searchItems: SearchResults[],
  ) => {
    if (dispatch)
      dispatch(
        AddItemActions.setSearchResults([
          ...(params.from ? state.searchResults : []),
          ...searchItems,
        ]),
      );

    if (searchItems.length < params.size) setMoreItems(false);
  };

  const searchItemsForAddition = async (params: SearchItemsForSubParams) => {
    if (!params.searchQuery || !params.storeId) return;
    try {
      setLoading(true);
      resetSearchResults();

      const res = await gqlService?.query<SearchCatalogResponse>({
        query: SEARCH_CATALOG,
        fetchPolicy: 'network-only',
        variables: params,
      });

      const searchCatalog = res?.data?.searchCatalog;
      const searchItems = searchCatalog?.items ?? [];
      const resultExists = searchItems?.length;
      const totalResult = searchCatalog?.totalResult ?? 0;

      handleAnalyticsEvents(
        params,
        resultExists,
        searchItems,
        totalResult,
        searchCatalog,
      );

      if (resultExists && dispatch) updateSearchResults(params, searchItems);
      if (searchItems.length < params.size) setMoreItems(false);

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {searchItemsForAddition, loading, moreItems};
}

export function useAddItem() {
  const [addItemLoading, setAddItemLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const addItem = async (params: AddItemParams, callback?: Function) => {
    setAddItemLoading(true);
    try {
      setErrorMessage('');
      params.source = 'shopper-addSearchItem';
      const response = await gqlService?.mutation({
        mutation: ADD_ITEM,
        fetchPolicy: 'network-only',
        variables: params,
      });
      const orderItemId = response?.data?.addItemInRun?.id || 0;
      if (orderItemId && callback) callback(orderItemId);
      setAddItemLoading(false);
    } catch (error: any) {
      setAddItemLoading(false);
      setErrorMessage(error?.message);
    }
  };

  return {addItemLoading, addItem, errorMessage};
}

export function useCheckForFrozenItems() {
  const [checkingItemForPick, setCheckingItemForPick] = useState(false);
  const checkForFrozenItems = async (runId?: number) => {
    if (!runId) return;
    setCheckingItemForPick(true);
    try {
      const response = await gqlService?.mutation({
        mutation: CHECK_FOR_FROZEN_ITEMS,
        variables: {runId: runId},
        fetchPolicy: 'network-only',
      });
      if (response?.data) {
        const allowToPick = response?.data?.checkForFrozenItems;
        setCheckingItemForPick(false);
        return allowToPick;
      }
      setCheckingItemForPick(false);
      return false;
    } catch (err: any) {
      setCheckingItemForPick(false);
      return false;
    }
  };

  return {checkingItemForPick, checkForFrozenItems};
}
