import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {BatchDetailState} from '../types';
import {BatchTask, ChatDetails} from '@buncha/AboutShift/types';

const initialState: BatchDetailState = {
  batchTaskInfo: undefined,
  chatDetails: undefined,
};

const reducer = {
  setBatchTaskInfo: function (
    state: BatchDetailState,
    action: PayloadAction<BatchTask | undefined>,
  ) {
    const payload = action.payload;
    state.batchTaskInfo = payload;
  },
  setChatDetails: function (
    state: BatchDetailState,
    action: PayloadAction<ChatDetails | undefined>,
  ) {
    state.chatDetails = action.payload;
  },
};

function initSlice() {
  const slice = createSlice({
    name: 'BatchDetails',
    initialState,
    reducers: reducer,
  });
  return slice;
}

export default initSlice();
