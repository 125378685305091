import {ActivityIndicator, ScrollView} from 'react-native';
import {Box, HBox} from '../../../../components/core/view';
import {H4Text, H6Text} from '../../../../components/core/text';
import React, {useCallback} from 'react';

import CirclePlusIcon from '../../../../components/core/icon/CirclePlusIcon';
import {CloseIcon} from 'native-base';
import If from '../../../../components/conditional/If';
import ImageCell from '../ImageCell';
import SceneIcon from '../../../../components/core/icon/SceneIcon';
import {Touchable} from '../../../../components/core/touchable';
import {isWeb} from '../../../../utils/common';
import {styles} from '../styles';
import {useColorThemeType} from '../../../../appStyles/hooks/theme';

interface ImageSectionProps {
  showCaptureModeFunc: (data?: any) => Promise<void>;
  uploadLoading: boolean;
  title: string;
  subtitle: string;
  imageList: string[];
  closeBottomSheet: () => void;
  handleImageDelete: Function;
  isUPCMode?: boolean;
}

const ImageSection = (props: ImageSectionProps) => {
  const theme = useColorThemeType();
  const {uploadLoading, title, subtitle} = props;
  const images = props?.imageList;
  const imagesExist = Boolean(images.length);
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);

  const openCamera = useCallback(async () => {
    if (isWeb()) hiddenFileInput?.current?.click();
    else await props.showCaptureModeFunc();
  }, [props]);

  const handleWebFileUploader = useCallback(
    (data: any) => {
      props.showCaptureModeFunc(data);
    },
    [props],
  );

  return (
    <Box key={images.length}>
      <HBox style={styles.headerBox}>
        <Box style={styles.infoBox}>
          <H4Text style={[styles.title, {color: theme?.text.blue[300]}]}>
            {title}
          </H4Text>
          <H6Text
            style={[styles.textAlignLeft, {color: theme?.text.gray[100]}]}>
            {subtitle}
          </H6Text>
        </Box>
        <Touchable onPress={props.closeBottomSheet} style={styles.crossIconBox}>
          <CloseIcon />
        </Touchable>
      </HBox>
      <If condition={uploadLoading}>
        <Box style={styles.loadingBox}>
          <ActivityIndicator />
        </Box>
      </If>
      <If condition={!uploadLoading}>
        <HBox style={styles.imagesBox}>
          <If condition={!imagesExist}>
            <Touchable onPress={openCamera}>
              <Box
                style={[
                  styles.addImagePlaceHolderBox,
                  {
                    backgroundColor: theme?.background.gray[200],
                  },
                ]}>
                <SceneIcon />
                <CirclePlusIcon style={styles.circlePlusIcon} />
              </Box>
            </Touchable>
          </If>
          <If condition={imagesExist}>
            <ScrollView
              horizontal={true}
              showsHorizontalScrollIndicator={false}>
              {images.map((image: string) => (
                <ImageCell
                  url={image}
                  key={image}
                  handleImageDelete={props.handleImageDelete}
                />
              ))}
            </ScrollView>
            <HBox style={[styles.addImageIcon]}>
              <Touchable onPress={openCamera}>
                <CirclePlusIcon />
              </Touchable>
            </HBox>
          </If>
          <If condition={isWeb()}>
            <input
              type="file"
              accept="image/*"
              onChange={(data: any) => handleWebFileUploader(data)}
              style={styles.webFileUpload}
              ref={hiddenFileInput}
            />
          </If>
        </HBox>
      </If>
    </Box>
  );
};

export default React.memo(ImageSection);
