import React, {useEffect, useState} from 'react';

import {CustomFlatListProps} from './type';
import {FlatList} from 'react-native';

/**
 * @example
   <CustomFlatList
    colorThemeType="warning"
    style={{height: 100}}
    key={2}
    loading={true}
    data={flatListData1}
    renderItem={({ item, index }) => (<Text key={index}>{item.firstName}</Text>)}
    loadingSkeleton={<Skeleton margin={1} />}
    userOffset={2}
    loadMore={true}
    onEndReachedAtt={(info, offset) => console.log('data ==> ', info, offset)}
  />
 */

const CustomFlatList: React.FC<CustomFlatListProps<any>> = React.forwardRef(
  (props, ref) => {
    const [offset, setOffset] = useState<number>(
      props.initialOffset || props.data?.length || 0,
    ); // for load more

    const offsetHandler = () => {
      setOffset(offset + (props.userOffset || 0));
    };
    const propsToSpread = {
      ...props,
      style: [props.style],
    };
    let footerComponent: any = props.ListFooterComponent;
    if (props.loading) {
      const tempArray = Array.from(
        Array(props?.numOfTimesToRender || 5).keys(),
      );
      footerComponent = (
        <React.Fragment>
          {tempArray.map(i => (
            <React.Fragment key={i}>{props.loadingSkeleton}</React.Fragment>
          ))}
        </React.Fragment>
      );
    }

    useEffect(() => {
      if (props.setRef) props.setRef({setOffset});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!props.loadMore)
        setOffset(props.initialOffset || props.data?.length || 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.loadMore]);

    return (
      <React.Fragment>
        <FlatList
          ref={ref}
          initialNumToRender={100}
          {...propsToSpread}
          onEndReachedThreshold={0.4}
          onEndReached={info => {
            if (props.loadMore) {
              offsetHandler();
              if (typeof props.onEndReachedAtt === 'function')
                props.onEndReachedAtt(info, offset);
            }
          }}
          ListFooterComponent={footerComponent}
        />
      </React.Fragment>
    );
  },
);

export default React.memo(CustomFlatList);
