import {Divider, Skeleton} from 'native-base';
import React, {useCallback, useEffect} from 'react';
import {
  useGetGroupedOrders,
  useVanLoadAtContext,
} from '@buncha/driver/hooks/VanLoadAt';

import {Box} from '@buncha/components/core/view';
import BuyerOrderListCell from './BuyerOrderListCell';
import {BuyerOrderListStyle} from './styles';
import {CustomFlatList} from '@buncha/components/core/list';
import {GroupedOrder} from '@buncha/driver/types/vanLoadAt';
import ListHeader from './ListHeader';

interface Props {
  runId: number;
  infoButtonPress: () => void;
}

const BuyerOrderList = (props: Props) => {
  const {runId} = props;
  const {state, dispatch} = useVanLoadAtContext();

  const {getGroupedOrder, loading} = useGetGroupedOrders(dispatch);
  const renderItem = useCallback(
    ({item, index}: {item: GroupedOrder; index: number}) => {
      return <BuyerOrderListCell index={index} groupedOrder={item} />;
    },
    [],
  );

  const separator = useCallback(() => {
    return <Divider style={BuyerOrderListStyle.divider} />;
  }, []);

  useEffect(() => {
    if (runId) getGroupedOrder(runId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId]);

  if (!state?.groupedOrders?.length) return <></>;

  return (
    <CustomFlatList
      data={state?.groupedOrders}
      renderItem={renderItem}
      ListHeaderComponent={
        <ListHeader infoToolTipHandler={props?.infoButtonPress} />
      }
      stickyHeaderIndices={[0]}
      ItemSeparatorComponent={separator}
      loading={loading}
      numOfTimesToRender={2}
      loadingSkeleton={
        <Box style={BuyerOrderListStyle.skeletonContainer}>
          <Skeleton marginTop={5} height={75} />
        </Box>
      }
    />
  );
};

export default React.memo(BuyerOrderList);
