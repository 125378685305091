import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {CustomImage} from '@buncha/components/core/Image';
import {H6Text} from '@buncha/components/core/text';
import {HBox} from '@buncha/components/core/view';
import React from 'react';
import {getDefaultImageUrl} from '@buncha/utils/common';
import {styles} from './style';

interface PhaseImageComponentProps {
  url: string;
  header: string;
}

const PhaseImageComponent: React.FC<PhaseImageComponentProps> = props => {
  const muli = useFontTheme('muli');
  const defaultImage = getDefaultImageUrl();
  const theme = useColorThemeType('main');
  return (
    <HBox style={styles.imageBox}>
      <CustomImage
        onErrorSource={defaultImage}
        style={styles.image}
        source={props?.url || ''}
      />

      <H6Text
        style={[
          styles.textAlign,
          {
            fontFamily: muli?.[700]?.normal,
            color: theme?.text?.blue?.[300],
          },
        ]}>
        {props?.header || ''}
      </H6Text>
    </HBox>
  );
};

export default React.memo(PhaseImageComponent);
