import AlcoholDisclaimer from '@buncha/itemDetails/components/AlcoholDisclaimer';
import {BottomButtonProps} from './type';
import {Box} from '../../core/view';
import {Divider} from 'native-base';
import If from '../../../components/conditional/If';
import LegalInfo from '@buncha/common/components/LegalInfo';
import {PButton} from '../../core/Button';
import React from 'react';
import {styles} from './styles';

const BottomButton: React.FC<BottomButtonProps> = React.forwardRef(
  (props, ref) => {
    return (
      <React.Fragment>
        <If condition={props.showDividers}>
          <Divider />
        </If>
        <Box
          colorThemeType={props.colorThemeType}
          style={[styles.defaultContainer, props?.containerStyle]}>
          <If condition={props?.showAlcoholicItemDisclaimer}>
            <AlcoholDisclaimer />
          </If>
          <PButton
            {...props}
            style={[styles.bottomButton, props.style]}
            ref={ref}
          />
          <If condition={props?.showLegalInfo}>
            <LegalInfo />
          </If>
        </Box>
      </React.Fragment>
    );
  },
);

export default React.memo(BottomButton);
