import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    paddingVertical: 5,
    paddingHorizontal: 20,
  },
  textStyle: {
    justifyContent: 'flex-start',
  },
  iconStyle: {
    justifyContent: 'flex-end',
  },
});
