export const ItemWeightUnits = [
  'lb',
  'oz',
  'fl',
  'gm',
  'kg',
  'ct',
  'gal',
  'pint',
  'each',
  'NA',
];

export const RunReceiptPrefixURL = 'https://users-order-images.gobuncha.com';
export const upcImagePrefixURL = 'https://upcimages.gobuncha.com';
export const awsRegion = 'us-east-1';

export const groupConversationFilter = {
  custom: {
    status: ['==', 'open'],
    name: ['==', 'operation_support_chat_group'],
  },
};
