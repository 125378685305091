import {CustomText, H4Text, H6Text} from '@buncha/components/core/text';
import React, {useCallback, useMemo, useState} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {BottomButton} from '@buncha/components/composites/Button';
import BottomSheet from '@buncha/components/composites/bottomSheet';
import Box from '@buncha/components/core/view/Box';
import If from '@buncha/components/conditional/If';
import {PTextInput} from '@buncha/components/core/input/TextInput';
import {StyleSheet} from 'react-native';
import font from '@buncha/appStyles/style/font';

interface UpdateQuantitySheetProps {
  open: boolean;
  onClose: () => void;
  orderItemId: number;
  quantity: number;
  handleUpdateQuantity: (qty: number) => void;
  loading?: boolean;
}
const UpdateQuantitySheet: React.FC<UpdateQuantitySheetProps> = props => {
  const [updatedQty, setupdatedQty] = useState(String(props?.quantity));
  const [hasError, setHasError] = useState(false);

  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');

  const handleChange = useCallback((qty: string) => {
    if (Number(qty) >= 1 && Number(qty) <= 100) setHasError(false);
    else setHasError(true);
    setupdatedQty(qty);
  }, []);

  const getBorderColor = useMemo(() => {
    if (hasError) return theme?.text?.red[100];
    return theme?.text?.blue?.[300];
  }, [hasError, theme?.text?.blue, theme?.text?.red]);

  return (
    <BottomSheet open={props.open} onClose={props?.onClose}>
      <Box style={styles.boxContainer}>
        <H4Text
          style={[
            styles.text,
            {
              color: theme?.text.blue[300],
            },
          ]}>
          Update Order Item Quantity
        </H4Text>
        <H6Text
          style={[
            styles.text,
            {
              color: theme?.text.blue[300],
            },
          ]}>
          Adjust the quantity of the ordered item based on the customer's
          request.
        </H6Text>
        <PTextInput
          value={updatedQty}
          placeholder="0"
          placeholderTextColor={theme?.text.gray[300]}
          clearInput
          debounce={false}
          returnKeyType="done"
          style={{
            fontFamily: muliFont[700].normal,
            ...font.FontSize.fontH3,
            color: theme?.text.blue[300],
          }}
          onChangeText={handleChange}
          containerStyle={[
            styles.inputContainer,
            {
              borderColor: getBorderColor,
            },
          ]}
          keyboardType="number-pad"
        />
        <If condition={hasError}>
          <CustomText style={[styles.hasError, {color: theme?.text?.red[100]}]}>
            The updated quantity must be greater than 0 and less than 100.
          </CustomText>
        </If>
        <BottomButton
          style={[styles.buttonContainer]}
          title="Update Quantity"
          onPress={() => props.handleUpdateQuantity(Number(updatedQty))}
          disabled={
            props?.loading || hasError || Number(updatedQty) === props?.quantity
          }
          loadingText="Updating ..."
          isLoading={props?.loading}
        />
      </Box>
    </BottomSheet>
  );
};

export default React.memo(UpdateQuantitySheet);

const styles = StyleSheet.create({
  boxContainer: {
    width: '95%',
  },
  buttonContainer: {
    width: '100%',
    marginTop: 10,
  },
  text: {
    textAlign: 'left',
  },
  inputContainer: {
    borderWidth: 1,
    height: 51,
    width: '100%',
    borderRadius: 50,
    marginTop: 10,
  },
  hasError: {
    ...font.FontSize.fontP10,
    marginBottom: 5,
    textAlign: 'left',
  },
});
