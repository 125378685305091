import {AddIcon, Tooltip} from 'native-base';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {H5Text} from '../../core/text';
import {HBox} from '../../core/view';
import MinusIcon from '../../../components/core/icon/MinusIcon';
import {StyleSheet} from 'react-native';
import {Touchable} from '../../../components/core/touchable';

interface QuantitySelectorProps {
  height?: number;
  width?: string | number;
  quantity: number;
  maxQuantity?: number;
  minQuantity?: number;
  onQuantityUpdate: (quantity: number) => void;
  incrementCallback?: () => void;
  decrementCallback?: () => void;
  allowQunatityToZero?: boolean;
  disableQuantity?: boolean;
  showTooltip?: boolean;
  disableIncrement?: boolean;
  disableDecrement?: boolean;
  hasSlotFull?: boolean;
}

const QuantitySelector: React.FC<QuantitySelectorProps> = props => {
  const theme = useColorThemeType('main');
  const fontTheme = useFontTheme();

  const {
    onQuantityUpdate,
    maxQuantity,
    minQuantity,
    disableIncrement,
    hasSlotFull,
  } = props;
  const [quantity, setQuantity] = useState(props.quantity || 1);

  const [show, setShow] = useState(true);
  const [btnClicked, setBtnClicked] = useState(false);

  const isMaxQuantityAchieved = Boolean(maxQuantity && quantity >= maxQuantity);
  const isMinQuantityAchieved = Boolean(minQuantity && quantity <= minQuantity);
  const disableAdd =
    props.disableQuantity ||
    isMaxQuantityAchieved ||
    disableIncrement ||
    hasSlotFull;

  const disableDecrement = props.disableDecrement;

  const disableMinus =
    props.disableQuantity || disableDecrement || isMinQuantityAchieved;

  useEffect(() => {
    if (disableDecrement)
      setQuantity(props.quantity === 1 ? quantity : quantity + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableDecrement]);

  const openToolTip = useMemo(() => {
    if (
      (isMaxQuantityAchieved || disableIncrement) &&
      show &&
      props.showTooltip &&
      btnClicked
    )
      return true;

    return false;
  }, [
    btnClicked,
    disableIncrement,
    isMaxQuantityAchieved,
    props.showTooltip,
    show,
  ]);

  useEffect(() => {
    if (props.quantity) setQuantity(props.quantity);
  }, [props.quantity]);
  const decrement = useCallback(() => {
    const minVal = 0;

    if (props.decrementCallback) return props.decrementCallback();
    if (quantity - 1 > minVal) setQuantity(quantity - 1);
    onQuantityUpdate(quantity - 1);
  }, [onQuantityUpdate, props, quantity]);

  const increment = useCallback(() => {
    setBtnClicked(true);
    if (isMaxQuantityAchieved) return;

    if (props.incrementCallback) return props.incrementCallback();
    setQuantity(quantity + 1);
    onQuantityUpdate(quantity + 1);
  }, [isMaxQuantityAchieved, props, quantity, onQuantityUpdate]);

  let duration = 1000;
  useEffect(() => {
    if (isMaxQuantityAchieved) {
      const id = setTimeout(() => {
        setShow(false);
      }, duration);
      return () => {
        setShow(true);
        clearInterval(id);
      };
    }
  }, [duration, isMaxQuantityAchieved]);

  return (
    <HBox
      style={[
        styles.quantityChanger,
        {
          borderColor: theme?.background.gray[100],
          height: Number(props.height || 50),
          width: Number(props.width),
        },
      ]}>
      <Touchable
        onPress={decrement}
        style={styles.minuIcon}
        disabled={disableMinus}>
        <MinusIcon
          style={styles.minuIcon}
          fill={disableMinus ? theme?.text.gray[200] : theme?.text.gray[100]}
          width="18px"
          height="18px"
          viewBox="0 0 1 1.95"
        />
      </Touchable>
      <H5Text
        style={{
          fontFamily: fontTheme[900].normal,
          color: theme?.text.blue[300],
        }}>
        {quantity}
      </H5Text>
      <Tooltip
        isOpen={openToolTip}
        label="Max limit reached"
        placement="top"
        style={styles.toolTip}>
        <Touchable onPress={increment} disabled={disableAdd}>
          <AddIcon
            size={4}
            color={
              disableAdd ? theme?.background.gray[200] : theme?.text.gray[100]
            }
          />
        </Touchable>
      </Tooltip>
    </HBox>
  );
};

export default React.memo(QuantitySelector);

const styles = StyleSheet.create({
  quantityChanger: {
    flexDirection: 'row',
    borderRadius: 30,
    alignItems: 'center',
    borderWidth: 1,
    paddingLeft: 10,
    paddingRight: 10,
  },
  minuIcon: {
    marginTop: 1,
    alignSelf: 'center',
  },
  toolTip: {
    marginBottom: 10,
    marginRight: 10,
  },
});
