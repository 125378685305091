import * as React from 'react';

import Icon from './Icon';
import {Path} from 'react-native-svg';

const SceneIcon = (props: any) => (
  <Icon
    width="35px"
    height="35px"
    viewBox="0 0 3 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.398 2.275a0.025 0.025 0 0 0 0.021 0.038h2.105a0.025 0.025 0 0 0 0.023 -0.035L1.899 0.804a0.025 0.025 0 0 0 -0.046 0.001L1.573 1.506a0.025 0.025 0 0 0 0.004 0.025l0.21 0.267a0.125 0.125 0 1 1 -0.197 0.154L1.052 1.269a0.025 0.025 0 0 0 -0.041 0.003l-0.613 1.003Z"
      fill="#000000"
    />
    <Path
      d="m1.786 1.798 0.049 -0.039 -0.049 0.039Zm-0.021 0.175 -0.039 -0.049 0.039 0.049Zm-0.175 -0.021 0.049 -0.039 -0.049 0.039Zm-0.017 -0.446 -0.058 -0.023 0.058 0.023Zm0.004 0.025 -0.049 0.039 0.049 -0.039Zm-0.524 -0.262 0.049 -0.039 -0.049 0.039Zm-0.041 0.003 -0.053 -0.033 0.053 0.033Zm0.888 -0.467 0.057 -0.025 -0.057 0.025Zm-0.046 0.001 0.058 0.023 -0.058 -0.023ZM0.398 2.275l-0.053 -0.033 0.053 0.033Zm2.149 0.003 -0.057 0.025 0.057 -0.025Zm-0.023 0.098H0.42v-0.125h2.105v0.125ZM1.956 0.779l0.648 1.473 -0.114 0.05 -0.648 -1.473 0.114 -0.05Zm-0.442 0.704 0.28 -0.701 0.116 0.046 -0.28 0.701 -0.116 -0.046Zm0.111 0.009 0.21 0.267 -0.098 0.077 -0.21 -0.267 0.098 -0.077Zm0.21 0.267a0.188 0.188 0 0 1 -0.032 0.263l-0.077 -0.098a0.063 0.063 0 0 0 0.011 -0.088l0.098 -0.077Zm-0.032 0.263a0.188 0.188 0 0 1 -0.263 -0.032l0.098 -0.077a0.063 0.063 0 0 0 0.088 0.011l0.077 0.098Zm-0.263 -0.032L1.003 1.308l0.098 -0.077 0.537 0.683 -0.098 0.077Zm-1.195 0.251L0.958 1.239l0.107 0.065 -0.613 1.003 -0.107 -0.065Zm1.286 -0.713a0.037 0.037 0 0 0 -0.005 -0.037l-0.098 0.077a0.087 0.087 0 0 1 -0.013 -0.086l0.116 0.046ZM1.003 1.308a0.037 0.037 0 0 0 0.061 -0.004l-0.107 -0.065a0.087 0.087 0 0 1 0.143 -0.009l-0.098 0.077Zm0.839 -0.478a0.037 0.037 0 0 0 0.069 -0.001l-0.116 -0.046a0.087 0.087 0 0 1 0.161 -0.003l-0.114 0.05ZM0.419 2.375a0.087 0.087 0 0 1 -0.075 -0.133l0.107 0.065A0.037 0.037 0 0 0 0.42 2.25v0.125Zm2.105 -0.125a0.037 0.037 0 0 0 -0.034 0.052l0.114 -0.05a0.087 0.087 0 0 1 -0.08 0.123v-0.125Z"
      fill="#000000"
    />
  </Icon>
);
export default SceneIcon;
