import {Box, HBox} from '../../../components/core/view';
import {CheckIcon, Divider} from 'native-base';
import {H3Text, H4Text} from '../../../components/core/text';
import React, {useCallback} from 'react';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import BottomSheet from '../../../components/composites/bottomSheet';
import If from '../../../components/conditional/If';
import {MapChoice} from '../../../onBoarding/types';
import {Touchable} from '../../../components/core/touchable';
import font from '../../../appStyles/style/font';
import {styles} from './styles';
import {useAppSelector} from '../../../config/redux/hooks';

interface MapBottomSheetProps {
  isOpen: boolean;
  close: () => void;
  selectChoiceForMaps: (choice: MapChoice) => void;
}
const MapSelectBottomSheet: React.FC<MapBottomSheetProps> = props => {
  const muliFont = useFontTheme('muli');
  const theme = useColorThemeType();

  const selectedMapChoice = useAppSelector(
    gbState => gbState.onboarding.currentUser?.mapChoice,
  );

  const setChoiceAsGoogleMaps = useCallback(() => {
    props?.selectChoiceForMaps(MapChoice.googleMaps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setChoiceAsAppleMaps = useCallback(() => {
    props?.selectChoiceForMaps(MapChoice.appleMaps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderText = useCallback(
    (mapType: MapChoice, optionText: string) => {
      const isSelected = selectedMapChoice === mapType;
      return (
        <HBox style={[styles.contactBoxStyled]}>
          <H4Text
            style={[
              {
                fontFamily: muliFont[600].normal,
                color: isSelected
                  ? theme?.text.green[100]
                  : theme?.text.gray[100],
              },
            ]}>
            {optionText}
          </H4Text>
          <If condition={selectedMapChoice === mapType}>
            <CheckIcon color={theme?.text.green[100]} />
          </If>
        </HBox>
      );
    },
    [muliFont, selectedMapChoice, theme?.text.gray, theme?.text.green],
  );

  return (
    <React.Fragment>
      <BottomSheet open={props?.isOpen} onClose={props?.close}>
        <Box style={styles.mainContainer}>
          <Box style={[styles.contactBoxStyled]}>
            <H3Text
              style={[
                {
                  fontFamily: font.fonts.muliBlack,
                  color: theme?.text.blue[300],
                },
              ]}>
              Select your choice of maps
            </H3Text>
          </Box>
          <Divider />
          <Touchable onPress={setChoiceAsGoogleMaps}>
            {renderText(MapChoice.googleMaps, 'Google Maps')}
          </Touchable>
          <Divider />

          <Touchable onPress={setChoiceAsAppleMaps}>
            {renderText(MapChoice.appleMaps, 'Apple Maps')}
          </Touchable>
          <Divider />
          <Touchable onPress={props?.close}>
            <Box style={[styles.contactBoxStyled]}>
              <H3Text
                style={[
                  {
                    fontFamily: muliFont[600].normal,
                    color: theme?.text.red[100],
                  },
                ]}>
                Cancel
              </H3Text>
            </Box>
          </Touchable>
        </Box>
      </BottomSheet>
    </React.Fragment>
  );
};

export default React.memo(MapSelectBottomSheet);
