import React, {useMemo} from 'react';

import {Box} from '../../../components/core/view';
import {styles} from './styles';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

interface PaginationProps {
  data: any;
  activeIndex: number;
  disabledPaginationColor?: string;
  paginationSize?: number;
}

function Pagination(props: PaginationProps) {
  const theme = useColorThemeType();
  const GetPagination = useMemo(() => {
    return props?.data?.map((ele: any, index: number) => {
      const paginationStyle = props.paginationSize
        ? [
            {
              borderRadius: props.paginationSize,
              width: props.paginationSize,
              height: props.paginationSize,
            },
          ]
        : [];
      const PaginationDotSelected = props.paginationSize
        ? {
            borderRadius: props.paginationSize + 2,
            backgroundColor: theme?.background?.blue[300],
            height: props.paginationSize + 2,
            width: props.paginationSize + 2,
          }
        : {};

      const key = ele?.id ?? index;

      const dotStyle: any = [styles.PaginationDot, ...paginationStyle];

      if (props.activeIndex === index)
        if (props.paginationSize) dotStyle.push(PaginationDotSelected);
        else dotStyle.push(styles.PaginationDotSelected);
      else if (props.disabledPaginationColor)
        dotStyle.push({backgroundColor: props.disabledPaginationColor});

      return <Box key={key?.toString()} style={dotStyle} />;
    });
  }, [
    props.activeIndex,
    props?.data,
    props.disabledPaginationColor,
    props.paginationSize,
    theme?.background?.blue,
  ]);
  return <Box style={[styles.PaginationContainer]}>{GetPagination}</Box>;
}

export default React.memo(Pagination);
