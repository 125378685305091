export const homeRouts = {
  Tabs: {
    screens: {
      YourSchedule: 'home',
    },
  },
};

export const aboutShiftRouts = {
  Home: {
    screens: {
      AboutShift: 'about-shift',
      OpenShifts: 'open-shifts',
      MemoDetails: 'memo-details',
    },
  },
};
