import {Box, HBox} from '../../../components/core/view';

import React from 'react';
import {Skeleton} from 'native-base';
import {styles} from './styles';

function ProfileSkeleton() {
  return (
    <Box>
      <Box style={styles.headerContainer}>
        <Skeleton style={styles.imageSkeleton} marginTop={5} />
        <Skeleton style={styles.textHeader} marginTop={5} />
        <Skeleton style={styles.textSubHeader} marginTop={5} />
      </Box>
      <Box style={styles.listContainer}>
        <HBox style={styles.listItem}>
          <Skeleton style={styles.iconContainer} marginTop={5} />
          <Skeleton style={styles.listText} marginTop={5} />
        </HBox>
        <Skeleton style={styles.divider} />
        <HBox style={styles.listItem}>
          <Skeleton style={styles.iconContainer} marginTop={5} />
          <Skeleton style={styles.listText} marginTop={5} />
        </HBox>
        <Skeleton style={styles.divider} />
        <HBox style={styles.listItem}>
          <Skeleton style={styles.iconContainer} marginTop={5} />
          <Skeleton style={styles.listText} marginTop={5} />
        </HBox>
        <Skeleton style={styles.divider} />
        <HBox style={styles.listItem}>
          <Skeleton style={styles.iconContainer} marginTop={5} />
          <Skeleton style={styles.listText} marginTop={5} />
        </HBox>
        <Skeleton style={styles.divider} />
      </Box>
    </Box>
  );
}

export default React.memo(ProfileSkeleton);
