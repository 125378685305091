import {OrderItems, RunForBatchDetail} from '../../../batchDetails/types';
import React, {useCallback} from 'react';

import {Analytics} from '../../../config/analytics/analytics';
import BatchOrderItem from '../BatchOrderItem';
import {CustomFlatList} from '../../../components/core/list';
import {Divider} from 'native-base';
import {EventType} from '../../../config/analytics/type';
import If from '@buncha/components/conditional/If';
import {ItemDetailsScreen} from '../../../itemDetails/navigation/types';
import {ShopperScreens} from '../../../home/navigation/types';
import {SpinnerComponent} from '@buncha/components/core/loader';
import {ToastType} from '@buncha/components/composites/notification/type';
import {navigation} from '../../../config/navigation';
import {styles} from './styles';
import {useCheckForFrozenItems} from '@buncha/batchDetails/hooks/AddItem';
import {useToastMessage} from '@buncha/components/composites/notification';

interface BatchOrderItemListProps {
  orderItems: OrderItems[];
  runDetail?: RunForBatchDetail;
}

function BatchOrderItemList(props: BatchOrderItemListProps) {
  const [showAlertToast] = useToastMessage(ToastType.Alert);

  const {checkForFrozenItems, checkingItemForPick} = useCheckForFrozenItems();
  const {orderItems, runDetail} = props;
  const navigateItemDetail = useCallback(
    (orderItem: OrderItems) => {
      Analytics.eventWithProps('Navigate to Item Detail', {
        type: EventType.cta,
        itemName: orderItem?.name,
        quanity: orderItem?.quantity,
        catalogItemId: orderItem.catalogItemId,
        orderItemId: orderItem.id,
        storeIndex: runDetail?.store?.elasticSearchIndex,
        runId: runDetail?.id,
      });
      // if (!orderItem.isCustomItem)
      navigation.navigate(ShopperScreens.ItemDetails, {
        screen: ItemDetailsScreen.ItemDetailsPage,
        params: {
          catalogItemId: orderItem.catalogItemId,
          orderItemId: orderItem.id,
          storeIndex: runDetail?.store?.elasticSearchIndex,
          runId: runDetail?.id,
        },
      });
    },
    [runDetail],
  );

  const onClickHandler = useCallback(
    async (orderItem: OrderItems) => {
      switch (orderItem.tags === 'Frozen') {
        case true:
          const allowToPick = await checkForFrozenItems(runDetail?.id);
          if (!allowToPick) navigateItemDetail(orderItem);
          else
            showAlertToast(
              'Please pick all other items before picking your frozen items.',
            );
          break;
        case false:
          navigateItemDetail(orderItem);
          break;
        default:
          break;
      }
    },
    [checkForFrozenItems, navigateItemDetail, runDetail?.id, showAlertToast],
  );

  const renderItem = useCallback(
    ({item}: {item: any; index: number}) => {
      return (
        <BatchOrderItem
          orderItem={item}
          onClick={async () => await onClickHandler(item)}
          esIndex={runDetail?.store?.elasticSearchIndex ?? ''}
        />
      );
    },
    [onClickHandler, runDetail?.store?.elasticSearchIndex],
  );

  const Separator = useCallback(() => <Divider style={styles.divider} />, []);

  return (
    <React.Fragment>
      <If condition={checkingItemForPick}>
        <SpinnerComponent size={'sm'} />
      </If>
      <CustomFlatList
        data={orderItems}
        renderItem={renderItem}
        ItemSeparatorComponent={Separator}
        // ListFooterComponent={Separator}
      />
    </React.Fragment>
  );
}

export default React.memo(BatchOrderItemList);
