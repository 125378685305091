import {CustomText, H5Text, H6Text} from '../../../../../components/core/text';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../appStyles/hooks/theme';

import {Box, HBox} from '../../../../../components/core/view';
import {DeliveryNotes} from '../../../../../../src/driver/types/orderDetails';
import If from '../../../../../../src/components/conditional/If';
import React, {useCallback} from 'react';
import {StyleSheet} from 'react-native';

interface DeliveryNoteProps {
  deliveryNote: DeliveryNotes | undefined;
}

const DeliveryNote: React.FC<DeliveryNoteProps> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const deliveryNote = props?.deliveryNote;
  const building = deliveryNote?.building || '';
  const address = deliveryNote?.address || '';
  const parking = deliveryNote?.parking || '';
  const entrance = deliveryNote?.entrance || '';
  const access = deliveryNote?.access || '';
  const dropPoint = deliveryNote?.pikupPoint || '';

  const renderComponent = useCallback(
    (title: string, value: string) => {
      return (
        <HBox style={styles.textBox}>
          <H6Text
            style={[
              styles.textAlignment,
              styles.flex1,
              {
                color: theme?.text?.blue?.[300],
                fontFamily: muli?.[700]?.normal,
              },
            ]}>
            {title}:{' '}
          </H6Text>
          <CustomText
            style={[
              styles.textAlignment,
              styles.flex2,
              {
                color: theme?.text?.gray?.[100],
                fontFamily: muli?.[600]?.normal,
              },
            ]}>
            {value}
          </CustomText>
        </HBox>
      );
    },
    [muli, theme?.text?.blue, theme?.text?.gray],
  );

  return (
    <Box style={styles.parentBox}>
      <H5Text
        style={[
          styles.apprtmentIns,
          {
            color: theme?.text.red[100],
          },
        ]}>
        Apartment Instruction
      </H5Text>
      <If condition={building}>{renderComponent('Building', building)}</If>
      <If condition={address}>{renderComponent('Address', address)}</If>
      <If condition={parking}>{renderComponent('Parking', parking)}</If>
      <If condition={entrance}>{renderComponent('Entrance', entrance)}</If>
      <If condition={access}>{renderComponent('Access', access)}</If>
      <If condition={dropPoint}>{renderComponent('Drop point', dropPoint)}</If>
    </Box>
  );
};

export default React.memo(DeliveryNote);

const styles = StyleSheet.create({
  textAlignment: {
    textAlign: 'left',
  },
  parentBox: {
    paddingVertical: 10,
  },
  textBox: {
    marginBottom: 5,
    justifyContent: 'flex-start',
  },
  flex1: {
    flex: 0.25,
  },
  flex2: {
    flex: 0.75,
  },
  apprtmentIns: {
    textAlign: 'left',
    marginBottom: 10,
  },
});
