export const onBoardingRouts = {
  HomeBeforeLogin: {
    screens: {
      Login: 'login',
      SignUp: 'sign-up',
      Onboarding: 'welcome',
      Lockout: 'lockout',
    },
  },
};
