import {Box, HBox} from '@buncha/components/core/view';
import {CustomText, H6Text} from '@buncha/components/core/text';
import React, {useCallback} from 'react';
import {
  RecommendedItemCell,
  ReplacementItemDetails,
} from '@buncha/itemDetails/types/itemReplacement';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {CustomImage} from '@buncha/components/core/Image';
import TransferArrowIcon from '@buncha/components/core/icon/TransferArrowIcon';
import {formatCurrency} from '@buncha/utils/common';
import {styles} from './styles';

interface ReplacementModalBodyProps {
  text: string;
  item?: ReplacementItemDetails;
  catalogItem?: RecommendedItemCell;
}

const ReplacementModalBody: React.FC<ReplacementModalBodyProps> = props => {
  const {text, item, catalogItem} = props;
  const muliFont = useFontTheme('muli');
  const theme = useColorThemeType();

  const getImageComponent = useCallback((url: string) => {
    return (
      <Box style={styles.imageBox}>
        <CustomImage style={styles.image} source={url} />
      </Box>
    );
  }, []);

  const getItem = useCallback(
    (
      headerText: string,
      renderItem?: ReplacementItemDetails | RecommendedItemCell,
    ) => {
      return (
        <Box style={{width: '40%', alignSelf: 'flex-start'}}>
          <H6Text
            style={[
              styles.textStyle,
              styles.headerText,
              {
                fontFamily: muliFont[700].normal,
                color: theme?.text.blue[300],
              },
            ]}
            numberOfLines={1}>
            {headerText}
          </H6Text>
          {getImageComponent(renderItem?.itemImageUrl ?? '')}
          <H6Text
            style={[
              styles.textStyle,
              styles.priceText,
              {
                fontFamily: muliFont[900].normal,
                color: theme?.text.gray[100],
              },
            ]}
            numberOfLines={1}>
            {formatCurrency((renderItem?.price ?? 0) * (item?.quantity ?? 0))}
            {'  '}
            <CustomText
              style={{
                fontFamily: muliFont[700].normal,
                color: theme?.text.gray[300],
              }}
              numberOfLines={1}>
              {`${formatCurrency(renderItem?.price ?? 0)} each`}
            </CustomText>
          </H6Text>
          <CustomText
            style={[
              styles.textStyle,
              {
                fontFamily: muliFont[700].normal,
                color: theme?.text.gray[100],
                marginTop: 2,
              },
            ]}
            numberOfLines={2}>
            {renderItem?.name}
          </CustomText>
        </Box>
      );
    },
    [
      getImageComponent,
      item?.quantity,
      muliFont,
      theme?.text.blue,
      theme?.text.gray,
    ],
  );

  return (
    <Box>
      <H6Text>{text}</H6Text>
      <HBox style={styles.subContainer}>
        {getItem('Substituted Item', catalogItem)}
        <TransferArrowIcon fill={theme?.icon.gray[100]} />
        {getItem('Original Item', item)}
      </HBox>
    </Box>
  );
};

export default React.memo(ReplacementModalBody);
