import {Box, ChevronDownIcon, ChevronUpIcon} from 'native-base';
import {CustomText, H4Text, H5Text, H6Text} from '@buncha/components/core/text';
import {Linking, StyleSheet} from 'react-native';
import React, {useCallback, useMemo, useState} from 'react';
import {
  useImageUpload,
  useItemLocationContext,
} from '@buncha/itemDetails/hooks/ItemLocation';

import {Accordian} from '@buncha/components/composites';
import BottomSheet from '@buncha/components/composites/bottomSheet';
import BottomSheetComponent from '../../ItemScanner/BottomSheetComponent';
import {CameraComponentState} from '@buncha/common/types/common';
import CameraModal from '@buncha/common/components/CameraModal/CameraModal';
import EditIcon from '@buncha/components/core/icon/EditIcon';
import {HBox} from '@buncha/components/core/view';
import If from '@buncha/components/conditional/If';
import ImageBottomSheet from '@buncha/common/components/BottomSheet/ImageBottomSheet';
import ImageUpload from '@buncha/components/core/icon/ImageUpload';
import StoreLocation from '@buncha/components/core/icon/StoreLocation';
import Timeline from '../../ItemScanner/Timeline';
import {Touchable} from '@buncha/components/core/touchable';
import {UPCScanFormData} from '@buncha/itemDetails/types/ItemScanner';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {useUPCOrder} from '@buncha/itemDetails/hooks/ItemDetails';
import {useUploadUpcImage} from '@buncha/itemDetails/hooks/ItemScanner';

const UpdateCell = (props: {
  orderItemId: number;
  onUpdate: any;
  itemLocationData: any;
  id: number;
}) => {
  const theme = useColorThemeType('main');
  const {state, dispatch} = useItemLocationContext();
  const {loading, requestPermissions} = useUploadUpcImage(dispatch);
  const {updateLocationData} = useUPCOrder();
  const [locationUpdate, setLocaationUpdate] = useState(false);
  const [imaageUpdate, setImageUpdat] = useState(false);
  const [openCameraModal, setOpenCameraModal] = useState(false);

  const toggleCameraModal = useCallback(() => {
    setOpenCameraModal(!openCameraModal);
  }, [openCameraModal]);

  const {itemLocationData} = props;

  const [uploadedImage, setUploadedImage] = useState<string[]>(
    itemLocationData?.imageUrl ? [itemLocationData?.imageUrl] : [],
  );

  const {uploadLocationImage, imageUploading} = useImageUpload();

  const [showImageUploadModal, setShowImageUploadModal] =
    useState<boolean>(false);
  const [showLoocationBottomSheet, setShowLocationBottomSheet] =
    useState(false);

  const onLocationVerificationClick = useCallback(() => {
    setShowLocationBottomSheet(!showLoocationBottomSheet);
  }, [showLoocationBottomSheet]);

  const onImageVerificationClick = useCallback(() => {
    setShowImageUploadModal(!showImageUploadModal);
  }, [showImageUploadModal]);

  const imageUploadHandler = useCallback(
    async (base64: string) => {
      const res = await uploadLocationImage(base64);
      if (res) setUploadedImage([res]);
    },
    [uploadLocationImage],
  );

  const showCaptureModeFunc = useCallback(async () => {
    const permissionStatus = await requestPermissions();
    if (permissionStatus)
      try {
        toggleCameraModal();
      } catch (error) {}
    else Linking.openSettings();
  }, [requestPermissions, toggleCameraModal]);

  const formData: UPCScanFormData[] = useMemo(() => {
    return [
      {
        index: 0,
        icons: <StoreLocation viewBox="0 -2 20 20" height={20} width={20} />,
        header: 'Item Location Description',
        bodyText:
          'Provide detailed information about the aisle, category, and precise location of the item.',
        bodySubText: 'Where did you find this item?',
        isComplete: locationUpdate,
        ctaTitle: 'Verify location of item',
        CTAAction: () => {
          onLocationVerificationClick();
        },
      },
      {
        index: 1,
        icons: <ImageUpload />,
        header: 'Item Location Image',
        isComplete: imaageUpdate,
        bodyText:
          'Upload an image of the location where you were able to locate this item.',
        ctaTitle: 'Upload Image',
        CTAAction: () => {
          onImageVerificationClick();
        },
      },
    ];
  }, [
    locationUpdate,
    imaageUpdate,
    onLocationVerificationClick,
    onImageVerificationClick,
  ]);

  const saveImage = useCallback(async () => {
    const locationData: any = {
      ...itemLocationData,
      orderItemId: props?.orderItemId || 0,
      locationImage: uploadedImage[0],
      esIndex: state?.itemLocationInformation?.esIndex,
    };
    await updateLocationData(locationData, () => {
      props.onUpdate();
      setImageUpdat(true);
      onImageVerificationClick();
    });
  }, [
    itemLocationData,
    onImageVerificationClick,
    props,
    state?.itemLocationInformation?.esIndex,
    updateLocationData,
    uploadedImage,
  ]);

  const deleteImage = useCallback(() => {
    setUploadedImage([]);
  }, []);

  const getChildren = useCallback(
    (id: number) => {
      switch (id) {
        case 0:
          return (
            <Touchable onPress={formData[id]?.CTAAction}>
              <If condition={formData[id]?.isComplete}>
                <HBox style={[styles.coomplteContainer]}>
                  <Box style={[styles.justifyContent]}>
                    <H6Text>
                      {`${itemLocationData?.aisle}, ${itemLocationData?.storeContext}`}
                    </H6Text>
                  </Box>
                  <Box>
                    <EditIcon />
                  </Box>
                </HBox>
              </If>
              <If condition={!formData[id]?.isComplete}>
                <H6Text
                  style={[
                    styles.typo,
                    styles.ctaTypo,
                    {
                      color: theme?.text?.green[100],
                    },
                  ]}>
                  {formData[id]?.ctaTitle}
                </H6Text>
              </If>
            </Touchable>
          );
        case 1:
          return (
            <Touchable onPress={formData[id]?.CTAAction}>
              <H6Text
                style={[
                  styles.typo,
                  styles.ctaTypo,
                  {
                    color: theme?.text?.green[100],
                  },
                ]}>
                {formData[id]?.ctaTitle}
              </H6Text>
            </Touchable>
          );
        default:
          return <CustomText />;
      }
    },
    [
      formData,
      itemLocationData?.aisle,
      itemLocationData?.storeContext,
      theme?.text?.green,
    ],
  );

  const renderAccordions = useMemo(() => {
    return formData.map((data: UPCScanFormData) => {
      return (
        <HBox style={[styles.jcfs]} key={data?.index}>
          <Box>
            <Timeline checked={formData[data?.index].isComplete} />
          </Box>
          <Box style={[styles.accordionContainer]}>
            <Accordian
              disabled={false}
              isExpanded={true}
              style={styles.accordian}
              // onPress={() => {}}
              iconEnable={<ChevronUpIcon color={theme?.text.blue[300]} />}
              iconDisable={<ChevronDownIcon color={theme?.text.blue[300]} />}
              header={
                <Box style={[styles.accordianHeader]}>
                  <HBox style={[styles.jcfs]}>
                    {data.icons}
                    <H5Text
                      style={[
                        styles.headerTypo,
                        {
                          color: theme?.text.blue[300],
                        },
                      ]}>
                      {data.header}
                    </H5Text>
                  </HBox>
                </Box>
              }
              children={
                <Box>
                  <Box style={[styles.accordinBody]}>
                    <H6Text style={[styles.typo]}>
                      {formData[data?.index].bodyText}
                    </H6Text>
                    <If condition={formData[data?.index]?.bodySubText}>
                      <CustomText style={[styles.typo, styles.bodySubTypo]}>
                        {formData[data?.index]?.bodySubText}
                      </CustomText>
                    </If>
                  </Box>
                  {getChildren(data?.index)}
                </Box>
              }
            />
            {/* <If condition={index < formData.length - 1}>
              <Divider />
            </If> */}
          </Box>
        </HBox>
      );
    });
  }, [formData, getChildren, theme?.text.blue]);

  const captureImageHandler = useCallback(
    (data: CameraComponentState) => {
      if (data) imageUploadHandler(data.base64);
    },
    [imageUploadHandler],
  );

  return (
    <Box style={[styles.parentContainer]}>
      <H4Text
        style={[
          styles.type,
          {
            color: theme?.text?.blue[300],
          },
        ]}>
        Update Item Location
      </H4Text>
      {renderAccordions}
      <BottomSheet
        open={showLoocationBottomSheet}
        onClose={onLocationVerificationClick}>
        <Box style={[styles.bottomSheetBody]}>
          <BottomSheetComponent
            onSheetClose={onLocationVerificationClick}
            onPress={async (data: any) => {
              await updateLocationData(
                {
                  orderItemId: props?.orderItemId || 0,
                  aisle: data?.locationDescription?.aisle,
                  storeContext: data?.locationDescription?.context,
                  locationImage: itemLocationData?.locationImage,
                  esIndex: state?.itemLocationInformation?.esIndex ?? '',
                },
                () => {
                  props.onUpdate();
                  setLocaationUpdate(true);
                },
              );
            }}
          />
        </Box>
      </BottomSheet>
      <ImageBottomSheet
        bottomSheetOpen={showImageUploadModal}
        title={'Add item images'}
        subtitle={'Please take clear photos of the item and upload the image'}
        loading={loading || imageUploading}
        imageList={uploadedImage}
        showCaptureModeFunc={showCaptureModeFunc}
        closeBottomSheet={onImageVerificationClick}
        handleImageDelete={() => deleteImage()}
        uploadButtonHandler={async () => await saveImage()}
        hasShowBottomButton
      />
      <If condition={openCameraModal}>
        <CameraModal
          capturedImage={captureImageHandler}
          open={openCameraModal}
          closeModal={toggleCameraModal}
        />
      </If>
    </Box>
  );
};

export default React.memo(UpdateCell);

const styles = StyleSheet.create({
  parentContainer: {
    paddingVertical: 5,
    // borderWidth: 1,
    marginTop: 10,
    width: '100%',
  },
  type: {
    textAlign: 'left',
  },
  accordian: {
    padding: 10,
  },
  headerTypo: {
    marginLeft: 5,
  },
  accordionContainer: {
    paddingVertical: 5,
  },
  typo: {
    textAlign: 'left',
    fontWeight: '600',
  },
  accordianHeader: {
    width: '90%',
    paddingHorizontal: 0,
  },
  accordinBody: {
    width: '80%',
    paddingHorizontal: 10,
    paddingBottom: 15,
    // paddingBottom: 20,
  },
  bodySubTypo: {
    marginTop: 15,
    fontWeight: '500',
  },
  ctaTypo: {
    marginTop: 10,
    fontWeight: '800',
  },
  bottomSheetBody: {
    padding: 0,
  },
  justifyContent: {
    justifyContent: 'center',
  },
  coomplteContainer: {
    paddingVertical: 5,
    paddingLeft: 10,
    width: '70%',
  },
  jcfs: {
    justifyContent: 'flex-start',
    // marginTop: -2,
    // borderWidth: 1,
  },
});
