import {Box, ChevronRightIcon} from 'native-base';
import {CustomText, H5Text} from '../../../components/core/text';
import {
  OrderItemStatus,
  OrderItems,
  itemsSortBy,
} from '../../../batchDetails/types';
import React, {ReactElement, useCallback, useMemo, useState} from 'react';
import {
  batchDetailActionsLocal,
  useBatchDetailContext,
} from '@buncha/batchDetails/hooks/batchDetail';
import {formatCurrency, getWidth} from '../../../utils/common';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import EditIcon from '@buncha/components/core/icon/EditIcon';
import {HBox} from '../../../components/core/view';
import If from '@buncha/components/conditional/If';
import ImageWithQuantityPill from '../../../common/components/ImageWithQuantityPill';
import LocationTypo from '../LocationTypo';
import {OrderItem} from '@buncha/home/types';
import {Touchable} from '../../../components/core/touchable';
import UpdateQuantitySheet from '@buncha/itemDetails/components/ItemDetail/UpdateQuantitySheet';
import {styles} from './styles';
import {useUpdateOrderItemQuantity} from '@buncha/itemDetails/hooks/ItemDetails';

interface BatchOrderItemProps {
  rightIcon?: ReactElement<any, any>;
  orderItem: OrderItems;
  onClick: (orderItem: OrderItems) => void;
  disableOnclick?: boolean;
  esIndex: string;
}

function BatchOrderItem(props: BatchOrderItemProps) {
  const {rightIcon, orderItem, onClick, disableOnclick} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const {state, dispatch} = useBatchDetailContext();
  const getRightIcon = useCallback(() => {
    if (rightIcon) return rightIcon;
    return <ChevronRightIcon color={theme?.text.gray[100]} />;
  }, [rightIcon, theme?.text.gray]);
  const updatedPrice =
    orderItem?.shopperItemChangesForShopper?.updatedPricePerQuantity;
  const price = updatedPrice || orderItem?.price;

  const dropped = orderItem.itemStatus === OrderItemStatus.dropped;
  const disable = dropped || disableOnclick;
  const opacity = {
    opacity: dropped ? 0.5 : 1,
  };
  const isCustomItem = props?.orderItem?.isCustomItem;

  const [showQtyUpdateSheet, setShowQtyUpdateSheet] = useState(false);
  const {updateOrderItemQuantity, info} = useUpdateOrderItemQuantity();

  const getQuantity = useMemo(() => {
    const quantity =
      orderItem?.shopperItemChangesForShopper?.updatedQuantity ||
      orderItem?.quantity;
    if (isCustomItem) return quantity;
    if (state.sortBarValue === itemsSortBy.category && state.showSortBar)
      return orderItem?.totalQuantityInThisRun;
    return quantity;
  }, [
    isCustomItem,
    orderItem?.quantity,
    orderItem?.shopperItemChangesForShopper?.updatedQuantity,
    orderItem?.totalQuantityInThisRun,
    state.showSortBar,
    state.sortBarValue,
  ]);

  const onItemClick = useCallback(() => {
    onClick(orderItem);
  }, [onClick, orderItem]);

  const toggleEditQuantity = useCallback(() => {
    setShowQtyUpdateSheet(!showQtyUpdateSheet);
  }, [showQtyUpdateSheet]);

  const handleUpdateQuantity = useCallback(
    async (updatedQty: number) => {
      await updateOrderItemQuantity(
        {
          orderItemId: orderItem.id,
          itemQuantity: Number(updatedQty),
        },
        (updateOrderItemQuantityRes: OrderItem) => {
          if (updateOrderItemQuantityRes && dispatch)
            dispatch(
              batchDetailActionsLocal.updateDoneSectionWithUpdated({
                orderId: orderItem?.orderId,
                orderItemId: orderItem?.id,
                updatedQty:
                  updateOrderItemQuantityRes?.quantity || Number(updatedQty),
              }),
            );
        },
      );
      toggleEditQuantity();
    },
    [
      dispatch,
      orderItem.id,
      orderItem?.orderId,
      toggleEditQuantity,
      updateOrderItemQuantity,
    ],
  );

  return (
    <Touchable disabled={disable} onPress={onItemClick}>
      <HBox>
        <HBox style={[styles.mainSubContainer, opacity]}>
          <Box>
            <ImageWithQuantityPill
              imageLink={orderItem?.itemImageUrl}
              quantity={getQuantity}
            />
          </Box>
          <Box style={[styles.textContainer]}>
            <H5Text
              numberOfLines={2}
              style={[
                styles.textHeader,
                {
                  fontFamily: muliFont[700].normal,
                  color: theme?.text.blue[300],
                },
              ]}>
              {orderItem?.name}
            </H5Text>
            <CustomText
              numberOfLines={2}
              style={[
                styles.textHeader,
                {
                  fontFamily: muliFont[700].normal,
                  color: theme?.text.blue[100],
                },
              ]}>
              {orderItem?.options}
            </CustomText>

            <If condition={rightIcon}>
              <Touchable
                style={styles.editQtyBox}
                onPress={toggleEditQuantity}
                disabled={dropped}>
                <CustomText
                  style={[styles.editQyt, {color: theme?.text.blue[100]}]}>
                  Edit Qty
                </CustomText>
                <EditIcon
                  viewBox="0 0 15 15"
                  width={Number(getWidth(15))}
                  height={15}
                  fill={theme?.text.blue[100]}
                />
              </Touchable>
            </If>
            <H5Text
              style={[
                styles.priceText,
                {
                  fontFamily: muliFont[700].normal,
                  color: theme?.text.blue[300],
                },
              ]}>
              {formatCurrency(price)}
            </H5Text>
          </Box>
        </HBox>
        <Box>{getRightIcon()}</Box>
      </HBox>
      <UpdateQuantitySheet
        open={showQtyUpdateSheet}
        onClose={toggleEditQuantity}
        orderItemId={orderItem.id}
        quantity={getQuantity}
        handleUpdateQuantity={handleUpdateQuantity}
        loading={info?.loading}
      />
      <If
        condition={
          orderItem?.itemLocationInformation?.aisle ||
          orderItem?.itemLocationInformation?.storeContext
        }>
        <LocationTypo
          aisle={orderItem?.itemLocationInformation?.aisle ?? ''}
          context={orderItem?.itemLocationInformation?.storeContext ?? ''}
          orderItemId={orderItem?.id}
          esIndex={props.esIndex}
        />
      </If>
    </Touchable>
  );
}

export default React.memo(BatchOrderItem);
