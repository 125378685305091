import {StyleSheet} from 'react-native';
import font from '@buncha/appStyles/style/font';

const OrderListStyle = StyleSheet.create({
  mainContainer: {marginHorizontal: 20},
  flexBox: {flex: 1},
});

const OrderListCellStyle = StyleSheet.create({
  mainContainer: {marginBottom: 10, paddingVertical: 10},
  buyerName: {
    textAlign: 'left',
    marginBottom: 5,
    fontFamily: font.fonts.muliSemiBold,
  },
  bagBoxContainer: {
    paddingHorizontal: 10,
    paddingVertical: 4,
    borderRadius: 10,
    marginHorizontal: 10,
  },
  driverName: {textAlign: 'left', fontFamily: font.fonts.muliRegular, flex: 1},
  flexBox: {flex: 1},
  underLineText: {textDecorationLine: 'underline'},
});

export {OrderListStyle, OrderListCellStyle};
