import {Linking, StyleSheet} from 'react-native';
import React, {useCallback} from 'react';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../src/appStyles/hooks/theme';

import {Analytics} from '../../../../../src/config/analytics/analytics';
import {H6Text} from '../../../../../src/components/core/text';
import {HBox} from '../../../../../src/components/core/view';
import LocationIcon from '../../../../../src/components/core/icon/LocationIcon';
import {MapChoice} from '../../../../../src/onBoarding/types';
import {MostRecentAddress} from '../../../../../src/driver/types/common';
import {Touchable} from '../../../../../src/components/core/touchable';
import {getMapUrl} from '../../../../../src/utils/common';
import {useAppSelector} from '../../../../../src/config/redux/hooks';

interface BuyerAddressCellProps {
  buyerAddress: MostRecentAddress | undefined;
}

const BuyerAddressCell: React.FC<BuyerAddressCellProps> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const buyerAddress = props?.buyerAddress;
  const lat = buyerAddress?.lat || 0;
  const lon = buyerAddress?.lon || 0;
  const address = buyerAddress?.address || '';
  const user = useAppSelector(gstate => gstate.onboarding.currentUser);
  const mapChoice = user?.mapChoice || MapChoice.googleMaps;

  const openUrl = useCallback(() => {
    const coords = {
      lat: lat || 0,
      lng: lon || 0,
    };
    Analytics.eventWithProps('Open map for driver', {
      mapChoice: mapChoice,
      buyerLat: lat,
      buyerLon: lon,
      type: 'CTA',
      userType: 'driver',
    });
    Linking.openURL(getMapUrl(mapChoice, coords));
  }, [lat, lon, mapChoice]);

  return (
    <Touchable style={styles.touchable} onPress={openUrl}>
      <HBox style={styles.parentBox}>
        <LocationIcon viewBox="-3 0 20 20" fill={theme?.icon?.blue?.[300]} />
        <H6Text
          alignText="left"
          numberOfLines={2}
          style={[
            styles.location,
            {
              color: theme?.text?.blue?.[300],
              fontFamily: muli?.[600]?.normal,
            },
          ]}>
          Navigate to {address}
        </H6Text>
      </HBox>
    </Touchable>
  );
};

export default React.memo(BuyerAddressCell);

const styles = StyleSheet.create({
  location: {
    textDecorationLine: 'underline',
    flex: 1,
    padding: 0,
    marginLeft: 10,
  },
  parentBox: {
    marginVertical: 10,
    justifyContent: 'flex-start',
    flex: 1,
  },
  touchable: {
    display: 'flex',
    flexDirection: 'row',
  },
});
