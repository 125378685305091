import {Dimensions, StyleSheet} from 'react-native';

import font from '../../appStyles/style/font';

export const styles = StyleSheet.create({
  containerStyles: {
    zIndex: 2,
  },
  updateHeader: {
    justifyContent: 'flex-start',
  },
  updateText: {
    flexGrow: 0.8,
  },
  image: {
    height: 75,
    width: 75,
  },
  highlightText: {
    borderRadius: 20,
    padding: 5,
    position: 'absolute',
    bottom: 0,
    alignSelf: 'center',
    zIndex: 3,
  },
  imageBox: {position: 'relative'},
  detailsContainer: {justifyContent: 'flex-start', marginBottom: 10},
  textContainer: {alignItems: 'flex-start'},
  scannedBox: {
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    marginBottom: 5,
  },
  infoBox: {
    paddingHorizontal: 10,
    paddingVertical: 2,
    borderRadius: 20,
  },
  infoText: {
    marginLeft: 10,
  },
  itemNameText: {
    maxWidth: Dimensions.get('window').width - 120,
    textAlign: 'left',
  },
  loader: {
    opacity: 0.8,
    zIndex: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  jCC: {
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 15,
  },
  rowFlex: {
    flexDirection: 'row',
  },
  updatePriceBox: {paddingHorizontal: 20, marginTop: 20},
  updatePriceText: {alignSelf: 'flex-start', marginBottom: 5},
  textIcon: {marginLeft: 10},
  inputContainerStyle: {
    borderWidth: 1,
    borderRadius: 50,
  },
  inputStyle: {
    backgroundColor: 'transparent',
    ...font.FontSize.fontH4,
  },
  bottomButton: {
    marginBottom: 5,
  },
});
