import {Box, ChevronRightIcon} from 'native-base';
import React, {useCallback} from 'react';
import {
  UPCOrderItem as UPCOrderItemType,
  UpcOrderItemStatus,
} from '../../../batchDetails/types';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {H5Text} from '../../../components/core/text';
import {HBox} from '../../../components/core/view';
import ImageWithQuantityPill from '../../../common/components/ImageWithQuantityPill';
import MinusTickIcon from '@buncha/components/core/icon/MinusTickIcon';
import RightTickIcon from '@buncha/components/core/icon/RightTickIcon';
import {Touchable} from '../../../components/core/touchable';
import {formatCurrency} from '../../../utils/common';
import {styles} from './styles';

interface BatchOrderItemProps {
  orderItem: UPCOrderItemType;
  onClick?: (orderItem: UPCOrderItemType) => void;
  disableOnclick?: boolean;
}

function UPCOrderItem(props: BatchOrderItemProps) {
  const {orderItem, onClick, disableOnclick} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');

  const getRightIcon = useCallback(() => {
    switch (orderItem.itemStatus) {
      case UpcOrderItemStatus.dropped:
        return (
          <MinusTickIcon
            width={24}
            height={24}
            viewBox="-3 -1 25 25"
            fill={theme?.background.red[100]}
          />
        );
      case UpcOrderItemStatus.picked:
        return (
          <RightTickIcon
            width={24}
            height={24}
            viewBox="-3 -1 25 25"
            fill={theme?.background.green[100]}
          />
        );
      default:
        return (
          <ChevronRightIcon
            color={theme?.text.gray[100]}
            width={24}
            height={24}
            viewBox="-3 -1 25 25"
            fill={theme?.background.green[100]}
          />
        );
    }
  }, [
    orderItem.itemStatus,
    theme?.background.green,
    theme?.background.red,
    theme?.text.gray,
  ]);

  const dropped = orderItem.itemStatus === UpcOrderItemStatus.dropped;
  const disable = dropped || disableOnclick;
  const opacity = {
    opacity: dropped ? 0.5 : 1,
  };

  const onItemClick = useCallback(() => {
    if (onClick) onClick(orderItem);
  }, [onClick, orderItem]);

  return (
    <Touchable disabled={disable} onPress={onItemClick}>
      <HBox>
        <HBox style={[styles.mainSubContainer, opacity]}>
          <Box>
            <ImageWithQuantityPill
              imageLink={orderItem?.itemImageUrl}
              quantity={0}
            />
          </Box>
          <Box style={[styles.textContainer]}>
            <H5Text
              numberOfLines={2}
              style={[
                styles.textHeader,
                {
                  fontFamily: muliFont[700].normal,
                  color: theme?.text.blue[300],
                },
              ]}>
              {orderItem?.name}
            </H5Text>
            <H5Text
              style={[
                styles.priceText,
                {
                  fontFamily: muliFont[700].normal,
                  color: theme?.text.blue[300],
                },
              ]}>
              {formatCurrency(orderItem?.price)}
            </H5Text>
          </Box>
        </HBox>
        <Box>{getRightIcon()}</Box>
      </HBox>
    </Touchable>
  );
}

export default React.memo(UPCOrderItem);
