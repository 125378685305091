import {ActivityIndicator, Dimensions, StyleSheet} from 'react-native';
import {Box, Divider} from 'native-base';
import {CustomText, H3Text, H6Text} from '../../../../components/core/text';
import {
  ItemDetailsAction,
  useItemDetailsContext,
  useLocationUpdate,
  useUPCOrder,
} from '@buncha/itemDetails/hooks/ItemDetails';
import React, {useCallback, useEffect, useState} from 'react';

import BinIcon from '@buncha/components/core/icon/BinIcon';
import DropItemModalBody from '../DropItemModalBody/DropItemModalBody';
import If from '../../../../../src/components/conditional/If';
import ItemLocationInfo from '../ItemLocationInfo';
import {ItemLocationInformation} from '../../../../itemDetails/types/itemDetails';
import ItemQuantityPill from '../../../../components/composites/ItemQuantityPill/ItemQuantityPill';
import {OrderItem} from '@buncha/itemDetails/types/ItemScanner';
import OrganicReminderModal from '../organicReminderModal';
import {Touchable} from '@buncha/components/core/touchable';
import VerifyUPCPill from '../VerifyUPCPill';
import {formatCurrency} from '../../../../utils/common';
import {useColorThemeType} from '../../../../appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';

interface ItemInfoProps {
  item?: OrderItem;
  isUPCMode: boolean;
  upcItemId?: number;
  orderItemId: number;
  esIndex: string;
}

const ItemInfo = (props: ItemInfoProps) => {
  const {state, dispatch} = useItemDetailsContext();
  const theme = useColorThemeType('main');
  const route = useRoute();
  const [modalOpen, setModalOpen] = useState(false);
  const routeParams: any = route?.params;

  const itemName = props?.item?.name || '';
  const isOrganic = itemName.toLocaleLowerCase().includes('organic');
  const updatedPrice = state.buyersForItem?.[0]?.updatedPricePerQuantity;
  const price = updatedPrice || props?.item?.price;
  const orderItemId = Number(routeParams?.orderItemId) || 0;

  const {markItem} = useLocationUpdate(dispatch);
  const {getItemLocationHistory, loadingItem} = useUPCOrder();

  const [showModal, setShowModal] = useState(false);

  const toggleDeleteModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const toggleModal = useCallback(() => {
    setModalOpen(!modalOpen);
  }, [modalOpen]);

  const refetchItemDetails = useCallback(() => {
    getItemLocationHistory(orderItemId, props.esIndex, (response: any) => {
      if (dispatch) dispatch(ItemDetailsAction.setItemLocationData(response));
    });
  }, [dispatch, getItemLocationHistory, orderItemId, props.esIndex]);

  useEffect(() => {
    if (isOrganic) {
      let timer = setTimeout(() => {
        setModalOpen(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isOrganic]);

  return (
    <Box style={styles.parentBox}>
      <H3Text style={[styles.ItemNameTypo, {color: theme?.text.blue[300]}]}>
        {props.item?.name}
      </H3Text>
      <If condition={props.item?.options}>
        <H6Text style={[styles.ItemNameTypo, {color: theme?.text.blue[100]}]}>
          {props.item?.options}
        </H6Text>
      </If>
      <Box style={[styles.BottomContainer]}>
        <H3Text style={[styles.ItemNameTypo, {color: theme?.text.blue[300]}]}>
          {formatCurrency(price ?? 0)}
        </H3Text>
        <If condition={props.isUPCMode}>
          <VerifyUPCPill />
        </If>
        <If condition={!props.isUPCMode}>
          <ItemQuantityPill
            quantity={
              props.item?.totalupdatedQuantityByShopper ||
              props.item?.quantity ||
              0
            }
          />
        </If>
      </Box>
      <If condition={state?.itemLocationInformation && !props.isUPCMode}>
        <Box style={[styles.locationInfoBox]}>
          <Divider />
          <If condition={loadingItem}>
            <ActivityIndicator />
          </If>
          <If condition={!loadingItem}>
            <ItemLocationInfo
              locationData={
                state?.itemLocationInformation as ItemLocationInformation
              }
              orderItemId={props?.orderItemId}
              showDivider={false}
              dispatch={dispatch}
              markItem={markItem}
              action={ItemDetailsAction}
              callback={refetchItemDetails}
              onUpdate={true}
            />
          </If>
        </Box>
      </If>
      <If condition={props.isUPCMode}>
        <Box style={[styles.unableToDropContainer]}>
          <Divider />
          <Touchable style={[styles.binContainer]} onPress={toggleDeleteModal}>
            <Box>
              <BinIcon
                viewBox="-5 -5 25 25"
                fill={theme?.background.red[100]}
              />
            </Box>
            <Box style={[styles.typoContainer, styles.jcC]}>
              <H6Text
                style={{
                  color: theme?.text?.red[100],
                }}>
                Unable to find this item
              </H6Text>
            </Box>
          </Touchable>
          <Divider />
        </Box>
        <If condition={!props?.isUPCMode}>
          <CustomText
            style={[
              styles.quanitityText,
              {
                color: theme?.text.red[100],
              },
            ]}>
            For all customers*
          </CustomText>
        </If>
      </If>
      <DropItemModalBody
        orderItemId={props?.orderItemId}
        onClose={toggleDeleteModal}
        open={showModal}
        isUPCMode={props?.isUPCMode}
      />
      <If condition={modalOpen}>
        <OrganicReminderModal onClose={toggleModal} open={modalOpen} />
      </If>
    </Box>
  );
};

export default React.memo(ItemInfo);

const styles = StyleSheet.create({
  ItemNameTypo: {
    textAlign: 'left',
  },
  BottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  parentBox: {
    marginTop: 10,
  },
  quanitityText: {
    textAlign: 'right',
  },
  unableToDropContainer: {
    marginTop: 20,
  },
  binContainer: {
    justifyContent: 'flex-start',
    paddingVertical: 10,
    flexDirection: 'row',
  },
  typoContainer: {
    marginLeft: 2,
  },
  jcC: {
    justifyContent: 'center',
  },
  fullWidth: {
    width: Dimensions.get('screen').width,
  },
  locationInfoBox: {
    marginTop: 10,
  },
});
