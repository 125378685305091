import {CustomText} from '@buncha/components/core/text';
import {HBox} from '@buncha/components/core/view';
import React from 'react';
import {StyleSheet} from 'react-native';
import {Touchable} from '@buncha/components/core/touchable';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const LocationInfoCell = (props: {text: string; onClick: any; icon: any}) => {
  const theme = useColorThemeType('main');
  const {onClick, text} = props;
  return (
    <Touchable onPress={onClick}>
      <HBox>
        {props?.icon}
        <CustomText
          style={[styles.locationStatusTypo, {color: theme?.text?.gray[100]}]}>
          {text}
        </CustomText>
      </HBox>
    </Touchable>
  );
};

export default React.memo(LocationInfoCell);

const styles = StyleSheet.create({
  locationStatusTypo: {
    textDecorationLine: 'underline',
    marginLeft: 5,
  },
});
