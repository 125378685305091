import React, {useCallback, useState} from 'react';

import {Clipboard} from 'react-native';
import CopyIcon from '@buncha/components/core/icon/CopyIcon';
import CrossIcon from '@buncha/components/core/icon/CrossIcon';
import {H5Text} from '@buncha/components/core/text';
import HBox from '@buncha/components/core/view/HBox';
import If from '@buncha/components/conditional/If';
import {PTextInput} from '@buncha/components/core/input/TextInput';
import {ToastType} from '@buncha/components/composites/notification/type';
import {Touchable} from '@buncha/components/core/touchable';
import {styles} from './style';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {useToastMessage} from '@buncha/components/composites/notification';

interface TemplateCellProps {
  sendTextToCustomer: (message?: string) => void;
  text: string;
  active: boolean;
  makeActive: Function;
}

const TemplateCell = (props: TemplateCellProps) => {
  const {sendTextToCustomer, text, active} = props;
  const theme = useColorThemeType();
  const [showSuccessToast] = useToastMessage(ToastType.Success);

  const [editMode, setEditMode] = useState(false);
  const [editText, setEditText] = useState(text);

  const allowEdit = editMode && active;

  const handleCopy = useCallback(() => {
    Clipboard.setString(text);
    showSuccessToast('Copied to clipboard');
  }, [showSuccessToast, text]);

  const closeEdit = useCallback(() => {
    setEditMode(false);
    setEditText(text);
  }, [text]);

  const handleLeftCTA = useCallback(
    (e: any) => {
      e?.preventDefault();
      if (allowEdit) closeEdit();
      else handleCopy();
    },
    [closeEdit, allowEdit, handleCopy],
  );

  const handleMessageClick = useCallback(() => {
    if (allowEdit) return;
    sendTextToCustomer(text);
  }, [allowEdit, sendTextToCustomer, text]);

  return (
    <Touchable style={styles.touchableStyles} onPress={handleMessageClick}>
      <HBox style={styles.textContainer}>
        <Touchable style={styles.padRight} onPress={handleLeftCTA}>
          <If condition={allowEdit}>
            <CrossIcon width="24" height="24" viewBox="0 0 20 20" />
          </If>
          <If condition={!allowEdit}>
            <CopyIcon
              width="20"
              height="20"
              viewBox="0 0 24 24"
              style={styles.editIcon}
            />
          </If>
        </Touchable>
        <If condition={allowEdit}>
          <PTextInput
            debounce={false}
            style={{
              color: theme?.text.blue[300],
            }}
            containerStyle={[
              styles.textBox,
              {
                borderColor: theme?.text.gray[100],
              },
            ]}
            value={editText}
            onChangeText={setEditText}
            textAlignVertical={'center'}
          />
        </If>
        <If condition={!allowEdit}>
          <H5Text style={styles.messageAlign}>{text}</H5Text>
        </If>
      </HBox>
    </Touchable>
  );
};

export default React.memo(TemplateCell);
