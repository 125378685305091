import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  divider: {
    marginTop: 15,
  },
  touchableStyle: {
    padding: 5,
  },
  hollowCircle: {
    borderWidth: 1,
    padding: 8,
    borderRadius: 50,
  },
});
