import {AboutShiftContext, initialState} from '../context/AboutShiftContext';
import {
  AboutShiftContextState,
  Batches,
  GetBatchesResponse,
  ShopperSessionStatus,
  UpdateShopperSessionStatusRes,
} from '../types';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import React, {useCallback, useContext, useReducer, useState} from 'react';

import {GET_ABOUT_BATCHES} from '../../config/graphQL/documentNode/queries';
import {ToastType} from '../../components/composites/notification/type';
import {UPDATE_SHOPPER_SESSION_STATUS} from '../../config/graphQL/documentNode/mutation';
import {gqlService} from '../../config/graphQL';
import {useToastMessage} from '../../components/composites/notification';

const reducer = {
  setBatches: function (
    state: AboutShiftContextState,
    action: PayloadAction<Batches>,
  ) {
    state.batches = action.payload;
  },
};

const slice = createSlice({
  initialState,
  name: 'aboutShiftContext',
  reducers: reducer,
});

export function useAboutShiftReducer() {
  const aboutShiftReducer = useReducer(slice.reducer, initialState);
  return aboutShiftReducer;
}

export function useAboutShiftContext() {
  return useContext(AboutShiftContext);
}

export const aboutShiftActionLocal = slice.actions;

export function useGetBatches(
  dispatch: React.Dispatch<any> | null,
): [(startDate: string) => void, boolean] {
  const [loading, setLoading] = useState(true);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const getBatches = useCallback(
    async (startDate: string) => {
      try {
        setLoading(true);
        const response = await gqlService?.query<GetBatchesResponse>({
          query: GET_ABOUT_BATCHES,
          variables: {startDate: startDate},
          fetchPolicy: 'network-only',
        });
        if (response?.data?.getBatches && dispatch)
          dispatch(aboutShiftActionLocal.setBatches(response?.data.getBatches));
      } catch (error: any) {
        showErrorToast(error?.message);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, showErrorToast],
  );

  return [getBatches, loading];
}

export function useUpdateShopperSessionStatus(
  dispatch: React.Dispatch<any> | null,
): [
  (
    sessionId: number,
    sessionStatus: ShopperSessionStatus,
    shiftId: string,
    latitude: number,
    longitude: number,
  ) => void,
  boolean,
] {
  const [loading, setLoading] = useState(true);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const updateShopperSessionStatus = useCallback(
    async (
      sessionId: number,
      sessionStatus: ShopperSessionStatus,
      shiftId: string,
      latitude: number,
      longitude: number,
    ) => {
      try {
        const response =
          await gqlService?.mutation<UpdateShopperSessionStatusRes>({
            mutation: UPDATE_SHOPPER_SESSION_STATUS,
            variables: {
              sessionId: sessionId,
              status: sessionStatus,
              shiftId: shiftId,
              latitude: latitude,
              longitude: longitude,
            },
            fetchPolicy: 'network-only',
          });
        if (response?.data?.updateShopperSessionStatus && dispatch)
          setLoading(false);
      } catch (error: any) {
        showErrorToast(error?.message);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, showErrorToast],
  );

  return [updateShopperSessionStatus, loading];
}
