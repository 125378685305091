import {StyleProp, TextStyle, ViewStyle} from 'react-native';

import Box from '../view/Box';
import {H3Text} from '../text';
import {HTouchable} from '../touchable';
import If from '../../conditional/If';
import {PButtonProps} from './type';
import React from 'react';
import {styles} from './style';
import {useButtonStyle} from './hooks';
import {useFontTheme} from '../../../appStyles/hooks/theme';

const PButton: React.FC<PButtonProps> = React.forwardRef((props, ref) => {
  const buttonStyle = useButtonStyle(props);
  const fontTheme = useFontTheme();
  const defaultStyle: StyleProp<ViewStyle>[] = [
    {
      backgroundColor: buttonStyle.backgroundColor,
      borderColor: buttonStyle.borderColor,
      borderWidth: buttonStyle.borderWidth,
      opacity: props.disabled ? 0.5 : 1,
    },
    styles.default,
  ];
  const textStyle: StyleProp<TextStyle> = [
    {
      color: buttonStyle.titleColor,
      textAlign: 'center',
      fontFamily: fontTheme[700].normal,
      alignSelf: 'center',
    },
    props.titleStyle,
  ];
  let rightComponent = props.rightComponent;
  let loadingComponent = props.loadingIndicator;
  const style = [defaultStyle, props.style];
  if (props.isLoading)
    if (React.isValidElement(props.loadingIndicator))
      loadingComponent = React.Children.map(props.loadingIndicator, child => {
        if (!child.props.color)
          return React.cloneElement(child, {
            ...child.props,
            color: buttonStyle.titleColor,
          });
        return child;
      });

  return (
    <HTouchable
      {...props}
      ref={ref}
      loading={false}
      style={style}
      disabled={props.isLoading || props.disabled}>
      <If condition={props.leftComponent && !props.isLoading}>
        <Box>{props.leftComponent}</Box>
      </If>
      <If condition={!props.hideTitle}>
        <Box style={styles.titleBox}>
          <H3Text
            maxFontSizeMultiplier={props.maxFontSizeMultiplier}
            style={textStyle}>
            {buttonStyle.titleButton}
          </H3Text>
          <If condition={props.isLoading && loadingComponent}>
            <Box style={styles.loadingComponent}>{loadingComponent}</Box>
          </If>
        </Box>
      </If>
      <If condition={rightComponent && !props.isLoading}>
        <Box>{rightComponent}</Box>
      </If>
    </HTouchable>
  );
});

export default React.memo(PButton);
