import {CommonScreens} from './types';
import React from 'react';
import Webview from '../screens/Webview.web';
import {createNativeStackNavigator} from '@react-navigation/native-stack';

const Stack = createNativeStackNavigator();
const MainNavigator: React.FC = () => {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen name={CommonScreens.Webview} component={Webview} />
    </Stack.Navigator>
  );
};
export default MainNavigator;
