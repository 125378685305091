import {Dimensions, Linking, StyleSheet, TouchableOpacity} from 'react-native';
import {H3Text, H4Text, H5Text} from '../../components/core/text';
import React, {useCallback} from 'react';
import font, {scaleFonts} from '../../appStyles/style/font';

import {Box} from '../../components/core/view';
import {CustomImage} from '../../components/core/Image';
import Header from '../../components/composites/Header/Header';
import {gqlService} from '../../config/graphQL';
import {onBoardingActions} from '../reducer';
import {useAppDispatch} from '../../config/redux/hooks';
import {useColorThemeType} from '../../appStyles/hooks/theme';

export default function Lockout() {
  const theme = useColorThemeType();
  const dispatch = useAppDispatch();

  const logout = useCallback(() => {
    dispatch(onBoardingActions.clearState(undefined));
    gqlService?.setLoginCredential('');
    gqlService?.setWhenIWorkLoginCredential('');
  }, [dispatch]);

  const openAppStore = useCallback(async () => {
    const link =
      'https://apps.apple.com/us/app/pikup-pickup-with-friends/id1413908254?l=is';
    try {
      const canOpen = await Linking.canOpenURL(link);
      if (canOpen) Linking.openURL(link);
    } catch (error) {}
  }, []);
  return (
    <Header
      showDefaultLeftHeader={true}
      hideBottomSafeArea
      hideUploadFile
      handler={logout}>
      <Box style={style.mainContainer}>
        <H3Text
          style={[
            style.headerTypo,
            {
              fontFamily: font.fonts['Mortise-X-Bold'],
              color: theme?.text.blue[300],
            },
          ]}>
          Oops... Wrong Application
        </H3Text>
        <CustomImage
          source={require('../../resource/images/NotShopper.webp')}
          resizeMode="cover"
          style={[style.imageContainer]}
        />
        <H5Text style={[style.bottomText]}>
          It seems like you have landed on the wrong application. Please
          download our buyer application in order to continue.
        </H5Text>
        <TouchableOpacity onPress={openAppStore}>
          <H4Text style={[style.downloadText, {color: theme?.text.green[100]}]}>
            Download Buncha For Buyers
          </H4Text>
        </TouchableOpacity>
      </Box>
    </Header>
  );
}

const style = StyleSheet.create({
  mainContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  imageContainer: {
    width: Dimensions.get('window').width * 0.92,
    height: 350,
    marginTop: 25,
  },
  headerTypo: {
    textAlign: 'left',
  },
  bottomText: {
    marginVertical: 20,
  },
  downloadText: {
    fontSize: scaleFonts(16),
    textDecorationLine: 'underline',
  },
});
