import {StyleSheet} from 'react-native';
import React from 'react';
import {Box} from '../../../components/core/view';
import {useColorThemeType} from '../../../appStyles/hooks/theme';
import {SpinnerComponent} from '../../../components/core/loader';

const CustomActivityIndicator = () => {
  const theme = useColorThemeType('main');
  return (
    <Box style={[styles.activityIndicatorBox]}>
      <SpinnerComponent size="large" color={theme?.background.green[100]} />
    </Box>
  );
};

export default CustomActivityIndicator;

const styles = StyleSheet.create({
  activityIndicatorBox: {
    height: 70,
    width: 70,
    borderRadius: 10,
    flexDirection: 'column',
    justifyContent: 'center',
    // backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
});
