import {HBox} from '../../../components/core/view';
import If from '@buncha/components/conditional/If';
import {ImageBackground} from 'react-native';
import ItemQuantityPill from '../../../components/composites/ItemQuantityPill/ItemQuantityPill';
import React from 'react';
import {getDefaultImageUrl} from '../../../utils/common';
import {styles} from './styles';

interface ImageWithQuantityPillProps {
  imageLink: string;
  quantity: number;
}

function ImageWithQuantityPill(props: ImageWithQuantityPillProps) {
  const {imageLink, quantity} = props;
  return (
    <ImageBackground
      source={{
        uri: imageLink || getDefaultImageUrl(),
      }}
      resizeMode="cover"
      style={[styles.itemImage]}>
      <If condition={quantity !== 0}>
        <HBox style={[styles.quantityArea]}>
          <ItemQuantityPill quantity={quantity} />
        </HBox>
      </If>
    </ImageBackground>
  );
}

export default React.memo(ImageWithQuantityPill);
