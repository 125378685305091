import React, {useCallback, useEffect} from 'react';
import Header from '../../components/composites/Header/Header';
import {H4Text} from '../../components/core/text';
import ScreenHeader from '../../common/components/screenHeader';
import {usePastOrders} from '../hooks/home';
import If from '../../components/conditional/If';
import {SpinnerComponent} from '../../components/core/loader';
import RunCell from '../components/RunCell';
import {RunsAndOrders} from '../types';
import {CustomFlatList} from '../../components/core/list';
import Scrollview from '../../components/composites/scrollview';
import {Box} from '../../components/core/view';
import {useColorThemeType} from '../../appStyles/hooks/theme';
import {Divider} from 'native-base';
import {StyleSheet} from 'react-native';

function PastRuns() {
  const {getPastOrders, runsAndOrders, loading} = usePastOrders();
  const theme = useColorThemeType();

  const renderRun = useCallback(
    ({item}: {item: RunsAndOrders; index: number}) => {
      return <RunCell key={item.run.id} run={item.run} orders={item.orders} />;
    },
    [],
  );

  const separator = useCallback(() => {
    return (
      <Divider
        style={[
          styles.divider,
          {
            backgroundColor: theme?.text.gray[200],
          },
        ]}
      />
    );
  }, [theme?.text.gray]);

  useEffect(() => {
    getPastOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Header loading={loading} showDefaultLeftHeader>
      <ScreenHeader title="Past Orders" />
      <Divider />
      <Box style={styles.container}>
        <If condition={loading}>
          <SpinnerComponent />
        </If>
        <If condition={!loading}>
          <If condition={!runsAndOrders?.length}>
            <H4Text>No Past Orders</H4Text>
          </If>
          <If condition={runsAndOrders?.length}>
            <Scrollview>
              <Box style={styles.marginVer}>
                <CustomFlatList
                  data={runsAndOrders}
                  renderItem={renderRun}
                  showsVerticalScrollIndicator={false}
                  ItemSeparatorComponent={separator}
                />
              </Box>
            </Scrollview>
          </If>
        </If>
      </Box>
    </Header>
  );
}

const styles = StyleSheet.create({
  divider: {
    marginVertical: 10,
    height: 2,
  },
  marginVer: {
    marginVertical: 10,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default React.memo(PastRuns);
