import AddDeliveryPhotoCell from '../addDeliveryPhotoCell';
import AddSignatureCell from '../addSignatureCell';
import {DeliveryLocationDetails} from '../../../../../src/driver/types/deliveryDetails';
import {HBox} from '../../../../../src/components/core/view';
import If from '../../../../../src/components/conditional/If';
import React from 'react';
import {StyleSheet} from 'react-native';
import VerifyIdCell from '../verifyIdCell';

interface PhotoAndSignatureComponentProps {
  orderId: string;
  deliveryLocationData: DeliveryLocationDetails | undefined;
  containsAlcoholicItems: boolean;
  runId: number;
}

const PhotoAndSignatureComponent: React.FC<
  PhotoAndSignatureComponentProps
> = props => {
  const deliveryLocationData = props?.deliveryLocationData;
  const signatureUrl = deliveryLocationData?.signature || '';
  const photoIdUrl = deliveryLocationData?.idProof || '';
  const deliveryLocationId = deliveryLocationData?.id || 0;
  const containsAlcoholicItems = props?.containsAlcoholicItems || false;

  return (
    <HBox style={styles.parentBox}>
      <AddDeliveryPhotoCell
        orderId={props?.orderId}
        deliveryLocationId={deliveryLocationId}
        runId={props?.runId}
      />
      <AddSignatureCell
        orderId={props?.orderId}
        signatureUrl={signatureUrl}
        runId={props?.runId}
      />
      <If condition={containsAlcoholicItems}>
        <VerifyIdCell
          photoIdUrl={photoIdUrl}
          orderId={props?.orderId}
          runId={props?.runId}
        />
      </If>
    </HBox>
  );
};

export default React.memo(PhotoAndSignatureComponent);

const styles = StyleSheet.create({
  parentBox: {
    marginTop: 15,
    justifyContent: 'space-evenly',
    width: '100%',
  },
});
