import {Flags} from '@buncha/batchDetails/types';
import {ShopperItemChangesForShopper} from './ItemScanner';
import {SubstitutionMode} from './itemDetails';

/* eslint-disable no-shadow */
export enum ItemReplacementCellMode {
  proactive = 'proactive',
  nonProactive = 'nonProactive',
}
export enum SubstitutionChoice {
  byShopper = 'byShopper',
  byBuyer = 'byBuyer',
  noSubstitution = 'noSubstitution',
  none = 'none',
}

export interface SubstitutionOptions {
  id: number;
  name: string;
  itemImageUrl: string;
  price: number;
  catalogItemId: number;
  isCustomItem: boolean;
  orderItem: {
    orderId: number;
    position: number;
    substitutedBy: string;
  };
}

export interface ReplacementItemDetails {
  id: number;
  itemImageUrl: string;
  name: string;
  quantity: number;
  substitutionNote: string;
  catalogItemId: number;
  tags: string;
  price: number;
  substitutionChoice: SubstitutionChoice;
  substitutionOptions: SubstitutionOptions[];
  shopperItemChangesForShopper: ShopperItemChangesForShopper;
  order: {
    id: number;
    run: {
      id: number;
      store: {
        id: number;
        elasticSearchIndex: string;
      };
    };
  };
}
export interface RecommendedItemCell {
  id: number;
  itemImageUrl: string;
  name: string;
  quantity: number;
  price: number;
  itemSource: string;
  indexPosition: number;
  tags: string[];
  flags: Flags;
}
export interface State {
  item: ReplacementItemDetails | undefined;
  searchResults: SearchResults[];
  showCustomItemCTA: boolean;
}

export interface SubstitutionRequestParams {
  catalogItemId: number;
  orderItemId: number;
  quantity: number;
  itemSource: string;
  indexPosition: number;
}

export interface RecommendationsForSubParams {
  catalogItemId: number;
  searchQuery: string;
  category: string;
  storeId: number;
  subCategory: string;
}

export interface SubstitutionItemParams {
  catalogItemId?: number;
  isCustomItem: boolean;
  itemImageUrl?: string;
  name: string;
  orderId: number;
  orderItemId: number;
  position?: number;
  price: number;
  quantity: number;
  substitutedBy: string;
  substitutionItemId?: number;
  substitutionRequestParmas?: ItemSubstitutionRequestParamas;
  itemSource?: string;
  indexPosition?: number;
  isPriceSensitive?: boolean;
  isWeightSensitive?: boolean;
}

export interface ItemSubstitutionRequestParamas {
  id?: number;
  substitutionMode: SubstitutionMode;
}

export interface SearchItemsForSubParams {
  storeId: number;
  searchQuery: string;
  from: number;
  size: number;
  catalogItemId?: number;
  runId?: number;
}

export interface SearchItemsForSubParamsUPC {
  esIndex: string;
  searchQuery: string;
  from: number;
  size: number;
  catalogItemId: number;
}

export interface SearchResults {
  id: number;
  name: string;
  price: number;
  tags: string[];
  description: string;
  itemImageUrl: string;
  itemType: string;
  itemSource: string;
  indexPosition: number;
  itemQuantity?: string;
  flags: Flags;
}

export interface SearchCatalog {
  totalResult: number;
  items: SearchResults[];
  searchRelevance: SearchRelevance[];
}

export interface SearchRelevance {
  key: string;
  length: number;
  name: string;
}
