import React, {useCallback, useEffect, useState} from 'react';

import {Analytics} from '../../../config/analytics/analytics';
import {BottomSheetType} from '../../../profile/types/profile';
import {EventType} from '../../../config/analytics/type';
import {MapChoice} from '../../../onBoarding/types';
import MapSelectBottomSheet from '../../../AboutShift/component/MapSelectBottomSheet';
import {useAppDispatch} from '../../../config/redux/hooks';
import {useIsFocused} from '@react-navigation/native';
import {useUpdateUserChoiceMap} from '../../../AboutShift/hooks/UserMapChoice';

interface ComponentBottomSheetProps {
  bottomSheetType?: BottomSheetType;
  closeSheet: () => void;
}

function ComponentBottomSheet(props: ComponentBottomSheetProps) {
  const {bottomSheetType, closeSheet} = props;
  const [openSheet, setOpenSheet] = useState(false);
  const isFocused = useIsFocused();
  const appDispatch = useAppDispatch();
  const [updateUserChoiceMap] = useUpdateUserChoiceMap(appDispatch);

  const openBottomSheet = useCallback(() => {
    Analytics.eventWithProps('Navigation select', {
      type: EventType.cta,
    });
    setOpenSheet(true);
  }, []);

  const closeBottomSheet = useCallback(() => {
    setOpenSheet(false);
    closeSheet();
  }, [closeSheet]);

  const selectMapChoice = useCallback(
    (choice: MapChoice) => {
      Analytics.eventWithProps('Update map choice', {
        choice: choice,
        type: EventType.cta,
      });
      updateUserChoiceMap(choice);
      closeBottomSheet();
    },
    [closeBottomSheet, updateUserChoiceMap],
  );

  useEffect(() => {
    if (isFocused)
      if (bottomSheetType === BottomSheetType.MapNavigationSheet)
        openBottomSheet();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomSheetType]);

  return (
    <React.Fragment>
      <MapSelectBottomSheet
        isOpen={openSheet}
        close={closeBottomSheet}
        selectChoiceForMaps={selectMapChoice}
      />
    </React.Fragment>
  );
}

export default React.memo(ComponentBottomSheet);
