import * as React from 'react';

import Icon from './Icon';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const PluseIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path d="M9.833 4.833H8.167v3.334H4.833v1.666h3.334v3.334h1.666V9.833h3.334V8.167H9.833V4.833ZM9 .667A8.336 8.336 0 0 0 .667 9c0 4.6 3.733 8.333 8.333 8.333S17.333 13.6 17.333 9 13.6.667 9 .667Zm0 15A6.675 6.675 0 0 1 2.333 9 6.676 6.676 0 0 1 9 2.333 6.675 6.675 0 0 1 15.667 9 6.675 6.675 0 0 1 9 15.667Z" />
    </Icon>
  );
};

export default PluseIcon;
