import DeliveryDetails from '../screens/DeliveryDetails';
import DriverOrders from '../screens/DriverOrders';
import {DriverScreens} from './type';
import LoadVanAt from '../screens/LoadVanAt';
import MapOverview from '../screens/MapOverview';
import OrderDetails from '../screens/OrderDetails';
import React from 'react';
import Signature from '../screens/Signature';
import {createNativeStackNavigator} from '@react-navigation/native-stack';

const Stack = createNativeStackNavigator();

const MainNavigator: React.FC = () => {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen
        name={DriverScreens.DriverOrders}
        component={DriverOrders}
        options={{
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name={DriverScreens.DeliveryDetails}
        component={DeliveryDetails}
        options={{
          gestureEnabled: false,
        }}
      />

      <Stack.Screen
        name={DriverScreens.OrderDetails}
        component={OrderDetails}
        options={{
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name={DriverScreens.Signature}
        component={Signature}
        options={{
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name={DriverScreens.MapOverview}
        component={MapOverview}
        options={{
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name={DriverScreens.LoadVanAt}
        component={LoadVanAt}
        options={{
          gestureEnabled: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default MainNavigator;
