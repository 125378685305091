import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  tabNode: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 5,
  },
  border: {
    borderBottomWidth: 3,
    // marginTop: 5,
    marginBottom: 15,
    borderRadius: 50,
  },
});
