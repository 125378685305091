import {ChatDetails} from '@buncha/AboutShift/types';
import {User} from '@talkjs/react-native';

export interface CurrentUser {
  id: number;
  chatUser: User;
  phoneNumber: string;
}

export interface GetChatInfoResponse {
  currentUser: CurrentUser;
}

export interface GetChatDetailsResponse {
  getRun: ChatDetails;
}

// eslint-disable-next-line no-shadow
export enum ConversationAccess {
  None = 'None',
  Read = 'Read',
  ReadWrite = 'ReadWrite',
}
export interface ChatParticipant {
  access: ConversationAccess;
  user: {
    id: number;
    lastName: string;
    firstName: string;
    phoneNumber: string;
  };
}

export interface Conversation {
  id: string;
  subject: string;
  custom: any;
  photoUrl: string;
}

export interface GetConversationById {
  getConversationById: {
    data: {
      conversation: Conversation;
    };
  };
}

export interface GetConversationParticipants {
  getConversationParticipants: {
    data: {
      participant: ChatParticipant[];
    };
  };
}

// eslint-disable-next-line no-shadow
export enum ChatModes {
  group = 'group',
  run = 'run',
}

// eslint-disable-next-line no-shadow
export enum ChatFilterName {
  operationFilter = 'operationFilter',
  orderFilter = 'orderFilter',
}
