import {ActivityIndicator, Dimensions, StyleSheet} from 'react-native';
import {Box, HBox} from '@buncha/components/core/view';
import {CustomText, H6Text} from '@buncha/components/core/text';
import React, {useEffect, useMemo} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';
import {useGetOrderItem, useItemLocationReducer} from '../hooks/ItemLocation';

import {CustomImage} from '@buncha/components/core/Image';
import Header from '@buncha/components/composites/Header/Header';
import If from '@buncha/components/conditional/If';
import ItemLocationAccordions from '../components/ItemDetail/ItemLocationAccordions';
import {ItemLocationContext} from '../context/ItemLocation';
import ScreenHeader from '@buncha/common/components/screenHeader';
import {formatCurrency} from '@buncha/utils/common';
import {useRoute} from '@react-navigation/native';

const ItemLocationPage = () => {
  const [state, dispatch] = useItemLocationReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);

  const {loadingItem, getOrderItem} = useGetOrderItem(dispatch);
  const route = useRoute();

  const theme = useColorThemeType('main');
  const muliFont = useFontTheme('muli');
  const routeParams: any = route?.params;
  const orderItemId = Number(routeParams?.orderItemId);

  const esIndex: string = routeParams?.esIndex;

  useEffect(() => {
    if (orderItemId) getOrderItem(orderItemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItemId]);

  return (
    <Header showDefaultLeftHeader>
      <ScreenHeader title="Item Location" />
      <ItemLocationContext.Provider value={provider}>
        <If condition={!loadingItem}>
          <HBox style={styles.detailsContainer}>
            <Box style={styles.imageBox}>
              <CustomImage
                style={styles.image}
                source={state?.item?.itemImageUrl || ''}
                resizeMode="cover"
                onErrorSource={''}
              />
              <HBox
                style={[
                  styles.highlightText,
                  {
                    backgroundColor: theme?.background.yellow[100],
                  },
                ]}>
                <CustomText
                  style={{
                    fontFamily: muliFont[700].normal,
                    color: theme?.text.blue[300],
                  }}>{`Qty: ${state?.item?.quantity || 0} `}</CustomText>
              </HBox>
            </Box>
            <Box style={styles.textContainer}>
              <H6Text
                style={[
                  styles.itemNameText,
                  {
                    fontFamily: muliFont[700].normal,
                    color: theme?.text.blue[300],
                  },
                ]}>
                {state?.item?.name}
              </H6Text>
              <H6Text
                style={{
                  fontFamily: muliFont[700].normal,
                  color: theme?.text.blue[300],
                }}>
                {formatCurrency(state?.item?.price || 0)}
              </H6Text>
            </Box>
          </HBox>
        </If>
        <If condition={loadingItem}>
          <Box>
            <ActivityIndicator />
          </Box>
        </If>
        <ItemLocationAccordions
          orderItemId={orderItemId ?? 0}
          esIndex={esIndex}
        />
      </ItemLocationContext.Provider>
    </Header>
  );
};

export default React.memo(ItemLocationPage);

const styles = StyleSheet.create({
  detailsContainer: {
    justifyContent: 'flex-start',
    marginBottom: 5,
    paddingHorizontal: 20,
  },
  textContainer: {
    alignItems: 'flex-start',
    // borderWidth: 1,
    marginLeft: 10,
    flex: 1,
  },
  unableToScanBox: {
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 5,
    width: '100%',
  },
  infoBox: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 20,
    marginRight: 10,
  },
  infoText: {
    marginLeft: 5,
  },
  itemNameText: {
    maxWidth: Dimensions.get('window').width - 120,
    textAlign: 'left',
    marginBottom: 10,
  },
  unableToScanText: {
    textAlign: 'left',
    marginLeft: 10,
    textDecorationLine: 'underline',
  },
  flexBox: {
    flex: 1,
  },
  accordian: {
    padding: 10,
  },
  bottomCta: {
    borderTopWidth: 1,
  },
  containerStyles: {
    zIndex: 2,
    height: '100%',
  },
  scanHeader: {
    justifyContent: 'flex-start',
  },
  scanText: {
    flexGrow: 0.8,
  },
  image: {
    height: 75,
    width: 75,
  },
  highlightText: {
    borderRadius: 20,
    padding: 5,
    position: 'absolute',
    bottom: 0,
    alignSelf: 'center',
    zIndex: 3,
  },
  imageBox: {position: 'relative'},
});
