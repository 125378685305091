import {CustomText, PSmallText} from '@buncha/components/core/text';
import {
  DeliveryLocationStatus,
  OrderItemStatus,
} from '@buncha/batchDetails/types';
import React, {Fragment, useCallback, useState} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import AppModal from '@buncha/common/components/CustomModal/AppModal';
import {Box} from '@buncha/components/core/view';
import If from '@buncha/components/conditional/If';
import {Sensitivity} from '@buncha/itemDetails/types/ItemScanner';
import {SpinnerComponent} from '@buncha/components/core/loader';
import {StyleSheet} from 'react-native';
import {Touchable} from '@buncha/components/core/touchable';
import {useUpdateOrderItemStatus} from '@buncha/batchDetails/hooks/BatchDetails';

interface DropItemProps {
  undrop: boolean;
  orderStatus?: DeliveryLocationStatus;
  orderItemId: number;
  handleDrop: (val: boolean) => void;
  mode?: Sensitivity;
}

const DropItem = (props: DropItemProps) => {
  const theme = useColorThemeType('main');
  const muliFont = useFontTheme();
  const {undrop, orderItemId, orderStatus} = props;

  const [dropModal, setDropModal] = useState(false);

  const {updateOrderItemStatus, changeStatusState} = useUpdateOrderItemStatus();

  const handleItemStatus = useCallback(
    (update?: boolean) => {
      let status = OrderItemStatus.dropped;
      if (!undrop && !update) return setDropModal(true);
      if (undrop)
        status =
          orderStatus === DeliveryLocationStatus.readyToPack
            ? OrderItemStatus.notPacked
            : OrderItemStatus.notPicked;
      updateOrderItemStatus(0, status, [orderItemId], () =>
        props.handleDrop(!undrop),
      );
    },
    [orderItemId, orderStatus, props, undrop, updateOrderItemStatus],
  );

  const dropItem = useCallback(() => {
    setDropModal(false);
    handleItemStatus(true);
  }, [handleItemStatus]);

  return (
    <Fragment>
      <AppModal
        isOpen={dropModal}
        onClose={() => setDropModal(false)}
        topCTAHandelr={dropItem}
        bottomCTAHandelr={() => setDropModal(false)}
        title={'Drop this Item?'}
        subTitle={'Are you sure you want to drop this item?'}
        ctaBottomTitle={'No'}
        ctaTopTitle={'Yes'}
        topCTAColor={theme?.background.red[100]}
      />
      <Touchable style={[styles.Container]} onPress={() => handleItemStatus()}>
        <If condition={changeStatusState.loading}>
          <SpinnerComponent size="sm" />
        </If>
        <If condition={!changeStatusState.loading}>
          <Box style={[styles.jcc]}>
            <If condition={props.mode === Sensitivity.weightSensitive}>
              <CustomText
                style={[
                  styles.nameTypo,
                  {
                    color: theme?.text.red[100],
                    fontFamily: muliFont[600].normal,
                  },
                ]}>
                {undrop ? 'Undrop' : 'Drop'} this item
              </CustomText>
            </If>
            <If condition={props.mode !== Sensitivity.weightSensitive}>
              <PSmallText
                style={[
                  styles.nameTypo,
                  {
                    color: theme?.text.red[100],
                    fontFamily: muliFont[600].normal,
                  },
                ]}>
                {undrop ? 'Undrop' : 'Drop'} this item
              </PSmallText>
            </If>
          </Box>
        </If>
      </Touchable>
    </Fragment>
  );
};

const styles = StyleSheet.create({
  Container: {
    flexDirection: 'row',
  },
  nameTypo: {
    textAlign: 'left',
  },
  jcc: {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 2,
  },
});

export default React.memo(DropItem);
