import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const AddSignature: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        d="M15.5 35.6999C19.06 34.2999 18.28 30.4399 16.48 27.9999C14.7 25.4999 12.24 23.7799 9.76003 22.1199C7.99054 21.0047 6.41062 19.6138 5.08003 17.9999C4.52003 17.3399 3.38003 16.1199 4.54003 15.8799C5.72003 15.6399 7.76003 16.7999 8.80003 17.2399C10.62 17.9999 12.42 18.8799 14.1 19.9199L16.12 16.5199C13 14.4599 9.00003 12.6399 5.28003 12.0999C3.16003 11.7799 0.920032 12.2199 0.200032 14.5199C-0.439968 16.4999 0.580032 18.4999 1.74003 20.0599C4.48003 23.7199 8.74003 25.4799 11.92 28.6399C12.6 29.2999 13.42 30.0799 13.82 30.9999C14.24 31.8799 14.14 31.9399 13.2 31.9399C10.72 31.9399 7.62003 29.9999 5.60003 28.7199L3.58003 32.1199C6.64003 33.9999 11.76 36.9399 15.5 35.6999ZM37.68 4.49994C38.12 4.05994 38.12 3.33994 37.68 2.91994L35.08 0.319943C34.8706 0.118053 34.591 0.00524902 34.3 0.00524902C34.0091 0.00524902 33.7295 0.118053 33.52 0.319943L31.48 2.35994L35.64 6.51994M18 15.8399V19.9999H22.16L34.46 7.69994L30.3 3.53994L18 15.8399Z"
        fill={props?.fill}
      />
    </Icon>
  );
};

export default AddSignature;
