import React, {useCallback, useMemo, useState} from 'react';

import AppModal from '../../../common/components/CustomModal/AppModal';
import {Shift} from '../../../../src/openShifts/types/openShifts';
import TakeShiftModalBody from './TakeShiftModalBody';
import {useColorThemeType} from '../../../appStyles/hooks/theme';
import {useTakeShift} from '../../../../src/openShifts/hooks/openShifts';

interface TakeShiftModalProps {
  open: boolean;
  onClose: () => void;
  storeShift: Shift;
}

const TakeShiftModal: React.FC<TakeShiftModalProps> = props => {
  const theme = useColorThemeType('main');
  const shiftId = props?.storeShift?.shiftId || '';
  const [selectingShift, setSelectingShift] = useState(false);
  const {takeShift, loading} = useTakeShift();
  const shiftDate = props?.storeShift?.date || '';

  const getHeading = useMemo(() => {
    if (selectingShift) return 'Take OpenShift';
    return shiftDate;
  }, [selectingShift, shiftDate]);

  const getButtonText = useMemo(() => {
    if (selectingShift) return 'Take OpenShift';
    return 'Take Shift';
  }, [selectingShift]);

  const confirmTakingShift = useCallback(async () => {
    if (!selectingShift) {
      setSelectingShift(true);
      return;
    }
    if (shiftId) {
      await takeShift(shiftId);
      props?.onClose();
    }
  }, [props, selectingShift, shiftId, takeShift]);

  const cancelTakingShift = useCallback(() => {
    props.onClose();
    setSelectingShift(false);
  }, [props]);
  return (
    <AppModal
      isOpen={props?.open}
      onClose={() => props?.onClose()}
      topCTAHandelr={confirmTakingShift}
      bottomCTAHandelr={cancelTakingShift}
      title={getHeading}
      modalBody={
        <TakeShiftModalBody
          storeShift={props?.storeShift}
          selectingShift={selectingShift}
        />
      }
      ctaBottomTitle={'Cancel'}
      ctaTopTitle={getButtonText}
      topCTAColor={theme?.background.green[100]}
      loading={loading}
    />
  );
};

export default React.memo(TakeShiftModal);
