import {CustomText, H2Text} from '../../../components/core/text';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {StyleSheet, TextInput, View} from 'react-native';
import font, {scaleFonts} from '../../../appStyles/style/font';

import Fonts from '../../../resource/fonts';
import If from '../../../components/conditional/If';
import Input from '../Input/Input';
import {onBoardingActions} from '../../../onBoarding/reducer';
import {useAppDispatch} from '../../../config/redux/hooks';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

function SignUpComponent() {
  const theme = useColorThemeType();
  const dispatch = useAppDispatch();
  const ref = useRef<TextInput>(null);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [errorMsg, setErrorMsg] = useState({nameError: '', emailError: ''});

  const onFirstNameChange = useCallback(
    (val: string) => {
      setFirstName(val);
      dispatch(onBoardingActions.setFirstName(val));
    },
    [dispatch],
  );

  const onEmailChange = useCallback(
    (val: string) => {
      setEmail(val);
      dispatch(onBoardingActions.setEmail(val));
    },
    [dispatch],
  );

  const validateName = useCallback(
    (value: string) => {
      setErrorMsg({...errorMsg, nameError: ''});
      let nameRegex = /^$|^[a-zA-Z ]{3,}$/;

      const errorMessage =
        value.length < 3
          ? 'Your full name must contain at least 3 characters'
          : 'Numbers or special character is not allowed in Name';

      if (nameRegex.test(value)) {
        onFirstNameChange(value);
        setErrorMsg({...errorMsg, nameError: ''});
      } else {
        onFirstNameChange(value);
        errorMsg.nameError = errorMessage;
        setErrorMsg({...errorMsg, nameError: errorMessage});
      }
    },
    [errorMsg, onFirstNameChange],
  );

  const validateEmail = useCallback(
    (val: string) => {
      setErrorMsg({...errorMsg, emailError: ''});
      // eslint-disable-next-line no-useless-escape
      let emailRegex = /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/;
      if (val?.length === 0 || emailRegex.test(val))
        setErrorMsg({...errorMsg, emailError: ''});
      else setErrorMsg({...errorMsg, emailError: 'Invalid email'});
      onEmailChange(val);
    },
    [errorMsg, onEmailChange],
  );

  const returnNameBorderColor = useMemo(() => {
    if (errorMsg.nameError.length) return theme?.text.red[100];
    else if (firstName) return theme?.text.gray[100];
    return theme?.text?.gray[200];
  }, [
    errorMsg.nameError.length,
    firstName,
    theme?.text?.gray,
    theme?.text.red,
  ]);

  const returnEmailBorderColor = useMemo(() => {
    if (errorMsg.emailError.length) return theme?.text.red[100];
    else if (email) return theme?.text.gray[100];
    return theme?.text?.gray[200];
  }, [errorMsg.emailError.length, theme?.text.red, theme?.text?.gray, email]);

  return (
    <View>
      <H2Text
        style={[
          styles.headerTypo,
          {
            fontFamily: font.fonts['Mortise-Bold'],
            color: theme?.text.blue[300],
          },
        ]}>
        Enter Details
      </H2Text>
      <View style={[styles.inputContainer]}>
        <Input
          debounce={false}
          defaultValue={firstName}
          autoCorrect={false}
          colorThemeType="default"
          keyboardType="default"
          clearButtonMode="while-editing"
          maxLength={50}
          autoFocus
          placeholder="Enter name"
          ref={ref}
          clearInput={true}
          textValue={firstName}
          containerStyle={[
            styles.firstNameContainer,
            {
              borderColor: returnNameBorderColor,
            },
          ]}
          style={[
            {
              backgroundColor: theme?.background?.white[100],
              fontSize: scaleFonts(16),
              color: theme?.text?.blue[300],
            },
          ]}
          onChangeText={validateName}
        />
      </View>
      <View style={styles.errorMessageContainer}>
        <If condition={Boolean(errorMsg.nameError.length)}>
          <CustomText style={[styles.errorTypo]}>
            {errorMsg.nameError}
          </CustomText>
        </If>
      </View>
      <Input
        debounce={true}
        defaultValue={email}
        autoCorrect={false}
        colorThemeType="default"
        keyboardType="email-address"
        clearButtonMode="while-editing"
        autoFocus
        placeholder="username@mail.com"
        ref={ref}
        clearInput={true}
        textValue={email}
        containerStyle={[
          styles.inputContainer,
          styles.inputContainer2,
          {borderColor: returnEmailBorderColor},
        ]}
        style={[
          {
            backgroundColor: theme?.background?.white[100],
            fontSize: scaleFonts(16),
            color: theme?.text?.blue[300],
          },
        ]}
        onChangeText={validateEmail}
      />
      <View style={styles.errorMessageContainer}>
        <If condition={Boolean(errorMsg.emailError.length)}>
          <CustomText style={[styles.errorTypo]}>
            {errorMsg.emailError}
          </CustomText>
        </If>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  headerTypo: {
    textAlign: 'left',
  },
  firstNameContainer: {
    width: '100%',
  },
  inputContainer: {
    top: 20,
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  inputContainer2: {
    marginTop: 15,
  },
  buttonContainer: {
    top: 40,
  },
  errorMessageContainer: {
    top: 20,
  },
  errorTypo: {
    fontFamily: Fonts.muliSemiBold,
    color: 'red',
  },
});

export default React.memo(SignUpComponent);
