import {StyleSheet} from 'react-native';
import {getOs} from '@buncha/config/utils/appInfo';
import {scaleFonts} from '@buncha/appStyles/style/font';

export const styles = StyleSheet.create({
  inputContainer: {
    borderWidth: 1,
    height: 51,
    width: '100%',
    borderRadius: 50,
    marginTop: 10,
    marginBottom: 20,
  },
  inputBox: {marginTop: getOs() === 'android' ? scaleFonts(20, 'height') : 0},
});
