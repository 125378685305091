import {
  Dimensions,
  Modal,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';

import {Box} from '../../../components/core/view';
import React from 'react';
import {getWidth} from '../../../utils/common';

interface ModalProps {
  open: boolean;
  onClose: Function;
  children: React.ReactNode;
}

const CustomModal = (props: ModalProps) => {
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={props.open}
      onRequestClose={() => {
        props.onClose();
      }}>
      <TouchableOpacity
        style={styles.centeredView}
        activeOpacity={1}
        onPress={() => {
          props.onClose();
        }}>
        <TouchableWithoutFeedback>
          <Box>
            <Box style={styles.modalView}>{props.children}</Box>
          </Box>
        </TouchableWithoutFeedback>
      </TouchableOpacity>
    </Modal>
  );
};

export default React.memo(CustomModal);

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.25)',
  },
  modalView: {
    // margin: 20,
    width: getWidth(Dimensions?.get('window')?.width - 40),
    backgroundColor: 'white',
    borderRadius: 20,
    elevation: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  columnFlex: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
  },
});
