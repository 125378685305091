import {DefaultContext} from '../../utils/types';
import React from 'react';
import {VanLoadAtContaxtState} from '../types/vanLoadAt';

export const initialState: VanLoadAtContaxtState = {
  run: undefined,
  groupedOrders: undefined,
};

const defaultVal: DefaultContext<VanLoadAtContaxtState> = {
  state: initialState,
  dispatch: null,
};

export const VanLoadAtContext = React.createContext(defaultVal);
