import {IconProps} from '../../../components/core/icon/type';
import React from 'react';
import {StyleSheet} from 'react-native';

interface SsrIconProps extends IconProps {
  renderIcon: string;
}

function SsrIcon(props: SsrIconProps) {
  return (
    <div
      dangerouslySetInnerHTML={{__html: props.renderIcon}}
      style={styles.iconStyle}
    />
  );
}

export default React.memo(SsrIcon);

const styles = StyleSheet.create({
  mainContainer: {
    borderWidth: 1,
  },
  iconStyle: {
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
