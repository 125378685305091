export const itemDetailRouts = {
  ItemDetails: {
    screens: {
      ItemDetailsPage: 'item-details-page',
      ItemReplacement: 'item-replacement',
      ItemScanner: 'item-scanner',
      ItemUpdate: 'item-update',
      CustomItem: 'custom-item',
    },
  },
};
