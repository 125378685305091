import {Box, CenterBox, HBox} from '@buncha/components/core/view';
import {CustomText, H1Text, H5Text, H6Text} from '@buncha/components/core/text';
import {MemoConfigProperty, MemoStatus} from '../types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';
import {useGetMemo, useMarkMemoRead} from '../hooks';
import {useIsFocused, useRoute} from '@react-navigation/native';

import Attachments from '../components/attachments';
import {Avatar} from 'native-base';
import {BackHeaderLeft} from '@buncha/common/components/BackHeaderLeft';
import {BottomButton} from '@buncha/components/composites/Button';
import Header from '@buncha/components/composites/Header/Header';
import {HomeScreens} from '@buncha/home/navigation/types';
import If from '@buncha/components/conditional/If';
import {Info} from '@buncha/components/core/icon';
import {MainScreens} from '@buncha/navigation/types';
import {Platform} from 'react-native';
import Scrollview from '@buncha/components/composites/scrollview';
import {SpinnerComponent} from '@buncha/components/core/loader';
import {ToastType} from '@buncha/components/composites/notification/type';
import {navigation} from '@buncha/config/navigation';
import {storage} from '@buncha/config/storage';
import {styles} from './style';
import {useToastMessage} from '@buncha/components/composites/notification';

const MemoDetails = () => {
  const router = useRoute();
  const theme = useColorThemeType();
  const muliFont = useFontTheme();
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const {getMemo, fullMemo, memoLoading} = useGetMemo();
  const {markMemoRead, memoReadLoading} = useMarkMemoRead();
  const sendTo = fullMemo?.sendTo;
  const memo = fullMemo?.memo;
  const createdUser = memo?.createdUser;
  const avatar = createdUser?.avatar ?? '';
  const memoConfig = memo?.memoConfig;

  const isFocused = useIsFocused();

  const isMemoRead = fullMemo?.readStatus === MemoStatus.read;

  const name = `${createdUser?.firstName ?? ''} ${
    createdUser?.lastName?.[0] ?? ''
  }.`;
  const routeParams: any = router.params;
  const memoId = routeParams?.memoId;

  const showMarkReadButton = fullMemo?.readStatus === MemoStatus.unread;

  const [memoReadData, setMemoReadData] = useState<Record<string, boolean>>({});

  const [title, body, mediaUrls] = useMemo(() => {
    let memoMediaUrls: string[] = [];
    let memoTitle = '';
    let memoBody = '';
    memoConfig?.forEach(config => {
      switch (config.property) {
        case MemoConfigProperty.imageSection:
          memoMediaUrls = memoMediaUrls.concat(
            config.props?.images?.map((image: {url: string}) => image.url),
          );
          break;
        case MemoConfigProperty.videoSection:
          memoMediaUrls = memoMediaUrls.concat(
            config.props?.video?.map((image: {url: string}) => image.url),
          );
          break;
        case MemoConfigProperty.title:
          memoTitle = config.content;
          break;
        case MemoConfigProperty.body:
          memoBody = config.content;
          break;
        default:
          break;
      }
    });

    return [memoTitle, memoBody, memoMediaUrls];
  }, [memoConfig]);

  const handleRead = useCallback(async () => {
    const memoMediaData = JSON.parse(
      (await storage.getItem(`memoData${memoId}`)) ?? '{}',
    );
    let allNotSeen = false;
    Object.keys(memoMediaData)?.forEach((data: any) => {
      if (!memoMediaData[data]) allNotSeen = true;
    });
    if (allNotSeen && Platform.OS !== 'web')
      return showErrorToast(
        'Please watch the media before marking this as read.',
      );
    if (!memoId) return;
    const res = await markMemoRead(memoId);
    if (res) navigation.goBack();
  }, [markMemoRead, memoId, showErrorToast]);

  const init = useCallback(async () => {
    const memoMediaStatus = JSON.parse(
      (await storage.getItem(`memoData${memoId}`)) ?? '{}',
    );
    mediaUrls?.map(media => {
      if (!memoMediaStatus[media]) memoMediaStatus[media] = false;
    });
    storage.setItem(`memoData${memoId}`, JSON.stringify(memoMediaStatus));
  }, [mediaUrls, memoId]);

  useEffect(() => {
    if (memoId) getMemo(memoId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoId]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaUrls]);

  useEffect(() => {
    const updateReadData = async () => {
      const memoData = JSON.parse(
        (await storage.getItem(`memoData${memoId}`)) ?? '{}',
      );
      if (!Object.keys(memoData)?.length)
        navigation.navigate(MainScreens.Home, {
          screen: HomeScreens.AttachmentsPage,
          params: {
            mediaUrls,
            index: 0,
            memoId,
          },
        });

      setMemoReadData(memoData);
    };
    if (mediaUrls?.length) updateReadData();
  }, [isFocused, mediaUrls]);

  if (memoLoading)
    return (
      <CenterBox style={styles.centerContainer}>
        <SpinnerComponent />
      </CenterBox>
    );

  return (
    <Header>
      <HBox style={styles.container}>
        <BackHeaderLeft />
        <Avatar size={10} backgroundColor={theme?.background.white[100]}>
          <H1Text>{avatar}</H1Text>
        </Avatar>
        <Box style={styles.detailsContainer}>
          <H6Text
            style={[
              styles.nameText,
              {
                fontFamily: muliFont[700].normal,
                color: theme?.text.blue[300],
              },
            ]}>
            {name}
          </H6Text>
          <CustomText
            style={[
              styles.textStyle,
              {
                fontFamily: muliFont[600].normal,
                color: theme?.text.gray[100],
              },
            ]}>
            {sendTo}
          </CustomText>
        </Box>
      </HBox>
      <Scrollview>
        <Box style={styles.scrollContainer}>
          <If condition={showMarkReadButton}>
            <HBox
              style={[
                styles.infoContainer,
                {backgroundColor: theme?.background.blue[200]},
              ]}>
              <Info viewBox="0 -2 26 26" fill={theme?.text.gray[100]} />
              <CustomText
                numberOfLines={2}
                style={[styles.infoText, {fontFamily: muliFont[600].normal}]}>
                Tap on “Mark as Read” in order to let us know that you’ve read
                this message!
              </CustomText>
            </HBox>
          </If>
          <Box style={styles.memoContainer}>
            <If condition={Platform.OS !== 'web'}>
              <Attachments
                isMemoRead={isMemoRead}
                memoReadData={memoReadData}
                mediaUrls={mediaUrls}
              />
            </If>
            <H5Text
              style={[
                styles.title,
                {
                  fontFamily: muliFont[900].normal,
                  color: theme?.text.blue[300],
                },
              ]}>
              {title}
            </H5Text>
            <H6Text style={[styles.body, {fontFamily: muliFont[600].normal}]}>
              {body}
            </H6Text>
          </Box>
        </Box>
      </Scrollview>
      <If condition={showMarkReadButton}>
        <Box style={styles.floatingBox}>
          <BottomButton
            title="Mark as Read"
            disabled={memoReadLoading}
            onPress={handleRead}
            isLoading={memoReadLoading}
            loadingIndicator={<SpinnerComponent />}
          />
        </Box>
      </If>
    </Header>
  );
};

export default React.memo(MemoDetails);
