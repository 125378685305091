import {Box, HBox} from '../../../../../../src/components/core/view';
import {
  formatCurrency,
  getDefaultImageUrl,
} from '../../../../../../src/utils/common';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../../src/appStyles/hooks/theme';

import {CustomImage} from '../../../../../../src/components/core/Image';
import {CustomText} from '../../../../../../src/components/core/text';
import {DriverItem} from '../../../../../../src/driver/types/orderDetails';
import React from 'react';
import {styles} from './styles';

interface ItemCellForDriverProps {
  itemInfo: DriverItem;
}

const ItemCellForDriver: React.FC<ItemCellForDriverProps> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const itemInfo = props?.itemInfo;
  const imageUrl = itemInfo?.itemImageUrl || '';
  const itemName = itemInfo?.name || '';
  const shopperChanges = itemInfo.shopperItemChangesForShopper;
  const itemPrice =
    shopperChanges?.updatedPricePerQuantity || itemInfo?.price || 0;
  const itemQuantity =
    shopperChanges?.updatedQuantity || itemInfo?.quantity || 0;
  const defaultImage = getDefaultImageUrl();

  return (
    <HBox style={styles.parentBox}>
      <Box style={styles.imageBox}>
        <CustomImage
          onErrorSource={defaultImage}
          style={styles.image}
          source={imageUrl || defaultImage}
        />
        <HBox
          style={[
            styles.qtyBox,
            {
              backgroundColor: theme?.background?.yellow?.[100],
            },
          ]}>
          <CustomText
            style={{
              color: theme?.text?.blue?.[300],
              fontFamily: muli?.[700]?.normal,
            }}>
            Qty: {itemQuantity}
          </CustomText>
        </HBox>
      </Box>
      <Box style={styles.textBox}>
        <HBox>
          <Box style={styles.namePriceBox}>
            <CustomText
              numberOfLines={1}
              alignText="left"
              style={{
                color: theme?.text?.blue?.[300],
                fontFamily: muli?.[700]?.normal,
              }}>
              {itemName}
            </CustomText>
            <CustomText
              alignText="left"
              style={[
                styles.priceText,
                {
                  color: theme?.text?.blue?.[300],
                  fontFamily: muli?.[700]?.normal,
                },
              ]}>
              {formatCurrency(itemPrice)}
            </CustomText>
          </Box>
        </HBox>
      </Box>
    </HBox>
  );
};

export default React.memo(ItemCellForDriver);
