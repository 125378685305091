import {
  FullMemo,
  GetMemoResponse,
  GetMemosResponse,
  MarkMemoReadResponse,
  Memo,
  MemoStatus,
} from '../types';
import {GET_MEMO, GET_MEMOS} from '@buncha/config/graphQL/documentNode/queries';

import {MARK_MEMO_READ} from '@buncha/config/graphQL/documentNode/mutation';
import {ToastType} from '@buncha/components/composites/notification/type';
import {gqlService} from '@buncha/config/graphQL';
import {onBoardingActions} from '@buncha/onBoarding/reducer';
import {useAppDispatch} from '@buncha/config/redux/hooks';
import {useState} from 'react';
import {useToastMessage} from '@buncha/components/composites/notification';

export function useGetMemos() {
  const FETCH_SIZE = 10;
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [showSuccessToast] = useToastMessage(ToastType.Success);
  const appDispatch = useAppDispatch();
  const [memosLoading, setLoading] = useState(false);
  const [unreadMemos, setUnReadMemos] = useState<Memo[]>([]);
  const [readMemos, setReadMemos] = useState<Memo[]>([]);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [currentSkip, setCurrentSkip] = useState(0);

  const getMemos = async (firstLoad?: true) => {
    if (memosLoading) return;
    setLoading(true);
    if (firstLoad) {
      setIsAtEnd(false);
      setReadMemos([]);
    }
    const skip = firstLoad ? 0 : currentSkip + FETCH_SIZE;
    setCurrentSkip(skip);
    try {
      const response = await gqlService?.query<GetMemosResponse>({
        query: GET_MEMOS,
        fetchPolicy: 'network-only',
        variables: {size: FETCH_SIZE, skip},
      });
      let fetchedMemos = response?.data?.getMemos ?? [];

      const incomingReadMemo = fetchedMemos.find(
        memo => memo.status === MemoStatus.read,
      )?.memo;
      const unReadMemos = fetchedMemos.find(
        memo => memo.status === MemoStatus.unread,
      );
      const incomingUnReadMemo = unReadMemos?.memo;
      const incomingUnReadMemoCount = unReadMemos?.totalLength;

      if (firstLoad) {
        setUnReadMemos(incomingUnReadMemo || []);
        appDispatch(
          onBoardingActions.setUnreadMemoCount(incomingUnReadMemoCount || 0),
        );
      }
      setReadMemos(
        firstLoad
          ? incomingReadMemo || []
          : [...readMemos, ...(incomingReadMemo || [])],
      );

      if (firstLoad && !incomingUnReadMemo?.length)
        showSuccessToast("You're all caught up.");

      if ((incomingReadMemo?.length || 0) < FETCH_SIZE) setIsAtEnd(true);
    } catch (err: any) {
      showErrorToast(err.message);
    } finally {
      setLoading(false);
    }
  };

  return {memosLoading, getMemos, readMemos, unreadMemos, isAtEnd};
}

export function useGetMemo() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [memoLoading, setLoading] = useState(true);
  const [fullMemo, setMemo] = useState<FullMemo>();

  const getMemo = async (memoId: number) => {
    setLoading(true);
    try {
      const res = await gqlService?.query<GetMemoResponse>({
        query: GET_MEMO,
        variables: {memoId},
        fetchPolicy: 'network-only',
      });

      setMemo(res?.data?.getMemo);
    } catch (err: any) {
      showErrorToast(err.message);
    } finally {
      setLoading(false);
    }
  };

  return {getMemo, fullMemo, memoLoading};
}

export function useMarkMemoRead() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [memoReadLoading, setLoading] = useState(false);

  const markMemoRead = async (memoId: number) => {
    setLoading(true);
    try {
      const res = await gqlService?.mutation<MarkMemoReadResponse>({
        mutation: MARK_MEMO_READ,
        variables: {memoId},
        fetchPolicy: 'network-only',
      });

      setLoading(false);
      return res?.data?.markMemoAsRead;
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err.message);
    }
  };

  return {markMemoRead, memoReadLoading};
}
