import React, {useCallback, useEffect, useMemo} from 'react';
import {RefreshControl, RefreshControlProps} from 'react-native';
import {useAppDispatch, useAppSelector} from '../../../config/redux/hooks';
import {
  useGetAssignedShift,
  useGetNextAssignedShift,
} from '../../../home/hooks/AssinedShift';

import AssignedShifts from '../../../home/component/assignedShifts/AssignedShifts';
import {Box} from '../../../components/core/view';
import {CustomFlatList} from '../../../components/core/list';
import If from '../../../components/conditional/If';
import NextShift from '../../../home/component/NextShift';
import NoShift from '../../../home/component/assignedShifts/NoShift';
import PendingShifts from '../PendingShifts';
import listener from '@buncha/config/listener';
import {styles} from './styles';
import {useColorThemeType} from '../../../appStyles/hooks/theme';
import {useIsFocused} from '@react-navigation/native';

interface AboutShiftPageProps {
  openModal: () => void;
}

function AboutShiftPage(props: AboutShiftPageProps) {
  const {openModal} = props;
  const theme = useColorThemeType();
  const globalState = useAppSelector(gstate => gstate.home);
  const onboardingState = useAppSelector(gState => gState.onboarding);
  const state = useAppSelector(gstate => gstate.home.shiftData);
  const nextShiftState = useAppSelector(gstate => gstate.home.nextShiftData);
  const whenIWorkToken = onboardingState.userAuthData?.wToken;
  const globalShiftData = globalState.shiftData;
  const isFocused = useIsFocused();
  const appDispatch = useAppDispatch();
  const [getAssignedShift, loadingShift] = useGetAssignedShift(appDispatch);
  const [getNextAssignedShift, loadingNextShift, loginError] =
    useGetNextAssignedShift(appDispatch);
  const loading = loadingShift || loadingNextShift;

  const onRefreshRun = useCallback(() => {
    getAssignedShift();
  }, [getAssignedShift]);

  useEffect(() => {
    const taskAssingViaLink = listener.addListener(
      'taskAssinged',
      onRefreshRun,
    );

    return taskAssingViaLink;
  }, []);

  useEffect(() => {
    if (whenIWorkToken?.length && isFocused) getNextAssignedShift();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whenIWorkToken, isFocused]);
  const noShift = useMemo(() => {
    return !state?.active?.shifts && !nextShiftState?.upcoming?.length;
  }, [nextShiftState?.upcoming, state?.active?.shifts]);

  return (
    <CustomFlatList
      refreshControl={
        (
          <RefreshControl
            refreshing={loading}
            onRefresh={onRefreshRun}
            tintColor={theme?.background.gray[100]}
            style={styles.refreshControl}
          />
        ) as React.ReactElement<RefreshControlProps>
      }
      ListHeaderComponent={
        <Box>
          <If condition={globalShiftData?.pending?.length}>
            <PendingShifts />
          </If>
          <If condition={globalShiftData?.active}>
            <NextShift />
          </If>
          <AssignedShifts loginError={loginError} openModal={openModal} />
        </Box>
      }
      ListEmptyComponent={
        <If condition={!loading && noShift}>
          <NoShift />
        </If>
      }
      data={undefined}
      renderItem={undefined}
      contentContainerStyle={styles.contentContainerStyle}
      showsVerticalScrollIndicator={false}
    />
  );
}

export default React.memo(AboutShiftPage);
