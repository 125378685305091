import {
  BatchDetailTabs,
  ReceiptBypassMode,
  itemsForSection,
  itemsSortBy,
} from '../types';
import {Box, HBox} from '../../components/core/view';
import {HomeScreens, ShopperScreens} from '../../home/navigation/types';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {
  batchDetailActionsLocal,
  useBatchDetailReducer,
} from '../hooks/batchDetail';
import {
  useGetOrdersForOrderAssigneeCount,
  useGetRunForBatchDetail,
  useUPCShift,
} from '../hooks/BatchDetails';

import {Analytics} from '../../config/analytics/analytics';
import {BatchDetailContext} from '../context/BatchDetailContext';
import BatchDetailHeader from '../component/BatchDetailHeader';
import BatchScreenComponent from '../components/BatchScreenComponent/BatchScreenComponent';
import {ChatModes} from '../types/Chat';
import ChatOpener from '@buncha/components/composites/ChatOpener';
import ContactBottomSheet from '../../itemDetails/components/ItemDetail/contactBottomSheet';
import CustomModal from '@buncha/common/components/CustomModal/CustomModal';
import {Divider} from 'native-base';
import DoneItemList from '../component/DoneItemList';
import {EventType} from '../../config/analytics/type';
import Header from '../../components/composites/Header/Header';
import If from '../../components/conditional/If';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import PassOrderModal from '../component/PassOrderModal/PassOrderModal';
import PdfIcon from '../../components/core/icon/PdfIcon';
import ReceiptIcon from '../../components/core/icon/ReceiptIcon';
import ScreenHeader from '../../common/components/screenHeader';
import SortBar from '../component/SortBar';
import {SpinnerComponent} from '../../components/core/loader';
import Tab from '../../components/composites/Tabs/Tab';
import {TabNode} from '../../components/shared/TabNode';
import TodoItemList from '../component/TodoItemList';
import {Touchable} from '../../components/core/touchable';
import {isWeb} from '@buncha/utils/common';
import listener from '../../config/listener';
import {navigation} from '../../config/navigation';
import {useColorThemeType} from '../../appStyles/hooks/theme';
import {useIsFocused} from '@react-navigation/native';

type Props = NativeStackScreenProps<any, any>;

const BatchDetailsPage = (props: Props) => {
  const theme = useColorThemeType('main');
  const [open, setOpen] = useState(false);
  const keyRef = useRef('');
  const [state, dispatch] = useBatchDetailReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);

  const [getRunForBatchDetail, runState] = useGetRunForBatchDetail();
  const {getOrderItemsCount} = useGetOrdersForOrderAssigneeCount(dispatch);
  const {orderLoading} = useUPCShift(dispatch);
  const isFocus = useIsFocused();
  const [hasNavigateToBack, setHasNavigateToBack] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>();

  const disableTodo = state.disableToDo;

  const contactBottomSheetProps = state.contactSheetProps;

  const params = props.route.params;
  const runId = Number(params?.runId) || 0;
  const isUPCMode = params?.isUPCMode || false;
  const upcOrderId = params?.upcOrderId || 0;

  const sections: {[key: string]: React.ReactElement} = {};
  const tabHeader = [
    BatchDetailTabs.todo,
    BatchDetailTabs.inReview,
    BatchDetailTabs.done,
  ];

  const closeContactSheet = useCallback(() => {
    if (dispatch)
      dispatch(batchDetailActionsLocal.setContactBottomSheetProps(undefined));
  }, [dispatch]);

  const navigateToReceiptScreen = useCallback(() => {
    Analytics.eventWithProps('Open Receipt Modal', {
      type: EventType.cta,
      runId,
    });
    setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
    if (dispatch)
      dispatch(
        batchDetailActionsLocal.setSkippedReceiptFlow(
          ReceiptBypassMode.skipByShopper,
        ),
      );
    if (runId) getRunForBatchDetail(runId);
  }, [dispatch, getRunForBatchDetail, runId]);

  const skipReceiptFlowHandler = useCallback(() => {
    if (dispatch) {
      dispatch(
        batchDetailActionsLocal.setSkippedReceiptFlow(
          ReceiptBypassMode.skipByShopper,
        ),
      );
      closeModal();
    }
  }, [closeModal, dispatch]);

  const renderComponent = useMemo(() => {
    if (open)
      return (
        <BatchScreenComponent
          isOpen={open}
          onClose={closeModal}
          runId={runId}
          hasSkip={state?.hasSkipReceipt}
          hasSkipReceiptHandler={skipReceiptFlowHandler}
        />
      );
  }, [closeModal, open, runId, skipReceiptFlowHandler, state?.hasSkipReceipt]);

  const navigateToPdfDetailScreen = useCallback(() => {
    Analytics.eventWithProps('Navigate to PDF', {
      type: EventType.cta,
      runId,
    });
    navigation.navigate(ShopperScreens.PdfDetailScreen, {
      runId: runId ?? '',
    });
  }, [runId]);

  useEffect(() => {
    if (runId) getRunForBatchDetail(runId);
    if (params?.tutorial === 'true' && dispatch)
      dispatch(batchDetailActionsLocal.setTutorial(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId]);

  tabHeader.forEach(tab => {
    switch (tab) {
      case BatchDetailTabs.todo:
        sections[tab] = (
          <TodoItemList
            isUPCMode={isUPCMode}
            runDetail={runState.data ?? undefined}
            upcOrderId={upcOrderId}
          />
        );
        break;
      case BatchDetailTabs.done:
        sections[tab] = (
          <DoneItemList
            isUPCMode={isUPCMode}
            runDetail={runState.data ?? undefined}
            upcOrderId={upcOrderId}
            navigateToReceiptScreen={navigateToReceiptScreen}
          />
        );
        break;
      default:
        sections[BatchDetailTabs.todo] = (
          <TodoItemList
            isUPCMode={isUPCMode}
            runDetail={runState.data ?? undefined}
            upcOrderId={upcOrderId}
          />
        );
    }
  });

  const tabNode = useCallback((info: any, activeKey: any) => {
    return <TabNode info={info} activeKey={activeKey} />;
  }, []);

  const refreshCount = useCallback(() => {
    getOrderItemsCount(runId, itemsForSection.todo, state.sortBarValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId, state.sortBarValue]);

  useEffect(() => {
    const unsubscribe = listener.addListener('itemUpdate', refreshCount);
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (runId)
      Analytics.eventWithProps('Batch Details Screen', {
        type: EventType.page,
        runID: runId,
        storeName: runState?.data?.storeName,
        sortBy: state.sortBarValue || itemsSortBy.category,
        status: 'In Picking Status',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId]);

  const navigateToCheckTask = useCallback(() => {
    dispatch(batchDetailActionsLocal.setPickedOrderFoundModal(false));
    setHasNavigateToBack(true);
  }, [dispatch]);

  useEffect(() => {
    if (hasNavigateToBack && !state.hasPickedOrderModal) {
      navigation.replace(HomeScreens.Shopper, {
        screen: ShopperScreens.BatchDetailsPage,
        params: {runId: runId},
      });
      setHasNavigateToBack(false);
    }
  }, [hasNavigateToBack, runId, state.hasPickedOrderModal]);

  useEffect(() => {
    setCurrentTab(disableTodo ? BatchDetailTabs.done : undefined);
  }, [disableTodo]);

  return (
    <BatchDetailContext.Provider value={provider}>
      <Header
        loading={runState.loading}
        showDefaultLeftHeader
        headerRightComponent={
          <If condition={!isUPCMode}>
            <HBox style={[styles.headerIconContainer]}>
              <TouchableOpacity onPress={navigateToReceiptScreen}>
                <ReceiptIcon
                  fill={theme?.background.gray[100]}
                  viewBox="-4 -10 25 25"
                />
              </TouchableOpacity>
              <Touchable onPress={navigateToPdfDetailScreen}>
                <PdfIcon
                  fill={theme?.background.gray[100]}
                  viewBox="0 -5 25 25"
                  style={[styles.rightIcon]}
                />
              </Touchable>
              <If condition={!isWeb()}>
                <ChatOpener
                  mode={ChatModes.run}
                  runId={isFocus ? runId : undefined}
                />
              </If>
            </HBox>
          </If>
        }
        headerColor={theme?.background.blue[300]}>
        <If condition={isUPCMode}>
          <ScreenHeader title="UPC Shift" />
        </If>
        <Divider />
        <Box style={styles.contentBox}>
          <If condition={runState.loading || orderLoading}>
            <Box style={styles.loaderStyle}>
              <SpinnerComponent size={'large'} />
            </Box>
          </If>
          <If
            condition={
              (!isUPCMode && !runState.loading) || (isUPCMode && !orderLoading)
            }>
            <If condition={runState.data || state?.upcOrderData?.upcOrder}>
              <BatchDetailHeader
                showRunPill={!isUPCMode}
                runDetail={runState.data ?? undefined}
                upcOrderData={state?.upcOrderData?.upcOrder}
              />
            </If>
            <If
              condition={
                state.showSortBar || state?.upcOrderData?.orderItems?.length
              }>
              <SortBar upcMode={isUPCMode} />
            </If>
            <Tab
              key={keyRef.current}
              activeKey={currentTab}
              tabData={[
                {
                  key: BatchDetailTabs.todo,
                  data: state.tabDataCount?.todoCount
                    ? `To-do(${state.tabDataCount?.todoCount})`
                    : 'To-do',
                },
                {
                  key: BatchDetailTabs.done,
                  data: state.tabDataCount.doneCount
                    ? `Done(${state.tabDataCount.doneCount})`
                    : 'Done',
                },
              ]}
              sections={sections}
              style={styles.tabContainer}
              tabNode={tabNode}
            />
          </If>
          {renderComponent}
        </Box>

        <If condition={contactBottomSheetProps}>
          <ContactBottomSheet
            isOpen={Boolean(contactBottomSheetProps)}
            onClose={closeContactSheet}
            showSMSTemplates={true}
            type={contactBottomSheetProps?.type}
            buttonText={contactBottomSheetProps?.buttonText}
            onButtonClick={contactBottomSheetProps?.onButtonClick}
            orderId={contactBottomSheetProps?.orderId}
            runId={runId}
            orderItemId={contactBottomSheetProps?.orderItemId}
          />
        </If>

        <If condition={state?.hasPickedOrderModal}>
          <CustomModal
            open={state?.hasPickedOrderModal}
            onClose={() => {
              dispatch(batchDetailActionsLocal.setPickedOrderFoundModal(false));
            }}>
            <PassOrderModal
              onContinueClick={() => navigateToCheckTask()}
              title={'Action Required!'}
              ctaTitle={'Check Task'}
              subtitle={
                'Looks like the dispatcher team has assigned more orders to you. Please go back and check the newly assigned tasks in this delivery.'
              }
            />
          </CustomModal>
        </If>
      </Header>
    </BatchDetailContext.Provider>
  );
};

export default React.memo(BatchDetailsPage);

const styles = StyleSheet.create({
  headerIconContainer: {
    marginRight: 20,
  },
  rightIcon: {
    marginLeft: 15,
  },
  tabContainer: {
    marginTop: 15,
    borderWidth: 2,
    height: '100%',
  },
  contentBox: {
    height: '100%',
    flex: 1,
  },
  loaderStyle: {flex: 1, justifyContent: 'center', alignItems: 'center'},
});
