import {
  FullUser,
  OrderItems,
  ShiftsFragment,
  ShopperSessionFragment,
  WhenIWorkLogin,
} from './fragment';

import {gql} from '@apollo/client';

export const SEND_SMS_CODE = gql`
  mutation sendSMSCode($phoneNumber: String!) {
    sendSMSCode(phoneNumber: $phoneNumber) {
      code
    }
  }
`;

export const VERIFY_SMS_CODE = gql`
  mutation verifySMSCode($phoneNumber: String!, $code: String!) {
    verifySMSCode(phoneNumber: $phoneNumber, code: $code) {
      loginToken
      createUserToken
    }
  }
`;

export const REGISTER_SHOPPER = gql`
  mutation CreateUser(
    $createUserToken: String!
    $phoneNumber: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $invitationLink: String
    $facebookId: String
    $profileImage: String
    $referrerId: Int
    $userTypes: [UserType]
  ) {
    createUser(
      createUserToken: $createUserToken
      phoneNumber: $phoneNumber
      email: $email
      firstName: $firstName
      lastName: $lastName
      invitationLink: $invitationLink
      facebookId: $facebookId
      profileImage: $profileImage
      referrerId: $referrerId
      userTypes: $userTypes
    ) {
      loginToken
      user {
        ...FullUser
      }
    }
  }
  ${FullUser}
`;

export const Set_User_Attendance_Status = gql`
  mutation setUserAttendanceStatus($status: AttendanceStatus) {
    setUserAttendanceStatus(status: $status) {
      id
      userId
      status
      shiftId
    }
  }
`;

export const REGISTER_DEVICE_FOR_NOTIFICATIONS = gql`
  mutation RegisterDeviceForNotifications(
    $uuid: String!
    $pushToken: String!
    $platform: String
  ) {
    registerDeviceForNotifications(
      uuid: $uuid
      pushToken: $pushToken
      platform: $platform
    ) {
      id
      disabled
    }
  }
`;

export const UPDATE_NOTIFICATION_STATUS = gql`
  mutation updateNotificationStatus(
    $status: NotificationStatus
    $notificationId: Int
  ) {
    updateNotificationStatus(status: $status, notificationId: $notificationId)
  }
`;

export const WHEN_I_WORK_LOGIN = gql`
  mutation whenIWorkLogin($credential: WhenIWorkCredential) {
    whenIWorkLogin(credential: $credential) {
      ...WhenIWorkLogin
    }
  }
  ${WhenIWorkLogin}
`;

export const LOGOUT = gql`
  mutation Logout($deviceUuid: String) {
    logout(deviceUuid: $deviceUuid)
  }
`;

export const UPLOAD_RUN_RECEIPT = gql`
  mutation uploadRunReceipt(
    $runId: Int
    $clientUploadInfo: ClientFileUpload
    $uploadBy: UploadBy
  ) {
    uploadRunReceipt(
      runId: $runId
      clientUploadInfo: $clientUploadInfo
      uploadBy: $uploadBy
    ) {
      id
      fileName
      fileUrl
    }
  }
`;

export const DELETE_DELIVERY_IMAGE = gql`
  mutation deleteRunreceipt($runId: Int, $receiptUrl: String) {
    deleteRunreceipt(runId: $runId, receiptUrl: $receiptUrl)
  }
`;
export const UPDATE_SHOPPER_SESSION_STATUS = gql`
  mutation updateShopperSessionStatus(
    $sessionId: Int
    $status: ShopperSessionStatus
    $shiftId: String
    $latitude: Float
    $longitude: Float
  ) {
    updateShopperSessionStatus(
      sessionId: $sessionId
      status: $status
      shiftId: $shiftId
      latitude: $latitude
      longitude: $longitude
    ) {
      ...ShopperSessionFragment
    }
  }
  ${ShopperSessionFragment}
`;

export const UPDATE_USER_CHOICE_MAP = gql`
  mutation updateUserChoiceMap($mapChoice: MapChoice) {
    updateUserChoiceMap(mapChoice: $mapChoice)
  }
`;

export const TAKE_SHIFT = gql`
  mutation takeShift($shiftId: ID) {
    takeShift(shiftId: $shiftId) {
      availableShift {
        days {
          key
          shifts {
            ...ShiftsFragment
          }
        }
      }
    }
  }
  ${ShiftsFragment}
`;

export const ADD_NOTE_FOR_ORDER_ITEM = gql`
  mutation addNoteForOrderItem($orderItemId: Int, $note: String) {
    addNoteForOrderItem(orderItemId: $orderItemId, note: $note) {
      note
    }
  }
`;

export const UPDATE_ORDER_ITEM_STATUS = gql`
  mutation updateOrderItemStatus(
    $orderItemStatus: OrderItemStatus
    $orderItemIds: [Int]
  ) {
    updateOrderItemStatus(
      orderItemStatus: $orderItemStatus
      orderItemIds: $orderItemIds
    ) {
      ...OrderItems
    }
  }
  ${OrderItems}
`;

export const SET_DELIVERY_LOCATION_STATUS_ORDERS = gql`
  mutation setDeliveryLocationStatusOfAssignedOrders(
    $runId: Int
    $status: DeliveryLocationStatus
    $orderIds: [Int]
  ) {
    setDeliveryLocationStatusOfAssignedOrders(
      runId: $runId
      status: $status
      orderIds: $orderIds
    ) {
      id
      deliveryStatus
    }
  }
`;

export const GET_ORDER_ASSIGNEE = gql`
  mutation countForOrdersAssignedToDriver($runId: Int) {
    countForOrdersAssignedToDriver(runId: $runId)
  }
`;

export const SEND_SUBSTITUTION_REQUEST_TO_BUYER = gql`
  mutation sendRequestToBuyer(
    $orderItemId: Int!
    $menuItemId: Int
    $catalogItemId: Int
    $quantity: Int
    $menuOptionIds: [Int!]
    $itemIndex: Int
    $itemSource: String
  ) {
    sendRequestToBuyer(
      orderItemId: $orderItemId
      menuItemId: $menuItemId
      catalogItemId: $catalogItemId
      quantity: $quantity
      menuOptionIds: $menuOptionIds
      itemIndex: $itemIndex
      itemSource: $itemSource
    ) {
      id
    }
  }
`;
export const ADD_ITEM_REMARK = gql`
  mutation addItemRemark($orderItemId: Int, $itemRemark: String) {
    addItemRemark(orderItemId: $orderItemId, itemRemark: $itemRemark)
  }
`;

export const SUBSTITUTE_ITEM_IN_RUN = gql`
  mutation substituteItemInRun(
    $orderId: Int!
    $orderItemId: Int!
    $priceWithTax: Int
    $name: String!
    $itemImageUrl: String
    $options: String
    $position: Int!
    $menuItemId: Int
    $catalogItemId: Int
    $menuOptionGroupId: Int
    $menuOptionId: [Int]
    $price: Int!
    $quantity: Int!
    $tags: String
    $isCustomItem: Boolean!
    $substitutedBy: String!
    $substitutionItemId: Int
    $substitutionRequestParmas: ItemSubstitutionRequestParamas
    $itemIndex: Int
    $itemSource: String
    $isPriceSensitive: Boolean
    $isWeightSensitive: Boolean
  ) {
    substituteItemInRun(
      orderId: $orderId
      orderItemId: $orderItemId
      name: $name
      itemImageUrl: $itemImageUrl
      options: $options
      position: $position
      menuItemId: $menuItemId
      catalogItemId: $catalogItemId
      menuOptionGroupId: $menuOptionGroupId
      menuOptionId: $menuOptionId
      price: $price
      priceWithTax: $priceWithTax
      quantity: $quantity
      tags: $tags
      isCustomItem: $isCustomItem
      substitutedBy: $substitutedBy
      substitutionItemId: $substitutionItemId
      substitutionRequestParmas: $substitutionRequestParmas
      itemIndex: $itemIndex
      itemSource: $itemSource
      isPriceSensitive: $isPriceSensitive
      isWeightSensitive: $isWeightSensitive
    ) {
      id
    }
  }
`;

export const SCAN_UPC_FOR_ALL_ITEMS = gql`
  mutation scanUPCForAllItems(
    $upc: String
    $catalogItemId: Int!
    $storeIndex: String!
    $runId: Int
    $lon: Float
    $lat: Float
  ) {
    scanUPCForAllItems(
      upc: $upc
      catalogItemId: $catalogItemId
      storeIndex: $storeIndex
      runId: $runId
      lon: $lon
      lat: $lat
    ) {
      message
      scanned
    }
  }
`;

export const SCAN_UPC_FOR_ALL_ITEMS_IMAGES = gql`
  mutation scanUPCForAllItems(
    $images: [String]
    $catalogItemId: Int!
    $storeIndex: String!
    $runId: Int
    $lon: Float
    $lat: Float
  ) {
    scanUPCForAllItems(
      images: $images
      catalogItemId: $catalogItemId
      storeIndex: $storeIndex
      runId: $runId
      lon: $lon
      lat: $lat
    ) {
      message
      scanned
    }
  }
`;

export const UPDATE_ITEMS_PRICE = gql`
  mutation updateItemsPrice($orderItemId: Int, $price: Int, $runId: Int) {
    updateItemsPrice(orderItemId: $orderItemId, price: $price, runId: $runId)
  }
`;

export const DELETE_RUN_RECEIPT = gql`
  mutation deleteRunreceipt($runId: Int, $receiptUrl: String) {
    deleteRunreceipt(runId: $runId, receiptUrl: $receiptUrl)
  }
`;

export const UPLOAD_UPC_IMAGE = gql`
  mutation uploadUpcImages(
    $storeIndex: String!
    $orderItemId: Int
    $file: Upload
  ) {
    uploadUpcImages(
      storeIndex: $storeIndex
      orderItemId: $orderItemId
      file: $file
    )
  }
`;

export const ADD_ITEM = gql`
  mutation addItemInRun(
    $orderId: Int!
    $priceWithTax: Int
    $name: String!
    $itemImageUrl: String
    $options: String
    $position: Int!
    $menuItemId: Int
    $catalogItemId: Int
    $menuOptionGroupId: Int
    $menuOptionId: [Int]
    $price: Int!
    $quantity: Int!
    $tags: String
    $isCustomItem: Boolean!
    $itemUnit: String
    $source: String
    $priceSensitive: Boolean!
    $weightSensitive: Boolean!
  ) {
    addItemInRun(
      orderId: $orderId
      name: $name
      itemImageUrl: $itemImageUrl
      options: $options
      position: $position
      menuItemId: $menuItemId
      catalogItemId: $catalogItemId
      menuOptionGroupId: $menuOptionGroupId
      menuOptionId: $menuOptionId
      price: $price
      priceWithTax: $priceWithTax
      quantity: $quantity
      tags: $tags
      isCustomItem: $isCustomItem
      itemUnit: $itemUnit
      source: $source
      priceSensitive: $priceSensitive
      weightSensitive: $weightSensitive
    ) {
      id
    }
  }
`;

export const SET_SUBSTITUTION_STATUS = gql`
  mutation setRequestStatus(
    $requestIds: [Int!]
    $status: SubstitutionRequestStatus!
    $runId: Int
    $navigateToScreen: String
  ) {
    setRequestStatus(
      requestIds: $requestIds
      status: $status
      runId: $runId
      navigateToScreen: $navigateToScreen
    ) {
      id
    }
  }
`;

export const CONNECT_STRIPE_ACCOUNT = gql`
  mutation connectRunnerStripeAccount($code: String!) {
    connectRunnerStripeAccount(code: $code)
  }
`;

export const IS_ACCOUNT_DELETABLE = gql`
  mutation isUserDeletable {
    isUserDeletable {
      isDeletable
      header
      subtext
    }
  }
`;

export const SET_DELIVERY_LOCATION_STATUS = gql`
  mutation setDeliveryLocationStatus(
    $deliveryLocationId: Int!
    $status: DeliveryLocationStatus!
    $deliveryFailureReason: String
    $runId: Int
  ) {
    setDeliveryLocationStatus(
      deliveryLocationId: $deliveryLocationId
      status: $status
      deliveryFailureReason: $deliveryFailureReason
      runId: $runId
    ) {
      id
      runId
      deliveryFailureReason
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteCurrentUser {
    deleteCurrentUser {
      isDeleted
      header
      subtext
    }
  }
`;

export const UPLOAD_DELIVERY_IMAGE = gql`
  mutation uploadDeliveryImage(
    $orderId: Int
    $file: Upload
    $deliveryImageType: DeliveryImageType
    $runId: Int
  ) {
    uploadDeliveryImage(
      orderId: $orderId
      file: $file
      deliveryImageType: $deliveryImageType
      runId: $runId
    ) {
      uri
    }
  }
`;

export const ADD_NOTE_FOR_DELIVERY_LOCATION = gql`
  mutation addNoteForDeliveryLocation($deliveryLocationId: Int, $note: String) {
    addNoteForDeliveryLocation(
      deliveryLocationId: $deliveryLocationId
      note: $note
    ) {
      note
    }
  }
`;

export const DELETE_DELIVERY_IMAGE_FOR_DRIVER = gql`
  mutation deleteDeliveryImage(
    $deliveryLocationId: Int
    $deliveryImageUrl: String
  ) {
    deleteDeliveryImage(
      deliveryLocationId: $deliveryLocationId
      deliveryImageUrl: $deliveryImageUrl
    )
  }
`;

export const UPLOAD_VAN_INSPECTION_IMAGE = gql`
  mutation uploadVanInspectionImages($file: Upload, $runId: Int) {
    uploadVanInspectionImages(file: $file, runId: $runId) {
      uri
    }
  }
`;

export const START_RUNNER_TUTORIAL = gql`
  mutation startTutorialForRunner($userType: UserType) {
    startTutorialForRunner(userType: $userType) {
      runId
      orderId
    }
  }
`;

export const FINISH_RUNNER_TUTORIAL = gql`
  mutation finishTutorialForRunner($name: TutorialNames) {
    finishTutorialForRunner(name: $name)
  }
`;

export const SET_ORDER_ASSIGNEE = gql`
  mutation setOrderAssignee(
    $authorId: Int!
    $orderIds: [Int!]
    $runId: Int!
    $link: String!
    $assignTo: AssignTo
  ) {
    setOrderAssignee(
      authorId: $authorId
      orderIds: $orderIds
      runId: $runId
      link: $link
      assignTo: $assignTo
    ) {
      id
    }
  }
`;

export const MARK_MEMO_READ = gql`
  mutation markMemoAsRead($memoId: Int) {
    markMemoAsRead(memoId: $memoId)
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation itemImageUpload($file: Upload) {
    itemImageUpload(file: $file) {
      uri
    }
  }
`;

export const COMPLETE_VAN_INSPECTION = gql`
  mutation completeVanInspection($startDate: String) {
    completeVanInspection(startDate: $startDate)
  }
`;

export const UPDATE_ITEM_IMAGE = gql`
  mutation updateCustomItemImage($itemImageUrl: String, $orderItemId: Int) {
    updateCustomItemImage(
      itemImageUrl: $itemImageUrl
      orderItemId: $orderItemId
    )
  }
`;

export const UPDATE_SHOPPER_ITEM_CHANGES = gql`
  mutation updateShopperItemChanges($shopperItemChanges: [ShopperItemChanges]) {
    updateShopperItemChanges(shopperItemChanges: $shopperItemChanges)
  }
`;

// UPC Shift APIs

export const CREATE_UPC_SHIFT = gql`
  mutation createUpcOrder(
    $storeName: String
    $storeLogo: String
    $esIndex: String
    $noOfOrderItems: Int
  ) {
    createUpcOrder(
      storeName: $storeName
      storeLogo: $storeLogo
      esIndex: $esIndex
      noOfOrderItems: $noOfOrderItems
    ) {
      created
      message
      upcOrder {
        id
        storeName
        storeLogo
        esIndex
        shopperId
        orderStatus
      }
    }
  }
`;

export const SUBMIT_STARTING_BAG_COUNT = gql`
  mutation submitStartingBagCount($runBagCount: RunBagCount) {
    submitStartingBagCount(runBagCount: $runBagCount) {
      id
      bagsCount
    }
  }
`;

export const SCAN_UPC = gql`
  mutation scanUPC(
    $upc: String
    $images: [String]
    $catalogItemId: Int!
    $storeIndex: String!
    $orderItemId: Int
    $lon: Float
    $lat: Float
    $isUpcOrder: Boolean = false
  ) {
    scanUPC(
      upc: $upc
      images: $images
      catalogItemId: $catalogItemId
      storeIndex: $storeIndex
      orderItemId: $orderItemId
      lon: $lon
      lat: $lat
      isUpcOrder: $isUpcOrder
    ) {
      message
      orderItem {
        id
      }
    }
  }
`;

export const DROP_UPC_ITEMS = gql`
  mutation dropUpcOrderItem($upcOrderItemId: Int!) {
    dropUpcOrderItem(upcOrderItemId: $upcOrderItemId)
  }
`;

export const CAPTURE_ITEM_INFORMATION = gql`
  mutation captureItemInformation(
    $isUpcOrder: Boolean
    $orderItemId: Int
    $aisle: String
    $storeContext: String
    $locationImage: String
    $price: Int
    $esIndex: String!
  ) {
    captureItemInformation(
      isUpcOrder: $isUpcOrder
      orderItemId: $orderItemId
      aisle: $aisle
      storeContext: $storeContext
      locationImage: $locationImage
      price: $price
      esIndex: $esIndex
    ) {
      id
    }
  }
`;

export const ADD_SUBSTITUTE_FOR_UPC = gql`
  mutation addSubstitutionSuggestion(
    $upcOrderItemId: Int
    $catalogItemId: Int
    $name: String
    $price: Int
    $isCustomItem: Boolean
    $itemImageUrl: String
    $note: String
  ) {
    addSubstitutionSuggestion(
      upcOrderItemId: $upcOrderItemId
      catalogItemId: $catalogItemId
      name: $name
      price: $price
      isCustomItem: $isCustomItem
      itemImageUrl: $itemImageUrl
      note: $note
    ) {
      catalogItemId
    }
  }
`;

export const MARK_ITEM_AS_FOUND_OR_MISSING = gql`
  mutation markItemAsFoundOrMissing(
    $itemInfoId: Int!
    $foundHere: Boolean
    $reportMissing: Boolean
  ) {
    markItemAsFoundOrMissing(
      itemInfoId: $itemInfoId
      foundHere: $foundHere
      reportMissing: $reportMissing
    ) {
      aisle
      catalogItemId
      esIndex
      foundHere
      id
      itemForReplacement
      locationImage
      orderItemId
      price
      reportMissing
      storeContext
      upcOrderItemId
    }
  }
`;

export const UPDATE_UPC_ORDER_STATUS = gql`
  mutation updateUpcOrderStatus($upcOrderId: Int!, $status: UpcOrderStatus) {
    updateUpcOrderStatus(upcOrderId: $upcOrderId, status: $status)
  }
`;

export const SCAN_UPC_FOR_CROWD_SOURCE = gql`
  mutation ScanUPC(
    $catalogItemId: Int!
    $storeIndex: String!
    $upc: String
    $images: [String]
    $orderItemId: Int
    $lat: Float
    $lon: Float
    $isUpcOrder: Boolean
  ) {
    scanUPC(
      catalogItemId: $catalogItemId
      storeIndex: $storeIndex
      upc: $upc
      images: $images
      orderItemId: $orderItemId
      lat: $lat
      lon: $lon
      isUpcOrder: $isUpcOrder
    ) {
      message
      orderItem {
        id
      }
    }
  }
`;

export const SUBMIT_DELIVERY_BAGS_COUNT = gql`
  mutation submitDeliveryBagsCount(
    $runBagCount: RunBagCount
    $returnBagForOrder: ReturnedBagForOrder
  ) {
    submitDeliveryBagsCount(
      runBagCount: $runBagCount
      returnBagForOrder: $returnBagForOrder
    ) {
      id
      bagsCount
    }
  }
`;

export const UPDATE_ORDER_ITEM_QUANTITY = gql`
  mutation UpdateOrderItemQuantity($orderItemId: Int, $itemQuantity: Int) {
    updateOrderItemQuantity(
      orderItemId: $orderItemId
      itemQuantity: $itemQuantity
    ) {
      id
      quantity
    }
  }
`;

export const UPLOAD_RUN_RECEIPT_OLD = gql`
  mutation uploadRunReceipt($runId: Int, $file: Upload) {
    uploadRunReceipt(runId: $runId, file: $file) {
      id
      fileName
      fileUrl
    }
  }
`;
