import {StyleSheet} from 'react-native';
import {getWidth} from '@buncha/utils/common';

export const styles = StyleSheet.create({
  updateBox: {
    height: '100%',
    justifyContent: 'space-between',
  },
  containerBox: {
    paddingHorizontal: 20,
    paddingTop: 5,
  },
  flatList: {
    height: '95%',
  },
  divider: {
    height: 0.5,
    marginVertical: 5,
  },
  flatListContainer: {
    paddingBottom: 200,
  },
  remainingText: {
    marginRight: 10,
    textAlign: 'right',
  },
  inputStyle: {
    width: getWidth(70),
    height: 20,
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  quantityText: {
    textAlign: 'left',
    marginBottom: 20,
  },
  bottomBox: {
    justifyContent: 'space-between',
    width: '100%',
    alignSelf: 'stretch',
    marginLeft: 5,
    marginBottom: 5,
  },
  quantityContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  quantityPill: {
    borderWidth: 1,
    padding: 10,
    borderRadius: 50,
  },
});
