import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useColorThemeType, useFontTheme} from '../../appStyles/hooks/theme';
import {
  useGetOrderItemInfo,
  useItemReplacementReducer,
} from '../hooks/itemReplacement';

import {Box} from '../../components/core/view';
import ContactBottomSheet from '../components/ItemDetail/contactBottomSheet';
import DropItemFooter from '../components/itemReplacement/dropItemFooter';
import {DynamicTextType} from '@buncha/batchDetails/types';
import {H4Text} from '../../../src/components/core/text';
import Header from '../../components/composites/Header/Header';
import If from '@buncha/components/conditional/If';
import {ItemReplacementContext} from '../context/itemReplacement';
import ItemToBeReplacedCell from '../components/itemReplacement/itemToBeReplacedCell';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {PButton} from '@buncha/components/core/Button';
import {ReplacementItemDetails} from '../types/itemReplacement';
import ReplacementSuggestions from '../components/itemReplacement/replacementSuggestions';
import ScreenHeader from '../../../src/common/components/screenHeader';
import {StyleSheet} from 'react-native';
import {SubstitutionChoice} from '../types/itemDetails';
import {useAppSelector} from '../../config/redux/hooks';

type Props = NativeStackScreenProps<any, any>;

const ItemReplacement: React.FC<Props> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const [state, dispatch] = useItemReplacementReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);

  const params = props?.route?.params;
  const runId = Number(params?.runId) || 0;
  const orderId = Number(params?.orderId) || 0;
  const orderItemId = Number(params?.orderItemId) || 0;
  const hasSubstitute = Boolean(params?.hasSubstitute) || 0;
  const subChoice = state?.item?.substitutionChoice;
  const [showContactSheet, setShowContactSheet] = useState(false);

  const {getOrderItemInfo, loading, data} = useGetOrderItemInfo(dispatch);
  const {upcOrderItem} = useAppSelector(gState => gState.itemScanner);

  const item = useMemo(() => {
    if (params?.isUPCMode)
      return {
        id: upcOrderItem?.id,
        itemImageUrl: upcOrderItem?.itemImageUrl,
        name: upcOrderItem?.name,
        quantity: upcOrderItem?.quantity,
        catalogItemId: upcOrderItem?.catalogItemId,
        tags: [],
        price: upcOrderItem?.price,
      };
    return data;
  }, [
    data,
    params?.isUPCMode,
    upcOrderItem?.catalogItemId,
    upcOrderItem?.id,
    upcOrderItem?.itemImageUrl,
    upcOrderItem?.name,
    upcOrderItem?.price,
    upcOrderItem?.quantity,
  ]);

  useEffect(() => {
    if (!params?.isUPCMode) getOrderItemInfo(orderItemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleContactSheet = useCallback(() => {
    setShowContactSheet(!showContactSheet);
  }, [showContactSheet]);

  return (
    <Header
      loading={loading}
      style={styles.parentBox}
      headerColor={theme?.background?.blue?.[300]}
      showDefaultLeftHeader>
      <ScreenHeader title="Add Replacement" />
      <ItemReplacementContext.Provider value={provider}>
        <Box style={styles.mainContainer}>
          <H4Text
            style={[
              styles.headerText,
              {
                color: theme?.text?.blue?.[300],
                fontFamily: muli?.[700]?.normal,
              },
            ]}>
            {data?.tags || ''}
          </H4Text>

          <ItemToBeReplacedCell
            itemData={item as ReplacementItemDetails}
            isUPCMode={params?.isUPCMode}
          />
          <ReplacementSuggestions
            catalogItemId={data?.catalogItemId || 0}
            category={data?.tags || ''}
            searchQuery={data?.name || ''}
            storeId={data?.order?.run?.store?.id || 0}
            subCategory={''}
            isUPCMode={params?.isUPCMode}
            orderItemId={orderItemId}
            orderId={data?.order?.id}
            runId={data?.order?.run?.id}
            quantity={data?.quantity}
          />
          <If condition={hasSubstitute}>
            <PButton
              title={'Contact Customer'}
              style={[styles.actionButton]}
              isLoading={loading}
              changeStyleOnLoading
              onPress={toggleContactSheet}
            />
          </If>
        </Box>

        <If condition={showContactSheet && hasSubstitute}>
          <ContactBottomSheet
            isOpen={showContactSheet}
            onClose={toggleContactSheet}
            // buyerInfo={props?.buyers}
            showSMSTemplates={true}
            type={DynamicTextType.recommendSubs}
            runId={runId}
            orderId={orderId}
            hasSubstitute={false}
          />
        </If>

        <DropItemFooter
          orderItemId={orderItemId}
          orderId={data?.order?.id}
          runId={data?.order?.run?.id}
          quantity={data?.quantity}
          showCustomItemCTA={
            subChoice !== SubstitutionChoice.byBuyer || state.showCustomItemCTA
          }
          isUPCMode={params?.isUPCMode ?? false}
          upcOrderItemId={upcOrderItem?.id}
        />
      </ItemReplacementContext.Provider>
    </Header>
  );
};

export default ItemReplacement;

const styles = StyleSheet.create({
  parentBox: {
    flex: 1,
    position: 'relative',
    justifyContent: 'center',
  },
  headerText: {
    textAlign: 'left',
  },
  loader: {
    alignSelf: 'center',
  },
  mainContainer: {
    paddingHorizontal: 15,
    flex: 1,
    marginTop: 10,
  },
  actionButton: {
    borderRadius: 50,
    marginTop: 10,
  },
});
