import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {RefreshControl, RefreshControlProps, StyleSheet} from 'react-native';
import {useAppDispatch, useAppSelector} from '../../config/redux/hooks';

import ActiveOrder from '../component/ActiveOrder';
import {Analytics} from '@buncha/config/analytics/analytics';
import BottomSheet from '../../components/composites/bottomSheet';
import {CustomFlatList} from '../../components/core/list';
import EmptyActiveOrder from '../component/EmptyActiveOrder';
import Header from '../../../src/components/composites/Header/Header';
import HomeHeader from '../../home/component/HomeHeader';
import If from '../../components/conditional/If';
import WhenIWorkBottomSheet from '../../home/components/whenIWorkBottomSheet';
import {useColorThemeType} from '../../appStyles/hooks/theme';
import {useGetActiveAssignedShift} from '../../home/hooks/AssinedShift';
import {useIsFocused} from '@react-navigation/native';

function Order() {
  const globalState = useAppSelector(gstate => gstate.home);
  const [showModal, setShowModal] = useState(false);
  const theme = useColorThemeType();

  const globalShiftData = globalState.shiftData;
  const todayShifts = globalShiftData.active;
  const onboardingState = useAppSelector(gState => gState.onboarding);

  const loginToken = onboardingState.userAuthData?.loginToken;
  const appDispatch = useAppDispatch();

  const [getActiveAssignedShift, loadingShift] =
    useGetActiveAssignedShift(appDispatch);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (loginToken?.length && isFocused) getActiveAssignedShift();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginToken, isFocused]);

  const getComponent = useMemo(() => {
    return <ActiveOrder />;
  }, []);

  const openWhenIWorkModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onRefreshRun = useCallback(() => {
    getActiveAssignedShift();
  }, [getActiveAssignedShift]);

  useEffect(() => {
    Analytics.event('Open Active orders');
  }, []);

  return (
    <Header hideBottomSafeArea>
      <HomeHeader
        headerTitle={'Active Orders'}
        openWIWModal={openWhenIWorkModal}
      />
      <CustomFlatList
        refreshControl={
          (
            <RefreshControl
              refreshing={loadingShift}
              onRefresh={onRefreshRun}
              tintColor={theme?.background.gray[100]}
            />
          ) as React.ReactElement<RefreshControlProps>
        }
        ListHeaderComponent={getComponent}
        data={undefined}
        renderItem={undefined}
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={
          <If condition={!todayShifts}>
            <EmptyActiveOrder />
          </If>
        }
        contentContainerStyle={styles.contentContainer}
      />
      <BottomSheet onClose={closeModal} open={showModal}>
        <WhenIWorkBottomSheet onSheetClose={closeModal} />
      </BottomSheet>
    </Header>
  );
}

export default React.memo(Order);

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
  },
});
