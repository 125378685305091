import {Keyboard, StyleSheet} from 'react-native';
import React, {useCallback} from 'react';

import {Actionsheet} from 'native-base';
import {BottomSheetProps} from './types';
import {getWidth} from '../../../utils/common';
import {useColorThemeType} from '../../../appStyles/hooks/theme';
import {useKeyboardBottomInset} from './hooks';

const BottomSheet = (props: BottomSheetProps) => {
  const {open, children, onClose, bgColor} = props;
  const bottomInset = useKeyboardBottomInset();
  const theme = useColorThemeType();

  const closeBottomSheet = useCallback(() => {
    Keyboard.dismiss();
    if (onClose) onClose();
  }, [onClose]);

  return (
    <Actionsheet
      isOpen={open}
      style={styles.bottomSheet}
      onClose={closeBottomSheet}>
      <Actionsheet.Content
        style={{backgroundColor: bgColor || theme?.background.white[100]}}
        bottom={bottomInset}>
        {children}
      </Actionsheet.Content>
    </Actionsheet>
  );
};
export default React.memo(BottomSheet);

export const styles = StyleSheet.create({
  bottomSheet: {
    maxWidth: getWidth(820),
    width: '100%',
    alignSelf: 'center',
    backgroundColor: 'transparent',
    flex: 1,
  },
});
