import * as React from 'react';

import {Path, SvgProps} from 'react-native-svg';

import Icon from './Icon';
import {IconProps} from './type';
import {memo} from 'react';

const SupportAgent: React.FC<IconProps> = (props: SvgProps) => (
  <Icon {...props}>
    <Path d="M17.5 10.183C17.5 5.608 13.95 2.5 10 2.5c-3.908 0-7.5 3.042-7.5 7.733a1.64 1.64 0 0 0-.833 1.434v1.666c0 .917.75 1.667 1.666 1.667h.834V9.917A5.83 5.83 0 0 1 10 4.083a5.829 5.829 0 0 1 5.833 5.834v5.916H9.167V17.5h6.666c.917 0 1.667-.75 1.667-1.667v-1.016a1.54 1.54 0 0 0 .833-1.367v-1.917c0-.583-.341-1.091-.833-1.35Z" />
    <Path d="M7.5 11.667A.833.833 0 1 0 7.5 10a.833.833 0 0 0 0 1.667ZM12.5 11.667a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667Z" />
    <Path d="M15 9.192A5.033 5.033 0 0 0 10.042 5C7.517 5 4.8 7.092 5.017 10.375a6.73 6.73 0 0 0 4.05-4.908A6.697 6.697 0 0 0 15 9.192Z" />
  </Icon>
);

export default memo(SupportAgent);
