import {ActivityIndicator, Linking, ScrollView} from 'react-native';
import {AddItemParams, CustomItemMode} from '../types/CustomItem';
import {Box, HBox} from '../../components/core/view';
import {H4Text, H6Text} from '../../components/core/text';
import {HomeScreens, ShopperScreens} from '../../home/navigation/types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '@buncha/config/redux/hooks';
import {useColorThemeType, useFontTheme} from '../../appStyles/hooks/theme';
import {useCustomItem, useGetBuyerDetails} from '../hooks/CustomItem';
import {
  useItemScannerReducer,
  useUPCOrder,
  useUploadUpcImage,
} from '../hooks/ItemScanner';

import AppModal from '@buncha/common/components/CustomModal/AppModal';
import {BottomButton} from '../../components/composites/Button';
import {CameraComponentState} from '@buncha/common/types/common';
import CameraModal from '@buncha/common/components/CameraModal/CameraModal';
import CustomInput from '../components/CustomItem/CustomInput';
import Header from '../../components/composites/Header/Header';
import If from '@buncha/components/conditional/If';
import ImageBottomSheet from '@buncha/common/components/BottomSheet/ImageBottomSheet';
import ImageUpload from '@buncha/components/core/icon/ImageUpload';
import {ItemDetailsScreen} from '../navigation/types';
import {ItemScannerContext} from '../context/ItemScanner';
import {ItemScannerGlobalActions} from '../reducer';
import KeyboardAvoidLayout from '../../components/composites/keyboardAvoidLayout';
import QuantitySelector from '../../components/composites/QuantitySelector';
import {SubstitutionItemParams} from '../types/itemReplacement';
import {SubstitutionMode} from '../types/itemDetails';
import {Touchable} from '@buncha/components/core/touchable';
import {UPCItemLocationStatus} from '../types/ItemScanner';
import {checkPriceDecimals} from '../../utils/common';
import {navigation} from '../../config/navigation';
import {styles} from '../styles/CustomItem';
import {useRoute} from '@react-navigation/native';

const CustomItem = () => {
  const theme = useColorThemeType('main');
  const muliTheme = useFontTheme('muli');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [notes, setNotes] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [showImageUploadModal, setShowImageUploadModal] =
    useState<boolean>(false);
  const [uploadedImage, setUploadedImage] = useState<any>([]);

  const {loading, addCustomItem, substituteCustomItem} = useCustomItem();
  const [state, dispatch] = useItemScannerReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);

  const {itemLocationData, upcOrderItem, selectedCatalogStoreForUPCRun} =
    useAppSelector(gState => gState.itemScanner);
  const appDispatch = useAppDispatch();
  const [priceCheckModal, setPriceCheckModal] = useState(false);
  const [getBuyerDetails, buyerState] = useGetBuyerDetails();

  const {substituteItemInUPC} = useUPCOrder(dispatch);
  const {uploadLocationImage, imageUploading} = useUPCOrder(dispatch);

  const {loading: loadingImage, requestPermissions} =
    useUploadUpcImage(dispatch);

  const onImageVerificationClick = useCallback(() => {
    setShowImageUploadModal(!showImageUploadModal);
  }, [showImageUploadModal]);

  const [openCameraModal, setOpenCameraModal] = useState(false);

  const toggleCameraModal = useCallback(() => {
    setOpenCameraModal(!openCameraModal);
  }, [openCameraModal]);

  const routeParams: any = useRoute().params;
  const mode = routeParams.mode || CustomItemMode.addItem;
  const orderId = Number(routeParams.orderId);
  const orderItemId = Number(routeParams.orderItemId);
  const runId = Number(routeParams.runId);
  const maxQuantity = Number(routeParams.maxQuantity);
  const returnPage = routeParams?.returnPage;
  const isUPCMode = routeParams?.isUPCMode;
  const upcOrderItemId = routeParams?.upcOrderItemId;

  const priceBoxColor = price.length
    ? theme?.text.blue[300]
    : theme?.text?.gray?.[100];

  const bottomButtonDisabled =
    !name.length || !price.length || !notes || !quantity || !Number(price);

  const addMode = mode === CustomItemMode.addItem;

  const navigateToRun = useCallback(() => {
    const commonParams = {
      screen: ShopperScreens.OrderPackingPage,
      params: {runId},
    };

    if (returnPage === ShopperScreens.OrderPackingPage || isUPCMode)
      return navigation.navigate(ShopperScreens.OrderPackingPage, commonParams);

    navigation.navigate(HomeScreens.Shopper, {
      screen: ShopperScreens.BatchDetailsPage,
      params: {runId},
    });
  }, [isUPCMode, returnPage, runId]);

  const addItem = useCallback(() => {
    if (!orderId || loading) return;
    const params: AddItemParams = {
      orderId: orderId,
      name: name,
      price: Number(Number(Number(price) * 100).toFixed(0)),
      priceWithTax: Number(Number(Number(price) * 100).toFixed(0)),
      quantity: quantity,
      isCustomItem: true,
      itemImageUrl: '',
      catalogItemId: null,
      tags: '',
      position: 0,
      options: notes,
      priceSensitive: true,
      weightSensitive: true,
    };
    addCustomItem(params, navigateToRun);
  }, [
    addCustomItem,
    loading,
    name,
    navigateToRun,
    notes,
    orderId,
    price,
    quantity,
  ]);

  const substituteItem = useCallback(() => {
    const params: SubstitutionItemParams = {
      name: name,
      orderId: orderId,
      orderItemId: orderItemId,
      position: 0,
      price: Number(Number(Number(price) * 100).toFixed(0)),
      quantity: quantity,
      isCustomItem: true,
      substitutedBy: '',
      substitutionRequestParmas: {
        substitutionMode: SubstitutionMode.approvedOverCallBeforeRequestSend,
      },
    };

    substituteCustomItem(params, navigateToRun);
  }, [
    name,
    navigateToRun,
    orderId,
    orderItemId,
    price,
    quantity,
    substituteCustomItem,
  ]);

  const handleSubmit = useCallback(async () => {
    if (isUPCMode) {
      const data: UPCItemLocationStatus = {
        ...itemLocationData,
        substituionData: {
          name: name,
          orderId: orderId,
          orderItemId: orderItemId,
          position: 0,
          price: Number(Number(Number(price) * 100).toFixed(0)),
          quantity: quantity,
          isCustomItem: true,
          substitutedBy: '',
          imageUrl: uploadedImage[0],
        },
      };
      navigation.navigate(ShopperScreens.ItemDetails, {
        screen: ItemDetailsScreen.ItemScanner,
        params: {
          orderItemId: upcOrderItem?.id,
          storeIndex: selectedCatalogStoreForUPCRun?.esIndex,
          runId: '',
          quantity: 0,
          isUpcMode: isUPCMode,
        },
      });
      await substituteItemInUPC(
        {
          upcOrderItemId: upcOrderItemId,
          name: name,
          price: Number(Number(Number(price) * 100).toFixed(0)),
          isCustomItem: true,
          itemImageUrl: uploadedImage[0],
          note: notes,
        },
        () => {
          if (appDispatch)
            appDispatch(ItemScannerGlobalActions.setItemLocationData(data));
        },
      );
    } else {
      if (bottomButtonDisabled) return;
      if (addMode) addItem();
      else substituteItem();
    }
  }, [
    addItem,
    addMode,
    appDispatch,
    bottomButtonDisabled,
    isUPCMode,
    itemLocationData,
    name,
    notes,
    orderId,
    orderItemId,
    price,
    quantity,
    selectedCatalogStoreForUPCRun?.esIndex,
    substituteItem,
    substituteItemInUPC,
    upcOrderItem?.id,
    upcOrderItemId,
    uploadedImage,
  ]);

  const imageUploadHandler = useCallback(
    async (base64: any) => {
      const res = await uploadLocationImage(base64);
      if (res) setUploadedImage([res]);
    },
    [uploadLocationImage],
  );

  const showCaptureModeFunc = useCallback(async () => {
    const permissionStatus = await requestPermissions();
    if (permissionStatus)
      try {
        toggleCameraModal();
      } catch (error) {}
    else Linking.openSettings();
  }, [requestPermissions, toggleCameraModal]);

  const handlePriceChange = useCallback((text: string) => {
    if (
      (Boolean(Number(text)) || text.length === 0 || Number(text) === 0) &&
      Number(text) <= 250 &&
      checkPriceDecimals(text)
    )
      setPrice(text);
  }, []);

  const deleteImage = useCallback(() => {
    setUploadedImage([]);
    const locationData: UPCItemLocationStatus = {
      ...itemLocationData,
      imageUrl: '',
    };
    if (appDispatch)
      appDispatch(ItemScannerGlobalActions.setItemLocationData(locationData));
  }, [appDispatch, itemLocationData]);

  const saveImage = useCallback(() => {
    const locationData: UPCItemLocationStatus = {
      ...itemLocationData,
      imageUrl: uploadedImage[0],
    };
    if (appDispatch)
      appDispatch(ItemScannerGlobalActions.setItemLocationData(locationData));
    onImageVerificationClick();
  }, [appDispatch, itemLocationData, onImageVerificationClick, uploadedImage]);

  const captureImageHandler = useCallback(
    (data: CameraComponentState) => {
      if (data) imageUploadHandler(data.base64);
    },
    [imageUploadHandler],
  );

  const checkPrice = useCallback(() => {
    if (Number(price) >= 10) {
      setPriceCheckModal(true);
      return;
    }
    handleSubmit();
  }, [handleSubmit, price]);

  const closeModal = useCallback(() => {
    setPriceCheckModal(false);
  }, []);

  const onSubmitModal = useCallback(() => {
    handleSubmit();
    closeModal();
  }, [closeModal, handleSubmit]);

  useEffect(() => {
    if (runId && orderId) getBuyerDetails(runId, orderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId, orderId]);

  return (
    <KeyboardAvoidLayout>
      <ItemScannerContext.Provider value={provider}>
        <Header
          headerColor={theme?.background?.blue?.[300]}
          showDefaultLeftHeader>
          <ScrollView>
            <Box style={styles.parentBox}>
              <H4Text
                style={[
                  styles.header,
                  {
                    color: theme?.text.blue[300],
                    fontFamily: muliTheme[700].normal,
                  },
                ]}>
                {`${addMode ? 'Add' : 'Substitute By'} Custom Item`}
              </H4Text>
              <CustomInput
                value={name}
                onTextChange={setName}
                placeHolder="Item name"
                textAlignVertical={'center'}
              />
              <CustomInput
                value={price}
                onTextChange={handlePriceChange}
                placeHolder="Item Price"
                leftComponent={
                  <H4Text
                    style={[
                      styles.inputLeftComponent,
                      {
                        color: priceBoxColor,
                      },
                    ]}>
                    $
                  </H4Text>
                }
                keyboardType="numeric"
                textAlignVertical={'center'}
              />
              <CustomInput
                value={notes}
                onTextChange={setNotes}
                placeHolder="Notes"
                multiline
                numberOfLines={7}
                extraStyles={[styles.notesInput]}
                textAlignVertical={'top'}
              />
              <If condition={isUPCMode}>
                <HBox style={[styles.imageUploadContainer]}>
                  <ImageUpload fill={theme?.text.green[100]} />
                  <Touchable onPress={onImageVerificationClick}>
                    <H6Text style={{color: theme?.text.green[100]}}>
                      Upload image
                    </H6Text>
                  </Touchable>
                </HBox>
              </If>
            </Box>
          </ScrollView>
          <HBox
            style={[
              styles.bottomContainer,
              {
                backgroundColor: theme?.background.white[100],
              },
            ]}>
            <QuantitySelector
              height={50}
              width={120}
              quantity={quantity}
              onQuantityUpdate={setQuantity}
              allowQunatityToZero={false}
              maxQuantity={maxQuantity}
              minQuantity={1}
            />
            <BottomButton
              style={styles.submitButton}
              title={addMode ? 'Add Item' : 'Substitute Item'}
              disabled={bottomButtonDisabled}
              onPress={checkPrice}
              isLoading={loading}
              changeStyleOnLoading
              loadingIndicator={<ActivityIndicator />}
              containerStyle={styles.buttonContainer}
            />
          </HBox>
        </Header>
        <ImageBottomSheet
          bottomSheetOpen={showImageUploadModal}
          title={'Add item images'}
          subtitle={'Please take clear photos of the item and upload the image'}
          loading={imageUploading || loadingImage}
          imageList={uploadedImage}
          showCaptureModeFunc={showCaptureModeFunc}
          closeBottomSheet={onImageVerificationClick}
          handleImageDelete={() => deleteImage()}
          uploadButtonHandler={() => saveImage()}
          hasShowBottomButton
        />
        <If condition={openCameraModal}>
          <CameraModal
            capturedImage={captureImageHandler}
            open={openCameraModal}
            closeModal={toggleCameraModal}
          />
        </If>
        <AppModal
          isOpen={priceCheckModal}
          onClose={closeModal}
          topCTAHandelr={onSubmitModal}
          bottomCTAHandelr={closeModal}
          title={'Confirmation!'}
          subTitle={`Are you want to add this $${price} item in ${buyerState?.data?.buyer?.firstName}'s order?`}
          ctaTopTitle={'OK'}
          ctaBottomTitle="Cancel"
          topCTAColor={theme?.background.green[100]}
        />
      </ItemScannerContext.Provider>
    </KeyboardAvoidLayout>
  );
};

export default React.memo(CustomItem);
