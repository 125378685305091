import React, {useEffect, useMemo} from 'react';

import {Box} from '@buncha/components/core/view';
import {Divider} from 'native-base';
import Header from '@buncha/components/composites/Header/Header';
import {ItemScannerGlobalActions} from '@buncha/itemDetails/reducer';
import ScreenHeader from '@buncha/common/components/screenHeader';
import {StyleSheet} from 'react-native';
import UPCShiftComponent from '../components/UPCShifts/index';
import {UPCShiftContext} from '../context/UPCShiftsCreation';
import {useAppDispatch} from '@buncha/config/redux/hooks';
import {useUPCShiftReducer} from '../hooks/UPCShiftsCreation';

const UPCShifts = () => {
  const [state, dispatch] = useUPCShiftReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);
  const appDispatch = useAppDispatch();

  useEffect(() => {
    appDispatch(
      ItemScannerGlobalActions.setSelectedCatalogStoreForUPCRun(undefined),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UPCShiftContext.Provider value={provider}>
      <Header showDefaultLeftHeader>
        <ScreenHeader title="UPC Order Creation" />
        <Divider />
        <Box style={[styles.bodyContainer]}>
          <UPCShiftComponent />
        </Box>
      </Header>
    </UPCShiftContext.Provider>
  );
};

export default React.memo(UPCShifts);

const styles = StyleSheet.create({
  bodyContainer: {
    paddingVertical: 10,
    zIndex: 10,
    flex: 1,
  },
});
