import {Linking, PermissionsAndroid, Platform} from 'react-native';
import React, {useCallback, useEffect, useState} from 'react';
import {convertBase64, getRandomNumbers, isWeb} from '@buncha/utils/common';

import BottomSheet from '../../../components/composites/bottomSheet';
import {Box} from '../../../components/core/view';
import {CameraComponentState} from '@buncha/common/types/common';
import CameraModal from '@buncha/common/components/CameraModal/CameraModal';
import If from '../../../components/conditional/If';
import ImageBottomSheet from '@buncha/common/components/BottomSheet/ImageBottomSheet';
import {ReceiptBypassMode} from '@buncha/batchDetails/types';
import UploadTutorial from '../UploadTutorial/UploadTutorial';
import {useBatchDetailContext} from '../../../batchDetails/hooks/batchDetail';
import {useDeleteReceiptUrl} from '../../../batchDetails/hooks/BatchDetails';
import {useRun} from '../../../batchDetails/hooks/Receipt';

interface BatchScreenComponentProps {
  isOpen: boolean;
  onClose: Function;
  runId: number;
  hasSkip?: ReceiptBypassMode;
  hasSkipReceiptHandler?: () => void;
}

const BatchScreenComponent = (props: BatchScreenComponentProps) => {
  const [firstRender, setFirstRender] = useState(props.isOpen);
  const [open, setOpen] = useState(props?.isOpen);
  const {state, dispatch} = useBatchDetailContext();
  const {getReceipts, uploadReceipt, uploadingReceipt} = useRun(dispatch);
  const {deleteRunReceipts, deletingImage} = useDeleteReceiptUrl();
  const [openCameraModal, setOpenCameraModal] = useState(false);

  const toggleCameraModal = useCallback(() => {
    setOpenCameraModal(!openCameraModal);
  }, [openCameraModal]);

  useEffect(() => {
    getReceipts(props.runId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
    setFirstRender(false);
    props.onClose();
  }, [props]);

  const receiptStateManipulator = useCallback(() => {
    getReceipts(props.runId);
  }, [getReceipts, props.runId]);

  const deleteUploadedImage = useCallback(
    (receipt: string) => {
      deleteRunReceipts(receipt, props.runId, receiptStateManipulator);
    },
    [deleteRunReceipts, props.runId, receiptStateManipulator],
  );

  const requestPermissions = useCallback(async () => {
    if (Platform.OS === 'android') {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.CAMERA,
        {
          title: 'Camera Permission',
          message:
            'Allow camera access to quickly upload images of receipts you need for better accuracy.',
          buttonPositive: 'Ok',
        },
      );
      return granted === PermissionsAndroid.RESULTS.GRANTED;
    }
    return true;
  }, []);

  const uploadImageHandler = useCallback(
    async (base64: string) => {
      await uploadReceipt(
        props.runId,
        {
          uri: `${getRandomNumbers().toString(36).slice(2, 7)}.jpg`,
          base64: base64,
        },
        state?.receipts,
      );
    },
    [props.runId, state?.receipts, uploadReceipt],
  );

  const uploadReceiptFormSystem = useCallback(
    async (file: any) => {
      let imgArray: any = [];
      let fileArray: any = Object.values(file.target.files);
      for (let i = 0; i < fileArray.length; i++) {
        const base64Data = await convertBase64(fileArray[i]);
        const base64Trimeed = base64Data as string;
        const returnData = {
          uri: fileArray[i].name,
          base64: base64Trimeed.split(',')[1],
        };
        imgArray[i] = returnData;
      }

      uploadImageHandler(imgArray[0]?.base64);
    },
    [uploadImageHandler],
  );

  const showCaptureModeFunc = useCallback(
    async (data?: any) => {
      if (data && isWeb()) return uploadReceiptFormSystem(data);
      const permissionStatus = await requestPermissions();
      if (permissionStatus)
        try {
          toggleCameraModal();
        } catch (error) {}
      else Linking.openSettings();
    },
    [requestPermissions, toggleCameraModal, uploadReceiptFormSystem],
  );

  const toggleFirstRender = useCallback(() => {
    setFirstRender(false);
  }, []);

  const captureImageHandler = useCallback(
    (data: CameraComponentState) => {
      if (data) uploadImageHandler(data.base64);
    },
    [uploadImageHandler],
  );

  return (
    <React.Fragment>
      <BottomSheet open={firstRender} onClose={closeModal}>
        <Box>
          <UploadTutorial
            onTakePhoto={toggleFirstRender}
            hasSkip={props?.hasSkip}
            skipReceiptHandler={props?.hasSkipReceiptHandler}
          />
        </Box>
      </BottomSheet>
      <If condition={!firstRender}>
        <ImageBottomSheet
          bottomSheetOpen={open}
          title={'Add Receipt Images'}
          subtitle={'You can upload multiple receipt images'}
          loading={uploadingReceipt || deletingImage}
          imageList={state?.receipts}
          showCaptureModeFunc={showCaptureModeFunc}
          closeBottomSheet={closeModal}
          handleImageDelete={deleteUploadedImage}
          hasDeleteModal
        />
      </If>
      <If condition={openCameraModal}>
        <CameraModal
          capturedImage={captureImageHandler}
          open={openCameraModal}
          closeModal={toggleCameraModal}
        />
      </If>
    </React.Fragment>
  );
};

export default React.memo(BatchScreenComponent);
