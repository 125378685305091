import React, {useMemo} from 'react';

import {Box} from '@buncha/components/core/view';
import {CheckBoxCountStyle} from './styles';
import {CheckIcon} from 'native-base';
import {CustomText} from '@buncha/components/core/text';
import If from '@buncha/components/conditional/If';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

interface Props {
  index: number;
  isChecked?: boolean;
}

const CheckBoxCount = (props: Props) => {
  const {index, isChecked} = props;
  const theme = useColorThemeType();

  const getColor = useMemo(() => {
    if (isChecked)
      return {
        backgroundColor: theme?.background.green[100],
        borderWidth: 0,
      };
  }, [isChecked, theme?.background.green]);

  return (
    <Box style={[CheckBoxCountStyle.container, getColor]}>
      <If condition={!isChecked}>
        <CustomText>{index + 1}</CustomText>
      </If>
      <If condition={isChecked}>
        <CheckIcon color={theme?.icon?.white[100]} viewBox="-3 -5 28 28" />
      </If>
    </Box>
  );
};

export default React.memo(CheckBoxCount);
