import React, {useCallback, useMemo, useState} from 'react';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../src/appStyles/hooks/theme';

import AddPhoto from '../../../../../src/components/core/icon/AddPhoto';
import {Analytics} from '@buncha/config/analytics/analytics';
import {CustomText} from '../../../../../src/components/core/text';
import DeliveryImagesComponent from '../deliveryImagesComponent';
import If from '../../../../../src/components/conditional/If';
import PhotoIcon from '../../../../../src/components/core/icon/PhotoIcon';
import {StyleSheet} from 'react-native';
import {Touchable} from '../../../../../src/components/core/touchable';
import {useDeliveryDetailsContext} from '../../../../../src/driver/hooks/deliveryDetails';

interface AddDeliveryPhotoCellProps {
  orderId: string;
  deliveryLocationId: number;
  runId: number;
}

const AddDeliveryPhotoCell: React.FC<AddDeliveryPhotoCellProps> = props => {
  const orderId = Number(props?.orderId) || 0;
  const {state} = useDeliveryDetailsContext();
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const deliveryLocationId = props?.deliveryLocationId || 0;
  const [open, setOpen] = useState(false);
  const deliveryImagesAdded = state?.deliveryImages?.length;
  const toggleImagesComponent = useCallback(() => {
    setOpen(!open);
    if (open)
      Analytics.eventWithProps('Add delivery photos', {
        type: 'CTA',
        userType: 'driver',
      });
  }, [open]);

  const getCellText = useMemo(() => {
    if (deliveryImagesAdded) return 'Photo(s) added';
    return 'Add photos';
  }, [deliveryImagesAdded]);
  return (
    <React.Fragment>
      <Touchable style={styles.parentBox} onPress={toggleImagesComponent}>
        <If condition={!deliveryImagesAdded}>
          <AddPhoto
            height={36}
            width={36}
            fill={theme?.icon?.blue?.[300]}
            style={styles.icon}
            viewBox="-1 2 39 30"
          />
        </If>
        <If condition={deliveryImagesAdded}>
          <PhotoIcon
            height={36}
            width={36}
            fill={theme?.icon?.blue?.[300]}
            style={styles.icon}
            viewBox="-1 2 46 30"
          />
        </If>

        <CustomText
          style={[
            styles.text,
            {color: theme?.text?.blue?.[300], fontFamily: muli?.[900]?.normal},
          ]}>
          {getCellText}
        </CustomText>
      </Touchable>
      <DeliveryImagesComponent
        isOpen={open}
        onClose={toggleImagesComponent}
        orderId={orderId}
        deliveryLocationId={deliveryLocationId}
        runId={props?.runId}
      />
    </React.Fragment>
  );
};

export default React.memo(AddDeliveryPhotoCell);

const styles = StyleSheet.create({
  parentBox: {
    borderWidth: 1,
    justifyContent: 'center',
    paddingVertical: 15,
    borderRadius: 8,
    flexGrow: 1,
  },
  text: {
    marginTop: 5,
  },
  icon: {
    alignSelf: 'center',
  },
});
