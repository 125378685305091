import React, {useCallback} from 'react';

import {Box} from '../../../../components/core/view';
import {CustomImage} from '@buncha/components/core/Image';
import If from '../../../../components/conditional/If';
import {StyleSheet} from 'react-native';
import {Touchable} from '../../../../components/core/touchable';
import TrashIcon from '../../../../components/core/icon/TrashIcon';
import {getDefaultImageUrl} from '@buncha/utils/common';

interface ImageCellProps {
  url: string;
  handleImageDelete?: Function;
}

const ImageCell = (props: ImageCellProps) => {
  const {url, handleImageDelete} = props;

  const imageDelete = useCallback(() => {
    if (handleImageDelete) handleImageDelete(url);
  }, [handleImageDelete, url]);

  return (
    <Box style={styles.container}>
      <CustomImage
        source={url}
        style={styles.image}
        onErrorSource={getDefaultImageUrl()}
      />
      <If condition={handleImageDelete}>
        <Touchable onPress={imageDelete} style={styles.binIcon}>
          <TrashIcon />
        </Touchable>
      </If>
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    marginRight: 10,
    borderRadius: 15,
  },
  image: {
    height: 120,
    width: 120,
    borderRadius: 15,
  },
  binIcon: {
    position: 'absolute',
    top: 2,
    right: 2,
    zIndex: 3,
    borderRadius: 15,
    padding: 3,
  },
});

export default React.memo(ImageCell);
