import {Box, HBox} from '../../../components/core/view';
import {CustomText, H5Text} from '../../../components/core/text';
import React, {useMemo} from 'react';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {GroupedAssignedItems} from '../../../batchDetails/types';
import If from '../../../components/conditional/If';
import ProfileIcon from '../../../components/core/icon/ProfileIcon';
import UserNameUpperPill from '@buncha/common/components/VipProfileIcon';
import {styles} from './styles';

interface CustomerOrderInfoProps {
  groupedAssignedItems: GroupedAssignedItems;
  hideFooter?: boolean;
  hasNewUser?: boolean;
  isVip?: boolean;
}

function CustomerOrderInfo(props: CustomerOrderInfoProps) {
  const {groupedAssignedItems, hideFooter} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const showUpperPill = props?.hasNewUser || props?.isVip;
  const customerTypeString = useMemo(() => {
    if (props?.isVip) return 'VIP Customer';
    if (props?.hasNewUser) return 'New on Buncha';
    return '';
  }, [props?.hasNewUser, props?.isVip]);

  return (
    <HBox style={styles.parent}>
      <ProfileIcon viewBox="-3 -1 25 25" fill={theme?.text.blue[300]} />
      <Box style={[styles.textContainer]}>
        <If condition={showUpperPill}>
          <UserNameUpperPill text={customerTypeString} />
        </If>
        <H5Text
          style={[
            styles.nameText,
            {
              fontFamily: muliFont[700].normal,
              color: theme?.text.blue[300],
            },
          ]}>
          {groupedAssignedItems?.groupedKey}
        </H5Text>
        <If condition={!hideFooter}>
          <CustomText
            style={[
              styles.nameText,
              {
                fontFamily: muliFont[600].normal,
                color: theme?.text.gray[100],
              },
            ]}>
            {groupedAssignedItems?.orderItems?.length} Item(s) picked
          </CustomText>
        </If>
      </Box>
    </HBox>
  );
}

export default React.memo(CustomerOrderInfo);
