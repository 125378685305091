import {Dispatch} from 'react';

/* eslint-disable no-shadow */

export enum Config {
  'appId' = 'APP_ID',
  'appName' = 'APP_NAME',
  'versionCode' = 'VERSION_CODE',
  'versionName' = 'VERSION_NAME',
  'dev1' = 'DEV1',
  'dev2' = 'DEV2',
  'dev3' = 'DEV3',
  'qa' = 'QA',
  'qanew' = 'QANEW',
  'stage' = 'STAGE',
  'preprod' = 'PREPROD',
  'prod' = 'PROD',
  'buildType' = 'BUILD_TYPE',
  'devTool' = 'DEV_TOOL',
  'firebaseApiKey' = 'FIREBASE_API_KEY',
  'firebaseVapidKey' = 'FIREBASE_VAPID_KEY',
  'firebaseAuthDomain' = 'FIREBASE_AUTH_DOMAIN',
  'firebaseDatabaseUrl' = 'FIREBASE_DATABASE_URL',
  'firebaseProjectId' = 'FIREBASE_PROJECT_ID',
  'firebaseStorageBucket' = 'FIREBASE_STORAGE_BUCKET',
  'firebaseMessagingSenderId' = 'FIREBASE_MESSAGING_SENDER_ID',
  'firebaseAppId' = 'FIREBASE_APP_ID',
  'firebaseMeasurementId' = 'FIREBASE_MEASUREMENT_ID',
  'mixpanelKey' = 'MIXPANEL_KEY',
  'mixpanelProdKey' = 'MIXPANEL_PROD_KEY',
  'deployType' = 'DEPLOY_TYPE',
  'smartlookKey' = 'SMARTLOOK_KEY',
  'appcenterSecretkeyIos' = 'APPCENTER_SECRET_KEY_IOS',
  'appcenterSecretkeyAndroid' = 'APPCENTER_SECRET_KEY_ANDROID',
  'codepushIosKey' = 'CODEPUSH_IOS_KEY',
  'codepushIosStageTestKey' = 'CODEPUSH_IOS_STAGE_TEST_KEY',
  'codepushIosDevTestKey' = 'CODEPUSH_IOS_DEV_TEST_KEY',
  'codepushAndroidKey' = 'CODEPUSH_ANDROID_KEY',
  'codepushAndroidStageTestKey' = 'CODEPUSH_ANDROID_STAGE_TEST_KEY',
  'codepushAndroidDevTestKey' = 'CODEPUSH_ANDROID_DEV_TEST_KEY',
  'intercomId' = 'INTERCOM_ID',
  'intercomAndroid' = 'INTERCOM_ANDROID',
  'intercomIos' = 'INTERCOM_IOS',
  'intercomProdId' = 'INTERCOM_PROD_ID',
  'intercomProdAndroid' = 'INTERCOM_PROD_ANDROID',
  'intercomProdIos' = 'INTERCOM_PROD_IOS',
  'webENV' = 'WEB_ENV',
  'chatKey' = 'CHAT_KEY',
  'chatTestKey' = 'CHAT_TEST_KEY',
  'hiringFormDev' = 'HIRING_FORM_DEV',
  'hiringFormStage' = 'HIRING_FORM_STAGE',
  'hiringFormQA' = 'HIRING_FORM_QA',
  'hiringFormProd' = 'HIRING_FORM_PROD',
  'awsIncognitoIdentityPoolId' = 'AWS_INCOGNITO_IDENTITY_POOL_ID',
  'googleMapApiKeyIos' = 'GOOGLE_MAP_API_KEY_IOS',
  'googleMapApiKeyAndroid' = 'GOOGLE_MAP_API_KEY_ANDROID',
  'relicLicenseKey' = 'NEW_RELIC_LICENSE_KEY',
  'awsLambdaMaintenanceUrl' = 'AWS_LAMBDA_MAINTENANCE_URL',
}

export interface EnvConfig {
  [configKey: string]: string | undefined;
}

export enum BrowseNames {
  chrome = 'chrome',
  edge = 'edge',
  safari = 'safari',
  firefox = 'firefox',
  ie = 'ie',
  opera = 'opera',
  other = 'other',
}

export interface DefaultContext<T> {
  state: T;
  dispatch: null | Dispatch<any>;
}
export enum CONSTANTS {
  maxWidth = 820,
}

export enum Source {
  admin = 'admin',
  merchant = 'merchant',
  app = 'app',
  dispatcher = 'dispatcher',
  fulfillment = 'fulfillment',
}
