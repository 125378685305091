import {Dispatch, useState} from 'react';
import {
  REGISTER_DEVICE_FOR_NOTIFICATIONS,
  REGISTER_SHOPPER,
  SEND_SMS_CODE,
  VERIFY_SMS_CODE,
} from '../../config/graphQL/documentNode/mutation';
import {getUUid, useDetectBrowser} from '../../utils/common';

import {Analytics} from '../../config/analytics/analytics';
import {BrowseNames} from '../../utils/types';
import {CURRENT_USER} from '../../config/graphQL/documentNode/queries';
import {EventType} from '../../config/analytics/type';
import {RegisterShopperParams} from '../types';
import {ToastType} from '../../components/composites/notification/type';
import {gqlService} from '../../config/graphQL';
import {onBoardingActions} from '../reducer';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {useToastMessage} from '../../components/composites/notification';

export function useUserManagement(dispatch: Dispatch<any | null>) {
  const [registeringShopper, setRegisteringShopper] = useState(false);
  const [loading, setLoading] = useState(false);
  const cpVersion = useAppSelector(gState => gState.home.codepushLatestVersion);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const getCurrentUser = async () => {
    setLoading(true);
    try {
      const response = await gqlService?.query({
        query: CURRENT_USER,
        fetchPolicy: 'network-only',
      });
      if (response?.data) {
        gqlService?.setCurrentUser(response?.data?.currentUser?.id);
        dispatch(onBoardingActions.setCurrentUser(response?.data?.currentUser));
        Analytics.setIdentifiedUserAttribute(
          response.data?.currentUser,
          cpVersion,
        );
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  const registerShopper = async (params: RegisterShopperParams) => {
    try {
      setRegisteringShopper(true);
      const response = await gqlService?.mutation({
        mutation: REGISTER_SHOPPER,
        fetchPolicy: 'network-only',
        variables: params,
      });
      if (response?.data) {
        gqlService?.setLoginCredential(response?.data?.createUser?.loginToken);
        Analytics.setIdentifiedUserAttribute(
          response.data?.createUser?.user,
          cpVersion,
        );
        dispatch(
          onBoardingActions.setLoginToken(
            response?.data?.createUser?.loginToken,
          ),
        );
        dispatch(
          onBoardingActions.setCurrentUser(response?.data?.createUser?.user),
        );
      }
    } catch (err: any) {
    } finally {
      setRegisteringShopper(false);
    }
  };

  return {getCurrentUser, registerShopper, registeringShopper, loading};
}

export function useAuthentication(dispatch: Dispatch<any | null>) {
  const cpVersion = useAppSelector(gState => gState.home.codepushLatestVersion);
  const [sendingSMSCode, setSendingSMSCode] = useState(false);
  const [sendingSMSCodeError, setSendingSMSCodeError] = useState('');
  const [verifyingOTP, setVerifyingOTP] = useState(false);
  const [verifyingOTPError, setVerifyingOTPError] = useState('');
  const [showAlertToast] = useToastMessage(ToastType.Alert);
  const sendSMSCode = async (phoneNumber: string) => {
    try {
      setVerifyingOTPError('');
      setSendingSMSCode(true);
      const response = await gqlService?.mutation({
        mutation: SEND_SMS_CODE,
        variables: {
          phoneNumber: phoneNumber,
        },
        fetchPolicy: 'network-only',
      });

      Analytics.eventWithProps('Send SMS Code Response', {
        type: EventType.api,
        phoneNumber: phoneNumber,
      });

      if (response?.data && dispatch) {
        dispatch(onBoardingActions.setOtpSentStatus(true));
        dispatch(
          onBoardingActions.setSmsCode(response.data?.sendSMSCode?.code),
        );
      }
      dispatch(onBoardingActions.setPhoneNumber(phoneNumber));
    } catch (error: any) {
      setSendingSMSCodeError(error.message);
    } finally {
      setSendingSMSCode(false);
    }
  };

  const verifySMSCode = async (phoneNumber: string, code: string) => {
    try {
      setVerifyingOTP(true);
      const response = await gqlService?.mutation({
        mutation: VERIFY_SMS_CODE,
        variables: {
          phoneNumber: phoneNumber,
          code: code,
        },
        fetchPolicy: 'network-only',
      });
      if (response?.data) {
        Analytics.eventWithProps('Verify SMS Code Response', {
          type: EventType.api,
          phoneNumber: phoneNumber,
        });
        dispatch(onBoardingActions.setOtpSentStatus(false));
        if (response?.data?.verifySMSCode?.loginToken) {
          dispatch(
            onBoardingActions.setLoginToken(
              response?.data?.verifySMSCode.loginToken,
            ),
          );
          gqlService?.setLoginCredential(
            response?.data?.verifySMSCode?.loginToken,
          );
          const user = await gqlService?.query({
            query: CURRENT_USER,
            fetchPolicy: 'network-only',
          });
          if (user?.data) {
            gqlService?.setCurrentUser(user?.data?.currentUser?.id);
            dispatch(onBoardingActions.setCurrentUser(user?.data?.currentUser));
            Analytics.setIdentifiedUserAttribute(
              user.data?.currentUser,
              cpVersion,
            );
          }
          return false;
        }
        setVerifyingOTP(false);
        if (response?.data?.verifySMSCode?.createUserToken) {
          dispatch(
            onBoardingActions.setCreateLoginToken(
              response?.data?.verifySMSCode?.createUserToken,
            ),
          );
          showAlertToast(
            'You are not authorized to use Buncha Shopper App. Please connect with the support team at support@gobuncha.com',
          );
        }

        return true;
      }
    } catch (error: any) {
      setVerifyingOTPError(error.message);
    } finally {
      setVerifyingOTP(false);
    }
  };

  const clearErrorMessage = () => {
    setSendingSMSCodeError('');
    setVerifyingOTPError('');
  };

  return {
    sendSMSCode,
    sendingSMSCode,
    sendingSMSCodeError,
    clearErrorMessage,
    verifySMSCode,
    verifyingOTP,
    verifyingOTPError,
  };
}

export function useRegisterDeviceForNotifications() {
  const {detectBrowser} = useDetectBrowser();
  const registerDeviceForNotifications = async (pushToken: string) => {
    const browserName = detectBrowser();
    if (browserName === BrowseNames.safari) return;
    if (!pushToken?.length) return;
    const uuid = await getUUid();
    if (!uuid?.length) return;
    const platform = 'web';
    try {
      await gqlService?.mutation<{id: number}>({
        mutation: REGISTER_DEVICE_FOR_NOTIFICATIONS,
        variables: {uuid, pushToken, platform},
        fetchPolicy: 'network-only',
      });
    } catch (err: any) {}
  };

  return {registerDeviceForNotifications};
}
