import {Dimensions, StyleSheet} from 'react-native';
import {getWidth, isWeb} from '@buncha/utils/common';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {Box} from '@buncha/components/core/view';
import {H6Text} from '@buncha/components/core/text';
import {PTextInputProps} from '@buncha/components/core/input/TextInput/type';
import React from 'react';
import {TextInputGroup} from '@buncha/components/core/input';
import {scaleFonts} from '@buncha/appStyles/style/font';

interface BottomSheetInputProps extends PTextInputProps {
  header?: string;
}

const BottomSheetInput: React.FC<BottomSheetInputProps> = React.forwardRef(
  (props, ref) => {
    const theme = useColorThemeType();
    const fontTheme = useFontTheme();
    return (
      <Box>
        <H6Text
          style={[
            styles.headerStyle,
            {
              color: theme?.text?.blue[300],
            },
          ]}>
          {props?.header}
        </H6Text>
        <TextInputGroup.PTextInput
          colorThemeType="default"
          clearButtonMode="while-editing"
          ref={ref}
          {...props}
          containerStyle={[
            styles.textInputContainer,
            {
              backgroundColor: theme?.background.white[100],
              borderColor: theme?.text.gray[300],
            },
            props.containerStyle,
          ]}
          style={[
            {
              backgroundColor: theme?.background.white[100],
              fontFamily: fontTheme[700].normal,
              color: theme?.text.gray[100],
              fontSize: scaleFonts(18),
            },
            props.style,
          ]}
          autoFocus={false}
        />
      </Box>
    );
  },
);

export default BottomSheetInput;

const styles = StyleSheet.create({
  textInputContainer: {
    borderRadius: 100,
    padding: 1,
    marginBottom: 10,
    borderWidth: 1,
    width: isWeb()
      ? Number(getWidth(Dimensions.get('screen').width)) * 0.85
      : Number(getWidth(Dimensions.get('screen').width)) * 0.95,
    marginLeft: 0,
    outlineStyle: 'none',
    height: 50,
    // marginTop: 15,
  },
  headerStyle: {
    textAlign: 'left',
    marginLeft: 15,
    marginBottom: 15,
  },
});
