import {
  GetOrdersForDeliveryResponse,
  NetworkConfiguration,
} from '../../../src/config/graphQL/type';
import {
  OrdersForDelivery,
  SubmitDeliveryBagsCountResponse,
} from '../types/driverOrders';
import {useCallback, useState} from 'react';

import {Analytics} from '@buncha/config/analytics/analytics';
import {ApiState} from '../../common/types/common';
import {BagsHistory} from '../../AboutShift/types';
import {DeliveryLocationStatus} from '../types/common';
import {EventType} from '@buncha/config/analytics/type';
import {GET_ORDERS_FOR_DELIVERY} from '../../../src/config/graphQL/documentNode/queries';
import {SUBMIT_DELIVERY_BAGS_COUNT} from '../../config/graphQL/documentNode/mutation';
import {ToastType} from '../../components/composites/notification/type';
import {getDeployType} from '../../../src/utils/appInfo';
import {gqlService} from '../../../src/config/graphQL';
import {useToastMessage} from '../../../src/components/composites/notification';

export function useGetOrdersForDelivery() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loading, setLoading] = useState(true);
  const [ordersData, setOrdersData] = useState<OrdersForDelivery[]>([]);
  const getOrdersForDelivery = async (
    lat?: number,
    lon?: number,
    runId?: number,
    deliveryStatuses?: DeliveryLocationStatus[],
  ) => {
    const deployType = getDeployType();
    const latitude =
      deployType !== NetworkConfiguration.production ? 37.42 : lat;
    const longitude =
      deployType !== NetworkConfiguration.production ? -122.08 : lon;
    try {
      setLoading(true);
      const res = await gqlService?.query<GetOrdersForDeliveryResponse>({
        query: GET_ORDERS_FOR_DELIVERY,
        fetchPolicy: 'network-only',
        variables: {
          lat: latitude,
          lon: longitude,
          runId: runId,
          deliveryStatuses,
        },
      });
      const orders = res?.data?.getOrdersForDelivery || [];
      setOrdersData(orders);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {getOrdersForDelivery, ordersData, loading};
}

export function useSubmitDeliveryBagsCount(): [
  (
    usedBagCount: number,
    returnedBagCount: number,
    runId: number,
    orderId: number,
  ) => void,
  ApiState<BagsHistory[]>,
] {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [state, setState] = useState<ApiState<BagsHistory[]>>({
    loading: false,
    error: undefined,
    data: undefined,
  });

  const func = useCallback(
    async (
      usedBagCount: number,
      returnedBagCount: number,
      runId: number,
      orderId: number,
    ) => {
      if (!runId || !usedBagCount || !orderId) return;
      setState({...state, loading: true});
      try {
        const response =
          await gqlService?.mutation<SubmitDeliveryBagsCountResponse>({
            mutation: SUBMIT_DELIVERY_BAGS_COUNT,
            variables: {
              runBagCount: {runId: runId, bagCount: usedBagCount},
              returnBagForOrder: {
                orderId: orderId,
                returnedBagCount: returnedBagCount,
              },
            },
            fetchPolicy: 'network-only',
          });
        if (response?.data?.submitDeliveryBagsCount) {
          Analytics.eventWithProps('Bag Tracking submit bag used count', {
            type: EventType.cta,
            usedBagCount: usedBagCount,
            returnedBagCount: returnedBagCount,
            orderId: orderId,
            runId: runId,
          });
          setState({
            ...state,
            data: response?.data?.submitDeliveryBagsCount,
            loading: false,
          });
        }
      } catch (error: any) {
        setState({...state, loading: false});
        showErrorToast(error?.message);
      }
    },
    [showErrorToast, state],
  );

  return [func, state];
}
