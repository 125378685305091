import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const Boxes: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 11.0202L10.0343 12.3323C10.1343 12.384 10.2451 12.4114 10.3577 12.4121C10.4703 12.4129 10.5815 12.387 10.6821 12.3366L12.8193 11.268C12.9379 11.2087 13.0377 11.1176 13.1075 11.0048C13.1772 10.892 13.2142 10.7621 13.2143 10.6294V8.65516C13.2142 8.52254 13.1772 8.39256 13.1075 8.27978C13.0377 8.167 12.9379 8.07586 12.8193 8.01658L10.3571 6.78516L7.88571 8.06516C7.76947 8.12538 7.67201 8.21639 7.60398 8.32826C7.53596 8.44012 7.49999 8.56852 7.5 8.69944V11.0202Z"
        stroke="#FF904F"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.17136 11.2196L4.31993 12.3325C4.41994 12.3842 4.53076 12.4115 4.64334 12.4123C4.75593 12.413 4.8671 12.3871 4.96779 12.3367L7.49993 11.071V8.65532C7.49986 8.5227 7.46287 8.39273 7.39311 8.27994C7.32334 8.16716 7.22356 8.07603 7.10493 8.01675L4.64279 6.78532L2.17136 8.06532C2.05511 8.12554 1.95765 8.21656 1.88963 8.32842C1.82161 8.44028 1.78563 8.56869 1.78564 8.69961V10.5853C1.78563 10.7162 1.82161 10.8446 1.88963 10.9565C1.95765 11.0684 2.05511 11.1594 2.17136 11.2196ZM4.64279 6.73461L7.17707 8.04675C7.27708 8.09847 7.3879 8.12582 7.50049 8.12657C7.61308 8.12731 7.72425 8.10143 7.82493 8.05103L10.3571 6.78532V4.36961C10.357 4.23699 10.32 4.10701 10.2502 3.99423C10.1805 3.88145 10.0807 3.79031 9.96207 3.73103L7.82493 2.66246C7.72425 2.61207 7.61308 2.58618 7.50049 2.58693C7.3879 2.58767 7.27708 2.61502 7.17707 2.66675L5.0285 3.77961C4.91225 3.83983 4.8148 3.93084 4.74677 4.0427C4.67875 4.15457 4.64278 4.28297 4.64279 4.41389V6.73461Z"
        stroke="#FF904F"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M4.88986 4.05174L7.18057 5.19674C7.27972 5.24628 7.38903 5.27207 7.49986 5.27207C7.61069 5.27207 7.72 5.24628 7.81914 5.19674L10.0856 4.06388M6.28486 3.32031L8.92843 4.64246M9.142 7.60603L11.7856 8.92817M3.42771 7.60603L6.07129 8.92817M7.747 8.33746L10.0377 9.48246C10.1369 9.532 10.2462 9.55779 10.357 9.55779C10.4678 9.55779 10.5771 9.532 10.6763 9.48246L12.942 8.3496M2.03271 8.33746L4.32343 9.48246C4.42257 9.532 4.53188 9.55779 4.64271 9.55779C4.75355 9.55779 4.86286 9.532 4.962 9.48246L7.22772 8.3496M7.49986 5.35674V8.21388M10.357 9.64246V12.1425M4.64271 9.64246V12.1425"
        stroke="#FF904F"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Icon>
  );
};

export default Boxes;
