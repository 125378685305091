import {Box, HBox} from '../../../../components/core/view';
import React, {useMemo} from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';

import {ChevronRightIcon} from 'native-base';
import {H3Text} from '../../../../components/core/text';
import If from '@buncha/components/conditional/If';
import ReceiptIcon from '../../../../components/core/icon/ReceiptIcon';
import ReplaceFilled from '@buncha/components/core/icon/ReplaceFilled';
import {SubstitutionChoice} from '@buncha/itemDetails/types/itemDetails';
import {Touchable} from '../../../../../src/components/core/touchable';
import font from '../../../../appStyles/style/font';
import {getWidth} from '../../../../utils/common';
import {useColorThemeType} from '../../../../appStyles/hooks/theme';

interface SubstitutionInfoProps {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  hasSubstitute?: boolean;
  substitutionChoice?: SubstitutionChoice;
}

const SubstitutionInfo = (props: SubstitutionInfoProps) => {
  const {hasSubstitute, substitutionChoice} = props;
  const theme = useColorThemeType('main');

  const getColor = useMemo(() => {
    const isSubstituteColor =
      hasSubstitute ||
      substitutionChoice === SubstitutionChoice.byShopper ||
      substitutionChoice === SubstitutionChoice.none;
    if (isSubstituteColor) return theme?.text.blue[100];
    return theme?.text.gray[100];
  }, [hasSubstitute, theme?.text.blue, theme?.text.gray]);

  const getIcon = useMemo(() => {
    if (hasSubstitute)
      return (
        <ReplaceFilled viewBox="3 -1 25 25" fill={theme?.text.blue[100]} />
      );
    return <ReceiptIcon viewBox="-3 -6 25 25" fill={getColor} />;
  }, [hasSubstitute, theme?.text.blue, theme?.text.gray]);

  return (
    <React.Fragment>
      <Touchable
        disabled={props?.disabled}
        style={[styles.Container, {opacity: props.disabled ? 0.5 : 1}]}
        onPress={props?.onClick}>
        <HBox style={[styles.rowFlex]}>
          <Box style={[styles.jcc]}>{getIcon}</Box>
          <Box style={[styles.jcc]}>
            <H3Text style={[styles.nameTypo, {color: getColor}]}>
              {props.title}
            </H3Text>
          </Box>
        </HBox>
        <If condition={!props?.disabled}>
          <Box style={[styles.jcc]}>
            <TouchableOpacity>
              <ChevronRightIcon color={getColor} />
            </TouchableOpacity>
          </Box>
        </If>
      </Touchable>
    </React.Fragment>
  );
};

export default SubstitutionInfo;

const styles = StyleSheet.create({
  Container: {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-between',
    padding: 2,
    paddingRight: 10,
  },
  rowFlex: {
    flexDirection: 'row',
  },
  nameTypo: {
    ...font.FontSize.fontH6,
    textAlign: 'left',
    textDecorationLine: 'underline',
  },
  childTypo: {
    ...font.FontSize.fontP10,
  },
  jcc: {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 2,
  },
  buttonStyle: {
    height: 37,
    width: getWidth(87),
  },
});
