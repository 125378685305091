import {Dimensions, StyleSheet} from 'react-native';

import {getWidth} from '@buncha/utils/common';

export const styles = StyleSheet.create({
  marRig10: {marginRight: 10},
  itemContent: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    zIndex: 2,
  },
  detailsContainer: {
    width: getWidth(Dimensions.get('screen').width),
    marginBottom: 10,
    paddingHorizontal: 20,
  },
  itemImage: {
    width: '100%',
    height: Dimensions.get('screen').height * 0.8,
    borderRadius: 20,
    resizeMode: 'contain',
  },
  itemContainerBox: {
    width: Dimensions.get('screen').width,
    alignItems: 'center',
  },
  marRight10: {
    marginLeft: 10,
    marginBottom: 5,
  },
  remainingQuantity: {
    paddingLeft: 5,
    paddingBottom: 1,
  },
});
