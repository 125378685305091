import {H3Text, H5Text} from '../../../components/core/text';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {Box} from '../../../components/core/view';
import {CustomDialogBox} from '../../../components/composites/dialogBox';
import {PButton} from '../../../components/core/Button';
import React from 'react';
import SyncIcon from '../../../components/core/icon/SyncIcon';
import font from '../../../appStyles/style/font';
import {styles} from './style';

interface DataSyncModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function DataSyncModal(props: DataSyncModalProps) {
  const theme = useColorThemeType();
  const mortiseFont = useFontTheme('mortise');
  const muliFont = useFontTheme('muli');

  const {isOpen, onClose} = props;

  return (
    <React.Fragment>
      <CustomDialogBox
        colorThemeType="default"
        visible={isOpen}
        modalBoxStyle={styles.modalBox}
        header={
          <H3Text
            style={[
              styles.headerText,
              {
                fontFamily: mortiseFont[700].normal,
                color: theme?.text.blue[300],
              },
            ]}>
            Data not synced yet
          </H3Text>
        }
        body={
          <H5Text>
            To start shopping for the next available store/batch, sync your data
            now.
          </H5Text>
        }
        footer={
          <Box style={styles.buttonContainer}>
            <PButton
              type="outline"
              borderWidth={1}
              titleColor={theme?.text.white[100]}
              borderColor={theme?.background.green[100]}
              bgColor={theme?.background.green[100]}
              style={styles.CTATop}
              titleStyle={{
                ...font.FontSize.fontH5,
                fontFamily: muliFont[900].normal,
              }}
              title="Sync Data"
              onPress={onClose}
              loadingText="Sync Data"
              changeStyleOnLoading
              leftComponent={
                <SyncIcon
                  height={16}
                  width={16}
                  viewBox="0 0 17 17"
                  fill={theme?.icon.white[100]}
                  style={styles.CTAIconTop}
                />
              }
              //   isLoading={loading}
            />

            <PButton
              type="outline"
              borderWidth={1}
              titleColor={theme?.text.gray[100]}
              borderColor={theme?.background.gray[100]}
              style={styles.CTABottom}
              title="Cancel"
              titleStyle={{
                ...font.FontSize.fontH6,
                fontFamily: muliFont[900].normal,
              }}
              onPress={onClose}
              //   isLoading={loading}
              loadingText="Cancel"
              changeStyleOnLoading
            />
          </Box>
        }
      />
    </React.Fragment>
  );
}
