import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const HorizontalBars: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        data-name="Icon horizontal-bars"
        d="M1 12h4c.55 0 1-.45 1-1s-.45-1-1-1H1c-.55 0-1 .45-1 1s.45 1 1 1ZM0 1c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H1C.45 0 0 .45 0 1Zm1 6h10c.55 0 1-.45 1-1s-.45-1-1-1H1c-.55 0-1 .45-1 1s.45 1 1 1Z"
        transform="translate(-3.375 -3.375)"
      />
    </Icon>
  );
};

export default HorizontalBars;
