import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const DoubleTick: React.FC<IconProps> = props => {
  return (
    <Icon viewBox="0 0 22 13" fill="none" width="22" height="13" {...props}>
      <Path
        d="M1.625 7.625L5.375 11.375M10.625 5.375L14.375 1.625M7.625 7.625L11.375 11.375L20.375 1.625"
        stroke="#737d94"
        stroke-width="2.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Icon>
  );
};

export default DoubleTick;
