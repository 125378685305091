import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {CustomText} from '@buncha/components/core/text';
import React from 'react';
import {StyleSheet} from 'react-native';
import {scaleFonts} from '@buncha/appStyles/style/font';

const LegalInfo = () => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  return (
    <CustomText
      style={[
        styles.text,
        {
          color: theme?.text?.gray?.[100],
          fontFamily: muli?.[600]?.normal,
        },
      ]}>
      Buncha is an Equal Opportunity Employer that is committed to diversity and
      inclusion. We do not discriminate on the basis of race, religion, color,
      national origin, gender, sexual orientation, gender identity or
      expression, age, marital status, veteran status, disability status,
      pregnancy, parental status, genetic information, political affiliation, or
      any other status protected by the laws or regulations in the locations
      where we operate. Accommodations are available for applicants with
      disabilities.
    </CustomText>
  );
};

export default React.memo(LegalInfo);

const styles = StyleSheet.create({
  text: {
    fontSize: scaleFonts(6),
    width: '90%',
    marginVertical: 5,
    alignSelf: 'center',
  },
});
