import {Box, HBox} from '../../../components/core/view';
import React, {useCallback, useEffect} from 'react';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import BottomSheet from '../../../components/composites/bottomSheet';
import {CustomFlatList} from '../../../components/core/list';
import {Divider} from 'native-base';
import {H3Text} from '../../../components/core/text';
import If from '../../../components/conditional/If';
import {OrderItems} from '../../../batchDetails/types';
import {PButton} from '../../../components/core/Button';
import RecheckSheetItem from '../RecheckSheetItem';
import {SpinnerComponent} from '../../../components/core/loader';
import font from '../../../appStyles/style/font';
import {styles} from './styles';
import {useItemCheckBeforePacking} from '../../../batchDetails/hooks/OrderOperation';
import {useRoute} from '@react-navigation/native';

interface RecheckBottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function RecheckBottomSheet(props: RecheckBottomSheetProps) {
  const {isOpen, onClose, onSubmit} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const mortiseFont = useFontTheme('mortise');
  const [itemCheckBeforePacking, checkState] = useItemCheckBeforePacking();
  const route = useRoute();

  const routeParams: any = route?.params;
  const runId = routeParams?.runId;
  const noRecheckData =
    !checkState.data?.recentOrderItem?.length &&
    !checkState.data?.substituteDroppedByShopper?.length &&
    !checkState.data?.substitutePickedByShopper?.length;

  const renderOrderItem = useCallback(
    ({item, title}: {item: OrderItems; title: string}) => {
      return <RecheckSheetItem orderItem={item} title={title} />;
    },
    [],
  );

  const Separator = useCallback(() => <Divider style={styles.divider} />, []);

  useEffect(() => {
    if (isOpen && runId) itemCheckBeforePacking(runId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, runId]);

  return (
    <BottomSheet
      open={isOpen}
      onClose={onClose}
      bgColor={theme?.background.white[100]}>
      <If condition={checkState.loading}>
        <Box style={styles.loadingContainer}>
          <SpinnerComponent style={styles.loader} />
        </Box>
      </If>
      <If condition={!checkState.loading}>
        <Box style={[styles.mainContainer]}>
          <H3Text
            style={[
              styles.headerText,
              {
                color: theme?.text.blue[300],
                fontFamily: mortiseFont[700].normal,
              },
            ]}>
            {noRecheckData ? 'No ' : ''} Recent Changes
          </H3Text>
          <CustomFlatList
            showsVerticalScrollIndicator={false}
            style={styles.scrollView}
            ListHeaderComponent={
              <Box>
                <If condition={checkState.data?.recentOrderItem?.length}>
                  <CustomFlatList
                    data={checkState.data?.recentOrderItem}
                    showsVerticalScrollIndicator={false}
                    renderItem={({item}) =>
                      renderOrderItem({item, title: 'Recently added new items'})
                    }
                    ItemSeparatorComponent={Separator}
                    ListFooterComponent={Separator}
                  />
                </If>
                <If
                  condition={
                    checkState.data?.substituteDroppedByShopper?.length
                  }>
                  <CustomFlatList
                    showsVerticalScrollIndicator={false}
                    data={checkState.data?.substituteDroppedByShopper}
                    renderItem={({item}) =>
                      renderOrderItem({
                        item,
                        title:
                          'You have dropped the item which was approved by the user',
                      })
                    }
                    ItemSeparatorComponent={Separator}
                    ListFooterComponent={Separator}
                  />
                </If>
                <If
                  condition={
                    checkState.data?.substitutePickedByShopper?.length
                  }>
                  <CustomFlatList
                    showsVerticalScrollIndicator={false}
                    data={checkState.data?.substitutePickedByShopper}
                    renderItem={({item}) =>
                      renderOrderItem({
                        item,
                        title:
                          'You have replaced below items as per user’s need',
                      })
                    }
                    ItemSeparatorComponent={Separator}
                  />
                </If>
              </Box>
            }
            data={undefined}
            renderItem={undefined}
          />
          <HBox style={styles.buttonContainer}>
            <PButton
              title={'Proceed'}
              style={[styles.buttonStyleLeft]}
              bgColor={theme?.background.white[100]}
              titleStyle={{
                ...font.FontSize.fontH5,
                fontFamily: muliFont[700].normal,
              }}
              titleColor={theme?.text.blue[100]}
              onPress={onSubmit}
              borderColor={theme?.background.blue[100]}
              borderWidth={1}
            />
            <PButton
              title={'Recheck'}
              style={styles.buttonStyleRight}
              bgColor={theme?.background.blue[100]}
              titleStyle={{
                ...font.FontSize.fontH5,
                fontFamily: muliFont[700].normal,
              }}
              onPress={onClose}
            />
          </HBox>
        </Box>
      </If>
    </BottomSheet>
  );
}

export default React.memo(RecheckBottomSheet);
