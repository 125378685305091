import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const DeliveryVanIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path d="M.125 5.25H14.75l1.125 2.25H1.25L.125 5.25Zm1.5 4.5H16.25L17.375 12H2.75L1.625 9.75ZM26 21.75a2.247 2.247 0 0 0 2.25-2.25A2.247 2.247 0 0 0 26 17.25a2.247 2.247 0 0 0-2.25 2.25A2.247 2.247 0 0 0 26 21.75Zm2.25-13.5H24.5V12h6.69l-2.94-3.75ZM11 21.75a2.247 2.247 0 0 0 2.25-2.25A2.247 2.247 0 0 0 11 17.25a2.247 2.247 0 0 0-2.25 2.25A2.247 2.247 0 0 0 11 21.75ZM29 6l4.5 6v7.5h-3c0 2.49-2.01 4.5-4.5 4.5s-4.5-2.01-4.5-4.5h-6c0 2.49-2.025 4.5-4.5 4.5-2.49 0-4.5-2.01-4.5-4.5h-3v-5.25h3v2.25h1.14A4.52 4.52 0 0 1 11 15a4.52 4.52 0 0 1 3.36 1.5h7.14V3h-18c0-1.665 1.335-3 3-3h18v6H29Z" />
    </Icon>
  );
};

export default DeliveryVanIcon;
