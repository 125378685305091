import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {scaleFonts} from '@buncha/appStyles/style/font';
import If from '@buncha/components/conditional/If';
import MemosIcon from '@buncha/components/core/icon/MemosIcon';
import {PSmallText} from '@buncha/components/core/text';
import {Box} from '@buncha/components/core/view';
import React from 'react';
import {StyleSheet} from 'react-native';

interface MemoIconProps {
  fill?: string;
  count: number;
}

const MemoIcon = (props: MemoIconProps) => {
  const {count, fill} = props;
  const theme = useColorThemeType();

  return (
    <Box style={styles.container}>
      <If condition={count}>
        <Box
          style={[
            styles.iconContainer,
            {backgroundColor: theme?.background.red[100]},
          ]}>
          <PSmallText style={[{color: theme?.text.white[100]}]}>
            {count}
          </PSmallText>
        </Box>
      </If>
      <MemosIcon fill={fill} width="27" height="27" viewBox="0 0 27 27" />
    </Box>
  );
};

export default React.memo(MemoIcon);

const styles = StyleSheet.create({
  iconContainer: {
    position: 'absolute',
    right: 0,
    top: -6,
    borderRadius: 10,
    height: scaleFonts(15),
    width: scaleFonts(15),
    zIndex: 10,
  },
  container: {position: 'relative'},
});
