import React, {useCallback} from 'react';

import {ChatModes} from '@buncha/batchDetails/types/Chat';
import ChatOpener from '@buncha/components/composites/ChatOpener';
import {HBox} from '../../../components/core/view';
import PdfIcon from '../../../components/core/icon/PdfIcon';
import ReceiptIcon from '../../../components/core/icon/ReceiptIcon';
import {ShopperScreens} from '../../../home/navigation/types';
import {Touchable} from '../../../components/core/touchable';
import {navigation} from '../../../config/navigation';
import {styles} from './styles';
import {useColorThemeType} from '../../../appStyles/hooks/theme';
import {useIsFocused} from '@react-navigation/native';

interface HeaderRightSectionProps {
  navigateToReceiptScreen: () => void;
  runId: number;
}

function HeaderRightSection(props: HeaderRightSectionProps) {
  const {navigateToReceiptScreen, runId} = props;
  const theme = useColorThemeType();
  const isFocus = useIsFocused();

  const navigateToPdfDetailScreen = useCallback(() => {
    navigation.navigate(ShopperScreens.PdfDetailScreen, {runId: runId});
  }, [runId]);

  return (
    <HBox style={[styles.headerIconContainer]}>
      <Touchable onPress={navigateToReceiptScreen}>
        <ReceiptIcon
          fill={theme?.background.gray[100]}
          viewBox="-4 -10 25 25"
        />
      </Touchable>
      <Touchable onPress={navigateToPdfDetailScreen}>
        <PdfIcon
          fill={theme?.background.gray[100]}
          viewBox="0 -5 25 25"
          style={[styles.rightIcon]}
        />
      </Touchable>
      <ChatOpener mode={ChatModes.run} runId={isFocus ? runId : undefined} />
    </HBox>
  );
}

export default React.memo(HeaderRightSection);
