import {Box, HBox} from '../../../../components/core/view';
import {Buyer, MostRecentAddress} from '../../../../../src/driver/types/common';
import {CustomText, H6Text} from '../../../../components/core/text';
import React, {useCallback, useState} from 'react';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../appStyles/hooks/theme';

import {Analytics} from '../../../../../src/config/analytics/analytics';
import Call from '../../../../components/core/icon/Call';
import ContactBottomSheet from '../../../../../src/itemDetails/components/ItemDetail/contactBottomSheet';
import {DynamicTextType} from '../../../../batchDetails/types';
import If from '../../../../../src/components/conditional/If';
import PersonRoundIcon from '../../../../components/core/icon/PersonRoundIcon';
import {StyleSheet} from 'react-native';
import {Touchable} from '../../../../components/core/touchable';
import UserNameUpperPill from '@buncha/common/components/VipProfileIcon';

interface BuyerDetailCellProps {
  hideAddress?: boolean;
  buyerDetails: Buyer | undefined;
  orderId?: number;
  buyerAddress?: MostRecentAddress;
  runId?: number;
}
const BuyerDetailCell: React.FC<BuyerDetailCellProps> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const [openSheet, setOpenSheet] = useState(false);
  const buyer = props?.buyerDetails;
  const buyerName = `${buyer?.firstName} ${buyer?.lastName}` || '';
  const address = props?.buyerAddress?.address || '';

  const toggleSheet = useCallback(() => {
    setOpenSheet(!openSheet);
    Analytics.eventWithProps('Open contact sheet for driver', {
      type: 'CTA',
    });
  }, [openSheet]);
  return (
    <HBox>
      <HBox style={styles.buyerBox}>
        <PersonRoundIcon fill={theme?.icon?.blue?.[300]} />
        <Box style={styles.nameBox}>
          <If condition={buyer?.isVip}>
            <UserNameUpperPill text={'VIP customer'} />
          </If>
          <H6Text
            numberOfLines={1}
            style={[
              styles.textAlignment,
              {
                color: theme?.text?.blue?.[300],
                fontFamily: muli?.[700]?.normal,
              },
            ]}>
            {buyerName}
          </H6Text>
          <If condition={!props?.hideAddress}>
            <CustomText
              numberOfLines={1}
              style={[
                styles.textAlignment,
                {
                  color: theme?.text?.gray?.[100],
                  fontFamily: muli?.[600]?.normal,
                },
              ]}>
              {address}
            </CustomText>
          </If>
        </Box>
      </HBox>

      <Touchable onPress={toggleSheet}>
        <Call viewBox="0 0 25 20" fill={theme?.icon?.green?.[100]} />
      </Touchable>
      <If condition={openSheet}>
        <ContactBottomSheet
          isOpen={openSheet}
          onClose={toggleSheet}
          showSMSTemplates={true}
          buyerInfo={buyer}
          type={DynamicTextType.driver}
          orderId={props.orderId}
          runId={props?.runId}
        />
      </If>
    </HBox>
  );
};

export default React.memo(BuyerDetailCell);

const styles = StyleSheet.create({
  nameBox: {
    alignItems: 'flex-start',
    flex: 1,
    marginLeft: 10,
  },
  textAlignment: {
    textAlign: 'left',
  },
  buyerBox: {
    flex: 0.8,
    alignItems: 'flex-start',
  },
});
