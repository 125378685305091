import {Dimensions, StyleSheet} from 'react-native';

import {Box} from '../../../components/core/view';
import {CustomImage} from '../../../components/core/Image';
import {H6Text} from '../../../components/core/text';
import If from '../../../components/conditional/If';
import React from 'react';
import font from '../../../appStyles/style/font';

interface SampleImageType {
  header: string;
  isDo: boolean;
}

const screenHeight = Dimensions.get('screen').height;

const SampleImage = (props: SampleImageType) => {
  const {header, isDo} = props;
  return (
    <Box style={[styles.parent]}>
      <H6Text style={[styles.titleStyle]}>{header}</H6Text>
      <If condition={isDo}>
        <CustomImage
          source={'https://constants.gobuncha.com/correctReceipt.png'}
          style={[styles.imageStyle]}
          resizeMode={'contain'}
        />
      </If>
      <If condition={!isDo}>
        <CustomImage
          source={'https://constants.gobuncha.com/badPhoto.png'}
          style={[styles.imageStyle]}
          resizeMode={'contain'}
        />
      </If>
    </Box>
  );
};

export default React.memo(SampleImage);

const styles = StyleSheet.create({
  parent: {
    flex: 1,
  },
  imageStyle: {
    height: screenHeight / 2.7,
    width: '100%',
  },
  titleStyle: {
    fontFamily: font.fonts.muliBold,
    marginBottom: 10,
    marginTop: 10,
  },
});
