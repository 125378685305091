import {PhotoFile} from 'react-native-vision-camera';

/* eslint-disable no-shadow */
export type WebViewParams = {uri?: string; onReload?: Function} | undefined;

export interface ApiState<T> {
  loading: boolean;
  error: Error | undefined;
  data?: T | null;
}

export enum OrderItemStatus {
  notPicked = 'notPicked',
  picked = 'picked',
  dropped = 'dropped',
  packed = 'packed',
  notPacked = 'notPacked',
  missing = 'missing',
  requestRejectedByUser = 'requestRejectedByUser',
}

export enum SubstitutionRequestStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  expired = 'expired',
  cleared = 'cleared',
  cancelled = 'cancelled',
}

export enum InputType {
  email = 'email',
  name = 'name',
  text = 'text',
  radio = 'radio',
  checkbox = 'checkbox',
}

export enum HiringApplicationState {
  submitted = 'submitted',
  rejected = 'rejected',
  interview = 'interview',
  offered = 'offered',
  accountSetup = 'accountSetup',
  completed = 'completed',
  notSubmitted = 'notSubmitted',
}

export enum CTAIntentType {
  navAction = 'navAction',
  deeplink = 'deeplink',
}

export type ImageParams = {imageUrl?: string} | undefined;

export interface CameraComponentState {
  fileData?: PhotoFile;
  base64: string;
}
