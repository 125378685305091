import Box from './Box';
import {BoxProps} from './type';
import React from 'react';

import {StyleSheet, View, ViewStyle} from 'react-native';

const CentreBox: React.FC<BoxProps<View>> = React.forwardRef((props, ref) => {
  const propsToSpread = {
    ...props,
    style: {...styles.CentreBox, ...(props.style as ViewStyle)},
  };
  return (
    <Box {...propsToSpread} ref={ref}>
      {props.children}
    </Box>
  );
});

const styles = StyleSheet.create({
  CentreBox: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
});

export default React.memo(CentreBox);
