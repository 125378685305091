import {StyleSheet} from 'react-native';
import {scaleFonts} from '../../../../appStyles/style/font';

export const styles = StyleSheet.create({
  default: {
    marginHorizontal: scaleFonts(3),
    borderRadius: 10,
    height: scaleFonts(55, 'height'),
    paddingHorizontal: scaleFonts(10),
  },
  containerDefault: {
    overflow: 'hidden',
  },

  clearTouchable: {
    padding: scaleFonts(12),
  },
  webInputBoxStyle: {
    outlineStyle: 'none',
    padding: 20,
    flex: 1,
  },
});
