import {CustomText, H5Text} from '../../../../components/core/text';
import {HomeScreens, ShopperScreens} from '../../../../home/navigation/types';
import React, {useCallback} from 'react';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../appStyles/hooks/theme';

import AppModal from '../../../../common/components/CustomModal/AppModal';
import {HBox} from '../../../../components/core/view';
import {ItemDetailsScreen} from '../../../../itemDetails/navigation/types';
import {OrderItemStatus} from '@buncha/batchDetails/types';
import {navigation} from '../../../../config/navigation';
import {styles} from './style';
import {useRoute} from '@react-navigation/native';
import {useUpdateOrderItemStatus} from '@buncha/batchDetails/hooks/BatchDetails';

interface ReminderModalProps {
  open: boolean;
  quantity?: number;
  onClose: Function;
  isCatalogItem: boolean;
  orderItemIds: number[];
}

const ReminderModal = (props: ReminderModalProps) => {
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const {open, quantity, onClose, orderItemIds} = props;
  const route = useRoute();
  const routeParams: any = route?.params;
  const orderItemId = routeParams?.orderItemId;
  const storeIndex = routeParams?.storeIndex || '';
  const runId = routeParams?.runId || '';
  const {updateOrderItemStatus, changeStatusState} = useUpdateOrderItemStatus();

  const navigateToBatchScreen = useCallback(() => {
    onClose();
    navigation.navigate(HomeScreens.Shopper, {
      screen: ShopperScreens.BatchDetailsPage,
      params: {runId},
    });
  }, [onClose, runId]);

  const handleOnClose = useCallback(() => {
    if (!props?.isCatalogItem && runId) {
      updateOrderItemStatus(
        runId,
        OrderItemStatus.picked,
        [orderItemId],
        navigateToBatchScreen,
      );
    } else {
      onClose();
      navigation.navigate(ShopperScreens.ItemDetails, {
        screen: ItemDetailsScreen.ItemScanner,
        params: {
          orderItemId,
          storeIndex,
          runId,
          quantity,
          orderItemIds: orderItemIds,
        },
      });
    }
  }, [
    navigateToBatchScreen,
    onClose,
    orderItemId,
    orderItemIds,
    props?.isCatalogItem,
    quantity,
    runId,
    storeIndex,
    updateOrderItemStatus,
  ]);

  return (
    <AppModal
      isOpen={open}
      onClose={() => props?.onClose()}
      topCTAHandelr={handleOnClose}
      title={'Reminder'}
      hideBottomCta
      modalBody={
        <HBox style={styles.modalBody}>
          <H5Text>You need to pick </H5Text>

          <HBox
            style={[
              styles.highlightText,
              {
                backgroundColor: theme?.background.yellow[100],
              },
            ]}>
            <CustomText
              style={{
                fontFamily: muliFont[700].normal,
                color: theme?.text.blue[300],
              }}>{` Qty: ${quantity} `}</CustomText>
          </HBox>
          <H5Text> for this item</H5Text>
        </HBox>
      }
      ctaTopTitle={'OK'}
      topCTAColor={theme?.background.green[100]}
      loading={changeStatusState?.loading}
    />
  );
};

export default React.memo(ReminderModal);
