import {HomeScreens, ShopperScreens} from '../../../../home/navigation/types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  RecommendedItemCell,
  SubstitutionItemParams,
  SubstitutionRequestParams,
} from '../../../../../src/itemDetails/types/itemReplacement';
import {
  useItemReplacementContext,
  useSendSubstitutionRequestToBuyer,
  useSubstituteItemInRun,
} from '../../../../../src/itemDetails/hooks/itemReplacement';

import AppModal from '../../../../common/components/CustomModal/AppModal';
import {H5Text} from '@buncha/components/core/text';
import {HBox} from '@buncha/components/core/view';
import If from '@buncha/components/conditional/If';
import {ItemDetailsScreen} from '../../../../itemDetails/navigation/types';
import QRCode from '../../../../../src/components/core/icon/QRCode';
import ReplacementModalBody from '../replacementModalBody';
import {SubstitutionMode} from '../../../../../src/itemDetails/types/itemDetails';
import {isWeb} from '@buncha/utils/common';
import {navigation} from '../../../../../src/config/navigation';
import {styles} from './styles';
import {useColorThemeType} from '../../../../../src/appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';

interface RequestReplacementModalProps {
  open: boolean;
  onClose: () => void;
  catalogItemId: number;
  itemSource: string;
  indexPosition: number;
  catalogItem?: RecommendedItemCell;
}

const RequestReplacementModal: React.FC<
  RequestReplacementModalProps
> = props => {
  const route = useRoute();
  const routeParams: {runId?: number} | undefined = route?.params;
  const runId = routeParams?.runId || 0;
  const {state} = useItemReplacementContext();
  const theme = useColorThemeType('main');
  const [alreadyApproved, setAlreadyApproved] = useState(false);
  const {sendSubstitutionRequestToBuyer, loading} =
    useSendSubstitutionRequestToBuyer();
  const {substituteItemInRun, itemSubstituteLoading, errorMessage} =
    useSubstituteItemInRun();

  const [showErrorModal, setSetshowErrorModal] = useState('');

  const handleOnClose = () => {
    setSetshowErrorModal('');
    props?.onClose();
  };

  useEffect(() => {
    if (errorMessage) setSetshowErrorModal(errorMessage);
  }, [errorMessage]);

  const catalogItemId = props?.catalogItemId || 0;
  const item = state?.item;
  const orderItemId = item?.id || 0;
  const orderId = item?.order?.id;
  const quantity =
    item?.shopperItemChangesForShopper?.updatedQuantity || item?.quantity || 0;
  const catalogItem = props.catalogItem;
  const isWebMode = isWeb();

  const storeIndex = item?.order?.run?.store?.elasticSearchIndex;

  const paramsToSend: SubstitutionRequestParams = {
    catalogItemId: catalogItemId,
    orderItemId: orderItemId,
    quantity: quantity,
    itemSource: props?.itemSource,
    indexPosition: props?.indexPosition,
  };

  const getText = useMemo(() => {
    if (alreadyApproved)
      return {
        header: 'Already approved by customer?',
        body: 'You will be penalized if the customer denies for this substitution',
        topCTA: `${isWebMode ? 'Pick Item' : 'Found Item'}`,
        bottomCTA: 'Cancel',
      };
    return {
      header: 'Are you sure?',
      body: 'Adding this item will lead to the replacement of the original item.',
      topCTA: 'Request Replacement',
      bottomCTA: 'Approved Via Text/Call',
    };
  }, [alreadyApproved, isWebMode]);

  const topCTAHandler = useCallback(async () => {
    if (!alreadyApproved && catalogItemId && orderItemId && quantity) {
      await sendSubstitutionRequestToBuyer(paramsToSend);
      props?.onClose();
      navigation.navigate(HomeScreens.Shopper, {
        screen: ShopperScreens.BatchDetailsPage,
        params: {
          runId: runId,
        },
      });
    } else if (alreadyApproved) {
      if (
        !catalogItem ||
        !catalogItemId ||
        !orderId ||
        !quantity ||
        !orderItemId ||
        !storeIndex
      )
        return;
      const substituteParams: SubstitutionItemParams = {
        catalogItemId: catalogItemId,
        isCustomItem: false,
        itemImageUrl: catalogItem.itemImageUrl,
        name: catalogItem.name,
        orderId: orderId,
        position: 0,
        orderItemId: orderItemId,
        price: catalogItem.price,
        quantity: quantity, // change to dynamic if required
        substitutedBy: JSON.stringify(item),
        substitutionRequestParmas: {
          substitutionMode: SubstitutionMode.approvedOverCallBeforeRequestSend,
        },
        indexPosition: catalogItem.indexPosition,
        itemSource: catalogItem.itemSource,
        isPriceSensitive: Boolean(catalogItem.flags?.priceSensitive),
        isWeightSensitive: Boolean(catalogItem.flags?.weightSensitive),
      };
      const response = await substituteItemInRun(substituteParams);
      if (response) {
        props.onClose();
        if (isWebMode)
          navigation.navigate(HomeScreens.Shopper, {
            screen: ShopperScreens.BatchDetailsPage,
            params: {runId},
          });
        else
          navigation.navigate(ShopperScreens.ItemDetails, {
            screen: ItemDetailsScreen.ItemScanner,
            params: {
              orderItemId,
              storeIndex,
              runId,
              quantity,
            },
          });
      }
    } else {
      props?.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyApproved, catalogItemId, orderItemId, props, quantity]);

  const bottomCTAHandler = useCallback(() => {
    if (!alreadyApproved) setAlreadyApproved(true);
    else props?.onClose();
  }, [alreadyApproved, props]);

  return (
    <React.Fragment>
      <If condition={!showErrorModal}>
        <AppModal
          isOpen={props?.open}
          onClose={() => props?.onClose()}
          hideTopCta={!alreadyApproved}
          topCTAHandelr={topCTAHandler}
          bottomCTAHandelr={bottomCTAHandler}
          title={getText?.header}
          modalBody={
            <ReplacementModalBody
              text={getText?.body}
              item={item}
              catalogItem={catalogItem}
            />
          }
          ctaBottomTitle={getText?.bottomCTA}
          ctaTopTitle={getText?.topCTA}
          topCTAColor={theme?.background.green[100]}
          ctaTopLeftComponent={
            alreadyApproved &&
            !isWebMode && <QRCode viewBox="-3 -1 25 15" style={styles.icon} />
          }
          loading={loading || itemSubstituteLoading}
        />
      </If>
      <If condition={Boolean(showErrorModal)}>
        <AppModal
          isOpen={true}
          onClose={handleOnClose}
          topCTAHandelr={handleOnClose}
          title={'Information!'}
          hideBottomCta
          modalBody={
            <HBox style={styles.modalBody}>
              <H5Text>{showErrorModal}</H5Text>
            </HBox>
          }
          ctaTopTitle={'OK'}
          topCTAColor={theme?.background.green[100]}
        />
      </If>
    </React.Fragment>
  );
};

export default React.memo(RequestReplacementModal);
