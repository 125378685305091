import Login from '../screens/Login';
import {OnBoardingScreens} from './types';
import Onboarding from '../screens/Onboarding';
import React from 'react';
import SignUp from '../screens/SignUp';
import Splash from '../screens/Splash';
import VerificationCode from '../screens/VerificationCode';
import {createNativeStackNavigator} from '@react-navigation/native-stack';

const Stack = createNativeStackNavigator();

const OnboardingNavigator: React.FC = () => {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen name={OnBoardingScreens.Splash} component={Splash} />
      <Stack.Screen
        name={OnBoardingScreens.Onboarding}
        component={Onboarding}
        options={{
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name={OnBoardingScreens.Login}
        component={Login}
        options={{
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name={OnBoardingScreens.VerificationCode}
        component={VerificationCode}
        options={{
          gestureEnabled: false,
        }}
      />
      {/* <Stack.Screen
        name={OnBoardingScreens.ShopperDriverApplication}
        component={ShopperDriverApplication}
        options={{
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name={OnBoardingScreens.ShopperDriverForm}
        component={ShopperDriverForm}
        options={{
          gestureEnabled: false,
        }}
      /> */}
      <Stack.Screen
        name={OnBoardingScreens.SignUp}
        component={SignUp}
        options={{
          gestureEnabled: false,
        }}
      />
    </Stack.Navigator>
  );
};

export {OnboardingNavigator};
