import {
  ADD_BAGS_USED_FOR_ORDER,
  GET_RUN_FOR_SEA_SPIDER,
} from '@buncha/config/graphQL/documentNode/queries';
import {Dispatch, useContext, useReducer, useState} from 'react';
import {NavigateVanLoadContext, initialState} from '../context/NavigateVanLoad';
import {NavigateVanLoadContextState, SeaSpiderRun} from '../types';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {gqlService} from '@buncha/config/graphQL';

const reducer = {
  setSeaSpiderRun: function (
    state: NavigateVanLoadContextState,
    action: PayloadAction<SeaSpiderRun | undefined>,
  ) {
    state.run = action.payload;
    state.loading = false;
  },
  setUsedBagCount: function (
    state: NavigateVanLoadContextState,
    action: PayloadAction<{orderId: number; usedBag: number}>,
  ) {
    const run = state?.run;
    const payload = action.payload;
    const orderId = payload?.orderId;
    const usedBag = payload?.usedBag;
    if (run) {
      const order = run.ordersForShopper.find(ord => ord.id === orderId);
      if (order) order.bagsUsed = usedBag;
    }
  },
  setBagCountUpdateStatus: function (
    state: NavigateVanLoadContextState,
    action: PayloadAction<{orderId: number; isBagCountUpdated: boolean}>,
  ) {
    const payload = action.payload;
    const orderId = payload.orderId;

    const update = state.bagCountUpdateStatus;

    update[orderId] = payload.isBagCountUpdated;
  },
};

const slice = createSlice({
  initialState,
  name: 'navigateVanLoad',
  reducers: reducer,
});

export function useNavigateVanLoadReducer() {
  const navigateVanLoadReducer = useReducer(slice.reducer, initialState);
  return navigateVanLoadReducer;
}

export function useNavigateVanLoadContext() {
  return useContext(NavigateVanLoadContext);
}

export const navigateVanLoadActions = slice.actions;

export function useGetRunforSeaSpider(dispatch: Dispatch<any> | null) {
  const [fetchingRuns, setFetchingRuns] = useState(false);
  const getRunForSeaSpider = async (runId: number) => {
    if (!runId) return;
    setFetchingRuns(true);
    try {
      const response = await gqlService?.query({
        query: GET_RUN_FOR_SEA_SPIDER,
        variables: {runId: runId},
        fetchPolicy: 'network-only',
      });
      if (response?.data) {
        const run = response?.data?.getRun;

        if (dispatch) dispatch(navigateVanLoadActions.setSeaSpiderRun(run));
        setFetchingRuns(false);
        return run;
      }
      setFetchingRuns(false);
    } catch (err: any) {
      setFetchingRuns(false);
    }
  };

  return {fetchingRuns, getRunForSeaSpider};
}

export function useAddBagsUsedForOrder(dispatch: Dispatch<any> | null) {
  const [loading, setLoading] = useState(false);
  const addBagsUsersForOrder = async (orderId: number, bagsUsed: number) => {
    try {
      setLoading(true);
      const res = await gqlService?.mutation({
        mutation: ADD_BAGS_USED_FOR_ORDER,
        fetchPolicy: 'network-only',
        variables: {
          orderId: orderId,
          bagsUsed: bagsUsed,
        },
      });

      const orderRes = res?.data?.addBagsUsedForOrder;

      if (orderRes && dispatch) {
        dispatch(
          navigateVanLoadActions.setBagCountUpdateStatus({
            orderId: orderId,
            isBagCountUpdated: true,
          }),
        );
        dispatch(
          navigateVanLoadActions.setUsedBagCount({
            orderId: orderId,
            usedBag: orderRes?.bagsUsed,
          }),
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return {addBagsUsersForOrder, loading};
}
