import {
  BatchDetailTabs,
  ContactBottomSheetProps,
  DeliveryLocationStatus,
  DynamicTextType,
  GroupedAssignedItems,
  OrderItemStatus,
  OrderItems,
  ReceiptBypassMode,
  RunForBatchDetail,
  itemsForSection,
  itemsSortBy,
  UPCOrderItem as upcOrderItem,
} from '../../../batchDetails/types';
import {Box, HBox} from '../../../components/core/view';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  Divider,
  IconButton,
  Skeleton,
} from 'native-base';
import {HomeScreens, ShopperScreens} from '../../../home/navigation/types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  batchDetailActionsLocal,
  useBatchDetailContext,
} from '../../../batchDetails/hooks/batchDetail';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';
import {
  useGetItemStatusCount,
  useSetDeliveryLocationStatusOfAssignedOrders,
  useUPCOrder,
} from '../../../batchDetails/hooks/OrderOperation';
import {
  useGetOrdersForOrderAssignee,
  useUPCShift,
  useUpdateOrderItemStatus,
} from '../../../batchDetails/hooks/BatchDetails';
import {useIsFocused, useRoute} from '@react-navigation/native';

import {Accordian} from '../../../components/composites';
import AddToCartIcon from '../../../components/core/icon/AddToCartIcon';
import {Analytics} from '../../../config/analytics/analytics';
import {BottomButton} from '../../../components/composites/Button';
import {CustomFlatList} from '../../../components/core/list';
import {CustomItemMode} from '../../../itemDetails/types/CustomItem';
import CustomModal from '@buncha/common/components/CustomModal/CustomModal';
import {CustomText} from '../../../components/core/text';
import CustomerOrderInfo from '../CustomerOrderInfo';
import DoneOrderItemList from '../DoneOrderItemList';
import EmptyDoneSection from '../EmptyDoneSection';
import {EventType} from '../../../config/analytics/type';
import If from '../../../../src/components/conditional/If';
import {ItemDetailsScreen} from '../../../itemDetails/navigation/types';
import {MainScreens} from '@buncha/navigation/types';
import {ReceiptModal} from '../RecieptModal';
import {SpinnerComponent} from '../../../components/core/loader';
import UPCOrderItem from '../BatchOrderItem/UPCOrderItem';
import WaveIcon from '../../../components/core/icon/WaveIcon';
import {navigation} from '../../../config/navigation';
import {styles} from './styles';
import {useAppSelector} from '@buncha/config/redux/hooks';

interface DoneItemListProps {
  runDetail?: RunForBatchDetail;
  isUPCMode?: boolean;
  upcOrderId: number;
  navigateToReceiptScreen: () => void;
}

function DoneItemList(props: DoneItemListProps) {
  const route = useRoute();
  const routeParams: {runId?: number} | undefined = route?.params;
  const runId = Number(routeParams?.runId);
  const {state, dispatch} = useBatchDetailContext();
  const [getOrdersForOrderAssignee, loading] =
    useGetOrdersForOrderAssignee(dispatch);
  const theme = useColorThemeType();
  const muliFont = useFontTheme();
  const {updateOrderItemStatus, changeStatusState} = useUpdateOrderItemStatus();
  const [setDeliveryLocationStatusOfAssignedOrders, pickState] =
    useSetDeliveryLocationStatusOfAssignedOrders(dispatch);
  const [getItemStatusCount, countState] = useGetItemStatusCount(dispatch);
  const {getUPCShiftOrder, orderLoading} = useUPCShift(dispatch);
  const {updateOrderStatus} = useUPCOrder();

  const {selectedCatalogStoreForUPCRun} = useAppSelector(
    gState => gState.itemScanner,
  );

  const isFocused = useIsFocused();
  const [showModal, setShowModal] = useState(false);
  const [uploadReceipt, setUploadReceipt] = useState(true);
  const runDetail = props.runDetail;

  const tutorial = state.tutorial;

  const isLoading = Boolean(
    pickState.loading ||
      loading ||
      countState.loading ||
      changeStatusState.loading,
  );

  const buttonTitle = useMemo(() => {
    if (uploadReceipt && !props.isUPCMode) return 'Upload Receipt';
    return 'Done Picking';
  }, [props.isUPCMode, uploadReceipt]);

  useEffect(() => {
    Analytics.eventWithProps('Done Tab', {
      type: EventType.cta,
      runID: runId,
      totalItem: state?.tabDataCount?.doneCount || 0,
    });

    return () => {
      if (dispatch)
        dispatch(batchDetailActionsLocal.setDisablePickingRadioButton(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.hasSkipReceipt === ReceiptBypassMode.skipByShopper)
      setUploadReceipt(false);
  }, [state.hasSkipReceipt]);

  useEffect(() => {
    if (!dispatch) return;
    if (runId && !runDetail?.receiptImageUrls?.length) {
      setUploadReceipt(true);
      dispatch(batchDetailActionsLocal.setOpenUploadReceiptModal(true));
      dispatch(
        batchDetailActionsLocal.setSkippedReceiptFlow(ReceiptBypassMode.none),
      );
    } else {
      dispatch(
        batchDetailActionsLocal.setSkippedReceiptFlow(
          ReceiptBypassMode.showSkip,
        ),
      );
    }
  }, [dispatch, runDetail?.receiptImageUrls?.length, runId]);

  const buttonDisabled = useMemo(() => {
    if (props?.isUPCMode)
      return !(
        state?.tabDataCount?.todoCount === 0 &&
        state?.tabDataCount?.doneCount >= 0
      );
    return Boolean(state.remainingItemCount[0] || isLoading);
  }, [
    isLoading,
    props?.isUPCMode,
    state.remainingItemCount,
    state?.tabDataCount?.doneCount,
    state?.tabDataCount?.todoCount,
  ]);

  const closeContactSheet = useCallback(() => {
    if (dispatch)
      dispatch(batchDetailActionsLocal.setContactBottomSheetProps(undefined));
  }, [dispatch]);

  const navigateToAddItem = useCallback(
    (item: GroupedAssignedItems) => {
      closeContactSheet();
      const orderId = item.orderItems?.length ? item.orderItems[0].orderId : 0;
      const storeId = runDetail?.store?.id;
      const storeIndex = runDetail?.store?.elasticSearchIndex || '';
      if (!runId || !storeId || !orderId) return;
      if (!runDetail?.store?.elasticSearchIndex?.length)
        return navigation.navigate(ShopperScreens.ItemDetails, {
          screen: ItemDetailsScreen.CustomItem,
          params: {
            orderId,
            runId,
            mode: CustomItemMode.addItem,
          },
        });
      navigation.navigate(HomeScreens.Shopper, {
        screen: ShopperScreens.AddItemPage,
        params: {
          storeId,
          runId,
          orderId,
          storeIndex,
        },
      });
    },
    [
      closeContactSheet,
      runDetail?.store?.elasticSearchIndex,
      runDetail?.store?.id,
      runId,
    ],
  );

  const navigateToOrderPack = useCallback(() => {
    navigation.navigate(HomeScreens.Shopper, {
      screen: ShopperScreens.OrderPackingPage,
      params: {runId: runId, tutorial: tutorial ? 'true' : undefined},
    });
  }, [runId, tutorial]);

  const updateUPCOrdetItemStatus = useCallback(async () => {
    await updateOrderStatus(selectedCatalogStoreForUPCRun?.id ?? 0, () => {
      navigation.replace(MainScreens.Home, {
        screen: HomeScreens.UPCShifts,
      });
    });
  }, [selectedCatalogStoreForUPCRun?.id, updateOrderStatus]);

  const orderPickState = useCallback(async () => {
    if (props.isUPCMode) {
      updateUPCOrdetItemStatus();
    } else if (uploadReceipt) {
      return props?.navigateToReceiptScreen();
    } else if (runId) {
      Analytics.eventWithProps('Done Picking', {
        type: EventType.cta,
        runID: runId,
        deliveryLocationStatus: DeliveryLocationStatus.readyToPack,
      });
      await setDeliveryLocationStatusOfAssignedOrders(
        runId,
        DeliveryLocationStatus.readyToPack,
        state.orderIdsToBeSend || [],
        navigateToOrderPack,
      );
    }
  }, [
    navigateToOrderPack,
    runId,
    setDeliveryLocationStatusOfAssignedOrders,
    state.orderIdsToBeSend,
    updateUPCOrdetItemStatus,
    props,
    uploadReceipt,
  ]);

  const getDoneItem = useCallback(() => {
    if (runId) {
      getItemStatusCount(runId, [OrderItemStatus.notPicked]);
      getOrdersForOrderAssignee(
        runId,
        itemsForSection.done,
        itemsSortBy.customer,
      );
    }
  }, [getItemStatusCount, getOrdersForOrderAssignee, runId]);

  const changedItemStatus = useCallback(
    (orderItem: OrderItems) => {
      Analytics.eventWithProps('Uncheck Item', {
        type: EventType.cta,
        runID: runId,
        itemStatus: OrderItemStatus.notPicked,
        currentItemStatus: orderItem?.itemStatus,
        orderItemId: orderItem.id,
      });
      if (runId)
        updateOrderItemStatus(
          runId,
          OrderItemStatus.notPicked,
          [orderItem.id],
          getDoneItem,
        );
    },
    [getDoneItem, runId, updateOrderItemStatus],
  );

  const handleAddItem = useCallback(
    (item: GroupedAssignedItems) => {
      const orderId = item.orderItems?.length ? item.orderItems[0].orderId : 0;
      const params: ContactBottomSheetProps = {
        type: DynamicTextType.addItem,
        buttonText: 'Add Item',
        onButtonClick: () => navigateToAddItem(item),
        // header: "Contact Customer",
        // subHeader: "Please coordinate with customer before adding item",
        orderId: orderId,
      };

      if (dispatch)
        dispatch(batchDetailActionsLocal.setContactBottomSheetProps(params));
    },
    [dispatch, navigateToAddItem],
  );

  const handleContactCustomer = useCallback(
    (item: GroupedAssignedItems) => {
      const orderId = item.orderItems?.length ? item.orderItems[0].orderId : 0;
      const params: ContactBottomSheetProps = {
        type: DynamicTextType.customer,
        orderId: orderId,
      };
      if (dispatch)
        dispatch(batchDetailActionsLocal.setContactBottomSheetProps(params));
    },
    [dispatch],
  );

  const renderItem = useCallback(
    ({item}: {item: GroupedAssignedItems | upcOrderItem; index: number}) => {
      if (props?.isUPCMode && !orderLoading) {
        const updatedItem = item as upcOrderItem;
        return (
          <Box style={[styles.mainContainer]}>
            <UPCOrderItem orderItem={updatedItem} />
          </Box>
        );
      }

      if (props?.isUPCMode && orderLoading)
        return (
          <Box style={styles.skeletonContainer}>
            <Skeleton marginTop={5} height={75} color={'red'} />
          </Box>
        );

      const itemForRender = item as GroupedAssignedItems;
      const isVip = itemForRender?.metaData?.buyer?.isVip;

      return (
        <Box style={[styles.mainContainer]}>
          <Accordian
            isExpanded={true}
            header={
              <HBox style={[styles.mainHeaderContainer]}>
                <CustomerOrderInfo
                  groupedAssignedItems={itemForRender}
                  isVip={isVip}
                />
                <HBox>
                  <IconButton
                    onPress={() => handleContactCustomer(itemForRender)}>
                    <WaveIcon />
                  </IconButton>
                  <IconButton onPress={() => handleAddItem(itemForRender)}>
                    <AddToCartIcon width={18} height={18} viewBox="0 0 21 21" />
                  </IconButton>
                  <Box
                    style={[
                      styles.countBox,
                      {
                        backgroundColor: theme?.background.gray[200],
                      },
                    ]}>
                    <CustomText
                      style={[
                        {
                          fontFamily: muliFont[700].normal,
                          color: theme?.text.gray[100],
                        },
                      ]}>
                      {itemForRender?.orderItems?.length}
                    </CustomText>
                  </Box>
                </HBox>
              </HBox>
            }
            children={
              <DoneOrderItemList
                esIndex={runDetail?.store?.elasticSearchIndex ?? ''}
                orderItems={itemForRender.orderItems}
                onClick={changedItemStatus}
                disableOnclick={
                  pickState.loading ||
                  changeStatusState.loading ||
                  state?.disablePickingRadioButton ||
                  false
                }
              />
            }
            iconEnable={<ChevronUpIcon color={theme?.text.blue[300]} />}
            iconDisable={<ChevronDownIcon color={theme?.text.blue[300]} />}
          />
        </Box>
      );
    },
    [
      props?.isUPCMode,
      orderLoading,
      theme?.background.gray,
      theme?.text.gray,
      theme?.text.blue,
      muliFont,
      runDetail?.store?.elasticSearchIndex,
      changedItemStatus,
      pickState.loading,
      changeStatusState.loading,
      state?.disablePickingRadioButton,
      handleContactCustomer,
      handleAddItem,
    ],
  );

  const Separator = useCallback(() => <Divider style={styles.divider} />, []);

  useEffect(() => {
    if (dispatch) dispatch(batchDetailActionsLocal.setShowSortBarValue(false));
  }, [dispatch]);

  useEffect(() => {
    if (runId && isFocused && !props.isUPCMode)
      getOrdersForOrderAssignee(
        runId,
        itemsForSection.done,
        itemsSortBy.customer,
      );
    else if (props.isUPCMode)
      getUPCShiftOrder(props?.upcOrderId, BatchDetailTabs.done, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId, isFocused, props.isUPCMode]);

  useEffect(() => {
    if (runId) getItemStatusCount(runId, [OrderItemStatus.notPicked]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId]);

  const dataToRender = useMemo(() => {
    if (props?.isUPCMode) return state.upcOrderData?.orderItems;
    return state.groupedAssignedItems.done;
  }, [
    props?.isUPCMode,
    state.groupedAssignedItems.done,
    state.upcOrderData?.orderItems,
  ]);

  const handleOnPressReceiptModal = useCallback(() => {
    if (dispatch) {
      setShowModal(false);
      setUploadReceipt(true);
      dispatch(batchDetailActionsLocal.setOpenUploadReceiptModal(true));
    }
  }, [dispatch]);

  return (
    <React.Fragment>
      <CustomFlatList
        data={dataToRender}
        renderItem={renderItem}
        ItemSeparatorComponent={Separator}
        loading={orderLoading || loading}
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={
          <If condition={!(orderLoading || loading)}>
            <EmptyDoneSection />
          </If>
        }
        loadingSkeleton={
          <Box style={styles.skeletonContainer}>
            <Skeleton marginTop={5} height={75} />
          </Box>
        }
        style={[styles.scrollContainer]}
        contentContainerStyle={styles.contentBox}
      />
      <If condition={showModal}>
        <CustomModal open={showModal} onClose={handleOnPressReceiptModal}>
          <ReceiptModal onClose={handleOnPressReceiptModal} />
        </CustomModal>
      </If>
      <BottomButton
        title={buttonTitle}
        disabled={buttonDisabled}
        onPress={orderPickState}
        isLoading={orderLoading || loading}
        loadingIndicator={<SpinnerComponent />}
      />
    </React.Fragment>
  );
}

export default React.memo(DoneItemList);
