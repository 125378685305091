import {CustomText, H5Text} from '../../../../components/core/text';
import React, {useCallback} from 'react';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../appStyles/hooks/theme';

import AppModal from '../../../../common/components/CustomModal/AppModal';
import {HBox} from '../../../../components/core/view';
import {styles} from './style';
import {useItemReplacementContext} from '../../../../itemDetails/hooks/itemReplacement';

interface QuantityModalProps {
  open: boolean;
  onClose: Function;
  onConfirm: Function;
}

const QuantityModal = (props: QuantityModalProps) => {
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const {state} = useItemReplacementContext();
  const {open, onClose, onConfirm} = props;

  const {item} = state;
  const quantity =
    item?.shopperItemChangesForShopper?.updatedQuantity || item?.quantity || 0;

  const handleOnClose = useCallback(() => {
    onClose();
    onConfirm();
  }, [onClose, onConfirm]);

  return (
    <AppModal
      isOpen={open}
      onClose={() => props?.onClose()}
      topCTAHandelr={handleOnClose}
      title={'Reminder'}
      hideBottomCta
      modalBody={
        <HBox style={styles.modalBody}>
          <H5Text>You need to pick </H5Text>

          <HBox
            style={[
              styles.highlightText,
              {
                backgroundColor: theme?.background.yellow[100],
              },
            ]}>
            <CustomText
              style={{
                fontFamily: muliFont[700].normal,
                color: theme?.text.blue[300],
              }}>{` Qty: ${quantity} `}</CustomText>
          </HBox>
          <H5Text> for this item</H5Text>
        </HBox>
      }
      ctaTopTitle={'OK'}
      topCTAColor={theme?.background.green[100]}
    />
  );
};

export default React.memo(QuantityModal);
