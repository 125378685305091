import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const QRCode: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        data-name="Icon QRCode"
        d="M8 4.5V7.5H5V4.5H8ZM9.5 3H3.5V9H9.5V3ZM8 12.5V15.5H5V12.5H8ZM9.5 11H3.5V17H9.5V11ZM16 4.5V7.5H13V4.5H16ZM17.5 3H11.5V9H17.5V3ZM11.5 11H13V12.5H11.5V11ZM13 12.5H14.5V14H13V12.5ZM14.5 11H16V12.5H14.5V11ZM11.5 14H13V15.5H11.5V14ZM13 15.5H14.5V17H13V15.5ZM14.5 14H16V15.5H14.5V14ZM16 12.5H17.5V14H16V12.5ZM16 15.5H17.5V17H16V15.5ZM20.5 5H18.5V2H15.5V0H20.5V5ZM20.5 20V15H18.5V18H15.5V20H20.5ZM0.5 20H5.5V18H2.5V15H0.5V20ZM0.5 0V5H2.5V2H5.5V0H0.5Z"
        transform="translate(-3.375 -3.375)"
      />
    </Icon>
  );
};

export default QRCode;
