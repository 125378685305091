import {Dimensions, StyleSheet} from 'react-native';
import {getWidth, isWeb} from '../../../utils/common';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {PTextInputProps} from '../../../components/core/input/TextInput/type';
import React from 'react';
import {TextInputGroup} from '../../../components/core/input';
import {scaleFonts} from '../../../appStyles/style/font';

const WhenIWorkInput: React.FC<PTextInputProps> = React.forwardRef(
  (props, ref) => {
    const theme = useColorThemeType();
    const fontTheme = useFontTheme();
    return (
      <TextInputGroup.PTextInput
        colorThemeType="default"
        clearButtonMode="while-editing"
        ref={ref}
        {...props}
        containerStyle={[
          styles.textInputContainer,
          {
            backgroundColor: theme?.background.white[100],
            borderColor: theme?.text.gray[100],
          },
          props.containerStyle,
        ]}
        style={[
          {
            backgroundColor: theme?.background.white[100],
            fontFamily: fontTheme[700].normal,
            color: theme?.text.gray[100],
            fontSize: scaleFonts(18),
          },
          props.style,
        ]}
        autoFocus={false}
      />
    );
  },
);

export default WhenIWorkInput;

const styles = StyleSheet.create({
  textInputContainer: {
    borderRadius: 100,
    padding: 3,
    marginBottom: 10,
    borderWidth: 1,
    width: isWeb()
      ? Number(getWidth(Dimensions.get('screen').width)) * 0.85
      : Number(getWidth(Dimensions.get('screen').width)) * 0.95,
    marginLeft: 0,
    outlineStyle: 'none',
  },
});
