import {Box, HBox} from '../../components/core/view';
import {H4Text, H6Text} from '../../components/core/text';
import React, {useEffect, useRef} from 'react';
import {ShiftByStore, ShiftCountByStore, Shifts} from '../types';
import {useColorThemeType, useFontTheme} from '../../appStyles/hooks/theme';

import {Analytics} from '../../config/analytics/analytics';
import {EventType} from '../../config/analytics/type';
import If from '../../components/conditional/If';
import StoreLogo from '../../common/components/StoreLogo';
import {StyleSheet} from 'react-native';

interface NextShiftInfoProps {
  shifts: Shifts[];
  shiftByStore: ShiftByStore;
  batchData: ShiftCountByStore[];
  index: number;
}

function NextShiftInfo(props: NextShiftInfoProps) {
  const {shifts, shiftByStore} = props;
  const defaultTheme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const eventRef = useRef(false);

  useEffect(() => {
    if (!eventRef.current)
      Analytics.eventWithProps('Todays Shifts', {
        type: EventType.page,
        storeName: shifts[shiftByStore.activeShiftIndex]?.store?.name,
        storeId: shifts[shiftByStore.activeShiftIndex]?.store?.id,
      });

    eventRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HBox
      style={[
        styles.mainContainer,
        {
          backgroundColor: defaultTheme?.background.yellow[200],
        },
      ]}>
      <StoreLogo
        storeLogoUrl={
          shifts[shiftByStore.activeShiftIndex]?.store?.storeLogoUrl
        }
      />
      <Box
        style={[
          styles.infoContainer,
          {
            backgroundColor: defaultTheme?.background.yellow[200],
          },
        ]}>
        <H4Text
          style={[
            styles.runName,
            {
              fontFamily: muliFont[900].normal,
              color: defaultTheme?.text.blue[300],
            },
          ]}>
          {shifts[shiftByStore.activeShiftIndex]?.store?.name}
        </H4Text>
        <HBox
          style={[
            {
              backgroundColor: defaultTheme?.background.yellow[200],
            },
          ]}>
          <H6Text
            style={[
              styles.runSubtext,
              {
                fontFamily: muliFont[700].normal,
                color: defaultTheme?.text.blue[300],
              },
            ]}>
            {shifts[shiftByStore.activeShiftIndex]?.time}{' '}
          </H6Text>
          <If condition={shiftByStore?.batches}>
            <H6Text
              style={[
                styles.dotSeparator,
                {
                  fontFamily: muliFont[900].normal,
                  color: defaultTheme?.text.blue[300],
                },
              ]}>
              •
            </H6Text>
            <H6Text
              style={[
                styles.runSubtext,
                {
                  fontFamily: muliFont[700].normal,
                  color: defaultTheme?.text.blue[300],
                },
              ]}>
              {' '}
              {shiftByStore?.batches} Batches
            </H6Text>
          </If>
        </HBox>
      </Box>
    </HBox>
  );
}

export default React.memo(NextShiftInfo);

const styles = StyleSheet.create({
  mainContainer: {
    justifyContent: 'flex-start',
  },
  storeLogo: {
    borderRadius: 50,
    height: 50,
    width: 50,
    overflow: 'hidden',
  },
  storeLogoBox: {
    borderWidth: 3,
    borderRadius: 50,
  },
  infoContainer: {
    marginLeft: 10,
  },
  runName: {
    textAlign: 'left',
    // cursor: 'default',
  },
  dotSeparator: {
    // marginTop: -10,
  },
  runSubtext: {
    textAlign: 'left',
    // cursor: 'default',
  },
});
