import * as React from 'react';

import {SvgProps, Path} from 'react-native-svg';
import {memo} from 'react';
import Icon from './Icon';
import {IconProps} from './type';

const SupportIcon: React.FC<IconProps> = (props: SvgProps) => (
  <Icon {...props}>
    <Path
      d="M15.688 1.843h-12a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-12a2 2 0 0 0-2-2Z"
      stroke="#B6BAC3"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={'#fff'}
    />
    <Path
      d="M4.688 5.843v3m3-4v6m4-6v6m3-5v3m-10 4c4 2.667 6 2.667 10 0"
      stroke="#B6BAC3"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={'#fff'}
    />
  </Icon>
);

export default memo(SupportIcon);
