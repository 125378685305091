import React, {useMemo} from 'react';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {Box} from '../../../components/core/view';
import {H3Text} from '../../../components/core/text';
import PearIcon from '../../../../src/components/core/icon/PearIcon';
import {StyleSheet} from 'react-native';
import {isWeb} from '@buncha/utils/common';

const EmptyDoneSection = () => {
  const mortiseFont = useFontTheme('mortise');
  const theme = useColorThemeType();

  const viewBox = useMemo(() => {
    if (isWeb()) return '-3 20 130 130';
    return '-3 4.5 125 10';
  }, []);
  return (
    <Box style={[styles.mainContainer]}>
      <PearIcon
        height={180}
        width={120}
        fill={theme?.background.blue[300]}
        viewBox={viewBox}
      />
      <H3Text
        style={[
          styles.text,
          {
            fontFamily: mortiseFont[700].normal,
            color: theme?.text.blue[300],
          },
        ]}>
        No items have been picked
      </H3Text>
    </Box>
  );
};

export default React.memo(EmptyDoneSection);

export const styles = StyleSheet.create({
  mainContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  text: {
    marginTop: 5,
  },
});
