/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * Generated with the TypeScript template
 * https://github.com/react-native-community/react-native-template-typescript
 *
 * @format
 */

import MainModule from './MainModule';
import {NetworkConfiguration} from './config/graphQL/type';
import React from 'react';

const App: React.FC<{networkConfig: NetworkConfiguration}> = props => {
  return <MainModule networkConfig={props.networkConfig} />;
};
export default App;
