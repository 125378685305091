import {Dimensions, StyleSheet} from 'react-native';
import React, {useCallback, useState} from 'react';

import {Analytics} from '../../../../../src/config/analytics/analytics';
import {Box} from '../../../../../src/components/core/view';
import DeliveryFailedBottomSheet from './deliveryFailedBottomSheet';
import {DeliveryLocation} from '../../../../../src/driver/types/orderDetails';
import {DeliveryLocationStatus} from '../../../../../src/driver/types/common';
import {DriverScreens} from '../../../../../src/driver/navigation/type';
import {EventType} from '@buncha/config/analytics/type';
import {HomeScreens} from '../../../../../src/home/navigation/types';
import If from '../../../../../src/components/conditional/If';
import {PButton} from '../../../../../src/components/core/Button';
import {SpinnerComponent} from '../../../../components/core/loader';
import {getWidth} from '../../../../../src/utils/common';
import {navigation} from '../../../../../src/config/navigation';
import {useColorThemeType} from '../../../../../src/appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';
import {useSetDeliveryLocationStatusForDriver} from '../../../../../src/driver/hooks/orderDetails';

interface DeliveryButtonComponentProps {
  deliveryLocation: DeliveryLocation | undefined;
  orderId: string;
}

const DeliveryButtonComponent: React.FC<
  DeliveryButtonComponentProps
> = props => {
  const theme = useColorThemeType('main');
  const routeParams: any = useRoute().params;
  const [open, setOpen] = useState(false);
  const deliveryLocation = props?.deliveryLocation;
  const deliveryStatus = deliveryLocation?.deliveryStatus;
  const deliveryLocationId = deliveryLocation?.id || 0;
  const runId = deliveryLocation?.runId || 0;
  const orderId = Number(props?.orderId) || 0;
  const {setDeliveryLocationStatusForDriver, loading} =
    useSetDeliveryLocationStatusForDriver();
  const toggleSheet = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const changeStatusToOnTheWay = useCallback(async () => {
    if (deliveryLocationId)
      await setDeliveryLocationStatusForDriver(
        deliveryLocationId,
        DeliveryLocationStatus.onTheWay,
        runId,
      );
    Analytics.eventWithProps('Send on the way', {
      type: EventType.cta,
      orderId: orderId,
      deliveryStatus: DeliveryLocationStatus.onTheWay,
      userType: 'driver',
    });
  }, [deliveryLocationId, orderId, runId, setDeliveryLocationStatusForDriver]);

  const navigateToDeliveryDetailsPage = useCallback(() => {
    Analytics.eventWithProps('Navigate To Delivery Details Page', {
      type: EventType.cta,
      orderId: orderId,
      userType: 'driver',
    });
    navigation.navigate(HomeScreens.Driver, {
      screen: DriverScreens.DeliveryDetails,
      params: {orderId: orderId, tutorial: routeParams?.tutorial, runId: runId},
    });
    Analytics.eventWithProps('Navigate to delivery details page', {
      type: 'CTA',
      orderId: orderId,
      userType: 'driver',
    });
  }, [orderId, routeParams?.tutorial, runId]);
  return (
    <React.Fragment>
      <Box
        style={[
          styles.parentBox,
          {
            borderColor: theme?.text?.gray?.[200],
            backgroundColor: theme?.background?.white?.[100],
          },
        ]}>
        <If condition={deliveryStatus === DeliveryLocationStatus.onTheWay}>
          <PButton
            onPress={toggleSheet}
            style={[styles.topCTA, {borderColor: theme?.text?.red?.[100]}]}
            bgColor={theme?.background?.white?.[100]}
            title={'Delivery Failed'}
            titleColor={theme?.text?.red?.[100]}
          />
          <PButton
            style={styles.bottomCTA}
            title={'Mark As Delivered'}
            onPress={navigateToDeliveryDetailsPage}
          />
        </If>
        <If
          condition={deliveryStatus === DeliveryLocationStatus.driverAssigned}>
          <PButton
            style={styles.bottomCTA}
            title={'Send on the way'}
            onPress={changeStatusToOnTheWay}
            disabled={loading}
            isLoading={loading}
            disabledBgColor={theme?.background?.green?.[200]}
            loadingIndicator={<SpinnerComponent />}
          />
        </If>
      </Box>
      <DeliveryFailedBottomSheet
        runId={runId}
        deliveryLocationId={deliveryLocationId}
        open={open}
        onClose={toggleSheet}
      />
    </React.Fragment>
  );
};

export default React.memo(DeliveryButtonComponent);

const styles = StyleSheet.create({
  parentBox: {
    paddingTop: 15,
    borderTopWidth: 1,
    // position: 'absolute',
    // bottom: 0,
    width: getWidth(Dimensions?.get('window')?.width),
  },
  topCTA: {
    marginBottom: 20,
    width: '90%',
    alignSelf: 'center',
    borderRadius: 50,
    borderWidth: 1,
  },
  bottomCTA: {
    marginBottom: 20,
    width: '90%',
    alignSelf: 'center',
    borderRadius: 50,
  },
});
