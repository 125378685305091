import React, {useCallback} from 'react';

import {Avatar} from 'native-base';
import {Box} from '@buncha/components/core/view';
import BunchaWithoutName from '@buncha/components/core/icon/BunchaWithoutName';
import {CustomFlatList} from '@buncha/components/core/list';
import {HomeScreens} from '@buncha/home/navigation/types';
import If from '@buncha/components/conditional/If';
import {MainScreens} from '@buncha/navigation/types';
import PlayIcon from '@buncha/components/core/icon/PlayIcon';
import {Touchable} from '@buncha/components/core/touchable';
import {isImage} from '@buncha/memos/util';
import {navigation} from '@buncha/config/navigation';
import {styles} from './style';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';

interface Props {
  mediaUrls: string[];
  memoReadData: Record<string, boolean>;
  isMemoRead: boolean;
}

const Separator = () => {
  return <Box style={[styles.divider]} />;
};

const Attachments = (props: Props) => {
  const {mediaUrls, memoReadData, isMemoRead} = props;
  const theme = useColorThemeType();
  const routeParams: any = useRoute().params;
  const memoId = routeParams?.memoId;

  const navigateToMedia = useCallback(
    (index?: number) => {
      navigation.navigate(MainScreens.Home, {
        screen: HomeScreens.AttachmentsPage,
        params: {
          mediaUrls,
          index,
          memoId,
        },
      });
    },
    [mediaUrls, memoId],
  );

  const renderItem = useCallback(
    ({item, index}: {item: string; index: number}) => {
      const isRead = memoReadData?.[item] || isMemoRead;
      return (
        <Touchable
          onPress={() => navigateToMedia(index)}
          style={[
            styles.mediaPill,
            {
              borderColor: isRead
                ? theme?.text.gray[200]
                : theme?.background.blue[100],
            },
          ]}>
          <Box style={styles.avatarContainer}>
            <If condition={isImage(item)}>
              <Avatar
                style={[
                  styles.mediaAvatar,
                  {borderColor: theme?.text.white[100]},
                ]}
                rounded={'2xl'}
                size={'lg'}
                source={{
                  uri: item,
                }}
              />
            </If>
            <If condition={!isImage(item)}>
              <BunchaWithoutName
                width={45}
                height={45}
                fill={'red'}
                viewBox="0 0 120 120"
                style={styles.iconStyle}
              />
              <PlayIcon style={styles.playIcon} />
            </If>
          </Box>
        </Touchable>
      );
    },
    [
      isMemoRead,
      memoReadData,
      navigateToMedia,
      theme?.background.blue,
      theme?.text.gray,
      theme?.text.white,
    ],
  );

  return (
    <Box style={styles.title}>
      {mediaUrls?.length > 0 && (
        <CustomFlatList
          horizontal={true}
          data={mediaUrls}
          ItemSeparatorComponent={Separator}
          renderItem={renderItem}
          style={styles.width100}
          showsHorizontalScrollIndicator={false}
        />
      )}
    </Box>
  );
};

export default React.memo(Attachments);
