import {
  ADD_NOTE_FOR_DELIVERY_LOCATION,
  DELETE_DELIVERY_IMAGE_FOR_DRIVER,
  SET_DELIVERY_LOCATION_STATUS,
  UPLOAD_DELIVERY_IMAGE,
} from '../../../src/config/graphQL/documentNode/mutation';
import {
  AddDeliveryNoteResponse,
  GetOrderForDeliveryDetailsResponse,
  UploadDeliveryLocationImageResponse,
} from '../../../src/config/graphQL/type';
import {DeliveryDetailsContext, initialState} from '../context/deliveryDetails';
import {
  DeliveryImageType,
  OrderForDeliveryDetails,
  State,
} from '../types/deliveryDetails';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {useContext, useReducer, useState} from 'react';

import {Analytics} from '@buncha/config/analytics/analytics';
import {DeliveryLocationStatus} from '../types/common';
import {EventType} from '@buncha/config/analytics/type';
import {GET_ORDER_FOR_DELIVERY_DETAILS} from '../../../src/config/graphQL/documentNode/queries';
import {ToastType} from '../../components/composites/notification/type';
import {gqlService} from '../../../src/config/graphQL';
import {useNavigation} from '@react-navigation/native';
import {useToastMessage} from '../../../src/components/composites/notification';

const reducer = {
  setSigAdded: function (state: State, action: PayloadAction<string[]>) {
    state.deliveryImages = action.payload;
  },
};

const slice = createSlice({
  initialState,
  name: 'DeliveryDetailsContext',
  reducers: reducer,
});

export function useDeliveryDetailsReducer() {
  const reducerInfo = useReducer(slice.reducer, initialState);
  return reducerInfo;
}

export function useDeliveryDetailsContext() {
  return useContext(DeliveryDetailsContext);
}

export const DeliveryDetailsAction = slice.actions;

export function useGetDeliveryDetails(dispatch: any) {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loading, setLoading] = useState(true);
  const [deliveryData, setDeliveryData] = useState<OrderForDeliveryDetails>();
  const getDeliveryDetails = async (orderId: string, runId: number) => {
    try {
      setLoading(true);
      const res = await gqlService?.query<GetOrderForDeliveryDetailsResponse>({
        query: GET_ORDER_FOR_DELIVERY_DETAILS,
        fetchPolicy: 'network-only',
        variables: {
          orderId: orderId,
          runId: runId,
        },
      });
      const deliveryInfo = res?.data?.getOrder;
      const images = deliveryInfo?.deliveryLocation?.deliveryImages || [];
      if (dispatch) dispatch(DeliveryDetailsAction?.setSigAdded(images));
      setDeliveryData(deliveryInfo);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {getDeliveryDetails, deliveryData, loading};
}

export function useUploadSignatureAndPhotoIdImage() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const nav = useNavigation();
  const uploadSignatureAndPhotoIdImage = async (
    orderId: number,
    deliveryImageType: DeliveryImageType,
    file: {
      uri: string;
      base64: string;
    },
    runId: number,
  ) => {
    try {
      setLoading(true);
      const res =
        await gqlService?.mutation<UploadDeliveryLocationImageResponse>({
          mutation: UPLOAD_DELIVERY_IMAGE,
          fetchPolicy: 'network-only',
          variables: {
            orderId: orderId,
            deliveryImageType: deliveryImageType,
            file: file,
            runId,
          },
        });

      const uri = res?.data?.uploadDeliveryImage?.uri || '';
      setImageUrl(uri);
      if (uri && deliveryImageType === DeliveryImageType.signature)
        nav.goBack();
      if (deliveryImageType === DeliveryImageType.signature)
        Analytics.eventWithProps('Signature Added', {
          type: EventType.cta,
          orderId: Number(orderId),
          deliveryImageType: DeliveryImageType.signature,
          userType: 'driver',
        });
      if (deliveryImageType === DeliveryImageType.idProof)
        Analytics.eventWithProps('Id proof uploaded', {
          type: EventType.cta,
          userType: 'driver',
        });

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {uploadSignatureAndPhotoIdImage, loading, imageUrl};
}

export function useAddNotesForDeliveryLocation() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loading, setLoading] = useState(false);
  const [notesAdded, setNotesAdded] = useState('');
  const addNotesForDeliveryLocation = async (
    deliveryLocationId: number,
    note: string,
  ) => {
    try {
      setLoading(true);
      const res = await gqlService?.mutation<AddDeliveryNoteResponse>({
        mutation: ADD_NOTE_FOR_DELIVERY_LOCATION,
        fetchPolicy: 'network-only',
        variables: {
          deliveryLocationId: deliveryLocationId,
          note: note,
        },
      });
      const deliveryNote = res?.data?.addNoteForDeliveryLocation?.note || '';
      setNotesAdded(deliveryNote);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {addNotesForDeliveryLocation, loading, notesAdded};
}

export function useUploadDeliveryImage() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loading, setLoading] = useState(false);
  const {state, dispatch} = useDeliveryDetailsContext();
  const uploadDeliveryImage = async (
    orderId: number,
    file: {
      uri: string;
      base64: string;
    },
    runId: number,
  ) => {
    try {
      setLoading(true);
      const res =
        await gqlService?.mutation<UploadDeliveryLocationImageResponse>({
          mutation: UPLOAD_DELIVERY_IMAGE,
          fetchPolicy: 'network-only',
          variables: {
            orderId: orderId,
            deliveryImageType: DeliveryImageType.deliveryImage,
            file: file,
            runId,
          },
        });
      const imageUrl = res?.data?.uploadDeliveryImage?.uri || '';
      const images = [...state.deliveryImages, imageUrl];
      if (dispatch) dispatch(DeliveryDetailsAction?.setSigAdded(images));
      Analytics.eventWithProps('Photo uploaded', {
        type: 'CTA',
        userType: 'driver',
      });
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {uploadDeliveryImage, loading};
}

export function useDeleteDeliveryImage() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const {state, dispatch} = useDeliveryDetailsContext();
  const deleteDeliveryImage = async (
    deliveryLocationId: number,
    deliveryImageUrl: string,
  ) => {
    try {
      setDeleteLoading(true);
      const res = await gqlService?.mutation({
        mutation: DELETE_DELIVERY_IMAGE_FOR_DRIVER,
        fetchPolicy: 'network-only',
        variables: {
          deliveryLocationId: deliveryLocationId,
          deliveryImageUrl: deliveryImageUrl,
        },
      });
      const imageDeleted = res?.data?.deleteDeliveryImage;
      if (imageDeleted) {
        const newImageList = state?.deliveryImages.filter(
          image => image !== deliveryImageUrl,
        );
        if (dispatch)
          dispatch(DeliveryDetailsAction?.setSigAdded(newImageList));
      }
      Analytics.eventWithProps('Photo deleted', {
        type: 'CTA',
        userType: 'driver',
      });
      setDeleteLoading(false);
    } catch (err: any) {
      setDeleteLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {deleteDeliveryImage, deleteLoading};
}

export function useSetDeliveryLocationStatus() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loading, setLoading] = useState(false);
  const setOrderAsDelivered = async (
    deliveryLocationId: number,
    runId: number,
    status: DeliveryLocationStatus,
  ) => {
    try {
      setLoading(true);
      const res = await gqlService?.mutation<{
        setDeliveryLocationStatus: {
          id: number;
        };
      }>({
        mutation: SET_DELIVERY_LOCATION_STATUS,
        fetchPolicy: 'network-only',
        variables: {
          deliveryLocationId: deliveryLocationId,
          status: status,
          runId: runId,
        },
      });
      setLoading(false);
      const id = res?.data?.setDeliveryLocationStatus?.id || 0;
      return id;
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {setOrderAsDelivered, loading};
}
