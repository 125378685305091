import {HomeScreens, ShopperScreens} from '../../home/navigation/types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ReceiptBypassMode, RunForBatchDetail, itemsSortBy} from '../types';
import {
  batchDetailActionsLocal,
  useBatchDetailReducer,
} from '../hooks/batchDetail';
import {useIsFocused, useRoute} from '@react-navigation/native';

import {Analytics} from '../../config/analytics/analytics';
import {ApiState} from '../../common/types/common';
import AssignmentBottmSheet from '../component/AssignmentBottomSheet/AssignmentBottmSheet';
import {BackHeaderLeft} from '../../common/components/BackHeaderLeft';
import {BatchDetailContext} from '../context/BatchDetailContext';
import BatchDetailHeader from '../component/BatchDetailHeader';
import BatchScreenComponent from '../components/BatchScreenComponent/BatchScreenComponent';
import BottomSheet from '../../components/composites/bottomSheet';
import ContactBottomSheet from '../../itemDetails/components/ItemDetail/contactBottomSheet';
import CustomModal from '../../common/components/CustomModal/CustomModal';
import {EventType} from '../../config/analytics/type';
import Header from '../../components/composites/Header/Header';
import HeaderRightSection from '../component/HeaderRightSection';
import If from '../../components/conditional/If';
import {MainScreens} from '../../navigation/types';
import PackItemList from '../component/PackItemList';
import PassOrderModal from '../component/PassOrderModal/PassOrderModal';
import {SpinnerComponent} from '../../components/core/loader';
import {StyleSheet} from 'react-native';
import listener from '../../config/listener';
import {navigation} from '../../config/navigation';
import {useAppSelector} from '../../config/redux/hooks';
import {useColorThemeType} from '../../appStyles/hooks/theme';
import {useGetRunForBatchDetail} from '../hooks/BatchDetails';

function OrderPackingPage() {
  const theme = useColorThemeType();
  const [state, dispatch] = useBatchDetailReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);

  const [open, setOpen] = useState(false);
  const [hasNavigateToBack, setHasNavigateToBack] = useState(false);
  const [getRunForBatchDetail, apiRunState] = useGetRunForBatchDetail();
  const [runState, setRunState] = useState<ApiState<RunForBatchDetail>>({
    loading: false,
    error: undefined,
    data: undefined,
  });
  const [openBottomSheet, setShowBottomSheet] = useState<boolean>(false);
  const [showAssignmentModal, setShowAssignementModal] =
    useState<boolean>(false);
  const globalState = useAppSelector(gstate => gstate.home);
  const shiftsId = globalState.batchShiftId;
  const shiftKey = globalState.batchShiftKey;

  const isFocused = useIsFocused();

  const route = useRoute();

  const routeParams: any = route?.params;
  const [runId, setRunId] = useState(routeParams?.runId);

  const contactBottomSheetProps = state.contactSheetProps;
  const tutorial = state.tutorial;

  const navigateToReceiptScreen = useCallback(() => {
    setOpen(true);
    Analytics.eventWithProps('Show shopper receipt modal', {
      type: EventType.cta,
    });
  }, []);

  useEffect(() => {
    if (runState?.data) setRunId(runState?.data?.id);
  }, [runState?.data]);

  const assignmentModal = useCallback((value: boolean) => {
    setShowAssignementModal(value);
  }, []);

  const closeModal = useCallback(() => {
    if (dispatch)
      dispatch(
        batchDetailActionsLocal.setSkippedReceiptFlow(
          ReceiptBypassMode.skipByShopper,
        ),
      );
    if (runId) getRunForBatchDetail(runId);
    setOpen(false);
  }, [dispatch, getRunForBatchDetail, runId]);

  const goBack = useCallback(() => {
    if (tutorial)
      return navigation.navigate(MainScreens.Home, {
        screen: HomeScreens.Tutorials,
      });
    navigation.navigate(MainScreens.Home, {
      screen: HomeScreens.AboutShift,
      params: {
        shiftId: shiftsId,
        shiftKey: shiftKey,
      },
    });
  }, [shiftKey, shiftsId, tutorial]);

  const skipReceiptFlowHandler = useCallback(() => {
    if (dispatch) {
      dispatch(
        batchDetailActionsLocal.setSkippedReceiptFlow(
          ReceiptBypassMode.skipByShopper,
        ),
      );
      closeModal();
    }
  }, [closeModal, dispatch]);

  const renderComponent = useMemo(() => {
    if (open)
      return (
        <BatchScreenComponent
          isOpen={open}
          onClose={closeModal}
          runId={runId}
          hasSkip={state?.hasSkipReceipt}
          hasSkipReceiptHandler={skipReceiptFlowHandler}
        />
      );
  }, [closeModal, open, runId, skipReceiptFlowHandler, state?.hasSkipReceipt]);

  const closeContactSheet = useCallback(() => {
    if (dispatch)
      dispatch(batchDetailActionsLocal.setContactBottomSheetProps(undefined));
  }, [dispatch]);

  const refreshRun = useCallback(() => {
    if (runId) getRunForBatchDetail(runId);
  }, [getRunForBatchDetail, runId]);

  const closeAssignmentModal = useCallback(() => {
    setShowAssignementModal(false);
    setShowBottomSheet(true);
  }, []);

  const navigateToCheckTask = useCallback(() => {
    dispatch(batchDetailActionsLocal.setPickedOrderFoundModal(false));
    setHasNavigateToBack(true);
  }, [dispatch]);

  useEffect(() => {
    if (hasNavigateToBack && !state.hasPickedOrderModal) {
      navigation.replace(HomeScreens.Shopper, {
        screen: ShopperScreens.BatchDetailsPage,
        params: {runId: runId},
      });
      setHasNavigateToBack(false);
    }
  }, [hasNavigateToBack, runId, state.hasPickedOrderModal]);

  const closeBottomSheet = useCallback(() => {
    setShowBottomSheet(false);
  }, []);

  useEffect(() => {
    if (routeParams.tutorial === 'true' && dispatch)
      dispatch(batchDetailActionsLocal.setTutorial(true));
    const itemStatusCount = listener.addListener(
      'orderPackRefresh',
      refreshRun,
    );
    return itemStatusCount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (runId) getRunForBatchDetail(runId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId, isFocused]);

  useEffect(() => {
    if (apiRunState.data) setRunState(apiRunState);
  }, [apiRunState]);

  useEffect(() => {
    if (runId && dispatch) {
      Analytics.eventWithProps('Batch Details Screen', {
        type: EventType.page,
        runID: runId,
        storeName: runState?.data?.storeName,
        status: 'In Packing Status',
      });
      dispatch(batchDetailActionsLocal.setSortBarValue(itemsSortBy.customer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId]);

  return (
    <Header
      style={styles.parentBox}
      headerLeftComponent={<BackHeaderLeft onClick={goBack} />}
      headerRightComponent={
        <HeaderRightSection
          navigateToReceiptScreen={navigateToReceiptScreen}
          runId={runState.data?.id || 0}
        />
      }
      headerColor={theme?.background.blue[300]}>
      <BatchDetailContext.Provider value={provider}>
        <If condition={runState.loading && !showAssignmentModal}>
          <SpinnerComponent style={styles.loaderStyle} />
        </If>
        <If condition={!runState.loading || showAssignmentModal}>
          <If condition={runState.data}>
            <BatchDetailHeader
              showRunPill={true}
              runDetail={runState.data ?? undefined}
            />
          </If>
          <PackItemList
            runDetail={runState.data ?? undefined}
            navigateToReceiptScreen={navigateToReceiptScreen}
            assignmentModal={assignmentModal}
          />
          <BottomSheet
            bgColor={theme?.background.white[100]}
            open={openBottomSheet}
            onClose={() => {
              closeBottomSheet();
            }}>
            <AssignmentBottmSheet
              tutorial={tutorial}
              runId={runId}
              closeSheet={() => closeBottomSheet()}
              dispatch={dispatch}
              orderIds={state.orderIdsToBeSend}
            />
          </BottomSheet>
        </If>
        {renderComponent}
        <If condition={state?.hasPickedOrderModal}>
          <CustomModal
            open={state?.hasPickedOrderModal}
            onClose={() => {
              dispatch(batchDetailActionsLocal.setPickedOrderFoundModal(false));
            }}>
            <PassOrderModal
              onContinueClick={() => navigateToCheckTask()}
              title={'Action Required!'}
              ctaTitle={'Check Task'}
              subtitle={
                'Looks like the dispatcher team has assigned more orders to you. Please go back and check the newly assigned tasks in this delivery.'
              }
            />
          </CustomModal>
        </If>
        <If condition={state?.orderAssignee && showAssignmentModal}>
          <CustomModal
            open={showAssignmentModal}
            onClose={() => closeAssignmentModal()}>
            <PassOrderModal
              onContinueClick={() => closeAssignmentModal()}
              title={'Order Assigned'}
              ctaTitle={'Continue'}
              subtitle={state.orderAssignee}
            />
          </CustomModal>
        </If>

        <If condition={contactBottomSheetProps}>
          <ContactBottomSheet
            isOpen={Boolean(contactBottomSheetProps)}
            onClose={closeContactSheet}
            showSMSTemplates={true}
            type={contactBottomSheetProps?.type}
            buttonText={contactBottomSheetProps?.buttonText}
            onButtonClick={contactBottomSheetProps?.onButtonClick}
            orderId={contactBottomSheetProps?.orderId}
            orderItemId={contactBottomSheetProps?.orderItemId}
            runId={runId}
          />
        </If>
      </BatchDetailContext.Provider>
    </Header>
  );
}

export default React.memo(OrderPackingPage);

const styles = StyleSheet.create({
  loaderStyle: {
    marginTop: '50%',
  },
  parentBox: {
    flex: 1,
  },
});
