import {IconProps} from './type';
import React from 'react';
import Svg from 'react-native-svg';
import {getWidth} from '../../../utils/common';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

const Icon: React.FC<IconProps> = props => {
  const theme = useColorThemeType();

  const propsToSpread = {
    width: Number(getWidth(25)),
    height: 30,
    viewBox: '0 0 30 30',
    fill: theme?.icon.gray[200],
    ...props,
  };
  return <Svg {...propsToSpread}>{props.children}</Svg>;
};

export default Icon;
