// eslint-disable-next-line no-shadow
export enum CustomItemMode {
  addItem = 'addItem',
  substituteItem = 'substituteItem',
}

export interface AddItemParams {
  orderId: number;
  name: string;
  price: number;
  priceWithTax: number;
  quantity: number;
  isCustomItem: boolean;
  itemImageUrl: string;
  catalogItemId: number | null;
  tags: string;
  position: number;
  options?: string;
  itemUnit?: string;
  source?: string;
  priceSensitive: boolean;
  weightSensitive: boolean;
}

export interface OrderUserDetails {
  buyer: {
    firstName: string;
  };
}

export interface GetOrderUserDetailsResponse {
  getOrder: OrderUserDetails;
}
