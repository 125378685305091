import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  headerContainer: {justifyContent: 'center', alignItems: 'center'},
  imageSkeleton: {width: 250, height: 250, borderRadius: 10},
  textHeader: {width: 220, height: 20, borderRadius: 5},
  textSubHeader: {width: 180, height: 20, borderRadius: 5},
  iconContainer: {width: 20, height: 20, borderRadius: 5},
  listText: {width: '80%', height: 20, borderRadius: 5, marginLeft: 10},
  listContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 40,
    marginHorizontal: 20,
  },
  listItem: {marginBottom: 15},
  divider: {height: 1},
});
