import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {CustomTextProps} from './type';
import Font from '../../../appStyles/style/font';
import React from 'react';
import {Text} from 'react-native';

const CustomText: React.FC<CustomTextProps<Text>> = React.forwardRef(
  (props, ref) => {
    const fontTheme = useFontTheme(props.fontTheme || 'muli');
    const colorTheme = useColorThemeType();
    const defaultStyle = {
      fontFamily: fontTheme[400][props.fontThemeType || 'normal'],
      ...Font.FontSize.fontP12,
      // cursor: 'default',
      textAlign: props.alignText || 'center',
      color: colorTheme?.text.gray[100],
      // backgroundColor: colorTheme?.background[100],
    };
    const propsToSpread = {
      ...props,
      style: [defaultStyle, props.style],
    };

    const loadingSkeleton = props.loadingSkeleton;
    if (props.loading)
      return <React.Fragment>{loadingSkeleton}</React.Fragment>;
    return (
      <Text ref={ref} {...propsToSpread} allowFontScaling={false}>
        {props.children}
      </Text>
    );
  },
);

export default React.memo(CustomText);
