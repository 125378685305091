import 'react-native-get-random-values';
import 'react-native-url-polyfill/auto';

import {Bucket, UploadingStatus} from './type';
import {DeleteUploadedImageData, UpdateUploadingStatus} from './hook';
import {Progress, Upload} from '@aws-sdk/lib-storage';
import {PutObjectCommandInput, S3Client} from '@aws-sdk/client-s3';
import {
  RunReceiptPrefixURL,
  awsRegion,
  upcImagePrefixURL,
} from '../utils/constants';

import {Buffer} from 'buffer';
import {Config} from '@buncha/utils/types';
import {fromCognitoIdentityPool} from '@aws-sdk/credential-providers';
import {getConfig} from '@buncha/utils/appInfo';

class FileUploadService {
  private readonly client: S3Client;

  constructor() {
    this.client = new S3Client({
      region: awsRegion,
      credentials: fromCognitoIdentityPool({
        identityPoolId: getConfig(Config.awsIncognitoIdentityPoolId),
        clientConfig: {region: awsRegion},
      }),
    });
  }

  async uploadService(
    params: PutObjectCommandInput,
    runId: number,
    fileName: string,
  ) {
    try {
      const upload = new Upload({
        client: this.client,
        params: params,
      });

      upload.on('httpUploadProgress', async (progress: Progress) => {
        if (progress) {
          const loaded = progress?.loaded || 0;
          const total = progress?.total || 1;

          const percentage =
            total > 0 ? Number(((loaded / total) * 100).toFixed(2)) : 0;
          if (loaded < total)
            await UpdateUploadingStatus(
              runId,
              fileName,
              UploadingStatus.inprogress,
              percentage,
            );
          else if (loaded === total)
            await DeleteUploadedImageData(runId, fileName);
        }
      });
      const res = upload.done().catch(async () => {
        await UpdateUploadingStatus(runId, fileName, UploadingStatus.failed, 0);
      });
      if (res) return res;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error in uploadService for ${fileName}:`, error);
      await UpdateUploadingStatus(runId, fileName, UploadingStatus.failed, 0);
    }
  }

  uploadUpcImages(
    fileName: string,
    base64: string,
    storeIndex: string,
    runId: number,
  ) {
    try {
      const buffer = Buffer.from(base64, 'base64');

      const params: PutObjectCommandInput = {
        Bucket: Bucket.upcImages,
        Key: storeIndex + '/' + fileName,
        Body: buffer,
      };

      this.uploadService(params, runId, fileName);
      const url = `${upcImagePrefixURL}/${
        storeIndex + '/' + encodeURIComponent(fileName)
      }`;
      return url;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error in uploadUpcImages for ${fileName}:`, error);
    }
  }

  uploadRunReceipt(fileName: string, base64: string, runId: number) {
    try {
      const buffer = Buffer.from(base64, 'base64');
      const params = {
        Bucket: Bucket.orderImage,
        Key: 'run-receipts' + '/' + fileName,
        Body: buffer,
      };

      this.uploadService(params, runId, fileName);
      const url = `${RunReceiptPrefixURL}/${
        'run-receipts' + '/' + encodeURIComponent(fileName)
      }`;
      return url;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error in uploadRunReceipt for ${fileName}:`, error);
    }
  }
}

export default FileUploadService;
