import React, {useCallback, useMemo} from 'react';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../appStyles/hooks/theme';

import AddSignature from '../../../../../src/components/core/icon/AddSignature';
import {Analytics} from '@buncha/config/analytics/analytics';
import CheckIcon from '../../../../../src/components/core/icon/CheckIcon';
import {CustomText} from '../../../../components/core/text';
import {DriverScreens} from '../../../../../src/driver/navigation/type';
import {HomeScreens} from '../../../../../src/home/navigation/types';
import If from '../../../../../src/components/conditional/If';
import {StyleSheet} from 'react-native';
import {Touchable} from '../../../../components/core/touchable';
import {navigation} from '../../../../../src/config/navigation';
import {EventType} from '@buncha/config/analytics/type';

interface AddSignatureCellProps {
  orderId: string;
  signatureUrl: string;
  runId: number;
}

const AddSignatureCell: React.FC<AddSignatureCellProps> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const orderId = Number(props?.orderId) || 0;
  const runId = Number(props?.runId) || 0;
  const signatureUrl = Boolean(props?.signatureUrl) || false;

  const getCellText = useMemo(() => {
    if (signatureUrl) return 'Signature added';
    return 'Add signature';
  }, [signatureUrl]);

  const navigateToSignature = useCallback(() => {
    Analytics.eventWithProps('Navigate to Signature screen', {
      type: EventType.cta,
      orderId: orderId,
      userType: 'driver',
    });
    navigation.navigate(HomeScreens.Driver, {
      screen: DriverScreens.Signature,
      params: {orderId: orderId, runId: runId},
    });
  }, [orderId, runId]);

  return (
    <Touchable
      style={styles.parentBox}
      disabled={signatureUrl}
      onPress={navigateToSignature}>
      <If condition={!signatureUrl}>
        <AddSignature
          height={36}
          width={36}
          fill={theme?.icon?.blue?.[300]}
          style={styles.icon}
          viewBox="-1 2 39 30"
        />
      </If>
      <If condition={signatureUrl}>
        <CheckIcon
          height={36}
          width={36}
          fill={theme?.icon?.blue?.[300]}
          style={styles.icon}
          viewBox="-2 3 44 30"
        />
      </If>

      <CustomText
        style={[
          styles.text,
          {color: theme?.text?.blue?.[300], fontFamily: muli?.[900]?.normal},
        ]}>
        {getCellText}
      </CustomText>
    </Touchable>
  );
};

export default React.memo(AddSignatureCell);

const styles = StyleSheet.create({
  parentBox: {
    borderWidth: 1,
    flexGrow: 1,
    justifyContent: 'center',
    paddingVertical: 15,
    borderRadius: 8,
    marginLeft: 5,
  },
  text: {
    marginTop: 5,
  },
  icon: {
    alignSelf: 'center',
  },
});
