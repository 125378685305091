import {Box} from '../../../src/components/core/view';
import Header from '../../../src/components/composites/Header/Header';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React from 'react';
import ScreenHeader from '../../common/components/screenHeader';
import SignatureComponent from '../components/signature/signatureComponent';
import {StyleSheet} from 'react-native';
import {isWeb} from '../../../src/utils/common';
import {useColorThemeType} from '../../../src/appStyles/hooks/theme';

type Props = NativeStackScreenProps<any, any>;

const Signature: React.FC<Props> = props => {
  const theme = useColorThemeType('main');
  const isWebMode = isWeb();
  const orderId = Number(props?.route?.params?.orderId) || 0;
  const runId = Number(props?.route?.params?.runId) || 0;

  return (
    <Header
      hideBottomSafeArea
      headerColor={theme?.background?.blue?.[300]}
      headerLeftComponent={isWebMode && <Box style={styles.webHeader} />}
      showDefaultLeftHeader>
      <ScreenHeader title="Add Signature" />
      <SignatureComponent orderId={orderId} runId={runId} />
    </Header>
  );
};
export default Signature;
const styles = StyleSheet.create({
  webHeader: {
    padding: 30,
  },
});
