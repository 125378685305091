import {
  DriverItem,
  RunInfo,
} from '../../../../../src/driver/types/orderDetails';
import React, {useCallback} from 'react';

import {Box} from '../../../../../src/components/core/view';
import {CustomFlatList} from '../../../../../src/components/core/list';
import {Divider} from 'native-base';
import ItemCellForDriver from './itemCellForDriver';
import OrderInfoHeader from './orderInfoHeader';
import {StyleSheet} from 'react-native';

interface OrderInfoProps {
  runInfo: RunInfo | undefined;
  orderItems: DriverItem[];
}

const OrderInfo: React.FC<OrderInfoProps> = props => {
  const orderItems = props?.orderItems || [];
  const renderItem = useCallback((orderItem: {item: DriverItem}) => {
    return <ItemCellForDriver itemInfo={orderItem?.item} />;
  }, []);

  const separator = useCallback(() => <Divider />, []);

  return (
    <Box style={styles.parentBox}>
      <OrderInfoHeader runInfo={props?.runInfo} />
      <CustomFlatList
        renderItem={renderItem}
        data={orderItems}
        ItemSeparatorComponent={separator}
      />
    </Box>
  );
};

export default React.memo(OrderInfo);

const styles = StyleSheet.create({
  parentBox: {
    marginVertical: 10,
  },
});
