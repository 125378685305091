import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  inputContainer: {
    borderWidth: 1,
    height: 51,
    width: '100%',
    borderRadius: 50,
    marginTop: 10,
    marginBottom: 20,
  },
  divider: {
    marginVertical: 20,
  },
  textStyle: {
    textAlign: 'left',
  },
});
