import {Animated, Image, StyleSheet, View} from 'react-native';
import React, {Component} from 'react';

export default class CustomLoader extends Component {
  loadingSpin: any;

  constructor(props: any) {
    super(props);
    this.loadingSpin = new Animated.Value(0);
  }

  spinAnimation() {
    this.loadingSpin.setValue(0);
    Animated.sequence([
      Animated.timing(this.loadingSpin, {
        toValue: 1,
        duration: 1500,
        useNativeDriver: false,
      }),
    ]).start(() => this.spinAnimation());
  }

  componentDidMount() {
    this.spinAnimation();
  }

  render() {
    const spin = this.loadingSpin.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg'],
    });

    return (
      <View>
        <Animated.Image
          style={[style.loaderParent, {transform: [{rotate: spin}]}]}
          source={require('../../../resource/images/Loader.png')}
        />
        <Image
          style={[style.image]}
          source={require('../../../resource/images/banana.png')}
        />
      </View>
    );
  }
}

const style = StyleSheet.create({
  image: {
    zIndex: -1,
    width: 64,
    height: 64,
    margin: 33,
    position: 'absolute',
  },
  loaderParent: {
    width: 130,
    height: 130,
  },
});
