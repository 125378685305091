import {
  BottomSheetProps,
  UPCItemLocationStatus,
} from '@buncha/itemDetails/types/ItemScanner';
import {Box, Divider} from 'native-base';
import {Dimensions, StyleSheet, TextInput, View} from 'react-native';
import {H3Text, H4Text} from '@buncha/components/core/text';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import font, {scaleFonts} from '@buncha/appStyles/style/font';
import {getWidth, isWeb} from '@buncha/utils/common';

import {BottomButton} from '@buncha/components/composites/Button';
import BottomSheetInput from './BottomSheetInput';
import Fonts from '@buncha/resource/fonts';
import {SpinnerComponent} from '@buncha/components/core/loader';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const BottomSheetComponent = (props: BottomSheetProps) => {
  const {onSheetClose} = props;
  const [aisle, setaisle] = useState('');
  const [context, setcontext] = useState('');
  const aisleRef = useRef<TextInput>(null);
  const contextRef = useRef<TextInput>(null);
  const {itemLocationData} = useAppSelector(gstate => gstate.itemScanner);

  const width = Number(getWidth(Dimensions.get('screen').width));
  const theme = useColorThemeType('main');

  const onaisleChange = useCallback((val: string) => {
    setaisle(val);
  }, []);

  const getDisabledStatus = useMemo(() => {
    return !aisle.length || !context.length;
  }, [aisle.length, context.length]);

  const returnBorderColor = useMemo(() => {
    if (aisle.length) return theme?.text.blue[300];
    return theme?.text?.gray[200];
  }, [aisle.length, theme?.text.blue, theme?.text?.gray]);

  const returncontextBorderColor = useMemo(() => {
    if (context.length) return theme?.text.blue[300];
    return theme?.text?.gray[200];
  }, [context.length, theme?.text.blue, theme?.text?.gray]);

  const enterItemLocation = useCallback(() => {
    const locationData: UPCItemLocationStatus = {
      ...itemLocationData,
      locationDescription: {
        aisle: aisle,
        context: context,
      },
    };
    props?.onPress(locationData);
    onSheetClose();
  }, [aisle, context, itemLocationData, onSheetClose, props]);

  const contextHandler = useCallback((val: string) => {
    setcontext(val);
  }, []);

  const getButtonText = useMemo(() => {
    if (!aisle.length && !context.length) return 'Enter the category & shelf';
    return 'Verify';
  }, [aisle.length, context.length]);

  return (
    <Box style={{backgroundColor: theme?.text.white[100]}}>
      <Box style={[styles.headerContainer]}>
        <H3Text
          style={[
            styles.headerText,
            {
              color: theme?.text.blue[300],
              fontFamily: font.fonts['Mortise-X-Bold'],
            },
          ]}>
          Verify Item Location
        </H3Text>
        <H4Text style={[styles.subHeaderTypo]}>
          Enter the category and shelf
        </H4Text>
      </Box>

      <View style={[styles.inputContainer]}>
        <BottomSheetInput
          debounce={false}
          defaultValue={aisle}
          autoCorrect={false}
          colorThemeType="default"
          clearButtonMode="while-editing"
          autoFocus
          placeholder="Bakery, 325, A5"
          ref={aisleRef}
          clearInput={true}
          textValue={aisle}
          header="Enter the Aisle"
          containerStyle={[{borderColor: returnBorderColor}]}
          style={[
            {
              backgroundColor: theme?.background?.white[100],
              fontSize: scaleFonts(16),
              color: theme?.text?.blue[300],
            },
          ]}
          onChangeText={onaisleChange}
        />
        <Box style={[styles.dividerContainer]}>
          <Divider />
        </Box>
        <BottomSheetInput
          debounce={false}
          defaultValue={context}
          autoCorrect={false}
          colorThemeType="default"
          clearButtonMode="never"
          autoFocus
          placeholder="Near the Deli"
          ref={contextRef}
          clearInput={true}
          textValue={context}
          header="Location Notes"
          containerStyle={[{borderColor: returncontextBorderColor}]}
          style={[
            {
              backgroundColor: theme?.background?.white[100],
              fontSize: scaleFonts(16),
              color: theme?.text?.blue[300],
            },
          ]}
          onChangeText={contextHandler}
        />
        <BottomButton
          style={[
            styles.buttonContainer,
            {width: isWeb() ? width * 0.75 : width * 0.95},
          ]}
          title={getButtonText}
          disabled={getDisabledStatus}
          onPress={enterItemLocation}
          isLoading={false}
          loadingIndicator={<SpinnerComponent />}
        />
      </View>
    </Box>
  );
};

export default React.memo(BottomSheetComponent);

const styles = StyleSheet.create({
  modal: {
    height: 500,
    padding: 20,
  },
  headerText: {
    textAlign: 'left',
    fontSize: scaleFonts(22),
  },
  subHeaderTypo: {
    textAlign: 'left',
    marginTop: 5,
    fontSize: scaleFonts(15),
  },
  textInputContainer: {
    marginHorizontal: 10,
  },
  textInput: {
    backgroundColor: 'transparent',
    ...font.FontSize.fontH4,
  },
  errorMessageContainer: {
    top: 1,
  },
  errorTypo: {
    fontFamily: Fonts.muliSemiBold,
  },
  inputContainer: {
    marginTop: 20,
    // paddingBottom: 20,
  },
  buttonContainer: {
    marginBottom: 0,
  },
  eyeIcon: {
    marginRight: 5,
  },
  headerContainer: {
    marginLeft: 15,
  },
  dividerContainer: {
    marginTop: 5,
    marginBottom: 15,
  },
});
