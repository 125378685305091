import {DefaultContext} from '../../utils/types';
import React from 'react';
import {State} from '../types/itemReplacement';

export const initialState: State = {
  item: undefined,
  searchResults: [],
  showCustomItemCTA: false,
};

const defaultVal: DefaultContext<State> = {
  state: initialState,
  dispatch: null,
};

export const ItemReplacementContext = React.createContext(defaultVal);
