import {
  useColorThemeType,
  useFontTheme,
} from '../../../../src/appStyles/hooks/theme';

import {Box} from '../../../components/core/view';
import {H3Text} from '../../../components/core/text';
import React from 'react';
import {StyleSheet} from 'react-native';

interface DriverScreenHeaderProps {
  title: string;
}

const ScreenHeader: React.FC<DriverScreenHeaderProps> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const title = props?.title || '';
  return (
    <Box style={styles.headerBox}>
      <H3Text
        style={{
          color: theme?.text?.blue[300],
          fontFamily: muli?.[700]?.normal,
        }}>
        {title}
      </H3Text>
    </Box>
  );
};

export default React.memo(ScreenHeader);
const styles = StyleSheet.create({
  headerBox: {
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: -36,
    alignSelf: 'center',
  },
});
