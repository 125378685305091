import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const CheckIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        d="M17.2 23.6L12.9 19.3C12.5333 18.9333 12.0667 18.75 11.5 18.75C10.9333 18.75 10.4667 18.9333 10.1 19.3C9.73333 19.6667 9.55 20.1333 9.55 20.7C9.55 21.2667 9.73333 21.7333 10.1 22.1L15.8 27.8C16.2 28.2 16.6667 28.4 17.2 28.4C17.7333 28.4 18.2 28.2 18.6 27.8L29.9 16.5C30.2667 16.1333 30.45 15.6667 30.45 15.1C30.45 14.5333 30.2667 14.0667 29.9 13.7C29.5333 13.3333 29.0667 13.15 28.5 13.15C27.9333 13.15 27.4667 13.3333 27.1 13.7L17.2 23.6ZM20 40C17.2333 40 14.6333 39.4747 12.2 38.424C9.76667 37.3733 7.65 35.9487 5.85 34.15C4.05 32.35 2.62533 30.2333 1.576 27.8C0.526667 25.3667 0.00133333 22.7667 0 20C0 17.2333 0.525334 14.6333 1.576 12.2C2.62667 9.76667 4.05133 7.65 5.85 5.85C7.65 4.05 9.76667 2.62533 12.2 1.576C14.6333 0.526667 17.2333 0.00133333 20 0C22.7667 0 25.3667 0.525334 27.8 1.576C30.2333 2.62667 32.35 4.05133 34.15 5.85C35.95 7.65 37.3753 9.76667 38.426 12.2C39.4767 14.6333 40.0013 17.2333 40 20C40 22.7667 39.4747 25.3667 38.424 27.8C37.3733 30.2333 35.9487 32.35 34.15 34.15C32.35 35.95 30.2333 37.3753 27.8 38.426C25.3667 39.4767 22.7667 40.0013 20 40Z"
        fill={props?.fill}
      />
    </Icon>
  );
};

export default CheckIcon;
