import React, {useCallback} from 'react';

import {BatchInfo as BatchInfoType} from '../../../AboutShift/types/';
import BatchListItem from '../BatchListItem';
import {CustomFlatList} from '../../../components/core/list';
import {Divider} from 'native-base';
import {styles} from './styles';

interface BatchListProps {
  batchNo?: number;
  online: boolean;
  onValueChange: () => void;
  activeShift?: BatchInfoType[];
  inActiveShift?: BatchInfoType[];
  coordinates?: {lat: number; long: number};
  inspectVan?: boolean;
}

function BatchList(props: BatchListProps) {
  const {
    online,
    onValueChange,
    activeShift,
    inActiveShift,
    batchNo,
    coordinates,
    inspectVan,
  } = props;

  const renderItem = useCallback(
    ({item, index}: {item: BatchInfoType; index: number}) => {
      return (
        <BatchListItem
          index={index}
          item={item}
          online={online}
          onValueChange={onValueChange}
          coordinates={coordinates}
          activeShift={Boolean(activeShift?.length)}
          batchNo={batchNo}
          inspectVan={Boolean(inspectVan)}
        />
      );
    },
    [
      activeShift?.length,
      batchNo,
      coordinates,
      inspectVan,
      onValueChange,
      online,
    ],
  );

  const separator = useCallback(() => {
    return <Divider style={[styles.divider]} />;
  }, []);

  return (
    <CustomFlatList
      data={activeShift ?? inActiveShift}
      renderItem={renderItem}
      ItemSeparatorComponent={separator}
      showsVerticalScrollIndicator={false}
    />
  );
}

export default React.memo(BatchList);
