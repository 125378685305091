import {H2Text, H5Text, H6Text} from '../../../components/core/text';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {StyleSheet, TextInput} from 'react-native';
import font, {scaleFonts} from '../../../appStyles/style/font';
import {inputFormattedNumber, isValidPhoneNumber} from '../../../utils/common';
import {useAppDispatch, useAppSelector} from '../../../config/redux/hooks';

import {Box} from '@buncha/components/core/view';
import Fonts from '../../../resource/fonts';
import If from '../../../components/conditional/If';
import Input from '../Input/Input';
import {onBoardingActions} from '../../../onBoarding/reducer';
import {useAuthentication} from '../../../onBoarding/hooks';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

function PhoneNumber(props: {
  clearPhoneNumber: boolean;
  toggleParentState: any;
  setIsValidate: Function;
  hasOTPSend: boolean;
}) {
  const theme = useColorThemeType();
  const dispatch = useAppDispatch();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const onBoardingState = useAppSelector(gState => gState.onboarding);
  const authData = onBoardingState.userAuthData;

  useEffect(() => {
    if (props.clearPhoneNumber) setPhoneNumber('');
    props.toggleParentState(false);
  }, [props]);

  const {sendingSMSCodeError, clearErrorMessage} = useAuthentication(dispatch);

  useEffect(() => {
    setErrorMsg(sendingSMSCodeError);
  }, [sendingSMSCodeError]);

  const ref = useRef<TextInput>(null);

  const formattedNumber = inputFormattedNumber(phoneNumber);

  const validatePhone = useCallback(
    (value: string) => {
      if (isValidPhoneNumber(inputFormattedNumber(value)))
        props.setIsValidate(true);
      else props.setIsValidate(false);
    },
    [props],
  );

  useEffect(() => {
    validatePhone(formattedNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPhoneNumberHandler = useCallback(
    (value: string) => {
      setPhoneNumber(value);
      validatePhone(value);
      dispatch(onBoardingActions.setSmsCode(undefined));
      dispatch(onBoardingActions.setPhoneNumber(value));
    },
    [dispatch, validatePhone],
  );

  const clearErrorColor = useCallback(() => {
    clearErrorMessage();
    setErrorMsg('');
  }, [clearErrorMessage]);

  const changeColor = useMemo(() => {
    if (errorMsg) return theme?.text.red[100];

    return theme?.text?.blue[300];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMsg]);

  const returnBorderColor = useMemo(() => {
    if (errorMsg) return theme?.text.red[100];
    if (phoneNumber) return theme?.text?.gray[100];
    return theme?.text?.gray[200];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMsg, phoneNumber]);

  const returnLetterSpacing = useMemo(() => {
    if (phoneNumber) return scaleFonts(3.4);
    return 0;
  }, [phoneNumber]);

  const disableStyle = useMemo(() => {
    return {
      opacity: authData?.otpSent ? 0.7 : 1,
    };
  }, [authData?.otpSent]);

  return (
    <Box>
      <H2Text
        style={[
          styles.headerTypo,
          {
            fontFamily: font.fonts['Mortise-Bold'],
            color: theme?.text.blue[300],
          },
        ]}>
        Enter Phone Number
      </H2Text>
      <Box style={[styles.inputContainer, disableStyle]}>
        <Input
          onFocus={() => {
            clearErrorColor();
            validatePhone(formattedNumber);
          }}
          editable
          clearErrorTheme={clearErrorColor}
          closeIcon={changeColor}
          debounce={false}
          defaultValue={formattedNumber}
          value={formattedNumber}
          autoCorrect={false}
          colorThemeType="default"
          keyboardType="number-pad"
          maxLength={14}
          autoFocus={!props?.hasOTPSend}
          placeholder="Phone Number"
          ref={ref}
          clearInput={true}
          textValue={formattedNumber}
          containerStyle={[
            styles.inputContainer,
            {borderColor: returnBorderColor},
          ]}
          leftComponent={
            // <If condition={phoneNumber}>
            <H5Text
              style={[
                styles.leftComponent,
                {
                  color: changeColor,
                  fontFamily: font.fonts['Mortise-Bold'],
                },
              ]}>
              +1
            </H5Text>
            // </If>
          }
          style={[
            {
              backgroundColor: theme?.background?.white[100],
              fontSize: scaleFonts(16),
              color: changeColor,
              letterSpacing: returnLetterSpacing,
            },
          ]}
          onChangeText={setPhoneNumberHandler}
        />
      </Box>
      <Box style={styles.errorMessageContainer}>
        <If condition={Boolean(errorMsg.length)}>
          <H6Text
            style={[
              styles.errorTypo,
              {
                ...font.FontSize.fontP10,
                color: theme?.text.red[100],
              },
            ]}>
            {errorMsg}
          </H6Text>
        </If>
      </Box>
    </Box>
  );
}

const styles = StyleSheet.create({
  headerTypo: {
    textAlign: 'left',
  },
  inputContainer: {
    marginTop: 10,
  },
  leftComponent: {marginLeft: scaleFonts(20), fontWeight: '600'},
  buttonContainer: {
    top: 40,
  },
  errorMessageContainer: {
    top: 40,
  },
  errorTypo: {
    fontFamily: Fonts.muliSemiBold,
  },
});

export default React.memo(PhoneNumber);
