import {Dimensions, StyleSheet} from 'react-native';

import {getWidth} from '../../../utils/common';

const height = Dimensions.get('screen').height;

export const styles = StyleSheet.create({
  mainContainer: {
    alignSelf: 'flex-start',
    width: getWidth('100%'),
    flexGrow: 1,
    paddingHorizontal: 10,
  },
  headerText: {
    textAlign: 'left',
    marginBottom: 15,
    // marginLeft: 15,
    paddingLeft: 0,
  },
  listView: {
    marginTop: 15,
    marginRight: 20,
    height: height * 0.62,
  },
  divider: {
    marginVertical: 15,
  },
  buttonContainer: {
    justifyContent: 'center',
    marginTop: 15,
    marginBottom: 10,
  },
  buttonStyleLeft: {
    marginRight: 15,
    marginLeft: 20,
    paddingHorizontal: 15,
    borderRadius: 50,
    flex: 1,
  },
  buttonStyleRight: {
    marginRight: 20,
    paddingHorizontal: 15,
    borderRadius: 50,
    flex: 1,
  },
  loadingContainer: {
    height: 150,
  },
  loader: {
    marginTop: '20%',
  },
  scrollView: {
    maxHeight: Dimensions.get('screen').height * 0.5,
    // paddingHoLeft: 5,
  },
});
