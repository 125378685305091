import {
  DeliveryLocationStatus,
  OrderItemStatus,
} from '../../batchDetails/types';

import {FileUploadData} from '@buncha/config/fileUpload/type';
import {LatLong} from '../../AboutShift/types';
import {MemoStatus} from '@buncha/memos/types';

export interface HomeState {
  counter: number;
  shiftData: ShiftData;
  nextShiftData: NextShiftData;
  shiftCountByStore: ShiftCountByStore[];
  batchShiftId: string;
  batchShiftKey: string;
  codepushLatestVersion: string;
  fileUploadData: FileUploadData;
}

export interface ShiftData {
  active?: AssignedShift;
  pending: AssignedShift[];
}

export interface NextShiftData {
  upcoming: AssignedShift[];
}

export interface HomeStateContextState {
  userAttendance?: UserAttendance;
}

export interface AssignedShift {
  id: string;
  key: string;
  shiftByStore: ShiftByStore[];
  shifts: Shifts[];
}

export interface ShiftByStore {
  storeMasterId: number;
  activeShiftIndex: number;
  shiftIds: number[];
  batches: number;
}

// eslint-disable-next-line no-shadow
export enum WPosition {
  'Shopper' = 'Shopper',
  'Driver' = 'Driver',
}

export interface Store {
  address: string;
  id: number;
  name: string;
  storeMaster: {
    id: number;
  };
  storeLogoUrl: string;
  elasticSearchIndex: string;
  isMerchant: boolean;
  getLatLongFromAddress: LatLong;
}

export interface Shifts {
  time: string;
  shiftId: string;
  endTime: string;
  startTime: string;
  position: WPosition;
  store: Store;
}

export interface GetAssignedShiftResponse {
  getAssignShift: ShiftData;
}

export interface GetNextShiftResponse {
  getAssignShift: NextShiftData;
}

// eslint-disable-next-line no-shadow
export enum AttendanceStatus {
  'active' = 'active',
  'inActive' = 'inActive',
}

export interface UserAttendance {
  id: number;
  userId: number;
  status: AttendanceStatus;
}

export interface SetUserAttendanceStatusResponse {
  setUserAttendanceStatus: UserAttendance;
}

export interface ShiftCountByStore {
  shiftIds: number[];
  storeMasterId: number;
  batches: number;
}
export interface GetBatchCountResponse {
  getBatchCount: ShiftCountByStore[];
}

export interface WhenIWorkLoginCredentials {
  email: string;
  password: string;
}

export interface BottomSheetProps {
  onSheetClose: () => any;
  refreshScreen?: () => void;
}

export interface PastRunData {
  id: number;
  deliveryDate: number;
  startDate: number;
  dropOffStartTime: string;
  dropOffEndTime: string;
  storeName: string;
  store: Store;
  deliveryDateData: {
    arrivalDate: string;
  };
  getTotalItemCount: number;
  getDroppedItemCount: number;
  uncancelledOrdersCount: number;
  uncancelledOrdersItemCount: number;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  completedOrderCount: number;
  isVip?: boolean;
}

export interface OrderItem {
  id: number;
  name: string;
  quantity: number;
  refundQuantity: number;
  price: number;
  itemImageUrl: string;
  isCustomItem: boolean;
  options: string;
  itemStatus: OrderItemStatus;
  substitutedBy: any;
}

export interface AssigneeUser {
  firstName: string;
  lastName: string;
  userTypes: string;
  id: number;
  phoneNumber: string;
}

export interface Assignee {
  assignee: AssigneeUser;
  assigneeSecondary: AssigneeUser;
}

export interface PastOrder {
  id: number;
  buyer: User;
  items: OrderItem[];
  runBudget: number;
  deliveryLocation?: {
    id: number;
    deliveryStatus: DeliveryLocationStatus;
  };
  orderAssignee?: Assignee;
  deliveryInstruction: string | null;
  orderSummaryLink: string;
}
export interface RunsAndOrders {
  run: PastRunData;
  orders: PastOrder[];
}

export interface StartTutorialResponse {
  startTutorialForRunner: {
    runId: number;
    orderId: number;
  };
}

export interface BagsLeft {
  bagsCount: number;
  text: string;
}

export interface GetBagCountLeftResponse {
  getBagCountLeft: BagsLeft;
}
export interface VersionCheckResponse {
  versionCheck: VersionCheck;
}

export interface VersionInfo {
  header: string;
  subHeader: string;
}

export interface VersionCheck {
  data: VersionInfo;
  showBottomSheet: boolean;
}

// eslint-disable-next-line no-shadow
export enum AppStateStatus {
  active = 'active',
  background = 'background',
  inactive = 'inactive',
  unknown = 'unknown',
  extension = 'extension',
}

export interface GetUnReadMemoCountResponse {
  getMemos: {
    status: MemoStatus;
    totalLength: number;
  }[];
}

// eslint-disable-next-line no-shadow
export enum TutorialsMode {
  driverGuide = 'driverGuide',
  shopperGuide = 'shopperGuide',
}
