import {Box, HBox} from '../../../components/core/view';
import {CustomText, H5Text} from '../../../components/core/text';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import React from 'react';
import {StyleSheet} from 'react-native';

interface AccordionTextHeaderProps {
  titleText: string;
  count: number;
}

function AccordionTextHeader(props: AccordionTextHeaderProps) {
  const {titleText, count} = props;
  const defaultTheme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  return (
    <HBox style={[styles.mainContainer]}>
      <H5Text
        style={[
          styles.titleText,
          {
            color: defaultTheme?.text.blue[300],
            fontFamily: muliFont[700].normal,
          },
        ]}>
        {titleText}
      </H5Text>
      <Box
        style={[
          styles.countBox,
          {
            backgroundColor: defaultTheme?.background.gray[200],
          },
        ]}>
        <CustomText>{count}</CustomText>
      </Box>
    </HBox>
  );
}

export default React.memo(AccordionTextHeader);

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    marginRight: 10,
  },
  countBox: {
    paddingHorizontal: 15,
    borderRadius: 50,
    paddingVertical: 2.5,
  },
  titleText: {
    textAlign: 'left',
  },
});
