import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const ShopIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path d="M26 0H2v3h24V0Zm1.5 15v-3L26 4.5H2L.5 12v3H2v9h15v-9h6v9h3v-9h1.5ZM14 21H5v-6h9v6Z" />
    </Icon>
  );
};

export default ShopIcon;
