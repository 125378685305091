import {ActivityIndicator, Linking, StyleSheet} from 'react-native';
import {Bucket, UploadingStatus} from '@buncha/config/fileUpload/type';
import {HomeScreens, ShopperScreens} from '@buncha/home/navigation/types';
import {
  ItemScannerAction,
  useGetBuyerDetails,
  useGetLocation,
  useGetOrderItemById,
  useGetUpc,
  useItemScannerReducer,
  useScanUPCForAllItems,
  useScanUPCForAllItemsImages,
  useUPCOrder,
  useUploadItemImage,
  useUploadUpcImage,
} from '../hooks/ItemScanner';
import {
  OrderItem,
  ScanUPCForAllItemsImagesParams,
  ScanUPCForAllItemsParams,
  ScanUPCParams,
  Sensitivity,
} from '../types/ItemScanner';
import {OrderItemStatus, UpcStatus} from '@buncha/batchDetails/types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  StoreImageProgress,
  useUploadImage,
} from '@buncha/config/fileUpload/hook';
import {useAppDispatch, useAppSelector} from '@buncha/config/redux/hooks';

import {Analytics} from '@buncha/config/analytics/analytics';
import {Box} from '../../components/core/view';
import {CameraComponentState} from '@buncha/common/types/common';
import CameraModal from '@buncha/common/components/CameraModal/CameraModal';
import {CustomFlatList} from '@buncha/components/core/list';
import {EventType} from '@buncha/config/analytics/type';
import Header from '../../components/composites/Header/Header';
import If from '@buncha/components/conditional/If';
import ImageBottomSheet from '@buncha/common/components/BottomSheet/ImageBottomSheet';
import ItemDetails from '../components/ItemScanner/ItemDetails';
import {ItemScannerContext} from '../context/ItemScanner';
import {ItemScannerGlobalActions} from '../reducer';
import KeyboardAvoidLayout from '@buncha/components/composites/keyboardAvoidLayout';
import ScanAgainButton from '../components/ItemScanner/ScanAgainButton';
import Scanner from '../components/ItemScanner/Scanner';
import {getRandomNumbers} from '@buncha/utils/common';
import {navigation} from '@buncha/config/navigation';
import {useColorThemeType} from '../../appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';
import {useUpdateOrderItemStatus} from '@buncha/batchDetails/hooks/BatchDetails';

const ItemScanner = () => {
  const [state, dispatch] = useItemScannerReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);
  const theme = useColorThemeType();
  const route = useRoute();

  const routeParams: any = route?.params;
  const orderItemId = Number(routeParams?.orderItemId);
  const orderItemIds = routeParams?.orderItemIds;
  const storeIndex = routeParams?.storeIndex;
  const isUPCMode = routeParams?.isUpcMode;

  const [upcImage, setUPCImage] = useState<string[]>(state.upcImages);
  const appDispatch = useAppDispatch();

  const {orderItemLoading, getOrderItemById} = useGetOrderItemById(dispatch);
  const {getBuyerDetails, buyerDetailsLoading} = useGetBuyerDetails(dispatch);
  const {getUpc, preLoadedUpcs} = useGetUpc();
  const {getCatalogItemForShopper} = useUPCOrder(dispatch);
  const {coordinates, getLocationWithPermission} = useGetLocation();
  const {handleScanning} = useScanUPCForAllItems(dispatch);
  const runId = routeParams?.runId;
  const catalogItemId = state.item?.catalogItemId;
  const returnPage = routeParams?.returnPage;

  const {uploadeImageService} = useUploadImage();

  const [openCameraModal, setOpenCameraModal] = useState(false);

  const toggleCameraModal = useCallback(() => {
    setOpenCameraModal(!openCameraModal);
  }, [openCameraModal]);

  const singleQuantiy = state?.buyerData?.[0]?.isItemPickedOnScan;

  const {loading, requestPermissions} = useUploadUpcImage(
    dispatch,
    orderItemId,
    storeIndex,
  );
  const {
    uploadLocationImage,
    imageUploading,
    scanUPCForCrowdSource,
    updatingUPC,
  } = useUPCOrder(dispatch);
  const {uploadItemImage, updateImageLoading} = useUploadItemImage(dispatch);
  const {upcScanImagesLoading, scanUPCForAllItemsImages} =
    useScanUPCForAllItemsImages();

  const {upcOrderItem, selectedCatalogStoreForUPCRun} = useAppSelector(
    gState => gState.itemScanner,
  );

  const {updateOrderItemStatus} = useUpdateOrderItemStatus();

  const onboardingState = useAppSelector(gState => gState.onboarding);
  const constants = onboardingState.constants;
  const user = onboardingState.currentUser;
  const item = useMemo(() => {
    if (routeParams?.isUpcMode)
      return {
        name: upcOrderItem?.name,
        price: upcOrderItem?.price,
        isPriceUpdatable: false,
        itemImageUrl: state?.upcOrderItem?.itemImageUrls.largeImages[0],
        upcImages: [],
        catalogItemId: upcOrderItem?.catalogItemId,
        upcStatus: UpcStatus.pending,
        itemUnit: 1,
      };
    return state.item;
  }, [
    routeParams?.isUpcMode,
    state.item,
    state?.upcOrderItem?.itemImageUrls.largeImages,
    upcOrderItem?.catalogItemId,
    upcOrderItem?.name,
    upcOrderItem?.price,
  ]);

  const scanned = useMemo(() => {
    return (
      item?.upcStatus === UpcStatus.scanned ||
      item?.upcStatus === UpcStatus.imageSaved ||
      state?.upcCrowdSourceProcessCompleted
    );
  }, [item, state?.upcCrowdSourceProcessCompleted]);

  const getImageBottomSheetTypo = useMemo(() => {
    if (isUPCMode)
      return {
        header: 'Upload UPC Image',
        body: 'Upload the image of the UPC found on the product package.',
      };
    return {
      header: 'Add item images',
      body: 'Please take clear photos of the item and upload the images',
    };
  }, [isUPCMode]);

  const navigateToRun = useCallback(() => {
    if (returnPage === ShopperScreens.OrderPackingPage)
      return navigation.navigate(HomeScreens.Shopper, {
        screen: ShopperScreens.OrderPackingPage,
        params: {runId},
      });
    navigation.navigate(HomeScreens.Shopper, {
      screen: ShopperScreens.BatchDetailsPage,
      params: {runId},
    });
  }, [returnPage, runId]);

  const closeBottomSheet = useCallback(() => {
    if (dispatch) dispatch(ItemScannerAction.setUpcImagesModal(false));
  }, [dispatch]);

  const upcScanHandler = useCallback(
    async (upc?: string, images?: string[]) => {
      if (!isUPCMode) return;
      let params: ScanUPCParams = {
        catalogItemId: upcOrderItem?.catalogItemId ?? 0,
        storeIndex: selectedCatalogStoreForUPCRun?.esIndex ?? '',
        orderItemId: routeParams?.orderItemId,
        lat: coordinates?.lat,
        lon: coordinates?.long,
        isUpcOrder: true,
      };
      if (images)
        params = {
          ...params,
          images: upcImage,
        };
      else
        params = {
          ...params,
          upc: upc,
        };
      await scanUPCForCrowdSource(params, () => {
        if (appDispatch) appDispatch(ItemScannerGlobalActions.clearItemState());
        getCatalogItemForShopper(orderItemId, routeParams?.isUpcMode);
        if (dispatch) {
          dispatch(ItemScannerAction.setScannerExpanded(false));
          dispatch(ItemScannerAction.setScannerActive(false));
          dispatch(ItemScannerAction.setUPScanStatus(true));
        }
      });
      closeBottomSheet();
    },
    [
      appDispatch,
      closeBottomSheet,
      coordinates?.lat,
      coordinates?.long,
      dispatch,
      getCatalogItemForShopper,
      isUPCMode,
      orderItemId,
      routeParams?.isUpcMode,
      routeParams?.orderItemId,
      scanUPCForCrowdSource,
      selectedCatalogStoreForUPCRun?.esIndex,
      upcImage,
      upcOrderItem?.catalogItemId,
    ],
  );

  const onScan = useCallback(
    async (upc: string) => {
      upcScanHandler(upc);
      if (!catalogItemId || !storeIndex || !runId || isUPCMode) return;

      if (constants?.SKIP_UPC_FLOW === 'true') {
        let itemIds = orderItemIds;
        if (!itemIds) itemIds = [orderItemId];

        await updateOrderItemStatus(runId, OrderItemStatus.picked, itemIds);
      } else {
        const params: ScanUPCForAllItemsParams = {
          upc: upc,
          catalogItemId: catalogItemId,
          storeIndex: storeIndex,
          runId: runId,
          lat: coordinates?.lat,
          lon: coordinates?.long,
        };
        const response = await handleScanning(params, preLoadedUpcs);
        if (!response) return;
      }
      if (singleQuantiy) return navigateToRun();
      if (dispatch) dispatch(ItemScannerAction.setScannerExpanded(false));
    },
    [
      catalogItemId,
      constants?.SKIP_UPC_FLOW,
      coordinates?.lat,
      coordinates?.long,
      dispatch,
      handleScanning,
      isUPCMode,
      navigateToRun,
      orderItemId,
      orderItemIds,
      preLoadedUpcs,
      runId,
      singleQuantiy,
      storeIndex,
      upcScanHandler,
      updateOrderItemStatus,
    ],
  );

  const openImagesBottomSheet = useCallback(() => {
    if (dispatch) dispatch(ItemScannerAction.setUpcImagesModal(true));
  }, [dispatch]);

  useEffect(() => {
    getLocationWithPermission();
    if (routeParams?.isUpcMode) {
      getCatalogItemForShopper(orderItemId, routeParams?.isUpcMode);
    } else {
      getOrderItemById(orderItemId);
      getBuyerDetails(orderItemId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItemId]);

  useEffect(() => {
    if (!item || !state.buyerData) return;

    const upcItem: any = item;
    const sensitivity = state.buyerData?.[0]?.sensitivity;
    const sensitivityText =
      sensitivity &&
      [
        Sensitivity.priceAndWeightSensitive,
        Sensitivity.priceSensitive,
        Sensitivity.weightSensitive,
      ].includes(sensitivity)
        ? 'Weight sensitive'
        : 'Quantity sensitive';
    if (upcItem?.isCustomItem)
      return Analytics.eventWithProps('Custom Item Details Page', {
        type: EventType.page,
        runId: runId,
        orderId: upcItem?.order?.id,
        orderItemId: orderItemId,
        orderItemName: item?.name,
        storeId: upcItem?.order?.run?.store.id,
        pageType: 'Custom Item',
      });
    return Analytics.eventWithProps('Barcode Scan Page', {
      type: EventType.page,
      runId: runId,
      orderId: upcItem?.order?.id,
      orderItemId: orderItemId,
      orderItemName: item?.name,
      storeId: upcItem?.order?.run?.store.id,
      pageType: sensitivityText,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, state.buyerData]);

  const handleImageDelete = useCallback(
    (url: string) => {
      const arr = upcImage.filter((image: string) => image !== url);
      setUPCImage(arr);
      if (dispatch) dispatch(ItemScannerAction.deleteUpcImage(url));
    },
    [dispatch, upcImage],
  );

  const uploadUPCImage = useCallback(
    async (data: any) => {
      const res = await uploadLocationImage(data);
      if (res) setUPCImage([...upcImage, res]);
    },
    [upcImage, uploadLocationImage],
  );

  const skipUPCHandler = useCallback(async () => {
    const itemIdsToProcess = orderItemIds || [orderItemId];
    await updateOrderItemStatus(
      runId,
      OrderItemStatus.picked,
      itemIdsToProcess,
    );
    closeBottomSheet();
    if (dispatch) dispatch(ItemScannerAction.setScannerExpanded(false));
    if (singleQuantiy) return navigateToRun();
  }, [
    closeBottomSheet,
    dispatch,
    navigateToRun,
    orderItemId,
    orderItemIds,
    runId,
    singleQuantiy,
    updateOrderItemStatus,
  ]);

  const uploadImageOnS3 = useCallback(
    (capturedData: CameraComponentState) => {
      const file = capturedData?.fileData;
      const fileName = `storeIndex:${storeIndex}_orderItemId:${orderItemId}shopperId:${
        user?.id
      }_${new Date()}.jpg`;

      if (file) {
        const base64 = capturedData.base64;
        const uri = file.path;
        const uploadFileType = {
          fileLocalUri: uri,
          fileName,
          uploadingStatus: UploadingStatus.pending,
          bucketType: Bucket.orderImage,
          progress: 0,
          storeIndex,
          runId: Number(runId),
          iamgeNameForLocal: `${item?.name?.split(' ')[0]}_${getRandomNumbers()
            .toString(36)
            .slice(2, 7)}.jpg`,
        };

        setUPCImage([...upcImage, uri]);
        StoreImageProgress(Number(runId), fileName, uploadFileType);
        setTimeout(() => {
          const url = uploadeImageService(
            Bucket.upcImages,
            fileName,
            base64,
            Number(runId),
            storeIndex,
          );

          if (dispatch && url) dispatch(ItemScannerAction.addUpcImage(url));
        }, 100);
      }
    },
    [
      dispatch,
      item?.name,
      orderItemId,
      runId,
      storeIndex,
      upcImage,
      uploadeImageService,
      user?.id,
    ],
  );

  const captureAndPerformActions = useCallback(
    async (capturedData: CameraComponentState) => {
      const permissionStatus = await requestPermissions();

      if (!permissionStatus) {
        Linking.openSettings();
        return;
      }

      try {
        const file = capturedData.fileData;

        if (isUPCMode && file) {
          const base64Image = capturedData.base64;
          uploadUPCImage(base64Image);
        } else if (constants?.SKIP_UPC_FLOW === 'true') {
          await skipUPCHandler();
        } else if (item?.catalogItemId) {
          uploadImageOnS3(capturedData);
        } else {
          uploadItemImage(capturedData.base64, routeParams?.orderItemId);
        }
      } catch (error) {}
    },
    [
      constants?.SKIP_UPC_FLOW,
      isUPCMode,
      item?.catalogItemId,
      requestPermissions,
      routeParams?.orderItemId,
      skipUPCHandler,
      uploadImageOnS3,
      uploadItemImage,
      uploadUPCImage,
    ],
  );

  const updateUpcImages = useCallback(async () => {
    await upcScanHandler(undefined, upcImage);
    if (!catalogItemId || !storeIndex || !runId || isUPCMode) return;
    const params: ScanUPCForAllItemsImagesParams = {
      catalogItemId,
      images: state?.upcImages,
      runId,
      storeIndex,
      lat: coordinates?.lat,
      lon: coordinates?.long,
    };
    const response = await scanUPCForAllItemsImages(params);
    if (response && dispatch) {
      dispatch(ItemScannerAction.setUpcImagesModal(false));
      if (singleQuantiy) return navigateToRun();
      dispatch(ItemScannerAction.setScannerExpanded(false));
    }
  }, [
    catalogItemId,
    coordinates?.lat,
    coordinates?.long,
    dispatch,
    isUPCMode,
    navigateToRun,
    runId,
    scanUPCForAllItemsImages,
    singleQuantiy,
    state?.upcImages,
    storeIndex,
    upcImage,
    upcScanHandler,
  ]);

  useEffect(() => {
    if (storeIndex && item?.catalogItemId)
      getUpc(storeIndex, item?.catalogItemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.catalogItemId, storeIndex]);

  useEffect(() => {
    if (!orderItemLoading) setUPCImage(state.upcImages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItemLoading]);

  const captureImageHandler = useCallback(
    async (data: CameraComponentState) => {
      await captureAndPerformActions(data);
    },
    [captureAndPerformActions],
  );

  if (orderItemLoading || buyerDetailsLoading)
    return (
      <Box
        style={[
          StyleSheet.absoluteFill,
          styles.loader,
          {
            backgroundColor: theme?.background.white[100],
          },
        ]}>
        <ActivityIndicator />
      </Box>
    );

  return (
    <ItemScannerContext.Provider value={provider}>
      <KeyboardAvoidLayout>
        <Header hideBottomSafeArea showDefaultLeftHeader>
          <CustomFlatList
            ListHeaderComponent={
              <Box>
                <If condition={item}>
                  <ItemDetails
                    item={item as OrderItem}
                    title="Scan Barcode"
                    scanned={scanned}
                    openImagesBottomSheet={openImagesBottomSheet}
                    showCaptureModeFunc={async () => toggleCameraModal()}
                    imageUploading={updateImageLoading}
                    isUPCMode={routeParams?.isUpcMode}
                  />
                </If>
                <If condition={item?.catalogItemId && !openCameraModal}>
                  <Scanner onScan={onScan} />
                </If>
              </Box>
            }
            data={undefined}
            renderItem={undefined}
            contentContainerStyle={styles.contentContainerStyle}
          />
          <ScanAgainButton isUPCMode={isUPCMode} />
          <ImageBottomSheet
            bottomSheetOpen={state.upcImagesModal}
            title={getImageBottomSheetTypo.header}
            subtitle={getImageBottomSheetTypo.body}
            loading={
              loading || upcScanImagesLoading || imageUploading || updatingUPC
            }
            imageList={upcImage}
            showCaptureModeFunc={async () => toggleCameraModal()}
            closeBottomSheet={closeBottomSheet}
            handleImageDelete={handleImageDelete}
            uploadButtonHandler={updateUpcImages}
            hasShowBottomButton
            isUPCMode={routeParams?.isUpcMode}
          />
        </Header>
        <If condition={openCameraModal}>
          <CameraModal
            capturedImage={captureImageHandler}
            open={openCameraModal}
            closeModal={toggleCameraModal}
            takePhotoOptions={{
              qualityPrioritization: 'speed',
            }}
          />
        </If>
      </KeyboardAvoidLayout>
    </ItemScannerContext.Provider>
  );
};

const styles = StyleSheet.create({
  loader: {
    opacity: 0.8,
    zIndex: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainerStyle: {
    flexGrow: 1,
  },
});

export default React.memo(ItemScanner);
