import {
  CURRENT_USER,
  SET_APPLICATION_STATUS,
} from '@buncha/config/graphQL/documentNode/queries';

import {HiringApplicationState} from '@buncha/common/types/common';
import {ToastType} from '@buncha/components/composites/notification/type';
import {UserStub} from '@buncha/onBoarding/types';
import {gqlService} from '@buncha/config/graphQL';
import {onBoardingActions} from '@buncha/onBoarding/reducer';
import {useAppDispatch} from '@buncha/config/redux/hooks';
import {useState} from 'react';
import {useToastMessage} from '@buncha/components/composites/notification';

export function useSetApplicationStatus() {
  const [statusLoading, setStatusLoading] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);

  const setApplicationStatus = async (
    application: {id: number}[],
    status: HiringApplicationState,
  ) => {
    setStatusLoading(true);
    try {
      await gqlService?.mutation({
        mutation: SET_APPLICATION_STATUS,
        variables: {application: application, status: status},
        fetchPolicy: 'network-only',
      });
      setStatusLoading(false);
    } catch (err: any) {
      setStatusLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {
    setApplicationStatus,
    statusLoading,
  };
}

export function useGetCurrentUser() {
  const [userLoading, setUserLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [showErrorToast] = useToastMessage(ToastType.Error);

  const getCurrentUser = async () => {
    await gqlService?.getLoginToken();
    setUserLoading(true);
    try {
      const response = await gqlService?.query<{currentUser: UserStub}>({
        query: CURRENT_USER,
        fetchPolicy: 'network-only',
      });

      const userData = response?.data?.currentUser;

      gqlService?.setCurrentUser(userData?.id);

      dispatch(onBoardingActions.setCurrentUser(userData));
      setUserLoading(false);
    } catch (err: any) {
      setUserLoading(false);
      showErrorToast(err?.message);
    }
  };
  return {
    userLoading,
    getCurrentUser,
  };
}
