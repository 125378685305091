import {Box, HBox} from '@buncha/components/core/view';
import {
  BuyerData,
  FormDataUpdate,
  MandatoryFieldNames,
  Sensitivity,
} from '@buncha/itemDetails/types/ItemScanner';
import {
  ItemScannerAction,
  useItemScannerContext,
} from '@buncha/itemDetails/hooks/ItemScanner';
import React, {Fragment, useCallback, useMemo} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {CustomText} from '@buncha/components/core/text';
import {Dimensions} from 'react-native';
import {Divider} from 'native-base';
import DropItem from '../dropItem';
import If from '@buncha/components/conditional/If';
import {Touchable} from '@buncha/components/core/touchable';
import UpdatePriceSection from './UpdatePriceSection';
import UpdateQuantitySection from './UpdateQuantitySection';
import UpdateWeightSection from './UpdateWeightSection';
import {getWidth} from '@buncha/utils/common';
import {styles} from './style';
import {useAppSelector} from '@buncha/config/redux/hooks';

interface UpdateDetailsSectionProps {
  item: BuyerData;
}

const UpdateDetailsSection = (props: UpdateDetailsSectionProps) => {
  const {item} = props;
  const {state, dispatch} = useItemScannerContext();
  const theme = useColorThemeType();
  const muliFont = useFontTheme();
  const maxWeightFields =
    Number(
      useAppSelector(gState => gState.onboarding.constants?.MAX_WEIGHT_FIELDS),
    ) || 9;

  const updateData = state.formData.find(
    data => data.orderItemId === item.orderItemId,
  );

  const visibleFields = state?.buyerData?.[0]?.whatToShow;

  const weightData = updateData?.weightInfo;

  const [isPriceField, isQuantityField, isWeightField] = useMemo(() => {
    let pricePerQuantity = false;
    let quantityFound = false;
    let weightFound = false;
    visibleFields.forEach(field => {
      switch (field.fieldName) {
        case MandatoryFieldNames.pricePerQuantity:
          pricePerQuantity = true;
          break;
        case MandatoryFieldNames.quantityFound:
          quantityFound = true;
          break;
        case MandatoryFieldNames.weightFound:
          weightFound = true;
          break;
        default:
          break;
      }
    });
    return [pricePerQuantity, quantityFound, weightFound];
  }, [visibleFields]);

  const handleFormDataUpdate = useCallback(
    (update: FormDataUpdate, updateValue: any) => {
      if (dispatch)
        dispatch(
          ItemScannerAction.setFormData({
            orderItemId: item.orderItemId,
            update,
            updateValue,
          }),
        );
    },
    [dispatch, item.orderItemId],
  );

  const handleDrop = useCallback(
    (val: boolean) => {
      handleFormDataUpdate(FormDataUpdate.dropped, val);
    },
    [handleFormDataUpdate],
  );

  const allowAddMoreCells = (weightData?.length ?? 0) < maxWeightFields;

  const addWeight = useCallback(() => {
    if (!allowAddMoreCells) return;
    if (dispatch) dispatch(ItemScannerAction.addWeight(item.orderItemId));
  }, [allowAddMoreCells, dispatch, item.orderItemId]);

  return (
    <Box
      style={[
        styles.containerBox,
        {
          width: getWidth(Dimensions.get('screen').width),
        },
      ]}>
      <Box
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          opacity: updateData?.dropped ? 0.5 : 1,
        }}>
        <If condition={isQuantityField}>
          <UpdateQuantitySection item={item} />
          <Divider
            style={[
              styles.divider,
              styles.marTop20,
              {borderColor: theme?.text.gray[200]},
            ]}
          />
        </If>
        <If condition={isWeightField}>
          {weightData?.map((_info, index) => (
            <Fragment key={(item.orderItemId + index).toString()}>
              <UpdateWeightSection itemIndex={index} item={item} />
              <If condition={weightData?.length !== Number(index) + 1}>
                <Divider
                  style={[styles.divider, {borderColor: theme?.text.gray[200]}]}
                />
              </If>
            </Fragment>
          ))}
          <HBox style={styles.weightDividerContainer}>
            <Divider
              style={[
                styles.divider,
                styles.weightDivider,
                styles.marVer20,
                {borderColor: theme?.text.gray[200]},
              ]}
            />
            <Touchable onPress={addWeight}>
              <CustomText
                style={{
                  color: allowAddMoreCells
                    ? theme?.text.gray[100]
                    : theme?.text.red[100],
                  fontFamily: muliFont[700].normal,
                }}>
                <If condition={allowAddMoreCells}>+Add more weight</If>
                <If condition={!allowAddMoreCells}>
                  Can not add more than {maxWeightFields}
                </If>
              </CustomText>
            </Touchable>
          </HBox>
        </If>
        <If condition={isPriceField}>
          <UpdatePriceSection item={item} isWeightField={isWeightField} />
        </If>
      </Box>
      <HBox>
        <DropItem
          mode={Sensitivity.weightSensitive}
          undrop={Boolean(updateData?.dropped)}
          orderItemId={item.orderItemId}
          orderStatus={item.order.deliveryLocation.deliveryStatus}
          handleDrop={handleDrop}
        />
      </HBox>
    </Box>
  );
};

export default React.memo(UpdateDetailsSection);
