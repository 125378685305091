import React, {useCallback, useMemo} from 'react';
import {StyleSheet, ViewStyle} from 'react-native';

import {ArrowBackIcon} from 'native-base';
import {Touchable} from '../../components/core/touchable';
import font from '../../appStyles/style/font';
import {navigation} from '../../config/navigation';
import {useColorThemeType} from '../../appStyles/hooks/theme';

interface Props {
  containerStyle?: ViewStyle;
  onClick?: Function;
  iconStyle?: ViewStyle;
  iconColor?: string;
}

export function BackHeaderLeft(props: Props) {
  const backHandler = useCallback(() => {
    if (props.onClick) return props.onClick();
    navigation.goBack();
  }, [props]);

  const theme = useColorThemeType();

  const getIconColor = useMemo(() => {
    if (props?.iconColor) return props?.iconColor;
    return theme?.background.blue[300];
  }, [props?.iconColor, theme?.background.blue]);

  return (
    <Touchable
      onPress={backHandler}
      style={[styles.container, props.containerStyle]}>
      <ArrowBackIcon
        style={[styles.headerLeft, props?.iconStyle]}
        size={5}
        color={getIconColor}
      />
    </Touchable>
  );
}

const styles = StyleSheet.create({
  container: {padding: 15},
  headerLeft: {
    fontFamily: font.fonts.muliBold,
  },
});
