import {
  AddItemParams,
  CustomItemMode,
} from '../../itemDetails/types/CustomItem';
import {Box, HBox} from '../../../src/components/core/view';
import {H5Text, H6Text} from '../../components/core/text';
import {HomeScreens, ShopperScreens} from '../../home/navigation/types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useAddItem, useAddItemReducer} from '../hooks/AddItem';

import {AddItemContext} from '../context/AddItem';
import AppModal from '@buncha/common/components/CustomModal/AppModal';
import {BottomButton} from '../../components/composites/Button';
import Header from '../../components/composites/Header/Header';
import If from '../../components/conditional/If';
import {ItemDetailsScreen} from '../../itemDetails/navigation/types';
import QRCode from '../../../src/components/core/icon/QRCode';
import SearchSection from '../component/SearchSection';
import {SpinnerComponent} from '../../components/core/loader';
import {Touchable} from '../../components/core/touchable';
import {isWeb} from '@buncha/utils/common';
import {navigation} from '../../config/navigation';
import {styles} from '../styles/AddItemPage';
import {useColorThemeType} from '../../appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';

const AddItemPage = () => {
  const theme = useColorThemeType('main');

  const [state, dispatch] = useAddItemReducer();

  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);

  const routeParams: any = useRoute().params;
  const runId = Number(routeParams?.runId);
  const orderId = Number(routeParams?.orderId);
  const returnPage = routeParams?.returnPage;
  const storeIndex = routeParams?.storeIndex || '';

  const isWebMode = isWeb();

  const {selectedQuantity, selectedItem} = state;

  const {addItemLoading, addItem, errorMessage} = useAddItem();

  const [showErrorModal, setSetshowErrorModal] = useState('');

  const handleOnPress = useCallback(() => {
    if (!orderId || !runId) return;
    navigation.navigate(ShopperScreens.ItemDetails, {
      screen: ItemDetailsScreen.CustomItem,
      params: {
        orderId,
        runId,
        mode: CustomItemMode.addItem,
        returnPage,
      },
    });
  }, [orderId, returnPage, runId]);

  useEffect(() => {
    if (errorMessage) setSetshowErrorModal(errorMessage);
  }, [errorMessage]);

  const handleOnClose = () => {
    setSetshowErrorModal('');
  };

  const navigateToBatchPage = useCallback(() => {
    if (returnPage === ShopperScreens.OrderPackingPage)
      return navigation.navigate(HomeScreens.Shopper, {
        screen: ShopperScreens.OrderPackingPage,
        params: {runId},
      });
    navigation.navigate(HomeScreens.Shopper, {
      screen: ShopperScreens.BatchDetailsPage,
      params: {runId},
    });
  }, [returnPage, runId]);

  const navigateToScanner = useCallback(
    (orderItemId: number) => {
      if (isWebMode) return navigateToBatchPage();
      return navigation.navigate(ShopperScreens.ItemDetails, {
        screen: ItemDetailsScreen.ItemScanner,
        params: {
          orderItemId: orderItemId,
          storeIndex,
          runId,
          quantity: selectedQuantity,
          returnPage,
        },
      });
    },
    [
      isWebMode,
      navigateToBatchPage,
      returnPage,
      runId,
      selectedQuantity,
      storeIndex,
    ],
  );

  const handleAddItem = useCallback(async () => {
    if (!orderId || !selectedItem || addItemLoading) return;
    const params: AddItemParams = {
      orderId: orderId,
      name: selectedItem.name,
      price: selectedItem.price,
      priceWithTax: selectedItem.price,
      quantity: selectedQuantity,
      isCustomItem: false,
      itemImageUrl: selectedItem.itemImageUrl,
      catalogItemId: selectedItem.id,
      tags: selectedItem.tags?.length ? selectedItem.tags[0] : '',
      position: 0,
      priceSensitive: Boolean(selectedItem.flags?.priceSensitive) ?? false,
      weightSensitive: Boolean(selectedItem?.flags?.weightSensitive) ?? false,
    };

    await addItem(params, navigateToScanner);
  }, [
    addItem,
    addItemLoading,
    navigateToScanner,
    orderId,
    selectedItem,
    selectedQuantity,
  ]);

  const getTitle = useMemo(() => {
    if (isWebMode) return 'Pick Item';
    return 'Found Item';
  }, [isWebMode]);

  return (
    <Header
      style={styles.parentBox}
      headerColor={theme?.background?.blue?.[300]}
      showDefaultLeftHeader>
      <AddItemContext.Provider value={provider}>
        <SearchSection selectedItem={selectedItem} />
        <If condition={!selectedItem}>
          <Touchable style={styles.bottomBox} onPress={handleOnPress}>
            <H6Text
              style={[styles.underlinedText, {color: theme?.text.gray[100]}]}>
              Add Custom Item
            </H6Text>
          </Touchable>
        </If>
        <If condition={selectedItem}>
          <Box style={styles.bottomBox}>
            <BottomButton
              leftComponent={
                !isWebMode && (
                  <QRCode viewBox="-5 -2 22 17" style={styles.iconBox} />
                )
              }
              title={getTitle}
              onPress={handleAddItem}
              isLoading={addItemLoading}
              disabled={addItemLoading}
              loadingIndicator={<SpinnerComponent />}
            />
          </Box>
        </If>
        <AppModal
          isOpen={Boolean(showErrorModal)}
          onClose={handleOnClose}
          topCTAHandelr={handleOnClose}
          title={'Information!'}
          hideBottomCta
          modalBody={
            <HBox style={styles.modalBody}>
              <H5Text>{showErrorModal}</H5Text>
            </HBox>
          }
          ctaTopTitle={'OK'}
          topCTAColor={theme?.background.green[100]}
        />
      </AddItemContext.Provider>
    </Header>
  );
};

export default React.memo(AddItemPage);
