import React, {useMemo} from 'react';

import BuyerInfo from './BuyerInfo';
import {BuyerOrderListCellStyle} from './styles';
import CheckBoxCount from './CheckBoxCount';
import {DeliveryLocationStatus} from '@buncha/driver/types/common';
import {GroupedOrder} from '@buncha/driver/types/vanLoadAt';
import {HBox} from '@buncha/components/core/view';
import StoreCheckList from './StoreCheckList';

interface CheckBoxCountProps {
  index: number;
  groupedOrder: GroupedOrder;
}

const BuyerOrderListCell = (props: CheckBoxCountProps) => {
  const {index, groupedOrder} = props;

  const filteredOrders = useMemo(() => {
    const data = groupedOrder.orders.every(
      order =>
        order.deliveryLocation.deliveryStatus ===
        DeliveryLocationStatus.driverAssigned,
    );

    if (data) return true;
    return false;
  }, [groupedOrder]);

  return (
    <HBox style={BuyerOrderListCellStyle.container}>
      <CheckBoxCount index={index} isChecked={filteredOrders} />
      <BuyerInfo buyer={groupedOrder?.buyer} orders={groupedOrder?.orders} />
      <StoreCheckList orders={groupedOrder?.orders} index={props?.index} />
    </HBox>
  );
};

export default React.memo(BuyerOrderListCell);
