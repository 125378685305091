import React, {useCallback, useMemo, useRef, useState} from 'react';
import {StyleSheet, TextInput} from 'react-native';

import {Box} from '@buncha/components/core/view';
import {H5Text} from '@buncha/components/core/text';
import InputBox from './InputBox';
import {scaleFonts} from '@buncha/appStyles/style/font';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const MinimumItemTextBox = (props: {itemCount: string; setItemCount: any}) => {
  const [errorMsg, setErrorMsg] = useState('');
  const TextRef = useRef<TextInput>(null);
  const theme = useColorThemeType('main');

  const clearErrorColor = useCallback(() => {
    setErrorMsg('');
  }, []);

  const returnBorderColor = useMemo(() => {
    if (props.itemCount) return theme?.text?.blue[300];
    if (errorMsg) return theme?.text.red[100];
    return theme?.text?.gray[300];
  }, [
    errorMsg,
    props.itemCount,
    theme?.text?.blue,
    theme?.text?.gray,
    theme?.text.red,
  ]);

  const setItemCount = useCallback(
    (val: string) => {
      const res = val.replace(/[, ]+/g, ' ').trim().split('-');
      const filteredVal = String(res[0].split('.')[0]);
      if (Number(filteredVal) <= 5000) props.setItemCount(filteredVal);
    },
    [props],
  );

  return (
    <Box style={[styles.parentContainer]}>
      <H5Text style={[styles.headerTypo, {color: theme?.text?.blue[300]}]}>
        Enter the number of items
      </H5Text>
      <InputBox
        onFocus={() => {
          clearErrorColor();
        }}
        debounce={false}
        defaultValue={''}
        autoCorrect={false}
        colorThemeType="default"
        clearButtonMode="while-editing"
        keyboardType="numeric"
        autoFocus
        placeholder="0"
        ref={TextRef}
        clearInput={true}
        value={props.itemCount}
        textValue={props.itemCount}
        containerStyle={[{borderColor: returnBorderColor}]}
        style={[
          {
            backgroundColor: theme?.background?.white[100],
            fontSize: scaleFonts(16),
            color: theme?.text?.blue[300],
          },
        ]}
        onChangeText={(val: any) => {
          setItemCount(String(val));
        }}
      />
    </Box>
  );
};

export default React.memo(MinimumItemTextBox);

const styles = StyleSheet.create({
  headerTypo: {
    textAlign: 'left',
  },
  parentContainer: {
    marginTop: 10,
    zIndex: 1,
    position: 'relative',
  },
});
