import {BunchaTheme} from '../context/theme';
import appStyle from '../style';
import {useContext} from 'react';

import {
  ColorThemeOptions,
  FontTheme,
  ModuleTheme,
  ShadowThemeType,
} from '../style/type';

/**
 * @returns Theme
 * @description current color and font theme name will return
 */
export function useTheme() {
  const bunchaTheme = useContext(BunchaTheme);
  return bunchaTheme;
}

/**
 * @default default
 * @returns ColorThemeOptions {text, background, icon, shadow}
 */
export function useColorThemeType(
  moduleName: ModuleTheme = 'main',
): ColorThemeOptions | undefined {
  const {colorTheme} = useTheme();
  const theme = appStyle.color[moduleName];
  const colorThemeWithType = theme[colorTheme];
  return colorThemeWithType;
}

export function useFontTheme(fontThemeStyle?: FontTheme) {
  const {fontTheme} = useTheme();
  const theme = fontThemeStyle || fontTheme;
  return appStyle.font.fontTheme[theme];
}

export function useShadow(num: ShadowThemeType) {
  return appStyle.shadow[num];
}
