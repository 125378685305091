import {DefaultContext} from '../../utils/types';
import {HomeStateContextState} from '../types';
import React from 'react';

export const initialState: HomeStateContextState = {
  userAttendance: undefined,
};

const defaultVal: DefaultContext<HomeStateContextState> = {
  state: initialState,
  dispatch: null,
};

export const HomeContext = React.createContext(defaultVal);
