/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * Generated with the TypeScript template
 * https://github.com/react-native-community/react-native-template-typescript
 *
 * @format
 */

import {
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import React, {useEffect, useRef} from 'react';

import {ActivityIndicator} from 'react-native';
import {ApolloProvider} from '@apollo/client';
import {Config} from './utils/types';
import {GraphQLService} from './config/graphQL/service';
import {IntercomProvider} from 'react-use-intercom';
import MainNavigator from './navigation/MainNavigator';
import {NetworkConfiguration} from './config/graphQL/type';
import {Provider} from 'react-redux';
import {getConfig} from './utils/appInfo';
import {initGraphQLService} from './config/graphQL';
import {linking} from './navigation/linking';
import {navigation} from './config/navigation';
import {notification} from './config/notification';
import {smartLook} from './config/analytics';
import {store} from './config/redux';

const MainModule = (props: {networkConfig: NetworkConfiguration}) => {
  let service = useRef<GraphQLService | null>(null);
  if (!service.current)
    service.current = initGraphQLService(props.networkConfig);
  let navigationRef = useNavigationContainerRef();

  const intercomId = getConfig(Config.intercomId);

  useEffect(() => {
    notification.onMessage();
    smartLook.setupAndStartRecording();
  }, []);

  return (
    <NavigationContainer
      ref={navigationRef}
      documentTitle={{
        formatter: () => 'Buncha Shopper',
      }}
      linking={linking}
      fallback={<ActivityIndicator />}
      onReady={() => {
        navigation.setNavigationRef(navigationRef.current);
      }}>
      <IntercomProvider appId={intercomId}>
        <ApolloProvider client={service?.current.getClient()}>
          <Provider store={store}>
            <MainNavigator />
          </Provider>
        </ApolloProvider>
      </IntercomProvider>
    </NavigationContainer>
  );
};

export default MainModule;
