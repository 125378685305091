import {Dimensions, StyleSheet} from 'react-native';

import {isWeb} from '../../../utils/common';

const height = Dimensions.get('screen').height;

export const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 20,
  },
  mainHeaderContainer: {
    flex: 1,
    marginRight: 10,
  },
  countBox: {
    paddingHorizontal: 15,
    borderRadius: 50,
    paddingVertical: 2.5,
  },
  divider: {
    marginVertical: 15,
  },
  scrollContainer: {
    height: isWeb() ? height * 0.57 : height * 0.65,
  },
  iconStyle: {
    marginRight: 10,
  },
});
