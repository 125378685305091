import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {Box, HBox} from '@buncha/components/core/view';
import {Skeleton} from 'native-base';
import React from 'react';
import {StyleSheet} from 'react-native';

const LoadingRow = () => {
  const theme = useColorThemeType();
  return (
    <HBox style={styles.containerBox}>
      <Skeleton
        width={10}
        rounded={'full'}
        startColor={theme?.text.yellow[100]}
      />
      <Box style={styles.innerContainer}>
        <HBox style={styles.marBot10}>
          <Skeleton
            startColor={theme?.text.gray[100]}
            width={40}
            height={3}
            borderRadius={5}
          />
          <Skeleton
            startColor={theme?.text.gray[100]}
            width={10}
            height={3}
            borderRadius={5}
          />
        </HBox>
        <Skeleton
          style={styles.marBot10}
          width={100}
          height={3}
          borderRadius={5}
        />
        <Skeleton
          style={styles.marBot10}
          width={20}
          height={3}
          borderRadius={5}
        />
      </Box>
    </HBox>
  );
};

export default React.memo(LoadingRow);

const styles = StyleSheet.create({
  marBot10: {marginBottom: 10},
  containerBox: {justifyContent: 'flex-start', alignItems: 'flex-start'},
  innerContainer: {flexGrow: 1, paddingLeft: 10},
});
