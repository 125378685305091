import {GET_ORDER_ITEM_BY_ID_SCAN} from '../../config/graphQL/documentNode/queries';
import {OrderItem} from '../types/ItemScanner';
import {ToastType} from '../../components/composites/notification/type';
import {UPDATE_ITEMS_PRICE} from '../../config/graphQL/documentNode/mutation';
import {UpdateItemsPriceParams} from '../types/Itemupdate';
import {gqlService} from '../../config/graphQL';
import {useState} from 'react';
import {useToastMessage} from '../../components/composites/notification';

export function useGetOrderItemById() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [orderItemLoading, setLoading] = useState(true);
  const [orderItemData, setData] = useState<OrderItem>();

  const getOrderItemById = async (orderItemId: number) => {
    if (!orderItemId) return;
    try {
      setLoading(true);
      const res = await gqlService?.query({
        query: GET_ORDER_ITEM_BY_ID_SCAN,
        fetchPolicy: 'network-only',
        variables: {
          orderItemId: orderItemId,
        },
      });
      const itemInfo = res?.data?.getOrderItemById;
      setData(itemInfo);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {orderItemLoading, orderItemData, getOrderItemById};
}

export function useUpdateOrderItemPrice() {
  const [loading, setLoading] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);

  const updateItemsPrice = async (params: UpdateItemsPriceParams) => {
    if (!params.orderItemId || !params.runId || !params.price) return;
    setLoading(true);
    try {
      const res = await gqlService?.mutation<{updateItemsPrice: boolean}>({
        mutation: UPDATE_ITEMS_PRICE,
        fetchPolicy: 'network-only',
        variables: params,
      });

      const response = res?.data?.updateItemsPrice;
      setLoading(false);
      return response;
    } catch (error: any) {
      showErrorToast(error.message);
      setLoading(false);
    }
  };

  return {loading, updateItemsPrice};
}
