import {Box, HBox} from '@buncha/components/core/view';
import React, {useCallback, useState} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import BottomSheet from '@buncha/components/composites/bottomSheet';
import {CTAIntentType} from '@buncha/common/types/common';
import {CustomFlatList} from '@buncha/components/core/list';
import {CustomText} from '@buncha/components/core/text';
import {Divider} from 'native-base';
import If from '@buncha/components/conditional/If';
import {PageDetails} from '@buncha/hiring/types/onboardingPhases';
import PhaseIconComponent from './PhaseIconComponent';
import PhaseImageComponent from './PhaseImageComponent';
import {Touchable} from '@buncha/components/core/touchable';
import WhenIWorkBottomSheet from '@buncha/home/components/whenIWorkBottomSheet';
import {deepLink} from '@buncha/navigation/deepLink';
import {openLink} from '@buncha/hiring/hooks/onboardingPhases';
import {styles} from './style';

interface PhaseListProps {
  sections: PageDetails[];
  refreshScreen: () => void;
}

const PhaseList: React.FC<PhaseListProps> = props => {
  const muli = useFontTheme('muli');
  const theme = useColorThemeType('main');
  const [showModal, setShowModal] = useState(false);
  const openWhenIWorkModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleClick = useCallback(
    (item: PageDetails) => {
      const ctaIntent = item?.touchable?.ctaIntent;
      if (item?.id === 'whenIWork') {
        openWhenIWorkModal();
        return;
      }
      if (ctaIntent?.type === CTAIntentType.navAction) {
        deepLink.navigate(ctaIntent?.deepLinkParams);
        return;
      }
      if (ctaIntent?.type === CTAIntentType.deeplink) openLink(ctaIntent?.link);
    },
    [openWhenIWorkModal],
  );

  const renderItem = useCallback(
    ({item, index}: {item: PageDetails; index: number}) => {
      return (
        <HBox style={styles.parentBox} key={index}>
          <PhaseIconComponent completed={item.completed} />
          <Box style={styles.contentBox}>
            <PhaseImageComponent url={item?.iconUrl} header={item?.header} />
            <Box>
              <CustomText
                style={[
                  styles.subHeader,
                  {
                    fontFamily: muli?.[600]?.normal,
                    color: theme?.text?.gray?.[100],
                  },
                ]}>
                {item?.subHeader}
              </CustomText>
              <If condition={!item?.completed}>
                <Touchable
                  {...item?.touchable?.props}
                  onPress={() => handleClick(item)}>
                  <CustomText
                    style={[
                      styles.textAlign,
                      {
                        color: item?.touchable?.props?.color,
                        fontFamily: muli?.[600]?.normal,
                      },
                    ]}>
                    {item?.touchable?.name}
                  </CustomText>
                </Touchable>
              </If>

              <If condition={item?.completed}>
                <CustomText style={styles.footerText}>
                  {item?.footer}
                </CustomText>
              </If>
            </Box>
          </Box>
        </HBox>
      );
    },
    [handleClick, muli, theme?.text?.gray],
  );
  const separator = useCallback(() => {
    return <Divider style={styles.divider} />;
  }, []);
  return (
    <React.Fragment>
      <CustomFlatList
        data={props?.sections}
        renderItem={renderItem}
        ItemSeparatorComponent={separator}
        showsVerticalScrollIndicator={false}
      />
      <BottomSheet onClose={closeModal} open={showModal}>
        <WhenIWorkBottomSheet
          refreshScreen={props?.refreshScreen}
          onSheetClose={closeModal}
        />
      </BottomSheet>
    </React.Fragment>
  );
};

export default React.memo(PhaseList);
