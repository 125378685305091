import {ActivityIndicator, StyleSheet} from 'react-native';
import {
  ItemReplacementAction,
  useItemReplacementContext,
  useSearchItemsForSubstitution,
  useSearchItemsForSubstitutionInUPC,
} from '../../../../../src/itemDetails/hooks/itemReplacement';
import React, {useCallback, useState} from 'react';

import {Analytics} from '@buncha/config/analytics/analytics';
import {Box} from '../../../../../src/components/core/view';
import {CustomFlatList} from '../../../../../src/components/core/list';
import {Divider} from 'native-base';
import {EventType} from '@buncha/config/analytics/type';
import If from '../../../../../src/components/conditional/If';
import {RecommendedItemCell} from '../../../../../src/itemDetails/types/itemReplacement';
import RecommendedItems from './RecommendedItems';
import ReplacementItemCell from '../replacementItemCell';
import SearchInput from '../../../../../src/components/composites/Input/TextInput/SearchInput';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {useColorThemeType} from '../../../../../src/appStyles/hooks/theme';

interface SearchResultsProps {
  catalogItemId: number;
  category: string;
  searchQuery: string;
  storeId: number;
  subCategory: string;
  isUPCMode: boolean;
  orderItemId?: number;
  orderId?: number;
  runId?: number;
  quantity?: number;
}
const SearchResults: React.FC<SearchResultsProps> = props => {
  const theme = useColorThemeType('main');
  const {state, dispatch} = useItemReplacementContext();
  const [searchQuery, setSearchQuery] = useState('');
  const {searchItemsForSubstitution, loading, moreItems} =
    useSearchItemsForSubstitution();
  const {selectedCatalogStoreForUPCRun} = useAppSelector(
    gState => gState.itemScanner,
  );

  const {searchItemsForSubstitutioninUPC, loadingUPC, moreItemsUPC} =
    useSearchItemsForSubstitutionInUPC();
  const size = 10;
  const searchSource = 'Add Replacement';

  const onFocus = useCallback(() => {
    Analytics.eventWithProps('Searching for an item', {
      type: EventType.input,
      source: searchSource,
    });
  }, []);

  const searchForAnItemEvent = useCallback(
    (keyword: string, offset: number) => {
      Analytics.eventWithProps('Search for an item', {
        searchItem: keyword,
        from: offset,
        size: size,
        source: searchSource,
        storeId: props.storeId,
        catalogItemId: props.catalogItemId,
      });
    },
    [props.catalogItemId, props.storeId],
  );

  const handleSearchQueryChange = useCallback(
    async (text: string) => {
      setSearchQuery(text);
      if (!text && dispatch)
        dispatch(ItemReplacementAction.setSearchResults([]));
      if (text) {
        searchForAnItemEvent(text, 0);
        if (props?.isUPCMode)
          await searchItemsForSubstitutioninUPC({
            esIndex: selectedCatalogStoreForUPCRun?.esIndex ?? '',
            from: 0,
            size: 10,
            searchQuery: text,
            catalogItemId: props?.catalogItemId,
          });
        else
          await searchItemsForSubstitution({
            storeId: props?.storeId || 0,
            from: 0,
            size: 10,
            searchQuery: text,
            catalogItemId: props?.catalogItemId,
          });
      }
    },
    [
      dispatch,
      props?.catalogItemId,
      props?.isUPCMode,
      props?.storeId,
      searchForAnItemEvent,
      searchItemsForSubstitution,
      searchItemsForSubstitutioninUPC,
      selectedCatalogStoreForUPCRun?.esIndex,
    ],
  );

  const handleScroll = useCallback(
    async (_: any, offset: number) => {
      searchForAnItemEvent(searchQuery, offset);
      if (props?.isUPCMode)
        await searchItemsForSubstitutioninUPC({
          esIndex: selectedCatalogStoreForUPCRun?.esIndex ?? '',
          from: offset,
          size: 10,
          searchQuery: searchQuery,
          catalogItemId: props?.catalogItemId,
        });
      else
        await searchItemsForSubstitution({
          storeId: props?.storeId || 0,
          from: offset,
          size: 10,
          searchQuery: searchQuery,
          catalogItemId: props?.catalogItemId,
        });
    },
    [
      props?.catalogItemId,
      props?.isUPCMode,
      props?.storeId,
      searchForAnItemEvent,
      searchItemsForSubstitution,
      searchItemsForSubstitutioninUPC,
      searchQuery,
      selectedCatalogStoreForUPCRun?.esIndex,
    ],
  );

  const separator = useCallback(() => <Divider />, []);

  const renderItem = useCallback(
    (subItem: {item: RecommendedItemCell}) => {
      return (
        <ReplacementItemCell
          itemInfo={subItem?.item}
          isUPCMode={props?.isUPCMode ?? false}
          orderItemId={props?.orderItemId}
          orderId={props?.orderId}
          runId={props?.runId}
          quantity={props?.quantity}
        />
      );
    },
    [
      props?.isUPCMode,
      props?.orderId,
      props?.orderItemId,
      props?.quantity,
      props?.runId,
    ],
  );
  return (
    <Box style={styles.parentBox}>
      <SearchInput
        defaultValue={searchQuery}
        containerStyle={[
          styles.search,
          {
            backgroundColor: theme?.background?.white?.[100],
          },
        ]}
        debounce={true}
        placeholderTextColor={theme?.text?.blue?.[300]}
        placeholder={'Search Item'}
        textValue={searchQuery}
        clearInput={true}
        colorThemeType="default"
        onChangeText={handleSearchQueryChange}
        style={{color: theme?.text?.blue?.[300]}}
        onFocus={onFocus}
      />
      <If
        condition={
          state?.searchResults.length ||
          ((loading || loadingUPC) && searchQuery)
        }>
        {state.searchResults?.length > 0 && (
          <CustomFlatList
            showsVerticalScrollIndicator={false}
            data={state.searchResults}
            renderItem={renderItem}
            ItemSeparatorComponent={separator}
            loadMore={props?.isUPCMode ? moreItemsUPC : moreItems}
            loading={loading || loadingUPC}
            userOffset={10}
            initialOffset={10}
            onEndReachedThreshold={0.5}
            numOfTimesToRender={1}
            loadingSkeleton={<ActivityIndicator />}
            onEndReachedAtt={handleScroll}
          />
        )}
      </If>
      <If
        condition={
          !searchQuery &&
          (!loading || !loadingUPC) &&
          !state.searchResults.length
        }>
        <RecommendedItems
          catalogItemId={props?.catalogItemId}
          category={props?.category}
          searchQuery={props?.searchQuery}
          storeId={props?.storeId}
          subCategory={props?.subCategory}
          isUPCMode={props?.isUPCMode ?? false}
          orderItemId={props?.orderItemId}
          orderId={props?.orderId}
          runId={props?.runId}
          quantity={props?.quantity}
        />
      </If>
    </Box>
  );
};
export default React.memo(SearchResults);
const styles = StyleSheet.create({
  search: {
    height: 55,
    borderRadius: 50,
    marginBottom: 10,
  },
  parentBox: {
    flex: 1,
  },
});
