import {ChevronDownIcon, ChevronUpIcon, Divider} from 'native-base';
import {Dimensions, RefreshControl, StyleSheet, ViewStyle} from 'react-native';
import React, {useCallback, useEffect} from 'react';
import {Shift, ShiftByDate} from '../../../../src/openShifts/types/openShifts';
import {
  useGetAvailableShifts,
  useOpenShiftsContext,
} from '../../../../src/openShifts/hooks/openShifts';

import {Accordian} from '../../../components/composites';
import Box from '../../../../src/components/core/view/Box';
import {CustomFlatList} from '../../../components/core/list';
import If from '../../../../src/components/conditional/If';
import LoaderComponents from '@buncha/components/composites/CustomLoader/LoaderComponents';
import NoAvailableOpenShifts from '../NoAvailableOpenShifts';
import OpenShiftAccordianHeader from './OpenShiftAccordianHeader';
import OpenShiftStoreCell from '../OpenShiftStoreCell';
import {getWidth} from '../../../utils/common';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

const OpenShiftsAccordian = () => {
  const {getAvailableShifts, loading} = useGetAvailableShifts();
  const {state} = useOpenShiftsContext();
  const theme = useColorThemeType('main');
  const days = state?.days;
  const shiftsAvailable = days?.length || 0;

  const renderStoreShiftCell = useCallback((storeShift: {item: Shift}) => {
    return <OpenShiftStoreCell storeShift={storeShift?.item} />;
  }, []);

  const separator = useCallback((style: ViewStyle) => {
    return <Divider style={style} />;
  }, []);

  const renderItem = useCallback(
    (day: {item: ShiftByDate}) => {
      return (
        <Box style={styles.accordianBox}>
          <Accordian
            containerStyle={styles.accordianContainer}
            isExpanded={true}
            header={
              <OpenShiftAccordianHeader
                heading={day?.item?.key}
                shiftCount={day?.item?.shifts?.length}
              />
            }
            iconEnable={<ChevronUpIcon color={theme?.text.blue[300]} />}
            iconDisable={<ChevronDownIcon color={theme?.text.blue[300]} />}>
            <CustomFlatList
              ItemSeparatorComponent={() => separator(styles.divider)}
              renderItem={renderStoreShiftCell}
              data={day?.item?.shifts}
            />
          </Accordian>
        </Box>
      );
    },
    [renderStoreShiftCell, separator, theme?.text.blue],
  );

  useEffect(() => {
    getAvailableShifts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) return <LoaderComponents showBottomText={false} />;
  return (
    <React.Fragment>
      <If condition={shiftsAvailable}>
        <CustomFlatList
          refreshControl={
            <RefreshControl
              refreshing={loading}
              onRefresh={() => {
                getAvailableShifts();
              }}
            />
          }
          ItemSeparatorComponent={() => separator(styles.accordianDivider)}
          renderItem={renderItem}
          data={days}
        />
      </If>
      <If condition={!shiftsAvailable}>
        <NoAvailableOpenShifts />
      </If>
    </React.Fragment>
  );
};

export default React.memo(OpenShiftsAccordian);
const styles = StyleSheet.create({
  accordianContainer: {
    marginVertical: 10,
  },
  accordianDivider: {
    marginTop: 15,
    width: Number(getWidth(Dimensions?.get('window').width) || 0),
    marginBottom: 10,
  },
  accordianBox: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  divider: {
    marginTop: 10,
  },
});
