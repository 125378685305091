import React, {useCallback, useState} from 'react';

import {MapChoice} from '../../onBoarding/types';
import {UPDATE_USER_CHOICE_MAP} from '../../config/graphQL/documentNode/mutation';
import {UpdateUserChoiceMapRes} from '../types';
import {gqlService} from '../../config/graphQL';
import {onBoardingActions} from '../../onBoarding/reducer';

export function useUpdateUserChoiceMap(
  dispatch: React.Dispatch<any> | null,
): [(mapChoice: MapChoice) => void, boolean] {
  const [loading, setLoading] = useState(true);
  const updateUserChoiceMap = useCallback(
    async (mapChoice: MapChoice) => {
      try {
        const response = await gqlService?.mutation<UpdateUserChoiceMapRes>({
          mutation: UPDATE_USER_CHOICE_MAP,
          variables: {
            mapChoice: mapChoice,
          },
          fetchPolicy: 'network-only',
        });
        const optionChanged = response?.data?.updateUserChoiceMap;
        if (optionChanged && dispatch)
          dispatch(onBoardingActions.setUserMapChoice(mapChoice));
      } finally {
        setLoading(false);
      }
    },
    [dispatch],
  );

  return [updateUserChoiceMap, loading];
}
