import {ChevronDownIcon, ChevronUpIcon} from 'native-base';
import {Dimensions, StyleSheet, TouchableOpacity} from 'react-native';

import {H5Text, H6Text} from '@buncha/components/core/text';
import If from '@buncha/components/conditional/If';
import React, {useCallback} from 'react';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {CustomFlatList} from '@buncha/components/core/list';
import Touchable from '@buncha/components/core/touchable/Touchable';
import {Box} from '@buncha/components/core/view';
import StoreLogo from '../../../common/components/StoreLogo';

const CustomDropdown = (props: {
  label: string;
  selectedValue: any;
  data: any;
  open: boolean;
  onOpen: Function;
  children: any;
  onPress: Function;
}) => {
  const theme = useColorThemeType('main');

  const renderDropdownElement = useCallback(
    ({item, index}: {item: any; index: number}) => {
      return (
        <Touchable
          key={index}
          style={[
            styles.storeContainer,
            {
              borderColor: theme?.text?.gray?.[300],
            },
          ]}
          onPress={() => {
            props.onPress(item);
          }}>
          <StoreLogo
            boxStyle={styles.boxStyle}
            imageBoxStyle={styles.imageStyle}
            storeLogoUrl={item.StoreLogo}
          />
          <Box style={[styles.jcc]}>
            <H5Text
              style={[
                styles.storeTypo,
                {
                  color: theme?.text?.blue[300],
                },
              ]}>
              {item?.Store}
            </H5Text>
          </Box>
        </Touchable>
      );
    },
    [props, theme?.text?.blue, theme?.text?.gray],
  );

  const renderDropdown = () => {
    if (props.open)
      return (
        <CustomFlatList data={props?.data} renderItem={renderDropdownElement} />
      );
  };

  return (
    <>
      <TouchableOpacity
        style={[
          styles.button,
          {
            borderColor: props?.selectedValue
              ? theme?.text.blue[300]
              : theme?.text?.gray?.[300],
          },
        ]}
        onPress={() => props.onOpen()}>
        <If condition={props?.selectedValue}>
          <StoreLogo
            boxStyle={styles.boxStyle}
            imageBoxStyle={styles.imageStyle}
            storeLogoUrl={props?.selectedValue?.StoreLogo}
          />
        </If>
        <H6Text
          style={[
            styles.buttonText,
            {
              color: props?.selectedValue?.Store
                ? theme?.text.blue[300]
                : theme?.text?.gray?.[100],
            },
          ]}>
          {props?.selectedValue?.Store || props.label}
        </H6Text>
        <If condition={!props?.open}>
          <ChevronDownIcon
            viewBox="0 -3 24 24"
            color={
              props?.selectedValue
                ? theme?.text.blue[300]
                : theme?.text?.gray?.[300]
            }
          />
        </If>
        <If condition={props?.open}>
          <ChevronUpIcon
            viewBox="0 -3 24 24"
            color={
              props?.selectedValue
                ? theme?.text.blue[300]
                : theme?.text?.gray?.[300]
            }
          />
        </If>
      </TouchableOpacity>
      <If condition={props.open}>
        <Box
          style={[
            styles.dropdown,
            {
              borderColor: theme?.text?.gray?.[200],
            },
          ]}>
          {renderDropdown()}
        </Box>
      </If>
    </>
  );
};

export default React.memo(CustomDropdown);

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    // width: '90%',
    paddingHorizontal: 10,
    // zIndex: 1,
    borderRadius: 150,
    borderWidth: 1,
    marginTop: 10,
  },
  buttonText: {
    flex: 1,
    textAlign: 'left',
    fontWeight: '900',
    marginLeft: 10,
  },
  dropdown: {
    // position: 'absolute',
    backgroundColor: '#fff',
    // top: 70,
    borderWidth: 1,
    width: Dimensions.get('window').width * 0.9,
    borderRadius: 5,
    marginTop: 5,
    // padding: 10,
    paddingVertical: 10,
    height: Dimensions.get('screen').width * 0.7,
    zIndex: 100,
    overflow: 'hidden',
    flexGrow: 1,
  },
  storeContainer: {
    padding: 10,
    // borderTopWidth: 0.25,
    borderBottomWidth: 0.25,
    justifyContent: 'flex-start',
    // maxHeight: 20,
    flexDirection: 'row',
  },
  storeTypo: {
    textAlign: 'left',
    marginLeft: 20,
  },
  boxStyle: {
    height: 37,
    width: 37,
  },
  imageStyle: {
    height: 30,
    width: 30,
  },
  jcc: {
    justifyContent: 'center',
  },
});
