import {Bucket, FileUploadData, UploadFileType, UploadingStatus} from './type';

import {ImageUploaderService} from '.';
import {StorageKeys} from '../storage/type';
import isEmpty from 'lodash/isEmpty';
import listener from '../listener';
import {storage} from '../storage';

export const useUploadImage = () => {
  const uploadeImageService = (
    bucketType: Bucket,
    fileName: string,
    base64: string,
    runId: number,
    storeIndex?: string,
  ) => {
    switch (bucketType) {
      case Bucket.upcImages:
        const upcURL = ImageUploaderService.uploadUpcImages(
          fileName,
          base64 || '',
          storeIndex || '',
          runId,
        );
        return upcURL;
      case Bucket.orderImage:
        const receiptUrl = ImageUploaderService.uploadRunReceipt(
          fileName,
          base64 || '',
          runId,
        );
        return receiptUrl;
      default:
        break;
    }
  };
  return {uploadeImageService};
};

export const StoreImageProgress = async (
  runId: number,
  fileName: string,
  uploadFileType: UploadFileType,
) => {
  const imageData = {[fileName]: uploadFileType};
  let uploaderData: FileUploadData = {};

  const data = await storage.getItem(StorageKeys.FileUploadData);

  if (data) uploaderData = JSON.parse(data);

  if (!uploaderData[runId]) uploaderData[runId] = {};

  uploaderData[runId] = {
    ...uploaderData[runId],
    ...imageData,
  };

  await storage.setItem(
    StorageKeys.FileUploadData,
    JSON.stringify(uploaderData),
  );

  listener.emit('uploadingProgress', uploaderData);
};

export const UpdateUploadingStatus = async (
  runId: number,
  fileName: string,
  uploadingStatus: UploadingStatus,
  progress: number,
) => {
  const data = await storage.getItem(StorageKeys.FileUploadData);
  if (!data) return;

  const uploaderData: FileUploadData = JSON.parse(data);

  const extRunWishData = uploaderData[runId];

  if (extRunWishData && extRunWishData[fileName]) {
    extRunWishData[fileName].uploadingStatus = uploadingStatus;
    extRunWishData[fileName].progress = progress;
    await storage.setItem(
      StorageKeys.FileUploadData,
      JSON.stringify(uploaderData),
    );
    listener.emit('uploadingProgress', uploaderData);
  }
};

export const DeleteUploadedImageData = async (
  runId: number,
  fileName: string,
) => {
  const data = await storage.getItem(StorageKeys.FileUploadData);
  if (!data) return;

  const uploaderData: FileUploadData = JSON.parse(data);
  const extRunWishData = uploaderData[runId];

  if (extRunWishData && extRunWishData[fileName]) {
    delete extRunWishData[fileName];

    if (isEmpty(extRunWishData)) delete uploaderData[runId];

    await storage.setItem(
      StorageKeys.FileUploadData,
      JSON.stringify(uploaderData),
    );
    listener.emit('uploadingProgress', uploaderData);
  }
};
