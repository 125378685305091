import {ChevronDownIcon, ChevronUpIcon} from 'native-base';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {Accordian} from '../../../components/composites';
import {Box} from '../../../components/core/view';
import {CustomText} from '../../../components/core/text';
import PendingShiftList from '../PendingShiftList';
import React from 'react';
import {styles} from './styles';
import {useAppSelector} from '../../../config/redux/hooks';

function PendingShifts() {
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const globalShiftData = useAppSelector(gState => gState.home.shiftData);
  const pendingShifts = globalShiftData.pending;

  return (
    <Box>
      <Accordian
        style={[
          styles.mainContainer,
          {
            backgroundColor: theme?.background.red[100],
          },
        ]}
        isExpanded={false}
        header={
          <Box>
            <CustomText
              style={[
                {
                  fontFamily: muliFont[600].normal,
                  color: theme?.text.white[100],
                },
              ]}>
              Pending Orders from Previous Shifts
            </CustomText>
          </Box>
        }
        children={<PendingShiftList pendingShifts={pendingShifts} />}
        iconEnable={<ChevronUpIcon color={theme?.text.white[100]} />}
        iconDisable={<ChevronDownIcon color={theme?.text.white[100]} />}
      />
    </Box>
  );
}

export default React.memo(PendingShifts);
