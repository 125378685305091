import {AboutShiftContextState} from '../types';
import {DefaultContext} from '../../utils/types';
import React from 'react';

export const initialState: AboutShiftContextState = {
  batches: {
    notStartedShift: [],
    startedShift: [],
    inspectVan: false,
  },
};

const defaultVal: DefaultContext<AboutShiftContextState> = {
  state: initialState,
  dispatch: null,
};

export const AboutShiftContext = React.createContext(defaultVal);
