import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 20,
  },
  mainHeaderContainer: {
    flex: 1,
    marginRight: 10,
  },
  countBox: {
    paddingHorizontal: 15,
    borderRadius: 50,
    paddingVertical: 2.5,
  },
  categoryText: {
    marginBottom: 5,
  },
  divider: {
    marginVertical: 15,
  },
  contentContainer: {paddingBottom: 40, flexGrow: 1},
  scrollContainer: {
    marginTop: 20,
  },
  skeletonContainer: {
    marginHorizontal: 20,
  },
});
