import {H5Text} from '@buncha/components/core/text';
import {Memo} from '@buncha/memos/types';
import MemoRow from '../MemoRow';
import React from 'react';
import {StyleSheet} from 'react-native';
import If from '@buncha/components/conditional/If';
import {Box} from '@buncha/components/core/view';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';
import {useAppSelector} from '@buncha/config/redux/hooks';

interface Props {
  unreadMemos: Memo[] | undefined;
  readMemoAvailable: boolean;
}

const HeaderComponent = (props: Props) => {
  const {unreadMemos, readMemoAvailable} = props;
  const muliFont = useFontTheme();
  const unreadMemoCount = useAppSelector(
    gState => gState.onboarding.unreadMemoCount,
  );
  const theme = useColorThemeType();
  return (
    <Box style={styles.outerBox}>
      <If condition={unreadMemoCount}>
        <H5Text
          style={[
            styles.marTop10,
            {
              fontFamily: muliFont[900].normal,
              color: theme?.text.blue[300],
            },
          ]}>
          Unread messages({unreadMemoCount})
        </H5Text>
        {unreadMemos?.map(memo => (
          <MemoRow key={memo.id} memo={memo} divider />
        ))}
      </If>
      <If condition={readMemoAvailable}>
        <H5Text
          style={{
            fontFamily: muliFont[900].normal,
            color: theme?.text.blue[300],
          }}>
          Read messages
        </H5Text>
      </If>
    </Box>
  );
};

export default React.memo(HeaderComponent);

const styles = StyleSheet.create({
  outerBox: {},
  marTop10: {
    marginTop: 10,
  },
});
