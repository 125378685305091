import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 20,
  },
  mainHeaderContainer: {
    flex: 1,
    marginRight: 10,
  },
  countBox: {
    paddingHorizontal: 15,
    borderRadius: 50,
    paddingVertical: 2.5,
  },
  divider: {
    marginVertical: 15,
  },
  parentBox: {
    flex: 1,
  },
  iconStyle: {
    marginRight: 10,
  },
  refreshControl: {zIndex: 100},
  skeletonContainer: {marginHorizontal: 20},
});
