import React, {useCallback} from 'react';

import {CustomFlatList} from '../../../components/core/list';
import ShiftBatchItem from './ShiftBatchItem';
import {Shifts} from '../../../home/types';
import {StyleSheet} from 'react-native';

interface ShiftBatchListProps {
  shiftList: Shifts[];
}

function ShiftBatchList(props: ShiftBatchListProps) {
  const {shiftList} = props;
  const renderItem = useCallback(({item}: {item: Shifts; index: number}) => {
    return <ShiftBatchItem shift={item} />;
  }, []);
  return (
    <CustomFlatList
      renderItem={renderItem}
      data={shiftList}
      style={styles.flatListContainer}
    />
  );
}

export default React.memo(ShiftBatchList);

const styles = StyleSheet.create({
  titleText: {
    textAlign: 'left',
  },
  dotSeparator: {
    marginTop: -10,
    marginHorizontal: 10,
  },
  runSubtext: {
    textAlign: 'left',
  },
  subTextBox: {
    justifyContent: 'flex-start',
  },
  flatListContainer: {
    marginTop: 10,
  },
});
