import {Dimensions, Pressable, StyleSheet} from 'react-native';
import React, {useCallback, useRef, useState} from 'react';
import {getOS, getWidth} from '../../../utils/common';

import {Box} from '../../../components/core/view';
import {H3Text} from '../../../components/core/text';
import {TextInputGroup} from '../../../components/core/input';
import {onBoardingActions} from '../../reducer';
import {useAppDispatch} from '../../../config/redux/hooks';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

interface OTPInputProps {
  code: any;
  setCode: (text: string) => void;
  maximumLength: number;
}

const OTPInput = (props: OTPInputProps) => {
  const inputRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const theme = useColorThemeType();
  const width = Dimensions.get('window').width;

  const [isInputBoxFocused, setIsInputBoxFocused] = useState(false);

  const handleOnPress = () => {
    setIsInputBoxFocused(true);
    if (inputRef?.current) {
      if (getOS() === 'android') setTimeout(() => inputRef.current.focus(), 0);
      inputRef?.current?.focus();
    }
  };

  const handleOnBlur = () => {
    setIsInputBoxFocused(false);
  };

  const updateOTP = useCallback(
    (val: string) => {
      dispatch(onBoardingActions.setSmsCode(val));
      props.setCode(val);
    },
    [dispatch, props],
  );

  const boxArray = new Array(props.maximumLength).fill(0);

  const boxDigit = (_: any, index: any) => {
    const emptyInput = '';
    const digit = props.code[index] || emptyInput;
    const isCurrentValue = index === props.code.length;
    const isLastValue = index === props.maximumLength - 1;
    const isCodeComplete = props.code.length === props.maximumLength;
    const boxWidth = (Number(getWidth(width)) / boxArray.length) * 0.7;

    const isValueFocused = isCurrentValue || (isLastValue && isCodeComplete);
    const boxContainer =
      isInputBoxFocused && isValueFocused
        ? [
            styles.SplitBoxes,
            {backgroundColor: theme?.background.blue[200], width: boxWidth},
          ]
        : [
            styles.SplitBoxes,
            {
              width: boxWidth,
            },
          ];
    return (
      <Box key={index} style={boxContainer}>
        <H3Text style={styles.SplitBoxText}>{digit}</H3Text>
      </Box>
    );
  };

  return (
    <Box style={styles.OTPInputContainer}>
      <Pressable style={styles.SplitOTPBoxesContainer} onPress={handleOnPress}>
        {boxArray.map(boxDigit)}
      </Pressable>
      <TextInputGroup.PTextInput
        value={props.code}
        onChangeText={updateOTP}
        maxLength={props.maximumLength}
        ref={inputRef}
        onBlur={handleOnBlur}
        debounce={false}
        style={styles.TextInputHidden}
        colorThemeType="default"
        keyboardType="number-pad"
        autoFocus
      />
    </Box>
  );
};

export default React.memo(OTPInput);

export const styles = StyleSheet.create({
  OTPInputContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  TextInputHidden: {
    position: 'absolute',
    opacity: 0,
  },
  SplitOTPBoxesContainer: {
    flexDirection: 'row',
  },
  SplitBoxes: {
    borderColor: '#100e3a',
    borderWidth: 2,
    padding: 12,
    height: 65,
    marginRight: '5%',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  SplitBoxText: {
    fontSize: 20,
    textAlign: 'center',
    color: '#100e3a',
  },
  SplitBoxesFocused: {
    backgroundColor: 'grey',
  },
});
