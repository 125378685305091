import {ColorThemeOptions} from '@buncha/appStyles/style/type';
import {PButtonProps} from './type';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

export function useButtonStyle(props: PButtonProps) {
  const {isLoading, type, changeStyleOnLoading} = props;

  const theme = useColorThemeType();

  const hasDisableLoading = isLoading && changeStyleOnLoading;
  const isOutline = type === 'outline';

  return isOutline
    ? getOutlineStyle(props, hasDisableLoading, theme)
    : getFilledStyle(props, hasDisableLoading, theme);
}

const getOutlineStyle = (
  props: PButtonProps,
  hasDisableLoading?: boolean,
  theme?: ColorThemeOptions,
) => {
  const {
    title,
    loadingText,
    titleColor: initialTitleColor,
    borderColor: initialBorderColor,
    bgColor: initialBgColor,
    borderWidth: initialBorderWidth,
    titleDisableColor,
    borderDisableColor,
  } = props;

  const titleColor = initialTitleColor || theme?.background.gray[200];
  const borderColor = initialBorderColor || theme?.background.gray[200];
  const backgroundColor = initialBgColor || theme?.background.white[100];
  const borderWidth = initialBorderWidth || 2;

  if (hasDisableLoading)
    return {
      titleButton: loadingText || '',
      titleColor: titleDisableColor || theme?.background.gray[200],
      borderColor: borderDisableColor || theme?.background.gray[200],
      backgroundColor,
      borderWidth,
    };

  return {
    titleButton: title as string,
    titleColor,
    borderColor,
    backgroundColor,
    borderWidth,
  };
};

const getFilledStyle = (
  props: PButtonProps,
  hasDisableLoading?: boolean,
  theme?: ColorThemeOptions,
) => {
  const {
    title,
    loadingText,
    titleColor: initialTitleColor,
    borderColor: initialBorderColor,
    bgColor: initialBgColor,
    borderWidth: initialBorderWidth,
    titleDisableColor,
    disabledBgColor,
  } = props;
  const titleColor = initialTitleColor || theme?.text.white[100];
  const backgroundColor = initialBgColor || theme?.background.green[100];
  const borderColor = initialBorderColor || theme?.background.green[100];
  const borderWidth = initialBorderWidth || 0;

  if (hasDisableLoading)
    return {
      titleButton: loadingText || '',
      titleColor: titleDisableColor || theme?.text.white[100],
      backgroundColor: disabledBgColor || theme?.background.green[200],
      borderColor: initialBorderColor || theme?.background.green[200],
      borderWidth,
    };

  return {
    titleButton: title as string,
    titleColor,
    borderColor,
    backgroundColor,
    borderWidth,
  };
};
