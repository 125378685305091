import {
  BatchInfo as BatchInfoType,
  RunType,
  ShopperSessionStatus,
  WPosition,
} from '../../../AboutShift/types/';
import {Box, HBox} from '../../../components/core/view';
import {
  DeliveryLocationStatus,
  OrderItemStatus,
} from '../../../batchDetails/types';
import {
  HomeScreens,
  ShopperScreens,
} from '../../../../src/home/navigation/types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {convertBase64, isWeb, requestPermissions} from '@buncha/utils/common';
import {
  useAboutShiftContext,
  useGetBatches,
  useUpdateShopperSessionStatus,
} from '../../../AboutShift/hooks/AboutShift';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';
import {
  useGetItemStatusCount,
  useGetMinimumDeliveryStatus,
  useVanDeliveryImages,
} from '../../../batchDetails/hooks/OrderOperation';
import {useIsFocused, useRoute} from '@react-navigation/native';

import {Analytics} from '../../../config/analytics/analytics';
import BagTrackingModal from '../BagTrackingModal';
import BatchInfo from '../BatchInfo';
import BatchListHeader from '../BatchListHeader';
import {CameraComponentState} from '@buncha/common/types/common';
import CameraModal from '@buncha/common/components/CameraModal/CameraModal';
import {CustomText} from '@buncha/components/core/text';
import DeliveryVanIcon from '@buncha/components/core/icon/DeliveryVanIcon';
import {Divider} from 'native-base';
import {DriverScreens} from '../../../../src/driver/navigation/type';
import {EventType} from '../../../config/analytics/type';
import If from '@buncha/components/conditional/If';
import ImageBottomSheet from '@buncha/common/components/BottomSheet/ImageBottomSheet';
import {Linking} from 'react-native';
import {PButton} from '../../../components/core/Button';
import {UserTypes} from '@buncha/onBoarding/types';
import VanLocation from '../VanLocation';
import {batchDetailsActions} from '@buncha/batchDetails/reducer';
import listener from '../../../config/listener';
import {navigation} from '../../../config/navigation';
import {styles} from './style';
import {useAppDispatch} from '../../../config/redux/hooks';
import {useCheckSubmittedBagCount} from '../../../AboutShift/hooks/BagTracking';

interface BatchListItemProps {
  index: number;
  item: BatchInfoType;
  coordinates?: {lat: number; long: number};
  activeShift: boolean;
  online: boolean;
  batchNo?: number;
  onValueChange: () => void;
  inspectVan: boolean;
}

function BatchListItem(props: Readonly<BatchListItemProps>) {
  const {item, coordinates, activeShift, inspectVan} = props;
  const muliFont = useFontTheme('muli');
  const route = useRoute();
  const params: {shiftId?: string; shiftKey?: string} | undefined =
    route?.params;
  const appDispatch = useAppDispatch();
  const {dispatch} = useAboutShiftContext();
  const [updateShopperSessionStatus] =
    useUpdateShopperSessionStatus(appDispatch);
  const theme = useColorThemeType();
  const [getItemStatusCount, countState] = useGetItemStatusCount(null);
  const isFocused = useIsFocused();
  const [bagCountModal, setBagCountModal] = useState(false);
  const [checkSubmittedBagCount, submitBagState] = useCheckSubmittedBagCount();
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [getBatches] = useGetBatches(dispatch);
  const [openCameraModal, setOpenCameraModal] = useState(false);

  const toggleCameraModal = useCallback(() => {
    setOpenCameraModal(!openCameraModal);
  }, [openCameraModal]);

  const {
    confirmVanImages,
    vanImages,
    vanDeliveryImageLoading,
    deleteVanImage,
    uploadVanImage,
  } = useVanDeliveryImages();
  const [getMinimumDeliveryStatus, minStatusState] =
    useGetMinimumDeliveryStatus(dispatch);

  const currentActiveBatch = Boolean(activeShift);
  const shopperSession = item.shopperSession;
  const taskFinished = Boolean(item.state === WPosition.none);
  const shopperTask = Boolean(item.state === WPosition.Shopper);
  const loading =
    !currentActiveBatch ||
    countState.loading ||
    taskFinished ||
    submitBagState.loading ||
    minStatusState.loading ||
    minStatusState.loading;

  const store = item.shift[item.activeShiftIndex].store;
  const masterRun = item?.run?.masterRun;
  const runType = item?.run.runType;
  const runId = item?.run?.id;

  const taskCount = shopperTask
    ? item?.task.shopperTask
    : item?.task.driverTask;

  const minimumOrderStatusForDriver = useMemo(() => {
    return [
      DeliveryLocationStatus.driverAssigned,
      DeliveryLocationStatus.onTheWay,
      DeliveryLocationStatus.delivered,
    ];
  }, []);

  const getButtonText = useMemo(() => {
    const ctaName = item?.ctaName ?? '';
    if (ctaName) return ctaName;
    switch (item?.state) {
      case WPosition.none:
        return 'Shift Finished';
      case WPosition.Driver:
        return 'Arrived at store';
      default:
        return 'Start Shopping';
    }
  }, [item?.ctaName, item?.state]);

  const hasDriverShift = useMemo(() => {
    return item.state === WPosition.Driver;
  }, [item.state]);

  const hasNormalRun = useMemo(() => {
    return runType === RunType.normal;
  }, [runType]);

  const navigateToBatchDetail = useCallback(() => {
    Analytics.eventWithProps(`${getButtonText}`, {
      type: EventType.cta,
      storeName: store?.name,
      runId: runId,
      orderCount: taskCount?.orderCount,
      itemCount: taskCount?.itemCount,
    });
    navigation.navigate(HomeScreens.Shopper, {
      screen: ShopperScreens.BatchDetailsPage,
      params: {runId: item.run.id},
    });
  }, [
    getButtonText,
    item.run.id,
    runId,
    store?.name,
    taskCount?.itemCount,
    taskCount?.orderCount,
  ]);

  const onPressGoPicked = useCallback(() => {
    if (shopperSession?.sessionStatus === ShopperSessionStatus.notStarted)
      updateShopperSessionStatus(
        shopperSession?.id,
        ShopperSessionStatus.started,
        shopperSession?.shiftId,
        coordinates?.lat ?? 0,
        coordinates?.long ?? 0,
      );
    navigateToBatchDetail();
  }, [
    coordinates?.lat,
    coordinates?.long,
    navigateToBatchDetail,
    shopperSession?.id,
    shopperSession?.sessionStatus,
    shopperSession?.shiftId,
    updateShopperSessionStatus,
  ]);

  const navigateToOrderPack = useCallback(
    (minStatus?: DeliveryLocationStatus) => {
      Analytics.eventWithProps(`${getButtonText}`, {
        type: EventType.cta,
        storeName: store?.name,
        runId: runId,
        orderCount: taskCount?.orderCount,
        itemCount: taskCount?.itemCount,
      });

      if (minStatus === DeliveryLocationStatus.toBeAssigned && !hasNormalRun)
        navigation.navigate(HomeScreens.Shopper, {
          screen: ShopperScreens.VanLoadSite,
          params: {runId: item.run.id},
        });
      else
        navigation.navigate(HomeScreens.Shopper, {
          screen: ShopperScreens.OrderPackingPage,
          params: {runId: runId},
        });
    },
    [
      getButtonText,
      hasNormalRun,
      item.run.id,
      runId,
      store?.name,
      taskCount?.itemCount,
      taskCount?.orderCount,
    ],
  );

  const navigateToDriverOrders = useCallback(
    (minStatus?: DeliveryLocationStatus) => {
      if (
        (minStatus && minimumOrderStatusForDriver.includes(minStatus)) ||
        hasNormalRun
      )
        return navigation.navigate(HomeScreens.Driver, {
          screen: DriverScreens.DriverOrders,
          params: {runId: runId},
        });
      return navigation.navigate(HomeScreens.Driver, {
        screen: DriverScreens.LoadVanAt,
        params: {runId: runId},
      });
    },
    [hasNormalRun, runId, minimumOrderStatusForDriver],
  );

  const onPress = useCallback(async () => {
    if (hasDriverShift) {
      if (!inspectVan) {
        let userType = UserTypes.driver;
        const minOrderStatusForDriver = await getMinimumDeliveryStatus(
          runId,
          userType,
        );
        Analytics.eventWithProps(`${getButtonText}`, {
          type: EventType.cta,
          storeName: store?.name,
          runId: runId,
          orderCount: taskCount?.orderCount,
          itemCount: taskCount?.itemCount,
        });
        return navigateToDriverOrders(minOrderStatusForDriver);
      }
      return setImageModalOpen(true);
    }

    const minOrderStatus = await getMinimumDeliveryStatus(runId);

    appDispatch(batchDetailsActions.setBatchTaskInfo(item?.task));

    if (minOrderStatus === DeliveryLocationStatus.notStarted)
      return onPressGoPicked();
    return navigateToOrderPack(minOrderStatus);
  }, [
    hasDriverShift,
    getMinimumDeliveryStatus,
    runId,
    item?.task,
    appDispatch,
    inspectVan,
    getButtonText,
    store?.name,
    taskCount?.orderCount,
    taskCount?.itemCount,
    navigateToDriverOrders,
    onPressGoPicked,
    navigateToOrderPack,
  ]);

  const toggleBagCountModal = useCallback(() => {
    setBagCountModal(!bagCountModal);
  }, [bagCountModal]);

  const onShiftSubmit = useCallback(() => {
    if (hasDriverShift || submitBagState.data?.bagsCount) onPress();
    else toggleBagCountModal();
  }, [
    hasDriverShift,
    submitBagState.data?.bagsCount,
    onPress,
    toggleBagCountModal,
  ]);

  const bagCountModalSubmit = useCallback(() => {
    toggleBagCountModal();
    onPress();
  }, [onPress, toggleBagCountModal]);

  const onRefreshEvent = useCallback(() => {
    if (runId && currentActiveBatch)
      getItemStatusCount(runId, [
        OrderItemStatus.notPicked,
        OrderItemStatus.picked,
      ]);
  }, [currentActiveBatch, getItemStatusCount, runId]);

  const uploadVanLoadImageWeb = useCallback(
    async (file: any) => {
      let imgArray: any = [];
      let fileArray: any = Object.values(file.target.files);
      for (let i = 0; i < fileArray.length; i++) {
        const base64Data = await convertBase64(fileArray[i]);
        const base64Trimeed = base64Data as string;
        const returnData = {
          uri: fileArray[i].name,
          base64: base64Trimeed.split(',')[1],
        };
        imgArray[i] = returnData;
      }

      uploadVanImage(imgArray[0]?.base64, runId);
    },
    [runId, uploadVanImage],
  );

  const onConfirmhandler = useCallback(async () => {
    if (!params?.shiftId) return;
    await confirmVanImages(params?.shiftId);
    setImageModalOpen(false);
    getBatches(params?.shiftId);
  }, [confirmVanImages, getBatches, params?.shiftId]);

  const captureImageHandler = useCallback(
    async (data?: any) => {
      if (data && isWeb()) return uploadVanLoadImageWeb(data);
      const permissionStatus = await requestPermissions();
      if (permissionStatus)
        try {
          toggleCameraModal();
        } catch (error) {}
      else Linking.openSettings();
    },
    [toggleCameraModal, uploadVanLoadImageWeb],
  );

  useEffect(() => {
    if (runId && currentActiveBatch && isFocused)
      getItemStatusCount(runId, [
        OrderItemStatus.notPicked,
        OrderItemStatus.picked,
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId, currentActiveBatch, isFocused]);

  useEffect(() => {
    if (isFocused && runId) checkSubmittedBagCount(runId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, runId]);

  useEffect(() => {
    const itemStatusCount = listener.addListener(
      'getItemStatusCount',
      onRefreshEvent,
    );
    return itemStatusCount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHeaderTitle = useMemo(() => {
    if (!hasDriverShift) return 'Shopping Location';
    return 'Van Load Location';
  }, [hasDriverShift]);

  const imageUploadHandler = useCallback(
    (data: CameraComponentState) => {
      if (data) uploadVanImage(data.base64, runId);
    },
    [runId, uploadVanImage],
  );

  return (
    <Box>
      <BatchListHeader headerTitle={getHeaderTitle} />
      <BatchInfo
        run={item.run}
        taskCount={item.task.shopperTask}
        hideDriverInfo={!hasDriverShift && !hasNormalRun}
        hideStoreInfo={hasDriverShift}
      />
      <PButton
        title={getButtonText}
        style={[styles.actionButton]}
        titleStyle={{
          fontFamily: muliFont[700].normal,
        }}
        isLoading={loading}
        changeStyleOnLoading
        loadingText={getButtonText}
        onPress={onShiftSubmit}
      />
      <If condition={!hasNormalRun && masterRun && !hasDriverShift}>
        <Divider style={styles.loader} />
        <VanLocation batchInfo={item} />
      </If>
      <BagTrackingModal
        isOpen={bagCountModal}
        onClose={toggleBagCountModal}
        onSubmit={bagCountModalSubmit}
        runId={item.run.id}
      />
      <If condition={inspectVan && hasDriverShift}>
        <HBox style={styles.deliveryBox}>
          <DeliveryVanIcon viewBox="0 0 35 35" fill={theme?.icon.blue[300]} />
          <CustomText style={styles.text}>
            Complete the Van Inspection checklist before starting the delivery
          </CustomText>
        </HBox>
      </If>
      <ImageBottomSheet
        bottomSheetOpen={imageModalOpen}
        title={'Attach Checklist'}
        subtitle={
          'Fill out Van Quality form and submit photos of form and van state before starting deliveries'
        }
        loading={vanDeliveryImageLoading}
        imageList={vanImages}
        showCaptureModeFunc={captureImageHandler}
        closeBottomSheet={() => setImageModalOpen(false)}
        handleImageDelete={deleteVanImage}
        hasDeleteModal
        uploadButtonHandler={onConfirmhandler}
        hasShowBottomButton
      />
      <If condition={openCameraModal}>
        <CameraModal
          capturedImage={imageUploadHandler}
          open={openCameraModal}
          closeModal={toggleCameraModal}
        />
      </If>
    </Box>
  );
}

export default React.memo(BatchListItem);
