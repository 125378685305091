import React from 'react';
import {TouchableProps} from './type';

import {TouchableOpacity, ViewStyle} from 'react-native';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

const Touchable: React.FC<TouchableProps<TouchableOpacity>> = React.forwardRef(
  (props, ref) => {
    const theme = useColorThemeType();
    const defaultStyle: ViewStyle = {
      backgroundColor: theme?.background.white[100],
      justifyContent: 'center',
    };
    const propsToSpread = {...props};
    propsToSpread.style = [defaultStyle, props.style];
    const loadingSkeleton = props.loadingSkeleton;
    if (props.loading)
      return <React.Fragment>{loadingSkeleton}</React.Fragment>;
    return (
      <TouchableOpacity activeOpacity={0.8} {...propsToSpread} ref={ref}>
        {props.children}
      </TouchableOpacity>
    );
  },
);

export default Touchable;
