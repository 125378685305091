import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  divider: {
    marginTop: 15,
  },
  touchableStyle: {
    padding: 5,
  },
});
