import {
  BatchDetailContextState,
  ReceiptBypassMode,
  itemsSortBy,
} from '../types';

import {DefaultContext} from '../../utils/types';
import React from 'react';

export const initialState: BatchDetailContextState = {
  sortBarValue: itemsSortBy.category,
  showSortBar: true,
  groupedAssignedItems: {
    todo: [],
    inReview: [],
    done: [],
    all: [],
  },
  tabDataCount: {
    todoCount: 0,
    doneCount: 0,
    inReviewCount: 0,
  },
  globalLoading: true,
  remainingItemCount: [0],
  orderAssignee: '',
  receipts: [],
  tutorial: false,
  hasPickedOrderModal: false,
  orderIdsToBeSend: [],
  openUploadReceiptModal: false,
  upcOrderData: {
    upcOrder: undefined,
    orderItems: [],
  },
  disablePickingRadioButton: false,
  disableToDo: false,
  minimumDeliveryLocationStatus: undefined,
  hasSkipReceipt: ReceiptBypassMode.none,
};

const defaultVal: DefaultContext<BatchDetailContextState> = {
  state: initialState,
  dispatch: null,
};

export const BatchDetailContext = React.createContext(defaultVal);
