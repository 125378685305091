import {Box, HBox} from '@buncha/components/core/view';
import React, {useCallback, useMemo, useState} from 'react';

import {Analytics} from '@buncha/config/analytics/analytics';
import Call from '@buncha/components/core/icon/Call';
import CallDriverBottomSheet from './CallDriverBottomSheet';
import {CustomText} from '@buncha/components/core/text';
import If from '@buncha/components/conditional/If';
import ScreenHeader from '@buncha/common/components/screenHeader';
import {Touchable} from '@buncha/components/core/touchable';
import {UserStub} from '@buncha/onBoarding/types';
import {VanLoadHeaderStyle} from './styles';
import font from '@buncha/appStyles/style/font';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

interface VanLoadHeaderProps {
  driverName: string;
  driverList: UserStub[];
}

const VanLoadHeader = (props: VanLoadHeaderProps) => {
  const theme = useColorThemeType();
  const {driverName, driverList} = props;

  const [open, setOpen] = useState(false);
  const toggleBottomSheet = useCallback(() => {
    if (driverList?.length) setOpen(!open);
  }, [driverList?.length, open]);

  const hasDriverAssigned = useMemo(() => {
    if (driverList?.length > 0) return true;
    return false;
  }, [driverList.length]);

  const openIntercom = useCallback(() => {
    Analytics.displayMessenger();
  }, []);

  return (
    <Box>
      <ScreenHeader title="Navigate to the Van Load Site" />
      <HBox style={VanLoadHeaderStyle.mainContainer}>
        <Box style={VanLoadHeaderStyle.driverNameBox}>
          <CustomText
            style={[
              VanLoadHeaderStyle.textAlign,
              VanLoadHeaderStyle.driverNameLabel,
            ]}>
            {'Driver Assigned:'}
          </CustomText>
          <If condition={hasDriverAssigned}>
            <CustomText
              style={[
                VanLoadHeaderStyle.textAlign,
                VanLoadHeaderStyle.driverName,
                {
                  color: theme?.text.blue[300],
                },
              ]}
              numberOfLines={1}>
              {driverName}
            </CustomText>
          </If>
          <If condition={!hasDriverAssigned}>
            <CustomText
              style={[
                VanLoadHeaderStyle.driverName,
                {
                  color: theme?.text.gray[100],
                  fontFamily: font.fonts.muliSemiBold,
                },
              ]}>
              There are no driver assigned here,{' '}
              <CustomText
                onPress={openIntercom}
                style={[
                  VanLoadHeaderStyle.driverName,
                  VanLoadHeaderStyle.underLineText,
                  {
                    color: theme?.text.blue[100],
                    fontFamily: font.fonts.muliSemiBold,
                  },
                ]}>
                connect to the dispatch team
              </CustomText>
            </CustomText>
          </If>
        </Box>
        <If condition={hasDriverAssigned}>
          <Touchable
            style={VanLoadHeaderStyle.callDriverBox}
            disabled={driverList?.length <= 0}
            onPress={toggleBottomSheet}>
            <Call viewBox="-6 -6 32 32" fill={theme?.text?.blue[100]} />
            <CustomText
              style={[
                VanLoadHeaderStyle.textAlign,
                VanLoadHeaderStyle.callDriver,
                {
                  color: theme?.text.blue[100],
                },
              ]}>
              Call Driver
            </CustomText>
          </Touchable>
        </If>
      </HBox>
      <CallDriverBottomSheet
        open={open}
        driverList={driverList}
        onClose={toggleBottomSheet}
      />
    </Box>
  );
};

export default React.memo(VanLoadHeader);
