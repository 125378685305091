import {Box, HBox} from '../../../../../src/components/core/view';
import {CustomText, H5Text} from '../../../../../src/components/core/text';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../src/appStyles/hooks/theme';

import {CustomImage} from '../../../../../src/components/core/Image';
import If from '../../../../../src/components/conditional/If';
import React from 'react';
import {ReplacementItemDetails} from '../../../../../src/itemDetails/types/itemReplacement';
import {styles} from './styles';

interface ItemCellToBeReplacedProps {
  itemData?: ReplacementItemDetails;
  isUPCMode?: boolean;
}

const ItemToBeReplacedCell: React.FC<ItemCellToBeReplacedProps> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const item = props?.itemData;
  const isUPCMode = props?.isUPCMode;
  const name = item?.name || '';
  const quantity =
    item?.shopperItemChangesForShopper?.updatedQuantity || item?.quantity || 0;
  const imageUrl = item?.itemImageUrl || '';
  const subNote = item?.substitutionNote || '';
  const price =
    (item?.shopperItemChangesForShopper?.updatedPricePerQuantity ||
      item?.price ||
      0) / 100;
  return (
    <HBox style={styles.parentBox}>
      <Box style={styles.imageBox}>
        <CustomImage style={styles.image} source={imageUrl} />
        <If condition={!isUPCMode}>
          <HBox
            style={[
              styles.qtyBox,
              {
                backgroundColor: theme?.background?.yellow?.[100],
              },
            ]}>
            <CustomText
              style={{
                color: theme?.text?.blue?.[300],
                fontFamily: muli?.[700]?.normal,
              }}>
              Qty: {quantity}
            </CustomText>
          </HBox>
        </If>
      </Box>
      <Box style={styles.textBox}>
        <HBox>
          <Box style={styles.namePriceBox}>
            <H5Text
              numberOfLines={2}
              alignText="left"
              style={{
                color: theme?.text?.blue?.[300],
                fontFamily: muli?.[700]?.normal,
              }}>
              {name}
            </H5Text>
            <H5Text
              alignText="left"
              style={{
                color: theme?.text?.blue?.[300],
                fontFamily: muli?.[700]?.normal,
              }}>
              $ {price}
            </H5Text>
          </Box>
        </HBox>
        <If condition={subNote}>
          <CustomText
            style={{
              color: theme?.text?.gray?.[100],
              fontFamily: muli?.[600]?.normal,
            }}
            numberOfLines={1}
            alignText="left">
            {subNote}
          </CustomText>
        </If>
      </Box>
    </HBox>
  );
};

export default React.memo(ItemToBeReplacedCell);
