import {
  ItemDetailsAction,
  useBuyerManagement,
  useCatalogItemManagement,
  useGetRunForItemDetail,
  useItemDetailsContext,
  useUPCOrder,
} from '../../../hooks/ItemDetails';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {Box} from '../../../../components/core/view';
import BuyerDetails from '../BuyerDetails/BuyerDetails';
import Carousal from '../../../../components/composites/Carousal/Carousal';
import If from '../../../../components/conditional/If';
import ItemInfo from '../ItemInfo/ItemInfo';
import ItemQuantityPill from '../ItemQuantityPill/index';
import LoaderComponents from '@buncha/components/composites/CustomLoader/LoaderComponents';
import {ScrollView} from 'react-native';
import {SubstitutionChoice} from '@buncha/itemDetails/types/itemReplacement';
import ViewReplacementBanner from '../ViewReplacementBanner';
import {getDefaultImageUrl} from '../../../../utils/common';
import {styles} from './styles';
import {useRoute} from '@react-navigation/native';

interface RouteParamsType {
  storeIndex?: string;
  catalogItemId?: number;
  orderItemId?: number;
  runId?: number;
}

interface Props {
  setLoading: (loading: boolean) => void;
  isUPCMode?: boolean;
  loading?: boolean;
}
const ItemDetailsBody = (props: Props) => {
  const {state, dispatch} = useItemDetailsContext();
  const route = useRoute();
  const [getRunForItemDetail, runState] = useGetRunForItemDetail();
  const {getCatalogItemForShopper, loadingItem} =
    useCatalogItemManagement(dispatch);
  const {getBuyersForItem, loadingBuyer} = useBuyerManagement(dispatch);
  const routeParams: RouteParamsType | undefined = route?.params;
  const storeIndex = routeParams?.storeIndex || '';
  const catalogItemId = Number(routeParams?.catalogItemId) || 0;
  const orderItemId = Number(routeParams?.orderItemId) || 0;
  const isCustomItem = Boolean(!state?.item?.itemImageUrls);
  const isMenu = Boolean(!runState.data?.store.elasticSearchIndex);
  const pageLoading = loadingItem || runState.loading;
  const isUPCMode = props?.isUPCMode;
  const scrollRef = useRef<ScrollView>(null);
  const [tipBoxHeight, setTipBoxHeight] = useState(0);
  const [showReplacementBanner, setShowReplacementBanner] = useState(false);

  const hasSubstitute = state.buyersForItem.find(
    item => item.substitutionChoice === SubstitutionChoice.byBuyer,
  );

  const {getItemLocationHistory} = useUPCOrder();

  const imageCaption = useMemo(() => {
    if (isCustomItem || isUPCMode) return '';
    return 'Item not available? Scroll down to add alternative';
  }, [isCustomItem, isUPCMode]);

  const imageData = useMemo(() => {
    if (isCustomItem)
      return [state?.item?.itemImageUrl || getDefaultImageUrl()];
    return state?.item?.itemImageUrls?.largeImages;
  }, [
    isCustomItem,
    state?.item?.itemImageUrl,
    state?.item?.itemImageUrls?.largeImages,
  ]);

  const toggleReplacementBanner = useCallback(() => {
    setShowReplacementBanner(!showReplacementBanner);
  }, [showReplacementBanner]);

  const handleScrollToTop = useCallback(() => {
    toggleReplacementBanner();
    return scrollRef.current?.scrollTo({y: tipBoxHeight + 400, animated: true});
  }, [tipBoxHeight, toggleReplacementBanner]);

  const getLayoutHeight = useCallback((eve: any) => {
    const {y} = eve.nativeEvent.layout;
    setTipBoxHeight(y);
  }, []);

  useEffect(() => {
    if (orderItemId) {
      getCatalogItemForShopper(orderItemId, isUPCMode ?? false);
      if (!props?.isUPCMode)
        getItemLocationHistory(orderItemId, storeIndex, (response: any) => {
          if (dispatch)
            dispatch(ItemDetailsAction.setItemLocationData(response));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeIndex, catalogItemId, orderItemId]);

  useEffect(() => {
    if (hasSubstitute) setShowReplacementBanner(true);
  }, [hasSubstitute]);

  useEffect(() => {
    if (orderItemId && !isUPCMode) getBuyersForItem(orderItemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItemId]);

  useEffect(() => {
    props.setLoading(pageLoading);
  }, [pageLoading, props]);

  useEffect(() => {
    if (routeParams?.runId && !isUPCMode)
      getRunForItemDetail(routeParams?.runId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams?.runId]);

  const getLoadingState = useMemo(
    () => pageLoading || !state?.item,
    [pageLoading, state?.item],
  );

  return (
    <React.Fragment>
      <ScrollView
        ref={scrollRef}
        contentContainerStyle={
          getLoadingState ? styles.contentContainer : undefined
        }>
        <React.Fragment>
          <If condition={getLoadingState || props.loading}>
            <LoaderComponents showBottomText={false} />
          </If>
          <If condition={!getLoadingState}>
            <Box style={styles.bodyContainer}>
              <If condition={state.item?.itemQuantity}>
                <Box style={[styles.pillBody]}>
                  <ItemQuantityPill
                    quantity={state.item?.itemQuantity as string}
                  />
                </Box>
              </If>
              <Carousal
                containerStyle={styles.itemContent}
                imageContainerStyle={styles.itemImage}
                showImageCaption={true}
                imageCaption={imageCaption}
                data={imageData}
                showPagination={true}
              />
            </Box>
            <Box style={styles.childContainer}>
              <ItemInfo
                isUPCMode={isUPCMode ?? false}
                item={state?.item}
                upcItemId={orderItemId}
                orderItemId={orderItemId}
                esIndex={runState?.data?.store?.elasticSearchIndex ?? ''}
              />
              <If condition={!isUPCMode}>
                <Box onLayout={getLayoutHeight}>
                  <BuyerDetails
                    isMenu={isMenu}
                    isCustomItem={isCustomItem}
                    loadingBuyer={loadingBuyer}
                  />
                </Box>
              </If>
            </Box>
          </If>
        </React.Fragment>
      </ScrollView>
      <If condition={!getLoadingState && showReplacementBanner}>
        <ViewReplacementBanner
          onPress={handleScrollToTop}
          toggle={toggleReplacementBanner}
        />
      </If>
    </React.Fragment>
  );
};

export default React.memo(ItemDetailsBody);
