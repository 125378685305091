import {Box} from '../../../../components/core/view';
import {H6Text} from '../../../../components/core/text';
import HourGlass from '../../../../components/core/icon/HourGlass';
import React from 'react';
import {StyleSheet} from 'react-native';
import {getWidth} from '../../../../utils/common';
import {useColorThemeType} from '../../../../appStyles/hooks/theme';
import {getOs} from '../../../../utils/appInfo';

interface PillProps {
  quantity: string;
}

const ItemQuantityPill = (props: PillProps) => {
  const theme = useColorThemeType('main');
  return (
    <Box
      style={[
        styles.parentContainer,
        {backgroundColor: theme?.background?.orange[100]},
      ]}>
      <HourGlass
        fill={theme?.background.white[100]}
        height={20}
        width={20}
        viewBox="0 -7 15 25"
      />
      <H6Text style={[{color: theme?.background.white[100]}]}>
        {props.quantity}
      </H6Text>
    </Box>
  );
};

export default React.memo(ItemQuantityPill);

const styles = StyleSheet.create({
  parentContainer: {
    flexDirection: 'row',
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    minWidth: getWidth(getOs() === 'ios' ? 100 : 80),
    padding: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
