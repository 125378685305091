import {Linking, StyleSheet} from 'react-native';
import React, {useCallback, useMemo} from 'react';

import {Analytics} from '../../../../../../src/config/analytics/analytics';
import {DeliveryLocationStatus} from '../../../../../../src/driver/types/common';
import {DriverScreens} from '../../../../../../src/driver/navigation/type';
import {EventType} from '@buncha/config/analytics/type';
import {HBox} from '../../../../../../src/components/core/view';
import {HomeScreens} from '../../../../../../src/home/navigation/types';
import LocationIcon from '../../../../../../src/components/core/icon/LocationIcon';
import {MapChoice} from '../../../../../../src/onBoarding/types';
import {PButton} from '../../../../../../src/components/core/Button';
import {Touchable} from '../../../../../../src/components/core/touchable';
import {getMapUrl} from '../../../../../../src/utils/common';
import {navigation} from '../../../../../../src/config/navigation';
import {scaleFonts} from '../../../../../../src/appStyles/style/font';
import {useAppSelector} from '../../../../../../src/config/redux/hooks';
import {useColorThemeType} from '../../../../../../src/appStyles/hooks/theme';

interface DriverOrderCellFooterProps {
  lat: number;
  lon: number;
  orderId: number;
  deliveryLocationStatus: DeliveryLocationStatus | string;
  runId: number;
  isFirstItem: boolean;
}

const DriverOrderCellFooter: React.FC<DriverOrderCellFooterProps> = props => {
  const theme = useColorThemeType('main');
  const user = useAppSelector(gstate => gstate.onboarding.currentUser);
  const mapChoice = user?.mapChoice || MapChoice.googleMaps;
  const orderId = props?.orderId || 0;

  const getButtonText = useMemo(() => {
    switch (props?.deliveryLocationStatus) {
      case DeliveryLocationStatus.driverAssigned:
        return 'Start Task';
      case DeliveryLocationStatus.onTheWay:
        return 'Mark as Delivered';
      case DeliveryLocationStatus.delivered:
        return 'Delivered';
      default:
        return 'Start Task';
    }
  }, [props?.deliveryLocationStatus]);

  const openUrl = useCallback(() => {
    const coords = {
      lat: props?.lat || 0,
      lng: props?.lon || 0,
    };
    Analytics.eventWithProps('Open map for driver', {
      mapChoice: mapChoice,
      buyerLat: props?.lat,
      buyerLon: props?.lon,
      type: EventType.cta,
      userType: 'driver',
    });
    Linking.openURL(getMapUrl(mapChoice, coords));
  }, [mapChoice, props?.lat, props?.lon]);

  const navigateToOrderDetails = useCallback(() => {
    navigation.navigate(HomeScreens.Driver, {
      screen: DriverScreens.OrderDetails,
      params: {orderId: orderId, runId: props?.runId},
    });
    Analytics.eventWithProps(`${getButtonText} Delivery`, {
      type: EventType.cta,
      orderId: orderId,
      userType: 'driver',
    });
  }, [getButtonText, orderId, props?.runId]);

  return (
    <HBox style={styles.parentBox}>
      <Touchable
        onPress={openUrl}
        style={[
          styles.locationTouchable,
          {borderColor: theme?.icon?.green?.[100]},
        ]}>
        <LocationIcon
          style={styles.icon}
          viewBox="-3 0 20 20"
          fill={theme?.icon?.green?.[100]}
        />
      </Touchable>
      <PButton
        onPress={navigateToOrderDetails}
        title={getButtonText}
        style={styles.button}
        titleStyle={styles.buttonTitle}
        disabled={!props?.isFirstItem}
      />
    </HBox>
  );
};

export default React.memo(DriverOrderCellFooter);

const styles = StyleSheet.create({
  parentBox: {
    justifyContent: 'flex-start',
    marginTop: 15,
  },
  locationTouchable: {
    borderWidth: 1,
    width: '25%',
    marginRight: 10,
    borderRadius: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 5,
    height: scaleFonts(50),
  },
  icon: {
    height: 30,
    width: 21,
  },
  button: {
    width: '72%',
    borderRadius: 50,
    height: scaleFonts(50),
  },
  buttonTitle: {
    fontSize: scaleFonts(16),
  },
});
