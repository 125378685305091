import {Box, HBox} from '../../components/core/view';
import {CustomText, H5Text} from '../../components/core/text';
import React, {useCallback, useState} from 'react';

import {CustomImage} from '../../components/core/Image';
import {Shift} from '../types/openShifts';
import {StyleSheet} from 'react-native';
import TakeShiftModal from './TakeShiftModal';
import {Touchable} from '../../../src/components/core/touchable';
import {scaleFonts} from '../../../src/appStyles/style/font';
import {useColorThemeType} from '../../appStyles/hooks/theme';

interface OpenShiftStoreCellProps {
  storeShift: Shift;
}

const OpenShiftStoreCell: React.FC<OpenShiftStoreCellProps> = props => {
  const theme = useColorThemeType('main');
  const storeShift = props?.storeShift;
  const store = storeShift?.store;
  const storeName = store?.name || '';
  const storeLogo = store?.storeLogoUrl || '';
  const time = storeShift?.time || '';
  const position = storeShift?.position || '';
  const shiftText = `OpenShift at ${storeName} as ${position}`;
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = useCallback(() => {
    setModalOpen(!modalOpen);
  }, [modalOpen]);
  return (
    <React.Fragment>
      <Touchable onPress={toggleModal}>
        <HBox style={styles.parentBox}>
          <Box style={styles.imageBox}>
            <CustomImage
              style={[
                styles.storeLogo,
                {borderColor: theme?.text?.gray?.[200]},
              ]}
              source={storeLogo}
            />
          </Box>
          <Box style={styles.subContainer}>
            <H5Text
              style={[styles.textAlignment, {color: theme?.text?.blue?.[300]}]}>
              {time}
            </H5Text>
            <CustomText
              style={[styles.storeText, {color: theme?.text?.gray?.[100]}]}
              numberOfLines={1}>
              {shiftText}
            </CustomText>
          </Box>
        </HBox>
      </Touchable>
      <TakeShiftModal
        open={modalOpen}
        onClose={toggleModal}
        storeShift={storeShift}
      />
    </React.Fragment>
  );
};

export default React.memo(OpenShiftStoreCell);

const styles = StyleSheet.create({
  storeLogo: {
    height: 50,
    width: 50,
    borderRadius: 50,
    borderWidth: 2,
  },
  parentBox: {
    justifyContent: 'flex-start',
    marginTop: 10,
  },
  imageBox: {
    marginRight: 20,
  },
  textAlignment: {
    textAlign: 'left',
  },
  storeText: {
    textAlign: 'left',
    fontSize: scaleFonts(12),
    marginTop: 2,
    flex: 1,
  },
  subContainer: {flex: 1},
});
