import {ViewStyle} from 'react-native';
import {useAppDispatch} from '../../../config/redux/hooks';
import {onBoardingActions} from '../../../onBoarding/reducer';
import {IToastProps, Toast} from 'native-base';
import {ToastType} from './type';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

export function useToastMessage(toastType?: ToastType) {
  const dispatch = useAppDispatch();
  const showToast = (
    message: string,
    style?: ViewStyle,
    duration = 3000,
    orientation = 'up',
  ) => {
    if (message.includes('JSON')) message = 'High user volume: Sorry for delay';
    dispatch(
      onBoardingActions.setNotificationToastInfo({
        show: true,
        details: {
          title: message,
          duration: duration,
          containerStyle: style,
          orientation: orientation,
          type: toastType || ToastType.Success,
        },
      }),
    );
  };
  return [showToast];
}

export function useGetToastTheme(toastType: ToastType = ToastType.Success) {
  const theme = useColorThemeType();

  let toastTheme = {
    backgroundColor: theme?.background.white[100],
    textColor: theme?.text.green[100],
    iconColor: theme?.icon.green[100],
  };

  switch (toastType) {
    case ToastType.Success:
      return toastTheme;
    case ToastType.Error:
      toastTheme = {
        ...toastTheme,
        textColor: theme?.text.red[100],
        iconColor: theme?.text.red[100],
      };
      return toastTheme;
    case ToastType.Warning:
      toastTheme = {
        ...toastTheme,
        textColor: theme?.text.blue[300],
        iconColor: theme?.text.orange[100],
      };
      return toastTheme;
    case ToastType.Alert:
      toastTheme = {
        ...toastTheme,
        textColor: theme?.text.blue[300],
        iconColor: theme?.text.red[100],
      };
      return toastTheme;
    default:
      return toastTheme;
  }
}

export function viewToast(toastInfo: IToastProps, duration = 100) {
  const id = 1;
  if (!Toast.isActive(id))
    Toast.show({
      ...toastInfo,
      id,
      duration: duration,
    });
}
