import * as React from 'react';

import Svg, {Path, SvgProps} from 'react-native-svg';

const SearchSync = (props: SvgProps) => (
  <Svg
    // xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <Path
      stroke="#100E3A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m21 21-6-6M3.291 8a7 7 0 0 1 5.077-4.806 7.021 7.021 0 0 1 8.242 4.403"
    />
    <Path
      stroke="#100E3A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 4v4h-4m3.705 4a7 7 0 0 1-5.074 4.798 7.02 7.02 0 0 1-8.241-4.403"
    />
    <Path
      stroke="#100E3A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 16v-4h4"
    />
  </Svg>
);
export default React.memo(SearchSync);
