import {StyleSheet, TextStyle, ViewStyle} from 'react-native';

import Box from '../view/Box';
import {H5Text} from '../text';
import If from '../../../components/conditional/If';
import {InterfaceSpinnerProps} from 'native-base/lib/typescript/components/primitives/Spinner/types';
import React from 'react';
import {Spinner} from 'native-base';
import font from '../../../appStyles/style/font';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

interface Props extends InterfaceSpinnerProps {
  loadingText?: string;
  showTopLoadingText?: boolean;
  showBottomLoadingText?: boolean;
  containerStyle?: ViewStyle;
  textStyle?: TextStyle;
  spinnerStyle?: ViewStyle;
}

const SpinnerComponent = (props: Props) => {
  const theme = useColorThemeType();
  const {showTopLoadingText, loadingText, showBottomLoadingText} = props;

  const defaultTextStyle = {
    fontFamily: font.fonts.muliBold,
    color: theme?.text.blue[300],
  };

  return (
    <Box style={props?.containerStyle || styles.container}>
      <If condition={showTopLoadingText}>
        <H5Text style={[defaultTextStyle, props.textStyle]}>
          {loadingText}
        </H5Text>
      </If>
      <Spinner
        size={'lg'}
        color={theme?.background.blue[300]}
        style={props?.spinnerStyle}
        {...props}
      />
      <If condition={showBottomLoadingText}>
        <H5Text style={[defaultTextStyle, props.textStyle]}>
          {loadingText}
        </H5Text>
      </If>
    </Box>
  );
};

export default React.memo(SpinnerComponent);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
  },
});
