import * as React from 'react';

import Svg, {Path, SvgProps} from 'react-native-svg';

const LocationTick = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      {...props}
      fillRule="evenodd"
      d="M3.145 7.397A6.698 6.698 0 0 1 9.82 1.25h.36a6.698 6.698 0 0 1 6.675 6.147 7.244 7.244 0 0 1-1.61 5.181l-3.995 4.885a1.615 1.615 0 0 1-2.5 0l-3.993-4.885a7.244 7.244 0 0 1-1.612-5.18Zm9.797-.289a.625.625 0 0 0-.884-.883L9.583 8.7 8.358 7.475a.625.625 0 1 0-.883.883l1.667 1.667a.625.625 0 0 0 .883 0l2.917-2.917Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default LocationTick;
