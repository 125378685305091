import {Box, HBox} from '../../../components/core/view';
import React, {useState} from 'react';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {DataSyncModal} from '../DataSyncModal';
import {H5Text} from '../../../components/core/text';
import If from '../../../components/conditional/If';
import {styles} from './styles';
import {useAppSelector} from '../../../config/redux/hooks';

interface AboutShiftHeaderProps {
  online: boolean;
}

function AboutShiftHeader(_props: AboutShiftHeaderProps) {
  const muliFont = useFontTheme('muli');
  const theme = useColorThemeType();
  const [isOpen, setIsOpen] = useState(false);
  const globalState = useAppSelector(gState => gState.home);
  return (
    <React.Fragment>
      <Box style={styles.mainContainer}>
        <If condition={globalState.batchShiftKey}>
          <H5Text
            style={[
              styles.shiftDetailText,
              {
                fontFamily: muliFont[700].normal,
                color: theme?.text.blue[300],
              },
            ]}>
            {globalState.batchShiftKey}
          </H5Text>
        </If>
        <HBox style={styles.summeryContainer}>
          <Box>
            <H5Text
              style={[
                styles.summeryText,
                {
                  fontFamily: muliFont[700].normal,
                  color: theme?.text.blue[300],
                },
              ]}>
              Shift Summary
            </H5Text>
          </Box>
        </HBox>
      </Box>
      <DataSyncModal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} />
    </React.Fragment>
  );
}

export default React.memo(AboutShiftHeader);
