import {
  GetAvailableShiftsResponse,
  TakeShiftResponse,
} from '../../../src/config/graphQL/type';
import {OpenShiftContextState, ShiftByDate} from '../types/openShifts';
import {OpenShiftsContext, initialState} from '../context/openShifts';
import {useContext, useReducer, useState} from 'react';

import {GET_AVAIALBLE_SHIFTS} from '../../../src/config/graphQL/documentNode/queries';
import {PayloadAction} from '@reduxjs/toolkit/dist/createAction';
import {TAKE_SHIFT} from '../../../src/config/graphQL/documentNode/mutation';
import {ToastType} from '../../components/composites/notification/type';
import {createSlice} from '@reduxjs/toolkit';
import {gqlService} from '../../../src/config/graphQL';
import {useToastMessage} from '../../../src/components/composites/notification';

const reducer = {
  setDaysShifts: function (
    state: OpenShiftContextState,
    action: PayloadAction<ShiftByDate[]>,
  ) {
    state.days = action.payload;
  },
};

const slice = createSlice({
  initialState,
  name: 'openShiftsContext',
  reducers: reducer,
});

export function useOpenShiftsReducer() {
  const openShiftsReducer = useReducer(slice.reducer, initialState);
  return openShiftsReducer;
}

export function useOpenShiftsContext() {
  return useContext(OpenShiftsContext);
}

export const OpenShiftActions = slice.actions;

export function useGetAvailableShifts() {
  const [loading, setLoading] = useState(true);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const {dispatch} = useOpenShiftsContext();

  const getAvailableShifts = async () => {
    setLoading(true);
    try {
      const res = await gqlService?.query<GetAvailableShiftsResponse>({
        query: GET_AVAIALBLE_SHIFTS,
        fetchPolicy: 'network-only',
      });
      const shiftData = res?.data?.getAvailableShifts?.days || [];
      if (dispatch) dispatch(OpenShiftActions?.setDaysShifts(shiftData));
      setLoading(false);
    } catch (err: any) {
      showErrorToast(err?.message);
      setLoading(false);
    }
  };

  return {getAvailableShifts, loading};
}

export function useTakeShift() {
  const [loading, setLoading] = useState(false);
  const {dispatch} = useOpenShiftsContext();
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [showSuccessToast] = useToastMessage(ToastType.Success);
  const takeShift = async (shiftId: string) => {
    setLoading(true);
    try {
      const res = await gqlService?.mutation<TakeShiftResponse>({
        mutation: TAKE_SHIFT,
        variables: {
          shiftId,
        },
        fetchPolicy: 'network-only',
      });
      const shiftData = res?.data?.takeShift?.availableShift?.days || [];
      if (dispatch) dispatch(OpenShiftActions?.setDaysShifts(shiftData));
      setLoading(false);
      showSuccessToast('Shift Assigned');
    } catch (err: any) {
      showErrorToast(err?.message);
      setLoading(false);
    }
  };

  return {takeShift, loading};
}
