import * as React from 'react';

import {ClipPath, Defs, G, Path} from 'react-native-svg';

import {Icon} from '.';
import {IconProps} from './type';

const TickIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <G clipPath="url(#a)">
        <Path
          {...props}
          fill="#fff"
          fillRule="evenodd"
          d="M8.978 2.13a.625.625 0 0 1 0 .883L4.293 7.698a.667.667 0 0 1-.943 0L1.023 5.37a.625.625 0 1 1 .883-.884l1.915 1.915 4.272-4.273a.625.625 0 0 1 .885 0Z"
          clipRule="evenodd"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h10v10H0z" />
        </ClipPath>
      </Defs>
    </Icon>
  );
};

export default TickIcon;
