import {AttendanceStatus, ShiftByStore, Store} from '../types';
import {Box, HBox} from '../../components/core/view';
import {H5Text, H6Text} from '../../components/core/text';
import {Linking, StyleSheet} from 'react-native';
import React, {useCallback, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from '../../config/redux/hooks';
import {useColorThemeType, useFontTheme} from '../../appStyles/hooks/theme';

import {Analytics} from '../../config/analytics/analytics';
import {CustomFlatList} from '../../components/core/list';
import {Divider} from 'native-base';
import {EventType} from '../../config/analytics/type';
import {HomeScreens} from '../navigation/types';
import If from '../../components/conditional/If';
import {LatLong} from '../../AboutShift/types';
import LocationIcon from '../../components/core/icon/LocationIcon';
import {MainScreens} from '../../navigation/types';
import {MapChoice} from '../../onBoarding/types';
import NextShiftInfo from './NextShiftInfo';
import {PButton} from '../../components/core/Button';
import StartShiftCard from './StartShiftCard';
import {Touchable} from '../../components/core/touchable';
import {getMapUrl} from '../../utils/common';
import moment from 'moment';
import {navigation} from '../../config/navigation';
import {onBoardingActions} from '@buncha/onBoarding/reducer';
import {useHomeContext} from '../hooks/home';
import {useSetUserAttendanceStatus} from '../hooks/AssinedShift';
import {useUpdateUserChoiceMap} from '../../AboutShift/hooks/UserMapChoice';

export default function NextShift() {
  const defaultTheme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const globalState = useAppSelector(gstate => gstate.home);
  const globalShiftData = globalState.shiftData;
  const user = useAppSelector(gUser => gUser.onboarding.currentUser);
  const {dispatch} = useHomeContext();
  const [setUserAttendanceStatus, loading] =
    useSetUserAttendanceStatus(dispatch);
  const appDispatch = useAppDispatch();
  const [updateUserChoiceMap] = useUpdateUserChoiceMap(appDispatch);

  const unreadMemoCount = useAppSelector(
    gState => gState.onboarding.unreadMemoCount,
  );

  const selectedMapChoice = user?.mapChoice;

  const todayShifts = globalShiftData.active;
  const time = moment(todayShifts?.id).format('h:mm A');
  const shifts = useMemo(() => {
    if (todayShifts) return todayShifts?.shifts;
    return [];
  }, [todayShifts]);

  const getButtonText = useMemo(() => {
    if (user?.isActive) return 'Start Task';
    return 'Check In';
  }, [user?.isActive]);

  const openMap = useCallback(async (mapLink: string) => {
    try {
      const supported = await Linking.canOpenURL(mapLink);

      if (supported) Linking.openURL(mapLink);
    } catch (error) {
      return error;
    }
  }, []);

  const selectMapChoice = useCallback(
    (choice: MapChoice, latLong: LatLong) => {
      updateUserChoiceMap(choice);
      const mapLink = choice ? getMapUrl(choice, latLong) : '';
      openMap(mapLink);
    },
    [openMap, updateUserChoiceMap],
  );

  const openMapHandler = useCallback(
    (store: Store) => {
      Analytics.eventWithProps('Navigate to map', {
        type: EventType.cta,
      });
      const latLong = store?.getLatLongFromAddress;
      if (!selectedMapChoice)
        return selectMapChoice(MapChoice.googleMaps, latLong);
      return selectMapChoice(selectedMapChoice, latLong);
    },
    [selectMapChoice, selectedMapChoice],
  );

  const onClick = useCallback(
    (store: Store, shiftTime: string) => {
      if (unreadMemoCount) {
        appDispatch(onBoardingActions.setUnreadMemoModal(true));
        return;
      }
      if (!user?.isActive) {
        Analytics.eventWithProps('Check In', {
          type: EventType.cta,
          storeName: store?.name,
          storeId: store?.id,
          shiftTime: shiftTime,
          shiftId: todayShifts?.id,
          shiftKey: todayShifts?.key,
        });
        return setUserAttendanceStatus(AttendanceStatus.active);
      }
      if (user?.isActive) {
        Analytics.eventWithProps('Start Task', {
          type: EventType.cta,
          storeName: store?.name,
          storeId: store?.id,
          shiftTime: shiftTime,
          shiftId: todayShifts?.id,
          shiftKey: todayShifts?.key,
        });
        navigation.navigate(MainScreens.Home, {
          screen: HomeScreens.AboutShift,
          params: {
            shiftId: todayShifts?.id,
            shiftKey: todayShifts?.key,
          },
        });
      }
    },
    [
      appDispatch,
      setUserAttendanceStatus,
      todayShifts?.id,
      todayShifts?.key,
      unreadMemoCount,
      user?.isActive,
    ],
  );

  const renderItem = useCallback(
    ({item, index}: {item: ShiftByStore; index: number}) => {
      const store = shifts[item.activeShiftIndex]?.store;
      const shiftTime = shifts[item.activeShiftIndex]?.time;

      return (
        <Box
          style={[
            styles.mainContainer,
            {
              backgroundColor: defaultTheme?.background.yellow[200],
            },
          ]}>
          <NextShiftInfo
            shifts={shifts}
            shiftByStore={item}
            batchData={globalState.shiftCountByStore}
            index={index}
          />
          <HBox style={[styles.locationBox]}>
            <LocationIcon
              fill={defaultTheme?.background.blue[300]}
              viewBox="-4 -3 25 25"
            />
            <Touchable
              onPress={() =>
                openMapHandler(shifts[item.activeShiftIndex]?.store)
              }
              style={[
                styles.flexBox,
                {
                  backgroundColor: defaultTheme?.background.yellow[200],
                },
              ]}>
              <H6Text
                numberOfLines={1}
                style={[
                  styles.addressText,
                  {
                    fontFamily: muliFont[600].normal,
                    color: defaultTheme?.text.blue[300],
                  },
                ]}>
                Navigate to {shifts[item.activeShiftIndex]?.store?.address}
              </H6Text>
            </Touchable>
          </HBox>
          <PButton
            title={getButtonText}
            style={[styles.actionButton]}
            titleStyle={{
              fontFamily: muliFont[700].normal,
            }}
            onPress={() => onClick(store, shiftTime)}
            disabled={loading}
          />
          <If condition={(todayShifts?.shiftByStore?.length || 0) > 1}>
            <Divider style={[styles.divider]} />
          </If>
        </Box>
      );
    },
    [
      defaultTheme?.background.blue,
      defaultTheme?.background.yellow,
      defaultTheme?.text.blue,
      getButtonText,
      globalState.shiftCountByStore,
      loading,
      muliFont,
      onClick,
      openMapHandler,
      shifts,
      todayShifts?.shiftByStore?.length,
    ],
  );
  const headerComponent = useCallback(() => {
    return (
      <H5Text
        style={[
          styles.shiftDate,
          {
            fontFamily: muliFont[900].normal,
            color: defaultTheme?.text.blue[300],
            backgroundColor: defaultTheme?.background.yellow[200],
          },
        ]}>
        Next Shift | {todayShifts?.key}
      </H5Text>
    );
  }, [
    defaultTheme?.background.yellow,
    defaultTheme?.text.blue,
    muliFont,
    todayShifts?.key,
  ]);

  return (
    <Box>
      <If condition={Boolean(shifts.length)}>
        <CustomFlatList
          data={todayShifts?.shiftByStore}
          renderItem={renderItem}
          ListHeaderComponent={headerComponent}
          ListFooterComponent={<StartShiftCard time={time} />}
        />
      </If>
    </Box>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    paddingHorizontal: 20,
    paddingBottom: 15,
  },
  shiftDate: {
    textAlign: 'left',
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  actionButton: {
    borderRadius: 50,
    marginTop: 10,
  },
  locationBox: {
    justifyContent: 'flex-start',
    marginLeft: 70,
  },
  addressText: {
    textAlign: 'left',
    textDecorationLine: 'underline',
    flex: 1,
    marginTop: -5,
  },
  divider: {
    marginTop: 10,
  },
  flexBox: {flex: 1},
});
