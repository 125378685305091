import * as React from 'react';

import {ClipPath, Defs, G, Path, Rect} from 'react-native-svg';

import Icon from './Icon';
import {IconProps} from './type';

const RightTickIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Rect width={20} height={20} rx={10} />
      <G clipPath="url(#a)">
        <Path
          d="m6.372 9.146-.352-.352-.354.351-.887.882-.356.353.355.355 3.493 3.494.354.353.354-.353 7.5-7.5.353-.354-.353-.354-.882-.88-.353-.354-.354.353-6.265 6.26-2.253-2.254Z"
          fill="#fff"
          stroke="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" transform="translate(3 2)" d="M0 0h15v15H0z" />
        </ClipPath>
      </Defs>
    </Icon>
  );
};

export default RightTickIcon;
