import {ActivityIndicator, StyleSheet} from 'react-native';
import {Divider, View} from 'native-base';
import React, {useCallback} from 'react';
import {RegisterShopperParams, UserTypes} from '../types';
import {useAppDispatch, useAppSelector} from '../../config/redux/hooks';

import {BottomButton} from '../../components/composites/Button';
import Header from '../../components/composites/Header/Header';
import KeyboardAvoidLayout from '../../components/composites/keyboardAvoidLayout';
import SignUpComponent from '../components/SignUpComponent/SignUpComponent';
import {useUserManagement} from '../hooks';

export default function SignUp() {
  const onboardingState = useAppSelector(gstate => gstate.onboarding);
  const authData = onboardingState?.userAuthData;
  const dispatch = useAppDispatch();

  const {registerShopper, registeringShopper} = useUserManagement(dispatch);

  const getDisabledStatus = useCallback(() => {
    // eslint-disable-next-line no-useless-escape
    let emailRegex = /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/;
    return !emailRegex.test(authData?.email || '') || !authData?.firstName;
  }, [authData]);

  const onSignUP = useCallback(async () => {
    const dataToSend: RegisterShopperParams = {
      firstName: authData?.firstName || '',
      lastName: authData?.lastName || '',
      email: authData?.email || '',
      phoneNumber: authData?.phoneNumber || '',
      createUserToken: authData?.createUserToken || '',
      userTypes: [UserTypes.shopper],
    };
    await registerShopper(dataToSend);
  }, [
    authData?.createUserToken,
    authData?.email,
    authData?.firstName,
    authData?.lastName,
    authData?.phoneNumber,
    registerShopper,
  ]);

  return (
    <KeyboardAvoidLayout>
      <Header showDefaultLeftHeader={true} hideUploadFile>
        <View style={[styles.container]}>
          <SignUpComponent />
        </View>
        <Divider />
        <BottomButton
          title={'Sign Up'}
          style={styles.buttonStyle}
          isLoading={registeringShopper}
          loadingIndicator={<ActivityIndicator />}
          onPress={onSignUP}
          disabled={getDisabledStatus()}
        />
      </Header>
    </KeyboardAvoidLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
  },
  text: {
    fontSize: 25,
    fontWeight: '500',
  },
  headerTypo: {
    textAlign: 'left',
  },
  bottomButtonContainer: {
    paddingBottom: 15,
  },
  buttonStyle: {
    marginTop: 15,
  },
});
