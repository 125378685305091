import CustomText from './CustomText';
import {CustomTextProps} from './type';
import Font from '../../../appStyles/style/font';
import React from 'react';

import {StyleSheet, Text, TextStyle} from 'react-native';
import {useFontTheme} from '../../../appStyles/hooks/theme';

const H1Text: React.FC<CustomTextProps<Text>> = React.forwardRef(
  (props, ref) => {
    const fontTheme = useFontTheme(props.fontTheme || 'muli');
    const propsToSpread = {
      ...props,
      style: [
        styles.H1Text,
        {fontFamily: fontTheme[900].normal},
        props.style as TextStyle,
      ],
    };
    return (
      <CustomText ref={ref} {...propsToSpread}>
        {props.children}
      </CustomText>
    );
  },
);

const styles = StyleSheet.create({
  H1Text: {
    ...Font.FontSize.fontH1,
  },
});

export default React.memo(H1Text);
