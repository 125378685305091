import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const HourGlass: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        data-name="Icon HourGlass"
        d="M.667 17.333c0-1.541.204-2.857.614-3.948.41-1.09.934-1.996 1.573-2.718A7.108 7.108 0 0 1 5 8.989a9.316 9.316 0 0 1 2.333-.822v-2.5c-1.902-.236-3.49-.823-4.76-1.761C1.302 2.969.667 1.889.667.666h16.666c0 1.223-.635 2.303-1.906 3.24-1.27.938-2.857 1.525-4.76 1.76v2.5A9.315 9.315 0 0 1 13 8.99a7.108 7.108 0 0 1 2.146 1.678c.639.722 1.163 1.628 1.573 2.718.41 1.09.614 2.407.614 3.948h-5v-1.666h3.23c-.25-2.111-1.039-3.64-2.365-4.584C11.872 10.14 10.472 9.667 9 9.667c-1.472 0-2.871.472-4.197 1.416-1.327.945-2.115 2.473-2.365 4.584h3.229v1.666h-5Zm8.333 0c-.458 0-.85-.163-1.177-.489a1.605 1.605 0 0 1-.49-1.177c0-.236.046-.452.136-.646a1.8 1.8 0 0 1 .364-.521c.334-.333.896-.684 1.688-1.053.791-.367 1.729-.739 2.812-1.114a36.916 36.916 0 0 1-1.125 2.813c-.36.791-.708 1.354-1.041 1.687a1.801 1.801 0 0 1-.521.365c-.195.09-.41.135-.646.135Z"
        transform="translate(-3.375 -3.375)"
      />
    </Icon>
  );
};

export default HourGlass;
