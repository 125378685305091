/* eslint-disable react-native/no-inline-styles */
import React, {useCallback, useState} from 'react';
import {
  useAddItemContext,
  useSearchItemsForAddItems,
} from '../../../batchDetails/hooks/AddItem';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {AddItemCell} from '../../../batchDetails/types';
import {Analytics} from '@buncha/config/analytics/analytics';
import {Box} from '../../../components/core/view';
import CustomActivityIndicator from '../../../components/composites/CustomActivityIndicator/CustomActivityIndicator';
import {CustomFlatList} from '../../../components/core/list';
import {Divider} from 'native-base';
import {EventType} from '@buncha/config/analytics/type';
import {H2Text} from '../../../components/core/text';
import ItemAddCell from '../ItemAddCell';
import SearchInput from '../../../components/composites/Input/TextInput/SearchInput';
import {StyleSheet} from 'react-native';
import {styles} from '../../../batchDetails/styles/AddItemPage';
import {useRoute} from '@react-navigation/native';

interface SearchSectionProps {
  selectedItem: AddItemCell | undefined;
}

const SearchSection: React.FC<SearchSectionProps> = props => {
  const muliTheme = useFontTheme('muli');
  const theme = useColorThemeType('main');

  const {state, dispatch} = useAddItemContext();

  const {searchResults} = state;

  const routeParams: any = useRoute().params;
  const storeId = Number(routeParams?.storeId);
  const runId = Number(routeParams?.runId);
  const size = 10;
  const searchSource = 'Add Item';

  const [searchQuery, setSearchQuery] = useState('');

  const {searchItemsForAddition, loading, moreItems} =
    useSearchItemsForAddItems(dispatch, state);

  const returnBorderColor = searchQuery?.length
    ? theme?.text?.gray[100]
    : theme?.text?.gray[200];

  const onFocus = useCallback(() => {
    Analytics.eventWithProps('Searching for an item', {
      type: EventType.input,
      source: searchSource,
    });
  }, []);

  const searchForAnItemEvent = useCallback(
    (keyword: string, offset: number) => {
      Analytics.eventWithProps('Search for an item', {
        searchItem: keyword,
        from: offset,
        size: size,
        source: searchSource,
        storeId: storeId,
        runId: runId,
      });
    },
    [runId, storeId],
  );

  const handleSearchQueryChange = useCallback(
    async (text: string) => {
      setSearchQuery(text);
      searchForAnItemEvent(text, 0);
      await searchItemsForAddition({
        storeId: storeId || 0,
        from: 0,
        size: size,
        searchQuery: text,
        runId: runId,
      });
    },
    [runId, searchForAnItemEvent, searchItemsForAddition, storeId],
  );

  const handleScroll = useCallback(
    async (_: any, offset: number) => {
      searchForAnItemEvent(searchQuery, offset);
      await searchItemsForAddition({
        storeId: storeId || 0,
        from: offset,
        size: size,
        searchQuery: searchQuery,
        runId: runId,
      });
    },
    [searchForAnItemEvent, searchQuery, searchItemsForAddition, storeId, runId],
  );

  const renderItem = useCallback((subItem: {item: AddItemCell}) => {
    return <ItemAddCell itemInfo={subItem?.item} />;
  }, []);

  const separator = useCallback(() => <Divider />, []);

  return (
    <Box style={styles.scrollView}>
      <H2Text
        style={[
          styles.headerText,
          {
            color: theme?.text.blue[300],
            fontFamily: muliTheme[700].normal,
          },
        ]}>
        Add Item
      </H2Text>
      <SearchInput
        defaultValue={searchQuery}
        containerStyle={[
          styles.search,
          {
            backgroundColor: theme?.background?.white?.[100],
            borderColor: returnBorderColor,
          },
        ]}
        debounce={true}
        placeholderTextColor={theme?.text?.gray?.[100]}
        placeholder={'Search Item'}
        textValue={searchQuery}
        clearInput={true}
        colorThemeType="default"
        onChangeText={handleSearchQueryChange}
        style={{color: theme?.text?.blue?.[300]}}
        onFocus={onFocus}
      />
      {searchResults?.length > 0 && (
        <CustomFlatList
          data={searchResults}
          renderItem={renderItem}
          style={{marginBottom: props?.selectedItem ? 75 : 25}}
          ItemSeparatorComponent={separator}
          loadMore={moreItems}
          loading={loading}
          userOffset={10}
          showsVerticalScrollIndicator={false}
          initialOffset={10}
          onEndReachedThreshold={0.5}
          numOfTimesToRender={1}
          loadingSkeleton={
            <Box style={[PageStyles.activityIndicatorContainer]}>
              <CustomActivityIndicator />
            </Box>
          }
          onEndReachedAtt={handleScroll}
        />
      )}
    </Box>
  );
};

export default React.memo(SearchSection);

export const PageStyles = StyleSheet.create({
  activityIndicatorContainer: {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'center',
    marginTop: 10,
  },
});
