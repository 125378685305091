import {
  ADD_ITEM,
  SUBSTITUTE_ITEM_IN_RUN,
} from '../../config/graphQL/documentNode/mutation';
import {
  AddItemParams,
  GetOrderUserDetailsResponse,
  OrderUserDetails,
} from '../types/CustomItem';

import {ApiState} from '@buncha/common/types/common';
import {GET_ORDER_BUYER_INFO} from '@buncha/config/graphQL/documentNode/queries';
import {SubstitutionItemParams} from '../types/itemReplacement';
import {ToastType} from '../../components/composites/notification/type';
import {gqlService} from '../../config/graphQL';
import {useState} from 'react';
import {useToastMessage} from '../../components/composites/notification';

export function useCustomItem() {
  const [loading, setLoading] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [showSuccessToast] = useToastMessage(ToastType.Success);

  const addCustomItem = async (params: AddItemParams, callback?: Function) => {
    setLoading(true);
    try {
      params.source = 'shopper-addCustomItem';
      if (params.price / 100 <= 500) {
        const response = await gqlService?.mutation({
          mutation: ADD_ITEM,
          fetchPolicy: 'network-only',
          variables: params,
        });
        if (response?.data?.addItemInRun && callback) {
          callback();
          showSuccessToast('Item added and picked successfully!!');
        }
      } else {
        throw new Error("You can't add item more that worth $500");
      }
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setLoading(false);
    }
  };

  const substituteCustomItem = async (
    params: SubstitutionItemParams,
    callback?: Function,
  ) => {
    setLoading(true);
    try {
      const response = await gqlService?.mutation({
        mutation: SUBSTITUTE_ITEM_IN_RUN,
        fetchPolicy: 'network-only',
        variables: params,
      });
      if (response?.data?.substituteItemInRun && callback) callback();
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {loading, addCustomItem, substituteCustomItem};
}

export function useGetBuyerDetails(): [
  (runId: number, orderId: number) => void,
  ApiState<OrderUserDetails>,
] {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [state, setState] = useState<ApiState<OrderUserDetails>>({
    loading: false,
    error: undefined,
    data: undefined,
  });

  const func = async (runId: number, orderId: number) => {
    if (!orderId) return;
    try {
      setState({...state, loading: true});
      const res = await gqlService?.query<GetOrderUserDetailsResponse>({
        query: GET_ORDER_BUYER_INFO,
        fetchPolicy: 'network-only',
        variables: {orderId: orderId.toString(), runId: runId},
      });
      const response = res?.data?.getOrder;
      if (response)
        setState({
          ...state,
          data: response,
          loading: false,
        });
    } catch (err: any) {
      setState({...state, error: err, loading: false});
      showErrorToast(err?.message);
    }
  };

  return [func, state];
}
