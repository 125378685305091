import {
  BatchDetailTabs,
  ContactBottomSheetProps,
  DynamicTextType,
  GroupedAssignedItems,
  RunForBatchDetail,
  UPCOrderItem as UPCOrderItemType,
  itemsForSection,
  itemsSortBy,
} from '../../../batchDetails/types';
import {Box, HBox} from '../../../components/core/view';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  Divider,
  IconButton,
  Skeleton,
} from 'native-base';
import {CustomText, H4Text} from '../../../components/core/text';
import React, {useCallback, useEffect, useMemo} from 'react';
import {
  batchDetailActionsLocal,
  useBatchDetailContext,
} from '../../../batchDetails/hooks/batchDetail';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';
import {
  useGetOrdersForOrderAssignee,
  useUPCShift,
} from '../../../batchDetails/hooks/BatchDetails';
import {useIsFocused, useRoute} from '@react-navigation/native';

import {Accordian} from '../../../components/composites';
import AddToCartIcon from '../../../components/core/icon/AddToCartIcon';
import {Analytics} from '../../../config/analytics/analytics';
import BatchOrderItemList from '../BatchOrderItemList';
import {CustomFlatList} from '../../../components/core/list';
import {CustomItemMode} from '../../../itemDetails/types/CustomItem';
import CustomerOrderInfo from '../CustomerOrderInfo';
import EmptyTodo from '../EmptyTodo';
import {EventType} from '../../../config/analytics/type';
import If from '../../../components/conditional/If';
import {ItemDetailsScreen} from '../../../itemDetails/navigation/types';
import {ItemScannerGlobalActions} from '@buncha/itemDetails/reducer';
import {ShopperScreens} from '../../../home/navigation/types';
import {SpinnerComponent} from '@buncha/components/core/loader';
import UPCOrderItem from '../BatchOrderItem/UPCOrderItem';
import WaveIcon from '../../../components/core/icon/WaveIcon';
import {navigation} from '../../../config/navigation';
import {styles} from './styles';
import {useAppDispatch} from '@buncha/config/redux/hooks';

interface TodoItemListProps {
  runDetail?: RunForBatchDetail;
  isUPCMode?: boolean;
  upcOrderId: number;
}

function TodoItemList(props: TodoItemListProps) {
  const {runDetail, isUPCMode} = props;
  const route = useRoute();
  const routeParams: {runId?: number} | undefined = route?.params;
  const appDispatch = useAppDispatch();
  const runId = Number(routeParams?.runId);
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const {state, dispatch} = useBatchDetailContext();
  const [getOrdersForOrderAssignee, getOrdersForOrderAssigneeLoading] =
    useGetOrdersForOrderAssignee(dispatch);
  const isFocus = useIsFocused();
  const sortBarValue = state.sortBarValue;
  const {getUPCShiftOrder, orderLoading} = useUPCShift(dispatch);

  useEffect(() => {
    Analytics.eventWithProps('To-do Tab', {
      type: EventType.cta,
      runID: runId,
      totalItem: state?.tabDataCount?.todoCount || 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeContactSheet = useCallback(() => {
    if (dispatch)
      dispatch(batchDetailActionsLocal.setContactBottomSheetProps(undefined));
  }, [dispatch]);

  const navigateToAddItem = useCallback(
    (item: GroupedAssignedItems) => {
      closeContactSheet();
      const orderId = item.orderItems?.length ? item.orderItems[0].orderId : 0;
      const storeId = runDetail?.store?.id;
      const storeIndex = runDetail?.store?.elasticSearchIndex || '';
      if (!runId || !storeId || !orderId) return;
      if (!runDetail?.store?.elasticSearchIndex?.length) {
        Analytics.eventWithProps('Navigate to Add Custom Item', {
          type: EventType.cta,
          runID: runId,
          orderId,
          storeId,
        });

        return navigation.navigate(ShopperScreens.ItemDetails, {
          screen: ItemDetailsScreen.CustomItem,
          params: {
            orderId,
            runId,
            mode: CustomItemMode.addItem,
          },
        });
      }
      Analytics.eventWithProps('Navigate to Add Item', {
        type: EventType.cta,
        runID: runId,
        orderId,
        storeId,
      });
      navigation.navigate(ShopperScreens.AddItemPage, {
        storeId,
        runId,
        orderId,
        storeIndex,
      });
    },
    [
      closeContactSheet,
      runDetail?.store?.elasticSearchIndex,
      runDetail?.store?.id,
      runId,
    ],
  );

  const leftComponent = useCallback(
    (item: GroupedAssignedItems) => {
      const isVip = item?.metaData?.buyer?.isVip;
      return (
        <Box>
          <If condition={sortBarValue === itemsSortBy.category}>
            <H4Text
              style={[
                styles.categoryText,
                {
                  fontFamily: muliFont[900].normal,
                  color: theme?.text.blue[300],
                },
              ]}>
              {item.groupedKey}
            </H4Text>
          </If>
          <If condition={sortBarValue !== itemsSortBy.category}>
            <CustomerOrderInfo
              groupedAssignedItems={item}
              hideFooter
              hasNewUser={item?.metaData?.completedOrderCount === 0}
              isVip={isVip}
            />
          </If>
        </Box>
      );
    },
    [muliFont, sortBarValue, theme?.text.blue],
  );

  const handleAddItem = useCallback(
    (item: GroupedAssignedItems) => {
      const orderId = item.orderItems?.length ? item.orderItems[0].orderId : 0;
      const params: ContactBottomSheetProps = {
        type: DynamicTextType.addItem,
        buttonText: 'Add Item',
        onButtonClick: () => navigateToAddItem(item),
        orderId: orderId,
      };

      if (dispatch)
        dispatch(batchDetailActionsLocal.setContactBottomSheetProps(params));
    },
    [dispatch, navigateToAddItem],
  );

  const handleContactCustomer = useCallback(
    (item: GroupedAssignedItems) => {
      const orderId = item.orderItems?.length ? item.orderItems[0].orderId : 0;
      const params: ContactBottomSheetProps = {
        type: DynamicTextType.customer,
        orderId: orderId,
      };
      if (dispatch)
        dispatch(batchDetailActionsLocal.setContactBottomSheetProps(params));
    },
    [dispatch],
  );

  const navigateItemDetail = useCallback(
    (orderItem: UPCOrderItemType) => {
      if (appDispatch)
        appDispatch(ItemScannerGlobalActions.setUPCOrderItem(orderItem));
      navigation.navigate(ShopperScreens.ItemDetails, {
        screen: ItemDetailsScreen.ItemDetailsPage,
        params: {
          catalogItemId: orderItem.catalogItemId,
          orderItemId: orderItem?.id,
          storeIndex: runDetail?.store?.elasticSearchIndex,
          runId: 0,
          isUPCMode: true,
        },
      });
    },
    [appDispatch, runDetail?.store?.elasticSearchIndex],
  );

  const renderItem = useCallback(
    ({
      item,
    }: {
      item: GroupedAssignedItems | UPCOrderItemType;
      index: number;
    }) => {
      if (isUPCMode) {
        const upcOrdeItem = item as UPCOrderItemType;
        if (orderLoading)
          return (
            <Box style={styles.skeletonContainer}>
              <Skeleton marginTop={5} height={75} />
            </Box>
          );
        return (
          <Box style={[styles.mainContainer]}>
            <UPCOrderItem
              orderItem={upcOrdeItem}
              onClick={navigateItemDetail}
            />
          </Box>
        );
      }

      const todoItem = item as GroupedAssignedItems;
      return (
        <Box style={[styles.mainContainer]}>
          <Accordian
            isExpanded={true}
            header={
              <HBox style={[styles.mainHeaderContainer]}>
                {leftComponent(todoItem)}
                <HBox>
                  <If condition={sortBarValue !== itemsSortBy.category}>
                    <IconButton onPress={() => handleContactCustomer(todoItem)}>
                      <WaveIcon />
                    </IconButton>
                    <IconButton onPress={() => handleAddItem(todoItem)}>
                      <AddToCartIcon
                        width={18}
                        height={18}
                        viewBox="0 0 21 21"
                      />
                    </IconButton>
                  </If>
                  <Box
                    style={[
                      styles.countBox,
                      {
                        backgroundColor: theme?.background.gray[200],
                      },
                    ]}>
                    <CustomText
                      style={[
                        {
                          fontFamily: muliFont[700].normal,
                          color: theme?.text.gray[100],
                        },
                      ]}>
                      {todoItem?.orderItems?.length}
                    </CustomText>
                  </Box>
                </HBox>
              </HBox>
            }
            children={
              <BatchOrderItemList
                orderItems={todoItem?.orderItems}
                runDetail={runDetail}
              />
            }
            iconEnable={<ChevronUpIcon color={theme?.text.blue[300]} />}
            iconDisable={<ChevronDownIcon color={theme?.text.blue[300]} />}
          />
        </Box>
      );
    },
    [
      handleAddItem,
      handleContactCustomer,
      isUPCMode,
      leftComponent,
      muliFont,
      navigateItemDetail,
      orderLoading,
      runDetail,
      sortBarValue,
      theme?.background.gray,
      theme?.text.blue,
      theme?.text.gray,
    ],
  );

  const Separator = useCallback(() => <Divider style={styles.divider} />, []);

  useEffect(() => {
    if (dispatch) dispatch(batchDetailActionsLocal.setShowSortBarValue(true));
  }, [dispatch]);

  useEffect(() => {
    if (runId && isFocus)
      getOrdersForOrderAssignee(
        runId,
        itemsForSection.todo,
        state.sortBarValue,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sortBarValue, runId, isFocus]);

  useEffect(() => {
    if (props?.isUPCMode)
      getUPCShiftOrder(props?.upcOrderId, BatchDetailTabs.todo, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sortBarValue, runId, isFocus]);

  const dataToRender = useMemo(() => {
    if (isUPCMode) return state.upcOrderData?.orderItems;
    return state.groupedAssignedItems.todo;
  }, [
    isUPCMode,
    state.groupedAssignedItems.todo,
    state.upcOrderData?.orderItems,
  ]);

  return (
    <React.Fragment>
      {getOrdersForOrderAssigneeLoading && <SpinnerComponent />}
      <CustomFlatList
        data={dataToRender}
        renderItem={renderItem}
        ItemSeparatorComponent={Separator}
        style={styles.scrollContainer}
        contentContainerStyle={styles.contentContainer}
        showsVerticalScrollIndicator={false}
        loading={state.globalLoading || orderLoading}
        loadingSkeleton={
          <Box style={styles.skeletonContainer}>
            <Skeleton marginTop={5} height={75} />
          </Box>
        }
        ListEmptyComponent={
          <If condition={!(state.globalLoading || orderLoading)}>
            <EmptyTodo />
          </If>
        }
      />
    </React.Fragment>
  );
}

export default React.memo(TodoItemList);
