import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {Box} from '../../../components/core/view';
import {H6Text} from '../../../components/core/text';
import React from 'react';
import {styles} from './styles';

interface BatchListHeaderProps {
  headerTitle: string;
}

function BatchListHeader(props: BatchListHeaderProps) {
  const {headerTitle} = props;
  const muliFont = useFontTheme('muli');
  const theme = useColorThemeType();

  return (
    <Box>
      <H6Text
        style={[
          styles.text,
          {
            fontFamily: muliFont[700].normal,
            color: theme?.text.gray[100],
          },
        ]}>
        {headerTitle}
      </H6Text>
    </Box>
  );
}

export default React.memo(BatchListHeader);
