import CustomText from './CustomText';
import {CustomTextProps} from './type';
import Font from '../../../appStyles/style/font';
import React from 'react';

import {StyleSheet, Text, TextStyle} from 'react-native';

const PSmallText: React.FC<CustomTextProps<Text>> = React.forwardRef(
  (props, ref) => {
    const propsToSpread = {
      ...props,
      style: [styles.PSmallText, props.style as TextStyle],
    };
    return (
      <CustomText ref={ref} {...propsToSpread}>
        {props.children}
      </CustomText>
    );
  },
);

const styles = StyleSheet.create({
  PSmallText: {
    ...Font.FontSize.fontP10,
  },
});

export default React.memo(PSmallText);
