import {Box, HBox} from '@buncha/components/core/view';
import {H3Text, H6Text} from '@buncha/components/core/text';
import React, {useCallback} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {StyleSheet} from 'react-native';
import {Touchable} from '@buncha/components/core/touchable';

interface ApplicantHeaderProps {
  applicantName: string;
  refreshScreen: () => void;
}

const ApplicantHeader: React.FC<ApplicantHeaderProps> = props => {
  const theme = useColorThemeType('main');
  const mortise = useFontTheme('mortise');
  const muli = useFontTheme('muli');
  const applicantName = props?.applicantName || '';

  const refreshData = useCallback(() => {
    props?.refreshScreen();
  }, [props]);
  return (
    <Box>
      <HBox
        style={[styles.box, {backgroundColor: theme?.background?.blue?.[300]}]}>
        <H3Text
          style={[
            styles.name,
            {
              color: theme?.text?.white?.[100],
              fontFamily: mortise?.[700]?.normal,
            },
          ]}>
          Welcome {applicantName}
        </H3Text>
        <Touchable
          onPress={refreshData}
          style={{backgroundColor: theme?.background?.blue?.[300]}}>
          <H6Text
            style={{
              color: theme?.text?.white?.[100],
              fontFamily: muli?.[700]?.normal,
            }}>
            Refresh
          </H6Text>
        </Touchable>
      </HBox>
    </Box>
  );
};

export default React.memo(ApplicantHeader);

const styles = StyleSheet.create({
  box: {
    width: '100%',
    paddingHorizontal: 20,
    paddingTop: 30,
    paddingBottom: 15,
  },
  name: {
    textAlign: 'left',
  },
});
