import {Box, HBox} from '@buncha/components/core/view';
import {ChevronDownIcon, ChevronUpIcon, ScrollView} from 'native-base';
import {Dimensions, StyleSheet} from 'react-native';
import {H5Text, H6Text} from '@buncha/components/core/text';
import {
  ItemLocationAction,
  useItemLocationContext,
  useLocationFetcher,
  useLocationUpdate,
} from '@buncha/itemDetails/hooks/ItemLocation';
import React, {useCallback, useEffect, useMemo} from 'react';

import {Accordian} from '@buncha/components/composites';
import ItemLocationInfo from '../ItemLocationInfo';
import {LocationHistory} from '@buncha/itemDetails/types/ItemLocation';
import StoreLocation from '@buncha/components/core/icon/StoreLocation';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const ItemLocationAccordions = (props: {
  orderItemId: number;
  esIndex: string;
}) => {
  const {state, dispatch} = useItemLocationContext();
  const {getLocationHistory} = useLocationFetcher(dispatch);
  const theme = useColorThemeType('main');

  const {markItem} = useLocationUpdate(dispatch);

  const getLocationHistoryFun = useCallback(async () => {
    await getLocationHistory(props?.orderItemId, props.esIndex);
  }, [getLocationHistory, props.esIndex, props?.orderItemId]);

  useEffect(() => {
    getLocationHistoryFun();
  }, []);

  const renderAccordion = useMemo(() => {
    return state?.locationData?.map(
      (locationData: LocationHistory, index: number) => {
        return (
          <Box
            style={[
              styles.accordionContainer,
              {borderBottomColor: theme?.text?.gray[200]},
            ]}
            key={locationData?.id}>
            <Accordian
              iconEnable={<ChevronUpIcon color={theme?.text.blue[300]} />}
              iconDisable={<ChevronDownIcon color={theme?.text.blue[300]} />}
              isExpanded={index === 0}
              header={
                <Box style={[styles.accordinBody]}>
                  <HBox style={[styles.jcfs]}>
                    <StoreLocation
                      viewBox="0 -2 20 20"
                      height={25}
                      width={25}
                    />
                    <Box>
                      <H5Text
                        style={[
                          styles.headerTypo,
                          {
                            color: theme?.text.blue[300],
                          },
                        ]}>
                        {`Item found at ${locationData?.at}`}
                      </H5Text>
                      <Box>
                        <H6Text
                          style={[
                            styles.headerTypo,
                            {
                              color: theme?.text.gray[100],
                            },
                          ]}>
                          {`Found by ${locationData?.user?.firstName}`}
                        </H6Text>
                      </Box>
                    </Box>
                  </HBox>
                </Box>
              }
              children={
                <Box style={[styles.accordianBodyContainer]}>
                  <ItemLocationInfo
                    locationData={locationData?.itemInfo}
                    orderItemId={props?.orderItemId}
                    showDivider={false}
                    dispatch={dispatch}
                    markItem={markItem}
                    action={ItemLocationAction}
                    callback={getLocationHistoryFun}
                    onUpdate={false}
                  />
                </Box>
              }
            />
          </Box>
        );
      },
    );
  }, [
    dispatch,
    getLocationHistoryFun,
    markItem,
    props?.orderItemId,
    state?.locationData,
    theme?.text.blue,
    theme?.text.gray,
  ]);
  return (
    <ScrollView style={[styles.parentContainer]}>{renderAccordion}</ScrollView>
  );
};

export default React.memo(ItemLocationAccordions);

const styles = StyleSheet.create({
  parentContainer: {
    marginTop: 15,
    // padding: 15,
    paddingHorizontal: 15,
  },
  mainContainer: {
    marginHorizontal: 20,
  },
  mainHeaderContainer: {
    flex: 1,
    marginRight: 10,
  },
  countBox: {
    paddingHorizontal: 15,
    borderRadius: 50,
    paddingVertical: 2.5,
  },
  accordinBody: {
    width: Dimensions.get('screen').width * 0.8,
    paddingHorizontal: 10,
    paddingBottom: 10,
  },
  jcfs: {
    justifyContent: 'flex-start',
  },
  headerTypo: {
    marginLeft: 5,
    textAlign: 'left',
  },
  accordianBodyContainer: {
    paddingBottom: 15,
  },
  accordionContainer: {
    borderBottomWidth: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 10,
  },
});
