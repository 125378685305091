import {NavAction} from '../../config/navigation/type';
import {OnBoardingScreens} from './types';
import {navigation} from '../../config/navigation';
import {MainScreens} from '../../navigation/types';

export default {
  [OnBoardingScreens.Login]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.HomeBeforeLogin,
      {
        screen: OnBoardingScreens.Login,
        params,
      },
      navigateAction,
    );
  },
  [OnBoardingScreens.SignUp]: (params?: any, navigateAction?: NavAction) => {
    navigation.navigate(
      MainScreens.HomeBeforeLogin,
      {
        screen: OnBoardingScreens.SignUp,
        params,
      },
      navigateAction,
    );
  },
};

export const OnBoardingDeepLinkConfig = {
  OnBoardingAfterLogin: {
    screens: {
      Login: 'Login',
      SignUp: 'SignUp',
    },
  },
};
