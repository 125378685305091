import * as React from 'react';

import Icon from './Icon';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const ChecvronRight: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        d="M4.49821 5.99935L0.270708 10.4599C0.0972847 10.6296 1.99535e-07 10.8588 1.57773e-07 11.0976C1.16011e-07 11.3365 0.0972845 11.5657 0.270708 11.7354C0.355868 11.8192 0.457377 11.8858 0.569327 11.9312C0.681277 11.9766 0.801432 12 0.9228 12C1.04417 12 1.16432 11.9766 1.27627 11.9312C1.38822 11.8858 1.48973 11.8192 1.57489 11.7354L6.72972 6.63843C6.9029 6.46831 7 6.23896 7 6C7 5.76104 6.9029 5.53169 6.72972 5.36157L1.57489 0.264582C1.48973 0.180784 1.38822 0.114239 1.27627 0.0688155C1.16432 0.0233929 1.04417 1.82568e-07 0.922802 1.61347e-07C0.801434 1.40127e-07 0.68128 0.0233928 0.569329 0.0688153C0.457379 0.114239 0.35587 0.180784 0.27071 0.264582C0.0972864 0.434308 1.98214e-06 0.663507 1.94037e-06 0.902357C1.89861e-06 1.14121 0.0972863 1.37041 0.270709 1.54013L4.49821 5.99935Z"
        fill="#737D94"
      />
    </Icon>
  );
};

export default ChecvronRight;
