import {DefaultContext} from '../../utils/types';
import {OpenShiftContextState} from '../types/openShifts';
import React from 'react';

export const initialState: OpenShiftContextState = {
  days: [],
};

const defaultVal: DefaultContext<OpenShiftContextState> = {
  state: initialState,
  dispatch: null,
};

export const OpenShiftsContext = React.createContext(defaultVal);
