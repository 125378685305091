import {Box, HBox} from '../../../../../src/components/core/view';
import {CustomText, H5Text} from '../../../../../src/components/core/text';
import {HomeScreens, ShopperScreens} from '@buncha/home/navigation/types';
import React, {useCallback, useEffect, useState} from 'react';
import {
  SubstitutionItemParams,
  SubstitutionOptions,
} from '../../../../../src/itemDetails/types/itemReplacement';
import {
  useItemReplacementContext,
  useSubstituteItemInRun,
} from '../../../../../src/itemDetails/hooks/itemReplacement';

import AppModal from '@buncha/common/components/CustomModal/AppModal';
import {CustomFlatList} from '../../../../../src/components/core/list';
import {Divider} from 'native-base';
import If from '@buncha/components/conditional/If';
import {StyleSheet} from 'react-native';
import SubstitutionOptionCell from '../substitutionOptionCell';
import {navigation} from '@buncha/config/navigation';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';

interface RouteParamsType {
  runId?: number;
}

const BuyerReplacementSuggestions: React.FC = () => {
  const {state} = useItemReplacementContext();

  const {substituteItemInRun, itemSubstituteLoading, errorMessage} =
    useSubstituteItemInRun();

  const theme = useColorThemeType();

  const subOptions = state?.item?.substitutionOptions || [];

  const route = useRoute();
  const routeParams: RouteParamsType | undefined = route?.params;
  const runId = routeParams?.runId || 0;

  const [showErrorModal, setSetshowErrorModal] = useState('');

  const handleOnClose = () => {
    setSetshowErrorModal('');
  };

  useEffect(() => {
    if (errorMessage) setSetshowErrorModal(errorMessage);
  }, [errorMessage]);

  const onPressHandler = useCallback(
    async (substituteParams: SubstitutionItemParams) => {
      const response = await substituteItemInRun(substituteParams);
      if (response)
        navigation.navigate(HomeScreens.Shopper, {
          screen: ShopperScreens.BatchDetailsPage,
          params: {runId: runId},
        });
    },
    [runId, substituteItemInRun],
  );

  const renderItem = useCallback(
    (subItem: {item: SubstitutionOptions}) => {
      return (
        <SubstitutionOptionCell
          itemInfo={subItem?.item}
          onPressHandler={onPressHandler}
          loading={itemSubstituteLoading}
        />
      );
    },
    [itemSubstituteLoading, onPressHandler],
  );

  const separator = useCallback(() => <Divider />, []);

  return (
    <Box style={styles.parentBox}>
      <CustomText style={[styles.text]}>
        If this item is out of stock, please replace with one of the
        customer&apos;s suggested items
      </CustomText>
      <CustomFlatList
        showsVerticalScrollIndicator={false}
        data={subOptions}
        renderItem={renderItem}
        ItemSeparatorComponent={separator}
      />
      <If condition={Boolean(showErrorModal)}>
        <AppModal
          isOpen={true}
          onClose={handleOnClose}
          topCTAHandelr={handleOnClose}
          title={'Information!'}
          hideBottomCta
          modalBody={
            <HBox style={styles.modalBody}>
              <H5Text>{showErrorModal}</H5Text>
            </HBox>
          }
          ctaTopTitle={'OK'}
          topCTAColor={theme?.background.green[100]}
        />
      </If>
    </Box>
  );
};

export default React.memo(BuyerReplacementSuggestions);

const styles = StyleSheet.create({
  text: {
    textAlign: 'left',
    marginBottom: 15,
  },
  parentBox: {
    flex: 1,
  },
  modalBody: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});
