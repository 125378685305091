import {ColorThemeInfo} from '../type';

/**
 * @description
 * those who are commented used as color in component
 * add here component name infront of color to know which are used or not
 * @description
 * Use colour name like green, gray, blue and ever colour have there own code like 100, 200, etc;
 * @example if text has green so 100 denote primary colour and 200 denote to desabled green
 *
 */

const bunchaLight: ColorThemeInfo = {
  text: {
    green: {
      100: '#40d689', // Primary/Green,
      200: '#9feac4', // Disabled/Green,
    },
    gray: {
      100: '#737d94', // Neutral/Gray
      200: '#ededed', // Disabled/Bright Gray
      300: '#b6bac3', // lighter gray
    },
    blue: {
      100: '#5da9e9', // Secondary/Blue
      200: '#e4edfb',
      300: '#100e3a', // Neutral/Dark Blue
    },
    orange: {
      100: '#ff904f', // Tertiary/Orange
    },
    red: {
      100: '#ff4654', // Light-red
    },
    white: {
      100: '#ffffff', // Normal/White
    },
    yellow: {
      100: '#ffe475', // Text/Yellow
      200: '#fff0cb', // Text/Peach
      300: '#ffe475', // yellow-tan - 20
    },
  },
  background: {
    green: {
      100: '#40d689', // Primary/Green,
      200: '#9feac4', // Disabled/Green,
    },
    gray: {
      100: '#737d94', // Neutral/Gray
      200: '#ededed', // Disabled/Bright Gray
    },
    blue: {
      100: '#5da9e9', // Secondary/Blue
      200: '#e4edfb', // Background/Blue
      300: '#100e3a', // Neutral/Dark Blue
    },
    orange: {
      100: '#ff904f', // Tertiary/Orange
    },
    red: {
      100: '#ff4654', // Light-red
    },
    yellow: {
      100: '#ffe475', // Background/Yellow
      200: '#fff0cb', // Background/Peach
      300: '#ffe475', // yellow-tan - 20
    },
    white: {
      100: '#ffffff', // Normal/White
    },
  },
  icon: {
    green: {
      100: '#40d689', // Primary/Green,
      200: '#9feac4', // Disabled/Green,
    },
    gray: {
      100: '#737d94', // Neutral/Gray
      200: '#ededed', // Disabled/Bright Gray
    },
    blue: {
      100: '#5da9e9', // Secondary/Blue
      200: '#e4edfb', // Background/Blue
      300: '#100e3a', // Neutral/Dark Blue
    },
    orange: {
      100: '#ff904f', // Tertiary/Orange
    },
    red: {
      100: '#ff4654', // Light-red
    },
    yellow: {
      100: '#ffe475', // Background/Yellow
      200: '#fff0cb', // Background/Peach
      300: '#ffe475', // yellow-tan - 20
    },
    white: {
      100: '#ffffff', // Normal/White
    },
  },
  skeleton: {
    100: {startColor: '#DCDCDC', endColor: '#F0F0F0'},
  },
  shadow: {
    100: 'rgba(89, 89, 89, 0.8)',
  },
};

export default {
  bunchaLight: bunchaLight,
};
