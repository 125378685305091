import {Buyer, MostRecentAddress} from './common';
import {DeliveryNotes} from './orderDetails';

import {BagsHistory} from '../../AboutShift/types';

/* eslint-disable no-shadow */
export enum DriverOrdersTab {
  RunOrders = 'RunOrders',
  AllOrders = 'AllOrders',
}

export interface OrdersForDelivery {
  buyer: Buyer;
  deliveryInstruction: string;
  deliveryLocation: DeliveryLocation;
  id: number;
  run: RunInfo;
}

export interface DeliveryLocation {
  deliveryStatus: string;
  id: number;
  userAddress: MostRecentAddress;
  deliveryNote?: DeliveryNotes;
}

export interface RunInfo {
  id: number;
  lat: number;
  lon: number;
}

export interface SubmitDeliveryBagsCountResponse {
  submitDeliveryBagsCount: BagsHistory[];
}
