import React, {useCallback, useMemo} from 'react';

import {Analytics} from '../config/analytics/analytics';
import Browse from '../components/core/icon/Browse';
import MemoIcon from './components/MemoIcon';
import Memos from '@buncha/memos/screens/Memos';
import Order from '../orders/screens/Order';
import PersonIcon from '../components/core/icon/Person';
import Profile from '../profile/screens/Profile';
import Schedule from '../components/core/icon/Schedule';
import SupportIcon from '../components/core/icon/Support';
import {TabScreens} from './types';
import {View} from 'react-native';
import YourSchedule from '../home/screens/YourSchedule';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import font from '../appStyles/style/font';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {useColorThemeType} from '../appStyles/hooks/theme';

const Tab = createBottomTabNavigator();

const BottomTab: React.FC = () => {
  const theme = useColorThemeType();

  const unreadMemoCount = useAppSelector(
    gState => gState.onboarding.unreadMemoCount,
  );

  const openIntercom = useCallback(() => {
    Analytics.displayMessenger();
  }, []);

  const tabScreen = useCallback((props: any) => {
    return (
      <Tab.Screen
        name={props.name}
        component={props.component}
        options={{
          tabBarIcon: ({color}) => {
            return React.cloneElement(props.tabBarIcon, {
              fill: color,
            });
          },
          tabBarIconStyle: props.tabBarIconStyle,
          title: props.title,
          tabBarLabelStyle: {...font.FontSize.fontP10},
          tabBarAllowFontScaling: false,
        }}
        listeners={props.listeners}
      />
    );
  }, []);

  const tabBarIconStyle = useMemo(() => {
    return {
      alignSelf: 'center',
      marginLeft: 7,
      marginTop: 12,
    };
  }, []);

  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarActiveTintColor: theme?.text.blue[300],
        tabBarActiveBackgroundColor: theme?.background.white[100],
        tabBarInactiveTintColor: theme?.text.gray[300],
        tabBarLabelStyle: {...font.FontSize.fontP12},
      }}>
      {tabScreen({
        name: TabScreens.YourSchedule,
        component: YourSchedule,
        tabBarIconStyle: tabBarIconStyle,
        tabBarIcon: <Schedule />,
        title: 'My Schedule',
      })}

      {tabScreen({
        name: TabScreens.ActiveOrders,
        component: Order,
        tabBarIconStyle: tabBarIconStyle,
        tabBarIcon: <Browse height={30} width={30} viewBox="-5 0 25 25" />,
        title: 'Active Orders',
      })}
      {tabScreen({
        name: TabScreens.Memos,
        component: Memos,
        tabBarIconStyle: tabBarIconStyle,
        tabBarIcon: <MemoIcon count={unreadMemoCount} />,
        title: 'Memos',
      })}
      {tabScreen({
        name: TabScreens.Support,
        component: View,
        tabBarIconStyle: tabBarIconStyle,
        tabBarIcon: (
          <SupportIcon width={30} height={30} viewBox={'0 0 25 25'} />
        ),
        title: 'Support',
        listeners: {
          tabPress: (e: any) => {
            e.preventDefault();
            openIntercom();
          },
        },
      })}

      {tabScreen({
        name: TabScreens.Profile,
        component: Profile,
        tabBarIconStyle: tabBarIconStyle,
        tabBarIcon: <PersonIcon width={30} height={30} viewBox={'0 0 25 25'} />,
        title: 'Profile',
      })}
    </Tab.Navigator>
  );
};

export default BottomTab;

export const TabDeepLinkConfig = {
  Tabs: {
    screens: {
      Home: 'home',
      Receipt: 'receipt',
      Profile: 'users',
      OmniSearchTab: 'omniSearchTab',
    },
  },
};
