import {AddItemContextState} from '../types';
import {DefaultContext} from '../../utils/types';
import React from 'react';

export const initialState: AddItemContextState = {
  selectedQuantity: 1,
  selectedItem: undefined,
  searchResults: [],
};

const defaultVal: DefaultContext<AddItemContextState> = {
  state: initialState,
  dispatch: null,
};

export const AddItemContext = React.createContext(defaultVal);
