import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';
import {CustomDialogBox} from '@buncha/components/composites/dialogBox';
import If from '@buncha/components/conditional/If';
import {PButton} from '@buncha/components/core/Button';
import {H3Text, H4Text} from '@buncha/components/core/text';
import {navigation} from '@buncha/config/navigation';
import {useAppDispatch, useAppSelector} from '@buncha/config/redux/hooks';
import {MainScreens, TabScreens} from '@buncha/navigation/types';
import {onBoardingActions} from '@buncha/onBoarding/reducer';
import React, {useCallback} from 'react';
import {StyleSheet} from 'react-native';

const UnreadMemoModal = () => {
  const mortise = useFontTheme('mortise');
  const muli = useFontTheme('muli');
  const theme = useColorThemeType();
  const dispatch = useAppDispatch();

  const unreadMemoModal = useAppSelector(
    gState => gState.onboarding.unreadMemoModal,
  );

  const closeModal = useCallback(() => {
    dispatch(onBoardingActions.setUnreadMemoModal(false));
  }, [dispatch]);

  const navigateToMemos = useCallback(() => {
    closeModal();
    navigation.navigate(MainScreens.Tabs, {
      screen: TabScreens.Memos,
    });
  }, [closeModal]);

  return (
    <If condition={unreadMemoModal}>
      <CustomDialogBox
        modalBoxStyle={styles.modalBoxStyle}
        onClose={closeModal}
        header={
          <H3Text
            style={[
              styles.marBot10,
              {
                fontFamily: mortise[700].normal,
                color: theme?.text.blue[300],
              },
            ]}>
            Unread Important Messages!
          </H3Text>
        }
        body={
          <H4Text style={[styles.marBot_10, {fontFamily: muli[700].normal}]}>
            Hey! It seems like you’ve some unread important messages, please
            read those before proceeding to start shopping.
          </H4Text>
        }
        footer={
          <PButton
            title={'Open Memos'}
            style={styles.actionButton}
            titleStyle={{
              fontFamily: muli[700].normal,
            }}
            onPress={navigateToMemos}
          />
        }
      />
    </If>
  );
};

export default React.memo(UnreadMemoModal);

export const styles = StyleSheet.create({
  actionButton: {
    borderRadius: 50,
    marginVertical: 10,
  },
  modalBoxStyle: {padding: 10, borderRadius: 20},
  marBot10: {
    marginBottom: 10,
  },
  marBot_10: {
    marginBottom: -10,
  },
});
