import {StyleSheet} from 'react-native';
import font from '@buncha/appStyles/style/font';

const VanLocationMapStyle = StyleSheet.create({
  mainContainer: {
    height: 180,
    marginHorizontal: -20,
  },
});

const VanLoadHeaderStyle = StyleSheet.create({
  mainContainer: {
    marginVertical: 15,
  },
  driverNameBox: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
  },
  textAlign: {
    textAlign: 'left',
  },
  driverNameLabel: {
    ...font.FontSize.fontP10,
    ...font.fontTheme.muli[700],
    paddingBottom: 5,
  },
  driverName: {paddingRight: 10, textAlign: 'left'},
  callDriverBox: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  callDriver: {
    fontFamily: font.fonts.muliBold,
  },
  underLineText: {textDecorationLine: 'underline'},
});

const CallDriverBottomSheetStyle = StyleSheet.create({
  mainContainer: {
    width: '100%',
    paddingHorizontal: 10,
  },
  contactBoxStyled: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    marginVertical: 10,
  },
  titleContainer: {
    alignItems: 'flex-start',
  },
  buyerNameBox: {justifyContent: 'flex-start', alignItems: 'center'},
  renderBox: {marginVertical: 10},
  buyerName: {
    paddingLeft: 10,
  },
});

export {VanLocationMapStyle, VanLoadHeaderStyle, CallDriverBottomSheetStyle};
