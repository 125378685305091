import * as React from 'react';

import Svg, {Path, SvgProps} from 'react-native-svg';

const LocationUpdate = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      {...props}
      fillRule="evenodd"
      d="m15.13 14.068-.988.979a547.3 547.3 0 0 1-2.839 2.76 1.875 1.875 0 0 1-2.606 0l-2.91-2.83c-.365-.36-.671-.662-.918-.909a7.256 7.256 0 1 1 10.262 0ZM6.668 8.964v-.01a.474.474 0 0 0 0 .01Z"
      clipRule="evenodd"
    />
    <Path
      fill="#fff"
      d="m12.433 6.975-1.416-1.4.466-.467a.64.64 0 0 1 .471-.191.64.64 0 0 1 .471.191l.467.467a.661.661 0 0 1 .2.463.602.602 0 0 1-.184.462l-.475.475Zm-.483.492L8.417 11H7V9.583l3.533-3.533 1.417 1.417Z"
    />
  </Svg>
);
export default LocationUpdate;
