import {Platform, StyleSheet} from 'react-native';
import React, {useCallback, useState} from 'react';

import {CustomItemMode} from '../../../../itemDetails/types/CustomItem';
import DropItemModalBody from '../../ItemDetail/DropItemModalBody/DropItemModalBody';
import {H6Text} from '../../../../../src/components/core/text';
import If from '../../../../../src/components/conditional/If';
import {ItemDetailsScreen} from '../../../../itemDetails/navigation/types';
import {KeyboardAvoidingView} from 'native-base';
import {ShopperScreens} from '../../../../home/navigation/types';
import {Touchable} from '../../../../../src/components/core/touchable';
import {navigation} from '../../../../config/navigation';
import {useColorThemeType} from '../../../../../src/appStyles/hooks/theme';

interface DropItemFooterProps {
  orderItemId: number;
  orderId?: number;
  runId?: number;
  quantity?: number;
  showCustomItemCTA?: boolean;
  isUPCMode?: boolean;
  upcOrderItemId?: number;
}

const DropItemFooter: React.FC<DropItemFooterProps> = props => {
  const orderItemId = props?.orderItemId || 0;
  const orderId = props?.orderId;
  const runId = props?.runId;
  const quantity = props?.quantity;
  const theme = useColorThemeType('main');
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleOnPress = useCallback(() => {
    if ((!orderId || !orderItemId || !runId) && !props?.isUPCMode) return;
    if (props?.showCustomItemCTA)
      navigation.navigate(ShopperScreens.ItemDetails, {
        screen: ItemDetailsScreen.CustomItem,
        params: {
          orderId,
          orderItemId,
          mode: CustomItemMode.substituteItem,
          maxQuantity: quantity,
          runId: runId,
          isUPCMode: props?.isUPCMode,
          upcOrderItemId: props?.upcOrderItemId,
        },
      });
    else toggleModal();
  }, [
    orderId,
    orderItemId,
    runId,
    props?.showCustomItemCTA,
    props?.isUPCMode,
    props?.upcOrderItemId,
    quantity,
    toggleModal,
  ]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 10 : 0}>
      <React.Fragment>
        <Touchable style={styles.parentBox} onPress={handleOnPress}>
          <If condition={props?.showCustomItemCTA}>
            <H6Text
              style={[styles.underlinedText, {color: theme?.text.gray[100]}]}>
              Can&apos;t find item? Add Custom Item
            </H6Text>
          </If>
          <If condition={!props?.showCustomItemCTA}>
            <H6Text style={{color: theme?.text?.red?.[100]}}>
              Can&apos;t find the above items, drop this item
            </H6Text>
          </If>
        </Touchable>
        <If condition={showModal}>
          <DropItemModalBody
            open={showModal}
            orderItemId={orderItemId}
            onClose={toggleModal}
            isUPCMode={props?.isUPCMode ?? false}
          />
        </If>
      </React.Fragment>
    </KeyboardAvoidingView>
  );
};

export default React.memo(DropItemFooter);

const styles = StyleSheet.create({
  parentBox: {
    marginTop: 10,
    marginBottom: 10,
    alignSelf: 'center',
  },
  underlinedText: {
    textDecorationLine: 'underline',
  },
});
