import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  title: {
    marginTop: 20,
    marginBottom: 10,
  },
  divider: {
    marginHorizontal: 10,
  },
  mediaPill: {
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderRadius: 100,
  },
  mediaAvatar: {
    backgroundColor: 'transparent',
    borderWidth: 5,
    borderRadius: 100,
  },
  avatarContainer: {
    height: 65,
    width: 65,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  width100: {width: '100%'},
  iconStyle: {opacity: 0.7},
  playIcon: {position: 'absolute'},
});
