import {StyleSheet} from 'react-native';

const StoreCheckListStyle = StyleSheet.create({
  mainContainer: {
    justifyContent: 'flex-end',
    maxWidth: '35%',
  },
  separator: {marginRight: 10},
});

const StoreCheckBoxStyle = StyleSheet.create({
  mainContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageBoxList: {
    width: 34,
    height: 34,
  },
  container: {
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    width: 45,
    paddingVertical: 4,
    borderRadius: 15,
    bottom: 10,
    alignItems: 'center',
  },
  checkBoxStyle: {
    borderWidth: 0.5,
    width: 12,
    height: 12,
    borderRadius: 2,
    flexShrink: 0,
  },
});

export {StoreCheckListStyle, StoreCheckBoxStyle};
