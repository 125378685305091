import {
  ADD_ITEM_REMARK,
  ADD_NOTE_FOR_ORDER_ITEM,
  CAPTURE_ITEM_INFORMATION,
  DROP_UPC_ITEMS,
  MARK_ITEM_AS_FOUND_OR_MISSING,
  UPDATE_ORDER_ITEM_QUANTITY,
  UPDATE_ORDER_ITEM_STATUS,
} from '../../../src/config/graphQL/documentNode/mutation';
import {ApiState, OrderItemStatus} from '../../../src/common/types/common';
import {
  BuyerChatInfo,
  Buyers,
  FetchMaskedNumberResponse,
  FetchMaskedNumberVariable,
  GetBuyersForItemsResponse,
  GetRunForItemDetailResponse,
  RunForItemDetail,
  State,
  UpdateOrderItemQtyVariables,
  UpdateOrderItemQuantityResponse,
} from '../types/itemDetails';
import {
  CaptureItemParams,
  OrderItem as OrderItemTypeScanner,
} from '../types/ItemScanner';
import {Dispatch, useCallback, useContext, useReducer, useState} from 'react';
import {
  FETCH_MARSKED_NUMBER,
  GENERATE_DYNAMIC_TEXTS,
  GET_BUYERS_FOR_ITEM,
  GET_BUYER_INFO,
  GET_CATALOG_ITEM,
  GET_CATALOG_ITEM_TO_FETCH_UPDATED_QUANTITY,
  GET_CONSTANTS,
  GET_LOCATION_INFO_HISTORY,
  GET_RUN_FOR_ITEM_DETAIL,
} from '../../config/graphQL/documentNode/queries';
import {ItemDetailsContext, initialState} from '../context/ItemDetails';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {AddNotesForOrderItemResponse} from '../../config/graphQL/type';
import {Buyer} from '../../driver/types/common';
import {OrderItem} from '@buncha/home/types';
import {ToastType} from '../../components/composites/notification/type';
import {gqlService} from '../../config/graphQL';
import {useToastMessage} from './../../components/composites/notification/NativeBaseToast';

const reducer = {
  setItemData: function (
    state: State,
    action: PayloadAction<OrderItemTypeScanner>,
  ) {
    state.item = action.payload;
  },
  setBuyersForItem: function (state: State, action: PayloadAction<Buyers[]>) {
    state.buyersForItem = action.payload;
  },
  setReasonForDrop: function (state: State, action: PayloadAction<any>) {
    state.reasonsForDropping = action.payload;
  },
  setItemNotes: function (state: State, action: PayloadAction<string>) {
    state.itemNotes = action.payload;
  },
  hasAnyCustomerInstructionMsg: function (
    state: State,
    action: PayloadAction<boolean>,
  ) {
    state.hasAnyBuyerInstructions = action.payload;
  },
  setItemLocationData: function (state: State, action: PayloadAction<any>) {
    state.itemLocationInformation = action.payload;
  },
  setOrderItemIds: function (state: State, action: PayloadAction<number[]>) {
    state.orderItemIds = action.payload;
  },
  updateQtyByOrderItemId: function (
    state: State,
    action: PayloadAction<{updatedQty: number; orderItemId: number}>,
  ) {
    const {orderItemId, updatedQty} = action.payload;
    const data = state.buyersForItem.find(
      order => order?.orderItemId === orderItemId,
    );
    if (data) data.quantity = updatedQty;
  },
  setItemDataWithUpdatedQuantity: function (
    state: State,
    action: PayloadAction<OrderItemTypeScanner>,
  ) {
    state.item = {
      ...state.item,
      ...action.payload,
    };
  },
  setConstants: function (state: State, action: PayloadAction<any>) {
    state.constants = action.payload;
  },
};

const slice = createSlice({
  initialState,
  name: 'ItemDetailsContext',
  reducers: reducer,
});

export function useItemDetailsReducer() {
  const reducerInfo = useReducer(slice.reducer, initialState);
  return reducerInfo;
}

export function useItemDetailsContext() {
  return useContext(ItemDetailsContext);
}

export const ItemDetailsAction = slice.actions;

export function useCatalogItemManagement(dispatch: Dispatch<any> | null) {
  const [loadingItem, setLoadingItem] = useState(false);
  const getCatalogItemForShopper = async (
    orderItemId: number,
    isUPCMode: boolean,
  ) => {
    try {
      setLoadingItem(true);
      const res = await gqlService?.query({
        query: GET_CATALOG_ITEM,
        fetchPolicy: 'network-only',
        variables: {
          orderItemId: orderItemId,
          isUpcOrder: isUPCMode,
        },
      });
      if (res?.data && dispatch)
        dispatch(
          ItemDetailsAction.setItemData(res?.data?.getCatalogItemForShopper),
        );
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('error ===>', error);
    } finally {
      setLoadingItem(false);
    }
  };

  return {getCatalogItemForShopper, loadingItem};
}

export function useCatalogItemToFetchUpdatedQuantity(
  dispatch: Dispatch<any> | null,
) {
  const [loadingItem, setLoadingItem] = useState(false);
  const getCatalogItemToFetchUpdatedQty = async (orderItemId: number) => {
    try {
      setLoadingItem(true);
      const res = await gqlService?.query({
        query: GET_CATALOG_ITEM_TO_FETCH_UPDATED_QUANTITY,
        fetchPolicy: 'network-only',
        variables: {
          orderItemId: orderItemId,
        },
      });
      if (res?.data && dispatch)
        dispatch(
          ItemDetailsAction.setItemDataWithUpdatedQuantity(
            res?.data?.getCatalogItemForShopper,
          ),
        );
    } catch (error: any) {
    } finally {
      setLoadingItem(false);
    }
  };

  return {getCatalogItemToFetchUpdatedQty, loadingItem};
}

export function useBuyerManagement(dispatch: Dispatch<any> | null) {
  const [loadingBuyer, setLoadingBuyers] = useState(false);
  const getBuyersForItem = async (orderItemId: number) => {
    try {
      setLoadingBuyers(true);
      const res = await gqlService?.query<GetBuyersForItemsResponse>({
        query: GET_BUYERS_FOR_ITEM,
        fetchPolicy: 'network-only',
        variables: {
          orderItemId: orderItemId,
        },
      });
      if (res?.data && dispatch) {
        const val = res?.data?.itemWithBuyersAndSub;
        const orderItemsIds: number[] = [];

        val?.forEach(item => orderItemsIds.push(item?.orderItemId || 0));
        dispatch(ItemDetailsAction.setBuyersForItem(val));
        const hasAnyInstuction = val.find(i => Boolean(i.substitutionNote));
        dispatch(
          ItemDetailsAction.hasAnyCustomerInstructionMsg(
            Boolean(hasAnyInstuction),
          ),
        );
        dispatch(ItemDetailsAction.setOrderItemIds(orderItemsIds));
      }
    } catch (error: any) {
    } finally {
      setLoadingBuyers(false);
    }
  };

  return {loadingBuyer, getBuyersForItem};
}

export function useConfig(dispatch?: Dispatch<any> | null) {
  const [loading, setLoading] = useState(true);
  const [reasonList, setReasonList] = useState();
  const [subRequestLife, setSubRequestLife] = useState(0);
  const getConstants = async () => {
    setLoading(true);
    try {
      const res = await gqlService?.query({
        query: GET_CONSTANTS,
        fetchPolicy: 'network-only',
      });
      const dropReaons = JSON.parse(
        res?.data?.getConstants?.REASON_FOR_DROPPING,
      );
      const reqLife = Number(
        res?.data?.getConstants?.SUBSTITUTION_REQUEST_LIFE,
      );
      setSubRequestLife(reqLife);
      const configData = res?.data?.getConfig?.CONSTANTS?.RETRY_CONFIG;
      if (configData) gqlService?.setRetryConfigData(configData);
      if (dispatch) dispatch(ItemDetailsAction.setReasonForDrop(dropReaons));
      setReasonList(dropReaons);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  return {getConstants, loading, reasonList, subRequestLife};
}

export function useAddNotesForItem() {
  const [loading, setLoading] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const {dispatch} = useItemDetailsContext();
  const addNotesForItem = async (id: number, note: string) => {
    setLoading(true);
    try {
      const res = await gqlService?.mutation<AddNotesForOrderItemResponse>({
        mutation: ADD_NOTE_FOR_ORDER_ITEM,
        variables: {
          orderItemId: id,
          note: note,
        },
      });
      const noteData = res?.data?.addNoteForOrderItem?.note || '';
      if (dispatch) dispatch(ItemDetailsAction?.setItemNotes(noteData));
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };
  return {loading, addNotesForItem};
}

export function useGenerateDynamicTexts() {
  const [loading, setLoading] = useState(false);
  const [dynamicTexts, setDynamicTexts] = useState<string[]>([]);
  const [showErrorToast] = useToastMessage(ToastType.Error);

  const generateDynamicTexts = async (
    orderItemId?: number,
    customMessageKey?: string,
    orderId?: number,
    runId?: number,
  ) => {
    if (!orderItemId && !orderId) return;
    setLoading(true);
    try {
      const response = await gqlService?.query({
        query: GENERATE_DYNAMIC_TEXTS,
        fetchPolicy: 'network-only',
        variables: {orderItemId, customMessageKey, orderId, runId},
      });
      setDynamicTexts(response?.data?.generateDynamicTexts || []);
    } catch (err: any) {
      showErrorToast(err?.message);
    } finally {
      setLoading(false);
    }
  };

  return {loading, generateDynamicTexts, dynamicTexts};
}

export function useGetBuyerInfoByOrderId() {
  const [buyerLoading, setLoading] = useState(false);
  const [buyerDetail, setBuyerDetails] = useState<Buyer>();
  const [buyerChatInfo, setBuyerChatInfo] = useState<BuyerChatInfo>();
  const [showErrorToast] = useToastMessage(ToastType.Error);

  const getBuyerInfo = async (runId: number, orderId?: number) => {
    if (!orderId) return;
    setLoading(true);
    try {
      const response = await gqlService?.query({
        query: GET_BUYER_INFO,
        fetchPolicy: 'network-only',
        variables: {orderId: orderId.toString(), runId: runId},
      });
      setBuyerDetails(response?.data?.getOrder?.buyer);
      setBuyerChatInfo(response?.data?.getOrder?.buyerChatInfo);
    } catch (err: any) {
      showErrorToast(err?.message);
    } finally {
      setLoading(false);
    }
  };

  return {getBuyerInfo, buyerLoading, buyerDetail, buyerChatInfo};
}

export function useDropOrderItem() {
  const [droppingItem, setDroppingItem] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const dropOrderItem = async (orderItemIds: number[]) => {
    setDroppingItem(true);
    try {
      await gqlService?.mutation({
        mutation: UPDATE_ORDER_ITEM_STATUS,
        variables: {
          orderItemIds: orderItemIds,
          orderItemStatus: OrderItemStatus.dropped,
        },
      });
    } catch (err: any) {
      showErrorToast(err?.message);
    } finally {
      setDroppingItem(false);
    }
  };
  return {dropOrderItem, droppingItem};
}

export function useAddItemRemark() {
  const [loading, setLoading] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const addItemRemark = async (orderItemId: number, itemRemark: string) => {
    setLoading(true);
    try {
      await gqlService?.mutation({
        mutation: ADD_ITEM_REMARK,
        variables: {
          orderItemId: orderItemId,
          itemRemark: itemRemark,
        },
      });
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };
  return {addItemRemark, loading};
}

export function useGetRunForItemDetail(): [
  (runId: number) => void,
  ApiState<RunForItemDetail>,
] {
  const [state, setState] = useState<ApiState<RunForItemDetail>>({
    loading: false,
    error: undefined,
    data: undefined,
  });

  const getRunForItemDetail = useCallback(
    async (runId: number) => {
      setState({...state, loading: true});
      try {
        const response = await gqlService?.query<GetRunForItemDetailResponse>({
          query: GET_RUN_FOR_ITEM_DETAIL,
          variables: {
            runId: runId,
          },
          fetchPolicy: 'network-only',
        });
        if (response?.data?.getRun)
          setState({...state, data: response?.data.getRun, loading: false});
      } catch (error: any) {
        //use toast
        setState({...state, loading: false});
      }
    },
    [state],
  );

  return [getRunForItemDetail, state];
}

export function useUPCOrder() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [showSuccessToast] = useToastMessage(ToastType.Success);
  const [markingItem, setMarkingItem] = useState<boolean>(false);
  const [droppingItem, setDroppingItem] = useState(false);
  const [loadingItem, setLoadingItem] = useState(false);

  const dropUPCItem = async (id: number) => {
    try {
      setDroppingItem(true);
      const response = await gqlService?.mutation({
        mutation: DROP_UPC_ITEMS,
        fetchPolicy: 'network-only',
        variables: {
          upcOrderItemId: id,
        },
      });

      if (response?.data) return response?.data?.dropUpcOrderItem;
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setDroppingItem(true);
    }
  };

  const updateLocationData = async (
    params: Omit<CaptureItemParams, 'isUpcOrder'>,
    callback: any,
  ) => {
    try {
      setMarkingItem(true);
      const res = await gqlService?.mutation({
        mutation: CAPTURE_ITEM_INFORMATION,
        variables: {
          ...params,
          isUpcOrder: false,
        },
        fetchPolicy: 'network-only',
      });
      if (res?.data) {
        showSuccessToast('Details updated successfully');
        callback();
      }
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setMarkingItem(false);
    }
  };

  const getItemLocationHistory = async (
    orderItemId: number,
    esIndex: string,
    callback: any,
  ) => {
    try {
      setLoadingItem(true);
      const res = await gqlService?.query({
        query: GET_LOCATION_INFO_HISTORY,
        variables: {
          orderItemId: orderItemId,
          isUpcOrder: false,
          esIndex: esIndex,
        },
        fetchPolicy: 'network-only',
      });
      if (res?.data?.getAllItemInformation?.length)
        callback(res?.data?.getAllItemInformation[0]?.itemInfo);
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setLoadingItem(false);
    }
  };

  return {
    dropUPCItem,
    droppingItem,
    updateLocationData,
    markingItem,
    getItemLocationHistory,
    loadingItem,
  };
}

export const useGetConstants = (dispatch: Dispatch<any> | null) => {
  const [constantsLoading, setLoading] = useState(false);
  const getConstants = useCallback(async () => {
    setLoading(true);
    try {
      const response = await gqlService?.query<{getConstants: any}>({
        query: GET_CONSTANTS,
        fetchPolicy: 'cache-first',
      });
      const data = response?.data?.getConstants;
      if (data && dispatch) dispatch(ItemDetailsAction.setConstants(data));
      const configData = data?.RETRY_CONFIG;
      if (configData) gqlService?.setRetryConfigData(configData);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  return {getConstants, constantsLoading};
};

export function useLocationUpdate(dispatch: any) {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loadingItem, setLoadingItem] = useState(false);

  const markItem = async (
    itemInfoId: number,
    foundHere: boolean,
    reportMissing: boolean,
    callback: any,
  ) => {
    try {
      setLoadingItem(true);
      const res = await gqlService?.mutation({
        mutation: MARK_ITEM_AS_FOUND_OR_MISSING,
        fetchPolicy: 'network-only',
        variables: {
          itemInfoId: itemInfoId,
          foundHere: foundHere,
          reportMissing: reportMissing,
        },
      });
      if (res?.data && dispatch) {
        dispatch(
          ItemDetailsAction.setItemLocationData(
            res?.data?.markItemAsFoundOrMissing,
          ),
        );
        callback();
      }
    } catch (err: any) {
      showErrorToast(err.message);
    } finally {
      setLoadingItem(false);
    }
  };

  return {loadingItem, markItem};
}

export function useFetchMaskedNumber() {
  const [info, setinfo] = useState<ApiState<FetchMaskedNumberResponse>>({
    data: null,
    error: undefined,
    loading: false,
  });

  const fetchMaskedNumber = async (params: FetchMaskedNumberVariable) => {
    setinfo({
      ...info,
      loading: true,
    });
    try {
      const res = await gqlService?.query<FetchMaskedNumberResponse>({
        query: FETCH_MARSKED_NUMBER,
        fetchPolicy: 'network-only',
        variables: params,
      });
      if (res)
        setinfo({
          ...info,
          data: res.data,
          loading: false,
        });
    } catch (error: any) {
      setinfo({
        ...info,
        error: error,
      });
    }
  };

  return {fetchMaskedNumber, info};
}
export function useUpdateOrderItemQuantity() {
  const [info, setInfo] = useState<ApiState<OrderItem>>({
    data: null,
    error: undefined,
    loading: false,
  });
  const updateOrderItemQuantity = useCallback(
    async (params: UpdateOrderItemQtyVariables, callback: Function) => {
      setInfo({
        ...info,
        loading: true,
      });
      try {
        const res = await gqlService?.mutation<UpdateOrderItemQuantityResponse>(
          {
            mutation: UPDATE_ORDER_ITEM_QUANTITY,
            fetchPolicy: 'network-only',
            variables: params,
          },
        );
        if (res?.data)
          setInfo({
            ...info,
            data: res?.data?.updateOrderItemQuantity,
            loading: false,
          });
        callback(res?.data?.updateOrderItemQuantity);
      } catch (error: any) {
        setInfo({
          ...info,
          error: new Error(error),
        });
      }
    },
    [info],
  );

  return {updateOrderItemQuantity, info};
}
