/* eslint-disable no-shadow */

export enum DeliveryLocationStatus {
  notStarted = 'notStarted',
  onTheWay = 'onTheWay',
  readyForPickup = 'readyForPickup',
  delivered = 'delivered',
  cancelled = 'cancelled',
  readyToPack = 'readyToPack',
  orderPacked = 'orderPacked',
  toBeAssigned = 'toBeAssigned',
  driverAssigned = 'driverAssigned',
  receiptGenerated = 'receiptGenerated',
  refunded = 'refunded',
  deliveryFailed = 'deliveryFailed',
  awaitingHandover = 'awaitingHandover',
}

export interface MostRecentAddress {
  address: string;
  lat: number;
  lon: number;
  name: string;
  deliveryInstruction: string;
  dropPoint: string;
}
export interface Buyer {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  mostRecentAddress: MostRecentAddress;
  isVip?: boolean;
}
