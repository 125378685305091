import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  subContainer: {marginVertical: 15},
  imageBox: {
    marginRight: 20,
    position: 'relative',
  },
  textStyle: {textAlign: 'left'},
  image: {
    height: 75,
    width: 75,
  },
  headerText: {marginBottom: 10},
  priceText: {marginTop: 5},
});
