import {GestureResponderEvent, StyleSheet} from 'react-native';
import React, {useCallback, useEffect, useState} from 'react';

import {AccordianProps} from './type';
import {Box} from '../../core/view';
import {HTouchable} from '../../core/touchable';
import If from '../../conditional/If';

const Accordian: React.FC<AccordianProps> = React.forwardRef((props, ref) => {
  const [isEnable, setIsEnable] = useState(props.isExpanded || false);
  const externalOnpress = props.onPress;
  const onIsEnableChange = props.onIsEnableChange;
  const onPress = useCallback(
    (e: GestureResponderEvent) => {
      if (props.disabled) return;
      setIsEnable(!isEnable);
      if (typeof externalOnpress === 'function') externalOnpress(e);
      if (typeof onIsEnableChange === 'function') onIsEnableChange(!isEnable);
    },
    [props.disabled, isEnable, externalOnpress, onIsEnableChange],
  );
  let iconEnableComponent = props.iconEnable;
  let iconDisableComponent = props.iconDisable;

  const accordianRef = props.setAccordianRef;

  useEffect(() => {
    if (accordianRef) accordianRef({setIsEnable});
  }, [accordianRef]);

  useEffect(() => {
    if (props?.isExpanded !== undefined) setIsEnable(props?.isExpanded);
  }, [props?.isExpanded]);

  return (
    <React.Fragment>
      <Box style={props.containerStyle}>
        <HTouchable ref={ref} {...props} onPress={onPress}>
          {props.header}
          <If condition={isEnable}>{iconEnableComponent}</If>
          <If condition={!isEnable}>{iconDisableComponent}</If>
        </HTouchable>
        {props.headerBody}
      </Box>
      <Box style={[styles.defaultBox, props.childContainerStyle]}>
        <If condition={isEnable}>{props.children}</If>
      </Box>
    </React.Fragment>
  );
});

export default React.memo(Accordian);

const styles = StyleSheet.create({
  defaultBox: {
    // margin: 5,
  },
});
