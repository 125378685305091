import {Dimensions, StyleSheet} from 'react-native';
import React from 'react';
import {Box} from '../../../components/core/view';
import BagIcon from '../../../components/core/icon/BagIcon';
import {H2Text} from '../../../components/core/text';
import {useColorThemeType} from '../../../appStyles/hooks/theme';
import {isWeb} from '../../../utils/common';
import font from '../../../appStyles/style/font';

const NoShift = () => {
  const theme = useColorThemeType('main');

  return (
    <Box style={styles.parentContainer}>
      <BagIcon height={200} width={200} viewBox="-35 -25 190 190" />
      <H2Text
        style={{
          color: theme?.text?.blue[300],
          fontFamily: font.fonts.muliBold,
        }}>
        Hmm... it seems like there are no shifts assigned to you at the moment.
      </H2Text>
    </Box>
  );
};

export default React.memo(NoShift);

const styles = StyleSheet.create({
  parentContainer: {
    // flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    // marginTop: Dimensions.get('screen').height * 0.2,
  },
  typoContainer: {
    width: isWeb()
      ? Dimensions.get('screen').width * 0.5
      : Dimensions.get('screen').width * 0.6,
    marginLeft: isWeb()
      ? Dimensions.get('screen').width * 0.2
      : Dimensions.get('screen').width * 0.15,
  },
});
