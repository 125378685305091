import {Box, HBox} from '@buncha/components/core/view';
import {Dimensions, StyleSheet} from 'react-native';
import React, {useCallback, useState} from 'react';

import {CustomImage} from '@buncha/components/core/Image';
import {Divider} from 'native-base';
import If from '@buncha/components/conditional/If';
import {ItemDetailsScreen} from '@buncha/itemDetails/navigation/types';
import {ItemLocationInformation} from '../../../../itemDetails/types/itemDetails';
import LocationCrossIcon from '@buncha/components/core/icon/LocationCrossIcon';
import LocationInfoCell from '../LocationInfoCell';
import LocationTick from '@buncha/components/core/icon/LocationTick';
import LocationTypo from '@buncha/batchDetails/component/LocationTypo';
import LocationUpdate from '@buncha/components/core/icon/LocationUpdate';
import {ShopperScreens} from '@buncha/home/navigation/types';
import UpdateCell from '../UpdateCell/UpdateCell';
import {navigation} from '@buncha/config/navigation';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {useUPCOrder} from '@buncha/itemDetails/hooks/ItemDetails';

interface ItemLocationInfoProps {
  locationData: ItemLocationInformation;
  orderItemId: number;
  showDivider: boolean;
  dispatch: any;
  markItem: any;
  action: any;
  callback: any;
  onUpdate?: any;
}

const ItemLocationInfo = (props: ItemLocationInfoProps) => {
  const theme = useColorThemeType('main');
  const showDivider = props.showDivider ?? true;

  const [showUpdateSection, setShowUpdateSection] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isFound, setIsFound] = useState(false);
  const [isReported, setisReported] = useState(false);

  const {getItemLocationHistory} = useUPCOrder();

  const navigateToItemLocationPage = useCallback(() => {
    navigation.navigate(ShopperScreens.ItemDetails, {
      screen: ItemDetailsScreen.ItemLocationPage,
      params: {
        orderItemId: props.orderItemId,
        esIndex: props?.locationData?.esIndex,
      },
    });
  }, [props?.locationData?.esIndex, props.orderItemId]);

  const onNotFoundClick = useCallback(async () => {
    await props.markItem(props?.locationData?.id ?? 0, false, true, () => {
      setisReported(true);
      setIsFound(false);
    });
  }, [props]);

  const onFoundClick = useCallback(async () => {
    await props.markItem(props?.locationData?.id ?? 0, true, false, () => {
      setIsFound(true);
      setisReported(false);
    });
  }, [props]);

  const onUpdateClick = useCallback(() => {
    setIsUpdated(true);
    setIsFound(false);
    setisReported(false);
    props?.callback();
  }, [props]);

  return (
    <Box style={[styles.parentContainer]}>
      <If condition={showDivider}>
        <Divider />
      </If>
      <LocationTypo
        aisle={props?.locationData?.aisle ?? ''}
        context={props?.locationData.storeContext ?? ''}
        orderItemId={props?.orderItemId}
        showBottomLine={false}
        esIndex={props?.locationData?.esIndex}
      />
      <CustomImage
        source={props?.locationData.locationImage ?? ''}
        style={[styles.imageStyle]}
        resizeMode="contain"
      />
      <HBox style={[styles.locationStatusContainer]}>
        <LocationInfoCell
          icon={
            <LocationCrossIcon
              fill={isReported ? theme?.text.red[100] : theme?.icon?.blue[300]}
            />
          }
          onClick={onNotFoundClick}
          text="Not found here"
        />
        <LocationInfoCell
          icon={
            <LocationTick
              fill={isFound ? theme?.text.green[100] : theme?.icon?.blue[300]}
            />
          }
          onClick={onFoundClick}
          text={`Found it (${props?.locationData?.foundHere} found here)`}
        />
        <LocationInfoCell
          icon={
            <LocationUpdate
              fill={isUpdated ? theme?.text?.blue[100] : theme?.icon?.blue[300]}
            />
          }
          onClick={() => {
            if (props.onUpdate) {
              navigateToItemLocationPage();
            } else {
              getItemLocationHistory(
                props.orderItemId,
                props.locationData.esIndex,
                (response: any) => {
                  if (props.dispatch)
                    props?.dispatch(props.action.setItemLocationData(response));
                },
              );
              setShowUpdateSection(!showUpdateSection);
            }
          }}
          text="Update"
        />
      </HBox>
      <If condition={showUpdateSection}>
        <UpdateCell
          orderItemId={props?.orderItemId}
          onUpdate={onUpdateClick}
          itemLocationData={props?.locationData}
          id={props?.locationData?.id}
        />
      </If>
    </Box>
  );
};

export default React.memo(ItemLocationInfo);

const styles = StyleSheet.create({
  parentContainer: {
    marginTop: 0,
    borderWidth: 1,
    borderColor: 'transparent',
  },
  imageStyle: {
    width: Dimensions.get('screen').width * 0.9,
    height: 146,
    borderRadius: 5,
    marginTop: 15,
    marginLeft: 2,
  },
  locationStatusContainer: {
    paddingRight: 5,
    marginTop: 10,
    marginLeft: -2,
  },
});
