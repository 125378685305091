import {AddIcon, MinusIcon} from 'native-base';
import {Box, HBox} from '@buncha/components/core/view';
import {
  BuyerData,
  FormDataUpdate,
  MandatoryFieldNames,
} from '@buncha/itemDetails/types/ItemScanner';
import {CustomText, H5Text} from '@buncha/components/core/text';
import {
  ItemScannerAction,
  useItemScannerContext,
} from '@buncha/itemDetails/hooks/ItemScanner';
import React, {useCallback, useMemo} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import If from '@buncha/components/conditional/If';
import {PTextInput} from '@buncha/components/core/input/TextInput';
import {Touchable} from '@buncha/components/core/touchable';
import {checkPriceDecimals} from '@buncha/utils/common';
import font from '@buncha/appStyles/style/font';
import {styles} from './style';

interface UpdateQuantitySectionProps {
  item: BuyerData;
}

const UpdateQuantitySection = (props: UpdateQuantitySectionProps) => {
  const {state, dispatch} = useItemScannerContext();
  const {item} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');

  const quantityMandatory = useMemo(
    () =>
      Boolean(
        state?.buyerData?.[0]?.mandatoryFields?.find(
          field => field.fieldName === MandatoryFieldNames.quantityFound,
        ),
      ),
    [state?.buyerData],
  );

  const updateDataFilter = useMemo(() => {
    return state.formData.find(data => data.orderItemId === item.orderItemId);
  }, [item.orderItemId, state.formData]);

  const updateData = updateDataFilter?.quantity
    ? updateDataFilter
    : {...updateDataFilter};

  const pointerEvents = updateData?.dropped ? 'none' : undefined;

  const handleFormDataUpdate = useCallback(
    (update: FormDataUpdate, updateValue: any) => {
      if (dispatch)
        dispatch(
          ItemScannerAction.setFormData({
            orderItemId: item.orderItemId,
            update,
            updateValue,
          }),
        );
    },
    [dispatch, item.orderItemId],
  );

  const quantityUpdate = useCallback(
    (quantity: string) => {
      const qty = Number(quantity);
      if (checkPriceDecimals(quantity) && qty < 100)
        handleFormDataUpdate(FormDataUpdate.quantity, qty);
    },
    [handleFormDataUpdate],
  );

  const modifyQty = useCallback(
    (quantity: number) => {
      const val = quantity ? quantity.toString() : '';
      quantityUpdate(val);
    },
    [quantityUpdate],
  );

  const qnty = item?.updatedQuantity || item?.quantity || 0;

  let remainingQuantity = qnty - (updateData?.quantity || 0);

  const remainingQuantityTextColor = useMemo(() => {
    if (remainingQuantity === qnty) return theme?.text.gray[100];
    else if (remainingQuantity) return theme?.text.red[100];
    return theme?.text.green[100];
  }, [
    qnty,
    remainingQuantity,
    theme?.text.gray,
    theme?.text.green,
    theme?.text.red,
  ]);

  const qtyValue = updateData?.quantity ?? 0;

  return (
    <Box style={styles.marginTop5}>
      <HBox style={styles.marginBottom5}>
        <Box>
          <H5Text
            style={[
              styles.alignLeft,
              {
                color: theme?.text.blue[300],
                fontFamily: muliFont[700].normal,
              },
            ]}>
            Quantity found
            <If condition={quantityMandatory}>
              <H5Text style={{color: theme?.text.red[100]}}>*</H5Text>
            </If>
          </H5Text>
          <CustomText
            style={[
              styles.remainingText,
              {
                color: remainingQuantityTextColor,
                fontFamily: remainingQuantity
                  ? muliFont[700].normal
                  : muliFont[900].normal,
              },
            ]}>
            <If condition={remainingQuantity}>
              <If condition={remainingQuantity > 0}>
                {remainingQuantity} items remaining
              </If>
              <If condition={remainingQuantity < 0}>
                Added {Math.abs(remainingQuantity)} item(s) extra
              </If>
            </If>
            <If condition={!remainingQuantity}>All picked</If>
          </CustomText>
        </Box>
        <Box
          style={[
            styles.quantityContainer,
            styles.quantityPill,
            {
              borderColor: theme?.background.blue[300],
            },
          ]}>
          <HBox style={styles.quantityContainer}>
            <Touchable
              activeOpacity={1}
              hitSlop={{top: 25, bottom: 25, left: 15, right: 15}}
              disabled={!updateData?.quantity}
              onPress={() => modifyQty(qtyValue - 1)}>
              <MinusIcon
                size={3}
                color={
                  !updateData?.quantity
                    ? theme?.background.gray[100]
                    : theme?.background.blue[300]
                }
              />
            </Touchable>
            <PTextInput
              placeholder="0"
              pointerEvents={pointerEvents}
              placeholderTextColor={theme?.text.gray[300]}
              debounce={false}
              textAlign="center"
              value={updateData?.quantity ? updateData.quantity.toString() : ''}
              onChangeText={quantityUpdate}
              keyboardType="number-pad"
              returnKeyType="done"
              containerStyle={[styles.inputStyle, styles.quantityStyle]}
              style={{
                fontFamily: muliFont[700].normal,
                ...font.FontSize.fontH3,
                color: theme?.text.blue[300],
              }}
            />
            <Touchable
              activeOpacity={1}
              hitSlop={{top: 25, bottom: 25, left: 15, right: 15}}
              style={styles.addIconContainer}
              onPress={() => modifyQty(qtyValue + 1)}>
              <AddIcon size={3} color={theme?.background.blue[300]} />
            </Touchable>
          </HBox>
        </Box>
      </HBox>
    </Box>
  );
};

export default React.memo(UpdateQuantitySection);
