import * as React from 'react';

import {Path, Rect} from 'react-native-svg';

import Icon from './Icon';
import {IconProps} from './type';

const MinusTickIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Rect width={20} height={20} rx={10} />
      <Rect x={0.5} y={0.5} width={19} height={19} rx={9.5} stroke="#E54F37" />
      <Path fill="#fff" d="M4 9h12v2H4z" />
    </Icon>
  );
};

export default MinusTickIcon;
