import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const UploadProgressIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        stroke={props?.stroke || '#100E3A'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        d="M11.667 16.667h-8.75a1.25 1.25 0 0 1-1.25-1.25V4.583a1.25 1.25 0 0 1 1.25-1.25h14.167a1.25 1.25 0 0 1 1.25 1.25v5.025m-2.084 7.475v-5m0 0-2.083 2.084m2.083-2.084 2.084 2.084"
      />
      <Path
        stroke={props?.stroke || '#100E3A'}
        strokeWidth={1.25}
        d="M1.667 4.583a1.25 1.25 0 0 1 1.25-1.25h14.167a1.25 1.25 0 0 1 1.25 1.25v3.75H1.667v-3.75Z"
      />
      <Path
        fill={props?.stroke || '#100E3A'}
        d="M3.333 5.833a.833.833 0 1 1 1.667 0 .833.833 0 0 1-1.667 0ZM5.833 5.833a.833.833 0 1 1 1.667 0 .833.833 0 0 1-1.667 0Z"
      />
    </Icon>
  );
};

export default React.memo(UploadProgressIcon);
