import {H3Text, H5Text} from '../../../components/core/text';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {BottomButton} from '../../../components/composites/Button';
import {Box} from '../../../components/core/view';
import React from 'react';
import {styles} from './styles';

interface ReceiptModalProps {
  onClose: () => void;
}

export function ReceiptModal(props: ReceiptModalProps) {
  const {onClose} = props;
  const theme = useColorThemeType();
  const mortiseFont = useFontTheme('mortise');
  const muliFont = useFontTheme('muli');
  return (
    <Box style={styles.mainContainer}>
      <H3Text
        style={[
          styles.headerText,
          {
            color: theme?.text.blue[300],
            fontFamily: mortiseFont[700].normal,
          },
        ]}>
        Attention!
      </H3Text>
      <H5Text
        style={[
          {
            fontFamily: muliFont[700].normal,
            color: theme?.text.gray[100],
          },
        ]}>
        It is mandatory to submit receipts. Please upload all the receipts for
        this run and try again.
      </H5Text>
      <BottomButton title={'OK'} style={styles.buttonStyle} onPress={onClose} />
    </Box>
  );
}
