import {Dispatch, useContext, useReducer, useState} from 'react';
import {
  GET_ORDER_ITEM_BY_ID,
  GET_RECOMMENDATIONS_FOR_SUBSTITUTION,
  GET_RECOMMENDATIONS_FOR_SUBSTITUTION_FOR_UPC_SHIFTS,
  SEARCH_CATALOG,
  SEARCH_CATALOG_FOR_UPC,
} from '../../../src/config/graphQL/documentNode/queries';
import {
  GetOrderItemResponse,
  RecommendItemsForSubstitutesResponse,
  SearchCatalogResponse,
} from '../../../src/config/graphQL/type';
import {ItemReplacementContext, initialState} from '../context/itemReplacement';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {
  RecommendationsForSubParams,
  RecommendedItemCell,
  ReplacementItemDetails,
  SearchItemsForSubParams,
  SearchItemsForSubParamsUPC,
  SearchResults,
  State,
  SubstitutionItemParams,
  SubstitutionRequestParams,
} from '../types/itemReplacement';
import {
  SEND_SUBSTITUTION_REQUEST_TO_BUYER,
  SUBSTITUTE_ITEM_IN_RUN,
} from '../../../src/config/graphQL/documentNode/mutation';

import {Analytics} from '@buncha/config/analytics/analytics';
import {ToastType} from '../../components/composites/notification/type';
import {gqlService} from '../../../src/config/graphQL';
import {useToastMessage} from '../../../src/components/composites/notification';

const reducer = {
  setItemData: function (
    state: State,
    action: PayloadAction<ReplacementItemDetails | undefined>,
  ) {
    state.item = action.payload;
  },
  setSearchResults: function (
    state: State,
    action: PayloadAction<SearchResults[]>,
  ) {
    state.searchResults = action.payload;
    state.showCustomItemCTA = true;
  },
};

const slice = createSlice({
  initialState,
  name: 'ItemReplacementContext',
  reducers: reducer,
});

export function useItemReplacementReducer() {
  const reducerInfo = useReducer(slice.reducer, initialState);
  return reducerInfo;
}

export function useItemReplacementContext() {
  return useContext(ItemReplacementContext);
}

export const ItemReplacementAction = slice.actions;

export function useGetOrderItemInfo(dispatch: Dispatch<any> | null) {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ReplacementItemDetails | undefined>();
  const getOrderItemInfo = async (orderItemId: number) => {
    try {
      setLoading(true);
      const res = await gqlService?.query<GetOrderItemResponse>({
        query: GET_ORDER_ITEM_BY_ID,
        fetchPolicy: 'network-only',
        variables: {
          orderItemId: orderItemId,
        },
      });
      const itemInfo = res?.data?.getOrderItemById;
      if (dispatch) dispatch(ItemReplacementAction?.setItemData(itemInfo));
      setData(itemInfo);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    } finally {
      setLoading(false);
    }
  };

  return {getOrderItemInfo, data, loading};
}

export function useGetRecommendedItemsForSubstitution() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<RecommendedItemCell[]>([]);
  const getRecommendedItemsForSubstitution = async (
    params: RecommendationsForSubParams,
  ) => {
    try {
      setLoading(true);
      const res = await gqlService?.query<RecommendItemsForSubstitutesResponse>(
        {
          query: GET_RECOMMENDATIONS_FOR_SUBSTITUTION,
          fetchPolicy: 'network-only',
          variables: params,
        },
      );
      const items = res?.data?.recommendItemsForSubstitutes?.items || [];
      setData(items);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  const getRecommendedItemsForSubstitutionInUPC = async (
    upcOrderItemId: number,
  ) => {
    try {
      setLoading(true);
      const res = await gqlService?.query({
        query: GET_RECOMMENDATIONS_FOR_SUBSTITUTION_FOR_UPC_SHIFTS,
        fetchPolicy: 'network-only',
        variables: {upcOrderItemId: upcOrderItemId},
      });
      const items =
        res?.data?.getRecommendItemsForSubstitutesInUpc?.items || [];
      setData(items);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {
    getRecommendedItemsForSubstitution,
    data,
    loading,
    getRecommendedItemsForSubstitutionInUPC,
  };
}

export function useSendSubstitutionRequestToBuyer() {
  const [loading, setLoading] = useState(false);
  const [showSuccessToast] = useToastMessage(ToastType.Success);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const sendSubstitutionRequestToBuyer = async (
    params: SubstitutionRequestParams,
  ) => {
    try {
      setLoading(true);
      await gqlService?.mutation({
        mutation: SEND_SUBSTITUTION_REQUEST_TO_BUYER,
        fetchPolicy: 'network-only',
        variables: params,
      });
      setLoading(false);
      showSuccessToast('Substitution request sent successfully');
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {sendSubstitutionRequestToBuyer, loading};
}

export function useSubstituteItemInRun() {
  const [itemSubstituteLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const substituteItemInRun = async (params: SubstitutionItemParams) => {
    try {
      setErrorMessage('');
      setLoading(true);
      const response = await gqlService?.mutation({
        mutation: SUBSTITUTE_ITEM_IN_RUN,
        fetchPolicy: 'network-only',
        variables: params,
      });
      setLoading(false);
      return response?.data;
    } catch (err: any) {
      setLoading(false);
      setErrorMessage(err?.message);
    }
  };

  return {substituteItemInRun, itemSubstituteLoading, errorMessage};
}

export function useSearchItemsForSubstitution() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loading, setLoading] = useState(false);
  const [moreItems, setMoreItems] = useState(true);
  const {dispatch, state} = useItemReplacementContext();
  const searchItemsForSubstitution = async (
    params: SearchItemsForSubParams,
  ) => {
    if (params.from === 0) {
      setMoreItems(true);
      if (dispatch) dispatch(ItemReplacementAction.setSearchResults([]));
    }
    try {
      setLoading(true);
      const res = await gqlService?.query<SearchCatalogResponse>({
        query: SEARCH_CATALOG,
        fetchPolicy: 'network-only',
        variables: params,
      });
      const searchItems = res?.data?.searchCatalog?.items || [];
      const resultExists = searchItems?.length;
      const totalResult = res?.data?.searchCatalog?.totalResult || 0;
      const searchSource = 'Add Replacement';
      const eventProps = {
        keyword: params.searchQuery,
        storeId: params.storeId,
        catalogItemId: params.catalogItemId,
        source: searchSource,
      };
      if (
        totalResult === 0 ||
        res?.data?.searchCatalog?.searchRelevance?.[0]?.key === 'other'
      )
        Analytics.eventWithProps('Searched but not found', {
          ...eventProps,
          from: params.from,
          size: params.size,
        });
      if (res?.data?.searchCatalog?.searchRelevance?.length && resultExists)
        Analytics.eventWithProps('Searched Result', {
          ...eventProps,
          type: 'CTA',
          top5_searched_name: searchItems
            .map((item: SearchResults) => item.name)
            .slice(0, 5),
        });
      if (dispatch && resultExists)
        dispatch(
          ItemReplacementAction.setSearchResults([
            ...(params.from ? state.searchResults : []),
            ...searchItems,
          ]),
        );

      if (searchItems.length < params.size) setMoreItems(false);

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {searchItemsForSubstitution, loading, moreItems};
}

export function useSearchItemsForSubstitutionInUPC() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loadingUPC, setLoading] = useState(false);
  const [moreItemsUPC, setMoreItems] = useState(true);
  const {dispatch, state} = useItemReplacementContext();
  const searchItemsForSubstitutioninUPC = async (
    params: SearchItemsForSubParamsUPC,
  ) => {
    if (params.from === 0) {
      setMoreItems(true);
      if (dispatch) dispatch(ItemReplacementAction.setSearchResults([]));
    }
    try {
      setLoading(true);
      const res = await gqlService?.query({
        query: SEARCH_CATALOG_FOR_UPC,
        fetchPolicy: 'network-only',
        variables: params,
      });
      const searchItems = res?.data?.searchCatalogForUPC?.items || [];
      if (dispatch && searchItems?.length)
        dispatch(
          ItemReplacementAction.setSearchResults([
            ...(params.from ? state.searchResults : []),
            ...searchItems,
          ]),
        );
      if (searchItems.length < params.size) setMoreItems(false);

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {searchItemsForSubstitutioninUPC, loadingUPC, moreItemsUPC};
}
