import {Box, HBox} from '../../../../../src/components/core/view';
import {
  SubstitutionItemParams,
  SubstitutionOptions,
} from '../../../../../src/itemDetails/types/itemReplacement';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../src/appStyles/hooks/theme';

import {ActivityIndicator} from 'react-native';
import {CustomImage} from '../../../../../src/components/core/Image';
import {H5Text} from '../../../../../src/components/core/text';
import {PButton} from '../../../../../src/components/core/Button';
import React from 'react';
import {scaleFonts} from '../../../../../src/appStyles/style/font';
import {styles} from './styles';
import {useItemReplacementContext} from '../../../../../src/itemDetails/hooks/itemReplacement';

interface SubstitutionOptionsCellProps {
  onPressHandler: (params: SubstitutionItemParams) => void;
  itemInfo?: SubstitutionOptions;
  loading?: boolean;
}

const SubstitutionOptionsCell: React.FC<
  SubstitutionOptionsCellProps
> = props => {
  const {state} = useItemReplacementContext();
  const orderItemIdToBeReplaced = state?.item?.id || 0;
  const itemToBeSubstituted = state?.item;
  const quantity = state?.item?.quantity || 0;
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const item = props?.itemInfo;
  const name = item?.name || '';
  const imageUrl = item?.itemImageUrl || '';
  const price = item?.price || 0;
  const priceToShow = (item?.price || 0) / 100;
  const catalogItemId = item?.catalogItemId || 0;
  const isCustomItem = item?.isCustomItem || false;
  const orderItem = item?.orderItem;
  const orderId = orderItem?.orderId || 0;
  const position = orderItem?.position || 0;
  const substitutionItemId = item?.id || 0;

  const substituteParams: SubstitutionItemParams = {
    catalogItemId: catalogItemId,
    isCustomItem: isCustomItem,
    itemImageUrl: imageUrl,
    name: name,
    orderId: orderId,
    orderItemId: orderItemIdToBeReplaced,
    position: position,
    price: price,
    quantity: quantity,
    substitutedBy: JSON.stringify(itemToBeSubstituted),
    substitutionItemId: substitutionItemId,
  };

  return (
    <HBox style={styles.parentBox}>
      <Box style={styles.imageBox}>
        <CustomImage style={styles.image} source={imageUrl} />
      </Box>
      <Box style={styles.textBox}>
        <HBox>
          <Box style={styles.namePriceBox}>
            <H5Text
              numberOfLines={2}
              alignText="left"
              style={{
                color: theme?.text?.blue?.[300],
                fontFamily: muli?.[700]?.normal,
              }}>
              {name}
            </H5Text>
            <H5Text
              alignText="left"
              style={{
                color: theme?.text?.blue?.[300],
                fontFamily: muli?.[700]?.normal,
              }}>
              $ {priceToShow}
            </H5Text>
          </Box>

          <PButton
            style={styles.replaceCTA}
            title="Replace"
            loadingIndicator={<ActivityIndicator />}
            disabledBgColor={theme?.background?.green?.[200]}
            titleStyle={{fontSize: scaleFonts(12)}}
            onPress={() => props?.onPressHandler(substituteParams)}
            isLoading={props?.loading}
            disabled={props?.loading}
          />
        </HBox>
      </Box>
    </HBox>
  );
};

export default React.memo(SubstitutionOptionsCell);
