import {StyleSheet} from 'react-native';
import {getOs} from '@buncha/utils/appInfo';
import {scaleFonts} from '@buncha/appStyles/style/font';

const BottomSheetStyle = StyleSheet.create({
  mainContainer: {
    paddingHorizontal: 10,
  },
  imageContainer: {
    height: 125,
    width: '100%',
    // flexShrink: 0,
    marginBottom: 15,
  },
  leftAlign: {
    textAlign: 'left',
    marginBottom: 15,
  },
  inputContainer: {
    borderWidth: 1,
    height: 51,
    width: '100%',
    borderRadius: 12,
    marginBottom: 15,
  },
  inputBox: {
    marginTop: getOs() === 'android' ? scaleFonts(20, 'height') : 0,
    borderWidth: 1,
    borderColor: 'red',
  },
  bottomButton: {
    width: '100%',
    marginBottom: 0,
  },
});

export {BottomSheetStyle};
