import {CustomText, H6Text} from '../../../../../../src/components/core/text';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../../src/appStyles/hooks/theme';

import {Box} from '../../../../../../src/components/core/view';
import React from 'react';
import {RunInfo} from '../../../../../../src/driver/types/orderDetails';
import {StyleSheet} from 'react-native';

interface OrderInfoHeaderProps {
  runInfo: RunInfo | undefined;
}

const OrderInfoHeader: React.FC<OrderInfoHeaderProps> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const runInfo = props?.runInfo;
  const deliveryDate = runInfo?.deliveryDateData?.arrivalDate || '';
  const dropStartTime = runInfo?.dropOffStartTime || '';
  const dropEndTime = runInfo?.dropOffEndTime || '';
  return (
    <Box>
      <H6Text
        style={[
          styles.textAlignment,
          {color: theme?.text?.blue?.[300], fontFamily: muli?.[700]?.normal},
        ]}>
        Order Details
      </H6Text>
      <CustomText
        style={[
          styles.textAlignment,
          {color: theme?.text?.gray?.[100], fontFamily: muli?.[600]?.normal},
        ]}>
        Deliver this order by: {deliveryDate}, {dropStartTime} - {dropEndTime}
      </CustomText>
    </Box>
  );
};

export default React.memo(OrderInfoHeader);

const styles = StyleSheet.create({
  textAlignment: {
    textAlign: 'left',
  },
});
