import {Box, HBox} from '../../core/view';

import {BackHeaderLeft} from '../../../../src/common/components/BackHeaderLeft';
import {HeaderProps} from './type';
import If from '../../conditional/If';
import ImageUploader from '@buncha/components/composites/ImageUploader';
import LoaderComponents from '../CustomLoader/LoaderComponents';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {StatusBar} from 'react-native';
import {styles} from './styles';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

const Header: React.FC<HeaderProps> = props => {
  const theme = useColorThemeType();
  const containerStyle = [
    styles.defaultBox,
    {backgroundColor: theme?.background.white[100]},
    props.style,
  ];
  return (
    <React.Fragment>
      <If condition={!props.hideTopSafeArea}>
        <SafeAreaView
          mode="padding"
          edges={['top']}
          style={{
            backgroundColor:
              props.headerColor || theme?.background?.blue?.[300],
          }}
        />
        <StatusBar
          barStyle={'light-content'}
          backgroundColor={theme?.background?.blue?.[300]}
        />
      </If>
      <Box style={containerStyle}>
        <If condition={!props?.hideUploadFile}>
          <ImageUploader />
        </If>
        <HBox>
          <If condition={props.headerLeftComponent}>
            {props.headerLeftComponent}
          </If>
          <If
            condition={
              !props.headerLeftComponent && props.showDefaultLeftHeader
            }>
            <BackHeaderLeft onClick={props?.handler} />
          </If>
          <If condition={props.headerRightComponent}>
            {props.headerRightComponent}
          </If>
        </HBox>

        <If condition={props.loading}>
          <LoaderComponents showBottomText={props?.showLoaderText ?? false} />
        </If>
        <If condition={!props.loading}>{props.children}</If>
      </Box>
      <If condition={!props.hideBottomSafeArea}>
        <SafeAreaView
          mode="padding"
          edges={['bottom']}
          style={{backgroundColor: theme?.text.white[100]}}
        />
      </If>
    </React.Fragment>
  );
};

export default Header;
