import {ActivityIndicator, StyleSheet, View} from 'react-native';
import {H3Text, H5Text} from '../../components/core/text';
import React, {useCallback, useEffect, useState} from 'react';
import {useColorThemeType, useFontTheme} from '../../appStyles/hooks/theme';
import {
  useGetOrderItemById,
  useUpdateOrderItemPrice,
} from '../hooks/ItemUpdate';

import {BottomButton} from '../../components/composites/Button';
import {Box} from 'native-base';
import CustomScrollView from '../../components/composites/CustomScrollView/CustomScrollView';
import Dollar from '../../components/core/icon/Dollar';
import Header from '../../components/composites/Header/Header';
import ItemDetails from '../components/ItemScanner/ItemDetails';
import KeyboardAvoidLayout from '../../components/composites/keyboardAvoidLayout';
import {UpdateItemsPriceParams} from '../types/Itemupdate';
import {navigation} from '../../config/navigation';
import {styles} from '../styles/ItemUpdate';
import {useRoute} from '@react-navigation/native';
import {TextInputGroup} from '../../components/core/input';
import {HomeScreens, ShopperScreens} from '../../home/navigation/types';

const ItemUpdate = () => {
  const theme = useColorThemeType();
  const {orderItemLoading, orderItemData, getOrderItemById} =
    useGetOrderItemById();
  const {loading, updateItemsPrice} = useUpdateOrderItemPrice();
  const [value, setValue] = useState('');
  const fontTheme = useFontTheme();
  const route = useRoute();
  const routeParams: any = route?.params;
  const orderItemId = Number(routeParams?.orderItemId);
  const runId = Number(routeParams?.runId);
  const returnPage = routeParams?.returnPage;

  const navigateToRun = useCallback(() => {
    if (returnPage === ShopperScreens.OrderPackingPage)
      return navigation.navigate(HomeScreens.Shopper, {
        screen: ShopperScreens.OrderPackingPage,
        params: {runId},
      });
    navigation.navigate(HomeScreens.Shopper, {
      screen: ShopperScreens.BatchDetailsPage,
      params: {runId},
    });
  }, [returnPage, runId]);

  const handleUpdatePrice = useCallback(async () => {
    const params: UpdateItemsPriceParams = {
      orderItemId,
      runId,
      price: Number(value) * 100,
    };
    const response = await updateItemsPrice(params);
    if (response) navigateToRun();
  }, [navigateToRun, orderItemId, runId, updateItemsPrice, value]);

  useEffect(() => {
    getOrderItemById(orderItemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItemId]);

  const handleTextChange = useCallback((text: string) => {
    const price = Number(text);
    if (price <= 250 && (text.length === 0 || Boolean(price))) setValue(text);
  }, []);

  const submitButtonDisabled = loading || value.length === 0;

  if (orderItemLoading)
    return (
      <View
        style={[
          StyleSheet.absoluteFill,
          styles.loader,
          {
            backgroundColor: theme?.background.gray[100],
          },
        ]}>
        <ActivityIndicator />
      </View>
    );

  return (
    <KeyboardAvoidLayout>
      <Header hideBottomSafeArea>
        <CustomScrollView>
          <React.Fragment>
            <ItemDetails scanned item={orderItemData} title="Update Price" />
            <Box style={styles.updatePriceBox}>
              <H5Text style={styles.updatePriceText}>
                Try to match the price of the item
              </H5Text>
              <TextInputGroup.PTextInput
                colorThemeType="default"
                value={value}
                onChangeText={handleTextChange}
                keyboardType="decimal-pad"
                clearButtonMode="while-editing"
                placeholder="0.00"
                debounce={false}
                autoFocus={true}
                leftComponent={<H3Text style={styles.textIcon}>$</H3Text>}
                containerStyle={[
                  styles.inputContainerStyle,
                  {
                    borderColor: theme?.text.blue[300],
                  },
                ]}
                style={[
                  styles.inputStyle,
                  {
                    fontFamily: fontTheme[400].normal,
                    color: theme?.text.blue[300],
                  },
                ]}
              />
            </Box>
          </React.Fragment>
        </CustomScrollView>
      </Header>

      <BottomButton
        disabled={submitButtonDisabled}
        loadingText={'Updating...'}
        changeStyleOnLoading
        showDividers={true}
        onPress={handleUpdatePrice}
        style={styles.bottomButton}
        title={
          <Box style={[styles.rowFlex]}>
            <Dollar />
            <Box style={[styles.jCC]}>
              <H3Text style={{color: theme?.text.white[100]}}>
                Update Price
              </H3Text>
            </Box>
          </Box>
        }
      />
    </KeyboardAvoidLayout>
  );
};

export default React.memo(ItemUpdate);
