import {Dimensions, PixelRatio} from 'react-native';

import resourse from '../../resource';

const {width: SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window');
// Based on iPhone 8 scale
const maxWidth = 820;
const widthBaseScale =
  SCREEN_WIDTH > maxWidth ? maxWidth / 375 : SCREEN_WIDTH / 375;
const heightBaseScale = SCREEN_HEIGHT / 667;

export function scaleFonts(size: number, based = 'width') {
  let newSize = widthBaseScale * size;
  if (based === 'width') {
    newSize = (widthBaseScale * 0.9 + heightBaseScale * 0.1) * size;
    if (widthBaseScale < 1 && heightBaseScale < 1) newSize = 0.9 * size;
    if (widthBaseScale >= 1 && heightBaseScale >= 1) newSize = size;
  }

  if (based === 'height')
    if (heightBaseScale > 1.15) newSize = size * 1.15;
    else if (heightBaseScale <= 1.2 && heightBaseScale >= 0.9)
      newSize = size * heightBaseScale;
    else newSize = size * 0.8;

  const normalizeFont = Math.round(PixelRatio.roundToNearestPixel(newSize));
  return normalizeFont;
}

const fontH1 = {fontSize: scaleFonts(26)};
const fontH2 = {fontSize: scaleFonts(22)};
const fontH3 = {fontSize: scaleFonts(20)};
const fontH4 = {fontSize: scaleFonts(18)};
const fontH5 = {fontSize: scaleFonts(16)};
const fontH6 = {fontSize: scaleFonts(14)};
const fontP12 = {fontSize: scaleFonts(12)}; // customtext
const fontP10 = {fontSize: scaleFonts(10)}; //psmalltext

const fontSize = {
  fontH1: fontH1,
  fontH2: fontH2,
  fontH3: fontH3,
  fontH4: fontH4,
  fontH5: fontH5,
  fontH6: fontH6,
  fontP12: fontP12,
  fontP10: fontP10,
};

const muli = {
  400: {
    normal: resourse.fonts.muliRegular,
  },
  600: {
    normal: resourse.fonts.muliSemiBold,
  },
  700: {
    normal: resourse.fonts.muliBold,
  },
  900: {
    normal: resourse.fonts.muliBlack,
  },
};

const mortise = {
  200: {
    normal: resourse.fonts['Mortise-X-Light'],
  },
  300: {
    normal: resourse.fonts['Mortise-Light'],
  },
  400: {
    normal: resourse.fonts['Mortise-Regular'],
  },
  500: {
    normal: resourse.fonts['Mortise-Medium'],
  },
  600: {
    normal: resourse.fonts['Mortise-Medium'],
  },
  700: {
    normal: resourse.fonts['Mortise-Bold'],
  },
  800: {
    normal: resourse.fonts['Mortise-X-Bold'],
  },
  900: {
    normal: resourse.fonts['Mortise-X-Bold'],
  },
};

export default {
  fontTheme: {
    muli: muli,
    mortise: mortise,
  },
  fonts: resourse.fonts,
  FontSize: fontSize,
};
