import {Box} from '../../../components/core/view';
import {H5Text} from '../../../components/core/text';
import React, {useMemo} from 'react';
import {StyleSheet} from 'react-native';
import font from '../../../appStyles/style/font';
import {getWidth} from '../../../utils/common';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

interface Props {
  quantity: number;
  hasTotalQunatity?: boolean;
}

function ItemQuantityPill(props: Props) {
  const theme = useColorThemeType('main');

  const getText = useMemo(() => {
    if (props?.hasTotalQunatity) return `Total Qty: ${props.quantity}`;
    return `Qty: ${props.quantity}`;
  }, [props?.hasTotalQunatity, props.quantity]);

  return (
    <Box
      style={[
        styles.ParentContainer,
        {backgroundColor: theme?.background?.yellow[300]},
      ]}>
      <H5Text style={[styles.typo, {color: theme?.text?.blue[300]}]}>
        {getText}
      </H5Text>
    </Box>
  );
}

export default React.memo(ItemQuantityPill);

const styles = StyleSheet.create({
  ParentContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 2,
    height: 27,
    minWidth: getWidth(56),
    borderRadius: 20,
    paddingHorizontal: 10,
  },
  typo: {
    ...font.FontSize.fontP10,
    fontFamily: font.fonts.muliBold,
  },
});
