import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  headerIconContainer: {
    marginRight: 20,
  },
  rightIcon: {
    marginLeft: 15,
  },
});
