import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  useAddBagsUsedForOrder,
  useNavigateVanLoadContext,
} from '@buncha/batchDetails/hooks/NavigateVanLoad';

import {Analytics} from '@buncha/config/analytics/analytics';
import {BottomButton} from '@buncha/components/composites/Button';
import BottomSheet from '@buncha/components/composites/bottomSheet';
import {BottomSheetStyle} from './style';
import {Box} from '@buncha/components/core/view';
import {CustomImage} from '@buncha/components/core/Image';
import {EventType} from '@buncha/config/analytics/type';
import {H5Text} from '@buncha/components/core/text';
import {PTextInput} from '@buncha/components/core/input/TextInput';
import {SpinnerComponent} from '@buncha/components/core/loader';
import {checkPriceDecimals} from '@buncha/utils/common';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

interface Props {
  open: boolean;
  onClose: () => void;
  buyerName: string;
  usedBag: number;
  orderId: number;
}

const UsedBagBottomSheet = (props: Props) => {
  const {open, buyerName, usedBag, orderId} = props;
  const theme = useColorThemeType();
  const [bagCount, setBagCount] = useState<number>(usedBag || 0);

  const {dispatch} = useNavigateVanLoadContext();

  const {addBagsUsersForOrder, loading} = useAddBagsUsedForOrder(dispatch);

  const handleChange = useCallback((count: string) => {
    if (checkPriceDecimals(count) && Number(count) <= 200)
      setBagCount(Number(count));
  }, []);

  const getBorderColor = useMemo(() => {
    if (!bagCount) return theme?.text?.gray?.[200];
    return theme?.text?.blue?.[300];
  }, [bagCount, theme?.text?.blue, theme?.text?.gray]);

  const onPressHandler = useCallback(async () => {
    await addBagsUsersForOrder(orderId, bagCount);
    Analytics.eventWithProps('Confirm bags/boxes used', {
      type: EventType.cta,
      orderId: orderId,
      usedBag: bagCount,
    });
    props.onClose();
  }, [addBagsUsersForOrder, bagCount, orderId, props]);

  useEffect(() => {
    if (open)
      Analytics.eventWithProps('Used Bag Bottom Sheet Open', {
        type: EventType.modal,
        orderId: orderId,
        buyerName: buyerName,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <BottomSheet open={open} onClose={usedBag ? props.onClose : undefined}>
      <Box style={BottomSheetStyle.mainContainer}>
        <CustomImage
          style={BottomSheetStyle.imageContainer}
          // resizeMode="cover"
          source={require('@buncha/resource/images/UsedBagImage.png')}
        />
        <H5Text
          style={[BottomSheetStyle.leftAlign, {color: theme?.text.blue[300]}]}>
          No. Of bags/boxes used to organize{' '}
          <H5Text style={{color: theme?.text.red[100]}}>
            {buyerName || ''}’s{' '}
          </H5Text>
          order?
        </H5Text>
        <PTextInput
          value={bagCount ? `${bagCount}` : ''}
          placeholder="0"
          textAlignVertical={'top'}
          clearInput={true}
          debounce={false}
          returnKeyType="done"
          style={[BottomSheetStyle.inputBox, {color: theme?.text?.blue?.[300]}]}
          onChangeText={handleChange}
          containerStyle={[
            BottomSheetStyle.inputContainer,
            {
              borderColor: getBorderColor,
            },
          ]}
          keyboardType="number-pad"
        />
        <Box>
          <BottomButton
            title={`Confirm ${bagCount} bags/boxes used`}
            disabled={
              loading ||
              usedBag === bagCount ||
              !(checkPriceDecimals(String(bagCount)) && bagCount <= 200)
            }
            onPress={onPressHandler}
            isLoading={loading}
            loadingIndicator={<SpinnerComponent />}
            style={[
              BottomSheetStyle.bottomButton,
              {
                backgroundColor: theme?.background.blue[100],
              },
            ]}
          />
        </Box>
      </Box>
    </BottomSheet>
  );
};

export default React.memo(UsedBagBottomSheet);
