import {Box, HBox} from '../../core/view';
import React, {useCallback, useEffect, useState} from 'react';
import {TabData, TabProps} from './type';

import {StyleSheet} from 'react-native';
import {Touchable} from '../../core/touchable';

const Tab: React.FC<TabProps> = props => {
  const colorThemeType = props.colorThemeType || 'default';
  const [activeKey, setActiveKey] = useState(props.tabData[0].key);
  const isActiveSeactionPresent = activeKey in (props.sections || {});

  const renderItem = useCallback(
    (item: TabData<any>) => {
      return (
        <Touchable
          style={styles.touchable}
          key={item.key}
          onPress={() => {
            if (props.activeKey) return;
            if (props.getActiveKey) props.getActiveKey(item);
            setActiveKey(item.key);
          }}>
          {props.tabNode(item, activeKey)}
        </Touchable>
      );
    },
    [activeKey, props],
  );

  useEffect(() => {
    if (props.activeKey) setActiveKey(props.activeKey);
  }, [props.activeKey]);

  return (
    <Box {...props} colorThemeType={colorThemeType} style={styles.parentBox}>
      <HBox style={styles.horizontalBox}>
        {props?.tabData?.map(item => {
          return renderItem(item);
        })}
      </HBox>

      {/* <CustomFlatList
        scrollEnabled={props?.scrollEnabled}
        horizontal={true}
        data={props.tabData}
        renderItem={renderItem}
        contentContainerStyle={{height: '100%', width: '100%'}}
        style={{backgroundColor: 'red', width: '50%', height: 50}}
        // decelerationRate="fast"
        showsHorizontalScrollIndicator={false}
      /> */}
      {isActiveSeactionPresent && props.sections[activeKey]}
    </Box>
  );
};

export default React.memo(Tab);

const styles = StyleSheet.create({
  parentBox: {
    flex: 1,
    width: '100%',
  },
  horizontalBox: {
    height: 40,
    paddingHorizontal: 10,
  },
  touchable: {
    width: '100%',
    flex: 1,
  },
});
