import {
  CheckForDeleteUserType,
  ModalType,
} from '../../../profile/types/profile';
import React, {useCallback, useEffect, useState} from 'react';
import {
  useLogoutAccount,
  useUserManagement,
} from '../../../profile/hooks/profile';

import {Analytics} from '../../../config/analytics/analytics';
import AppModal from '../../../common/components/CustomModal/AppModal';
import {EventType} from '../../../config/analytics/type';
import If from '../../../components/conditional/If';
import {useAppDispatch} from '../../../config/redux/hooks';

interface ComponentModalProps {
  modalType?: ModalType;
  closeModal: () => void;
}

function ComponentModal(props: ComponentModalProps) {
  const {modalType, closeModal} = props;
  const dispatch = useAppDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalHeader, setShowDeleteModalHeader] = useState('');
  const [deleteModalBody, setShowDeleteModalBody] = useState('');
  const [deleteModalButtonText, setShowDeleteModalButtonText] = useState('');
  const [isDeletionAllowed, setIsDeletionAllowed] = useState(true);
  const [logoutModal, setLogoutModal] = useState(false);
  const {loading, getLogoutMutation} = useLogoutAccount(dispatch);
  const {
    checkForAccountDeletion,
    checkingStatus,
    deleteUserAccount,
    deletingAccount,
  } = useUserManagement(dispatch);

  const closeDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
    setShowDeleteModalBody('');
    setShowDeleteModalHeader('');
    setShowDeleteModalButtonText('');
    setIsDeletionAllowed(true);
    closeModal();
  }, [closeModal]);

  const deleteAnalyser = useCallback((data: CheckForDeleteUserType) => {
    if (data.isDeletable) {
      setShowDeleteModalHeader(data.subtext || 'Are you sure?');
      setShowDeleteModalBody(
        data.header ||
          'Deleting this account is permanent, there is no going back.',
      );
      setShowDeleteModalButtonText('Yes delete');
      setIsDeletionAllowed(true);
    } else {
      setShowDeleteModalBody(data.subtext);
      setShowDeleteModalHeader(data.header);
      setShowDeleteModalButtonText('Go back');
      setIsDeletionAllowed(false);
    }
  }, []);

  const deleteAccount = useCallback(async () => {
    Analytics.eventWithProps('delete account', {
      isDeletionAllowed: isDeletionAllowed,
      type: EventType.cta,
    });
    if (isDeletionAllowed) await deleteUserAccount(closeDeleteModal);
    else closeDeleteModal();
  }, [closeDeleteModal, deleteUserAccount, isDeletionAllowed]);

  const deleteUserAccountHandler = useCallback(async () => {
    setShowDeleteModal(true);
    Analytics.eventWithProps('Show Delete Account Modal', {
      type: EventType.cta,
    });
    await checkForAccountDeletion((data: CheckForDeleteUserType) =>
      deleteAnalyser(data),
    );
  }, [checkForAccountDeletion, deleteAnalyser]);

  const logoutHandler = useCallback(async () => {
    Analytics.eventWithProps('show logout user modal', {
      type: EventType.cta,
    });
    try {
      await getLogoutMutation();
    } catch (error: any) {}
  }, [getLogoutMutation]);

  const openLogOutModal = useCallback(() => {
    setLogoutModal(true);
  }, []);

  const closeLogOutModal = useCallback(() => {
    setLogoutModal(false);
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    switch (modalType) {
      case ModalType.DeleteAccountModal:
        deleteUserAccountHandler();
        break;
      case ModalType.LogoutAccountModal:
        openLogOutModal();
        break;
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType]);

  return (
    <React.Fragment>
      <If condition={showDeleteModal}>
        <AppModal
          isOpen={showDeleteModal}
          onClose={closeDeleteModal}
          title={deleteModalHeader}
          subTitle={deleteModalBody}
          topCTAHandelr={deleteAccount}
          loading={deletingAccount || checkingStatus}
          ctaTopTitle={deleteModalButtonText}
          ctaBottomTitle={'Cancel'}
        />
      </If>
      <AppModal
        isOpen={logoutModal}
        onClose={closeLogOutModal}
        title={'Logout?'}
        subTitle="Are you sure you want to logout your account?"
        topCTAHandelr={logoutHandler}
        loading={loading}
        ctaTopTitle={'Yes'}
        ctaBottomTitle={'Cancel'}
      />
    </React.Fragment>
  );
}

export default React.memo(ComponentModal);
