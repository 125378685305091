import * as React from 'react';

import {Circle} from 'react-native-svg';
import {Icon} from '.';
import {IconProps} from './type';

const EmptyEllipse: React.FC<IconProps> = props => {
  return (
    <Icon {...props} fill="none">
      <Circle cx={7} cy={7} r={6.5} stroke={props?.fill} />
    </Icon>
  );
};

export default EmptyEllipse;
