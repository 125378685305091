import {StyleSheet} from 'react-native';
import {getWidth} from '../../../utils/common';

export const styles = StyleSheet.create({
  mainContainer: {
    justifyContent: 'center',
  },
  textContainer: {
    marginLeft: 10,
  },
  storeLogo: {
    borderRadius: 50,
    height: 50,
    width: getWidth(50),
    overflow: 'hidden',
  },
  storeLogoBox: {
    borderWidth: 3,
    borderRadius: 50,
  },
  locationContainer: {
    borderWidth: 1,
    borderRadius: 50,
    paddingHorizontal: 25,
    paddingVertical: 10,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    alignContent: 'flex-end',
  },
  storeText: {
    textAlign: 'left',
    width: getWidth(160),
  },
});
