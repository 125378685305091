import * as React from 'react';

import Icon from './Icon';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const CrossEyeIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path d="m11.885 14.988 3.104-3.098L15 12c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972C19.748 20 24 11.551 24 11.551s-1.415-2.478-4.067-4.595zm1.431-3.536L2.745 22l-1.382-1.422 3.455-3.447C1.796 14.681 0 11.551 0 11.551S4.446 4 12.015 4c1.825 0 3.456.426 4.886 1.075L19.982 2l1.382 1.42zM7.613 14.342l1.519-1.515A2.938 2.938 0 0 1 9 12c0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515A4.906 4.906 0 0 0 12 7c-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z" />
    </Icon>
  );
};

export default React.memo(CrossEyeIcon);
