import React, {useCallback, useEffect, useState} from 'react';

import {Analytics} from '../config/analytics/analytics.web';
import {Auth} from '../onBoarding/navigation';
import BottomTab from './Tabs';
import {Common} from '../common/navigation';
import DownTime from '@buncha/maintenance/screens/DownTime';
import {HiringNavigator} from '@buncha/hiring/navigation';
import {Home} from '@buncha/home/navigation';
import If from '../components/conditional/If';
import {Linking} from 'react-native';
import Lockout from '../onBoarding/screens/Lockout';
import {MainScreens} from './types';
import {NativeBaseProvider} from 'native-base';
import {ShopperNavigator} from '@buncha/home/navigation/MainNavigator';
import {StorageKeys} from '@buncha/config/storage/type';
import {Toast} from '../components/composites';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {getScreenToNavigate} from './utils/navigation';
import {gqlService} from '../config/graphQL';
import {storage} from '@buncha/config/storage';
import {useAppSelector} from '../config/redux/hooks';
import {useFetchDownTimeInfo} from '@buncha/maintenance/hooks/downtime';
import {useIntercom} from 'react-use-intercom';

const Stack = createNativeStackNavigator();

const MainNavigator: React.FC = () => {
  const onboardingState = useAppSelector(gstate => gstate.onboarding);
  const authData = onboardingState.userAuthData;
  const whenIWorkToken = onboardingState.userAuthData?.wToken;
  const notificationToastInfo = onboardingState.notificationToastInfo;

  const intercom = useIntercom();

  useEffect(() => {
    Analytics.setupForServerConfiguration(intercom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAppUser = useCallback(async () => {
    const urlData = (await Linking.getInitialURL()) || '';
    const user: string[] = urlData?.split('userId=');
    const hasUser = user.length > 1;

    const userId = hasUser
      ? user[1].split('?tn')[0]
      : await storage.getItem(StorageKeys.user);
    return userId;
  }, []);

  const manageLoginToken = useCallback(async () => {
    const urlData = (await Linking.getInitialURL()) || '';
    const token: string[] = urlData.split('tn=');
    const userId = await getAppUser();
    gqlService?.setUser(String(userId));
    let loginToken = (await storage.getItem(StorageKeys.loginToken)) || '';
    const hasTokenParam = token?.length > 1 && token[1].length;
    if (hasTokenParam) loginToken = token[1];
    gqlService?.setLoginCredential(loginToken);
  }, [getAppUser]);

  useEffect(() => {
    manageLoginToken();
    gqlService?.setLoginCredential(authData?.loginToken);
    if (whenIWorkToken?.length)
      gqlService?.setWhenIWorkLoginCredential(whenIWorkToken);
  }, [authData?.loginToken, manageLoginToken, whenIWorkToken]);

  return (
    <NativeBaseProvider>
      <If condition={notificationToastInfo?.show}>
        <Toast {...notificationToastInfo?.details} />
      </If>
      <StackNavigator />
    </NativeBaseProvider>
  );
};

const StackNavigator: React.FC<any> = () => {
  const onboardingState = useAppSelector(gState => gState.onboarding);
  const {fetchDownTimeInfo, downTime} = useFetchDownTimeInfo();
  const user = onboardingState?.currentUser;
  let loginToken = onboardingState?.userAuthData?.loginToken;
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(loginToken?.length));

  useEffect(() => {
    fetchDownTimeInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    gqlService?.getLoginToken();
    gqlService?.setWhenIWorkToken();
  }, []);

  useEffect(() => {
    if (loginToken?.length) setIsLoggedIn(true);
  }, [loginToken?.length]);

  if (downTime) return <StackNavigatorForMaintenance />;
  else if (isLoggedIn && user) return <StackNavigatorAfterLogin />;
  return <StackNavigatorBeforeLogin />;
};

const StackNavigatorForMaintenance: React.FC = () => {
  return (
    <Stack.Navigator key={'Maintenance'} screenOptions={{headerShown: false}}>
      <Stack.Screen name={MainScreens.DownTime} component={DownTime} />
    </Stack.Navigator>
  );
};

const StackNavigatorBeforeLogin: React.FC = () => {
  return (
    <Stack.Navigator key={'BeforeLogin'} screenOptions={{headerShown: false}}>
      <Stack.Screen
        name={MainScreens.HomeBeforeLogin}
        component={Auth.OnboardingNavigator}
      />
      <Stack.Screen name={MainScreens.Common} component={Common} />
      <Stack.Screen name={MainScreens.DownTime} component={DownTime} />
    </Stack.Navigator>
  );
};

const StackNavigatorAfterLogin: React.FC = () => {
  return <FulfillmentStackNavigator />;
};

const FulfillmentStackNavigator: React.FC = () => {
  const onboardingState = useAppSelector(gState => gState.onboarding);
  const user = onboardingState?.currentUser;

  const route = getScreenToNavigate(user);

  const [intialRoute] = useState(route.screen);

  return (
    <Stack.Navigator
      screenOptions={{headerShown: false, gestureEnabled: false}}
      key={'Shopper'}
      initialRouteName={intialRoute}>
      <Stack.Group>
        <Stack.Screen name={MainScreens.Hiring} component={HiringNavigator} />
        <Stack.Screen name={MainScreens.Tabs} component={BottomTab} />
        <Stack.Screen name={MainScreens.Home} component={Home.HomeNavigator} />
        <Stack.Screen name={MainScreens.Lockout} component={Lockout} />
      </Stack.Group>
      <Stack.Screen name={MainScreens.Common} component={Common} />

      {/* For Deeplink only */}
      <Stack.Screen name={MainScreens.Shopper} component={ShopperNavigator} />
      <Stack.Screen name={MainScreens.DownTime} component={DownTime} />
    </Stack.Navigator>
  );
};

export default MainNavigator;
