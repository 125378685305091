import {HomeState, NextShiftData, ShiftCountByStore, ShiftData} from '../types';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {FileUploadData} from '@buncha/config/fileUpload/type';

const initialState: HomeState = {
  counter: 0,
  shiftData: {active: undefined, pending: []},
  nextShiftData: {upcoming: []},
  shiftCountByStore: [],
  batchShiftId: '',
  batchShiftKey: '',
  codepushLatestVersion: '',
  fileUploadData: {},
};

const reducer = {
  setTodayShifts: function (
    state: HomeState,
    action: PayloadAction<{data: ShiftData; isOnlyAcive?: boolean}>,
  ) {
    const payload = action.payload;
    if (payload.isOnlyAcive) {
      state.shiftData.active = payload.data.active;
    } else {
      state.shiftData.pending = payload.data.pending;
      state.shiftData.active = payload.data.active;
    }
  },
  setNextShifts: function (
    state: HomeState,
    action: PayloadAction<NextShiftData>,
  ) {
    state.nextShiftData.upcoming = action.payload.upcoming;
  },
  setShiftCountByStore: function (
    state: HomeState,
    action: PayloadAction<ShiftCountByStore[]>,
  ) {
    state.shiftCountByStore = action.payload;
  },
  setBatchShiftId: function (
    state: HomeState,
    action: PayloadAction<{batchShiftId: string; batchShiftKey: string}>,
  ) {
    state.batchShiftId = action.payload.batchShiftId;
    state.batchShiftKey = action.payload.batchShiftKey;
  },
  setCodePushLatestVersion: function (
    state: HomeState,
    action: PayloadAction<string>,
  ) {
    state.codepushLatestVersion = action.payload;
  },
  setFileUplaodData: function (
    state: HomeState,
    action: PayloadAction<FileUploadData>,
  ) {
    state.fileUploadData = action.payload;
  },
};

function initSlice() {
  const slice = createSlice({
    name: 'HomeState',
    initialState,
    reducers: reducer,
  });
  return slice;
}

export default initSlice();
