import {
  AttendanceStatus,
  BagsLeft,
  GetAssignedShiftResponse,
  GetBagCountLeftResponse,
  GetBatchCountResponse,
  GetNextShiftResponse,
  SetUserAttendanceStatusResponse,
  ShiftByStore,
} from '../types';
import {
  GET_ACTIVE_ASSIGN_SHIFT,
  GET_ASSIGN_SHIFT,
  GET_BAG_COUNT_LEFT,
  GET_BATCH_COUNT,
  GET_NEXT_ASSIGN_SHIFT,
} from '../../config/graphQL/documentNode/queries';
import React, {useCallback, useState} from 'react';

import {ApiState} from '../../common/types/common';
import {Set_User_Attendance_Status} from '../../config/graphQL/documentNode/mutation';
import {ToastType} from '../../components/composites/notification/type';
import {gqlService} from '../../config/graphQL';
import {homeActions} from '../reducer';
import {homeActionsLocal} from './home';
import {isWeb} from '@buncha/utils/common';
import {onBoardingActions} from '../../onBoarding/reducer';
import {useAppDispatch} from '../../config/redux/hooks';
import {useToastMessage} from '../../components/composites/notification';

export function useGetAssignedShift(
  dispatch: React.Dispatch<any> | null,
): [() => void, boolean] {
  const [loading, setLoading] = useState(isWeb());
  const getAssignedShift = useCallback(async () => {
    try {
      setLoading(true);
      const response = await gqlService?.query<GetAssignedShiftResponse>({
        query: GET_ASSIGN_SHIFT,
        fetchPolicy: 'network-only',
      });
      if (response?.data?.getAssignShift && dispatch)
        dispatch(
          homeActions.setTodayShifts({
            data: response?.data.getAssignShift,
            isOnlyAcive: false,
          }),
        );
      setLoading(false);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('error ===>', error);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  return [getAssignedShift, loading];
}

export function useGetActiveAssignedShift(
  dispatch: React.Dispatch<any> | null,
): [() => void, boolean] {
  const [loading, setLoading] = useState(false);
  const getActiveAssignedShift = useCallback(async () => {
    try {
      setLoading(true);
      const response = await gqlService?.query<GetAssignedShiftResponse>({
        query: GET_ACTIVE_ASSIGN_SHIFT,
        fetchPolicy: 'network-only',
      });
      if (response?.data?.getAssignShift && dispatch)
        dispatch(
          homeActions.setTodayShifts({
            data: response?.data.getAssignShift,
            isOnlyAcive: true,
          }),
        );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  return [getActiveAssignedShift, loading];
}

export function useGetNextAssignedShift(
  dispatch: React.Dispatch<any> | null,
): [() => void, boolean, boolean] {
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const getNextAssignedShift = useCallback(async () => {
    try {
      const response = await gqlService?.query<GetNextShiftResponse>({
        query: GET_NEXT_ASSIGN_SHIFT,
        fetchPolicy: 'network-only',
      });
      if (response?.data?.getAssignShift && dispatch)
        dispatch(homeActions.setNextShifts(response?.data.getAssignShift));
      setLoading(false);
    } catch (error: any) {
      if (String(error?.message).includes('User login required'))
        setLoginError(true);
      showErrorToast(error?.message);
    } finally {
      setLoading(false);
    }
  }, [dispatch, showErrorToast]);

  return [getNextAssignedShift, loading, loginError];
}

export function useSetUserAttendanceStatus(
  dispatch: React.Dispatch<any> | null,
): [(status: AttendanceStatus) => void, boolean] {
  const [loading, setLoading] = useState(false);
  const globalDispatch = useAppDispatch();
  const setUserAttendanceStatus = useCallback(
    async (status: AttendanceStatus) => {
      setLoading(true);
      try {
        const response =
          await gqlService?.mutation<SetUserAttendanceStatusResponse>({
            mutation: Set_User_Attendance_Status,
            variables: {
              status: status,
            },
            fetchPolicy: 'network-only',
          });
        if (response?.data?.setUserAttendanceStatus && dispatch) {
          dispatch(
            homeActionsLocal.setUserAttendance(
              response?.data.setUserAttendanceStatus,
            ),
          );
          globalDispatch(onBoardingActions.setUserAttendance(true));
        }
      } catch (error: any) {
      } finally {
        setLoading(false);
      }
    },
    [dispatch, globalDispatch],
  );

  return [setUserAttendanceStatus, loading];
}

export function useGetBatchCount(
  dispatch: React.Dispatch<any> | null,
): [(startDate: string, shiftByStore: ShiftByStore[]) => void, boolean] {
  const [loading, setLoading] = useState(false);
  const getBatchCount = useCallback(
    async (startDate: string, shiftByStore: ShiftByStore[]) => {
      try {
        setLoading(true);
        const dataConversion = shiftByStore.map(item => {
          return {
            storeMasterId: item.storeMasterId,
            // activeShiftIndex: item.activeShiftIndex,
            shiftIds: item.shiftIds,
          };
        });
        const response = await gqlService?.query<GetBatchCountResponse>({
          query: GET_BATCH_COUNT,
          variables: {startDate: startDate, shiftByStore: dataConversion},
          fetchPolicy: 'network-only',
        });
        if (response?.data?.getBatchCount && dispatch)
          dispatch(
            homeActions.setShiftCountByStore(response?.data.getBatchCount),
          );
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [dispatch],
  );

  return [getBatchCount, loading];
}

export function useGetBagCountLeft(): [() => void, ApiState<BagsLeft>] {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [state, setState] = useState<ApiState<BagsLeft>>({
    loading: false,
    error: undefined,
    data: undefined,
  });

  const func = useCallback(async () => {
    setState({...state, loading: true});
    try {
      const response = await gqlService?.query<GetBagCountLeftResponse>({
        query: GET_BAG_COUNT_LEFT,
        fetchPolicy: 'network-only',
      });
      if (response?.data?.getBagCountLeft)
        setState({
          ...state,
          data: response?.data?.getBagCountLeft,
          loading: false,
        });
    } catch (error: any) {
      setState({...state, loading: false});
      showErrorToast(error?.message);
    }
  }, [showErrorToast, state]);

  return [func, state];
}
