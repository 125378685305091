import {
  Animated,
  Dimensions,
  GestureResponderEvent,
  Image,
  TouchableWithoutFeedback,
} from 'react-native';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {AttachmentsPageParams} from '../types';
import {Box} from '@buncha/components/core/view';
import CrossRoundedIcon from '@buncha/components/core/icon/CrossRoundedIcon';
import Header from '@buncha/components/composites/Header/Header';
import If from '@buncha/components/conditional/If';
import Pills from './Pills';
import PlayIcon from '@buncha/components/core/icon/PlayIcon';
import {SpinnerComponent} from '@buncha/components/core/loader';
import {Touchable} from '@buncha/components/core/touchable';
import VideoPlayer from '../components/videoPlayer';
import ZoomableImage from '../components/ZoomableImage';
import {isImage} from '../util';
import {navigation} from '@buncha/config/navigation';
import {storage} from '@buncha/config/storage';
import {styles} from './style';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';

const AttachmentsPage = () => {
  const theme = useColorThemeType();

  const route = useRoute();
  const routeParams: any = {
    ...route,
  };

  const attachmentParams: AttachmentsPageParams = routeParams?.params;

  const mediaUrls = useMemo(
    () => attachmentParams?.mediaUrls ?? [],
    [attachmentParams?.mediaUrls],
  );
  const index = attachmentParams?.index ?? 0;
  const memoId = attachmentParams?.memoId;

  const [currentIndex, setCurrentIndex] = useState(index);
  const [loading, setLoading] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const videoCounter = useRef(new Animated.Value(0)).current;
  const [paused, setPaused] = useState(false);

  const mediaLength = mediaUrls?.length;

  const currentUrl = mediaUrls?.[currentIndex];

  const handleGoBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const onTouchStart = useCallback((event: GestureResponderEvent) => {
    setTouchStart(event.nativeEvent.locationY);
  }, []);

  const onTouchEnd = useCallback((event: GestureResponderEvent) => {
    setTouchEnd(event.nativeEvent.locationY);
  }, []);

  const goNext = useCallback(() => {
    if (currentIndex < mediaLength - 1) setCurrentIndex(state => state + 1);
    else handleGoBack();
  }, [currentIndex, handleGoBack, mediaLength]);
  const goPrev = useCallback(() => {
    if (currentIndex > 0) setCurrentIndex(state => state - 1);
  }, [currentIndex]);

  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      if (loading) return;
      const x = event.nativeEvent.locationX;
      if (x < Dimensions.get('screen').width / 3) goPrev();
      else if (x > (Dimensions.get('screen').width / 3) * 2) goNext();
      else setPaused(state => !state);
    },
    [goNext, goPrev, loading],
  );

  const handleVideoLoad = useCallback(
    (value: number) => {
      Animated.timing(videoCounter, {
        toValue: value,
        duration: 1000,
        useNativeDriver: false,
      }).start();
    },
    [videoCounter],
  );

  const unPause = useCallback(() => setPaused(false), []);

  useEffect(() => {
    if (touchEnd - touchStart > 100) handleGoBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touchEnd]);

  useEffect(() => {
    const updateReadData = async () => {
      const memoData = JSON.parse(
        (await storage.getItem(`memoData${memoId}`)) ?? '{}',
      );
      if (isImage(currentUrl)) memoData[currentUrl] = true;
      storage.setItem(`memoData${memoId}`, JSON.stringify(memoData));
    };
    updateReadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  useEffect(() => {
    if (currentIndex === mediaUrls?.length) handleGoBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <Header
      // hideTopSafeArea
      headerColor="black"
      hideBottomSafeArea
      style={[styles.attachmentPage]}>
      <If condition={paused}>
        <Touchable style={styles.playIcon} onPress={unPause}>
          <PlayIcon />
        </Touchable>
      </If>
      <Touchable style={styles.crossIcon} onPress={handleGoBack}>
        <CrossRoundedIcon fill={theme?.text.white[100]} />
      </Touchable>
      <TouchableWithoutFeedback onPress={handlePress} style={styles.flex}>
        <Box
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          style={styles.flex}>
          <Pills
            paused={paused}
            currentIndex={currentIndex}
            goNext={goNext}
            videoCounter={videoCounter}
          />
          <If condition={loading}>
            <SpinnerComponent
              containerStyle={styles.spinnerContainer}
              style={styles.spinner}
            />
          </If>
          <If condition={currentUrl}>
            <If condition={isImage(currentUrl)}>
              <ZoomableImage>
                <Image
                  onLoadStart={() => setLoading(true)}
                  onLoadEnd={() => setLoading(false)}
                  source={{uri: currentUrl}}
                  resizeMode="contain"
                  style={styles.image}
                />
              </ZoomableImage>
            </If>
            <If condition={!isImage(currentUrl)}>
              <VideoPlayer
                setLoading={setLoading}
                paused={paused}
                url={currentUrl}
                goNext={goNext}
                handleVideoLoad={handleVideoLoad}
              />
            </If>
          </If>
        </Box>
      </TouchableWithoutFeedback>
    </Header>
  );
};

export default React.memo(AttachmentsPage);
