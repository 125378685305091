import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  defaultHeaderLeft: {
    position: 'absolute',
    zIndex: 100,
    top: 15,
    left: 15,
  },
  defaultHeaderRight: {
    position: 'absolute',
    zIndex: 100,
    top: 20,
    right: 20,
    flexDirection: 'row',
  },
  defaultBox: {
    flex: 1,
  },
  jsfw: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
