// eslint-disable-next-line no-shadow
export enum Bucket {
  upcImages = 'upcimages',
  orderImage = 'gobuncha-users-order-images',
}

// eslint-disable-next-line no-shadow
export enum UploadingStatus {
  pending = 'pending',
  inprogress = 'inprogress',
  failed = 'failed',
  none = 'none',
  success = 'success',
}

export interface FileUploadData {
  [runId: number]: UploadingImage;
}

export interface UploadingImage {
  [fileName: string]: UploadFileType;
}

export interface UploadFileType {
  fileLocalUri: string;
  fileName: string;
  uploadingStatus: UploadingStatus;
  bucketType: Bucket;
  progress?: number;
  iamgeNameForLocal?: string; // image name for user
  runId: number;
  storeIndex?: string;
}
