import {Box, Divider} from 'native-base';
import {
  Dimensions,
  PermissionsAndroid,
  RefreshControl,
  RefreshControlProps,
  StyleSheet,
} from 'react-native';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useAboutShiftReducer, useGetBatches} from '../hooks/AboutShift';
import {useAppDispatch, useAppSelector} from '../../config/redux/hooks';
import {useIsFocused, useRoute} from '@react-navigation/native';

import {AboutShiftContext} from '../context/AboutShiftContext';
import AboutShiftHeader from '../component/AboutShiftHeader';
import {Analytics} from '../../config/analytics/analytics';
import {BackHeaderLeft} from '../../common/components/BackHeaderLeft';
import BatchList from '../component/BatchList';
import CustomActivityIndicator from '../../components/composites/CustomActivityIndicator/CustomActivityIndicator';
import {CustomFlatList} from '../../components/core/list';
import CustomScrollView from '../../components/composites/CustomScrollView/CustomScrollView';
import {EventType} from '../../config/analytics/type';
import Geolocation from 'react-native-geolocation-service';
import Header from '../../components/composites/Header/Header';
import {HomeScreens} from '../../home/navigation/types';
import If from '../../components/conditional/If';
import {MainScreens} from '../../navigation/types';
import ScreenHeader from '../../../src/common/components/screenHeader';
import {getOs} from '../../utils/appInfo';
import {homeActions} from '../../home/reducer';
import listener from '../../config/listener';
import {navigation} from '../../config/navigation';
import {useColorThemeType} from '../../appStyles/hooks/theme';

function AboutShift() {
  const defaultTheme = useColorThemeType();
  const [online, setOnline] = useState(true);
  const [state, dispatch] = useAboutShiftReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);
  const [getBatches, loadingBatches] = useGetBatches(dispatch);
  const isFocus = useIsFocused();
  const appDispatch = useAppDispatch();
  const [coordinates, setCoordinates] = useState<
    {lat: number; long: number} | undefined
  >(undefined);
  const route = useRoute();
  const params: {shiftId?: string; shiftKey?: string} | undefined =
    route?.params;

  const activeShift = state.batches.startedShift;
  const inActiveShift = state.batches.notStartedShift;
  const todayShift = useAppSelector(gState => gState.home.shiftData.active);
  const noShiftAvailable =
    !state.batches?.notStartedShift?.length &&
    !state.batches?.startedShift?.length &&
    !loadingBatches;

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  useEffect(() => {
    Analytics.eventWithProps('Shift Details Screen', {
      type: EventType.page,
      status: todayShift?.key?.includes('Today') ? 'Today Batch' : 'Past Batch',
      inActiveBatchsCount: inActiveShift?.length || 0,
      activeBatchCount: activeShift?.length || 0,
    });
  }, [activeShift?.length, inActiveShift?.length, todayShift?.key]);

  const getCurrentLocation = useCallback(() => {
    Geolocation.getCurrentPosition(
      position => {
        setCoordinates({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
      },
      err => {
        return err;
      },
      {enableHighAccuracy: true, timeout: 15000, maximumAge: 10000},
    );
  }, []);

  const getWebCurrentLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setCoordinates({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
      },
      error => {
        return error;
      },
      {enableHighAccuracy: true, timeout: 5000},
    );
  }, []);

  const getLocationWithPermission = useCallback(async () => {
    try {
      const os = getOs();
      if (os === 'web') getWebCurrentLocation();
      if (os === 'android') {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
          {
            title: 'Location Permission',
            message: 'Allow location access.',
            buttonPositive: 'Ok',
          },
        );
        if (granted === PermissionsAndroid.RESULTS.GRANTED)
          getCurrentLocation();
      }
      if (os === 'ios') {
        const resAlways = await Geolocation.requestAuthorization('always');
        const resWhenInUse = await Geolocation.requestAuthorization(
          'whenInUse',
        );
        if (resAlways === 'granted' || resWhenInUse === 'granted')
          getCurrentLocation();
      }
    } catch (err: any) {
      return err;
    }
  }, [getCurrentLocation, getWebCurrentLocation]);

  const onRefreshRun = useCallback(() => {
    if (params?.shiftId) getBatches(params?.shiftId);
    listener.emit('getItemStatusCount');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.shiftId]);

  useEffect(() => {
    if (isFocus) getLocationWithPermission();
  }, [getLocationWithPermission, isFocus]);

  useEffect(() => {
    if (params?.shiftId && params?.shiftKey)
      appDispatch(
        homeActions.setBatchShiftId({
          batchShiftId: params?.shiftId,
          batchShiftKey: params?.shiftKey,
        }),
      );
  }, [appDispatch, params?.shiftId, params?.shiftKey]);

  useEffect(() => {
    if (params?.shiftId && isFocus) getBatches(params?.shiftId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.shiftId, isFocus]);

  useEffect(() => {
    if (noShiftAvailable)
      navigation.navigate(MainScreens.Tabs, {
        screen: HomeScreens.YourSchedule,
      });
  }, [noShiftAvailable]);

  return (
    <AboutShiftContext.Provider value={provider}>
      <Header
        loading={loadingBatches}
        showDefaultLeftHeader
        headerLeftComponent={<BackHeaderLeft onClick={goBack} />}>
        <ScreenHeader title="About this Shift" />
        <CustomFlatList
          refreshControl={
            (
              <RefreshControl
                refreshing={false}
                onRefresh={onRefreshRun}
                tintColor={defaultTheme?.background.gray[100]}
                style={styles.refreshControl}
              />
            ) as React.ReactElement<RefreshControlProps>
          }
          ListHeaderComponent={
            <Box>
              <AboutShiftHeader online={online} />
              <CustomScrollView>
                <Box style={styles.mainContainer}>
                  <If condition={loadingBatches}>
                    <Box style={[styles.activityIndicator]}>
                      <CustomActivityIndicator />
                    </Box>
                  </If>
                  <If condition={!loadingBatches}>
                    {/* <AboutShiftHeader online={online} /> */}
                    <If condition={activeShift.length}>
                      <BatchList
                        online={online}
                        onValueChange={() => setOnline(!online)}
                        activeShift={activeShift}
                        coordinates={coordinates}
                        inspectVan={state.batches?.inspectVan}
                      />
                    </If>
                    <If condition={activeShift.length}>
                      <Divider style={[styles.divider]} />
                    </If>
                    <If condition={inActiveShift.length}>
                      <BatchList
                        online={online}
                        onValueChange={() => setOnline(!online)}
                        inActiveShift={inActiveShift}
                        batchNo={activeShift.length}
                      />
                    </If>
                  </If>
                </Box>
              </CustomScrollView>
            </Box>
          }
          data={undefined}
          renderItem={undefined}
        />
      </Header>
    </AboutShiftContext.Provider>
  );
}

export default React.memo(AboutShift);

const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 20,
    marginBottom: 20,
  },
  scrollView: {height: '80%'},
  divider: {
    marginVertical: 15,
  },
  refreshControl: {zIndex: 100},
  webHeader: {
    paddingBottom: 10,
  },
  activityIndicator: {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'center',
    top: Dimensions.get('screen').height * 0.35,
  },
});
