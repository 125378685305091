import Smartlook from 'smartlook-react-native-wrapper';

export interface UserInfo {
  name?: string;
  phoneNumber?: string;
  email?: string;
}

export interface SmartlookWrapperType {
  setupAndStartRecording: () => void;
  resetSession: (shouldStartNewSession: boolean) => void;
  setUserIdentity: (id: string, userInfo?: UserInfo | undefined) => void;
  trackCustomEvent: (eventName: string, properties: object) => void;
  trackNavigationEvent: (screen: string, position: Smartlook.ViewState) => void;
}

// eslint-disable-next-line no-shadow
export enum EventType {
  cta = 'CTA',
  page = 'PAGE',
  api = 'API',
  input = 'INPUT',
  modal = 'MODAL',
}
