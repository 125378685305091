import React, {useCallback, useMemo, useState} from 'react';
import {
  VanLoadAtAction,
  useVanLoadAtContext,
} from '@buncha/driver/hooks/VanLoadAt';

import {Box} from '@buncha/components/core/view';
import {CheckIcon} from 'native-base';
import {CustomText} from '@buncha/components/core/text';
import {DeliveryLocationStatus} from '@buncha/batchDetails/types';
import If from '@buncha/components/conditional/If';
import {StoreCheckBoxStyle} from './styles';
import StoreLogo from '@buncha/common/components/StoreLogo';
import {Touchable} from '@buncha/components/core/touchable';
import font from '@buncha/appStyles/style/font';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {useSetDeliveryLocationStatus} from '@buncha/driver/hooks/deliveryDetails';

interface Props {
  storeLogoUrl: string;
  usedBag: number;
  deliveryLocationId: number;
  runId: number;
  orderStatus: DeliveryLocationStatus;
}

const StoreCheckBox = (props: Props) => {
  const {storeLogoUrl, usedBag, deliveryLocationId, runId, orderStatus} = props;
  const theme = useColorThemeType();
  const {dispatch} = useVanLoadAtContext();
  const {setOrderAsDelivered} = useSetDeliveryLocationStatus();

  const [isChecked, setIsChecked] = useState(
    orderStatus === DeliveryLocationStatus.driverAssigned,
  );

  const disabledCheckBox = useMemo(() => {
    const list = [
      DeliveryLocationStatus.awaitingHandover,
      DeliveryLocationStatus.driverAssigned,
    ];
    if (list.includes(orderStatus)) return false;
    return true;
  }, [orderStatus]);

  const opacity = disabledCheckBox ? 0.5 : 1;

  const onChangeHandler = useCallback(async () => {
    switch (orderStatus) {
      case DeliveryLocationStatus.awaitingHandover:
        const driverAssignedRes = await setOrderAsDelivered(
          deliveryLocationId,
          runId,
          DeliveryLocationStatus.driverAssigned,
        );
        if (driverAssignedRes) {
          setIsChecked(true);
          if (dispatch)
            dispatch(
              VanLoadAtAction.setUpdateOrderStatusLocal({
                deliveryLocationId: driverAssignedRes,
                updatedStatus: DeliveryLocationStatus.driverAssigned,
              }),
            );
        }
        break;
      case DeliveryLocationStatus.driverAssigned:
        const awaitRes = await setOrderAsDelivered(
          deliveryLocationId,
          runId,
          DeliveryLocationStatus.awaitingHandover,
        );
        if (awaitRes) {
          setIsChecked(false);
          if (dispatch)
            dispatch(
              VanLoadAtAction.setUpdateOrderStatusLocal({
                deliveryLocationId: awaitRes,
                updatedStatus: DeliveryLocationStatus.awaitingHandover,
              }),
            );
        }
        break;
      default:
        break;
    }
  }, [deliveryLocationId, dispatch, orderStatus, runId, setOrderAsDelivered]);

  const getColor = useMemo(() => {
    if (isChecked)
      return {
        backgroundColor: theme?.background.blue[300],
        borderColor: theme?.text.blue[300],
      };
    return {
      backgroundColor: theme?.background.white[100],
      borderColor: theme?.icon.gray[100],
    };
  }, [
    isChecked,
    theme?.background.blue,
    theme?.background.white,
    theme?.icon.gray,
    theme?.text.blue,
  ]);

  return (
    <Touchable
      onPress={async () => await onChangeHandler()}
      disabled={disabledCheckBox}
      style={[
        StoreCheckBoxStyle.mainContainer,
        {
          opacity: opacity,
        },
      ]}>
      <StoreLogo
        storeLogoUrl={storeLogoUrl}
        imageBoxStyle={StoreCheckBoxStyle.imageBoxList}
      />
      <Box
        style={[
          StoreCheckBoxStyle.container,
          {backgroundColor: theme?.background.yellow[100]},
        ]}>
        <CustomText
          style={{...font.FontSize.fontP10, color: theme?.text.blue[300]}}>
          {usedBag || '?'}
        </CustomText>
        <If condition={isChecked}>
          <Box
            style={[
              StoreCheckBoxStyle.checkBoxStyle,
              {
                borderColor: getColor.borderColor,
                backgroundColor: getColor.backgroundColor,
              },
            ]}>
            <CheckIcon
              height={10}
              width={10}
              color={theme?.icon?.white[100]}
              style={{alignSelf: 'center'}}
              viewBox="-10 4 44 30"
            />
          </Box>
        </If>
        <If condition={!isChecked}>
          <Box
            style={[
              StoreCheckBoxStyle.checkBoxStyle,
              {
                borderColor: getColor.borderColor,
                backgroundColor: getColor.backgroundColor,
              },
            ]}
          />
        </If>
      </Box>
    </Touchable>
  );
};

export default React.memo(StoreCheckBox);
