import {StyleSheet} from 'react-native';
import {isWeb} from '../../../utils/common';

export const styles = StyleSheet.create({
  bottomButton: {
    borderRadius: 30,
    width: '90%',
    alignSelf: 'center',
    marginBottom: isWeb() ? 30 : 5,
    justifyContent: 'center',
  },
  defaultContainer: {
    // position: 'absolute',
    // bottom: 0,
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: 'white',
  },
});
