/* eslint-disable no-shadow */
export interface MemoContextState {
  memos: Memo[];
}

export interface Memo {
  id: number;
  createdAt: Date;
  formatedCreatedAt: string;
  memoConfig: {
    content: string;
    property: string;
  }[];
  createdUser: {
    firstName: string;
    lastName: string;
    avatar: string;
  };
}

export interface MemoData {
  memo: Memo[];
  status: MemoStatus;
  totalLength: number;
}

export enum MemoStatus {
  read = 'read',
  unread = 'unread',
}

export interface GetMemosResponse {
  getMemos: MemoData[];
}

export enum MemoConfigProperty {
  imageSection = 'imageSection',
  videoSection = 'videoSection',
  title = 'title',
  body = 'body',
}

export interface AttachmentsPageParams {
  mediaUrls: string[];
  index?: number;
  memoId: number;
}

export interface FullMemo {
  sendTo: string;
  readStatus: MemoStatus;
  memo: {
    createdUser: {
      avatar: string;
      firstName: string;
      lastName: string;
    };
    memoConfig: {
      content: string;
      property: MemoConfigProperty;
      props: any;
    }[];
  };
}

export interface GetMemoResponse {
  getMemo: FullMemo;
}

export interface MarkMemoReadResponse {
  markMemoAsRead: boolean;
}
