import {
  GET_STORES,
  GET_UPC_ORDER,
} from '@buncha/config/graphQL/documentNode/queries';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {PendingUPCOrder, StoreData, UPCShiftsState} from '../types';
import React, {useContext, useReducer, useState} from 'react';
import {UPCShiftContext, initialState} from '../context/UPCShiftsCreation';

import {CREATE_UPC_SHIFT} from '@buncha/config/graphQL/documentNode/mutation';
import {ItemScannerGlobalActions} from '@buncha/itemDetails/reducer';
import {ToastType} from '@buncha/components/composites/notification/type';
import {gqlService} from '@buncha/config/graphQL';
import {useAppDispatch} from '@buncha/config/redux/hooks';
import {useToastMessage} from '@buncha/components/composites/notification';

const reducer = {
  setAvailableStores: function (
    state: UPCShiftsState,
    action: PayloadAction<StoreData[]>,
  ) {
    state.storeList = action.payload;
  },
  setPendingUPCOrders: function (
    state: UPCShiftsState,
    action: PayloadAction<PendingUPCOrder[]>,
  ) {
    state.pendingUPCOrders = action.payload;
  },
};

const slice = createSlice({
  initialState,
  name: 'UPCShiftContext',
  reducers: reducer,
});

export function useUPCShiftReducer() {
  const UPCShiftReducer = useReducer(slice.reducer, initialState);
  return UPCShiftReducer;
}

export function useUPCShiftContext() {
  return useContext(UPCShiftContext);
}

export const upcShiftActionsLocal = slice.actions;

export function useInitializeUPCShift(dispatch: React.Dispatch<any> | null) {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const getAvailableStores = async () => {
    try {
      const res = await gqlService?.query({
        query: GET_STORES,
        fetchPolicy: 'network-only',
      });
      if (res?.data && dispatch)
        dispatch(
          upcShiftActionsLocal.setAvailableStores(
            JSON.parse(res?.data?.getCatalogStores),
          ),
        );
    } catch (error: any) {
      showErrorToast(error.message);
    }
  };

  return {getAvailableStores};
}

export function useUPCShifts(dispatch: React.Dispatch<any> | null) {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loadingUPCOrders, setLoadingUpcOrders] = useState<boolean>(false);
  const [creatingShift, setCreatingShift] = useState<boolean>(false);
  const appDispatch = useAppDispatch();

  const getPendingUPCOrders = async () => {
    try {
      setLoadingUpcOrders(true);
      const res = await gqlService?.query({
        query: GET_UPC_ORDER,
        fetchPolicy: 'network-only',
      });
      if (res?.data && dispatch)
        dispatch(
          upcShiftActionsLocal.setPendingUPCOrders(res?.data?.getUpcOrders),
        );
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setLoadingUpcOrders(false);
    }
  };

  const createUPCShift = async (
    storeData: StoreData,
    itemCount: number,
    callback: any,
  ) => {
    try {
      setCreatingShift(true);
      if (storeData?.MinUpcCount > itemCount) {
        throw new Error(
          `Quantity for UPC order should be ${storeData?.MinUpcCount} or more`,
        );
      } else if (itemCount >= 120) {
        throw new Error('Quantity for UPC order should be 120 or less');
      } else {
        const res = await gqlService?.mutation({
          mutation: CREATE_UPC_SHIFT,
          fetchPolicy: 'network-only',
          variables: {
            storeName: storeData?.Store,
            storeLogo: storeData?.StoreLogo,
            esIndex: storeData?.Index,
            noOfOrderItems: itemCount,
          },
        });
        if (res?.data) {
          const upcData = res?.data?.createUpcOrder.upcOrder;
          appDispatch(
            ItemScannerGlobalActions.setSelectedCatalogStoreForUPCRun(upcData),
          );
          if (res?.data?.createUpcOrder?.created)
            callback(res?.data?.createUpcOrder?.upcOrder?.id);
          else throw new Error(res?.data?.createUpcOrder?.message);
        }
      }
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setCreatingShift(false);
    }
  };

  return {loadingUPCOrders, getPendingUPCOrders, createUPCShift, creatingShift};
}
