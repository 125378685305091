import {
  MapChoice,
  NotificationToastDetails,
  NotificationToastInfo,
  OnBoardingState,
  UserStub,
} from '../types';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {StorageKeys} from '../../config/storage/type';
import {storage} from '../../config/storage';

const initialState: OnBoardingState = {
  currentUser: undefined,
  userAuthData: undefined,
  notificationToastInfo: undefined,
  unreadMemoCount: 0,
  unreadMemoModal: false,
  downTime: false,
};

const initialNotificationToastInfo: NotificationToastDetails = {
  title: '',
  duration: 1000,
  colorThemeType: 'default',
  fontThemeType: 'normal',
};

const reducer = {
  setSmsCode: (
    state: OnBoardingState,
    action: PayloadAction<string | undefined>,
  ) => {
    state.userAuthData = {
      ...state.userAuthData,
      smsCode: action.payload,
    };
  },
  clearState: (state: OnBoardingState) => {
    state.userAuthData = undefined;
    state.currentUser = undefined;
    storage.delete(StorageKeys.loginToken);
    storage.delete(StorageKeys.wToken);
    storage.delete(StorageKeys.user);
  },
  setPhoneNumber: (
    state: OnBoardingState,
    action: PayloadAction<string | undefined>,
  ) => {
    state.userAuthData = {
      ...state.userAuthData,
      phoneNumber: action.payload,
    };
  },
  setOtpSentStatus: (
    state: OnBoardingState,
    action: PayloadAction<boolean | undefined>,
  ) => {
    state.userAuthData = {
      ...state.userAuthData,
      otpSent: action.payload,
    };
  },
  setLoginToken: (
    state: OnBoardingState,
    action: PayloadAction<string | undefined>,
  ) => {
    state.userAuthData = {
      ...state.userAuthData,
      loginToken: action.payload,
    };
  },
  setCreateLoginToken: (
    state: OnBoardingState,
    action: PayloadAction<string | undefined>,
  ) => {
    state.userAuthData = {
      ...state.userAuthData,
      createUserToken: action.payload,
    };
  },
  setCurrentUser: (
    state: OnBoardingState,
    action: PayloadAction<UserStub | undefined>,
  ) => {
    state.currentUser = action.payload;
  },
  setFirstName: (
    state: OnBoardingState,
    action: PayloadAction<string | undefined>,
  ) => {
    state.userAuthData = {
      ...state.userAuthData,
      firstName: action.payload,
    };
  },
  setLastName: (
    state: OnBoardingState,
    action: PayloadAction<string | undefined>,
  ) => {
    state.userAuthData = {
      ...state.userAuthData,
      lastName: action.payload,
    };
  },
  setEmail: (
    state: OnBoardingState,
    action: PayloadAction<string | undefined>,
  ) => {
    state.userAuthData = {
      ...state.userAuthData,
      email: action.payload,
    };
  },
  setUserAttendance: (
    state: OnBoardingState,
    action: PayloadAction<boolean>,
  ) => {
    if (state.currentUser) state.currentUser.isActive = action.payload;
  },
  setWhenIWorkData: (state: OnBoardingState, action: PayloadAction<any>) => {
    state.currentUser = {
      ...(state.currentUser as UserStub),
      WhenIWorkInfo: action.payload,
    };
  },
  setWhenIWorkToken: (
    state: OnBoardingState,
    action: PayloadAction<string>,
  ) => {
    state.userAuthData = {
      ...state.userAuthData,
      wToken: action.payload,
    };
  },
  setNotificationToastInfo: (
    state: OnBoardingState,
    action: PayloadAction<NotificationToastInfo>,
  ) => {
    state.notificationToastInfo = {
      show: action.payload.show,
      details: {...initialNotificationToastInfo, ...action.payload.details},
    };
  },
  setUserMapChoice: (
    state: OnBoardingState,
    action: PayloadAction<MapChoice>,
  ) => {
    state.currentUser = {
      ...(state.currentUser as UserStub),
      mapChoice: action.payload,
    };
  },
  clearLoginState: (state: OnBoardingState) => {
    if (state.userAuthData) {
      state.userAuthData.phoneNumber = undefined;
      state.userAuthData.smsCode = undefined;
      state.userAuthData.otpSent = false;
    }
  },
  setConstants: (state: OnBoardingState, action: PayloadAction<any>) => {
    state.constants = action.payload;
  },
  setUnreadMemoCount: (
    state: OnBoardingState,
    action: PayloadAction<number>,
  ) => {
    state.unreadMemoCount = action.payload;
  },
  setUnreadMemoModal: (
    state: OnBoardingState,
    action: PayloadAction<boolean>,
  ) => {
    state.unreadMemoModal = action.payload;
  },
  setDownTime: (state: OnBoardingState, action: PayloadAction<boolean>) => {
    state.downTime = action.payload;
  },
};

function initSlice() {
  const slice = createSlice({
    name: 'OnBoardingState',
    initialState,
    reducers: reducer,
  });
  return slice;
}

export default initSlice();
