import * as React from 'react';

import {ClipPath, Defs, G, Mask, Path, SvgProps} from 'react-native-svg';

import Icon from './Icon';
import {IconProps} from './type';
import {memo} from 'react';

const BrowseIcon: React.FC<IconProps> = (props: SvgProps) => (
  <Icon {...props}>
    <G clipPath="url(#a)">
      <Mask
        id="b"
        width={21}
        height={21}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse">
        <Path fill="#fff" d="M20.833.323H0v20h20.833v-20z" />
      </Mask>
      <G fillRule="evenodd" clipRule="evenodd" mask="url(#b)">
        <Path d="M17.826 10.166v-.873c0-1.071-.778-1.973-1.826-2.223V.927a.598.598 0 0 0-.61-.585H.976a.598.598 0 0 0-.61.585V16.95c0 .322.273.583.61.583h9.38v.48c0 1.262 1.07 2.289 2.388 2.289h5.335c1.317 0 2.388-1.027 2.388-2.288V10.75a.598.598 0 0 0-.609-.585h-2.03zm-1.217-.873v.873h-2.396v-.873c0-.62.537-1.123 1.197-1.123s1.2.503 1.2 1.123zM1.584 16.365V1.512H14.78V7.08c-1.028.264-1.787 1.155-1.787 2.213v.873h-2.03a.598.598 0 0 0-.61.585v5.614h-8.77zm17.664 1.649c0 .616-.525 1.118-1.17 1.118h-5.335c-.645 0-1.17-.503-1.17-1.119v-6.677h1.421v1.54c0 .322.274.584.61.584.336 0 .61-.262.61-.585v-1.54h2.395v1.54c0 .323.272.585.608.585.338 0 .61-.262.61-.585v-1.54h1.42v6.678z" />
        <Path d="m3.932 3.582-.046-.056a.627.627 0 0 0-.856-.09.57.57 0 0 0-.094.822l.487.582a.613.613 0 0 0 .476.218.59.59 0 0 0 .304-.078.587.587 0 0 0 .14-.107l1.173-1.209a.57.57 0 0 0-.03-.826.628.628 0 0 0-.861.03l-.693.714zm3.58.872h5.441c.338 0 .61-.26.61-.583a.596.596 0 0 0-.61-.585H7.512a.596.596 0 0 0-.608.585c0 .322.272.583.608.583zM3.932 7.13l-.046-.056a.627.627 0 0 0-.856-.089.57.57 0 0 0-.094.822l.487.583a.613.613 0 0 0 .476.218.605.605 0 0 0 .445-.186l1.172-1.21a.568.568 0 0 0-.03-.825.626.626 0 0 0-.861.03l-.693.713zm3.58.873h3.412a.597.597 0 0 0 .608-.585.597.597 0 0 0-.608-.585H7.512a.597.597 0 0 0-.608.585c0 .323.272.585.608.585zm-3.58 2.675-.046-.056a.627.627 0 0 0-.856-.09.57.57 0 0 0-.094.822l.487.582a.613.613 0 0 0 .476.218.59.59 0 0 0 .304-.078.586.586 0 0 0 .14-.107l1.173-1.209a.57.57 0 0 0-.03-.826.628.628 0 0 0-.861.03l-.693.714zm4.596-.296H7.512a.596.596 0 0 0-.608.585c0 .322.272.583.608.583h1.016c.336 0 .61-.26.61-.583a.597.597 0 0 0-.61-.585z" />
      </G>
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 .323h20.833v20H0z" />
      </ClipPath>
    </Defs>
  </Icon>
);

export default memo(BrowseIcon);
