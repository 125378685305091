import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const WarningInfo: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        fill={props?.fill || '#FF904F'}
        d="M10.884 1.032c-.346-.654-1.422-.654-1.768 0l-9 17A.999.999 0 0 0 1 19.5h18a.998.998 0 0 0 .883-1.467L10.884 1.032ZM11 16.5H9v-2h2v2Zm-2-4v-5h2l.001 5H9Z"
      />
    </Icon>
  );
};

export default WarningInfo;
