import * as React from 'react';

import Svg, {Path, SvgProps} from 'react-native-svg';

const LocationCrossIcon = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      {...props}
      d="M4.87 3.8a7.256 7.256 0 0 1 10.427 10.088l-.166.173c-.763.762-2.039 2.008-3.828 3.738a1.875 1.875 0 0 1-2.606 0l-3.209-3.124c-.236-.233-.443-.438-.619-.614a7.254 7.254 0 0 1 0-10.261Zm7.655 2.605-.07-.06a.625.625 0 0 0-.736-.007l-.078.067L10 8.047 8.36 6.405l-.07-.06a.625.625 0 0 0-.737-.007l-.078.067-.06.07a.625.625 0 0 0-.006.735l.067.078 1.64 1.642-1.641 1.642-.06.07a.625.625 0 0 0-.006.736l.067.077.07.06a.625.625 0 0 0 .735.007l.078-.067L10 9.815l1.642 1.64.07.06a.625.625 0 0 0 .735.007l.078-.067.06-.07a.625.625 0 0 0 .007-.735l-.067-.078-1.642-1.642 1.642-1.642.06-.07a.625.625 0 0 0 .007-.735l-.067-.078Z"
    />
  </Svg>
);
export default LocationCrossIcon;
