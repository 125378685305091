import React from 'react';
import Touchable from './Touchable';
import {TouchableProps} from './type';

import {StyleSheet, TouchableOpacity} from 'react-native';

const HTouchable: React.FC<TouchableProps<TouchableOpacity>> = React.forwardRef(
  (props, ref) => {
    const propsToSpread = {
      ...props,
      style: [styles.HTouchable, props.style],
    };
    return (
      <Touchable {...propsToSpread} ref={ref}>
        {props.children}
      </Touchable>
    );
  },
);

const styles = StyleSheet.create({
  HTouchable: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default React.memo(HTouchable);
