// eslint-disable-next-line no-shadow
export enum StorageKeys {
  newUser = 'newUser',
  hasAppPreviouslyOpen = 'hasAppPreviouslyOpen',
  deviceUUId = 'deviceUUId',
  loginToken = '@login_token',
  wToken = '@wToken',
  retryConfig = 'retryConfig',
  FileUploadData = 'FileUploadData',
  user = '@user',
  secondLogLocation = 'secondLogLocation',
}
