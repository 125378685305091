import {Box, HBox} from '@buncha/components/core/view';
import {
  BuyerData,
  FormDataUpdate,
  MandatoryFieldNames,
} from '@buncha/itemDetails/types/ItemScanner';
import {CustomText, H3Text, H5Text} from '@buncha/components/core/text';
import {
  ItemScannerAction,
  useItemScannerContext,
} from '@buncha/itemDetails/hooks/ItemScanner';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {checkPriceDecimals, formatCurrency} from '@buncha/utils/common';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import If from '@buncha/components/conditional/If';
import {PTextInput} from '@buncha/components/core/input/TextInput';
import font from '@buncha/appStyles/style/font';
import {styles} from './style';
import {useRoute} from '@react-navigation/native';

interface UpdatePriceSectionProps {
  item: BuyerData;
  isWeightField: boolean;
}

const UpdatePriceSection = (props: UpdatePriceSectionProps) => {
  const {state, dispatch} = useItemScannerContext();
  const {item} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');

  const routeParams: any = useRoute().params;
  const quantity = Number(routeParams?.quantity) || 1;

  const [updatePrice, setPriceUpdate] = useState('');

  const updateData = state.formData.find(
    data => data.orderItemId === item.orderItemId,
  );
  const pointerEvents = updateData?.dropped ? 'none' : undefined;

  const weightInfo = updateData?.weightInfo;

  const priceMandatory = useMemo(
    () =>
      Boolean(
        state?.buyerData?.[0]?.mandatoryFields?.find(
          field => field.fieldName === MandatoryFieldNames.pricePerQuantity,
        ),
      ),
    [state?.buyerData],
  );

  const priceWithWeight = useMemo(() => {
    let price = 0;
    if (updateData) {
      if (props.isWeightField) {
        weightInfo?.forEach(
          weightData => (price += Number(weightData?.weight ?? 0)),
        );
        return price * Number(updateData?.price ?? 0);
      }
      return (
        (updateData?.quantity ?? quantity ?? 0) * Number(updateData?.price ?? 0)
      );
    }
    return 0;
  }, [props.isWeightField, quantity, updateData, weightInfo]);

  const handleFormDataUpdate = useCallback(
    (update: FormDataUpdate, updateValue: any) => {
      if (dispatch)
        dispatch(
          ItemScannerAction.setFormData({
            orderItemId: item.orderItemId,
            update,
            updateValue,
          }),
        );
    },
    [dispatch, item.orderItemId],
  );

  const priceUpdate = useCallback(
    (price: string) => {
      if (checkPriceDecimals(price) && Number(price) <= 500) {
        setPriceUpdate(price);
        handleFormDataUpdate(FormDataUpdate.price, Number(price));
      }
    },
    [handleFormDataUpdate],
  );

  useEffect(() => {
    const orderItem = state.formData.find(
      data => data.orderItemId === item.orderItemId,
    );
    if (!orderItem) return;
    if (orderItem.price === 0) setPriceUpdate('');
  }, [item.orderItemId, state.formData]);

  return (
    <Box>
      <HBox style={styles.marginBottom5}>
        <H5Text
          style={{
            color: theme?.text.blue[300],
            fontFamily: muliFont[700].normal,
          }}>
          Price per {props.isWeightField ? weightInfo?.[0]?.unit : 'quantity'}
          <If condition={priceMandatory}>
            <H5Text style={{color: theme?.text.red[100]}}>*</H5Text>
          </If>
        </H5Text>
        <HBox>
          <H3Text
            style={{
              color: theme?.text.blue[300],
            }}>
            $
          </H3Text>
          <PTextInput
            placeholder="0.00"
            placeholderTextColor={theme?.text.gray[300]}
            debounce={false}
            value={
              updatePrice ||
              (updateData?.price ? updateData.price.toString() : '')
            }
            onChangeText={priceUpdate}
            pointerEvents={pointerEvents}
            textAlign="right"
            keyboardType="decimal-pad"
            returnKeyType="done"
            containerStyle={[styles.inputStyle, styles.priceStyle]}
            style={{
              fontFamily: muliFont[700].normal,
              ...font.FontSize.fontH3,
              color: theme?.text.blue[300],
            }}
          />
        </HBox>
      </HBox>
      <CustomText
        style={[
          styles.remainingTextRight,
          {
            fontFamily: muliFont[700].normal,
          },
        ]}>
        Total Price: {formatCurrency(priceWithWeight * 100, true)}
      </CustomText>
    </Box>
  );
};

export default React.memo(UpdatePriceSection);
