import {Box} from '../../../../../src/components/core/view';
import BuyerReplacementSuggestions from '../buyerReplacementSuggestions';
import If from '../../../../../src/components/conditional/If';
import React from 'react';
import SearchResults from './SearchResults';
import {StyleSheet} from 'react-native';
import {SubstitutionChoice} from '../../../../../src/itemDetails/types/itemReplacement';
import {useItemReplacementContext} from '../../../../../src/itemDetails/hooks/itemReplacement';

interface ReplacementSuggestionsProps {
  catalogItemId: number;
  category: string;
  searchQuery: string;
  storeId: number;
  subCategory: string;
  isUPCMode?: boolean;
  orderItemId?: number;
  orderId?: number;
  runId?: number;
  quantity?: number;
}

const ReplacementSuggestions: React.FC<ReplacementSuggestionsProps> = props => {
  const {state} = useItemReplacementContext();
  const subChoice = state?.item?.substitutionChoice;
  const subOptions = state?.item?.substitutionOptions || [];
  return (
    <Box style={styles.parentBox}>
      <If
        condition={
          subChoice === SubstitutionChoice.byBuyer && subOptions.length
        }>
        <BuyerReplacementSuggestions />
      </If>
      <If
        condition={
          subChoice !== SubstitutionChoice.byBuyer || !subOptions.length
        }>
        <SearchResults
          catalogItemId={props?.catalogItemId}
          category={props?.category}
          searchQuery={props?.searchQuery}
          storeId={props?.storeId}
          subCategory={props?.subCategory}
          isUPCMode={props?.isUPCMode ?? false}
          orderItemId={props?.orderItemId}
          orderId={props?.orderId}
          runId={props?.runId}
          quantity={props?.quantity}
        />
      </If>
    </Box>
  );
};
export default React.memo(ReplacementSuggestions);
const styles = StyleSheet.create({
  parentBox: {
    marginTop: 15,
    flex: 1,
  },
});
