import React, {useCallback, useEffect} from 'react';
import {RefreshControl, StyleSheet} from 'react-native';

import {Analytics} from '../../../../../../src/config/analytics/analytics';
import {CustomFlatList} from '../../../../../../src/components/core/list';
import {DeliveryLocationStatus} from '@buncha/batchDetails/types';
import DriverOrderCell from '../../driverOrderCell';
import LoaderComponents from '@buncha/components/composites/CustomLoader/LoaderComponents';
import NoOrderForDriver from '../../noOrderForDriver';
import {OrdersForDelivery} from '../../../../../../src/driver/types/driverOrders';
import {isWeb} from '../../../../../../src/utils/common';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {useColorThemeType} from '../../../../../../src/appStyles/hooks/theme';
import {useGetLocation} from '@buncha/utils/commonWithoutWeb';
import {useGetOrdersForDelivery} from '../../../../../../src/driver/hooks/driverOrders';
import {useIsFocused} from '@react-navigation/native';

interface RunOrdersProps {
  runId: number;
  setOrdersCount: React.Dispatch<React.SetStateAction<number>>;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const RunOrders: React.FC<RunOrdersProps> = props => {
  const runId = props?.runId || 0;
  const theme = useColorThemeType('main');
  const isFocused = useIsFocused();
  const {getOrdersForDelivery, loading, ordersData} = useGetOrdersForDelivery();
  const {coordinates, getLocationWithPermission} = useGetLocation();

  const onboardingState = useAppSelector(gState => gState.onboarding);
  const constants = onboardingState.constants;
  const enabledDriverTab = constants?.ENABLED_ALL_DRIVER_TAB;

  const renderItem = useCallback(
    ({item, index}: {item: OrdersForDelivery; index: number}) => {
      const assignedOrder = item;
      let isFirstItem = index === 0;

      if (enabledDriverTab === 'true') isFirstItem = true;
      return (
        <DriverOrderCell order={assignedOrder} isFirstItem={isFirstItem} />
      );
    },
    [enabledDriverTab],
  );

  useEffect(() => {
    getLocationWithPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ordersData) props?.setOrdersCount(ordersData?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersData]);

  useEffect(() => {
    if (props?.setLoading) props?.setLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if ((runId && isFocused && coordinates) || isWeb()) {
      getOrdersForDelivery(coordinates?.lat, coordinates?.lon, runId, [
        DeliveryLocationStatus.driverAssigned,
        DeliveryLocationStatus.onTheWay,
      ]);
      props?.setOrdersCount(ordersData?.length);

      Analytics.eventWithProps('Run wise orders for driver', {
        runId: runId,
        userType: 'driver',
        ordersCount: ordersData?.length,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, coordinates]);
  if (loading) return <LoaderComponents showBottomText={false} />;
  return (
    <CustomFlatList
      ListEmptyComponent={<NoOrderForDriver />}
      contentContainerStyle={styles.content}
      refreshControl={
        <RefreshControl
          refreshing={loading}
          onRefresh={async () =>
            await getOrdersForDelivery(
              coordinates?.lat,
              coordinates?.lon,
              runId,
              [
                DeliveryLocationStatus.driverAssigned,
                DeliveryLocationStatus.onTheWay,
              ],
            )
          }
          tintColor={theme?.background.gray[100]}
          style={styles.refreshControl}
        />
      }
      showsVerticalScrollIndicator={false}
      renderItem={renderItem}
      data={ordersData}
    />
  );
};

export default React.memo(RunOrders);

const styles = StyleSheet.create({
  refreshControl: {zIndex: 100},
  spinnerContainer: {
    justifyContent: 'center',
    flex: 1,
  },
  content: {
    flexGrow: 1,
  },
});
