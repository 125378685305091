import {Box, HBox} from '../../../components/core/view';
import {CustomText, H5Text} from '../../../components/core/text';
import React, {useCallback} from 'react';
import {Run, TaskInfo} from '../../../AboutShift/types';
import {getMapUrl, getWidth} from '../../../utils/common';
import {useAppDispatch, useAppSelector} from '../../../config/redux/hooks';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {Analytics} from '../../../config/analytics/analytics';
import {BatchRunInfo} from '../BatchRunInfo';
import {EventType} from '../../../config/analytics/type';
import {Linking} from 'react-native';
import LocationIcon from '../../../components/core/icon/LocationIcon';
import {MapChoice} from '../../../onBoarding/types';
import StoreLogo from '../../../common/components/StoreLogo';
import {Touchable} from '../../../components/core/touchable';
import {styles} from './styles';
import {useUpdateUserChoiceMap} from '../../../AboutShift/hooks/UserMapChoice';

interface BatchInfoProp {
  run: Run;
  taskCount?: TaskInfo;
  hideStoreInfo?: boolean;
  hideDriverInfo?: boolean;
}

function BatchInfo(props: BatchInfoProp) {
  const {run, hideDriverInfo, hideStoreInfo, taskCount} = props;
  const store = run?.store;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const appDispatch = useAppDispatch();
  const [updateUserChoiceMap] = useUpdateUserChoiceMap(appDispatch);

  const selectedMapChoice = useAppSelector(
    gbState => gbState.onboarding.currentUser?.mapChoice,
  );

  const openMap = useCallback(async (mapLink: string) => {
    try {
      await Linking.openURL(mapLink);
    } catch (error) {
      return error;
    }
  }, []);

  const selectMapChoice = useCallback(
    (choice: MapChoice) => {
      updateUserChoiceMap(choice);
      const latLong = store?.getLatLongFromAddress;
      const mapLink = choice ? getMapUrl(choice, latLong) : '';
      openMap(mapLink);
    },
    [openMap, store?.getLatLongFromAddress, updateUserChoiceMap],
  );

  const openMapHandler = useCallback(() => {
    Analytics.eventWithProps('Navigate to map', {
      type: EventType.cta,
    });
    if (!selectedMapChoice) selectMapChoice(MapChoice.googleMaps);
    else selectMapChoice(selectedMapChoice);
  }, [selectMapChoice, selectedMapChoice]);

  return (
    <Box style={styles.mainContainer}>
      <HBox>
        <HBox>
          <StoreLogo storeLogoUrl={store.storeLogoUrl} />
          <Box style={styles.textContainer}>
            <H5Text
              style={[
                styles.storeText,
                {
                  fontFamily: muliFont[900].normal,
                  color: theme?.text.blue[300],
                },
              ]}>
              {store.name}
            </H5Text>
            <CustomText
              numberOfLines={1}
              style={[
                styles.storeText,
                {
                  fontFamily: muliFont[600].normal,
                  color: theme?.text.gray[100],
                },
              ]}>
              {run.locationAddress}
            </CustomText>
            <CustomText
              numberOfLines={1}
              style={[
                styles.storeText,
                {
                  fontFamily: muliFont[600].normal,
                  color: theme?.text.gray[100],
                },
              ]}>
              Run ID: {run.id}
            </CustomText>
          </Box>
        </HBox>
        <Touchable
          style={[
            styles.locationContainer,
            {
              borderColor: theme?.background.green[100],
            },
          ]}
          onPress={openMapHandler}>
          <LocationIcon
            height={30}
            width={Number(getWidth(30))}
            viewBox="-4 0 22 22"
            fill={theme?.background.green[100]}
          />
        </Touchable>
      </HBox>
      <BatchRunInfo
        store={store}
        taskCount={taskCount}
        hideDriverInfo={hideDriverInfo}
        hideStoreInfo={hideStoreInfo}
      />
    </Box>
  );
}

export default React.memo(BatchInfo);
