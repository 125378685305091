import {Box, HBox} from '../../../components/core/view';
import {H4Text, H6Text} from '../../../components/core/text';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import React from 'react';
import {Shifts} from '../../types';
import {styles} from './styles';
import StoreLogo from '../../../common/components/StoreLogo';

interface PendingShiftItemsProps {
  shift: Shifts;
}

function PendingShiftItems(props: PendingShiftItemsProps) {
  const {shift} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  return (
    <HBox style={[styles.mainContainer]}>
      <StoreLogo storeLogoUrl={shift?.store?.storeLogoUrl} />
      <Box style={[styles.infoContainer]}>
        <H4Text
          style={[
            styles.runName,
            {
              fontFamily: muliFont[900].normal,
              color: theme?.text.blue[300],
            },
          ]}>
          {shift?.store?.name}
        </H4Text>
        <H6Text
          style={[
            styles.runSubtext,
            {
              fontFamily: muliFont[700].normal,
              color: theme?.text.blue[300],
            },
          ]}>
          {shift?.time}
        </H6Text>
      </Box>
    </HBox>
  );
}

export default React.memo(PendingShiftItems);
