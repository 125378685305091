import {Box, HBox} from '../../../components/core/view';
import {ChevronDownIcon, ChevronUpIcon, IconButton} from 'native-base';
import {
  ContactBottomSheetProps,
  DynamicTextType,
  GroupedAssignedItems,
  OrderItems,
  RunForBatchDetail,
} from '../../../batchDetails/types';
import {HomeScreens, ShopperScreens} from '../../../home/navigation/types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  batchDetailActionsLocal,
  useBatchDetailContext,
} from '../../../batchDetails/hooks/batchDetail';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {Accordian} from '../../../components/composites';
import AddToCartIcon from '../../../components/core/icon/AddToCartIcon';
import {CustomItemMode} from '../../../itemDetails/types/CustomItem';
import {CustomText} from '../../../components/core/text';
import CustomerOrderInfo from '../CustomerOrderInfo';
import If from '@buncha/components/conditional/If';
import {ItemDetailsScreen} from '../../../itemDetails/navigation/types';
import {OrderItemStatus} from '@buncha/common/types/common';
import PackOrderItemList from '../PackOrderItemList';
import UsedBagBottomSheet from '../VanLoad/UsedBag/UsedBagBottomSheet';
import WaveIcon from '../../../components/core/icon/WaveIcon';
import {navigation} from '../../../config/navigation';
import {styles} from './style';

interface PackItemAccordionProps {
  item: GroupedAssignedItems;
  changedItemStatus: (orderItem: OrderItems) => void;
  packingFinished: boolean;
  orderLoading: boolean;
  runDetail?: RunForBatchDetail;
}

function PackItemAccordion(props: PackItemAccordionProps) {
  const {item, changedItemStatus, packingFinished, orderLoading, runDetail} =
    props;
  const buyer = item?.metaData?.buyer;
  const order = item?.metaData?.order;
  const isVip = buyer?.isVip;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const {dispatch} = useBatchDetailContext();
  const storeIndex = runDetail?.store?.elasticSearchIndex || '';
  const [open, setOpen] = useState(false);

  const toggleBottomSheet = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const closeContactSheet = useCallback(() => {
    if (dispatch)
      dispatch(batchDetailActionsLocal.setContactBottomSheetProps(undefined));
  }, [dispatch]);

  const navigateToAddItem = useCallback(
    (group: GroupedAssignedItems) => {
      closeContactSheet();
      const orderId = group.orderItems?.length
        ? group.orderItems[0].orderId
        : 0;
      const runId = runDetail?.id;
      const storeId = runDetail?.store?.id;
      if (!runId || !storeId || !orderId) return;
      if (!storeIndex?.length)
        return navigation.navigate(ShopperScreens.ItemDetails, {
          screen: ItemDetailsScreen.CustomItem,
          params: {
            orderId,
            runId,
            mode: CustomItemMode.addItem,
            returnPage: ShopperScreens.OrderPackingPage,
          },
        });
      navigation.navigate(HomeScreens.Shopper, {
        screen: ShopperScreens.AddItemPage,
        params: {
          storeId,
          runId,
          orderId,
          storeIndex,
          returnPage: ShopperScreens.OrderPackingPage,
        },
      });
    },
    [closeContactSheet, runDetail?.id, runDetail?.store?.id, storeIndex],
  );

  const handleContactCustomer = useCallback(
    (group: GroupedAssignedItems) => {
      const orderId = group.orderItems?.length
        ? group.orderItems[0].orderId
        : 0;
      const params: ContactBottomSheetProps = {
        type: DynamicTextType.customer,
        orderId: orderId,
      };
      if (dispatch)
        dispatch(batchDetailActionsLocal.setContactBottomSheetProps(params));
    },
    [dispatch],
  );

  const handleAddItem = useCallback(
    (group: GroupedAssignedItems) => {
      const orderId = group.orderItems?.length
        ? group.orderItems[0].orderId
        : 0;
      const params: ContactBottomSheetProps = {
        type: DynamicTextType.addItem,
        buttonText: 'Add Item',
        onButtonClick: () => navigateToAddItem(group),
        orderId: orderId,
      };

      if (dispatch)
        dispatch(batchDetailActionsLocal.setContactBottomSheetProps(params));
    },
    [dispatch, navigateToAddItem],
  );

  const checkLastItemPick = useMemo(() => {
    if (item.orderItems) {
      const data = item.orderItems.find(
        or => or.itemStatus === OrderItemStatus.notPacked,
      );

      if (data) return false;
      return true;
    }
    return false;
  }, [item.orderItems]);

  useEffect(() => {
    if (checkLastItemPick && !order?.bagsUsed) setOpen(true);
    else setOpen(false);
  }, [checkLastItemPick, order?.bagsUsed]);

  return (
    <Box style={[styles.mainContainer]}>
      <Accordian
        isExpanded={true}
        header={
          <HBox style={[styles.mainHeaderContainer]}>
            <CustomerOrderInfo groupedAssignedItems={item} isVip={isVip} />
            <HBox>
              <IconButton onPress={() => handleContactCustomer(item)}>
                <WaveIcon />
              </IconButton>
              <IconButton onPress={() => handleAddItem(item)}>
                <AddToCartIcon width={18} height={18} viewBox="0 0 21 21" />
              </IconButton>
              <Box
                style={[
                  styles.countBox,
                  {
                    backgroundColor: theme?.background.gray[200],
                  },
                ]}>
                <CustomText
                  style={[
                    {
                      fontFamily: muliFont[700].normal,
                      color: theme?.text.gray[100],
                    },
                  ]}>
                  {item.orderItems.length}
                </CustomText>
              </Box>
            </HBox>
          </HBox>
        }
        children={
          <PackOrderItemList
            packOrderItem={item.orderItems}
            onClick={changedItemStatus}
            packingFinished={packingFinished}
            orderLoading={orderLoading}
            storeIndex={storeIndex}
          />
        }
        iconEnable={<ChevronUpIcon color={theme?.text.blue[300]} />}
        iconDisable={<ChevronDownIcon color={theme?.text.blue[300]} />}
      />

      <If condition={open}>
        <UsedBagBottomSheet
          open={open || false}
          onClose={toggleBottomSheet}
          buyerName={`${buyer?.firstName} ${buyer?.lastName}`}
          usedBag={0}
          orderId={item?.metaData.orderId}
        />
      </If>
    </Box>
  );
}

export default React.memo(PackItemAccordion);
