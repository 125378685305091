import React, {useCallback, useEffect} from 'react';
import ScreenHeader from '../../common/components/screenHeader';
import Header from '../../components/composites/Header/Header';
import If from '../../components/conditional/If';
import {SpinnerComponent} from '../../components/core/loader';
import {usePastOrderByRun} from '../hooks/home';
import {useRoute} from '@react-navigation/native';
import {H3Text} from '../../components/core/text';
import {CustomFlatList} from '../../components/core/list';
import AccordianCell from '../components/AccordianCell';
import {PastOrder} from '../types';
import {Box} from '../../components/core/view';
import {Dimensions, StyleSheet} from 'react-native';
import {Divider} from 'native-base';
import Scrollview from '../../components/composites/scrollview';
import {getWidth} from '../../utils/common';
import {useColorThemeType} from '../../appStyles/hooks/theme';

function PastOrders() {
  const route = useRoute();
  const routeParams: {runId?: number} | undefined = route?.params;
  const runId = routeParams?.runId;
  const theme = useColorThemeType();
  const {getPastOrdersByRun, loading, orders} = usePastOrderByRun();

  const renderOrder = useCallback(
    ({item}: {item: PastOrder; index: number}) => {
      return <AccordianCell key={item.id} order={item} />;
    },
    [],
  );

  useEffect(() => {
    getPastOrdersByRun(runId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const separator = useCallback(() => {
    return (
      <Divider
        style={[
          styles.divider,
          {
            backgroundColor: theme?.text.gray[200],
          },
        ]}
      />
    );
  }, [theme?.text.gray]);

  return (
    <Header loading={loading} showDefaultLeftHeader>
      <ScreenHeader title={`Run ID: ${runId}`} />
      <Box style={styles.container}>
        <If condition={loading}>
          <SpinnerComponent />
        </If>
        <If condition={!loading}>
          <If condition={!orders?.length}>
            <H3Text>No Orders</H3Text>
          </If>
          <If condition={orders?.length}>
            <Scrollview>
              <Box style={styles.pad20}>
                <CustomFlatList
                  data={orders}
                  renderItem={renderOrder}
                  showsVerticalScrollIndicator={false}
                  ItemSeparatorComponent={separator}
                />
              </Box>
            </Scrollview>
          </If>
        </If>
      </Box>
    </Header>
  );
}

const styles = StyleSheet.create({
  pad20: {
    padding: 20,
  },
  divider: {
    marginVertical: 15,
    minWidth: getWidth(Dimensions.get('window').width),
    margin: -20,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default React.memo(PastOrders);
