import {AnyAction, configureStore} from '@reduxjs/toolkit';

import rootStore from './rootStore';

// ...

export const store = configureStore({
  reducer: rootStore,
  devTools: false,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export function dispatch(action: AnyAction) {
  return store.dispatch(action);
}
