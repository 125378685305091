import {Dimensions, StyleSheet} from 'react-native';

import {getWidth} from '../../../../../src/utils/common';

export const inputNotesStyles = StyleSheet.create({
  inputContainer: {
    borderWidth: 1,
    width: '100%',
    borderRadius: 15,
    marginTop: 10,
  },
  input: {
    height: 150,
    marginTop: 5,
  },
  addCTA: {
    borderRadius: 50,
    borderWidth: 0,
  },
  cancelCTA: {
    marginVertical: 15,
    borderRadius: 50,
    borderWidth: 1,
  },
  buttonBox: {
    paddingHorizontal: 20,
  },
  modal: {
    borderRadius: 20,
    width: getWidth(Dimensions.get('window').width - 40),
  },
});
