import {Box, HBox} from '@buncha/components/core/view';

import {CustomText} from '@buncha/components/core/text';
import React from 'react';
import {StyleSheet} from 'react-native';
import UploadProgressIcon from '@buncha/components/core/icon/UploadProgressIcon';
import font from '@buncha/appStyles/style/font';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const FileUploadHeader = () => {
  const theme = useColorThemeType();
  return (
    <Box
      style={[
        styles.mainContainer,
        {
          backgroundColor: theme?.background.yellow[100],
        },
      ]}>
      <HBox style={styles.container}>
        <UploadProgressIcon
          fill={theme?.background.yellow[100]}
          viewBox="-2 -3 25 25"
          stroke={theme?.background.blue[300]}
        />
        <CustomText
          style={[
            styles.title,
            {
              color: theme?.text.blue[300],
            },
          ]}>
          Upload in Progress
        </CustomText>
      </HBox>
    </Box>
  );
};

export default React.memo(FileUploadHeader);

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  container: {
    justifyContent: 'flex-start',
  },
  title: {
    marginLeft: 8,
    fontFamily: font.fonts.muliSemiBold,
  },
});
