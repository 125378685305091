import React from 'react';
import {ImageStyle, StyleSheet, ViewStyle} from 'react-native';
import {useColorThemeType} from '../../../appStyles/hooks/theme';
import {CustomImage} from '../../../components/core/Image';
import {Box} from '../../../components/core/view';

interface Props {
  storeLogoUrl: string;
  boxStyle?: ViewStyle;
  imageBoxStyle?: ImageStyle;
}
function StoreLogo(props: Props) {
  const defaultTheme = useColorThemeType();
  return (
    <Box
      style={[
        styles.storeLogoBox,
        {
          borderColor: defaultTheme?.background.gray[200],
        },
        props?.boxStyle,
      ]}>
      <CustomImage
        source={props.storeLogoUrl}
        resizeMode="cover"
        style={[styles.storeLogo, props?.imageBoxStyle]}
        onErrorSource={''}
      />
    </Box>
  );
}

export default React.memo(StoreLogo);

const styles = StyleSheet.create({
  storeLogoBox: {
    borderWidth: 3,
    borderRadius: 50,
  },
  storeLogo: {
    borderRadius: 50,
    height: 50,
    width: 50,
    overflow: 'hidden',
  },
});
