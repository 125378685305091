import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 20,
  },
  mainHeaderContainer: {
    flex: 1,
    marginRight: 10,
  },
  countBox: {
    paddingHorizontal: 15,
    borderRadius: 50,
    paddingVertical: 2.5,
  },
  divider: {
    marginVertical: 15,
  },
  scrollContainer: {
    marginTop: 20,
  },
  contentBox: {
    flexGrow: 1,
  },
  skeletonContainer: {
    marginHorizontal: 20,
  },
});
