import {Dimensions, StyleSheet} from 'react-native';
import {getWidth} from '../../../utils/common';

export const styles = StyleSheet.create({
  body: {
    // height: screenHeight / 1.5,
    position: 'relative',
  },
  centerBox: {
    justifyContent: 'center',
  },
  subTitle: {
    marginTop: 10,
  },
  buttonStyle: {
    marginTop: 0,
  },
  imageContainer: {
    // height: screenHeight / 1.85,
    alignItems: 'flex-start',
  },
  typoBox: {
    width: getWidth('95%'),
  },
  skipText: {
    textAlign: 'right',
  },
  scrollStyles: {
    maxHeight: Dimensions.get('screen').height * 0.52,
  },
});
