import {StyleProp, ViewStyle} from 'react-native';

import {CustomFlatList} from '../../../components/core/list';
import React from 'react';

interface Props {
  children?: any;
  contentContainerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
}

const CustomScrollView = (props: Props) => {
  return (
    <CustomFlatList
      {...props}
      showsVerticalScrollIndicator={false}
      data={[]}
      renderItem={undefined}
      keyboardShouldPersistTaps="always"
      ListHeaderComponent={props.children}
      alwaysBounceVertical={false}
      contentContainerStyle={props?.contentContainerStyle}
      style={props?.style}
    />
  );
};

export default React.memo(CustomScrollView);
