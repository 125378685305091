import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  parent: {
    alignItems: 'flex-start',
  },
  textContainer: {
    marginLeft: 10,
    alignItems: 'flex-start',
  },
  nameText: {
    textAlign: 'left',
    marginRight: 5,
  },
  newOnBunchaText: {
    borderRadius: 50,
    paddingHorizontal: 10,
    paddingVertical: 2,
  },
  vipBox: {
    position: 'absolute',
    top: -3,
    left: -3,
    width: 12,
    height: 12,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  profileBoxIcon: {position: 'relative'},
});
