import {Dimensions, StyleSheet} from 'react-native';

import {Box} from '../../../components/core/view';
import {PageHeaderComponent} from '../ProfileOptions/types';
import React from 'react';
import {getWidth} from '../../../utils/common';

interface ProfileHeaderProps {
  headerData?: PageHeaderComponent;
}

const ProfileHeader = (props: ProfileHeaderProps) => {
  const {headerData} = props;
  const headerHtml = headerData?.headerHtml;
  const headerIcon = headerData?.icon;
  return (
    <Box style={[styles.parentContainer]}>
      <Box style={[styles.imageContainer]}>
        <div
          dangerouslySetInnerHTML={{
            __html: headerIcon?.data?.component ?? '',
          }}
          style={styles.imageWeb}
        />
      </Box>
      <Box style={styles.htmlMainContainer}>
        <div
          dangerouslySetInnerHTML={{__html: headerHtml?.htmlData ?? ''}}
          style={styles.htmlContainer}
        />
      </Box>
    </Box>
  );
};

export default React.memo(ProfileHeader);

const styles = StyleSheet.create({
  parentContainer: {
    // padding: 20,
    justifyContent: 'flex-start',
  },
  imageContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  imageWeb: {
    height: Dimensions.get('screen').height * 0.35,
    width: Number(getWidth(Dimensions.get('screen').width)) * 0.8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  htmlContainer: {
    margin: 20,
  },
  htmlMainContainer: {
    display: 'flex',
    margin: 20,
  },
});
