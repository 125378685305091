import {Keyboard, Modal, TouchableWithoutFeedback} from 'react-native';
import React, {forwardRef, useCallback} from 'react';

import {Box} from '../../core/view';
import {CustomDialogBoxProps} from './type';
import {styles} from './style';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

/**
 * @example
 * <CustomDialogBox
    colorThemeType="error"
    visible={true}
    onRequestClose={() => {}}
    // modalBoxStyle={{ backgroundColor: 'yellow', width: 270 }}
    header={<CustomText>Header</CustomText>}
    body={
      <Box>
        <CustomText>Lorem ipsum dolor sit amet, consectetur adipiscing elit</CustomText>
      </Box>
    }
    footer={
      <HBox>
        <Box style={[{ flex: 2 }]}><Button title="CTA1" onPress={() => {}} /></Box>
        <Box style={[{ flex: 2 }]}><Button title="CTA2" onPress={() => {}} /></Box>
      </HBox>
    }
  />
 */

const CustomDialogBox: React.FC<CustomDialogBoxProps<Modal>> = forwardRef(
  (props, ref) => {
    const theme = useColorThemeType();
    const defaultStyle = {
      backgroundColor: theme?.background.white[100],
    };

    const propsToSpread: CustomDialogBoxProps = {
      animationType: 'slide',
      transparent: true,
      ...props,
    };

    const getModal = () => {
      return (
        <React.Fragment>
          <Box style={styles.modalHeader}>{props.header}</Box>
          <Box style={[styles.modalData]}>{props.body}</Box>
          <Box style={[styles.modalFooter]}>{props.footer}</Box>
        </React.Fragment>
      );
    };

    const closeModal = useCallback(() => {
      if (props?.onClose) props?.onClose();
      Keyboard.dismiss();
    }, [props]);
    return (
      <Modal {...propsToSpread} ref={ref}>
        <TouchableWithoutFeedback onPress={closeModal}>
          <Box style={styles.modal}>
            <Box
              style={[
                styles.modalBackground,
                defaultStyle,
                props.modalBoxStyle,
              ]}>
              {getModal()}
            </Box>
          </Box>
        </TouchableWithoutFeedback>
      </Modal>
    );
  },
);

export default React.memo(CustomDialogBox);
