import {StyleSheet} from 'react-native';
import {getWidth} from '@buncha/utils/common';

export const styles = StyleSheet.create({
  alignLeft: {
    textAlign: 'left',
  },
  marRight5: {marginRight: 5},
  inputStyle: {
    width: getWidth(70),
    height: 15,
  },
  quantityStyle: {
    width: getWidth(70),
  },
  priceStyle: {
    width: getWidth(80),
  },
  containerBox: {
    paddingLeft: 20,
    paddingRight: 10,
  },
  remainingText: {
    marginRight: 10,
    textAlign: 'left',
    marginTop: 5,
  },
  remainingTextRight: {
    marginRight: 10,
    textAlign: 'right',
    marginTop: 5,
  },
  divider: {
    height: 0.5,
    marginVertical: 10,
    marginRight: 10,
  },
  marTop20: {
    marginTop: 20,
  },
  marVer20: {
    marginVertical: 20,
  },
  weightDivider: {
    flex: 1,
  },
  weightDividerContainer: {marginVertical: 10},
  marginBottom5: {marginBottom: 5},
  marginBottom10: {marginBottom: 20},
  marginTop20: {marginTop: 0},
  marginTop5: {marginTop: 10},
  downIcon: {height: 10},
  downIconContainer: {justifyContent: 'space-between', paddingRight: 10},
  downIconSubContainer: {flex: 1, alignItems: 'flex-end'},
  padRight10: {paddingRight: 10},
  quantityContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  quantityPill: {
    borderWidth: 1,
    padding: 10,
    borderRadius: 50,
  },
  addIconContainer: {paddingVertical: 5},
});
