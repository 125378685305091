import {
  ADD_SUBSTITUTE_FOR_UPC,
  CAPTURE_ITEM_INFORMATION,
  DELETE_RUN_RECEIPT,
  SCAN_UPC_FOR_ALL_ITEMS,
  SCAN_UPC_FOR_ALL_ITEMS_IMAGES,
  SCAN_UPC_FOR_CROWD_SOURCE,
  UPDATE_ITEM_IMAGE,
  UPDATE_SHOPPER_ITEM_CHANGES,
  UPLOAD_IMAGE,
  UPLOAD_UPC_IMAGE,
} from '../../config/graphQL/documentNode/mutation';
import {
  BuyerData,
  CaptureItemParams,
  FormData,
  FormDataUpdate,
  FormUpdate,
  GetBuyerDataResponse,
  GetShopperItemChangesResponse,
  MandatoryFieldNames,
  OrderItem,
  ScanResultForAllItemsResponse,
  ScanUPCForAllItemsImagesParams,
  ScanUPCForAllItemsParams,
  ScanUPCParams,
  ShopperItemChanges,
  State,
  SubstituteInUPCParams,
  UploadImageUrlResponse,
  UploadItemImageResponse,
} from '../types/ItemScanner';
import {Dispatch, useContext, useReducer, useState} from 'react';
import {
  GET_BUYER_DETAILS,
  GET_CATALOG_ITEM,
  GET_ORDER_ITEM_BY_ID_SCAN,
  GET_UPC_IDS_FOR_CATEGORIES,
} from '../../config/graphQL/documentNode/queries';
import {ItemScannerContext, initialState} from '../context/ItemScanner';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {PermissionsAndroid, Platform} from 'react-native';

import {Analytics} from '@buncha/config/analytics/analytics';
import {ErrorSource} from '@buncha/config/Error/service';
import Geolocation from 'react-native-geolocation-service';
import {OrderItemStatus} from '@buncha/batchDetails/types';
import {ToastType} from '../../components/composites/notification/type';
import {getOs} from '../../utils/appInfo';
import {getRandomNumbers} from '@buncha/utils/common';
import {gqlService} from '../../config/graphQL';
import {useToastMessage} from '../../components/composites/notification';

const defaultWeightInfo = {
  id: null,
  weight: 0,
  unit: 'lb',
  isUpdate: false,
};

const reducer = {
  setItemData: function (state: State, action: PayloadAction<OrderItem>) {
    state.item = action.payload;
    state.upcImages = action.payload.upcImages || [];
  },
  setBuyersData: function (state: State, action: PayloadAction<BuyerData[]>) {
    state.buyerData = action.payload;
    state.formData = action.payload.map(item => {
      return {
        orderItemId: item.orderItemId,
        dropped: item.itemStatus === OrderItemStatus.dropped,
        weightInfo: item.weightInfo?.map(info => ({
          ...defaultWeightInfo,
          isUpdate: true,
          id: info.id,
        })) || [defaultWeightInfo],
      };
    });
    const mandatoryFields = state.buyerData?.[0].mandatoryFields;

    state.remainingCustomers =
      action.payload.filter(item => item.itemStatus !== OrderItemStatus.dropped)
        ?.length || 0;

    if (mandatoryFields.length === 0) state.remainingCustomers = 0;
  },
  addWeight: function (state: State, action: PayloadAction<number>) {
    const orderItemId = action.payload;
    state.formData = state.formData.map(item => {
      if (item.orderItemId !== orderItemId) return item;
      return {
        ...item,
        weightInfo: [...(item.weightInfo || []), defaultWeightInfo],
      };
    });
  },
  removeWeight: function (state: State, action: PayloadAction<number>) {
    const orderItemId = action.payload;
    state.formData = state.formData.map(item => {
      if (item.orderItemId !== orderItemId) return item;
      const info = item.weightInfo;
      info?.pop();
      return {
        ...item,
        weightInfo: info,
      };
    });
  },
  setUpcImagesModal: function (state: State, action: PayloadAction<boolean>) {
    state.upcImagesModal = action.payload;
    if (action.payload) state.scannerActive = false;
    else state.scannerActive = true;
  },
  setScannerActive: function (state: State, action: PayloadAction<boolean>) {
    if (state.scannerExpanded) state.scannerActive = action.payload;
  },
  setScanAgainButtonLoading: function (
    state: State,
    action: PayloadAction<boolean>,
  ) {
    if (state.scannerExpanded) state.scanAgainButtonLoading = action.payload;
  },
  deleteUpcImage: function (state: State, action: PayloadAction<string>) {
    state.upcImages = state.upcImages.filter(image => image !== action.payload);
  },
  addUpcImage: function (state: State, action: PayloadAction<string>) {
    state.upcImages = [...state.upcImages, action.payload];
  },
  setScannerExpanded: function (state: State, action: PayloadAction<boolean>) {
    state.scannerExpanded = action.payload;
    state.scannerActive = action.payload;
  },
  setFormData: function (state: State, action: PayloadAction<FormUpdate>) {
    const {orderItemId, update, updateValue, weightIndex} = action.payload;
    let item = state.formData.find(data => data.orderItemId === orderItemId);
    if (!item) return;
    const mandatoryFields = state.buyerData?.[0].mandatoryFields;

    updateFormData(update, item, updateValue, weightIndex ?? 0);

    const weightMandatory = mandatoryFields.find(
      (field: {fieldName: MandatoryFieldNames}) =>
        field.fieldName === MandatoryFieldNames.weightFound,
    );
    const priceMandatory = mandatoryFields.find(
      (field: {fieldName: MandatoryFieldNames}) =>
        field.fieldName === MandatoryFieldNames.pricePerQuantity,
    );
    const quantityMandatory = mandatoryFields.find(
      (field: {fieldName: MandatoryFieldNames}) =>
        field.fieldName === MandatoryFieldNames.quantityFound,
    );
    state.remainingCustomers =
      state.formData.filter(data => {
        if (data.dropped) return false;
        if (weightMandatory && !data.weightInfo?.find(info => info.weight))
          return true;
        if (priceMandatory && !data.price) return true;
        if (quantityMandatory && !data.quantity) return true;
        return false;
      })?.length || 0;
  },
  setUPCItemData: function (state: State, action: PayloadAction<OrderItem>) {
    state.upcOrderItem = action.payload;
  },
  setUPCScannedImages: function (state: State, action: PayloadAction<string>) {
    state.upcScannedImages = [action.payload];
  },
  setUPScanStatus: function (state: State, action: PayloadAction<boolean>) {
    state.upcCrowdSourceProcessCompleted = action.payload;
  },
};

const slice = createSlice({
  initialState,
  name: 'ItemScannerContext',
  reducers: reducer,
});

export function useItemScannerReducer() {
  const reducerInfo = useReducer(slice.reducer, initialState);
  return reducerInfo;
}

export function useItemScannerContext() {
  return useContext(ItemScannerContext);
}

export const ItemScannerAction = slice.actions;

const updateFormData = (
  update: FormDataUpdate,
  item: FormData,
  updateValue: any,
  weightIndex: number,
) => {
  const weightInfo = item.weightInfo?.[weightIndex || 0];
  switch (update) {
    case FormDataUpdate.dropped:
      item.dropped = updateValue;
      item.price = 0;
      item.quantity = 0;
      item.weightInfo = item.weightInfo?.map(_info => ({
        weight: 0,
        unit: 'lb',
      }));
      break;
    case FormDataUpdate.price:
      let priceWithWeight = 0;
      item?.weightInfo?.forEach(
        weightData => (priceWithWeight += Number(weightData?.weight || 0)),
      );
      item.price = updateValue;
      item.totalPrice = priceWithWeight * Number(updateValue);
      break;
    case FormDataUpdate.quantity:
      item.quantity = updateValue;
      break;
    case FormDataUpdate.unit:
      if (weightInfo) weightInfo.unit = updateValue;
      break;
    case FormDataUpdate.weight:
      if (weightInfo) weightInfo.weight = updateValue;
      break;
    default:
  }
  return item;
};

export function useGetOrderItemById(dispatch: Dispatch<any> | null) {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [orderItemLoading, setLoading] = useState(false);

  const getOrderItemById = async (orderItemId: number) => {
    if (!orderItemId) return;
    try {
      setLoading(true);
      const res = await gqlService?.query({
        query: GET_ORDER_ITEM_BY_ID_SCAN,
        fetchPolicy: 'network-only',
        variables: {
          orderItemId: orderItemId,
        },
      });
      const itemInfo = res?.data?.getOrderItemById;
      if (dispatch) dispatch(ItemScannerAction?.setItemData(itemInfo));
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {getOrderItemById, orderItemLoading};
}

export function useScanUPCForAllItems(dispatch: Dispatch<any> | null) {
  const [upcScanLoading, setLoading] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Warning);

  const handleScanning = async (
    params: ScanUPCForAllItemsParams,
    upcs: string[],
  ) => {
    const scanned =
      upcs.find(
        upc =>
          params.upc === upc ||
          (!isNaN(Number(params.upc)) && Number(upc) === Number(params.upc)),
      ) || !upcs.length;

    if (!scanned)
      showErrorToast(
        "This item's barcode does not match our records. Please recheck or take a picture of the item and continue.",
      );

    await scanUPCForAllItems(params);
    return scanned;
  };

  const scanUPCForAllItems = async (params: ScanUPCForAllItemsParams) => {
    if (
      !params.catalogItemId ||
      !params.runId ||
      !params.storeIndex ||
      !params.upc
    )
      return;
    setLoading(true);
    if (dispatch) dispatch(ItemScannerAction.setScanAgainButtonLoading(true));
    if (dispatch) dispatch(ItemScannerAction.setScannerActive(false));
    try {
      const res = await gqlService?.mutation<ScanResultForAllItemsResponse>({
        mutation: SCAN_UPC_FOR_ALL_ITEMS,
        fetchPolicy: 'network-only',
        variables: params,
      });

      const response = res?.data?.scanUPCForAllItems;
      const message = response?.message;
      if (message) throw new Error(message);
      setLoading(false);
      if (dispatch)
        dispatch(ItemScannerAction.setScanAgainButtonLoading(false));
      return response?.scanned;
    } catch (error: any) {
      setLoading(false);
      if (dispatch)
        dispatch(ItemScannerAction.setScanAgainButtonLoading(false));
      Analytics.eventWithProps('error', {
        type: 'Error',
        error: error?.message,
        errorType: 'ValidationError',
        statusCode: 200,
        oprationName: 'scanUPCForAllItems',
        variables: JSON.stringify(params),
        errorSource: ErrorSource.graphql,
      });
    }
  };

  return {upcScanLoading, scanUPCForAllItems, handleScanning};
}

export function useGetLocation() {
  const [coordinates, setCoordinates] = useState<
    {lat: number; long: number} | undefined
  >(undefined);

  const getCurrentLocation = () => {
    Geolocation.getCurrentPosition(
      position => {
        setCoordinates({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
      },
      err => {
        return err;
      },
      {enableHighAccuracy: true, timeout: 15000, maximumAge: 10000},
    );
  };

  const getWebCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setCoordinates({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
      },
      error => {
        return error;
      },
      {enableHighAccuracy: true, timeout: 5000},
    );
  };

  const getLocationWithPermission = async () => {
    try {
      const os = getOs();
      if (os === 'web') getWebCurrentLocation();
      if (os === 'android') {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
          {
            title: 'Location Permission',
            message: 'Allow location access.',
            buttonPositive: 'Ok',
          },
        );
        if (granted === PermissionsAndroid.RESULTS.GRANTED)
          getCurrentLocation();
      }
      if (os === 'ios') {
        const resAlways = await Geolocation.requestAuthorization('always');
        const resWhenInUse = await Geolocation.requestAuthorization(
          'whenInUse',
        );
        if (resAlways === 'granted' || resWhenInUse === 'granted')
          getCurrentLocation();
      }
    } catch (err: any) {
      return err;
    }
  };

  return {coordinates, getLocationWithPermission};
}

export function useDeleteRunReceipt(dispatch: Dispatch<any> | null) {
  const [loading, setLoading] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);

  const deleteRunReceipt = async (runId: number, receiptUrl: string) => {
    if (!runId || !receiptUrl) return;
    try {
      setLoading(true);
      const response = await gqlService?.mutation({
        mutation: DELETE_RUN_RECEIPT,
        fetchPolicy: 'network-only',
        variables: {
          runId,
          receiptUrl,
        },
      });
      if (response?.data?.deleteRunreceipt && dispatch)
        dispatch(ItemScannerAction.deleteUpcImage(receiptUrl));
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {loading, deleteRunReceipt};
}

export function useUploadUpcImage(
  dispatch: Dispatch<any> | null,
  orderItemId?: number,
  storeIndex?: string,
) {
  const [loading, setLoading] = useState(false);
  const [upcImageError, setError] = useState('');

  const requestPermissions = async () => {
    if (Platform.OS === 'android') {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.CAMERA,
        {
          title: 'Camera Permission',
          message:
            'Allow camera access to quickly upload images of barcodes you need for better accuracy.',
          buttonPositive: 'Ok',
        },
      );
      return granted === PermissionsAndroid.RESULTS.GRANTED;
    }
    return true;
  };

  const uploadUpcImage = async (base64: string) => {
    if (!base64 || !orderItemId || !storeIndex?.length) return;
    const file = {
      uri: `${getRandomNumbers().toString(36).slice(2, 7)}.jpg`,
      base64: base64,
    };
    setLoading(true);
    setError('');
    try {
      const response = await gqlService?.mutation({
        mutation: UPLOAD_UPC_IMAGE,
        variables: {orderItemId, storeIndex, file},
      });
      const imageUrl: string = response?.data?.uploadUpcImages;
      setLoading(false);
      if (dispatch) dispatch(ItemScannerAction.addUpcImage(imageUrl));
      return imageUrl;
    } catch (err: any) {
      setError(err?.message);
      setLoading(false);
    }
  };

  return {loading, upcImageError, uploadUpcImage, requestPermissions};
}

export function useScanUPCForAllItemsImages() {
  const [upcScanImagesLoading, setLoading] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Warning);

  const scanUPCForAllItemsImages = async (
    params: ScanUPCForAllItemsImagesParams,
  ) => {
    if (
      !params.catalogItemId ||
      !params.runId ||
      !params.storeIndex ||
      !params.images?.length ||
      upcScanImagesLoading
    )
      return;
    setLoading(true);
    try {
      const res = await gqlService?.mutation<ScanResultForAllItemsResponse>({
        mutation: SCAN_UPC_FOR_ALL_ITEMS_IMAGES,
        fetchPolicy: 'network-only',
        variables: params,
      });

      const response = res?.data?.scanUPCForAllItems;
      const message = response?.message;
      if (message) throw new Error(message);
      setLoading(false);
      return response?.scanned;
    } catch (error: any) {
      showErrorToast(error.message);
      setLoading(false);
    }
  };

  return {upcScanImagesLoading, scanUPCForAllItemsImages};
}

export function useGetBuyerDetails(dispatch: Dispatch<any> | null) {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [buyerDetailsLoading, setLoading] = useState(false);

  const getBuyerDetails = async (orderItemId?: number) => {
    if (!orderItemId) return;
    try {
      setLoading(true);
      const res = await gqlService?.query<GetBuyerDataResponse>({
        query: GET_BUYER_DETAILS,
        fetchPolicy: 'network-only',
        variables: {
          orderItemId: orderItemId,
        },
      });
      const itemInfo = res?.data?.itemWithBuyersAndSub;
      if (dispatch) dispatch(ItemScannerAction?.setBuyersData(itemInfo || []));
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {buyerDetailsLoading, getBuyerDetails};
}

export function useUploadItemImage(dispatch: Dispatch<any> | null) {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const {getOrderItemById} = useGetOrderItemById(dispatch);
  const [updateImageLoading, setLoading] = useState(false);
  const uploadItemImage = async (
    base64: string,
    orderItemId: number,
    isUPCMode?: boolean,
  ) => {
    if (!base64 || !orderItemId) return;
    setLoading(true);
    const file = {
      uri: `${getRandomNumbers().toString(36).slice(2, 7)}.jpg`,
      base64: base64,
    };
    try {
      const res = await gqlService?.mutation<UploadImageUrlResponse>({
        mutation: UPLOAD_IMAGE,
        fetchPolicy: 'network-only',
        variables: {file},
      });

      if (isUPCMode && dispatch) {
        dispatch(ItemScannerAction.setUPCScannedImages(file?.uri));
      } else {
        const response = await gqlService?.mutation<UploadItemImageResponse>({
          mutation: UPDATE_ITEM_IMAGE,
          fetchPolicy: 'network-only',
          variables: {
            orderItemId,
            itemImageUrl: res?.data?.itemImageUpload?.uri,
          },
        });

        if (response) await getOrderItemById(orderItemId);
      }
    } catch (err: any) {
      showErrorToast(err?.message);
    } finally {
      setLoading(false);
    }
  };

  return {uploadItemImage, updateImageLoading};
}

export function useUpdateItemDetails() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [updateLoading, setLoading] = useState(false);

  const updateItemDetails = async (
    shopperItemChanges: ShopperItemChanges[],
  ) => {
    if (!shopperItemChanges?.length) return true;
    setLoading(true);
    try {
      const res = await gqlService?.mutation<GetShopperItemChangesResponse>({
        mutation: UPDATE_SHOPPER_ITEM_CHANGES,
        fetchPolicy: 'network-only',
        variables: {shopperItemChanges},
      });
      setLoading(false);
      return res?.data?.updateShopperItemChanges;
    } catch (err: any) {
      showErrorToast(err?.message);
    } finally {
      setLoading(false);
    }
  };

  return {updateLoading, updateItemDetails};
}

export function useUPCOrder(dispatch: Dispatch<any> | null) {
  const [loadingItem, setLoadingItem] = useState(false);
  const [markingItem, setMarkingItem] = useState<boolean>(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [imageUploading, setImageUploading] = useState(false);
  const [updatingUPC, setUpdatingUPC] = useState(false);

  const getCatalogItemForShopper = async (
    orderItemId: number,
    isUPCMode: boolean,
  ) => {
    try {
      setLoadingItem(true);
      const res = await gqlService?.query({
        query: GET_CATALOG_ITEM,
        fetchPolicy: 'network-only',
        variables: {
          orderItemId: orderItemId,
          isUpcOrder: isUPCMode,
        },
      });
      if (res?.data && dispatch)
        dispatch(
          ItemScannerAction.setUPCItemData(res?.data?.getCatalogItemForShopper),
        );
    } catch (error) {
    } finally {
      setLoadingItem(false);
    }
  };

  const substituteItemInUPC = async (
    params: SubstituteInUPCParams,
    callback: any,
  ) => {
    try {
      const res = await gqlService?.mutation({
        mutation: ADD_SUBSTITUTE_FOR_UPC,
        variables: params,
        fetchPolicy: 'network-only',
      });
      if (res?.data) callback();
    } catch (error: any) {
      showErrorToast(error.message);
    }
  };
  const markItemAsScanned = async (
    params: Omit<CaptureItemParams, 'isUpcOrder'>,
    callback: any,
  ) => {
    try {
      setMarkingItem(true);
      const res = await gqlService?.mutation({
        mutation: CAPTURE_ITEM_INFORMATION,
        variables: {
          ...params,
          isUpcOrder: true,
        },
        fetchPolicy: 'network-only',
      });
      if (res?.data) callback();
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setMarkingItem(false);
    }
  };

  const uploadLocationImage = async (base64: string) => {
    try {
      setImageUploading(true);
      const file = {
        uri: `${getRandomNumbers().toString(36).slice(2, 7)}.jpg`,
        base64: base64,
      };
      const res = await gqlService?.mutation<UploadImageUrlResponse>({
        mutation: UPLOAD_IMAGE,
        fetchPolicy: 'network-only',
        variables: {file},
      });
      if (res) return res?.data?.itemImageUpload?.uri;
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setImageUploading(false);
    }
  };

  const scanUPCForCrowdSource = async (
    params: ScanUPCParams,
    callback: any,
  ) => {
    try {
      setUpdatingUPC(true);
      const res = await gqlService?.mutation({
        mutation: SCAN_UPC_FOR_CROWD_SOURCE,
        fetchPolicy: 'network-only',
        variables: params,
      });

      if (res?.data) {
        const message = res?.data?.message;
        if (message) throw new Error(message);
        else callback();
      }
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setUpdatingUPC(false);
    }
  };

  return {
    getCatalogItemForShopper,
    loadingItem,
    markItemAsScanned,
    markingItem,
    substituteItemInUPC,
    uploadLocationImage,
    imageUploading,
    scanUPCForCrowdSource,
    updatingUPC,
  };
}

export function useGetUpc() {
  const [preLoadedUpcs, setPreLoadedUpcs] = useState<string[]>([]);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const getUpc = async (storeIndex: string, catalogItemId: number) => {
    try {
      const response = await gqlService?.query({
        query: GET_UPC_IDS_FOR_CATEGORIES,
        fetchPolicy: 'network-only',
        variables: {
          storeIndex,
          catalogItemId,
        },
      });
      setPreLoadedUpcs(response?.data?.getUpcIdsForCatalogItem || []);
    } catch (error: any) {
      showErrorToast(error.message);
    }
  };

  return {preLoadedUpcs, getUpc};
}
