export interface DeepLinkParams {
  screenName: string;
  screenPayload?: any;
  targetUrl?: string;
  navigateTo: NavigateTo;
}

// eslint-disable-next-line no-shadow
export enum NavigateTo {
  webview = 'webview',
  run = 'run',
  screen = 'screen',
  intercomCarousel = 'intercomCarousel',
  none = 'none',
}
