import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {Box} from '@buncha/components/core/view';
import {PSmallText} from '@buncha/components/core/text';
import React from 'react';
import {styles} from './styles';
import {useItemDetailsContext} from '@buncha/itemDetails/hooks/ItemDetails';

const AlcoholDisclaimer = () => {
  const theme = useColorThemeType();
  const fontTheme = useFontTheme();
  const {state} = useItemDetailsContext();
  const {constants} = state;

  if (!constants?.SHOPPER_ALCOHOL_DISCLAIMER) return <></>;
  return (
    <Box>
      <PSmallText
        style={[
          styles.disclaimer,
          {
            fontFamily: fontTheme?.[600]?.normal,
            color: theme?.text?.red?.[100],
          },
        ]}>
        {constants?.SHOPPER_ALCOHOL_DISCLAIMER}
      </PSmallText>
    </Box>
  );
};

export default React.memo(AlcoholDisclaimer);
