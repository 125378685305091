import {DefaultContext} from '../../utils/types';
import React from 'react';
import {State} from '../types/deliveryDetails';

export const initialState: State = {
  deliveryImages: [],
};

const defaultVal: DefaultContext<State> = {
  state: initialState,
  dispatch: null,
};

export const DeliveryDetailsContext = React.createContext(defaultVal);
