import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 20,
  },
  headerText: {
    textAlign: 'left',
    marginBottom: 10,
  },
  shiftDetailText: {
    textAlign: 'left',
    marginBottom: 15,
  },
  summeryText: {
    textAlign: 'left',
  },
  summeryContainer: {
    marginBottom: 15,
  },
  syncText: {
    paddingLeft: 10,
  },
});
