import React from 'react';

interface DeliveryImagesComponentProps {
  isOpen: boolean;
  onClose: Function;
  orderId: number;
  deliveryLocationId: number;
}

const DeliveryImagesComponent: React.FC<DeliveryImagesComponentProps> = () => {
  return <></>;
};

export default React.memo(DeliveryImagesComponent);
