import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  divider: {
    marginVertical: 10,
  },
  mainContainer: {
    paddingVertical: 5,
  },
});
