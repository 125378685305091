import {EventName} from './type';

export default class Listener {
  static events: {[key: string]: {id: number; callback: Function}[]} = {};

  getEvents(name: string) {
    return Listener.events[name] || [];
  }

  addListener(name: EventName, callback: Function) {
    const events = Listener.events;
    const len = Listener.events[name]?.length || 0;
    const obj = {id: len + 1, callback};
    if (name in events) Listener.events[name].push(obj);
    else Listener.events[name] = [obj];
    return () => {
      this.removeListener(len + 1, name);
    };
  }

  removeListener(id: number, name: string) {
    let events = this.getEvents(name);
    events = events.filter(event => event.id !== id);
    Listener.events[name] = events;
  }

  emit(name: EventName, params?: any) {
    let events = this.getEvents(name);
    events.forEach(event => {
      event.callback(params);
    });
  }
}
