import {StyleSheet} from 'react-native';

const VanLoadAtHeaderStyle = StyleSheet.create({
  headerBox: {
    flexDirection: 'row',
    alignSelf: 'center',
    borderWidth: 1,
  },
  headerChildBox: {
    flex: 1,
    padding: 20,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  backHeaderLeftContainer: {
    padding: 0,
    marginRight: 10,
  },
  storeLogoContainer: {
    borderColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bunchaYellowVan: {
    position: 'absolute',
    bottom: -10,
  },
  storeDetailsContainer: {
    alignItems: 'flex-start',
    paddingLeft: 20,
    borderColor: 'white',
    flex: 1,
    paddingVertical: 10,
  },
  startRouteContainer: {
    borderColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
  routeContainer: {
    height: 27,
    justifyContent: 'center',
    alignItems: 'center',
  },
  LoadVanText: {
    paddingBottom: 5,
  },
  titleText: {
    textAlign: 'left',
  },
});

export {VanLoadAtHeaderStyle};
