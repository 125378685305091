import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import BatchOrderItem from '../BatchOrderItem';
import {Box} from '../../../components/core/view';
import {H5Text} from '../../../components/core/text';
import {OrderItems} from '../../../batchDetails/types';
import React from 'react';
import {styles} from './styles';

interface RecheckSheetItemProps {
  orderItem: OrderItems;
  title: string;
}

function RecheckSheetItem(props: RecheckSheetItemProps) {
  const {orderItem, title} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  return (
    <Box>
      <H5Text
        style={[
          styles.nameText,
          {
            color: theme?.text.blue[300],
            fontFamily: muliFont[700].normal,
          },
        ]}>
        {title}
      </H5Text>
      <Box>
        <BatchOrderItem
          esIndex=""
          orderItem={orderItem}
          rightIcon={<Box />}
          onClick={() => {
            return;
          }}
          disableOnclick
        />
      </Box>
    </Box>
  );
}

export default React.memo(RecheckSheetItem);
