import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';
import If from '@buncha/components/conditional/If';
import {
  CustomText,
  H1Text,
  H6Text,
  PSmallText,
} from '@buncha/components/core/text';
import {Touchable} from '@buncha/components/core/touchable';
import {Box, HBox} from '@buncha/components/core/view';
import {navigation} from '@buncha/config/navigation';
import {HomeScreens} from '@buncha/home/navigation/types';
import {Memo} from '@buncha/memos/types';
import {MainScreens} from '@buncha/navigation/types';
import {Avatar, Divider} from 'native-base';
import React, {Fragment, useCallback} from 'react';
import {Dimensions, StyleSheet} from 'react-native';

interface Props {
  memo: Memo;
  divider?: boolean;
}

const MemoRow = (props: Props) => {
  const {memo} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme();
  const {createdUser, formatedCreatedAt, memoConfig} = memo;
  const title =
    memoConfig.find(config => config.property === 'title')?.content || '';
  let body =
    memoConfig.find(config => config.property === 'body')?.content || '';

  if (body.length > 150) body = body.substring(0, 150) + '...';

  const handleNavigation = useCallback(() => {
    navigation.navigate(MainScreens.Home, {
      screen: HomeScreens.MemoDetails,
      params: {
        memoId: memo.id,
      },
    });
  }, [memo.id]);

  const {firstName, lastName, avatar} = createdUser;
  return (
    <Fragment>
      <Touchable onPress={handleNavigation}>
        <HBox style={styles.containerBox}>
          <Avatar size={10} backgroundColor={theme?.background.yellow[200]}>
            <H1Text>{avatar}</H1Text>
          </Avatar>
          <Box style={styles.innerContainer}>
            <HBox style={styles.detailsBox}>
              <H6Text
                style={{
                  fontFamily: muliFont[700].normal,
                  color: theme?.text.blue[300],
                }}>{`${firstName} ${lastName?.[0] || ''}.`}</H6Text>
              <PSmallText style={{fontFamily: muliFont[600].normal}}>
                {formatedCreatedAt}
              </PSmallText>
            </HBox>
            <CustomText
              style={[
                styles.textAlignLeft,
                {
                  fontFamily: muliFont[600].normal,
                  color: theme?.text.blue[300],
                },
              ]}>
              {title}
            </CustomText>
            <CustomText
              style={[
                styles.bodyText,
                {
                  fontFamily: muliFont[600].normal,
                  color: theme?.text.gray[100],
                },
              ]}>
              {body}
            </CustomText>
          </Box>
        </HBox>
      </Touchable>
      <If condition={props.divider}>
        <Divider style={styles.divider} />
      </If>
    </Fragment>
  );
};

export default React.memo(MemoRow);

const styles = StyleSheet.create({
  textAlignLeft: {
    textAlign: 'left',
    maxWidth: Dimensions.get('screen').width * 0.8 - 10,
  },
  detailsBox: {
    marginBottom: 5,
  },
  containerBox: {
    alignItems: 'flex-start',
    paddingVertical: 10,
    maxWidth: Dimensions.get('screen').width * 0.85 - 10,
  },
  innerContainer: {
    width: Dimensions.get('screen').width * 0.8 - 20,
    paddingLeft: 10,
  },
  bodyText: {
    maxWidth: Dimensions.get('screen').width * 0.8 - 10,
    textAlign: 'left',
  },
  divider: {
    marginVertical: 10,
  },
});
