import {Box, HBox} from '../../../components/core/view';
import {ChevronDownIcon, ChevronUpIcon, Divider} from 'native-base';
import {CustomText, H5Text, H6Text} from '../../../components/core/text';
import {Dimensions, StyleSheet} from 'react-native';
import {OrderItem, PastOrder} from '../../../home/types';
import React, {useCallback} from 'react';
import {formatCurrency, getWidth} from '../../../utils/common';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {Accordian} from '../../../components/composites';
import {CustomFlatList} from '../../../components/core/list';
import If from '../../../components/conditional/If';
import ImageWithQuantityPill from '../../../common/components/ImageWithQuantityPill';
import LightGreyPill from '../LightGreyPill';
import {OrderItemStatus} from '../../../batchDetails/types';
import ProfileIcon from '../../../components/core/icon/ProfileIcon';
import UserNameUpperPill from '@buncha/common/components/VipProfileIcon';

interface AccordianCellProps {
  order: PastOrder;
}

const AccordianCell = (props: AccordianCellProps) => {
  const {order} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');

  const {buyer, items} = order;
  const firstName = buyer.firstName || '';
  const lastName = buyer.lastName || '';
  const isVip = buyer?.isVip ?? false;

  const itemCount = items?.length;
  const ordervalue = order.runBudget;

  const getStatus = useCallback(
    (status: OrderItemStatus, substitutedBy: any) => {
      let color = theme?.text.green[100];
      let text = 'Picked';
      if (status === OrderItemStatus.dropped) {
        color = theme?.text.red[100];
        text = 'Dropped';
      }
      if (substitutedBy) {
        color = theme?.text.orange[100];
        text = ' Substituted';
      }

      return (
        <CustomText
          style={{
            fontFamily: muliFont[900].normal,
            color: color,
          }}>
          {text}
        </CustomText>
      );
    },
    [muliFont, theme?.text.green, theme?.text.orange, theme?.text.red],
  );

  const renderItemCell = useCallback(
    ({item}: {item: OrderItem; index: number}) => {
      let substituteItem = item.substitutedBy?.name;
      return (
        <Box>
          <HBox
            style={[
              styles.itemCellContainer,
              {
                maxWidth: item.isCustomItem
                  ? getWidth(Dimensions.get('screen').width * 0.37)
                  : getWidth(Dimensions.get('screen').width * 0.65),
              },
            ]}>
            <ImageWithQuantityPill
              imageLink={item.itemImageUrl}
              quantity={item.quantity}
            />
            <Box style={[styles.itemDetailBox]}>
              <H5Text
                numberOfLines={4}
                style={[
                  styles.textAlignLeft,
                  {
                    fontFamily: muliFont[700].normal,
                    color: theme?.text.blue[300],
                  },
                ]}>
                {item.name}
              </H5Text>
              <H5Text
                style={[
                  styles.marginLeft,
                  {
                    fontFamily: muliFont[700].normal,
                    color: theme?.text.blue[300],
                  },
                ]}>
                {formatCurrency(item.price)}
              </H5Text>
              <CustomText>
                Status: {getStatus(item.itemStatus, item.substitutedBy)}
              </CustomText>
            </Box>
            <If condition={item.isCustomItem}>
              <LightGreyPill text="Custom Item" />
            </If>
          </HBox>
          <If condition={substituteItem}>
            <HBox style={styles.containerFlexStart}>
              <H6Text
                style={[
                  styles.textAlignLeft,
                  {
                    fontFamily: muliFont[700].normal,
                    color: theme?.text.blue[300],
                  },
                ]}>
                Substituted By:{' '}
                <H6Text style={[styles.textAlignLeft, styles.maxWidth]}>
                  {substituteItem}
                </H6Text>
              </H6Text>
            </HBox>
          </If>
        </Box>
      );
    },
    [getStatus, muliFont, theme?.text.blue],
  );

  const separator = useCallback(
    () => (
      <Divider
        style={[
          styles.marginTop,
          {
            backgroundColor: theme?.text.gray[200],
          },
        ]}
      />
    ),
    [theme?.text.gray],
  );

  return (
    <Accordian
      isExpanded={true}
      header={
        <HBox style={styles.accordianHeader}>
          <ProfileIcon viewBox="-3 -1 25 25" fill={theme?.text.blue[300]} />
          <Box style={styles.customerDetailBox}>
            <If condition={isVip}>
              <UserNameUpperPill text={'VIP Customer'} />
            </If>
            <H6Text
              style={[
                styles.textAlignLeft,
                {
                  fontFamily: muliFont[700].normal,
                  color: theme?.text.blue[300],
                },
              ]}>{`${firstName} ${lastName}`}</H6Text>
            <CustomText
              style={[
                styles.textAlignLeft,
                {
                  fontFamily: muliFont[600].normal,
                  color: theme?.text.gray[100],
                },
              ]}>{`${itemCount} Item(s) | ${formatCurrency(
              ordervalue,
            )}(after discounts)`}</CustomText>
          </Box>
          <LightGreyPill text={itemCount.toString()} />
        </HBox>
      }
      children={
        <Box style={styles.marginTop}>
          <CustomFlatList
            data={order.items}
            renderItem={renderItemCell}
            ItemSeparatorComponent={separator}
          />
        </Box>
      }
      iconEnable={<ChevronUpIcon color={theme?.text.blue[300]} />}
      iconDisable={<ChevronDownIcon color={theme?.text.blue[300]} />}
    />
  );
};

const styles = StyleSheet.create({
  accordianHeader: {
    width: '95%',
    paddingRight: 10,
    alignItems: 'flex-start',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  customerDetailBox: {
    flex: 1,
    marginHorizontal: 10,
    alignItems: 'flex-start',
  },
  itemCellContainer: {
    marginVertical: 10,
    alignItems: 'flex-start',
  },
  itemDetailBox: {
    alignItems: 'flex-start',
    width: '100%',
    marginHorizontal: 10,
  },
  containerFlexStart: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  maxWidth: {
    maxWidth: getWidth(Dimensions.get('window').width * 0.7),
  },
  marginLeft: {
    marginVertical: 5,
  },
  marginTop: {
    marginTop: 10,
  },
});

export default React.memo(AccordianCell);
