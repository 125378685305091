import {Analytics} from './analytics';

export class Intercom {
  sendTokenToIntercom(_dummy: any) {
    // Dummy for web
  }

  displayMessenger() {
    Analytics.displayMessenger();
  }
}

export const intercom = new Intercom();
