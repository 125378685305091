import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const PhotosAdded: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        d="M40 28V4C40 1.8 38.2 0 36 0H12C9.8 0 8 1.8 8 4V28C8 30.2 9.8 32 12 32H36C38.2 32 40 30.2 40 28ZM18.8 21.06L22.06 25.42L27.22 18.98C27.3137 18.8632 27.4324 18.7689 27.5674 18.7042C27.7024 18.6394 27.8503 18.6058 28 18.6058C28.1497 18.6058 28.2976 18.6394 28.4326 18.7042C28.5676 18.7689 28.6863 18.8632 28.78 18.98L34.7 26.38C35.22 27.04 34.76 28 33.92 28H14C13.8143 28 13.6322 27.9483 13.4743 27.8507C13.3163 27.753 13.1886 27.6133 13.1056 27.4472C13.0225 27.2811 12.9874 27.0952 13.004 26.9102C13.0207 26.7252 13.0886 26.5486 13.2 26.4L17.2 21.06C17.6 20.54 18.4 20.54 18.8 21.06ZM0 10V36C0 38.2 1.8 40 4 40H30C31.1 40 32 39.1 32 38C32 36.9 31.1 36 30 36H6C4.9 36 4 35.1 4 34V10C4 8.9 3.1 8 2 8C0.9 8 0 8.9 0 10Z"
        fill={props?.fill}
      />
    </Icon>
  );
};

export default PhotosAdded;
