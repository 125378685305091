import * as React from 'react';

import Svg, {Path, SvgProps} from 'react-native-svg';
const StoreLocation = (props: SvgProps) => (
  <Svg width={19} height={18} fill="none" {...props}>
    <Path
      fill="#100E3A"
      d="M17 2H1V0h16v2Zm-7 9.5c0 1.14.43 2.36 1 3.5v1H1v-6H0V8l1-5h16l.7 3.5c-.7-.32-1.42-.5-2.2-.5-3 0-5.5 2.5-5.5 5.5ZM9 10H3v4h6v-4Zm10 1.5c0 2.6-3.5 6.5-3.5 6.5S12 14.1 12 11.5C12 9.6 13.6 8 15.5 8S19 9.6 19 11.5Zm-2.3.1c0-.6-.6-1.2-1.2-1.2s-1.2.5-1.2 1.2c0 .6.5 1.2 1.2 1.2s1.3-.6 1.2-1.2Z"
    />
  </Svg>
);
export default StoreLocation;
