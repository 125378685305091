import {
  Analytics,
  AnalyticsPlatform,
} from '../../../config/analytics/analytics';
import {Box, HBox} from '../../../components/core/view';
import {ChevronLeftIcon, ChevronRightIcon} from 'native-base';
import {Dimensions, Image, StyleSheet} from 'react-native';
import React, {useCallback, useMemo, useRef} from 'react';

import {BottomButton} from '../Button';
import {CarousalProps} from './types';
import {CommonScreens} from '../../../common/navigation/types';
import {H3Text} from '../../../components/core/text';
import If from '../../../components/conditional/If';
import {MainScreens} from '../../../navigation/types';
import Pagination from '../Pagination/Pagination';
import {Touchable} from '../../../components/core/touchable';
import font from '../../../appStyles/style/font';
import {isWeb} from '../../../utils/common';
import {navigation} from '../../../config/navigation';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const {width} = Dimensions.get('window');

interface Props extends Omit<CarousalProps, 'renderItem'> {
  renderItem: Function;
}

const UpdatePriceCarousal = (props: Props) => {
  const {data, imageCaption, showPagination, showImageCaption} = props;
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const theme = useColorThemeType();

  const touchX = useRef(0);
  const touchY = useRef(0);

  const scroll = useCallback((index: number) => {
    setActiveIndex(index);
  }, []);

  const onBackScroll = useCallback(() => {
    scroll(activeIndex ? activeIndex - 1 : 0);
  }, [activeIndex, scroll]);

  const onNextScroll = useCallback(() => {
    scroll(activeIndex < data?.length ? activeIndex + 1 : activeIndex);
  }, [activeIndex, data?.length, scroll]);

  const onSwipeTab = useCallback(
    (direction: 'next' | 'previous') => {
      switch (direction) {
        case 'next':
          onNextScroll();
          break;
        case 'previous':
          onBackScroll();
          break;
        default:
      }
    },
    [onBackScroll, onNextScroll],
  );

  const onTouchStart = useCallback((e: any) => {
    const {pageX, pageY} = e.nativeEvent;
    touchX.current = pageX;
    touchY.current = pageY;
  }, []);

  const onTouchEnd = useCallback(
    (e: any) => {
      const {pageX, pageY} = e.nativeEvent;
      const diffX = touchX.current - pageX;
      const absDiffY = Math.abs(touchY.current - pageY);
      if (absDiffY < 10)
        if (diffX > 10) onSwipeTab('next');
        else if (diffX < -10) onSwipeTab('previous');
    },
    [onSwipeTab],
  );

  const getPagination = useMemo(() => {
    return (
      <Pagination
        paginationSize={props.paginationSize}
        disabledPaginationColor={props.disabledPaginationColor}
        key={activeIndex}
        data={data}
        activeIndex={activeIndex}
      />
    );
  }, [activeIndex, data, props.disabledPaginationColor, props.paginationSize]);

  const viewItemImage = useCallback((itemImageUrl: string) => {
    if (isWeb()) return;
    Analytics.eventWithProps(
      'View Item Image',
      {
        imageUrl: itemImageUrl,
      },
      AnalyticsPlatform.all,
    );

    navigation.navigate(MainScreens.Common, {
      screen: CommonScreens.ImagePreview,
      params: {imageUrl: itemImageUrl},
    });
  }, []);

  const renderItem = useCallback(
    (info: {item: any; index: any}) => {
      const {item, index} = info;
      return (
        <Touchable onPress={() => viewItemImage(item)}>
          <Box
            key={index}
            style={{
              width: width * 0.9,
            }}>
            <Box style={props.containerStyle}>
              <Image source={{uri: item}} style={props.imageContainerStyle} />
            </Box>
          </Box>
        </Touchable>
      );
    },
    [props.containerStyle, props.imageContainerStyle, viewItemImage],
  );

  return (
    <Box style={styles.container}>
      <Box
        onTouchCancel={onTouchEnd}
        onTouchEnd={onTouchEnd}
        onTouchStart={onTouchStart}>
        {data.map(
          (item: any, index: number) =>
            index === activeIndex && (props.renderItem || renderItem)(item),
        )}
      </Box>
      <If condition={showImageCaption}>
        <Box style={[styles.imageCaptionContainer]}>
          <H3Text style={[styles.captionTypo]}>{imageCaption}</H3Text>
        </Box>
      </If>
      <If condition={showPagination}>{getPagination}</If>
      <If condition={props.nextButton}>
        <HBox style={styles.buttonContainers}>
          <BottomButton
            disabled={activeIndex === 0}
            style={styles.height}
            containerStyle={styles.buttonContainer}
            onPress={onBackScroll}
            title={'Prev'}
            titleStyle={styles.titleStyle}
            titleColor={theme?.text?.green?.[100]}
            borderWidth={1}
            borderColor={theme?.text?.green?.[100]}
            borderDisableColor={theme?.text.green[200]}
            bgColor={theme?.background.white[100]}
            leftComponent={
              <Box style={styles.iconStyle}>
                <ChevronLeftIcon
                  style={{color: theme?.background.green[100]}}
                />
              </Box>
            }
          />
          <BottomButton
            disabled={activeIndex === data?.length - 1}
            containerStyle={styles.buttonContainer}
            titleStyle={styles.titleStyle}
            style={styles.height}
            onPress={onNextScroll}
            title={'Next'}
            rightComponent={
              <Box style={styles.iconStyle}>
                <ChevronRightIcon
                  style={{color: theme?.background.white[100]}}
                />
              </Box>
            }
          />
        </HBox>
      </If>
    </Box>
  );
};

export default React.memo(UpdatePriceCarousal);

const styles = StyleSheet.create({
  buttonContainer: {
    width: '45%',
  },
  height: {
    height: 45,
  },
  iconStyle: {marginRight: 5},
  buttonContainers: {
    maxWidth: width,
    marginTop: 10,
  },
  titleStyle: {marginBottom: 1},
  imageCaptionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    flex: 1,
  },
  captionTypo: {
    ...font.FontSize.fontH6,
    opacity: 0.5,
  },
  container: {flex: 1, justifyContent: 'center', alignItems: 'center'},
});
