import {Dimensions, Platform, StyleSheet} from 'react-native';

import {getWidth} from '../../utils/common';

export const styles = StyleSheet.create({
  parentBox: {
    paddingHorizontal: 20,
    paddingTop: 15,
  },
  header: {
    textAlign: 'left',
  },
  inputContainer: {
    borderWidth: 1,
    width: '100%',
    borderRadius: 40,
    marginTop: 20,
  },
  inputLeftComponent: {
    paddingLeft: 20,
  },
  notesInput: {
    padding: 10,
    paddingTop: 15,
    height: 150,
  },
  bottomContainer: {
    width: '100%',
    paddingTop: 10,
    justifyContent: 'center',
    margin: 0,
    bottom: 0,
  },
  submitButton: {
    ...(Platform.OS === 'web'
      ? {
          width: Number(getWidth(Dimensions.get('window').width)) - 200,
          padding: 15,
          marginTop: 25,
          marginLeft: 10,
        }
      : {
          marginLeft: 10,
        }),
  },
  buttonContainer: {
    width: '60%',
  },
  imageUploadContainer: {
    paddingVertical: 20,
    justifyContent: 'flex-start',
  },
});
