export function useGetLocation() {
  const coordinates = undefined;

  const getLocationWithPermission = async () => {
    // not needed for web
  };
  return {coordinates, getLocationWithPermission};
}

export const getBase64FromURI = async (_fileUri: string) => {
  // not needed for web
};

const createHar = async (_requests: any[], _userId: string, _count: number) => {
  // not needed for web
};

export default createHar;
