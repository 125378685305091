import {
  COMPLETE_VAN_INSPECTION,
  GET_ORDER_ASSIGNEE,
  SET_DELIVERY_LOCATION_STATUS_ORDERS,
  UPDATE_UPC_ORDER_STATUS,
  UPLOAD_VAN_INSPECTION_IMAGE,
} from '../../config/graphQL/documentNode/mutation';
import {
  CompleteVanInspectionResponse,
  DeliveryLocation,
  DeliveryLocationStatus,
  GetItemStatusCountResponse,
  GetMinimumDeliveryStatusResponse,
  ItemCheckBeforePacking,
  ItemCheckBeforePackingResponse,
  OrderItemStatus,
  SetDeliveryLocationStatusOrdersResponse,
  UpcOrderStatus,
  UploadVanInspectionImageResponse,
} from '../types';
import {
  GET_ITEM_STATUS_COUNT,
  GET_MINIMUM_DELIVERY_STATUS,
  ITEM_CHECK_BEFORE_PACKING,
} from '../../config/graphQL/documentNode/queries';
import React, {Dispatch, useCallback, useState} from 'react';

import {Analytics} from '@buncha/config/analytics/analytics';
import {ApiState} from '../../common/types/common';
import {ToastType} from '../../components/composites/notification/type';
import {UserTypes} from '@buncha/onBoarding/types';
import {batchDetailActionsLocal} from './batchDetail';
import {getRandomNumbers} from '@buncha/utils/common';
import {gqlService} from '../../config/graphQL';
import listener from '../../config/listener';
import {useToastMessage} from '../../components/composites/notification';

export function useSetDeliveryLocationStatusOfAssignedOrders(
  dispatch: React.Dispatch<any> | null,
): [
  (
    runId: number,
    status: DeliveryLocationStatus,
    orderIds: number[],
    navigateToOrderPack: () => void,
  ) => Promise<void>,
  ApiState<DeliveryLocation[]>,
] {
  const [getMinimumDeliveryStatus] = useGetMinimumDeliveryStatus(dispatch);
  const [state, setState] = useState<ApiState<DeliveryLocation[]>>({
    loading: false,
    error: undefined,
    data: undefined,
  });
  const [showMessage] = useToastMessage(ToastType.Error);

  const setDeliveryLocationStatusOfAssignedOrders = useCallback(
    async (
      runId: number,
      status: DeliveryLocationStatus,
      orderIds: number[],
      navigateToOrderPack: () => void,
    ) => {
      setState({...state, loading: true});
      try {
        const minOrderStatus = await getMinimumDeliveryStatus(runId);

        if (
          minOrderStatus === DeliveryLocationStatus.notStarted &&
          status !== DeliveryLocationStatus.readyToPack
        ) {
          if (dispatch)
            dispatch(batchDetailActionsLocal.setPickedOrderFoundModal(true));
          setState({...state, loading: false});
        } else {
          if (dispatch)
            dispatch(
              batchDetailActionsLocal.setDisablePickingRadioButton(true),
            );
          const response =
            await gqlService?.mutation<SetDeliveryLocationStatusOrdersResponse>(
              {
                mutation: SET_DELIVERY_LOCATION_STATUS_ORDERS,
                variables: {
                  runId: runId,
                  status: status,
                  orderIds: orderIds,
                },
                fetchPolicy: 'network-only',
              },
            );
          if (response?.data?.setDeliveryLocationStatusOfAssignedOrders) {
            if (status === DeliveryLocationStatus.toBeAssigned)
              Analytics.eventWithProps('Update delivery location status', {
                type: 'CTA',
                status: DeliveryLocationStatus.toBeAssigned,
                userType: 'shopper',
              });
            listener.emit('orderPackRefresh');
            setState({
              ...state,
              data: response?.data.setDeliveryLocationStatusOfAssignedOrders,
              loading: false,
            });
            navigateToOrderPack();
          }
        }
      } catch (error: any) {
        showMessage(error.message);
        setState({...state, loading: false});
      }
    },
    [dispatch, getMinimumDeliveryStatus, showMessage, state],
  );

  return [setDeliveryLocationStatusOfAssignedOrders, state];
}

export function useGetItemStatusCount(
  dispatch: React.Dispatch<any> | null,
): [
  (
    runId: number,
    itemStatuses: OrderItemStatus[],
  ) => Promise<number[] | undefined>,
  ApiState<number[]>,
] {
  const [state, setState] = useState<ApiState<number[]>>({
    loading: false,
    error: undefined,
    data: undefined,
  });
  const [showMessage] = useToastMessage(ToastType.Error);

  const getItemStatusCount = useCallback(
    async (runId: number, itemStatuses: OrderItemStatus[]) => {
      setState({...state, loading: true});
      try {
        const response = await gqlService?.query<GetItemStatusCountResponse>({
          query: GET_ITEM_STATUS_COUNT,
          variables: {
            runId: runId,
            itemStatuses: itemStatuses,
          },
          fetchPolicy: 'network-only',
        });
        if (response?.data?.getItemStatusCount) {
          if (dispatch)
            dispatch(
              batchDetailActionsLocal.setRemainingItemCount(
                response?.data?.getItemStatusCount,
              ),
            );
          setState({
            ...state,
            data: response?.data.getItemStatusCount,
            loading: false,
          });
          return response?.data?.getItemStatusCount;
        }
      } catch (error: any) {
        showMessage(error.message);
        setState({...state, loading: false});
      }
    },
    [dispatch, showMessage, state],
  );

  return [getItemStatusCount, state];
}

export function useItemCheckBeforePacking(): [
  (runId: number) => void,
  ApiState<ItemCheckBeforePacking>,
] {
  const [state, setState] = useState<ApiState<ItemCheckBeforePacking>>({
    loading: false,
    error: undefined,
    data: undefined,
  });
  const [showMessage] = useToastMessage(ToastType.Error);

  const itemCheckBeforePacking = useCallback(
    async (runId: number) => {
      setState({...state, loading: true});
      try {
        const response =
          await gqlService?.query<ItemCheckBeforePackingResponse>({
            query: ITEM_CHECK_BEFORE_PACKING,
            variables: {
              getForAllAssignedOrders: true,
              runId: runId,
            },
            fetchPolicy: 'network-only',
          });
        if (response?.data?.itemCheckBeforePacking)
          setState({
            ...state,
            data: response?.data.itemCheckBeforePacking,
            loading: false,
          });
      } catch (error: any) {
        showMessage(error.message);
        setState({...state, loading: false});
      }
    },
    [showMessage, state],
  );

  return [itemCheckBeforePacking, state];
}

export function useOrderAssignment(dispatch: Dispatch<any> | null) {
  const getOrderAssignee = async (runId: number) => {
    try {
      const res = await gqlService?.mutation({
        mutation: GET_ORDER_ASSIGNEE,
        fetchPolicy: 'network-only',
        variables: {
          runId: runId,
        },
      });
      if (dispatch && res?.data)
        dispatch(
          batchDetailActionsLocal.setOrderAssignee(
            res?.data?.countForOrdersAssignedToDriver,
          ),
        );
    } catch (error: any) {}
  };

  return {getOrderAssignee};
}

export function useUPCOrder() {
  const [showMessage] = useToastMessage(ToastType.Error);
  const [showSuccessMessage] = useToastMessage(ToastType.Success);
  const updateOrderStatus = async (upcOrderId: number, callback: any) => {
    try {
      const res = await gqlService?.mutation({
        mutation: UPDATE_UPC_ORDER_STATUS,
        fetchPolicy: 'network-only',
        variables: {
          upcOrderId: upcOrderId,
          status: UpcOrderStatus.completed,
        },
      });
      if (res?.data) {
        showSuccessMessage('Order done successfully');
        callback();
      }
    } catch (error: any) {
      showMessage(error.message);
    }
  };

  return {updateOrderStatus};
}

export function useVanDeliveryImages() {
  const [vanDeliveryImageLoading, setLoading] = useState(false);
  const [vanImages, setVanImages] = useState<string[]>([]);
  const [showErrorMessage] = useToastMessage(ToastType.Error);

  const uploadVanImage = async (base64: string, runId: number) => {
    try {
      setLoading(true);
      const res = await gqlService?.mutation<UploadVanInspectionImageResponse>({
        mutation: UPLOAD_VAN_INSPECTION_IMAGE,
        fetchPolicy: 'network-only',
        variables: {
          file: {
            uri: `${getRandomNumbers().toString(36).slice(2, 7)}.jpg`,
            base64: base64,
          },
          runId,
        },
      });
      const imageUrl = res?.data?.uploadVanInspectionImages?.uri || '';
      const images = [...vanImages, imageUrl];
      setVanImages(images);
    } catch (err: any) {
      showErrorMessage(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteVanImage = (img: string) => {
    setVanImages(images => images.filter(image => image !== img));
  };

  const confirmVanImages = async (shiftId?: string) => {
    if (!shiftId) return;
    try {
      setLoading(true);
      await gqlService?.mutation<CompleteVanInspectionResponse>({
        mutation: COMPLETE_VAN_INSPECTION,
        fetchPolicy: 'network-only',
        variables: {startDate: shiftId},
      });
    } catch (err: any) {
      showErrorMessage(err?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    vanDeliveryImageLoading,
    vanImages,
    uploadVanImage,
    deleteVanImage,
    confirmVanImages,
  };
}

export function useGetMinimumDeliveryStatus(
  dispatch: React.Dispatch<any> | null,
): [
  (
    runId: number,
    userTypes?: UserTypes,
  ) => Promise<DeliveryLocationStatus | undefined>,
  ApiState<DeliveryLocationStatus>,
] {
  const [state, setState] = useState<ApiState<DeliveryLocationStatus>>({
    loading: false,
    error: undefined,
    data: undefined,
  });
  const [showMessage] = useToastMessage(ToastType.Error);

  const getMinimumDeliveryStatus = useCallback(
    async (runId: number, userType: UserTypes = UserTypes.shopper) => {
      setState({...state, loading: true});
      try {
        const response =
          await gqlService?.query<GetMinimumDeliveryStatusResponse>({
            query: GET_MINIMUM_DELIVERY_STATUS,
            variables: {
              runId: runId,
              userType: userType,
            },
            fetchPolicy: 'network-only',
          });
        if (response?.data?.getMinimumDeliveryStatus) {
          if (dispatch)
            dispatch(
              batchDetailActionsLocal.getMinimumDeliveryStatus(
                response?.data?.getMinimumDeliveryStatus,
              ),
            );
          setState({
            ...state,
            data: response?.data.getMinimumDeliveryStatus,
            loading: false,
          });
          return response?.data?.getMinimumDeliveryStatus;
        }
      } catch (error: any) {
        showMessage(error.message);
        setState({...state, loading: false});
      }
    },
    [dispatch, showMessage, state],
  );

  return [getMinimumDeliveryStatus, state];
}
