/* eslint-disable no-console */
import {Dimensions, StyleSheet} from 'react-native';
import React, {useCallback, useRef, useState} from 'react';

import {AttachmentsPageParams} from '@buncha/memos/types';
import {storage} from '@buncha/config/storage';
import {useRoute} from '@react-navigation/native';

interface Props {
  url: string;
  goNext: () => void;
  handleVideoLoad: (val: number) => void;
}

const VideoPlayer = (props: Props) => {
  const {url, goNext, handleVideoLoad} = props;
  const videoRef = useRef<any | null>(null);
  const [duration, setDuration] = useState(1);
  const route = useRoute();
  const routeParams: {attachmentParams?: AttachmentsPageParams} | undefined =
    route?.params;

  const attachmentParams = routeParams?.attachmentParams;
  const memoId = attachmentParams?.memoId;

  const handleMemoRead = useCallback(async () => {
    const memoData = JSON.parse(
      (await storage.getItem(`memoData${memoId}`)) ?? '{}',
    );
    memoData[url] = true;
    storage.setItem(`memoData${memoId}`, JSON.stringify(memoData));
  }, [memoId, url]);

  const onProgress = useCallback(
    (data: any) => {
      console.log('data onProgress', data);
      const percentage = Math.floor((data.currentTime / duration) * 100);
      if (percentage > 80) handleMemoRead();
      handleVideoLoad(percentage);
    },
    [duration, handleMemoRead, handleVideoLoad],
  );

  const onLoad = useCallback((data: any) => {
    console.log('data onLoad', data);
    setDuration(Math.floor(data.duration));
  }, []);

  const handleEnd = useCallback(async () => {
    await handleMemoRead();
    goNext();
  }, [goNext, handleMemoRead]);

  return (
    <video
      onLoad={onLoad}
      onEnded={handleEnd}
      ref={videoRef}
      onProgress={onProgress}
      resource={url}
      style={styles.backgroundVideo}
    />
  );
};

export default React.memo(VideoPlayer);

const styles = StyleSheet.create({
  backgroundVideo: {
    position: 'absolute',
    top: 45,
    left: 0,
    bottom: 0,
    right: 0,
    height: Dimensions.get('window').height * 0.85,
    width: Dimensions.get('screen').width,
  },
});
