import {HomeScreens, ShopperScreens} from '../../../../home/navigation/types';
import React, {useCallback, useMemo, useState} from 'react';
import {
  useAddItemRemark,
  useConfig,
  useDropOrderItem,
  useUPCOrder,
} from '../../../../itemDetails/hooks/ItemDetails';

import AppModal from '../../../../common/components/CustomModal/AppModal';
import {Box} from 'native-base';
import Dropdown from '../../../../common/components/Dropdown/Dropdown';
import {H3Text} from '../../../../components/core/text';
import {StyleSheet} from 'react-native';
import font from '../../../../appStyles/style/font';
import {getWidth} from '../../../../utils/common';
import listener from '../../../../config/listener';
import {navigation} from '../../../../config/navigation';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {useColorThemeType} from '../../../../appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';

interface ModalBodyProps {
  onClose: any;
  orderItemId: number;
  disableNavigation?: boolean;
  disableState?: boolean;
  open: boolean;
  hasListnerReq?: boolean;
  isUPCMode?: boolean;
}

interface RouteParamsType {
  runId?: number;
}

const DropItemModalBody = (props: ModalBodyProps) => {
  const route = useRoute();
  const routeParams: RouteParamsType | undefined = route?.params;
  const runId = routeParams?.runId || 0;
  const theme = useColorThemeType('main');
  const {reasonList} = useConfig();
  const choiceConfirmed = false;
  const [selectedReason, setSelectedReason] = useState('');
  const {dropOrderItem, droppingItem} = useDropOrderItem();
  const {addItemRemark, loading} = useAddItemRemark();
  const {dropUPCItem, droppingItem: UPCDropping} = useUPCOrder();
  const {upcOrderItem} = useAppSelector(gState => gState.itemScanner);

  const setSelectedReasonHandler = useCallback(
    (reason: string) => {
      setSelectedReason(reason);
    },
    [setSelectedReason],
  );

  const dropItemAndMarkReason = useCallback(async () => {
    if (props?.isUPCMode) {
      const res = await dropUPCItem(upcOrderItem?.id ?? 0);
      if (res) navigation.goBack();
    } else {
      await dropOrderItem([props?.orderItemId]);
      await addItemRemark(props?.orderItemId, selectedReason);
      if (!props.disableNavigation)
        navigation.navigate(HomeScreens.Shopper, {
          screen: ShopperScreens.BatchDetailsPage,
          params: {
            runId: runId,
          },
        });
    }
    props?.onClose();
    if (props?.hasListnerReq) listener.emit('orderPackDropRefresh');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, selectedReason]);

  const getHeader = useMemo(() => {
    return choiceConfirmed ? 'Select reason for drop' : 'Are you sure?';
  }, [choiceConfirmed]);

  const getBody = useMemo(() => {
    return (
      <Box>
        <H3Text style={[styles.childTypo]}>
          {choiceConfirmed
            ? 'Please select the reason for dropping the item'
            : 'This will remove this item from this order. Are you sure?'}
        </H3Text>
        {choiceConfirmed && (
          <Box style={styles.dropDown}>
            <Dropdown
              data={reasonList}
              key="reason"
              value="reason"
              onChange={(val: any) => setSelectedReasonHandler(val)}
              selectedValue={selectedReason}
            />
          </Box>
        )}
      </Box>
    );
  }, [choiceConfirmed, reasonList, selectedReason, setSelectedReasonHandler]);

  return (
    <AppModal
      isOpen={props?.open}
      onClose={() => props?.onClose()}
      topCTAHandelr={dropItemAndMarkReason}
      bottomCTAHandelr={props.onClose}
      hideBottomCta={choiceConfirmed ? true : false}
      title={getHeader}
      modalBody={getBody}
      ctaBottomTitle={'Cancel'}
      ctaTopTitle={choiceConfirmed ? 'OK' : 'Yes, Drop'}
      topCTAColor={
        choiceConfirmed
          ? theme?.background.green[100]
          : theme?.background.red[100]
      }
      loading={droppingItem || UPCDropping || loading}
    />
  );
};

export default React.memo(DropItemModalBody);

const styles = StyleSheet.create({
  parentContainer: {
    padding: 20,
  },
  headerTypo: {
    fontFamily: font.fonts['Mortise-X-Bold'],
    ...font.FontSize.fontH2,
  },
  childContainer: {
    marginTop: 10,
  },
  childTypo: {
    ...font.FontSize.fontH6,
  },
  buttonContainer: {
    top: 15,
  },
  buttonStyle: {
    width: getWidth('100%'),
  },
  cancelButton: {
    top: -15,
    borderWidth: 1,
    width: getWidth('100%'),
  },
  dropDown: {marginTop: 15},
});
