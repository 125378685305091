import React, {useMemo} from 'react';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../../../src/appStyles/hooks/theme';

import {FailureReason} from '../../../../../../../src/driver/types/orderDetails';
import {H6Text} from '../../../../../../../src/components/core/text';
import {StyleSheet} from 'react-native';
import {Touchable} from '../../../../../../../src/components/core/touchable';

interface DeliveryFailedReasonCellProps {
  option: FailureReason;
  changeReason: (option: FailureReason) => void;
  selected: boolean;
}

const DeliveryFailedReasonCell: React.FC<
  DeliveryFailedReasonCellProps
> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const option = props?.option;

  const getColor = useMemo(() => {
    if (props?.selected) return theme?.text?.green?.[100];
    return theme?.text?.gray?.[100];
  }, [props?.selected, theme?.text?.gray, theme?.text?.green]);
  return (
    <Touchable
      style={styles.touchable}
      onPress={() => props?.changeReason(option)}>
      <H6Text
        style={[
          styles.text,
          {color: getColor, fontFamily: muli?.[700]?.normal},
        ]}
        alignText="left">
        {option?.reason}
      </H6Text>
    </Touchable>
  );
};

export default React.memo(DeliveryFailedReasonCell);
const styles = StyleSheet.create({
  touchable: {
    paddingVertical: 10,
  },
  text: {
    textDecorationLine: 'underline',
  },
});
