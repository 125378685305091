import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    marginTop: 15,
    justifyContent: 'center',
  },
  subContainer: {
    marginBottom: 10,
    justifyContent: 'flex-start',
  },
  textContainer: {
    marginLeft: 15,
  },
  text: {
    textAlign: 'left',
  },
});
