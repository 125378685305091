import {Box, HBox} from '../../components/core/view';

import BunchaWithoutName from '../../components/core/icon/BunchaWithoutName';
import {H5Text} from '../../components/core/text';
import React from 'react';
import {StyleSheet} from 'react-native';
import {useColorThemeType} from '../../appStyles/hooks/theme';

function EmptyActiveOrder() {
  const defaultTheme = useColorThemeType();
  return (
    <HBox style={styles.mainContainer}>
      <Box>
        <BunchaWithoutName
          width={115}
          height={115}
          fill={defaultTheme?.icon.yellow[200]}
          viewBox="0 0 120 120"
          style={styles.iconStyle}
        />
        <H5Text
          style={[
            styles.subText,
            {
              color: defaultTheme?.text.blue[300],
            },
          ]}>
          Seems like there are no active orders. Start an assigned order to view
          your active orders here.
        </H5Text>
      </Box>
    </HBox>
  );
}

export default React.memo(EmptyActiveOrder);

const styles = StyleSheet.create({
  mainContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  subText: {
    marginHorizontal: 60,
    marginTop: 10,
  },
  iconStyle: {
    alignSelf: 'center',
  },
});
