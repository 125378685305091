import React, {useCallback, useMemo, useState} from 'react';
import {
  useAddNotesForItem,
  useItemDetailsContext,
} from '../../../../../src/itemDetails/hooks/ItemDetails';

import AppModal from '../../../../common/components/CustomModal/AppModal';
import {PTextInput} from '../../../../../src/components/core/input/TextInput';
import {inputNotesStyles} from './styles';
import {useColorThemeType} from '../../../../../src/appStyles/hooks/theme';

interface ItemNotesModalProps {
  orderItemId: number;
  isOpen: boolean;
  onClose: () => void;
}

const ItemNotesModal: React.FC<ItemNotesModalProps> = props => {
  const {state} = useItemDetailsContext();
  const addedNotes = state?.itemNotes;
  const theme = useColorThemeType('main');
  const orderItemId = props?.orderItemId || 0;
  const [itemNotes, setItemNotes] = useState(addedNotes);
  const {addNotesForItem, loading} = useAddNotesForItem();

  const addNotes = useCallback(async () => {
    if (!itemNotes || !orderItemId) return;
    await addNotesForItem(orderItemId, itemNotes);
    props?.onClose();
  }, [addNotesForItem, itemNotes, orderItemId, props]);

  const handleItemNotesChange = useCallback(
    (text: string) => setItemNotes(text),
    [],
  );

  const getBorderColor = useMemo(() => {
    if (!itemNotes) return theme?.text?.gray?.[200];
    return theme?.text?.blue?.[300];
  }, [itemNotes, theme?.text?.blue, theme?.text?.gray]);

  return (
    <AppModal
      isOpen={props?.isOpen}
      onClose={() => props?.onClose()}
      topCTAHandelr={addNotes}
      title={'Add Notes'}
      modalBody={
        <PTextInput
          value={itemNotes}
          numberOfLines={10}
          placeholder="Add a note"
          textAlignVertical={'top'}
          multiline={true}
          debounce={false}
          returnKeyType="done"
          style={[inputNotesStyles.input, {color: theme?.text?.blue?.[300]}]}
          onChangeText={handleItemNotesChange}
          containerStyle={[
            inputNotesStyles.inputContainer,
            {
              borderColor: getBorderColor,
            },
          ]}
        />
      }
      ctaBottomTitle={'Cancel'}
      ctaTopTitle={'Add'}
      topCTAColor={theme?.background.green[100]}
      loading={loading}
      disableCtaTop={!itemNotes}
    />
  );
};

export default React.memo(ItemNotesModal);
