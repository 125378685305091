// eslint-disable-next-line no-shadow
export enum HomeScreens {
  YourSchedule = 'YourSchedule',
  AboutShift = 'AboutShift',
  Lockout = 'Lockout',
  OpenShifts = 'OpenShifts',
  Driver = 'Driver',
  PastRuns = 'PastRuns',
  PastOrders = 'PastOrders',
  Shopper = 'Shopper',
  Tutorials = 'Tutorials',
  MemoDetails = 'MemoDetails',
  AttachmentsPage = 'AttachmentsPage',
  UPCShifts = 'UPCShifts',
}

// eslint-disable-next-line no-shadow
export enum ShopperScreens {
  BatchDetailsPage = 'BatchDetailsPage',
  ItemDetails = 'ItemDetails',
  AddItemPage = 'AddItemPage',
  OrderPackingPage = 'OrderPackingPage',
  PdfDetailScreen = 'PdfDetailScreen',
  UPCShifts = 'UPCShifts',
  VanLoadSite = 'VanLoadSite',
}
