import {DefaultContext} from '../../utils/types';
import React from 'react';
import {UPCShiftsState} from '../types';

export const initialState: UPCShiftsState = {
  storeList: [],
  pendingUPCOrders: [],
};

const defaultVal: DefaultContext<UPCShiftsState> = {
  state: initialState,
  dispatch: null,
};

export const UPCShiftContext = React.createContext(defaultVal);
