import {Box} from '../../../src/components/core/view';
import BuyersOnMap from '../components/mapOverview/buyersOnMap';
import Header from '../../../src/components/composites/Header/Header';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React from 'react';
import ScreenHeader from '../../../src/common/components/screenHeader';
import {StyleSheet} from 'react-native';
import {isWeb} from '../../../src/utils/common';
import {useColorThemeType} from '../../../src/appStyles/hooks/theme';

type Props = NativeStackScreenProps<any, any>;

const MapOverview: React.FC<Props> = () => {
  const theme = useColorThemeType('main');
  const isWebMode = isWeb();
  return (
    <Header
      headerColor={theme?.background?.blue?.[300]}
      headerLeftComponent={isWebMode && <Box style={styles.webHeader} />}
      showDefaultLeftHeader>
      <ScreenHeader title={'Map Overview'} />
      <BuyersOnMap />
    </Header>
  );
};

export default React.memo(MapOverview);
const styles = StyleSheet.create({
  parentBox: {
    paddingHorizontal: 20,
    flex: 1,
  },
  webHeader: {
    padding: 30,
  },
});
