import React, {useCallback, useMemo} from 'react';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../../../src/appStyles/hooks/theme';

import {H6Text} from '../../../../../../../src/components/core/text';
import {HBox} from '../../../../../../../src/components/core/view';
import If from '@buncha/components/conditional/If';
import {StyleSheet} from 'react-native';
import {Touchable} from '../../../../../../../src/components/core/touchable';

interface DeliveryFailedFooterProps {
  disabled: boolean;
  closeSheet: () => void;
  markAsFailed: () => void;
  tutorial: boolean;
}

const DeliveryFailedFooter: React.FC<DeliveryFailedFooterProps> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');

  const getDoneCTAColor = useMemo(() => {
    if (props?.disabled) return theme?.text?.gray?.[100];
    return theme?.text?.green?.[100];
  }, [props?.disabled, theme?.text?.gray, theme?.text?.green]);

  const markOrderAsFailed = useCallback(() => {
    props?.markAsFailed();
  }, [props]);
  return (
    <HBox style={styles.buttonBox}>
      <Touchable onPress={() => props?.closeSheet()}>
        <H6Text
          style={{
            color: theme?.text?.red?.[100],
            fontFamily: muli?.[700]?.normal,
          }}>
          Cancel
        </H6Text>
      </Touchable>
      <Touchable disabled={props?.disabled} onPress={markOrderAsFailed}>
        <H6Text
          style={{
            color: getDoneCTAColor,
            fontFamily: muli?.[700]?.normal,
          }}>
          Done
          <If condition={props.tutorial}>(Finish Tutorial)</If>
        </H6Text>
      </Touchable>
    </HBox>
  );
};

export default React.memo(DeliveryFailedFooter);

const styles = StyleSheet.create({
  buttonBox: {
    marginVertical: 10,
  },
});
