import {Dimensions, StyleSheet} from 'react-native';
import {getWidth, isWeb} from '../../../utils/common';

const width: number = Dimensions.get('window').width;
const PAGINATION_DOT_SIZE = 12;

export const styles = StyleSheet.create({
  PaginationContainer: {
    width: isWeb() ? '100%' : getWidth(width),
    flexDirection: 'row',
    justifyContent: 'center',
  },
  PaginationDot: {
    borderRadius: PAGINATION_DOT_SIZE,
    width: PAGINATION_DOT_SIZE,
    height: PAGINATION_DOT_SIZE,
    margin: 5,
    backgroundColor: '#EBEBF0',
  },
  PaginationDotSelected: {
    borderRadius: PAGINATION_DOT_SIZE,
    backgroundColor: '#100E3A',
    height: PAGINATION_DOT_SIZE,
    width: PAGINATION_DOT_SIZE,
  },
});
