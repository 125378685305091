import {Dispatch, useState} from 'react';
import {GetReceiptForRunResponse, UploadReceiptResponse} from '../types';

import {Analytics} from '@buncha/config/analytics/analytics';
import {EventType} from '@buncha/config/analytics/type';
import {GET_RECEIPTS_FOR_RUN} from '../../config/graphQL/documentNode/queries';
import {ToastType} from '../../components/composites/notification/type';
import {UPLOAD_RUN_RECEIPT_OLD} from '../../config/graphQL/documentNode/mutation';
import {batchDetailActionsLocal} from './batchDetail';
import {gqlService} from '../../config/graphQL';
import {useToastMessage} from '../../components/composites/notification';

export function useRun(dispatch: Dispatch<any> | null) {
  const [uploadingReceipt, setUploadingReceipt] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const getReceipts = async (runId: number) => {
    try {
      const response = await gqlService?.query<GetReceiptForRunResponse>({
        query: GET_RECEIPTS_FOR_RUN,
        variables: {
          runId: runId,
        },
        fetchPolicy: 'network-only',
      });
      if (response?.data && dispatch)
        dispatch(
          batchDetailActionsLocal.setReceipts(
            response?.data?.getRun?.receiptImageUrls,
          ),
        );
    } catch (error: any) {
      showErrorToast(error.message);
    }
  };

  const uploadReceipt = async (
    runId: number,
    dataToSend: any,
    existingReceipts: string[],
  ) => {
    try {
      setUploadingReceipt(true);
      const res = await gqlService?.mutation<UploadReceiptResponse>({
        mutation: UPLOAD_RUN_RECEIPT_OLD,
        fetchPolicy: 'network-only',
        variables: {
          runId: runId,
          file: dataToSend,
        },
      });
      if (res?.data && dispatch) {
        const receipts = [
          ...existingReceipts,
          res?.data?.uploadRunReceipt?.fileUrl,
        ];
        dispatch(batchDetailActionsLocal.setReceipts(receipts));
        Analytics.eventWithProps('upload shopper receipt', {
          type: EventType.cta,
          runId: runId,
        });
      }
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setUploadingReceipt(false);
    }
  };
  return {getReceipts, uploadReceipt, uploadingReceipt};
}
