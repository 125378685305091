import {Dimensions, StyleSheet, View} from 'react-native';

import {ComponentPropsType} from './types';
import React from 'react';
import {getWidth} from '../../../utils/common';

export default function LoginComponent(props: ComponentPropsType) {
  const {children} = props;
  const windowWidth = Dimensions.get('window').width;
  return (
    <View style={[style.container, {width: getWidth(windowWidth)}]}>
      {children}
    </View>
  );
}

const style = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    padding: 20,
    paddingBottom: 0,
  },
});
