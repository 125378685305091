import {Box, HBox} from '@buncha/components/core/view';
import {CustomText, H6Text} from '@buncha/components/core/text';
import React, {useCallback, useMemo, useState} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {Analytics} from '@buncha/config/analytics/analytics';
import {Checkbox} from 'native-base';
import If from '@buncha/components/conditional/If';
import {OrderListCellStyle} from './styles';
import {OrdersForShopper} from '@buncha/batchDetails/types';
import {Touchable} from '@buncha/components/core/touchable';
import UsedBagBottomSheet from '../UsedBag/UsedBagBottomSheet';
import font from '@buncha/appStyles/style/font';
import {useNavigateVanLoadContext} from '@buncha/batchDetails/hooks/NavigateVanLoad';

interface Props {
  order: OrdersForShopper;
  checkBoxHandler: (orderId: number, hasChecked: boolean) => void;
}

const OrderListCell = (props: Props) => {
  const {order} = props;
  const assignee = order.orderAssignee;
  const assigneeSecondary = assignee.assigneeSecondary;
  const buyer = order.buyer;
  const muliFont = useFontTheme('muli');
  const [open, setOpen] = useState(false);

  const theme = useColorThemeType();
  const {state} = useNavigateVanLoadContext();
  const bagCountUpdateStatus = state.bagCountUpdateStatus;

  const onChangeHandler = useCallback(() => {
    props?.checkBoxHandler(order.id, !bagCountUpdateStatus[order.id]);
  }, [bagCountUpdateStatus, order.id, props]);

  const getColor = useMemo(() => {
    if (bagCountUpdateStatus[order.id])
      return {
        backgroundColor: theme?.background.green[100],
        borderColor: theme?.text.green[100],
      };
    return {
      backgroundColor: theme?.background.white[100],
      borderColor: theme?.text.blue[300],
    };
  }, [
    bagCountUpdateStatus,
    order.id,
    theme?.background.green,
    theme?.background.white,
    theme?.text.blue,
    theme?.text.green,
  ]);

  const hasDriverAssigned = useMemo(() => {
    if (assigneeSecondary?.firstName) return true;
    return false;
  }, [assigneeSecondary?.firstName]);

  const openIntercom = useCallback(() => {
    Analytics.displayMessenger();
  }, []);

  const toggleBottomSheet = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <HBox style={OrderListCellStyle.mainContainer}>
      <Box style={OrderListCellStyle.flexBox}>
        <H6Text
          style={[
            OrderListCellStyle.buyerName,
            {
              color: theme?.text.blue[300],
            },
          ]}>
          {buyer?.firstName} {buyer?.lastName}
        </H6Text>
        <If condition={hasDriverAssigned}>
          <CustomText
            style={[
              OrderListCellStyle.driverName,
              {
                color: theme?.text.gray[100],
                fontFamily: font.fonts.muliSemiBold,
              },
            ]}>
            Driver: {assigneeSecondary?.firstName} {assigneeSecondary?.lastName}
          </CustomText>
        </If>
        <If condition={!hasDriverAssigned}>
          <CustomText
            style={[
              OrderListCellStyle.driverName,
              {
                color: theme?.text.gray[100],
                fontFamily: font.fonts.muliSemiBold,
              },
            ]}>
            There are no driver assigned here,{' '}
            <CustomText
              onPress={openIntercom}
              style={[
                OrderListCellStyle.driverName,
                OrderListCellStyle.underLineText,
                {
                  color: theme?.text.blue[100],
                  fontFamily: font.fonts.muliSemiBold,
                },
              ]}>
              connect to the dispatch team
            </CustomText>
          </CustomText>
        </If>
      </Box>
      <HBox>
        <Touchable onPress={toggleBottomSheet}>
          <CustomText
            style={[
              OrderListCellStyle.underLineText,
              {
                color: theme?.text.blue[100],
                ...font.FontSize.fontP10,
                fontFamily: muliFont[700].normal,
              },
            ]}>
            edit
          </CustomText>
        </Touchable>
        <If condition={order?.bagsUsed}>
          <Box
            style={[
              OrderListCellStyle.bagBoxContainer,
              {backgroundColor: theme?.background.yellow[100]},
            ]}>
            <CustomText
              style={[
                {
                  color: theme?.text.blue[300],
                  ...font.FontSize.fontP10,
                  fontFamily: font.fonts.muliSemiBold,
                },
              ]}>{`${order?.bagsUsed} Boxes`}</CustomText>
          </Box>
        </If>
        <Checkbox
          value={''}
          onChange={onChangeHandler}
          backgroundColor={getColor.backgroundColor}
          style={{borderColor: getColor.borderColor}}
          // color={theme?.background.white[100]}
          isChecked={bagCountUpdateStatus[order.id] || false}
          defaultIsChecked={bagCountUpdateStatus[order.id] || false}
        />
      </HBox>
      <If condition={open}>
        <UsedBagBottomSheet
          open={open || false}
          onClose={toggleBottomSheet}
          buyerName={`${buyer?.firstName} ${buyer?.lastName}`}
          usedBag={order?.bagsUsed}
          orderId={order.id}
        />
      </If>
    </HBox>
  );
};

export default React.memo(OrderListCell);
