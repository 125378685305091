import {Box, HBox} from '@buncha/components/core/view';
import {ChevronDownIcon, ChevronUpIcon} from 'native-base';

import {Accordian} from '@buncha/components/composites';
import {H5Text} from '@buncha/components/core/text';
import React from 'react';
import StoreLists from './StoreLists';
import {StyleSheet} from 'react-native';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const AvailableUPCShifts = () => {
  const theme = useColorThemeType('main');
  return (
    <Box style={[styles.parentContainer]}>
      <Accordian
        header={
          <HBox style={[styles.mainHeaderContainer]}>
            <H5Text style={{color: theme?.text.blue[300]}}>
              Pending UPC Orders
            </H5Text>
          </HBox>
        }
        children={<StoreLists />}
        iconEnable={<ChevronUpIcon color={theme?.text.blue[300]} onP />}
        iconDisable={<ChevronDownIcon color={theme?.text.blue[300]} />}
      />
    </Box>
  );
};

export default React.memo(AvailableUPCShifts);

const styles = StyleSheet.create({
  parentContainer: {
    paddingBottom: 15,
    flex: 1,
    height: '100%',
  },
  mainContainer: {
    marginHorizontal: 20,
  },
  mainHeaderContainer: {
    flex: 1,
    marginRight: 10,
    zIndex: 90,
  },
  countBox: {
    paddingHorizontal: 15,
    borderRadius: 50,
    paddingVertical: 2.5,
  },
  divider: {
    marginVertical: 15,
  },
  scrollContainer: {
    marginTop: 20,
  },
  contentBox: {
    flexGrow: 1,
  },
  skeletonContainer: {
    marginHorizontal: 20,
  },
  accordionChild: {
    paddingTop: 10,
  },
});
