import {DefaultContext} from '../../utils/types';
import {NavigateVanLoadContextState} from '../types';
import React from 'react';

export const initialState: NavigateVanLoadContextState = {
  run: undefined,
  loading: true,
  bagCountUpdateStatus: {},
};

const defaultVal: DefaultContext<NavigateVanLoadContextState> = {
  state: initialState,
  dispatch: null,
};

export const NavigateVanLoadContext = React.createContext(defaultVal);
