import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
  },
  contactBoxStyled: {
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionButton: {
    borderRadius: 50,
    height: 52,
    marginTop: 10,
  },
});
