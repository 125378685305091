import {Dimensions, StyleSheet} from 'react-native';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../src/appStyles/hooks/theme';

import {Box} from 'native-base';
import BunchaYellowVan from '../../../../../src/components/core/icon/BunchaYellowVan';
import {H5Text} from '../../../../../src/components/core/text';
import React from 'react';

const NoOrderForDriver: React.FC = () => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');

  return (
    <Box style={styles.parentBox}>
      {/* <If condition={isWebMode}>
        <Image
          style={[styles.imageStyleWeb]}
          source={{uri: imgSrc}}
          resizeMode="contain"
        />
      </If> */}
      {/* <If condition={!isWebMode}> */}
      <BunchaYellowVan height={138} width={305} viewBox="0 8 295 20" />
      {/* </If> */}
      <H5Text
        style={[
          {
            color: theme?.text?.blue?.[300],
            fontFamily: muli?.[700]?.normal,
          },
          styles.text,
        ]}>
        Hmm... it seems like there are no shifts assigned to you at the moment.
      </H5Text>
    </Box>
  );
};

export default React.memo(NoOrderForDriver);

const styles = StyleSheet.create({
  parentBox: {
    alignItems: 'center',
    alignSelf: 'center',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    height: Dimensions.get('window').height * 0.8,
  },
  text: {
    width: '90%',
    marginTop: 10,
  },
  imageStyle: {
    height: Dimensions.get('window').height * 0.5,
    width: '100%',
  },
  imageStyleWeb: {
    height: Dimensions.get('window').height * 0.3,
    width: '100%',
  },
});
