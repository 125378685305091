import * as React from 'react';

import Icon from './Icon';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const PdfIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path d="M18 0H6C4.9 0 4 .9 4 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2ZM9.5 7.5C9.5 8.33 8.83 9 8 9H7v2H5.5V5H8c.83 0 1.5.67 1.5 1.5v1Zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V5H13c.83 0 1.5.67 1.5 1.5v3Zm4-3H17v1h1.5V9H17v2h-1.5V5h3v1.5ZM7 7.5h1v-1H7v1ZM2 4H0v14c0 1.1.9 2 2 2h14v-2H2V4Zm10 5.5h1v-3h-1v3Z" />
    </Icon>
  );
};

export default PdfIcon;
