import React, {useMemo} from 'react';

import Header from '../../../src/components/composites/Header/Header';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import OpenShiftsAccordian from '../components/openShiftsAccordian';
import {OpenShiftsContext} from '../context/openShifts';
import ScreenHeader from '../../../src/common/components/screenHeader';
import {useColorThemeType} from '../../../src/appStyles/hooks/theme';
import {useOpenShiftsReducer} from '../hooks/openShifts';

type Props = NativeStackScreenProps<any, any>;

const OpenShifts: React.FC<Props> = () => {
  const theme = useColorThemeType('main');
  const [state, dispatch] = useOpenShiftsReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);

  return (
    <Header headerColor={theme?.background?.blue?.[300]} showDefaultLeftHeader>
      <OpenShiftsContext.Provider value={provider}>
        <ScreenHeader title="Open Shifts" />
        <OpenShiftsAccordian />
      </OpenShiftsContext.Provider>
    </Header>
  );
};

export default OpenShifts;
