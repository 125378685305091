import * as React from 'react';

import Icon from './Icon';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const LocationIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path d="M7 0c3.87 0 7 3.13 7 7 0 1.74-.5 3.37-1.41 4.84-.95 1.54-2.2 2.86-3.16 4.4-.47.75-.81 1.45-1.17 2.26C8 19.05 7.79 20 7 20s-1-.95-1.25-1.5c-.37-.81-.7-1.51-1.17-2.26-.96-1.53-2.21-2.85-3.16-4.4C.5 10.37 0 8.74 0 7c0-3.87 3.13-7 7-7Zm0 9.75a2.5 2.5 0 0 0 0-5 2.5 2.5 0 0 0 0 5Z" />
    </Icon>
  );
};

export default LocationIcon;
