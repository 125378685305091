import {StyleSheet} from 'react-native';
import {scaleFonts} from '../../../../appStyles/style/font';

export const styles = StyleSheet.create({
  parentBox: {
    padding: 10,
    width: '100%',
  },
  textAlign: {
    textAlign: 'left',
  },
  textWarning: {
    paddingBottom: 10,
  },
  subtitleText: {
    marginVertical: 10,
  },
  callCTA: {
    marginBottom: 15,
    borderRadius: 50,
    height: scaleFonts(50),
  },
  textCTA: {
    borderRadius: 50,
    height: scaleFonts(50),
    borderWidth: 1,
  },
  touchableStyles: {
    backgroundColor: 'transparent',
  },
  textContainer: {
    justifyContent: 'space-between',
    marginBottom: 10,
    textAlign: 'left',
  },
  editIcon: {
    marginRight: 10,
  },
  actionButton: {
    borderRadius: 50,
    height: 52,
    marginTop: 10,
    width: '49%',
  },
  skeleton: {
    height: 13,
    width: 100,
    borderRadius: 10,
  },
  buttonSize: {
    fontSize: scaleFonts(16),
  },
  messageAlign: {
    textAlign: 'left',
    flex: 1,
  },
  flatlist: {
    maxHeight: 130,
  },
  textBox: {
    maxWidth: '80%',
    borderWidth: 1,
    borderRadius: 10,
  },
  padRight: {paddingRight: 10},
  padLeft: {paddingLeft: 10},
});
