import * as React from 'react';

import Icon from './Icon';
import {Path} from 'react-native-svg';

const PlayIcon = (props: any) => (
  <Icon
    fill="#85888c"
    width="30px"
    height="30px"
    viewBox="0 0 14 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M3.78 2L3 2.41v12l.78.42 9-6V8l-9-6zM4 13.48V3.35l7.6 5.07L4 13.48z" />
  </Icon>
);
export default React.memo(PlayIcon);
