import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../../src/appStyles/hooks/theme';

import {Box} from '../../../../../../src/components/core/view';
import {H6Text} from '../../../../../../src/components/core/text';
import React from 'react';
import {StyleSheet} from 'react-native';
import {TabData} from '../../../../../../src/components/composites/Tabs/type';

const TabHeaderNode: React.FC<{
  info: TabData<any>;
  activeKey: string;
}> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const info = props?.info;
  const activeKey = props?.activeKey;
  const isTabSelected = activeKey === info?.key;
  return (
    <Box
      style={[
        styles.headerBox,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          borderBottomWidth: isTabSelected ? 2 : 0,
          borderColor: isTabSelected
            ? theme?.text?.blue?.[300]
            : theme?.text?.gray?.[100],
        },
      ]}>
      <H6Text
        style={{
          fontFamily: muli?.[700]?.normal,
          color: isTabSelected
            ? theme?.text?.blue?.[300]
            : theme?.text?.gray?.[100],
        }}>
        {info.data}
      </H6Text>
    </Box>
  );
};

export default React.memo(TabHeaderNode);

const styles = StyleSheet.create({
  headerBox: {
    paddingBottom: 5,
  },
});
