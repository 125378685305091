import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  parentBox: {
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    // borderWidth: 2,
    marginVertical: 15,
  },
  icon: {
    marginRight: 10,
    marginTop: 5,
  },
  image: {
    height: 24,
    width: 24,
    marginRight: 5,
  },
  divider: {width: '80%', alignSelf: 'center'},
  textAlign: {
    textAlign: 'left',
  },
  subHeader: {
    textAlign: 'left',
    marginVertical: 5,
  },
  contentBox: {
    flex: 1,
  },
  bottomText: {
    textAlign: 'left',
  },
  infoIcon: {
    marginRight: 5,
  },
  footerText: {
    textAlign: 'left',
  },
  footerBox: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 25,
    paddingTop: 5,
  },
  imageBox: {
    alignSelf: 'flex-start',
  },
});
