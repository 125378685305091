import {StyleSheet} from 'react-native';
import font from '../../../../appStyles/style/font';
import {getWidth} from '../../../../utils/common';

export const styles = StyleSheet.create({
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: getWidth(50),
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    height: 27,
    padding: 5,
    paddingHorizontal: 15,
  },
  locationTypo: {
    textAlign: 'center',
    fontFamily: font.fonts.muliBold,
  },
});
