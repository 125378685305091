/**
 * @enum {string}
 */
// eslint-disable-next-line no-shadow
enum Fonts {
  'muliRegular' = 'Muli-Regular',
  'muliSemiBold' = 'Muli-SemiBold',
  'muliBold' = 'Muli-Bold',
  'muliBlack' = 'Muli-Black',
  'Mortise-X-Light' = 'Mortise-X-Light',
  'Mortise-Light' = 'Mortise-Light',
  'Mortise-Medium' = 'Mortise-Medium',
  'Mortise-Regular' = 'Mortise-Regular',
  'Mortise-X-Bold' = 'Mortise-X-Bold',
  'Mortise-Bold' = 'Mortise-Bold',
}

export default Fonts;
