import {Dimensions, StyleSheet} from 'react-native';

import {getWidth} from '../../../../utils/common';

export const styles = StyleSheet.create({
  headerText: {
    marginBottom: 10,
  },
  buttonContainer: {
    marginHorizontal: 15,
  },
  CTATop: {
    marginBottom: 15,
    borderRadius: 50,
  },
  modalBox: {
    width: getWidth(Dimensions.get('screen').width - 40),
    borderRadius: 20,
  },
  highlightText: {
    borderRadius: 20,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  modalBody: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});
