import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    justifyContent: 'flex-start',
    marginHorizontal: 20,
  },
  storeLogoBox: {
    borderWidth: 3,
    borderRadius: 50,
  },
  storeLogo: {
    borderRadius: 50,
    height: 50,
    width: 50,
    overflow: 'hidden',
  },
  infoContainer: {
    marginLeft: 10,
  },
  runName: {
    textAlign: 'left',
    // cursor: 'default',
  },
  runSubtext: {
    textAlign: 'left',
    // cursor: 'default',
  },
});
