import {ItemScannerGlobalReducer} from '../../itemDetails/reducer';
import {batchDetailsReducer} from '../../batchDetails/reducer';
import {homeReducer} from '../../home/reducer';
import {onBoardingReducer} from '../../onBoarding/reducer';

export default {
  home: homeReducer,
  onboarding: onBoardingReducer,
  batchDetail: batchDetailsReducer,
  itemScanner: ItemScannerGlobalReducer,
};
