import {Box, HBox} from '../../../../../src/components/core/view';
import React, {useCallback, useState} from 'react';
import {useAppDispatch, useAppSelector} from '@buncha/config/redux/hooks';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../src/appStyles/hooks/theme';

import {CustomImage} from '../../../../../src/components/core/Image';
import {H5Text} from '../../../../../src/components/core/text';
import If from '../../../../../src/components/conditional/If';
import {ItemDetailsScreen} from '@buncha/itemDetails/navigation/types';
import {ItemScannerGlobalActions} from '@buncha/itemDetails/reducer';
import {PButton} from '../../../../../src/components/core/Button';
import QuantityModal from '../quantityModal';
import {RecommendedItemCell} from '../../../../../src/itemDetails/types/itemReplacement';
import RequestReplacementModal from '../requestReplacementModal';
import {ShopperScreens} from '@buncha/home/navigation/types';
import {UPCItemLocationStatus} from '@buncha/itemDetails/types/ItemScanner';
import {navigation} from '@buncha/config/navigation';
import {scaleFonts} from '../../../../../src/appStyles/style/font';
import {styles} from './styles';
import {
  useItemScannerContext,
  useUPCOrder,
} from '@buncha/itemDetails/hooks/ItemScanner';

interface ReplacementItemCellProps {
  itemInfo?: RecommendedItemCell;
  orderItemId?: number;
  orderId?: number;
  runId?: number;
  quantity?: number;
  isUPCMode?: boolean;
}

const ReplacementItemCell: React.FC<ReplacementItemCellProps> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const item = props?.itemInfo;
  const name = item?.name || '';
  const imageUrl = item?.itemImageUrl || '';
  const price = (item?.price || 0) / 100;
  const catalogItemId = item?.id || 0;
  const itemSource = item?.itemSource || '';
  const indexPosition = item?.indexPosition || 0;
  const [modalOpen, setModalOpen] = useState(false);
  const [quantityModal, setQuantityModal] = useState(false);

  const {dispatch} = useItemScannerContext();

  const {substituteItemInUPC} = useUPCOrder(dispatch);

  const {itemLocationData, upcOrderItem, selectedCatalogStoreForUPCRun} =
    useAppSelector(gState => gState.itemScanner);
  const appDispatch = useAppDispatch();

  const toggleModal = useCallback(() => {
    setModalOpen(!modalOpen);
  }, [modalOpen]);

  const toggleQuantityModal = useCallback(async () => {
    if (props?.isUPCMode) {
      const data: UPCItemLocationStatus = {
        ...itemLocationData,
        substituionData: {
          name: name,
          orderId: props?.orderId,
          orderItemId: props?.orderItemId,
          position: 0,
          price: Number(Number(Number(price) * 100).toFixed(0)),
          quantity: props?.quantity,
          isCustomItem: false,
          substitutedBy: '',
          imageUrl: item?.itemImageUrl || '',
        },
      };
      navigation.navigate(ShopperScreens.ItemDetails, {
        screen: ItemDetailsScreen.ItemScanner,
        params: {
          orderItemId: upcOrderItem?.id,
          storeIndex: selectedCatalogStoreForUPCRun?.esIndex,
          runId: '',
          quantity: 0,
          isUpcMode: props?.isUPCMode,
        },
      });
      await substituteItemInUPC(
        {
          upcOrderItemId: upcOrderItem?.id || 0,
          name: name,
          price: Number(Number(Number(price) * 100).toFixed(0)),
          isCustomItem: true,
          itemImageUrl: '',
          note: '',
          catalogItemId: catalogItemId,
        },
        () => {
          if (appDispatch)
            appDispatch(ItemScannerGlobalActions.setItemLocationData(data));
        },
      );
    } else {
      setQuantityModal(!quantityModal);
    }
  }, [
    appDispatch,
    catalogItemId,
    item?.itemImageUrl,
    itemLocationData,
    name,
    price,
    props?.isUPCMode,
    props?.orderId,
    props?.orderItemId,
    props?.quantity,
    quantityModal,
    selectedCatalogStoreForUPCRun?.esIndex,
    substituteItemInUPC,
    upcOrderItem?.id,
  ]);

  return (
    <HBox style={styles.parentBox}>
      <Box style={styles.imageBox}>
        <CustomImage style={styles.image} source={imageUrl} />
      </Box>
      <Box style={styles.textBox}>
        <HBox>
          <Box style={styles.namePriceBox}>
            <H5Text
              numberOfLines={2}
              alignText="left"
              style={{
                color: theme?.text?.blue?.[300],
                fontFamily: muli?.[700]?.normal,
              }}>
              {name}
            </H5Text>
            <H5Text
              alignText="left"
              style={{
                color: theme?.text?.blue?.[300],
                fontFamily: muli?.[700]?.normal,
              }}>
              $ {price}
            </H5Text>
          </Box>

          <PButton
            style={styles.replaceCTA}
            title="Replace"
            titleStyle={{fontSize: scaleFonts(12)}}
            onPress={toggleQuantityModal}
          />
        </HBox>
      </Box>
      <If condition={modalOpen}>
        <RequestReplacementModal
          catalogItemId={catalogItemId}
          catalogItem={item}
          itemSource={itemSource}
          indexPosition={indexPosition}
          open={modalOpen}
          onClose={toggleModal}
        />
      </If>
      <If condition={quantityModal}>
        <QuantityModal
          onClose={toggleQuantityModal}
          open={quantityModal}
          onConfirm={toggleModal}
        />
      </If>
    </HBox>
  );
};

export default React.memo(ReplacementItemCell);
