import * as React from 'react';

import Icon from './Icon';
import {Path} from 'react-native-svg';

const CirclePlusIcon = (props: any) => (
  <Icon
    width="25px"
    height="25px"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fill="#000000"
      d="M352 480h320a32 32 0 1 1 0 64H352a32 32 0 0 1 0-64z"
    />
    <Path
      fill="#000000"
      d="M480 672V352a32 32 0 1 1 64 0v320a32 32 0 0 1-64 0z"
    />
    <Path
      fill="#000000"
      d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768zm0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896z"
    />
  </Icon>
);
export default CirclePlusIcon;
