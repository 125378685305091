import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {Box} from '../../../components/core/view';
import {H6Text} from '../../../components/core/text';
import React from 'react';
import {TabData} from '../../../components/composites/Tabs/type';
import {styles} from './styles';

export const TabNode: React.FC<{info: TabData<any>; activeKey: string}> =
  React.memo(props => {
    const defaultTheme = useColorThemeType();
    const muliFont = useFontTheme('muli');
    const {info, activeKey} = props;
    return (
      <Box
        style={[
          styles.tabNode,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            borderBottomWidth: activeKey === info.key ? 2 : 0,
          },
        ]}>
        <H6Text
          style={{
            color:
              activeKey === info.key
                ? defaultTheme?.text.blue[300]
                : defaultTheme?.text.gray[100],
            fontFamily: muliFont[700].normal,
          }}>
          {info.data}
        </H6Text>
      </Box>
    );
  });
