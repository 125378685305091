import {Dimensions, StyleSheet} from 'react-native';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import AssignedShiftAccordion from './AssignedShiftAccordion';
import {Box} from '../../../components/core/view';
import {H3Text} from '../../../components/core/text';
import If from '../../../components/conditional/If';
import React from 'react';
import WhenIWorkDisclaimer from '../../../home/components/whenIWorkDisclaimer';
import {useAppSelector} from '../../../config/redux/hooks';

interface AssignedShiftsProps {
  loginError?: boolean;
  openModal?: () => void;
}

function AssignedShifts(props: AssignedShiftsProps) {
  const {loginError, openModal} = props;
  const defaultTheme = useColorThemeType();
  const mortiseFont = useFontTheme('mortise');
  const state = useAppSelector(gState => gState.home.nextShiftData);
  const onBoardingState = useAppSelector(gstate => gstate.onboarding);
  const assignedShifts = state?.upcoming || [];
  const whenIWorkData = onBoardingState.currentUser?.WhenIWorkInfo;

  return (
    <Box style={styles.mainContainer}>
      <If condition={Boolean(assignedShifts.length)}>
        <React.Fragment>
          <H3Text
            style={[
              styles.titleText,
              {
                fontFamily: mortiseFont[900].normal,
                color: defaultTheme?.text.blue[300],
              },
            ]}>
            Assigned Shifts
          </H3Text>
          <AssignedShiftAccordion />
        </React.Fragment>
      </If>
      <If condition={loginError || !whenIWorkData?.login.isLogin}>
        <WhenIWorkDisclaimer onSignInAgain={openModal} />
      </If>
    </Box>
  );
}

export default React.memo(AssignedShifts);

const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 20,
    marginTop: 10,
  },
  noShiftContainer: {
    height: Dimensions.get('screen').height * 0.9,
  },
  titleText: {
    textAlign: 'left',
    marginBottom: 15,
    marginTop: 15,
    // cursor: 'default',
  },
});
