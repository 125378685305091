/* eslint-disable no-shadow */
export enum ColorTheme {
  'bunchaLight' = 'bunchaLight',
}

export type ColorThemeType = 'default' | 'error' | 'warning' | 'success';

export type ColorThemeOption =
  | 'red'
  | 'white'
  | 'yellow'
  | 'orange'
  | 'gray'
  | 'green'
  | 'blue';
export type ModuleTheme = 'main';
export type FontTheme = 'muli' | 'mortise';
export type FontThemeType = 'normal';
export type ColorThemeInfo = ColorThemeOptions;

export interface ColorThemeOptions {
  text: ColorOptions;
  background: ColorOptions;
  shadow: ColorSubOptions;
  skeleton: SkeletonColorOptions;
  icon: ColorOptions;
}

type ColorOptions = {
  [key in ColorThemeOption]: ColorSubOptions | string;
};
type ColorSubOptions = {
  [key in 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 1000]?: string;
};

type SkeletonColorOptions = {
  [key in 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 1000]?: {
    startColor: string;
    endColor: string;
  };
};

export type ShadowThemeType =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9';
