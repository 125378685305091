import * as React from 'react';

import Icon from './Icon';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const MapOverviewIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        fill={props?.fill}
        d="m12 18-6-2.1-4.65 1.8a.902.902 0 0 1-.925-.113A.988.988 0 0 1 0 16.75v-14c0-.217.063-.408.188-.575.125-.167.296-.292.512-.375L6 0l6 2.1L16.65.3a.902.902 0 0 1 .925.113.988.988 0 0 1 .425.837v14a.931.931 0 0 1-.188.575 1.133 1.133 0 0 1-.512.375L12 18Zm-1-2.45V3.85l-4-1.4v11.7l4 1.4Z"
      />
    </Icon>
  );
};

export default MapOverviewIcon;
