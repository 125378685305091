import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const CrossIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} fill="none">
      <Path
        d="M7 7L17 17M7 17L17 7"
        stroke="#E54F37"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Icon>
  );
};

export default CrossIcon;
