import {
  useColorThemeType,
  useFontTheme,
} from '../../../../appStyles/hooks/theme';

import {PTextInputProps} from '../../../core/input/TextInput/type';
import React from 'react';
import {SearchIcon} from 'native-base';
import {StyleSheet} from 'react-native';
import {TextInputGroup} from '../../../core/input';
import font from '../../../../appStyles/style/font';

const SearchInput: React.FC<PTextInputProps> = React.forwardRef(
  (props, ref) => {
    const theme = useColorThemeType();
    const fontTheme = useFontTheme();
    return (
      <TextInputGroup.PTextInput
        leftComponent={
          <SearchIcon
            size={5}
            color={theme?.text.blue[300]}
            style={[styles.searchIcon]}
          />
        }
        {...props}
        containerStyle={[
          styles.textInputContainer,
          {
            borderColor: theme?.text.gray[100],
            backgroundColor: theme?.text.gray[200],
          },
          props.containerStyle,
        ]}
        style={[
          styles.textInput,
          {
            fontFamily: fontTheme[400].normal,
            color: theme?.text.gray[200],
          },
          props.style,
        ]}
        ref={ref}
      />
    );
  },
);

export default React.memo(SearchInput);

const styles = StyleSheet.create({
  textInputContainer: {
    borderWidth: 1,
    borderRadius: 10,
    marginTop: 5,
  },
  textInput: {
    backgroundColor: 'transparent',
    ...font.FontSize.fontH4,
  },
  searchIcon: {
    marginLeft: 10,
  },
});
