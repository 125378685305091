import {Buyer, MostRecentAddress} from '../../../../../src/driver/types/common';

import {Box} from '../../../../../src/components/core/view';
import BuyerAddressCell from '../../common/buyerAddressCell';
import BuyerDetailCell from '../../common/buyerDetailCell';
import DeliveryInstructions from './deliveryInstructions';
import DeliveryNote from './deliveryNote';
import {DeliveryNotes} from '../../../../../src/driver/types/orderDetails';
import {Divider} from 'native-base';
import If from '../../../../../src/components/conditional/If';
import React from 'react';
import {StyleSheet} from 'react-native';

interface NameAndAddressDetailsProps {
  forOrderDetails: boolean;
  buyerInfo: Buyer | undefined;
  deliveryInstructions?: string;
  deliveryNote?: DeliveryNotes;
  buyerAddress: MostRecentAddress | undefined;
  orderId?: number;
  runId?: number;
}

const NameAndAddressDetails: React.FC<NameAndAddressDetailsProps> = props => {
  const buyerAddress = props?.buyerAddress;
  const deliveryInstruction = buyerAddress?.deliveryInstruction;
  const dropPoint = buyerAddress?.dropPoint;

  return (
    <Box>
      <Box style={styles.cellBox}>
        <BuyerDetailCell
          buyerDetails={props?.buyerInfo}
          hideAddress={true}
          orderId={props.orderId}
          runId={props?.runId}
        />
      </Box>
      <Divider />
      <BuyerAddressCell buyerAddress={props?.buyerAddress} />
      <Divider />
      <If condition={deliveryInstruction || dropPoint}>
        <DeliveryInstructions
          deliveryInstruction={deliveryInstruction}
          dropPoint={dropPoint}
        />
        <Divider />
      </If>
      <If condition={props?.forOrderDetails}>
        <If condition={props?.deliveryNote}>
          <DeliveryNote deliveryNote={props?.deliveryNote} />
          <Divider />
        </If>
      </If>
    </Box>
  );
};
export default React.memo(NameAndAddressDetails);

const styles = StyleSheet.create({
  cellBox: {
    marginVertical: 10,
  },
});
