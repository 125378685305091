/* eslint-disable no-shadow */
export enum WPosition {
  'Shopper' = 'Shopper',
  'Driver' = 'Driver',
  'none' = 'none',
}

export enum ShopperSessionSyncStatus {
  'incomplete' = 'incomplete',
  'complete' = 'complete',
}

export enum ShopperSessionAppStatus {
  'online' = 'online',
  'offline' = 'offline',
}

export enum ShopperSessionStatus {
  'notStarted' = 'notStarted',
  'started' = 'started',
  'completed' = 'completed',
}

export interface AboutShiftContextState {
  batches: Batches;
}

export interface Batches {
  startedShift: BatchInfo[];
  notStartedShift: BatchInfo[];
  inspectVan: boolean;
}

export interface LatLong {
  lat: number;
  lng: number;
}

export interface Store {
  address: string;
  id: number;
  name: string;
  storeLogoUrl: string;
  elasticSearchIndex: string;
  getLatLongFromAddress: LatLong;
}

export interface Run {
  id: number;
  dropOffPoint: string;
  store: Store;
  dropOffStartTime: string;
  locationAddress: string;
  isMaster: boolean;
  masterRun: Run;
  runType: RunType;
}

export enum RunType {
  child = 'child',
  master = 'master',
  normal = 'normal',
}

export interface Shift {
  time: string;
  shiftId: string;
  endTime: string;
  startTime: string;
  position: WPosition;
  store: Store;
}

export interface TaskInfo {
  orderCount: number;
  itemCount: number;
}
export interface BatchTask {
  shopperTask: TaskInfo;
  driverTask: TaskInfo;
}

export interface ChatDetails {
  conversationFilterForRunner: any;
  ordersForShopper: {
    id: number;
    buyer: {
      phoneNumber: string;
    };
  }[];
}

export interface BatchInfo {
  run: Run;
  shift: Shift[];
  task: BatchTask;
  shopperSession: ShopperSession;
  state: WPosition;
  activeShiftIndex: number;
  ctaName: string;
}

export interface GetBatchesResponse {
  getBatches: Batches;
}

export interface ShopperSession {
  id: number;
  shiftId: string;
  syncStatus: ShopperSessionSyncStatus;
  appStatus: ShopperSessionAppStatus;
  sessionStatus: ShopperSessionStatus;
  lastSyncTime: Date;
  syncFrequency: number;
}

export interface UpdateShopperSessionStatusRes {
  updateShopperSessionStatus: ShopperSession;
}

export interface UpdateUserChoiceMapRes {
  updateUserChoiceMap: boolean;
}

export interface BagsHistory {
  id: number;
  bagsCount: number;
}

export interface SubmitStartingBagCountResponse {
  submitStartingBagCount: BagsHistory;
}

export interface CheckSubmittedBagCountResponse {
  checkSubmittedBagCount: BagsHistory;
}
