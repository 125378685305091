import {Box} from '../../components/core/view';
import NextShift from '../../home/component/NextShift';
import React from 'react';

export default function ActiveOrder() {
  return (
    <Box>
      <NextShift />
    </Box>
  );
}
