import {H3Text, H5Text} from '@buncha/components/core/text';
import React, {useCallback, useEffect} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {Analytics} from '@buncha/config/analytics/analytics';
import {BottomButton} from '@buncha/components/composites/Button';
import BottomSheet from '@buncha/components/composites/bottomSheet';
import {Box} from '@buncha/components/core/view';
import {CustomFlatList} from '@buncha/components/core/list';
import {CustomImage} from '@buncha/components/core/Image';
import {Divider} from 'native-base';
import {DriverScreens} from '@buncha/driver/navigation/type';
import {EventType} from '@buncha/config/analytics/type';
import {HomeScreens} from '@buncha/home/navigation/types';
import {LoadSequenceStyle} from './styles';
import {SpinnerComponent} from '@buncha/components/core/loader';
import {navigation} from '@buncha/config/navigation';

interface Props {
  open: boolean;
  onClose: () => void;
  runId: number;
  routeStarted: boolean;
}

const SequenceBottomSheet = (props: Props) => {
  const muli = useFontTheme('muli');
  const mortise = useFontTheme('mortise');
  const theme = useColorThemeType('main');

  const onPressHandler = useCallback(() => {
    if (props?.routeStarted) {
      props?.onClose();
      return navigation.navigate(HomeScreens.Driver, {
        screen: DriverScreens.DriverOrders,
        params: {runId: props?.runId},
      });
    }
    props?.onClose();
  }, [props]);

  useEffect(() => {
    if (props?.open)
      Analytics.eventWithProps('Sequence Bottom Sheet Open', {
        type: EventType.modal,
        runId: props?.runId,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.open]);

  return (
    <BottomSheet open={props?.open} onClose={props?.onClose}>
      <CustomFlatList
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={
          <>
            <Box style={LoadSequenceStyle.topBox}>
              <H3Text
                style={[
                  {
                    fontFamily: mortise?.[700]?.normal,
                    color: theme?.text?.blue?.[300],
                  },
                  LoadSequenceStyle.title,
                ]}>
                Van Loading Order
              </H3Text>
              <H5Text
                style={[
                  {
                    fontFamily: muli?.[700]?.normal,
                    color: theme?.text?.gray[100],
                  },
                  LoadSequenceStyle.subTitle,
                ]}>
                The van loading order starts with the last stop loading
                first...and ends with the first stop being loaded last.
              </H5Text>

              <Box style={LoadSequenceStyle.imageBox}>
                <CustomImage
                  style={LoadSequenceStyle.imageContainer}
                  resizeMode="contain"
                  source={require('@buncha/resource/images/LoadSequence.png')}
                />
              </Box>
            </Box>
            <Divider />
            <Box style={LoadSequenceStyle.bottomBox}>
              <H3Text
                style={[
                  {
                    fontFamily: mortise?.[700]?.normal,
                    color: theme?.text?.blue?.[300],
                  },
                  LoadSequenceStyle.title,
                ]}>
                Delivery Order
              </H3Text>
              <H5Text
                style={[
                  {
                    fontFamily: muli?.[700]?.normal,
                    color: theme?.text?.gray[100],
                  },
                  LoadSequenceStyle.subTitle,
                ]}>
                The delivery order is the reverse of the load order to improve
                order accessibility in the van.
              </H5Text>
              <Box style={LoadSequenceStyle.imageBox}>
                <CustomImage
                  style={LoadSequenceStyle.imageContainer}
                  resizeMode="contain"
                  source={require('@buncha/resource/images/RouteStopeSequence.png')}
                />
              </Box>
            </Box>
          </>
        }
        data={undefined}
        renderItem={undefined}
      />
      <BottomButton
        title={'Got it'}
        onPress={onPressHandler}
        loadingIndicator={<SpinnerComponent />}
        bgColor={theme?.background.green[100]}
      />
    </BottomSheet>
  );
};

export default React.memo(SequenceBottomSheet);
