import {gql} from '@apollo/client';
import {OrderItems} from './fragment';

export const LIVE_SUBSTITUTION_UPDATE = gql`
  subscription liveSubstitutionUpdate($runId: Int!, $userId: Int) {
    liveSubstitutionUpdate(runId: $runId, userId: $userId) {
      groupedAssignedItems {
        groupedKey
        orderItems {
          ...OrderItems
        }
        keyTotalEntries
      }
    }
  }
  ${OrderItems}
`;
