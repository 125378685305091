import {OrderItems, UpcStatus} from '../../../batchDetails/types';
import React, {useCallback, useState} from 'react';

import {Box} from '../../../components/core/view';
import {CustomFlatList} from '../../../components/core/list';
import {Divider} from 'native-base';
import DropItemModalBody from '../../../itemDetails/components/ItemDetail/DropItemModalBody/DropItemModalBody';
import {ItemDetailsScreen} from '@buncha/itemDetails/navigation/types';
import {ItemScannerRoute} from '@buncha/itemDetails/types/ItemScanner';
import {OrderItemStatus} from '../../../common/types/common';
import PackOrderItem from '../PackOrderItem';
import {ShopperScreens} from '@buncha/home/navigation/types';
import UnpackItemModal from '../UnpackItemModal';
import listener from '../../../config/listener';
import {navigation} from '@buncha/config/navigation';
import {sortBy} from 'lodash';
import {styles} from './styles';
import {useRoute} from '@react-navigation/native';
import {useUpdateOrderItemStatus} from '../../../batchDetails/hooks/BatchDetails';

interface PackOrderItemListProps {
  packOrderItem: OrderItems[];
  onClick: (orderItem: OrderItems) => void;
  packingFinished: boolean;
  orderLoading: boolean;
  storeIndex: string;
}

function PackOrderItemList(props: PackOrderItemListProps) {
  const {packOrderItem, onClick, packingFinished, orderLoading} = props;
  const [showModal, setShowModal] = useState(false);
  const [showUnpackModal, setShowUnpackModal] = useState(false);
  const [orderItem, setOrderItem] = useState<OrderItems | null>(null);
  const {updateOrderItemStatus, changeStatusState} = useUpdateOrderItemStatus();
  const route = useRoute();
  const routeParams: ItemScannerRoute | undefined = route?.params;
  const runId = Number(routeParams?.runId);
  const loading = orderLoading || changeStatusState.loading;

  const dropItem = useCallback((itemClicked: OrderItems | null) => {
    setOrderItem(itemClicked);
    setShowModal(true);
  }, []);

  const unpackItem = useCallback((itemClicked: OrderItems | null) => {
    setOrderItem(itemClicked);
    setShowUnpackModal(true);
  }, []);

  const closeUnpackItemModal = useCallback(() => {
    listener.emit('orderPackDropRefresh');
    setShowUnpackModal(false);
    if (orderItem?.upcStatus !== UpcStatus.pending) return;
    navigation.navigate(ShopperScreens.ItemDetails, {
      screen: ItemDetailsScreen.ItemScanner,
      params: {
        orderItemId: orderItem?.id,
        storeIndex: props?.storeIndex,
        runId,
        quantity:
          orderItem?.shopperItemChangesForShopper?.updatedQuantity ||
          orderItem?.quantity,
        returnPage: ShopperScreens.OrderPackingPage,
      },
    });
  }, [
    orderItem?.id,
    orderItem?.quantity,
    orderItem?.shopperItemChangesForShopper?.updatedQuantity,
    orderItem?.upcStatus,
    props?.storeIndex,
    runId,
  ]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);
  const sortedPackOrderItem = sortBy(
    packOrderItem,
    sortedOrderItem => sortedOrderItem.id,
  );

  const changedOrderItemStatus = useCallback(() => {
    if (runId && orderItem?.id)
      updateOrderItemStatus(
        runId,
        OrderItemStatus.notPacked,
        [orderItem?.id],
        closeUnpackItemModal,
      );
  }, [closeUnpackItemModal, orderItem?.id, runId, updateOrderItemStatus]);

  const renderItem = useCallback(
    ({item}: {item: OrderItems; index: number}) => {
      return (
        <PackOrderItem
          orderItem={item}
          onClick={onClick}
          packingFinished={packingFinished}
          dropItem={dropItem}
          unpackItem={unpackItem}
        />
      );
    },
    [dropItem, onClick, packingFinished, unpackItem],
  );

  const Separator = useCallback(() => <Divider style={styles.divider} />, []);

  return (
    <Box>
      <CustomFlatList
        data={sortedPackOrderItem}
        renderItem={renderItem}
        ItemSeparatorComponent={Separator}
      />
      <DropItemModalBody
        orderItemId={orderItem?.id || 0}
        onClose={closeModal}
        disableNavigation={true}
        disableState={orderLoading}
        hasListnerReq
        open={showModal}
      />
      <UnpackItemModal
        onSubmit={changedOrderItemStatus}
        open={showUnpackModal}
        onClose={() => setShowUnpackModal(false)}
        loading={loading}
      />
    </Box>
  );
}

export default React.memo(PackOrderItemList);
