import {Box, HBox} from '@buncha/components/core/view';
import {HomeScreens, ShopperScreens} from '@buncha/home/navigation/types';
import React, {useCallback} from 'react';

import {ChevronRightIcon} from 'native-base';
import {CustomFlatList} from '@buncha/components/core/list';
import {H5Text} from '@buncha/components/core/text';
import {ItemScannerGlobalActions} from '@buncha/itemDetails/reducer';
import {PendingUPCOrder} from '@buncha/UPCShifts/types';
import StoreLogo from '@buncha/common/components/StoreLogo';
import {StyleSheet} from 'react-native';
import {Touchable} from '@buncha/components/core/touchable';
import {navigation} from '../../../../config/navigation';
import {useAppDispatch} from '@buncha/config/redux/hooks';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {useUPCShiftContext} from '@buncha/UPCShifts/hooks/UPCShiftsCreation';

const StoreList = () => {
  const {state} = useUPCShiftContext();
  const theme = useColorThemeType('main');
  const appDispatch = useAppDispatch();
  const pendingShifts = state?.pendingUPCOrders || [];

  const navigateToBatchPage = useCallback(
    (data: PendingUPCOrder) => {
      appDispatch(
        ItemScannerGlobalActions.setSelectedCatalogStoreForUPCRun(data),
      );
      navigation.navigate(HomeScreens.Shopper, {
        screen: ShopperScreens.BatchDetailsPage,
        params: {isUPCMode: true, upcOrderId: data?.id, runId: 0},
      });
    },
    [appDispatch],
  );

  const renderItem = useCallback(
    ({item, index}: {item: PendingUPCOrder; index: number}) => {
      return (
        <Touchable
          key={index}
          style={[
            styles.storeContainer,
            {
              borderColor: theme?.text?.gray?.[300],
            },
          ]}
          onPress={() => navigateToBatchPage(item)}>
          <HBox>
            <StoreLogo
              boxStyle={styles.boxStyle}
              imageBoxStyle={styles.imageStyle}
              storeLogoUrl={item.storeLogo}
            />
            <Box style={[styles.typoContainer]}>
              <H5Text
                style={[
                  styles.storeTypo,
                  {
                    color: theme?.text?.blue[300],
                  },
                ]}>
                {item.storeName}
              </H5Text>
            </Box>
          </HBox>
          <Box style={[styles.typoContainer]}>
            <ChevronRightIcon />
          </Box>
        </Touchable>
      );
    },
    [navigateToBatchPage, theme?.text?.blue, theme?.text?.gray],
  );

  return (
    <CustomFlatList
      showsVerticalScrollIndicator={false}
      style={[styles.pendingUPCOrderContainer]}
      data={pendingShifts}
      renderItem={renderItem}
    />
  );
};

export default React.memo(StoreList);

const styles = StyleSheet.create({
  headerTypo: {
    textAlign: 'left',
    fontWeight: '300',
  },
  typoContainer: {
    justifyContent: 'center',
  },
  minimumItemCellTextBox: {
    paddingVertical: 15,
  },
  storeContainer: {
    paddingVertical: 15,
    // borderTopWidth: 0.25,
    borderBottomWidth: 0.5,
    justifyContent: 'space-between',
    // maxHeight: 20,
    flexDirection: 'row',
  },
  storeTypo: {
    textAlign: 'left',
    marginLeft: 20,
    fontWeight: '400',
    // fontSize: scaleFonts(14),
  },
  boxStyle: {
    height: 44,
    width: 44,
  },
  imageStyle: {
    height: 37,
    width: 37,
  },
  pendingUPCOrderContainer: {
    // height: Dimensions.get('screen').height * 0.35,
  },
});
