import {Box, HBox} from '../../view';
import React, {useEffect, useState} from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';

import {CustomRadioProps} from './type';
import If from '../../../conditional/If';
import {getWidth} from '../../../../utils/common';
import {useColorThemeType} from '../../../../appStyles/hooks/theme';

/**
 * @example
  <Choice.CustomRadio
    colorThemeType="default"
    data={[
      { name: 'Stuffed Potato', id: 1 },
      { name: 'Stuffed Garlic Bread', id: 2 },
    ]}
    setValue={(id: any) => {
      // eslint-disable-next-line no-console
      console.log(id);
    }}
    // radioStyle={{ borderRadius: 5 }}
    // containerStyle={{ marginTop: 5 }}
    // textStyle={{ color: 'blue' }}
  />
 */

const CustomRadio: React.FC<CustomRadioProps> = props => {
  const theme = useColorThemeType();
  const circleStyle = {
    ...styles.circle,
    borderColor: theme?.icon.green[100],
  };
  const smallCircleStyle = {
    ...styles.smallCircle,
    backgroundColor: theme?.icon.green[100],
  };
  const firstId = props.defaultValue || 0;

  const [value, setValue] = useState(firstId);

  useEffect(() => {
    props.setValue(firstId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRadio = (id: any) => {
    let data = (
      <TouchableOpacity
        style={[circleStyle, props.radioStyle]}
        onPress={() => {
          props.setValue(id);
          setValue(id);
        }}
      />
    );
    if (value === id)
      data = (
        <TouchableOpacity style={[circleStyle, props.radioStyle]}>
          <Box style={[smallCircleStyle, props.radioStyle]} />
        </TouchableOpacity>
      );

    return data;
  };

  return props.data.map((item: any) => {
    return (
      <HBox key={item.id} style={props.containerStyle}>
        {getRadio(item.id)}
        <If condition={props.cellNode}>{props.cellNode(item)}</If>
      </HBox>
    );
  });
};

export default React.memo(CustomRadio);

const styles = StyleSheet.create({
  circle: {
    height: 20,
    width: getWidth(20),
    borderRadius: 50,
    borderWidth: 1,
    justifyContent: 'center',
  },
  smallCircle: {
    height: 10,
    width: getWidth(10),
    borderRadius: 50,
    alignSelf: 'center',
  },
});
