import {CustomText, H5Text} from '../../../../components/core/text';
import React, {useCallback} from 'react';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../appStyles/hooks/theme';

import AppModal from '../../../../common/components/CustomModal/AppModal';
import {HBox} from '../../../../components/core/view';
import {styles} from './style';

interface OrganicReminderModalProps {
  open: boolean;
  onClose: Function;
}

const OrganicReminderModal: React.FC<OrganicReminderModalProps> = props => {
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');

  const handleClose = useCallback(() => {
    props?.onClose();
  }, [props]);
  return (
    <AppModal
      isOpen={props?.open}
      onClose={() => props?.onClose()}
      topCTAHandelr={handleClose}
      title={'Reminder'}
      hideBottomCta
      modalBody={
        <HBox style={styles.modalBody}>
          <H5Text>This is an </H5Text>

          <HBox
            style={[
              styles.highlightText,
              {
                backgroundColor: theme?.background?.orange?.[100],
              },
            ]}>
            <CustomText
              style={{
                fontFamily: muliFont[700].normal,
                color: theme?.text?.white?.[100],
              }}>
              Organic
            </CustomText>
          </HBox>
          <H5Text> item</H5Text>
        </HBox>
      }
      ctaTopTitle={'OK'}
      topCTAColor={theme?.background.green[100]}
    />
  );
};

export default React.memo(OrganicReminderModal);
