import CustomText from './CustomText';
import {CustomTextProps} from './type';
import Font from '../../../appStyles/style/font';
import React from 'react';

import {StyleSheet, Text} from 'react-native';
import {useFontTheme} from '../../../appStyles/hooks/theme';

const H2Text: React.FC<CustomTextProps<Text>> = React.forwardRef(
  (props, ref) => {
    const fontTheme = useFontTheme(props.fontTheme || 'muli');
    const propsToSpread = {
      ...props,
      style: [styles.H2Text, {fontFamily: fontTheme[900].normal}, props.style],
    };
    return (
      <CustomText ref={ref} {...propsToSpread}>
        {props.children}
      </CustomText>
    );
  },
);

const styles = StyleSheet.create({
  H2Text: {
    ...Font.FontSize.fontH2,
  },
});

export default React.memo(H2Text);
