import {H5Text, H6Text} from '../../../components/core/text';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {HBox} from '../../../components/core/view';
import React from 'react';
import {StyleSheet} from 'react-native';

interface OpenShiftAccordianHeaderProps {
  heading: string;
  shiftCount: number;
}

const OpenShiftAccordianHeader: React.FC<
  OpenShiftAccordianHeaderProps
> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  return (
    <HBox style={styles.headerBox}>
      <H5Text
        style={{
          fontFamily: muli?.[700]?.normal,
          color: theme?.text?.blue?.[300],
        }}>
        {props?.heading}
      </H5Text>
      <HBox
        style={[
          styles.counterBox,
          {backgroundColor: theme?.background?.gray?.[200]},
        ]}>
        <H6Text>{props?.shiftCount}</H6Text>
      </HBox>
    </HBox>
  );
};

export default React.memo(OpenShiftAccordianHeader);

const styles = StyleSheet.create({
  headerBox: {
    flex: 1,
    justifyContent: 'space-between',
    // borderWidth: 1,
    marginRight: 10,
  },
  counterBox: {
    borderRadius: 30,
    paddingHorizontal: 15,
  },
});
