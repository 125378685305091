import {DeepLinkParams, NavigateTo} from './type';
import {ShopperNav, homeNav} from '@buncha/home/navigation';

import {CommonScreens} from '../../common/navigation/types';
import {IsUrlValid} from '@buncha/utils/common';
import {MainScreens} from '../types';
import {intercom} from '../../config/analytics/intercom';
import {navigation} from '../../config/navigation';
import {onBoardingNav} from '../../onBoarding/navigation';

const deepLinkInfo: {[key: string]: (params: object) => void} = Object.assign(
  {},
  onBoardingNav,
  homeNav,
  ShopperNav,
);

export class DeepLink {
  webView(info: DeepLinkParams) {
    let billboardTargetUrl = info.targetUrl || '';
    billboardTargetUrl = billboardTargetUrl.trim();
    const isUrlValid = IsUrlValid(billboardTargetUrl);
    if (isUrlValid)
      navigation.navigate(MainScreens.Common, {
        screen: CommonScreens.Webview,
        params: {uri: billboardTargetUrl},
      });
  }

  navigate(info: DeepLinkParams) {
    switch (info.navigateTo) {
      case NavigateTo.webview:
        this.webView(info);
        break;
      case NavigateTo.intercomCarousel:
        const payload = info.screenPayload.carouselId || '';
        intercom.showCarousel(String(payload));
        break;
      case NavigateTo.screen:
        try {
          const func = deepLinkInfo[info.screenName];
          func(info.screenPayload);
        } catch (err: any) {}
        break;
      default:
        break;
    }
  }
}
