import {Platform} from 'react-native';

/**
 *
 * @returns string
 */
export function getOs() {
  return Platform.OS.toString();
}

const expression = new RegExp(/^\d*(\.\d{0,2})?$/);

export const checkPriceDecimals = (value: string) => {
  try {
    return expression.test(value);
  } catch (error: any) {
    return false;
  }
};
