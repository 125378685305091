import {useAppMultiDispatch, useAppSelector} from '../../config/redux/hooks';
import {useCallback, useEffect, useState} from 'react';

import {Analytics} from '../../config/analytics/analytics';
import {CURRENT_USER} from '../../config/graphQL/documentNode/queries';
import {OnBoardingScreens} from '../navigation/types';
import {UserStub} from '../types';
import {gqlService} from '../../config/graphQL';
import {onBoardingActions} from '../reducer';
import {useRoute} from '@react-navigation/native';

export function useCheckUserDetail(timeOut: number) {
  const onboardingState = useAppSelector(state => state.onboarding);
  const loginToken = onboardingState?.userAuthData?.loginToken;
  const dispatch = useAppMultiDispatch();
  const route = useRoute();

  const [checkingUserDetails, setCheckingUserDetails] = useState(true);

  const cpVersion = useAppSelector(gState => gState.home.codepushLatestVersion);

  const handleUserInfo = useCallback(
    (userInfo: UserStub | undefined, waitForSplash: number) => {
      gqlService?.setCurrentUser(userInfo?.id);
      if (userInfo)
        setTimeout(() => {
          dispatch([onBoardingActions.setCurrentUser(userInfo)]);
          Analytics.setIdentifiedUserAttribute(userInfo, cpVersion);
          return setCheckingUserDetails(false);
        }, waitForSplash);
    },
    [cpVersion, dispatch],
  );

  async function checkUser() {
    await gqlService?.getLoginToken();
    if (route.name === OnBoardingScreens.Login && !loginToken?.length) {
      setCheckingUserDetails(false);
      return;
    }

    if (!loginToken?.length) {
      setCheckingUserDetails(false);
      return;
    }

    try {
      setCheckingUserDetails(true);
      const startTime = new Date().getTime();
      const data = await gqlService?.query<{currentUser: UserStub}>({
        query: CURRENT_USER,
        fetchPolicy: 'network-only',
      });
      const endTime = new Date().getTime();
      let waitForSplash;
      const responseTime = endTime - startTime;
      if (responseTime >= timeOut) waitForSplash = 0;
      else waitForSplash = timeOut - responseTime;
      const userInfo = data?.data?.currentUser;
      handleUserInfo(userInfo, waitForSplash);
    } catch (error: any) {
      setCheckingUserDetails(false);
    }
  }

  useEffect(() => {
    checkUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginToken]);

  return {checkingUserDetails};
}
