import {Box, HBox} from '@buncha/components/core/view';
import {
  BuyerData,
  FormDataUpdate,
  MandatoryFieldNames,
} from '@buncha/itemDetails/types/ItemScanner';
import {H4Text, H5Text} from '@buncha/components/core/text';
import {
  ItemScannerAction,
  useItemScannerContext,
} from '@buncha/itemDetails/hooks/ItemScanner';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import font, {scaleFonts} from '@buncha/appStyles/style/font';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import AppModal from '@buncha/common/components/CustomModal/AppModal';
import {ChevronDownIcon} from 'native-base';
import {Cross} from '@buncha/components/core/icon';
import If from '@buncha/components/conditional/If';
import {ItemWeightUnits} from '@buncha/config/utils';
import {PTextInput} from '@buncha/components/core/input/TextInput';
import {Picker} from '@react-native-picker/picker';
import {Platform} from 'react-native';
import {Touchable} from '@buncha/components/core/touchable';
import {checkPriceDecimals} from '@buncha/utils/common';
import {styles} from './style';
import {useAppSelector} from '@buncha/config/redux/hooks';

interface UpdateWeightSectionProps {
  item: BuyerData;
  itemIndex?: number;
}

const UpdateWeightSection = (props: UpdateWeightSectionProps) => {
  const {state, dispatch} = useItemScannerContext();
  const {item, itemIndex = 0} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');

  const units = useAppSelector(
    gState => gState.onboarding.constants?.ITEM_UNITS,
  );

  const [updateWeight, setUpdateWeight] = useState('');
  const [unitModal, setUnitModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState('lb');

  const updateData = state.formData.find(
    data => data.orderItemId === item.orderItemId,
  );

  const weightInfo = updateData?.weightInfo;

  const weightData = weightInfo?.[itemIndex];

  const pointerEvents = updateData?.dropped ? 'none' : undefined;

  const itemUnits = useMemo(() => {
    return (units && JSON.parse(units)) || ItemWeightUnits;
  }, [units]);

  const weightMandatory = useMemo(
    () =>
      Boolean(
        state?.buyerData?.[0]?.mandatoryFields?.find(
          field => field.fieldName === MandatoryFieldNames.weightFound,
        ),
      ),
    [state?.buyerData],
  );

  const handleFormDataUpdate = useCallback(
    (update: FormDataUpdate, updateValue: any) => {
      if (dispatch)
        dispatch(
          ItemScannerAction.setFormData({
            orderItemId: item.orderItemId,
            update,
            updateValue,
            weightIndex: itemIndex,
          }),
        );
    },
    [dispatch, item.orderItemId, itemIndex],
  );

  const toggleUnitModal = useCallback(() => {
    if (!pointerEvents) setUnitModal(unit => !unit);
  }, [pointerEvents]);

  const weightUpdate = useCallback(
    (weight: string) => {
      const intWeight = Number(weight);
      if (checkPriceDecimals(weight) && intWeight < 1000) {
        setUpdateWeight(weight);
        handleFormDataUpdate(FormDataUpdate.weight, intWeight);
      }
    },
    [handleFormDataUpdate],
  );

  const unitUpdate = useCallback(() => {
    handleFormDataUpdate(FormDataUpdate.unit, selectedValue);
    toggleUnitModal();
  }, [handleFormDataUpdate, selectedValue, toggleUnitModal]);

  useEffect(() => {
    if (!weightData) return;
    if (weightData.weight === 0) setUpdateWeight('');
  }, [item.orderItemId, state.formData, weightData]);

  const getBody = useMemo(() => {
    return (
      <Box>
        <Picker
          style={{
            backgroundColor:
              Platform.OS === 'android'
                ? theme?.background?.gray?.[200]
                : undefined,
          }}
          dropdownIconColor={theme?.background?.gray?.[200]}
          selectedValue={selectedValue}
          onValueChange={(itemValue: any) => setSelectedValue(itemValue)}>
          {itemUnits.map((ele: any) => {
            return <Picker.Item key={ele} label={ele} value={ele} />;
          })}
        </Picker>
      </Box>
    );
  }, [itemUnits, selectedValue, theme?.background?.gray]);

  const removeRow = useCallback(() => {
    if (dispatch) dispatch(ItemScannerAction.removeWeight(item.orderItemId));
  }, [dispatch, item.orderItemId]);

  return (
    <Box>
      <HBox style={styles.marginBottom5}>
        <Box>
          <H5Text
            style={{
              color: theme?.text.blue[300],
              fontFamily: muliFont[700].normal,
            }}>
            Item Weight
            <If condition={(updateData?.weightInfo?.length || 0) > 1}>
              {' '}
              - {itemIndex + 1}
            </If>
            <If condition={weightMandatory && itemIndex === 0}>
              <H5Text style={{color: theme?.text.red[100]}}>*</H5Text>
            </If>
          </H5Text>
        </Box>
        <HBox style={styles.padRight10}>
          <PTextInput
            placeholder="0"
            debounce={false}
            placeholderTextColor={theme?.text.gray[300]}
            value={
              updateWeight ||
              (weightData?.weight ? weightData.weight.toString() : '')
            }
            onChangeText={weightUpdate}
            pointerEvents={pointerEvents}
            textAlign="right"
            textAlignVertical="center"
            keyboardType="decimal-pad"
            returnKeyType="done"
            containerStyle={[styles.inputStyle, styles.quantityStyle]}
            style={{
              fontFamily: muliFont[700].normal,
              ...font.FontSize.fontH3,
              color: theme?.text.blue[300],
            }}
          />
          <Touchable onPress={toggleUnitModal}>
            <H4Text
              style={{
                color: theme?.text.blue[300],
              }}>
              {weightData?.unit}
            </H4Text>
          </Touchable>
        </HBox>
      </HBox>
      <HBox style={[styles.downIconContainer]}>
        <If condition={itemIndex + 1 === weightInfo?.length && itemIndex > 0}>
          <Touchable onPress={removeRow}>
            <Cross
              accessible={true}
              accessibilityLabel="Clear"
              width={scaleFonts(18)}
              height={scaleFonts(18)}
              viewBox="0 0 15 15"
              fill={theme?.text.gray[300]}
              style={styles.marRight5}
            />
          </Touchable>
        </If>
        <Box style={styles.downIconSubContainer}>
          <Touchable onPress={toggleUnitModal} activeOpacity={0.5}>
            <ChevronDownIcon style={styles.downIcon} />
          </Touchable>
        </Box>
      </HBox>
      <AppModal
        isOpen={unitModal}
        onClose={toggleUnitModal}
        topCTAHandelr={unitUpdate}
        bottomCTAHandelr={toggleUnitModal}
        title={'Select Unit'}
        modalBody={getBody}
        ctaBottomTitle={'Cancel'}
        ctaTopTitle={'Confirm'}
        topCTAColor={theme?.background.green[100]}
      />
    </Box>
  );
};

export default React.memo(UpdateWeightSection);
