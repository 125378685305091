import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  listContainer: {
    padding: 15,
  },
  listTypo: {
    textAlign: 'left',
    textDecorationLine: 'underline',
  },
  listInsideContainer: {
    justifyContent: 'flex-start',
  },
  boxContainer: {
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  boxContentContainer: {
    alignItems: 'center',
    marginLeft: 8,
    flex: 1,
  },
});
