import {StyleSheet, Switch, SwitchProps} from 'react-native';

import {PSwichProps} from './type';
import React from 'react';
import {getWidth} from '../../../../utils/common';
import {useColorThemeType} from '../../../../appStyles/hooks/theme';

const sizes = {
  sm: 1.0,
  md: 1.3,
  lg: 1.6,
};

const PSwitch: React.FC<PSwichProps> = React.forwardRef((props, ref) => {
  const theme = useColorThemeType();
  const size = props.size || 'sm';
  const formatSwitchProps: SwitchProps = {
    trackColor: {
      true: props.trackColorOn || theme?.icon.green[100],
      false: props.trackColorOff || theme?.icon.gray[200],
    },
    thumbColor: props.value
      ? props.thumbColorOn || theme?.icon.green[200]
      : props.thumbColorOff || theme?.icon.gray[200],
    style: [
      styles.default,
      props.size && {
        transform: [{scaleX: sizes[size]}, {scaleY: sizes[size]}],
      },
      props.style,
    ],
  };
  return <Switch {...props} {...formatSwitchProps} ref={ref} />;
});

export default React.memo(PSwitch);

const styles = StyleSheet.create({
  default: {
    width: getWidth(50),
    transform: [{scaleX: sizes.sm}, {scaleY: sizes.sm}],
  },
});
