import BatchInfo from '../BatchInfo';
import {BatchInfo as BatchInfoType} from '@buncha/AboutShift/types';
import BatchListHeader from '../BatchListHeader';
import {Box} from '@buncha/components/core/view';
import If from '@buncha/components/conditional/If';
import React from 'react';

interface VanLocationIndo {
  batchInfo: BatchInfoType;
}

const VanLocation = (props: VanLocationIndo) => {
  const masterRun = props?.batchInfo.run?.masterRun;

  return (
    <If condition={props?.batchInfo.run?.masterRun}>
      <Box>
        <BatchListHeader headerTitle="Van Load Location" />
        <BatchInfo run={masterRun} hideStoreInfo />
      </Box>
    </If>
  );
};

export default React.memo(VanLocation);
