import {Box, HBox} from '@buncha/components/core/view';
import {Divider, InfoOutlineIcon} from 'native-base';

import {H6Text} from '@buncha/components/core/text';
import {ListHeaderStyle} from './styles';
import React from 'react';
import {Touchable} from '@buncha/components/core/touchable';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

interface Props {
  infoToolTipHandler: () => void;
}

const ListHeader = (props: Props) => {
  const theme = useColorThemeType();
  return (
    <Box
      style={[
        ListHeaderStyle.container,
        {backgroundColor: theme?.background.white[100]},
      ]}>
      <HBox style={ListHeaderStyle.headerBox}>
        <H6Text
          style={[ListHeaderStyle.headerText, {color: theme?.text?.blue[300]}]}>
          Load the orders in the following sequence
        </H6Text>
        <Touchable onPress={props?.infoToolTipHandler}>
          <InfoOutlineIcon color={theme?.icon.blue[300]} />
        </Touchable>
      </HBox>
      <HBox style={ListHeaderStyle.sequenceBox}>
        <H6Text>Van Loading Sequence</H6Text>
        <H6Text>Order Details</H6Text>
      </HBox>
      <Divider style={ListHeaderStyle.divider} />
    </Box>
  );
};

export default React.memo(ListHeader);
