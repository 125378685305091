import {
  BatchDetailTabs,
  GetOrderItemByIdResponse,
  GetOrdersForOrderAssigneeResponse,
  GetRunForBatchDetailResponse,
  GroupedAssignedItems,
  OrderItemStatus,
  OrderItems,
  RunForBatchDetail,
  SetSubstituitonRequestParams,
  SubstitutionItemParams,
  UpdateOrderItemStatusResponse,
  itemsForSection,
  itemsSortBy,
} from '../types';
import {
  DELETE_DELIVERY_IMAGE,
  SET_SUBSTITUTION_STATUS,
  SUBSTITUTE_ITEM_IN_RUN,
  UPDATE_ORDER_ITEM_STATUS,
} from '../../config/graphQL/documentNode/mutation';
import {
  GET_BATCH_DETAIL_RUN,
  GET_ORDERS_FOR_ORDER_ASSIGNEE,
  GET_ORDERS_FOR_ORDER_ASSIGNEE_COUNT,
  GET_ORDER_ITEM_BY_ID_SUBSTITUTE,
  GET_UPC_ORDER_ITEMS,
  GET_UPC_ORDER_ITEMS_COUNT,
} from '../../config/graphQL/documentNode/queries';
import React, {Dispatch, useCallback, useState} from 'react';

import {Analytics} from '../../config/analytics/analytics';
import {ApiState} from '../../common/types/common';
import {EventType} from '../../config/analytics/type';
import {FetchResult} from '@apollo/client';
import {ToastType} from '../../components/composites/notification/type';
import {batchDetailActionsLocal} from './batchDetail';
import {gqlService} from '../../config/graphQL';
import {useToastMessage} from '../../components/composites/notification';

export function useGetOrdersForOrderAssignee(
  dispatch: React.Dispatch<any> | null,
): [
  (runId: number, option: itemsForSection, sortBy?: itemsSortBy) => void,
  boolean,
] {
  const [loading, setLoading] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);

  const setState = useCallback(
    (data: GroupedAssignedItems[], option: itemsForSection) => {
      if (dispatch)
        switch (option) {
          case itemsForSection.todo:
            dispatch(batchDetailActionsLocal.setGroupedAssignedItemsTodo(data));
            break;
          case itemsForSection.pending:
            dispatch(
              batchDetailActionsLocal.setGroupedAssignedItemsInReview(data),
            );
            break;
          case itemsForSection.done:
            dispatch(batchDetailActionsLocal.setGroupedAssignedItemsDone(data));
            break;
          case itemsForSection.all:
            dispatch(batchDetailActionsLocal.setGroupedAssignedItemsAll(data));
            break;
          default:
            return;
        }
    },
    [dispatch],
  );

  const getOrdersForOrderAssignee = useCallback(
    async (runId: number, option: itemsForSection, sortBy?: itemsSortBy) => {
      try {
        setLoading(true);
        const response =
          await gqlService?.query<GetOrdersForOrderAssigneeResponse>({
            query: GET_ORDERS_FOR_ORDER_ASSIGNEE,
            variables: {
              runId: runId,
              sortBy: sortBy,
              option: option,
            },
            fetchPolicy: 'network-only',
          });
        if (response?.data?.getOrdersForOrderAssignee)
          setState(response?.data.getOrdersForOrderAssignee, option);
      } catch (error: any) {
        //use toast
        showErrorToast(error.message);
      } finally {
        setLoading(false);
      }
    },
    [setState, showErrorToast],
  );

  return [getOrdersForOrderAssignee, loading];
}

export function useGetOrdersForOrderAssigneeCount(
  dispatch: React.Dispatch<any> | null,
) {
  const [showErrorToast] = useToastMessage(ToastType.Error);

  const getOrderItemsCount = async (
    runId: number,
    option: itemsForSection,
    sortBy?: itemsSortBy,
  ) => {
    try {
      const response =
        await gqlService?.query<GetOrdersForOrderAssigneeResponse>({
          query: GET_ORDERS_FOR_ORDER_ASSIGNEE_COUNT,
          variables: {
            runId: runId,
            sortBy: sortBy,
            option: option,
          },
          fetchPolicy: 'network-only',
        });
      if (response?.data?.getOrdersForOrderAssignee && dispatch) {
        const totalEntries =
          response?.data.getOrdersForOrderAssignee[0]?.keyTotalEntries;
        Analytics.eventWithProps('getOrdersForOrderAssignee', {
          type: EventType.api,
          runID: runId,
          sortBy: sortBy,
          option: option,
          keyTotalEntries: totalEntries,
        });
        dispatch(batchDetailActionsLocal.setToDoCount(totalEntries));
      }
    } catch (error: any) {
      showErrorToast(error.message);
    }
  };

  return {getOrderItemsCount};
}

export function useGetRunForBatchDetail(): [
  (runId: number) => void,
  ApiState<RunForBatchDetail>,
] {
  const [state, setState] = useState<ApiState<RunForBatchDetail>>({
    loading: false,
    error: undefined,
    data: undefined,
  });

  const getRunForBatchDetail = useCallback(
    async (runId: number) => {
      setState({...state, loading: true});
      try {
        const response = await gqlService?.query<GetRunForBatchDetailResponse>({
          query: GET_BATCH_DETAIL_RUN,
          variables: {
            runId: runId,
          },
          fetchPolicy: 'network-only',
        });
        if (response?.data?.getRun)
          setState({...state, data: response?.data.getRun, loading: false});
      } catch (error: any) {
        //use toast
        setState({...state, loading: false});
      }
    },
    [state],
  );

  return [getRunForBatchDetail, state];
}

export function useUpdateOrderItemStatus() {
  const [changeStatusState, setchangeStatusState] = useState<
    ApiState<OrderItems>
  >({
    loading: false,
    error: undefined,
    data: undefined,
  });
  const [showErrorToast] = useToastMessage(ToastType.Error);

  const updateOrderItemStatus = useCallback(
    async (
      runId: number,
      orderItemStatus: OrderItemStatus,
      orderItemIds: number[],
      callback?: () => void,
    ) => {
      setchangeStatusState({...changeStatusState, loading: true});
      try {
        const response =
          await gqlService?.mutation<UpdateOrderItemStatusResponse>({
            mutation: UPDATE_ORDER_ITEM_STATUS,
            variables: {
              orderItemStatus: orderItemStatus,
              orderItemIds: orderItemIds,
            },
            fetchPolicy: 'network-only',
          });
        if (
          response?.data?.updateOrderItemStatus.itemStatus === orderItemStatus
        ) {
          setchangeStatusState({
            ...changeStatusState,
            data: response?.data.updateOrderItemStatus,
            loading: false,
          });
          if (callback) callback();
        }
      } catch (error: any) {
        showErrorToast(error?.message);
        setchangeStatusState({...changeStatusState, loading: false});
      }
    },
    [showErrorToast, changeStatusState],
  );

  return {updateOrderItemStatus, changeStatusState};
}

export function useGetOrderItemById(): [
  (orderItemId: number) => void,
  ApiState<OrderItems>,
] {
  const [state, setState] = useState<ApiState<OrderItems>>({
    loading: false,
    error: undefined,
    data: undefined,
  });

  const getOrderItemById = useCallback(
    async (orderItemId: number) => {
      setState({...state, loading: true});
      try {
        const response = await gqlService?.query<GetOrderItemByIdResponse>({
          query: GET_ORDER_ITEM_BY_ID_SUBSTITUTE,
          variables: {
            orderItemId: orderItemId,
          },
          fetchPolicy: 'network-only',
        });
        if (response?.data?.getOrderItemById)
          setState({
            ...state,
            data: response?.data.getOrderItemById,
            loading: false,
          });
      } catch (error: any) {
        //use toast
        setState({...state, loading: false});
      }
    },
    [state],
  );

  return [getOrderItemById, state];
}

export function useDeleteReceiptUrl() {
  const [deletingImage, setDeletingImage] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);

  const deleteRunReceipts = async (
    url: string,
    runId: number,
    callback: Function,
  ) => {
    try {
      setDeletingImage(true);
      let res = await gqlService?.mutation({
        mutation: DELETE_DELIVERY_IMAGE,
        variables: {
          runId: runId,
          receiptUrl: url,
        },
        fetchPolicy: 'network-only',
      });
      if (res?.data?.deleteRunreceipt) callback();
      Analytics.eventWithProps('Delete shopper receipt', {
        type: EventType.cta,
        runId: runId,
      });
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setDeletingImage(false);
    }
  };

  return {deletingImage, deleteRunReceipts};
}

export function useSetSubstitutionStatus() {
  const [loading, setLoading] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const setSubstitutionStatus = async (
    params: SetSubstituitonRequestParams,
  ) => {
    setLoading(true);
    try {
      await gqlService?.mutation({
        mutation: SET_SUBSTITUTION_STATUS,
        variables: params,
        fetchPolicy: 'network-only',
      });
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {setSubstitutionStatus, loading};
}

export function useSubstituteItemInRun() {
  const [loading, setLoading] = useState(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const substituteItemInRun = async (params: SubstitutionItemParams) => {
    try {
      setLoading(true);
      await gqlService?.mutation({
        mutation: SUBSTITUTE_ITEM_IN_RUN,
        fetchPolicy: 'network-only',
        variables: params,
      });
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {substituteItemInRun, loading};
}

export function useUPCShift(dispatch: Dispatch<any> | null) {
  const [orderLoading, setOrderLoading] = useState<boolean>(false);
  const [showErrorToast] = useToastMessage(ToastType.Error);

  const fetchUPCOrderItems = async (
    upcOrderId: number,
    option: BatchDetailTabs,
    fetchAll: boolean,
  ) => {
    try {
      setOrderLoading(true);
      const variables = {
        upcOrderId,
        option,
      };
      let res: FetchResult<any> | undefined;

      if (fetchAll)
        res = await gqlService?.query({
          query: GET_UPC_ORDER_ITEMS_COUNT,
          fetchPolicy: 'network-only',
          variables,
        });
      else
        res = await gqlService?.query({
          query: GET_UPC_ORDER_ITEMS,
          fetchPolicy: 'network-only',
          variables,
        });

      if (dispatch) {
        if (option === BatchDetailTabs.done)
          dispatch(
            batchDetailActionsLocal.setUPCItemsDone(
              res?.data?.getUpcOrder?.orderItems?.length,
            ),
          );
        else
          dispatch(
            batchDetailActionsLocal.setUPCItemsTodo(
              res?.data?.getUpcOrder?.orderItems?.length,
            ),
          );
        dispatch(
          batchDetailActionsLocal.setUPCOrderData(res?.data?.getUpcOrder),
        );
      }
    } catch (error: any) {
      showErrorToast(error.message);
    } finally {
      setOrderLoading(false);
    }
  };

  const getUPCShiftOrder = async (
    upcOrderId: number,
    option: BatchDetailTabs,
    fetchAll: boolean,
  ) => {
    if (fetchAll)
      try {
        await Promise.all([
          fetchUPCOrderItems(upcOrderId, BatchDetailTabs.todo, fetchAll),
          fetchUPCOrderItems(upcOrderId, BatchDetailTabs.done, fetchAll),
        ]);
      } catch (error: any) {
        showErrorToast(error.message);
      }
    else
      try {
        await fetchUPCOrderItems(upcOrderId, option, fetchAll);
      } catch (error: any) {
        showErrorToast(error.message);
      }
  };

  return {getUPCShiftOrder, orderLoading};
}
