import React, {useCallback, useEffect, useState} from 'react';

import AppModal from '@buncha/common/components/CustomModal/AppModal';
import {PTextInput} from '@buncha/components/core/input/TextInput';
import {StyleSheet} from 'react-native';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

interface ChatConfirmationModalProps {
  open: boolean;
  message: string;
  closeModal: () => void;
  sendTextToCustomer: (message?: string) => void;
}

const ChatConfirmationModal = (props: ChatConfirmationModalProps) => {
  const theme = useColorThemeType();
  const [text, setText] = useState(props.message);

  const handleConfirm = useCallback(() => {
    props.sendTextToCustomer(text);
  }, [props, text]);

  useEffect(() => {
    setText(props.message);
  }, [props.message]);

  return (
    <AppModal
      isOpen={props?.open}
      onClose={props.closeModal}
      topCTAHandelr={handleConfirm}
      bottomCTAHandelr={props.closeModal}
      modalBody={
        <PTextInput
          value={text}
          numberOfLines={10}
          placeholder="Message"
          textAlignVertical={'top'}
          multiline={true}
          debounce={false}
          returnKeyType="done"
          style={[styles.input, {color: theme?.text?.blue?.[300]}]}
          onChangeText={setText}
          containerStyle={[
            styles.inputContainer,
            {
              borderColor: theme?.text?.gray?.[200],
            },
          ]}
        />
      }
      title={'Edit your message'}
      ctaBottomTitle={'Cancel'}
      ctaTopTitle={'Send Message'}
      topCTAColor={theme?.background.green[100]}
    />
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    borderWidth: 1,
    width: '100%',
    borderRadius: 15,
    marginTop: 10,
    zIndex: 100,
  },
  input: {
    height: 150,
    marginTop: 5,
  },
});

export default React.memo(ChatConfirmationModal);
