import {
  BagsHistory,
  CheckSubmittedBagCountResponse,
  SubmitStartingBagCountResponse,
} from '../types';
import {useCallback, useState} from 'react';

import {Analytics} from '@buncha/config/analytics/analytics';
import {ApiState} from '../../common/types/common';
import {CHECK_SUBMIT_BAG_COUNT} from '../../config/graphQL/documentNode/queries';
import {EventType} from '@buncha/config/analytics/type';
import {SUBMIT_STARTING_BAG_COUNT} from '../../config/graphQL/documentNode/mutation';
import {ToastType} from '../../components/composites/notification/type';
import {gqlService} from '../../config/graphQL';
import {useToastMessage} from '../../components/composites/notification';

export function useSubmitStartingBagCount(): [
  (runId: number, bagCount: number) => void,
  ApiState<BagsHistory>,
] {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [state, setState] = useState<ApiState<BagsHistory>>({
    loading: false,
    error: undefined,
    data: undefined,
  });

  const func = useCallback(
    async (runId: number, bagCount: number) => {
      if (!runId || !bagCount) return;
      setState({...state, loading: true});
      try {
        const response =
          await gqlService?.mutation<SubmitStartingBagCountResponse>({
            mutation: SUBMIT_STARTING_BAG_COUNT,
            variables: {
              runBagCount: {runId: runId, bagCount: bagCount},
            },
            fetchPolicy: 'network-only',
          });
        if (response?.data?.submitStartingBagCount) {
          Analytics.eventWithProps('Bag Tracking submit count', {
            type: EventType.cta,
            bagCount: response?.data?.submitStartingBagCount,
            runId: runId,
          });
          setState({
            ...state,
            data: response?.data?.submitStartingBagCount,
            loading: false,
          });
        }
      } catch (error: any) {
        setState({...state, loading: false});
        showErrorToast(error?.message);
      }
    },
    [showErrorToast, state],
  );

  return [func, state];
}

export function useCheckSubmittedBagCount(): [
  (runId: number) => void,
  ApiState<BagsHistory>,
] {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [state, setState] = useState<ApiState<BagsHistory>>({
    loading: false,
    error: undefined,
    data: undefined,
  });

  const func = useCallback(
    async (runId: number) => {
      if (!runId) return;
      setState({...state, loading: true});
      try {
        const response =
          await gqlService?.query<CheckSubmittedBagCountResponse>({
            query: CHECK_SUBMIT_BAG_COUNT,
            variables: {
              runId: runId,
            },
            fetchPolicy: 'network-only',
          });
        if (response?.data?.checkSubmittedBagCount)
          setState({
            ...state,
            data: response?.data?.checkSubmittedBagCount,
            loading: false,
          });
      } catch (error: any) {
        setState({...state, loading: false});
        showErrorToast(error?.message);
      }
    },
    [showErrorToast, state],
  );

  return [func, state];
}
