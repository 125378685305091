import {StyleSheet, View} from 'react-native';

import Box from './Box';
import {BoxProps} from './type';
import React from 'react';

const HBox: React.FC<BoxProps<View>> = React.forwardRef((props, ref) => {
  const propsToSpread = {
    ...props,
    style: [styles.HBox, props.style],
  };
  return (
    <Box {...propsToSpread} ref={ref}>
      {props.children}
    </Box>
  );
});

const styles = StyleSheet.create({
  HBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default React.memo(HBox);
