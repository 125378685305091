import {Analytics, AnalyticsPlatform} from '../analytics/analytics';

import {ErrorInfo} from 'react';
import {ErrorResponse} from '@apollo/client/link/error';

// eslint-disable-next-line no-shadow
export enum ErrorSource {
  graphql = 'graphql',
  mixpanel = 'mixpanel',
  intercom = 'intercom',
  codepush = 'codepush',
  branch = 'branch',
}

export default class ErrorService {
  report(
    err: Error | ErrorResponse,
    errorSrouce: ErrorSource,
    errorInfo?: ErrorInfo,
    name = 'error',
  ) {
    if (errorSrouce === ErrorSource.graphql) {
      let errorResponse = err as ErrorResponse;
      const networkError: any = errorResponse?.networkError;
      let errorName = networkError?.message;
      let errorType = networkError?.name;
      let statusCode = networkError?.statusCode;
      if (errorResponse?.graphQLErrors) {
        errorName = errorResponse?.graphQLErrors?.[0].message;
        errorType = errorResponse?.graphQLErrors?.[0].name;
      }
      Analytics.eventWithProps(
        name,
        {
          type: 'Error',
          error: errorName,
          errorType: errorType,
          errorCode: statusCode,
          errorInfo: JSON.stringify(errorResponse),
          oprationName: errorResponse?.operation?.operationName,
          variables: JSON.stringify(errorResponse?.operation?.variables),
          errorSource: errorSrouce,
          response: JSON.stringify(errorResponse?.response),
          operation: JSON.stringify(errorResponse?.operation),
          graphQLErrors: JSON.stringify(errorResponse?.graphQLErrors),
          networkError: JSON.stringify(errorResponse?.networkError),
        },
        AnalyticsPlatform.all,
      );
      return;
    }
    let error = err as Error;
    Analytics.eventWithProps(
      name,
      {
        type: 'Error',
        error: error?.message,
        errorType: error?.name,
        errorInfo: JSON.stringify(error),
        errorSource: errorSrouce,
      },
      AnalyticsPlatform.all,
    );
  }
}
