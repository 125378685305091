import {
  DeliveryLocationStatus,
  GroupedAssignedItems,
  OrderItemStatus,
  OrderItems,
  RunForBatchDetail,
  itemsForSection,
  itemsSortBy,
} from '../../../batchDetails/types';
import {Divider, Skeleton} from 'native-base';
import {HomeScreens, ShopperScreens} from '@buncha/home/navigation/types';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {RefreshControl, RefreshControlProps} from 'react-native';
import {
  useGetItemStatusCount,
  useGetMinimumDeliveryStatus,
  useOrderAssignment,
  useSetDeliveryLocationStatusOfAssignedOrders,
} from '../../../batchDetails/hooks/OrderOperation';
import {
  useGetOrdersForOrderAssignee,
  useUpdateOrderItemStatus,
} from '../../../batchDetails/hooks/BatchDetails';

import {Analytics} from '@buncha/config/analytics/analytics';
import {BottomButton} from '../../../components/composites/Button';
import {Box} from '../../../components/core/view';
import {CustomFlatList} from '../../../components/core/list';
import {EventType} from '@buncha/config/analytics/type';
import PackItemAccordion from '../PackItemAccordion';
import RecheckBottomSheet from '../RecheckBottomSheet';
import {RunType} from '@buncha/AboutShift/types';
import {SpinnerComponent} from '../../../components/core/loader';
import listener from '../../../config/listener';
import {navigation} from '@buncha/config/navigation';
import {styles} from './styles';
import {useBatchDetailContext} from '../../../batchDetails/hooks/batchDetail';
import {useColorThemeType} from '../../../appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';

interface PackItemListProps {
  runDetail?: RunForBatchDetail;
  navigateToReceiptScreen: () => void;
  assignmentModal: (value: boolean) => void;
}

function PackItemList(props: PackItemListProps) {
  const {runDetail, assignmentModal} = props;

  const theme = useColorThemeType();
  const {state, dispatch} = useBatchDetailContext();

  const {getOrderAssignee} = useOrderAssignment(dispatch);
  const [getOrdersForOrderAssignee, orderLoading] =
    useGetOrdersForOrderAssignee(dispatch);
  const {updateOrderItemStatus, changeStatusState} = useUpdateOrderItemStatus();
  const [setDeliveryLocationStatusOfAssignedOrders, packState] =
    useSetDeliveryLocationStatusOfAssignedOrders(dispatch);
  const [getItemStatusCount, countState] = useGetItemStatusCount(dispatch);
  const route = useRoute();

  const [getMinimumDeliveryStatus, minStatusState] =
    useGetMinimumDeliveryStatus(dispatch);

  const [recheckSheet, setRecheckSheet] = useState(false);
  const [toDriver, setToDriver] = useState(false);

  let timeOutId: any = useRef();

  const receipNotMandateMerchant =
    runDetail?.store?.isMerchant && runDetail?.store?.storeMaster?.id === 14;

  const routeParams: any = route?.params;
  const [runId, setRunId] = useState(routeParams?.runId);
  const isLoading = Boolean(
    orderLoading ||
      changeStatusState.loading ||
      countState.loading ||
      packState.loading,
  );

  const disableButton = useMemo(() => {
    return Boolean(isLoading || state.remainingItemCount[0]);
  }, [isLoading, state.remainingItemCount]);

  useEffect(() => {
    getOrderAssignee(runId as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (runDetail) {
      getMinimumDeliveryStatus(runDetail.id);
      setRunId(runDetail.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runDetail]);

  const packingFinished =
    minStatusState?.data === DeliveryLocationStatus.toBeAssigned;
  const orderPacked =
    minStatusState?.data === DeliveryLocationStatus.orderPacked;

  const buttonTitle = useMemo(() => {
    if (orderPacked || packingFinished) {
      if (receipNotMandateMerchant) return 'Finish Packing';
      if (runDetail?.receiptImageUrls?.length) return 'Finish Packing';
    }
    return 'Recheck Items';
  }, [
    orderPacked,
    packingFinished,
    receipNotMandateMerchant,
    runDetail?.receiptImageUrls?.length,
  ]);

  const onRefresh = useCallback(() => {
    if (runId) {
      getMinimumDeliveryStatus(runId);
      getOrdersForOrderAssignee(
        runId,
        itemsForSection.all,
        itemsSortBy.customer,
      );
      getItemStatusCount(runId, [OrderItemStatus.notPacked]);
    }
  }, [
    getItemStatusCount,
    getMinimumDeliveryStatus,
    getOrdersForOrderAssignee,
    runId,
  ]);

  const toggleSheet = useCallback(() => {
    setRecheckSheet(!recheckSheet);
  }, [recheckSheet]);

  const toggleToDriver = useCallback(() => {
    assignmentModal(true);
    setToDriver(!toDriver);
  }, [assignmentModal, toDriver]);

  const toggleModal = useCallback(async () => {
    setRecheckSheet(false);
    if (runId) {
      Analytics.eventWithProps('Proceed', {
        type: EventType.cta,
        runID: runId,
      });
      await setDeliveryLocationStatusOfAssignedOrders(
        runId,
        DeliveryLocationStatus.orderPacked,
        state?.orderIdsToBeSend,
        toggleSheet,
      );
      onRefresh();
      return;
    }
    listener.emit('orderPackRefresh');
    assignmentModal(true);
  }, [
    assignmentModal,
    onRefresh,
    runId,
    setDeliveryLocationStatusOfAssignedOrders,
    state?.orderIdsToBeSend,
    toggleSheet,
  ]);

  const orderPickState = useCallback(async () => {
    const runType = runDetail?.runType;
    if (runId && orderPacked && runDetail?.receiptImageUrls) {
      await setDeliveryLocationStatusOfAssignedOrders(
        runId,
        DeliveryLocationStatus.toBeAssigned,
        state?.orderIdsToBeSend,
        () => {
          if (runType !== RunType.normal && runDetail?.masterRun?.id)
            navigation.navigate(HomeScreens.Shopper, {
              screen: ShopperScreens.VanLoadSite,
              params: {runId: runDetail?.id},
            });
          else toggleToDriver();
        },
      );

      return onRefresh();
    } else if (runId && packingFinished && runDetail?.receiptImageUrls) {
      Analytics.eventWithProps('Pass to Driver modal', {
        type: EventType.cta,
        runID: runId,
        packingFinished: packingFinished,
      });
      assignmentModal(true);
      return;
    }
    Analytics.eventWithProps('Recheck Item (Bottom Sheet)', {
      type: EventType.cta,
      runID: runId,
    });
    return toggleSheet();
  }, [
    assignmentModal,
    onRefresh,
    orderPacked,
    packingFinished,
    runDetail,
    runId,
    setDeliveryLocationStatusOfAssignedOrders,
    state?.orderIdsToBeSend,
    toggleSheet,
    toggleToDriver,
  ]);

  const debounce = useCallback(
    (itemRunId: number, delay: number) => {
      if (timeOutId.current) {
        clearInterval(timeOutId.current);
        timeOutId.current = null;
      }
      timeOutId.current = setTimeout(() => {
        if (!countState.loading) {
          getItemStatusCount(itemRunId, [
            OrderItemStatus.notPicked,
            OrderItemStatus.notPacked,
          ]);
          getOrdersForOrderAssignee(
            itemRunId,
            itemsForSection.all,
            itemsSortBy.customer,
          );
          getMinimumDeliveryStatus(runId);
        }
      }, delay);
    },
    [
      countState.loading,
      getItemStatusCount,
      getMinimumDeliveryStatus,
      getOrdersForOrderAssignee,
      runId,
    ],
  );

  const getItemStatus = useCallback((itemStatus: OrderItemStatus) => {
    switch (itemStatus) {
      case OrderItemStatus.dropped:
        return OrderItemStatus.notPacked;
      case OrderItemStatus.packed:
        return OrderItemStatus.notPacked;

      default:
        return OrderItemStatus.packed;
    }
  }, []);

  const changedItemStatus = useCallback(
    (orderItem: OrderItems) => {
      const itemStatus = getItemStatus(orderItem?.itemStatus);
      if (runId) {
        Analytics.eventWithProps('Item Status changed', {
          type: EventType.cta,
          runID: runId,
          itemStatus: itemStatus,
          orderItemId: orderItem.id,
        });
        updateOrderItemStatus(runId, itemStatus, [orderItem.id]);
        if (orderItem.itemStatus === OrderItemStatus.dropped)
          return debounce(runId, 0);
        debounce(runId, 1000);
      }
    },
    [debounce, getItemStatus, runId, updateOrderItemStatus],
  );

  const renderItem = useCallback(
    ({item}: {item: GroupedAssignedItems; index: number}) => {
      const deliveryStatus =
        item?.metaData?.order?.deliveryLocation?.deliveryStatus;
      const isPackingFinished =
        deliveryStatus &&
        deliveryStatus === DeliveryLocationStatus.toBeAssigned;
      return (
        <PackItemAccordion
          changedItemStatus={changedItemStatus}
          packingFinished={isPackingFinished}
          item={item}
          orderLoading={orderLoading}
          runDetail={runDetail}
        />
      );
    },
    [changedItemStatus, orderLoading, runDetail],
  );

  const Separator = useCallback(() => <Divider style={styles.divider} />, []);

  useEffect(() => {
    if (runId)
      getOrdersForOrderAssignee(
        runId,
        itemsForSection.all,
        itemsSortBy.customer,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId]);

  useEffect(() => {
    if (runId) getItemStatusCount(runId, [OrderItemStatus.notPacked]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId]);

  useEffect(() => {
    const itemStatusCount = listener.addListener(
      'orderPackDropRefresh',
      onRefresh,
    );
    return itemStatusCount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box style={styles.parentBox}>
      {Separator()}

      <CustomFlatList
        refreshControl={
          (
            <RefreshControl
              refreshing={countState.loading}
              onRefresh={onRefresh}
              tintColor={theme?.background.gray[100]}
              style={styles.refreshControl}
            />
          ) as React.ReactElement<RefreshControlProps>
        }
        data={state.groupedAssignedItems.all}
        renderItem={renderItem}
        ItemSeparatorComponent={Separator}
        ListFooterComponent={Separator}
        loading={orderLoading}
        showsVerticalScrollIndicator={false}
        loadingSkeleton={
          <Box style={styles.skeletonContainer}>
            <Skeleton marginTop={5} height={75} />
          </Box>
        }
      />
      <BottomButton
        title={buttonTitle}
        disabled={disableButton}
        onPress={orderPickState}
        loadingIndicator={<SpinnerComponent />}
        bgColor={theme?.background.blue[100]}
      />
      <RecheckBottomSheet
        isOpen={recheckSheet}
        onClose={toggleSheet}
        onSubmit={toggleModal}
      />
    </Box>
  );
}

export default React.memo(PackItemList);
