import {KeyboardAvoidingView, Platform, StyleSheet} from 'react-native';

import React from 'react';

const KeyboardAvoidLayout = (props: {children: React.ReactNode}) => {
  const isIOS = Platform.OS === 'ios';

  return (
    <KeyboardAvoidingView
      behavior={isIOS ? 'padding' : undefined}
      keyboardVerticalOffset={isIOS ? 0 : 25}
      style={styles.keyboardView}>
      {props.children}
    </KeyboardAvoidingView>
  );
};

export default React.memo(KeyboardAvoidLayout);

const styles = StyleSheet.create({
  keyboardView: {
    flex: 1,
    padding: 0,
  },
});
