import {Dimensions, StyleSheet} from 'react-native';

import {getWidth} from '../../../utils/common';

const width = Number(getWidth(Dimensions.get('screen').width));

export const styles = StyleSheet.create({
  mainContainer: {
    marginTop: 10,
    marginHorizontal: 20,
  },
  storeLogoBox: {
    borderWidth: 3,
    borderRadius: 50,
  },
  storeLogo: {
    borderRadius: 50,
    height: 50,
    width: 50,
    overflow: 'hidden',
  },
  runInfoContainer: {
    marginLeft: 10,
  },
  runName: {
    textAlign: 'left',
    width: width / 2.3,
  },
  runIdContainer: {
    borderRadius: 30,
  },
  runIdText: {
    textAlign: 'left',
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
});
