import CheckIcon from '@buncha/components/core/icon/CheckIcon';
import EmptyEllipse from '@buncha/components/core/icon/EmptyEllipse';
import If from '@buncha/components/conditional/If';
import React from 'react';
import {styles} from './style';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

interface PhaseComponentProps {
  completed: boolean;
}
const PhaseIconComponent: React.FC<PhaseComponentProps> = props => {
  const theme = useColorThemeType('main');
  const completed = props?.completed;
  return (
    <React.Fragment>
      <If condition={!completed}>
        <EmptyEllipse
          height={20}
          width={20}
          style={styles.icon}
          fill={theme?.icon?.green?.[100]}
          viewBox="-2 1 20 10"
        />
      </If>
      <If condition={completed}>
        <CheckIcon
          height={20}
          width={20}
          style={styles.icon}
          fill={theme?.icon?.green?.[100]}
          viewBox="-3 3 50 10"
        />
      </If>
    </React.Fragment>
  );
};

export default React.memo(PhaseIconComponent);
