import {FileUploadData, UploadingStatus} from '@buncha/config/fileUpload/type';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {
  UpdateUploadingStatus,
  useUploadImage,
} from '@buncha/config/fileUpload/hook';
import {useAppDispatch, useAppSelector} from '../../config/redux/hooks';
import {
  useGetUnreadMemoCount,
  useHomeReducer,
  useSetConstants,
  useVersionCheck,
} from '../hooks/home';

import AboutShiftPage from '../components/AboutShiftPage';
import {Analytics} from '../../config/analytics/analytics';
import BagCountBanner from '../components/BagCountBanner';
import BottomSheet from '../../components/composites/bottomSheet';
import {ChatModes} from '@buncha/batchDetails/types/Chat';
import ChatOpener from '@buncha/components/composites/ChatOpener';
import {EventType} from '../../config/analytics/type';
import Header from '../../components/composites/Header/Header';
import {HomeContext} from '../context/homeContext';
import HomeHeader from '../component/HomeHeader';
import If from '@buncha/components/conditional/If';
import KeyboardAvoidLayout from '../../components/composites/keyboardAvoidLayout';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {StorageKeys} from '@buncha/config/storage/type';
import UnreadMemoModal from '../components/unreadMemoModal';
import VersionCheckSheet from '../components/VersionCheckSheet';
import WhenIWorkBottomSheet from '../components/whenIWorkBottomSheet';
import {getBase64FromURI} from '@buncha/utils/commonWithoutWeb';
import {gqlService} from '../../config/graphQL';
import {isWeb} from '@buncha/utils/common';
import {navigation} from '@buncha/config/navigation';
import {notification} from '../../config/notification';
import {storage} from '@buncha/config/storage';
import {styles} from './style';
import {useGetAssignedShift} from '../hooks/AssinedShift';
import {useIsFocused} from '@react-navigation/native';

type Props = NativeStackScreenProps<any, any>;

const YourSchedule: React.FC<Props> = () => {
  const [state, dispatch] = useHomeReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);

  const appDispatch = useAppDispatch();
  const {setConstants} = useSetConstants(appDispatch);
  const {getUnReadMemoCount} = useGetUnreadMemoCount(appDispatch);
  const unreadMemoCount = useAppSelector(
    gState => gState.onboarding.unreadMemoCount,
  );
  const onBoardingState = useAppSelector(gstate => gstate.onboarding);
  const loginToken = onBoardingState.userAuthData?.loginToken;
  const whenIWorkData = onBoardingState.currentUser?.WhenIWorkInfo;

  const [showModal, setShowModal] = useState(false);
  const [getVersion, versionData] = useVersionCheck();
  const isFocused = useIsFocused();
  const versionInfo = versionData.data;

  const {uploadeImageService} = useUploadImage();

  const [getAssignedShift, loadingShift] = useGetAssignedShift(appDispatch);

  useLayoutEffect(() => {
    if (whenIWorkData?.login?.refreshToken?.length)
      gqlService?.setWhenIWorkLoginCredential(
        whenIWorkData?.login?.refreshToken,
      );
    gqlService?.setWhenIWorkToken();
  }, [whenIWorkData?.login.isLogin, whenIWorkData?.login?.refreshToken]);

  const openWhenIWorkModal = useCallback(() => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    if (loginToken?.length && isFocused) getAssignedShift();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginToken, isFocused]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const openModal = useCallback(() => {
    Analytics.eventWithProps('WhenIWork Login', {
      type: EventType.cta,
    });
    setShowModal(true);
  }, []);

  useEffect(() => {
    Analytics.eventWithProps('Home Screen', {
      type: EventType.page,
      whenIWorkLogin: whenIWorkData?.login?.isLogin,
    });
  }, [whenIWorkData?.login?.isLogin]);

  useEffect(() => {
    setConstants();
    if (!unreadMemoCount) getUnReadMemoCount();
    if (isFocused) getVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const reUploadImages = useCallback(async () => {
    const fileUploadData = await storage.getItem(StorageKeys.FileUploadData);
    if (fileUploadData) {
      let uploderData: FileUploadData = JSON.parse(fileUploadData);
      for (const data in uploderData) {
        const iamgesData = uploderData[data];
        if (iamgesData) {
          const fileData = Object.values(iamgesData);
          fileData.forEach(async image => {
            const base64 = await getBase64FromURI(image.fileLocalUri);
            if (base64) {
              UpdateUploadingStatus(
                image?.runId,
                image.fileName,
                UploadingStatus.pending,
                0,
              );

              uploadeImageService(
                image.bucketType,
                image.fileName,
                base64,
                Number(image?.runId),
                image?.storeIndex,
              );
            }
          });
        }
      }
    }
  }, [uploadeImageService]);

  useEffect(() => {
    reUploadImages();
    setTimeout(() => {
      if (navigation?.getNavigationRef()?.isReady()) notification.action();
      else setTimeout(() => notification.action(), 250);
    }, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <KeyboardAvoidLayout>
      <Header hideBottomSafeArea loading={loadingShift}>
        <HomeContext.Provider value={provider}>
          <HomeHeader
            headerTitle="My Schedule"
            openWIWModal={openWhenIWorkModal}
          />
          <AboutShiftPage openModal={openModal} />
          <BagCountBanner />
          <BottomSheet onClose={closeModal} open={showModal}>
            <WhenIWorkBottomSheet onSheetClose={closeModal} />
          </BottomSheet>
          <If condition={!isWeb()}>
            <ChatOpener
              mode={ChatModes.group}
              style={styles.chatIconContainer}
              width={60}
              height={60}
            />
          </If>
          <BottomSheet
            open={(versionInfo?.showBottomSheet && !isWeb()) || false}>
            <VersionCheckSheet versionInfo={versionInfo ?? undefined} />
          </BottomSheet>
          <UnreadMemoModal />
        </HomeContext.Provider>
      </Header>
    </KeyboardAvoidLayout>
  );
};

export default React.memo(YourSchedule);
