import {
  ActionType,
  BottomSheetType,
  ModalType,
} from '../../../profile/types/profile';
import {Box, HBox} from '../../../components/core/view';
import {
  Component,
  SubComponent,
  SubComponentAction,
  SubComponentParam,
} from '../ProfileOptions/types';
import React, {useCallback, useState} from 'react';

import {Analytics} from '../../../config/analytics/analytics';
import ChecvronRight from '../../../components/core/icon/ChecvronRight';
import ComponentBottomSheet from '../ComponentBottomSheet';
import ComponentModal from '../ComponentModal';
import {H6Text} from '../../../components/core/text';
import SsrIcon from '../../../common/components/SsrIcon';
import {TouchableOpacity} from 'react-native';
import {deepLink} from '../../../navigation/deepLink';
import {styles} from './style';
import {useFontTheme} from '../../../appStyles/hooks/theme';

interface MainComponentProps {
  component: Component;
}

function MainComponent(props: MainComponentProps) {
  const {component} = props;
  const muliFont = useFontTheme('muli');
  const [bottomSheetType, setBottomSheetType] = useState<BottomSheetType>();
  const [modalType, setModalType] = useState<ModalType>();

  const openSheet = useCallback((data?: SubComponentParam) => {
    setBottomSheetType(data?.bottomSheetName);
  }, []);

  const closeSheet = useCallback(() => {
    setBottomSheetType(undefined);
  }, []);

  const openModal = useCallback((data?: SubComponentParam) => {
    setModalType(data?.modalName);
  }, []);

  const closeModal = useCallback(() => {
    setModalType(undefined);
  }, []);

  const navigate = useCallback((data?: SubComponentParam) => {
    if (!data?.screenPayload) return;

    const deepLinkParams = data?.screenPayload?.deepLinkParams;

    Analytics.eventWithProps(`navigate to ${deepLinkParams.screenName}`, {
      type: 'CTA',
      deepLinkParams: deepLinkParams,
      source: 'Profile',
    });

    deepLink.navigate(deepLinkParams);
  }, []);

  const onPress = useCallback(
    (action?: SubComponentAction) => {
      if (action)
        switch (action.actionType) {
          case ActionType.bottomSheet:
            openSheet(action.data);
            break;
          case ActionType.modal:
            openModal(action.data);
            break;
          case ActionType.navigation:
            navigate(action.data);
            break;
          default:
            break;
        }
    },
    [navigate, openModal, openSheet],
  );

  const subComponentRender = useCallback(
    (subComponents: SubComponent[]) => {
      return subComponents.map(subComponent => {
        const opecity = subComponent.index ? 0.5 : 1;
        return (
          <TouchableOpacity
            onPress={() => onPress(subComponent.action)}
            key={subComponent.index}>
            <Box style={[styles.listContainer, {opacity: opecity}]}>
              <HBox style={styles.boxContainer}>
                <SsrIcon
                  renderIcon={subComponent?.icon?.data?.component || ''}
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                />
                <HBox style={styles.boxContentContainer}>
                  <H6Text
                    style={[
                      styles.listTypo,
                      {
                        fontFamily: muliFont[700].normal,
                        color: subComponent?.title?.color,
                      },
                    ]}>
                    {subComponent?.title?.value}
                  </H6Text>
                  <ChecvronRight viewBox="-7 -3 20 20" />
                </HBox>
              </HBox>
            </Box>
          </TouchableOpacity>
        );
      });
    },
    [muliFont, onPress],
  );

  return (
    <React.Fragment>
      {subComponentRender(component.subComponent || [])}
      <ComponentBottomSheet
        bottomSheetType={bottomSheetType}
        closeSheet={closeSheet}
      />
      <ComponentModal modalType={modalType} closeModal={closeModal} />
    </React.Fragment>
  );
}

export default React.memo(MainComponent);
