import {MultiSelectInput, MultiSelectTileProps} from './type';
import React, {useCallback, useEffect, useState} from 'react';

import HBox from '../../view/HBox';
import {StyleSheet} from 'react-native';
import {Touchable} from '../../touchable';

const MultiSelectTile: React.FC<MultiSelectTileProps> = props => {
  const [selectedInfo, setSelectedInfo] = useState<MultiSelectInput[]>([]);
  const onTileSelect = props.onTileSelect;
  useEffect(() => {
    onTileSelect(selectedInfo);
  }, [selectedInfo, onTileSelect]);
  const setSelectedInfoFunc = useCallback(
    (info: React.SetStateAction<MultiSelectInput[]>) => {
      setSelectedInfo(info);
    },
    [],
  );

  useEffect(() => {
    if (props.setRefObject)
      props.setRefObject({setSelectedInfo: setSelectedInfoFunc});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HBox style={styles.tileBox}>
      {props?.data?.map((item: MultiSelectInput) => {
        return (
          <Touchable
            style={styles.tile}
            key={item?.id}
            onPress={() => {
              const isPresent = selectedInfo.find(val => val.id === item.id);
              let newIds = [...selectedInfo];
              if (isPresent)
                newIds = newIds.filter(info => info.id !== item.id);
              else newIds.push(item);
              setSelectedInfo(newIds);
            }}>
            {props.cellNode(item, selectedInfo)}
          </Touchable>
        );
      })}
    </HBox>
  );
};

export default React.memo(MultiSelectTile);

const styles = StyleSheet.create({
  tileBox: {
    padding: 5,
    marginTop: 10,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  tile: {
    marginTop: 10,
  },
});
