import * as React from 'react';

import Icon from './Icon';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const Dollar: React.FC<IconProps> = props => (
  <Icon width={25} height={25} viewBox="0 0 19 19" fill="none" {...props}>
    <Path
      d="M9.99999 1.66666C8.35182 1.66666 6.74065 2.15541 5.37024 3.07108C3.99983 3.98676 2.93173 5.28825 2.301 6.81097C1.67027 8.33369 1.50524 10.0092 1.82678 11.6258C2.14833 13.2423 2.942 14.7271 4.10744 15.8926C5.27287 17.058 6.75773 17.8517 8.37424 18.1732C9.99075 18.4948 11.6663 18.3297 13.189 17.699C14.7117 17.0683 16.0132 16.0002 16.9289 14.6298C17.8446 13.2593 18.3333 11.6482 18.3333 10C18.3333 8.90565 18.1178 7.82202 17.699 6.81097C17.2802 5.79992 16.6664 4.88126 15.8925 4.10744C15.1187 3.33362 14.2001 2.71979 13.189 2.301C12.178 1.88221 11.0943 1.66666 9.99999 1.66666ZM9.99999 16.6667C8.68145 16.6667 7.39252 16.2757 6.29619 15.5431C5.19986 14.8106 4.34538 13.7694 3.8408 12.5512C3.33621 11.333 3.20419 9.9926 3.46143 8.6994C3.71866 7.40619 4.3536 6.2183 5.28595 5.28595C6.2183 4.3536 7.40619 3.71866 8.69939 3.46143C9.9926 3.20419 11.333 3.33622 12.5512 3.8408C13.7694 4.34539 14.8106 5.19987 15.5431 6.2962C16.2757 7.39252 16.6667 8.68146 16.6667 10C16.6667 11.7681 15.9643 13.4638 14.714 14.714C13.4638 15.9643 11.7681 16.6667 9.99999 16.6667Z"
      fill="#FFFFFF"
    />
    <Path
      d="M9.99999 9.16667C8.33332 9.16667 8.33332 8.64167 8.33332 8.33333C8.33332 8.025 8.91666 7.5 9.99999 7.5C11.0833 7.5 11.1583 8.03333 11.1667 8.33333H12.8333C12.8221 7.76563 12.6179 7.21867 12.2544 6.78246C11.8909 6.34625 11.3897 6.04678 10.8333 5.93333V5H9.16666V5.90833C7.49999 6.18333 6.66666 7.25833 6.66666 8.33333C6.66666 9.26667 7.09999 10.8333 9.99999 10.8333C11.6667 10.8333 11.6667 11.4 11.6667 11.6667C11.6667 11.9333 11.15 12.5 9.99999 12.5C8.46666 12.5 8.33332 11.7833 8.33332 11.6667H6.66666C6.66666 12.4333 7.21666 13.7917 9.16666 14.1V15H10.8333V14.1C12.5 13.8167 13.3333 12.7417 13.3333 11.6667C13.3333 10.7333 12.9 9.16667 9.99999 9.16667Z"
      fill="#FFFFFF"
    />
  </Icon>
);
export default Dollar;
