import {Box, HBox} from '../../core/view';
import {CheckCircleIcon, SmallCloseIcon, WarningIcon} from 'native-base';
import React, {useEffect, useMemo} from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {BoxProps} from '../../core/view/type';
import {DefaultTextProps} from '../../type';
import {H6Text} from '../../core/text';
import If from '../../../components/conditional/If';
import {ToastType} from './type';
import WarningInfo from '../../../components/core/icon/WarningInfo';
import {onBoardingActions} from '../../../onBoarding/reducer';
import {useAppDispatch} from '../../../config/redux/hooks';
import {useGetToastTheme} from './NativeBaseToast';

interface Props extends BoxProps, DefaultTextProps<View> {
  title?: string;
  containerStyle?: StyleProp<ViewStyle>;
  duration?: number;
  orientation?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  type?: ToastType;
}
const Toast: React.FC<Props> = props => {
  const defaultTheme = useColorThemeType();
  const theme = useFontTheme();

  const toastTheme = useGetToastTheme(props?.type);

  const dispatch = useAppDispatch();
  useEffect(() => {
    const id = setTimeout(() => {
      dispatch(
        onBoardingActions.setNotificationToastInfo({
          show: false,
          details: {title: ''},
        }),
      );
    }, props.duration);
    return () => {
      clearInterval(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const toastBoxStyle: StyleProp<ViewStyle>[] = [
    styles.toastBox,
    {
      backgroundColor: toastTheme.backgroundColor,
    },
  ];
  const textStyle = [
    {
      fontFamily: theme[900].normal,
      color: toastTheme.textColor,
    },
    styles.text,
  ];

  const getLeftIcon = useMemo(() => {
    switch (props?.type) {
      case ToastType.Success:
        return <CheckCircleIcon size={6} color={toastTheme.iconColor} />;
      case ToastType.Error:
        return (
          <Box
            style={[
              styles.errorIconBox,
              {backgroundColor: toastTheme.iconColor},
            ]}>
            <SmallCloseIcon size={6} color={defaultTheme?.icon.white[100]} />
          </Box>
        );
      case ToastType.Warning:
        return (
          <WarningInfo color={toastTheme.iconColor} viewBox="-2 -2 25 25" />
        );
      case ToastType.Alert:
        return <WarningIcon size={6} color={toastTheme.iconColor} />;
      default:
        break;
    }
  }, [defaultTheme?.icon.white, props?.type, toastTheme.iconColor]);

  return (
    <HBox
      key={props.orientation}
      style={[
        styles.containerDefault,
        {
          backgroundColor: defaultTheme?.background.white[100],
        },
        props.containerStyle,
      ]}
      colorThemeType={props.colorThemeType}>
      <HBox {...props} style={toastBoxStyle}>
        <Box style={[styles.icon]}>{props.leftIcon || getLeftIcon}</Box>
        <H6Text
          fontThemeType={props.fontThemeType}
          colorThemeType={props.colorThemeType}
          style={textStyle}>
          {props.title}
        </H6Text>
        <If condition={props.rightIcon}>
          <Box style={[styles.icon]}>{props.rightIcon}</Box>
        </If>
      </HBox>
    </HBox>
  );
};

const styles = StyleSheet.create({
  toastBox: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 5,
    flex: 1,
  },
  icon: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  containerDefault: {
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'absolute',
    bottom: 80,
    zIndex: 100,
    flex: 1,
    elevation: 2,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    marginHorizontal: 20,
    shadowOpacity: 0.25,
    paddingVertical: 10,
    shadowRadius: 3.84,
  },
  errorIconBox: {
    borderRadius: 50,
    justifyContent: 'center',
    alignContent: 'center',
    padding: '1%',
  },
  text: {
    marginLeft: 10,
    marginRight: 10,
    flex: 1,
  },
});

export default React.memo(Toast);
