import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  container: {alignItems: 'center'},
  mainContainer: {
    position: 'absolute',
    bottom: 5,
    paddingHorizontal: 10,
    width: '70%',
    paddingVertical: 5,
    borderRadius: 50,
  },
});
