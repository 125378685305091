import * as React from 'react';

import {G, Path} from 'react-native-svg';

import Icon from './Icon';
import {IconProps} from './type';

const MinusIcon: React.FC<IconProps> = props => {
  return (
    <Icon fill={'red'} {...props}>
      <G>
        <Path d="M1.875 1.031c0 0.03 -0.026 0.056 -0.056 0.056H0.131a0.058 0.058 0 0 1 -0.056 -0.056v-0.112c0 -0.03 0.026 -0.056 0.056 -0.056h1.688c0.03 0 0.056 0.026 0.056 0.056v0.112z" />
      </G>
    </Icon>
  );
};

export default MinusIcon;
