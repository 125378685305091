import {H3Text, H5Text} from '../../../components/core/text';
import React, {useCallback, useEffect, useMemo} from 'react';

import {Box} from '../../../components/core/view';
import {Divider} from 'native-base';
import {PTextInput} from '../../../components/core/input/TextInput';
import {checkPriceDecimals} from '../../../utils/common';
import {styles} from './styles';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

interface BagInputComponentProps {
  setGlobalDisabled: (val: boolean) => void;
  setBagReturnCount: React.Dispatch<React.SetStateAction<number>>;
  setBagUsedCount: React.Dispatch<React.SetStateAction<number>>;
  bagReturnCount: number;
  bagUsedCount: number;
}

function BagInputComponent(props: BagInputComponentProps) {
  const {
    setGlobalDisabled,
    setBagReturnCount,
    setBagUsedCount,
    bagReturnCount,
    bagUsedCount,
  } = props;
  const theme = useColorThemeType();

  const usedBorderColor = useMemo(() => {
    if (!bagUsedCount) return theme?.text?.gray?.[200];
    return theme?.text?.blue?.[300];
  }, [bagUsedCount, theme?.text?.blue, theme?.text?.gray]);

  const returnBorderColor = useMemo(() => {
    if (!bagReturnCount) return theme?.text?.gray?.[200];
    return theme?.text?.blue?.[300];
  }, [bagReturnCount, theme?.text?.blue, theme?.text?.gray]);

  const handleBagReturn = useCallback(
    (count: string) => {
      if (checkPriceDecimals(count) && Number(count) <= 200)
        setBagReturnCount(Number(count));
    },
    [setBagReturnCount],
  );

  const handleBagUsed = useCallback(
    (count: string) => {
      if (checkPriceDecimals(count) && Number(count) <= 200)
        setBagUsedCount(Number(count));
    },
    [setBagUsedCount],
  );

  useEffect(() => {
    setGlobalDisabled(false);
  }, [setGlobalDisabled]);

  return (
    <Box>
      <Divider style={styles.divider} />
      <Box>
        <H3Text
          style={[
            styles.textStyle,
            {
              color: theme?.text.blue[300],
            },
          ]}>
          Bags used for this delivery
        </H3Text>
        <H5Text
          style={[
            styles.textStyle,
            {
              color: theme?.text.gray[100],
            },
          ]}>
          Enter the no. of bags you use in order to deliver this order
        </H5Text>
        <PTextInput
          value={bagUsedCount ? `${bagUsedCount}` : ''}
          placeholder="0"
          textAlignVertical={'top'}
          clearInput
          debounce={false}
          returnKeyType="done"
          style={[{color: theme?.text?.blue?.[300]}]}
          onChangeText={handleBagUsed}
          containerStyle={[
            styles.inputContainer,
            {
              borderColor: usedBorderColor,
            },
          ]}
          keyboardType="number-pad"
        />
        <Divider style={styles.divider} />
        <H3Text
          style={[
            styles.textStyle,
            {
              color: theme?.text.blue[300],
            },
          ]}>
          Bags Returned by Customer
        </H3Text>
        <H5Text
          style={[
            styles.textStyle,
            {
              color: theme?.text.gray[100],
            },
          ]}>
          Enter the no. of bags that were returned by the customer
        </H5Text>
        <PTextInput
          value={bagReturnCount ? `${bagReturnCount}` : ''}
          placeholder="0"
          textAlignVertical={'top'}
          clearInput
          debounce={false}
          returnKeyType="done"
          style={[{color: theme?.text?.blue?.[300]}]}
          onChangeText={handleBagReturn}
          containerStyle={[
            styles.inputContainer,
            {
              borderColor: returnBorderColor,
            },
          ]}
          keyboardType="number-pad"
        />
      </Box>
    </Box>
  );
}

export default React.memo(BagInputComponent);
