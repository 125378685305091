import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

import * as React from 'react';

const Info: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path
        d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5A10 10 0 1022 12 10 10 0 0011.99 2zM12 20a8 8 0 118-8 8 8 0 01-8 8z"
        transform="rotate(180 11 11)"
      />
    </Icon>
  );
};

export default Info;
