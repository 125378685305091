import {ActivityIndicator, StyleSheet} from 'react-native';
import {Box, HBox} from '../../../components/core/view';
import {CustomText, H2Text, H5Text} from '../../../components/core/text';
import React, {useCallback, useEffect, useState} from 'react';
import font, {scaleFonts} from '../../../appStyles/style/font';
import {getWidth, inputFormattedNumber} from '../../../utils/common';
import {useAppDispatch, useAppSelector} from '../../../config/redux/hooks';

import {Analytics} from '../../../config/analytics/analytics';
import {EventType} from '../../../config/analytics/type';
import {HTouchable} from '../../../components/core/touchable';
import If from '../../../components/conditional/If';
import OTPInput from '../Input/OTPInput';
import {navigation} from '@buncha/config/navigation';
import {onBoardingActions} from '../../reducer';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

function VerifCodeComponent(props: {
  error: string;
  resendCode: Function;
  sendingCode: boolean;
  clearError: Function;
  setIsValidate: Function;
  setCodeSent: Function;
  codeResent: boolean;
}) {
  const theme = useColorThemeType();
  const state = useAppSelector(gState => gState.onboarding);
  const authData = state.userAuthData;
  const [otp, setOTP] = useState('');
  const dispatch = useAppDispatch();
  const [time, setTime] = useState(60);
  const maximumCodeLength = 5;
  const timerRef = React.useRef(time);

  useEffect(() => {
    Analytics.eventWithProps('Enter OTP', {
      type: EventType.input,
      otp: state.userAuthData?.smsCode,
    });
    setOTP(state.userAuthData?.smsCode || '');
  }, [state.userAuthData?.smsCode]);

  useEffect(() => {
    if (props?.codeResent) {
      const timerId = setInterval(() => {
        timerRef.current -= 1;
        if (timerRef.current <= 0) {
          clearInterval(timerId);
          props?.setCodeSent(false);
          setTime(60);
          timerRef.current = 60;
        } else {
          setTime(timerRef.current);
        }
      }, 1000);
      return () => {
        clearInterval(timerId);
      };
    }
  }, [props]);

  useEffect(() => {
    props.setIsValidate((otp.length || 0) === 5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  const resendCode = useCallback(() => {
    Analytics.eventWithProps('Resend Verification code', {
      type: EventType.cta,
    });
    props.resendCode();
  }, [props]);

  const changePhoneNumber = useCallback(() => {
    dispatch(onBoardingActions.setOtpSentStatus(false));
    Analytics.eventWithProps('Change Phone Number', {
      type: EventType.cta,
    });
    navigation.goBack();
  }, [dispatch]);

  useEffect(() => {
    props?.setCodeSent(props?.codeResent);
  }, [props, props?.codeResent]);

  return (
    <Box style={[styles.container]}>
      <H2Text
        style={[
          styles.headerTypo,
          {
            fontFamily: font.fonts['Mortise-Bold'],
            color: theme?.text.blue[300],
          },
        ]}>
        Enter Verification Code
      </H2Text>
      <Box>
        <OTPInput
          code={otp}
          setCode={setOTP}
          maximumLength={maximumCodeLength}
        />
      </Box>
      <If condition={props.error}>
        <CustomText
          style={[
            styles.errorTypo,
            {color: theme?.text.red[100], ...font.fontTheme.muli},
          ]}>
          {props.error}
        </CustomText>
      </If>
      <If condition={true}>
        <Box style={[styles.typoContainer]}>
          <If condition={!props?.codeResent}>
            <HTouchable onPress={resendCode} style={[styles.touchable]}>
              <H5Text
                style={[styles.resendTypo, {color: theme?.text.green[100]}]}>
                Resend Verification code
              </H5Text>
              <If condition={props.sendingCode}>
                <ActivityIndicator color={theme?.text.green[100]} />
              </If>
            </HTouchable>
          </If>
          <If condition={props?.codeResent && time > 0}>
            <H5Text style={[styles.resendTypo, {color: theme?.text.gray[100]}]}>
              {`Code Resent. Try again in ${time}s`}
            </H5Text>
          </If>
          <HBox>
            <CustomText
              style={[styles.phoneNumberTypo, {color: theme?.text.gray[100]}]}>
              Is this your number +1{' '}
              {inputFormattedNumber(authData?.phoneNumber)}?{' '}
              <CustomText
                onPress={changePhoneNumber}
                style={[
                  styles.phoneNumberTypo,
                  styles.changePhoneNoTypo,
                  {
                    color: theme?.text.gray[100],
                    fontFamily: font.fonts.muliBold,
                  },
                ]}>
                Change Phone No.
              </CustomText>
            </CustomText>
          </HBox>
        </Box>
      </If>
    </Box>
  );
}

export default React.memo(VerifCodeComponent);

const styles = StyleSheet.create({
  container: {
    // marginTop: 10,
    position: 'relative',
  },
  headerTypo: {
    textAlign: 'left',
    marginBottom: 12,
  },
  codeInputFieldStyle: {
    borderRadius: 20,
    opacity: 1,
    color: '#100e3a',
    height: 65,
    width: Number(getWidth(52)),
    fontWeight: '500',
    fontSize: scaleFonts(18),
  },
  resendTypo: {
    fontSize: scaleFonts(14),
    textAlign: 'left',
    fontWeight: '700',
    marginRight: 10,
  },
  errorTypo: {
    textAlign: 'left',
    fontWeight: '700',
    marginRight: 10,
    // left: 5,
    marginTop: 5,
    marginBottom: 10,
    bottom: 5,
  },
  phoneNumberTypo: {
    textAlign: 'left',
    marginTop: 15,
  },
  changePhoneNoTypo: {
    textDecorationLine: 'underline',
  },
  touchable: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  typoContainer: {},
  title: {textAlign: 'center', fontSize: 30},
  codeFieldRoot: {marginTop: 20},
  cell: {
    width: Number(getWidth(52)),
    height: 65,
    lineHeight: 65,
    fontSize: 24,
    borderWidth: 1,
    color: '#100e3a',
    borderColor: '#100e3a',
    textAlign: 'center',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  focusCell: {
    borderColor: '#000',
  },
  footerText: {marginTop: 10},
});
