import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  parentContainer: {
    padding: 10,
    paddingBottom: 0,
    width: '100%',
  },
  infoBox: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
  },
  headerBox: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  loadingBox: {
    padding: 20,
  },
  imagesBox: {
    marginTop: 15,
    justifyContent: 'flex-start',
  },
  addImagePlaceHolderBox: {
    position: 'relative',
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    height: 120,
    width: 120,
  },
  circlePlusIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  addImageIcon: {height: 100, justifyContent: 'center', marginRight: 10},
  textAlignLeft: {
    textAlign: 'left',
  },
  bottomButton: {
    width: '100%',
    marginBottom: 0,
    marginTop: 10,
  },
  crossIconBox: {
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    padding: 4,
  },
  title: {
    marginBottom: 10,
  },
  webFileUpload: {display: 'none'},
});
