import {Box, HBox} from '../../../../../../src/components/core/view';
import React, {useMemo} from 'react';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../../src/appStyles/hooks/theme';

import {DeliveryLocationStatus} from '../../../../../../src/driver/types/common';
import {H6Text} from '../../../../../../src/components/core/text';
import {StyleSheet} from 'react-native';

interface OrderStatusCellProps {
  deliveryLocationStatus: DeliveryLocationStatus | string;
  runId: number;
  orderId: number;
}

const OrderStatusCell: React.FC<OrderStatusCellProps> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const runId = props?.runId || 0;
  const orderId = props?.orderId || 0;
  const getStatusText = useMemo(() => {
    switch (props?.deliveryLocationStatus) {
      case DeliveryLocationStatus.driverAssigned:
        return 'Order Packed';
      case DeliveryLocationStatus.onTheWay:
        return 'In Transit';
      case DeliveryLocationStatus.delivered:
        return 'Delivered';
      default:
        return 'Order Packed';
    }
  }, [props?.deliveryLocationStatus]);

  const getColors = useMemo(() => {
    switch (props?.deliveryLocationStatus) {
      case DeliveryLocationStatus.driverAssigned:
        return {
          background: theme?.background?.gray?.[100],
          foreground: theme?.text?.gray?.[100],
        };
      case DeliveryLocationStatus.onTheWay:
        return {
          background: theme?.background?.blue?.[100],
          foreground: theme?.background?.blue?.[100],
        };
      case DeliveryLocationStatus.delivered:
        return {
          background: theme?.background?.green[100],
          foreground: theme?.text?.green[100],
        };

      default:
        return {
          background: theme?.background?.gray?.[200],
          foreground: theme?.text?.gray?.[100],
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.deliveryLocationStatus]);
  return (
    <HBox style={styles.parentBox}>
      <HBox
        style={[
          styles.statusCell,
          styles.borderWidth,
          {borderColor: getColors?.background},
        ]}>
        <Box
          style={[
            styles.dot,
            {
              backgroundColor: getColors?.foreground,
            },
          ]}
        />
        <H6Text
          style={{
            fontFamily: muli?.[700]?.normal,
            color: getColors?.foreground,
          }}>
          {getStatusText}
        </H6Text>
      </HBox>
      <Box>
        <Box
          style={[
            styles.statusCell,
            {backgroundColor: theme?.background?.gray?.[200]},
          ]}>
          <H6Text style={{fontFamily: muli?.[700]?.normal}}>
            Run ID: {runId}
          </H6Text>
        </Box>
        <Box
          style={[
            styles.statusCell,
            {backgroundColor: theme?.background?.gray?.[200]},
          ]}>
          <H6Text style={{fontFamily: muli?.[700]?.normal}}>
            Ord ID: {orderId}
          </H6Text>
        </Box>
      </Box>
    </HBox>
  );
};

export default React.memo(OrderStatusCell);

const styles = StyleSheet.create({
  parentBox: {
    marginBottom: 10,
  },
  statusCell: {
    borderRadius: 20,
    paddingHorizontal: 7,
    paddingVertical: 2,
    marginBottom: 2,
  },
  borderWidth: {
    borderWidth: 1,
  },
  runIdCell: {
    borderRadius: 20,
    paddingHorizontal: 7,
    paddingVertical: 2,
  },
  dot: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 5,
  },
});
