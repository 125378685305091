import React, {useState} from 'react';

import ApplicationStatusScreen from '@buncha/hiring/screens/ApplicationStatusScreen';
import {HiringScreens} from './type';
import OnboardingPhases from '@buncha/hiring/screens/OnboardingPhases';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {getScreenToNavigate} from '@buncha/navigation/utils';
import {useAppSelector} from '@buncha/config/redux/hooks';

const Stack = createNativeStackNavigator();

const MainNavigator: React.FC = () => {
  const onboardingState = useAppSelector(gState => gState.onboarding);
  const user = onboardingState?.currentUser;

  const route = getScreenToNavigate(user);

  const [intialRoute] = useState(route.subScreen);
  return (
    <Stack.Navigator
      screenOptions={{headerShown: false}}
      initialRouteName={intialRoute}>
      <Stack.Screen
        name={HiringScreens.ApplicationStatus}
        component={ApplicationStatusScreen}
        options={{
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name={HiringScreens.OnboardingPhases}
        component={OnboardingPhases}
        options={{
          gestureEnabled: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default MainNavigator;
