import {PageComponent} from '../components/ProfileOptions/types';

/* eslint-disable no-shadow */
export interface GetStripeLoginAuthDataReturnType {
  getConfig: {
    stripeOauthSignInUrl: string;
  };
}

export interface CheckForDeleteUserType {
  header: string;
  isDeletable: boolean;
  subtext: string;
}

export interface DeleteUserType {
  header: string;
  isDeleted: boolean;
  subtext: string;
}

export interface IsAccountDeletableReturnType {
  isUserDeletable: CheckForDeleteUserType;
}

export interface DeleteAccountReturnType {
  deleteCurrentUser: DeleteUserType;
}

export interface GetPageDataResponse {
  getPageData: PageComponent[];
}

export enum PageType {
  UserProfilePage = 'UserProfilePage',
  GuestProfilePage = 'GuestProfilePage',
  ShopperUserProfilePage = 'ShopperUserProfilePage',
}

export enum ActionType {
  navigation = 'navigation',
  bottomSheet = 'bottomSheet',
  webView = 'webView',
  modal = 'modal',
  inAppReview = 'inAppReview',
}

export enum BottomSheetType {
  MapNavigationSheet = 'MapNavigationSheet',
}

export enum ModalType {
  LogoutAccountModal = 'LogoutAccountModal',
  DeleteAccountModal = 'DeleteAccountModal',
}
