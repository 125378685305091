import AppModal from '../../../common/components/CustomModal/AppModal';
import React from 'react';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

interface UnpackItemModalProps {
  open: boolean;
  onClose: Function;
  onSubmit: () => void;
  loading: boolean;
}

function UnpackItemModal(props: UnpackItemModalProps) {
  const {open, onClose, onSubmit, loading} = props;
  const theme = useColorThemeType();
  return (
    <AppModal
      isOpen={open}
      onClose={() => onClose()}
      topCTAHandelr={onSubmit}
      title={'Pack Item Again?'}
      subTitle={
        'Are you sure you want to pack this item again? It appears to be currently marked as “dropped.”'
      }
      ctaBottomTitle={'Cancel'}
      ctaTopTitle={'Yes, undrop item'}
      topCTAColor={theme?.background.green[100]}
      loading={loading}
    />
  );
}

export default React.memo(UnpackItemModal);
