import CustomLoader from './CustomLoader';
import {CustomText} from '../../core/text';
import If from '../../conditional/If';
import React from 'react';
import {StyleSheet} from 'react-native';

import {Box, HBox} from '../../core/view';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import font from '@buncha/appStyles/style/font';

const LoaderComponents = (props: {showBottomText: boolean}) => {
  const theme = useColorThemeType();

  return (
    <Box style={[styles.parentContainer]}>
      <HBox style={[styles.loaderContainer]}>
        <CustomLoader />
      </HBox>
      <If condition={props.showBottomText}>
        <HBox style={[styles.TypoContainer]}>
          <CustomText style={{color: theme?.text.gray[100]}}>
            Average person goes to the grocery store{' '}
            <CustomText
              style={[styles.boldFont, {color: theme?.text.gray[100]}]}>
              1.6 times a week
            </CustomText>{' '}
            and spends
            <CustomText
              style={[styles.boldFont, {color: theme?.text.gray[100]}]}>
              {' '}
              43 minutes
            </CustomText>{' '}
            there.
          </CustomText>
        </HBox>
      </If>
    </Box>
  );
};

export default React.memo(LoaderComponents);

const styles = StyleSheet.create({
  parentContainer: {
    flex: 1,
    flexGrow: 1,
    justifyContent: 'center',
  },
  loaderContainer: {
    justifyContent: 'center',
  },
  TypoContainer: {
    // width: Dimensions.get('screen').width * 0.9,
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  boldFont: {
    fontFamily: font.fontTheme.muli[700].normal,
  },
});
