import {Analytics} from '../../config/analytics/analytics';
import {Dispatch} from '@reduxjs/toolkit';
import {EventType} from '../../config/analytics/type';
import {WHEN_I_WORK_LOGIN} from '../../config/graphQL/documentNode/mutation';
import {WhenIWorkLoginCredentials} from '../types';
import {gqlService} from '../../config/graphQL';
import {onBoardingActions} from '../../onBoarding/reducer';
import {useState} from 'react';

export function useWhenIWork(dispatch: Dispatch<any> | null) {
  const [loggingIn, setLoggingIn] = useState(false);
  const [loggingInError, setLoggingInError] = useState('');

  const loginToWhenIWork = async (
    params: WhenIWorkLoginCredentials,
    callback: Function,
  ) => {
    try {
      setLoggingIn(true);
      const res = await gqlService?.mutation({
        mutation: WHEN_I_WORK_LOGIN,
        fetchPolicy: 'network-only',
        variables: {credential: params},
      });
      if (res?.data && dispatch) {
        Analytics.eventWithProps('Logged In Success', {
          type: EventType.api,
          email: params.email,
        });
        dispatch(onBoardingActions.setWhenIWorkData(res?.data?.whenIWorkLogin));
        dispatch(
          onBoardingActions.setWhenIWorkToken(
            res?.data?.whenIWorkLogin?.apiResponse?.data?.token,
          ),
        );
        gqlService?.setWhenIWorkLoginCredential(
          res?.data?.whenIWorkLogin?.apiResponse?.data?.token,
        );
        callback();
      }
    } catch (error: any) {
      Analytics.eventWithProps('Logged In Failure', {
        type: EventType.api,
        email: params.email,
        errorMessage: error?.message,
      });
      setLoggingInError(error.message);
    } finally {
      setLoggingIn(false);
    }
  };

  const clearErrorMessage = () => {
    setLoggingInError('');
  };
  return {loggingIn, loggingInError, loginToWhenIWork, clearErrorMessage};
}
