import {Dimensions, StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    maxWidth: Dimensions.get('screen').width * 0.9,
  },
  textStyle: {
    maxWidth: Dimensions.get('screen').width * 0.7,
    textAlign: 'left',
  },
  detailsContainer: {alignItems: 'flex-start', marginLeft: 5},
  infoContainer: {
    justifyContent: 'flex-start',
    paddingHorizontal: 20,
    paddingVertical: 5,
  },
  infoText: {
    textAlign: 'left',
    paddingLeft: 10,
    flex: 1,
  },
  title: {
    textAlign: 'left',
    marginHorizontal: 10,
    marginTop: 20,
    marginBottom: 10,
  },
  body: {textAlign: 'left', marginHorizontal: 10},
  floatingBox: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  scrollContainer: {paddingBottom: 150},
  centerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameText: {
    marginBottom: 2,
  },
  memoContainer: {
    paddingHorizontal: 10,
  },
  pillSubContainer: {
    height: 5,
    marginHorizontal: 5,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 20,
  },
  pillBody: {
    height: 5,
    borderRadius: 20,
  },
  attachmentPage: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'black',
  },
  crossIcon: {
    position: 'absolute',
    top: 25,
    right: 10,
    zIndex: 10,
    backgroundColor: 'transparent',
  },
  pillContainer: {
    position: 'absolute',
    top: 10,
    zIndex: 11,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    paddingHorizontal: 10,
  },
  spinnerContainer: {zIndex: 13},
  spinner: {
    zIndex: 13,
    position: 'absolute',
    top: Dimensions.get('screen').height / 2 - 60,
    left: Dimensions.get('screen').width / 2 - 20,
  },
  image: {
    width: Dimensions.get('screen').width,
    height: Dimensions.get('screen').height,
  },
  playIcon: {
    position: 'absolute',
    top: 25,
    right: 60,
    zIndex: 10,
    backgroundColor: 'transparent',
  },
  flex: {flex: 1},
});
