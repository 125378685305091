import {Linking, StyleSheet} from 'react-native';
import React, {useCallback} from 'react';

import {Analytics} from '@buncha/config/analytics/analytics';
import {CommonScreens} from '@buncha/common/navigation/types';
import {CustomText} from '@buncha/components/core/text';
import {EventType} from '@buncha/config/analytics/type';
import {HBox} from '@buncha/components/core/view';
import {MainScreens} from '@buncha/navigation/types';
import font from '@buncha/appStyles/style/font';
import {isWeb} from '@buncha/utils/common';
import {navigation} from '@buncha/config/navigation';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

function TermAndPrivacy() {
  const theme = useColorThemeType();

  const privacyPolicyOnPress = useCallback(async () => {
    Analytics.eventWithProps('Privacy Policy', {
      type: EventType.cta,
    });
    const url = 'https://www.gobuncha.com/privacy-policy';
    if (isWeb()) return Linking.openURL(url);
    return navigation.navigate(MainScreens.Common, {
      screen: CommonScreens.Webview,
      params: {uri: url},
    });
  }, []);

  const termOfUseOnpress = useCallback(async () => {
    Analytics.eventWithProps('Terms of Service', {
      type: EventType.cta,
    });
    const url = 'https://www.gobuncha.com/terms-of-service';
    if (isWeb()) return Linking.openURL(url);
    return navigation.navigate(MainScreens.Common, {
      screen: CommonScreens.Webview,
      params: {uri: url},
    });
  }, []);

  return (
    <HBox style={[styles.container]}>
      <CustomText
        style={[styles.termOfPrivacy, {color: theme?.text.gray[100]}]}>
        We will send you a verification text. Data and messages rate may apply.
        By continuing you are agreeing to our{' '}
        <CustomText
          onPress={termOfUseOnpress}
          style={[
            styles.phoneNumberTypo,
            {
              color: theme?.text.green[100],
              fontFamily: font.fonts.muliBold,
            },
          ]}>
          Terms of Use{' '}
        </CustomText>
        &{' '}
        <CustomText
          onPress={privacyPolicyOnPress}
          style={[
            styles.phoneNumberTypo,
            {
              color: theme?.text.green[100],
              fontFamily: font.fonts.muliBold,
            },
          ]}>
          Privacy Policy
        </CustomText>
      </CustomText>
    </HBox>
  );
}

export default React.memo(TermAndPrivacy);

const styles = StyleSheet.create({
  container: {
    marginTop: 0,
  },
  termOfPrivacy: {
    textAlign: 'left',
    marginTop: 5,
  },
  phoneNumberTypo: {
    textAlign: 'left',
    marginTop: 5,
  },
});
