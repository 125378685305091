import React from 'react';

interface SignatureComponentProps {
  orderId: number;
}

const SignatureComponent: React.FC<SignatureComponentProps> = () => {
  return <></>;
};

export default React.memo(SignatureComponent);
