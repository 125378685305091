import {getMessaging, getToken, onMessage} from 'firebase/messaging';

import {Config} from '../../../src/utils/types';
import {StorageKeys} from '../storage/type';
import {getConfig} from '../../../src/utils/appInfo';
import {initializeApp} from 'firebase/app';
import {storage} from '../storage';
import {useRegisterDeviceForNotifications} from '../../onBoarding/hooks';

const firebaseConfig = {
  apiKey: getConfig(Config.firebaseApiKey),
  authDomain: getConfig(Config.firebaseAuthDomain),
  databaseURL: getConfig(Config.firebaseDatabaseUrl),
  projectId: getConfig(Config.firebaseProjectId),
  storageBucket: getConfig(Config.firebaseStorageBucket),
  messagingSenderId: getConfig(Config.firebaseMessagingSenderId),
  appId: getConfig(Config.firebaseAppId),
  measurementId: getConfig(Config.firebaseMeasurementId),
};

const vApiKey = getConfig(Config.firebaseVapidKey);

const firebaseApp = initializeApp(firebaseConfig);

class FCMNotification {
  async requestPermissionSecondTimeAppOpen() {
    const info = await storage.getItem(StorageKeys.hasAppPreviouslyOpen);
    if (!info) return storage.setItem(StorageKeys.hasAppPreviouslyOpen, 'true');
    this.requestUserPermission();
  }

  action() {
    // Add any action to perform on mount
  }

  requestUserPermission = async () => {
    const messaging = getMessaging(firebaseApp);
    const {registerDeviceForNotifications} =
      useRegisterDeviceForNotifications();
    try {
      const webGlobal = global as any;
      const permission = await webGlobal.Notification.requestPermission();
      if (permission === 'granted') {
        const registration = await webGlobal.navigator.serviceWorker.register(
          '/firebase-messaging-sw.js',
        );

        const currentToken = await getToken(messaging, {
          vapidKey: vApiKey,
          serviceWorkerRegistration: registration,
        });
        if (currentToken) {
          this.onMessage();
          await registerDeviceForNotifications(currentToken);
        }
        return currentToken;
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log('err ==>', err);
    }
  };

  onMessage = () => {
    onMessage(getMessaging(firebaseApp), payload => {
      if (!payload?.data) return;
      const notif = JSON.parse(payload.data.notification);
      // eslint-disable-next-line no-console
      console.log('notification ---->', notif);
    });
  };
}

export const notification = new FCMNotification();
