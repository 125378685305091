import {getDeployType, showDevTools} from '../../utils/appInfo';

import {NetworkConfiguration} from '../graphQL/type';

const includeAnalyticsEnv = [NetworkConfiguration.production];
export function strictApiLoad() {
  const deployType = getDeployType() as NetworkConfiguration;
  if (includeAnalyticsEnv.includes(deployType) && !showDevTools()) return true;
  return false;
}
export function softApiLoad() {
  const deployType = getDeployType() as NetworkConfiguration;
  switch (showDevTools()) {
    case true:
      if (!includeAnalyticsEnv.includes(deployType)) return true;
      break;
    case false:
      if (includeAnalyticsEnv.includes(deployType)) return true;
      break;
    default:
      return false;
  }
}

export function shouldAnalyticsLoad(mode: 'soft' | 'strict') {
  switch (mode) {
    case 'soft':
      return softApiLoad();
    case 'strict':
      return strictApiLoad();
    default:
      return false;
  }
}
