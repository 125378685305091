import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  touchableStyle: {
    padding: 5,
  },
  hollowCircle: {
    borderWidth: 1,
    padding: 8,
    borderRadius: 50,
  },
  circleBox: {
    width: 18,
    height: 18,
  },
  dropText: {
    textDecorationLine: 'underline',
    textAlign: 'left',
  },
  dropContainer: {
    marginTop: 5,
    alignContent: 'flex-start',
    // marginLeft: 10,
  },
});
