import {Dimensions, StyleSheet} from 'react-native';

const {width} = Dimensions.get('window');

export const styles = StyleSheet.create({
  container: {marginTop: 10},
  child: {
    width: width - 200,
    justifyContent: 'center',
    marginRight: 40,
    // height: 450,
  },
  textStyle: {
    textAlign: 'left',
    marginVertical: 10,
  },
});
