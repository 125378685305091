import {Dimensions, StyleSheet} from 'react-native';
import {H3Text, H5Text} from '../../components/core/text';
import React, {useCallback, useEffect} from 'react';
import {getOs, getUrlForShopperForm} from '../../utils/appInfo';
import {getWidth, isWeb} from '../../utils/common';
import {useColorThemeType, useFontTheme} from '../../appStyles/hooks/theme';

import {Analytics} from '../../config/analytics/analytics';
import {BottomButton} from '../../components/composites/Button';
import {CommonScreens} from '@buncha/common/navigation/types';
import {CustomImage} from '../../components/core/Image';
import {EventType} from '../../config/analytics/type';
import Header from '../../components/composites/Header/Header';
import If from '@buncha/components/conditional/If';
import LegalInfo from '@buncha/common/components/LegalInfo';
import LoginComponent from '../components/LoginComponent/LoginComponent';
import {MainScreens} from '../../navigation/types';
import {OnBoardingScreens} from '../navigation/types';
import {Touchable} from '@buncha/components/core/touchable';
import {View} from 'native-base';
import {navigation} from '../../config/navigation';
import {onBoardingActions} from '../reducer';
import {useAppDispatch} from '../../config/redux/hooks';
import {useIsFocused} from '@react-navigation/native';

function Onboarding() {
  const muli = useFontTheme('muli');
  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  const mortiseFont = useFontTheme('mortise');
  const theme = useColorThemeType();
  const dispatch = useAppDispatch();

  const isFocused = useIsFocused();
  const url = getUrlForShopperForm();

  const onClickHandler = useCallback(() => {
    Analytics.eventWithProps('Navigate to Log In or Sing Up', {
      type: EventType.cta,
    });

    navigation.navigate(MainScreens.HomeBeforeLogin, {
      screen: OnBoardingScreens.Login,
    });
  }, []);

  const openFormUrl = useCallback(() => {
    navigation.navigate(MainScreens.Common, {
      screen: CommonScreens.Webview,
      params: {uri: url},
    });
  }, [url]);

  const clearState = useCallback(() => {
    dispatch(onBoardingActions.clearLoginState());
  }, [dispatch]);

  useEffect(() => {
    Analytics.event('Welcom Screen');
  }, []);

  useEffect(() => {
    if (isFocused) clearState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return (
    <Header hideTopSafeArea hideUploadFile>
      <CustomImage
        source={require('../../resource/images/onBoarding.png')}
        resizeMode="cover"
        style={{
          width: Number(getWidth(windowWidth)),
          height: windowHeight - 150,
        }}
      />
      <View style={[styles.container]}>
        <LoginComponent>
          <H3Text
            style={[
              styles.headerTypo,
              {
                fontFamily: mortiseFont[700].normal,
                color: theme?.text.blue[300],
              },
            ]}>
            {'Get paid to shop and deliver\ngroceries 🍌'}
          </H3Text>
          <BottomButton
            onPress={onClickHandler}
            activeOpacity={0.8}
            style={[styles.bottomButton]}
            title="Log In"
          />
          <If condition={!isWeb()}>
            <Touchable onPress={openFormUrl} style={styles.formTouchable}>
              <H5Text
                style={[
                  styles.urlText,
                  {
                    fontFamily: muli[700].normal,
                    color: theme?.text.blue[300],
                  },
                ]}>
                Become a Shopper/Driver
              </H5Text>
            </Touchable>
          </If>
          <LegalInfo />
        </LoginComponent>
      </View>
    </Header>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    // bottom: 45,
    position: 'absolute',
    zIndex: 5,
    bottom: 0,
  },
  headerTypo: {
    textAlign: 'center',
    width: '100%',
  },
  bottomButton: {
    borderRadius: 30,
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: getOs() === 'android' ? 15 : 10,
  },
  urlText: {
    textDecorationLine: 'underline',
  },
  formTouchable: {
    alignSelf: 'center',
    marginBottom: 5,
  },
});

export default React.memo(Onboarding);
