import {
  ActivityIndicator,
  Keyboard,
  ScrollView,
  StyleSheet,
} from 'react-native';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '@buncha/config/redux/hooks';

import {Analytics} from '@buncha/config/analytics/analytics';
import {BottomButton} from '@buncha/components/composites/Button';
import {Divider} from 'native-base';
import {EventType} from '@buncha/config/analytics/type';
import Header from '@buncha/components/composites/Header/Header';
import KeyboardAvoidLayout from '@buncha/components/composites/keyboardAvoidLayout';
import VerifCodeComponent from '../components/verificationCode/VerifCodeComponent';
import {extractNumber} from '@buncha/utils/common';
import {useAuthentication} from '../hooks';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

function VerificationCode() {
  const theme = useColorThemeType();
  const state = useAppSelector(gState => gState.onboarding);
  const authData = state.userAuthData;
  const dispatch = useAppDispatch();
  const [isValidate, setIsValidate] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  useEffect(() => {
    Analytics.eventWithProps('Enter OTP', {
      type: EventType.input,
      otp: state.userAuthData?.smsCode,
    });
  }, [state.userAuthData?.smsCode]);

  const {
    verifySMSCode,
    sendingSMSCode,
    verifyingOTP,
    verifyingOTPError,
    sendSMSCode,
    clearErrorMessage,
  } = useAuthentication(dispatch);

  const resendCode = useCallback(async () => {
    Analytics.eventWithProps('Resend Verification code', {
      type: EventType.cta,
    });
    await sendSMSCode(authData?.phoneNumber || '');
    setCodeSent(true);
  }, [authData?.phoneNumber, sendSMSCode]);

  const verifySMSCodeHandler = useCallback(async () => {
    const phnNumber = extractNumber(authData?.phoneNumber as string) || '';
    if (authData?.phoneNumber && authData?.phoneNumber?.length) {
      Analytics.eventWithProps('Send Code', {
        type: EventType.cta,
        phoneNumber: phnNumber,
      });
      await verifySMSCode(authData?.phoneNumber || '', authData?.smsCode || '');
      Keyboard.dismiss();
    }
  }, [authData?.phoneNumber, authData?.smsCode, verifySMSCode]);

  const getButtonDisabledStatus = useMemo(() => {
    if (authData?.phoneNumber && !authData?.otpSent) return false;
    if (authData?.otpSent && authData?.smsCode?.length === 5) return false;
    return true;
  }, [authData?.otpSent, authData?.phoneNumber, authData?.smsCode?.length]);

  return (
    <KeyboardAvoidLayout>
      <Header showDefaultLeftHeader={true} hideUploadFile>
        <ScrollView style={[styles.container]}>
          <VerifCodeComponent
            error={verifyingOTPError}
            resendCode={resendCode}
            sendingCode={sendingSMSCode}
            clearError={clearErrorMessage}
            setIsValidate={setIsValidate}
            codeResent={codeSent}
            setCodeSent={setCodeSent}
          />
        </ScrollView>
        <Divider />
        <BottomButton
          showLegalInfo={true}
          title={'Verify Code'}
          style={styles.buttonStyle}
          disabledBgColor={theme?.background.green[200]}
          isLoading={verifyingOTP || sendingSMSCode}
          loadingIndicator={
            (verifyingOTP || sendingSMSCode) && <ActivityIndicator />
          }
          onPress={verifySMSCodeHandler}
          disabled={!isValidate || getButtonDisabledStatus || sendingSMSCode}
        />
      </Header>
    </KeyboardAvoidLayout>
  );
}

export default React.memo(VerificationCode);

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    position: 'relative',
    marginHorizontal: 20,
    flex: 1,
  },
  buttonStyle: {
    marginTop: 15,
  },
});
