import {Buyer, Order} from '@buncha/driver/types/vanLoadAt';
import React, {useMemo} from 'react';

import {Box} from '@buncha/components/core/view';
import {BuyerInfoStyle} from './styles';
import {CustomText} from '@buncha/components/core/text';
import font from '@buncha/appStyles/style/font';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

interface Props {
  buyer: Buyer;
  orders: Order[];
}

const BuyerInfo = (props: Props) => {
  const theme = useColorThemeType();

  const {buyer, orders} = props;
  const mostRecentAddress = buyer?.mostRecentAddress;

  const getShopperText = useMemo(() => {
    let text = 'Shopped by: ';
    orders.forEach((order, index) => {
      const bagsUsed = order?.bagsUsed;
      const shopperName = order?.orderAssignee?.assignee?.shortName;
      const val = `${shopperName}(${bagsUsed || '?'})`;
      if (index === 0) text = text.concat(val);
      else text = text.concat(`, ${val}`);
    });

    return text;
  }, [orders]);

  return (
    <Box style={BuyerInfoStyle.mainContainer}>
      <CustomText
        style={[
          BuyerInfoStyle.nameStyle,
          {
            color: theme?.text.blue[300],
            fontFamily: font.fonts.muliSemiBold,
          },
        ]}>
        {buyer?.firstName} {buyer?.lastName}
      </CustomText>
      <CustomText
        numberOfLines={1}
        style={[
          BuyerInfoStyle.addressStyle,
          {
            ...font.FontSize.fontP10,
            fontFamily: font.fonts.muliSemiBold,
          },
        ]}>
        {mostRecentAddress?.address}{' '}
      </CustomText>
      <CustomText
        style={[
          BuyerInfoStyle.shopperText,
          {
            ...font.FontSize.fontP10,
            fontFamily: font.fonts.muliSemiBold,
          },
        ]}>
        {getShopperText}
      </CustomText>
    </Box>
  );
};

export default React.memo(BuyerInfo);
