import {
  ItemScannerState,
  PendingUPCOrder,
  UPCItemLocationStatus,
} from '../types/ItemScanner';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {UPCOrderItem} from '@buncha/batchDetails/types';

const initialState: ItemScannerState = {
  itemLocationData: {
    locationDescription: undefined,
    imageUrl: '',
    price: 0,
    substituionData: undefined,
  },
  selectedCatalogStoreForUPCRun: undefined,
  upcOrderItem: undefined,
};

const reducer = {
  setItemLocationData: function (
    state: ItemScannerState,
    action: PayloadAction<UPCItemLocationStatus>,
  ) {
    state.itemLocationData = action.payload;
  },
  setSelectedCatalogStoreForUPCRun: function (
    state: ItemScannerState,
    action: PayloadAction<PendingUPCOrder | undefined>,
  ) {
    state.selectedCatalogStoreForUPCRun = action.payload;
  },
  setUPCOrderItem: function (
    state: ItemScannerState,
    action: PayloadAction<UPCOrderItem>,
  ) {
    state.upcOrderItem = action.payload;
  },
  clearItemState: function (state: ItemScannerState) {
    state.itemLocationData = {
      locationDescription: undefined,
      imageUrl: '',
      price: 0,
      substituionData: undefined,
    };
  },
};

function initSlice() {
  const slice = createSlice({
    name: 'ItemScannerState',
    initialState,
    reducers: reducer,
  });
  return slice;
}

export default initSlice();
