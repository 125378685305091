import {Box, HBox} from '@buncha/components/core/view';
import {ChevronDownIcon, ChevronUpIcon} from 'native-base';
import {CustomText, H6Text} from '@buncha/components/core/text';
import {Linking, ScrollView, StyleSheet} from 'react-native';
import React, {useCallback, useMemo, useState} from 'react';
import {
  UPCItemLocationStatus,
  UPCScanFormData,
} from '@buncha/itemDetails/types/ItemScanner';
import {useAppDispatch, useAppSelector} from '@buncha/config/redux/hooks';
import {
  useItemScannerContext,
  useUPCOrder,
  useUploadUpcImage,
} from '@buncha/itemDetails/hooks/ItemScanner';

import {Accordian} from '@buncha/components/composites';
import AddedSubstitution from '../AddedSubstitution';
import BottomSheet from '@buncha/components/composites/bottomSheet';
import BottomSheetComponent from '../BottomSheetComponent';
import {CameraComponentState} from '@buncha/common/types/common';
import CameraModal from '@buncha/common/components/CameraModal/CameraModal';
import {CustomFlatList} from '@buncha/components/core/list';
import DollarSearch from '@buncha/components/core/icon/DollarSearch';
import EditIcon from '@buncha/components/core/icon/EditIcon';
import If from '@buncha/components/conditional/If';
import ImageBottomSheet from '@buncha/common/components/BottomSheet/ImageBottomSheet';
import ImageUpload from '@buncha/components/core/icon/ImageUpload';
import {ItemDetailsScreen} from '@buncha/itemDetails/navigation/types';
import {ItemScannerGlobalActions} from '@buncha/itemDetails/reducer';
import PriceBottomSheet from '../BottomSheetComponent/PriceBottomSheet';
import SearchSync from '@buncha/components/core/icon/SearchSync';
import {ShopperScreens} from '@buncha/home/navigation/types';
import StoreLocation from '@buncha/components/core/icon/StoreLocation';
import Timeline from '../Timeline';
import {Touchable} from '@buncha/components/core/touchable';
import {formatCurrency} from '@buncha/utils/common';
import {navigation} from '@buncha/config/navigation';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

const UPCUpdateSection = () => {
  const theme = useColorThemeType('main');
  const appDispatch = useAppDispatch();
  const {dispatch} = useItemScannerContext();

  const {itemLocationData, upcOrderItem} = useAppSelector(
    gState => gState.itemScanner,
  );

  const [showLoocationBottomSheet, setShowLocationBottomSheet] =
    useState(false);
  const [showPriceBottomSheet, setShowPriceBottomSheet] = useState(false);
  const [showImageUploadModal, setShowImageUploadModal] =
    useState<boolean>(false);

  const [uploadedImage, setUploadedImage] = useState<any>(
    itemLocationData?.imageUrl ? [itemLocationData?.imageUrl] : [],
  );

  const {loading, requestPermissions} = useUploadUpcImage(dispatch);

  const {uploadLocationImage, imageUploading} = useUPCOrder(dispatch);

  const onLocationVerificationClick = useCallback(() => {
    setShowLocationBottomSheet(!showLoocationBottomSheet);
  }, [showLoocationBottomSheet]);

  const onPriceVerifcatiionClick = useCallback(() => {
    setShowPriceBottomSheet(!showPriceBottomSheet);
  }, [showPriceBottomSheet]);

  const onImageVerificationClick = useCallback(() => {
    setShowImageUploadModal(!showImageUploadModal);
  }, [showImageUploadModal]);

  const handelSubstituteNav = useCallback(() => {
    navigation.navigate(ShopperScreens.ItemDetails, {
      screen: ItemDetailsScreen.ItemReplacement,
      params: {
        orderItemId: upcOrderItem?.id,
        isUPCMode: true,
      },
    });
  }, [upcOrderItem?.id]);

  const [openCameraModal, setOpenCameraModal] = useState(false);

  const toggleCameraModal = useCallback(() => {
    setOpenCameraModal(!openCameraModal);
  }, [openCameraModal]);

  const formData: UPCScanFormData[] = useMemo(() => {
    return [
      {
        index: 0,
        icons: <StoreLocation viewBox="0 -2 20 20" height={20} width={20} />,
        header: 'Item Location Description',
        bodyText:
          'Provide detailed information about the aisle, category, and precise location of the item.',
        bodySubText: 'Where did you find this item?',
        isComplete:
          (Boolean(itemLocationData?.locationDescription?.aisle.length) ||
            Boolean(itemLocationData?.locationDescription?.context.length)) ??
          false,
        ctaTitle: 'Verify location of item',
        CTAAction: () => {
          onLocationVerificationClick();
        },
      },
      {
        index: 1,
        icons: <ImageUpload />,
        header: 'Item Location Image',
        isComplete: Boolean(itemLocationData?.imageUrl?.length) ?? false,
        bodyText:
          'Upload an image of the location where you were able to locate this item.',
        ctaTitle: 'Upload Image',
        CTAAction: () => {
          onImageVerificationClick();
        },
      },
      {
        index: 2,
        icons: <DollarSearch viewBox="0 -2 20 20" height={20} width={20} />,
        header: 'Item Price Inquiry',
        isComplete: itemLocationData?.price !== 0 ?? false,
        ctaTitle: 'Update price',
        CTAAction: () => {
          onPriceVerifcatiionClick();
        },
        bodyText:
          'Provide the price at which you purchased the item or specify the price of this item.',
      },
      {
        index: 3,
        icons: <SearchSync />,
        header: 'Pick a Replacement',
        isComplete: itemLocationData?.substituionData ?? false,
        bodyText:
          'Let us know which replacement would you like to pick for this item',
        ctaTitle: 'Add Replacement',
        CTAAction: () => {
          handelSubstituteNav();
        },
      },
    ];
  }, [
    itemLocationData?.locationDescription?.aisle.length,
    itemLocationData?.locationDescription?.context.length,
    itemLocationData?.imageUrl?.length,
    itemLocationData?.price,
    itemLocationData?.substituionData,
    onLocationVerificationClick,
    onImageVerificationClick,
    onPriceVerifcatiionClick,
    handelSubstituteNav,
  ]);

  const getChildren = useCallback(
    (id: number) => {
      switch (id) {
        case 0:
          return (
            <Touchable onPress={formData[id]?.CTAAction}>
              <If condition={formData[id]?.isComplete}>
                <HBox style={[styles.coomplteContainer]}>
                  <Box style={[styles.justifyContent]}>
                    <H6Text>
                      {`${itemLocationData?.locationDescription?.aisle}, ${itemLocationData?.locationDescription?.context}`}
                    </H6Text>
                  </Box>
                  <Box>
                    <EditIcon />
                  </Box>
                </HBox>
              </If>
              <If condition={!formData[id]?.isComplete}>
                <H6Text
                  style={[
                    styles.typo,
                    styles.ctaTypo,
                    {
                      color: theme?.text?.green[100],
                    },
                  ]}>
                  {formData[id]?.ctaTitle}
                </H6Text>
              </If>
            </Touchable>
          );
        case 1:
          return (
            <Touchable onPress={formData[id]?.CTAAction}>
              <H6Text
                style={[
                  styles.typo,
                  styles.ctaTypo,
                  {
                    color: theme?.text?.green[100],
                  },
                ]}>
                {formData[id]?.isComplete
                  ? 'Update Image'
                  : formData[id]?.ctaTitle}
              </H6Text>
            </Touchable>
          );
        case 2:
          return (
            <Touchable onPress={formData[id]?.CTAAction}>
              <If condition={formData[id]?.isComplete}>
                <HBox style={[styles.coomplteContainer]}>
                  <Box style={[styles.justifyContent]}>
                    <H6Text>
                      {`${formatCurrency(Number(itemLocationData?.price))}`}
                    </H6Text>
                  </Box>
                  <Box>
                    <EditIcon />
                  </Box>
                </HBox>
              </If>
              <If condition={!formData[id]?.isComplete}>
                <H6Text
                  style={[
                    styles.typo,
                    styles.ctaTypo,
                    {
                      color: theme?.text?.green[100],
                    },
                  ]}>
                  {formData[id]?.ctaTitle}
                </H6Text>
              </If>
            </Touchable>
          );
        case 3:
          return (
            <React.Fragment>
              <If condition={formData[id]?.isComplete}>
                <AddedSubstitution onChange={formData[id]?.CTAAction} />
              </If>
              <If condition={!formData[id]?.isComplete}>
                <Touchable onPress={formData[id]?.CTAAction}>
                  <H6Text
                    style={[
                      styles.typo,
                      styles.ctaTypo,
                      {
                        color: theme?.text?.green[100],
                      },
                    ]}>
                    {formData[id]?.ctaTitle}
                  </H6Text>
                </Touchable>
              </If>
            </React.Fragment>
          );

        default:
          return <CustomText />;
      }
    },
    [
      formData,
      itemLocationData?.locationDescription?.aisle,
      itemLocationData?.locationDescription?.context,
      itemLocationData?.price,
      theme?.text?.green,
    ],
  );

  const renderItem = useCallback(
    ({item, index}: {item: any; index: number}) => {
      return (
        <HBox style={[styles.jcfs]} key={index}>
          <Box>
            <Timeline checked={formData[item?.index].isComplete} />
          </Box>
          <Box style={[styles.accordionContainer]}>
            <Accordian
              disabled={false}
              isExpanded={!item.isComplete}
              style={styles.accordian}
              iconEnable={<ChevronUpIcon color={theme?.text.blue[300]} />}
              iconDisable={<ChevronDownIcon color={theme?.text.blue[300]} />}
              header={
                <Box style={[styles.accordinHeader]}>
                  <HBox style={[styles.jcfs]}>
                    {item.icons}
                    <H6Text
                      style={[
                        styles.headerTypo,
                        {
                          color: theme?.text.blue[300],
                        },
                      ]}>
                      {item.header}
                    </H6Text>
                  </HBox>
                </Box>
              }
              children={
                <Box>
                  <Box style={[styles.accordinBody]}>
                    <CustomText style={[styles.typo]}>
                      {formData[item?.index].bodyText}
                    </CustomText>
                    <If condition={formData[item?.index]?.bodySubText}>
                      <CustomText style={[styles.typo, styles.bodySubTypo]}>
                        {formData[item?.index]?.bodySubText}
                      </CustomText>
                    </If>
                  </Box>
                  {getChildren(item?.index)}
                </Box>
              }
            />
          </Box>
        </HBox>
      );
    },
    [formData, getChildren, theme?.text.blue],
  );

  const renderAccordions = useMemo(() => {
    return <CustomFlatList data={formData} renderItem={renderItem} />;
  }, [formData, renderItem]);

  const imageUploadHandler = useCallback(
    async (base64: any) => {
      const res = await uploadLocationImage(base64);
      if (res) setUploadedImage([res]);
    },
    [uploadLocationImage],
  );

  const saveImage = useCallback(() => {
    const locationData: UPCItemLocationStatus = {
      ...itemLocationData,
      imageUrl: uploadedImage[0],
    };
    if (appDispatch)
      appDispatch(ItemScannerGlobalActions.setItemLocationData(locationData));
    onImageVerificationClick();
  }, [appDispatch, itemLocationData, onImageVerificationClick, uploadedImage]);

  const showCaptureModeFunc = useCallback(async () => {
    const permissionStatus = await requestPermissions();
    if (permissionStatus)
      try {
        toggleCameraModal();
      } catch (error) {}
    else Linking.openSettings();
  }, [requestPermissions, toggleCameraModal]);

  const deleteImage = useCallback(() => {
    setUploadedImage([]);
    const locationData: UPCItemLocationStatus = {
      ...itemLocationData,
      imageUrl: '',
    };
    if (appDispatch)
      appDispatch(ItemScannerGlobalActions.setItemLocationData(locationData));
  }, [appDispatch, itemLocationData]);

  const captureImageHandler = useCallback(
    (data: CameraComponentState) => {
      if (data) imageUploadHandler(data.base64);
    },
    [imageUploadHandler],
  );

  return (
    <Box>
      <ScrollView>
        <HBox>
          <Box style={[styles.parentContainer]}>{renderAccordions}</Box>
        </HBox>
      </ScrollView>
      <BottomSheet
        open={showLoocationBottomSheet}
        onClose={onLocationVerificationClick}>
        <Box style={[styles.bottomSheetBody]}>
          <BottomSheetComponent
            onSheetClose={onLocationVerificationClick}
            onPress={(data: UPCItemLocationStatus) => {
              if (appDispatch)
                appDispatch(ItemScannerGlobalActions.setItemLocationData(data));
            }}
          />
        </Box>
      </BottomSheet>
      <BottomSheet
        open={showPriceBottomSheet}
        onClose={onPriceVerifcatiionClick}>
        <Box style={[styles.bottomSheetBody]}>
          <PriceBottomSheet
            onSheetClose={onPriceVerifcatiionClick}
            onPress={(data: UPCItemLocationStatus) => {
              if (appDispatch)
                appDispatch(ItemScannerGlobalActions.setItemLocationData(data));
            }}
          />
        </Box>
      </BottomSheet>
      <ImageBottomSheet
        bottomSheetOpen={showImageUploadModal}
        title={'Add item images'}
        subtitle={'Please take clear photos of the item and upload the image'}
        loading={loading || imageUploading}
        imageList={uploadedImage}
        showCaptureModeFunc={showCaptureModeFunc}
        closeBottomSheet={onImageVerificationClick}
        handleImageDelete={() => deleteImage()}
        uploadButtonHandler={() => saveImage()}
        hasShowBottomButton
      />
      <If condition={openCameraModal}>
        <CameraModal
          capturedImage={captureImageHandler}
          open={openCameraModal}
          closeModal={toggleCameraModal}
        />
      </If>
    </Box>
  );
};

export default React.memo(UPCUpdateSection);

const styles = StyleSheet.create({
  parentContainer: {
    width: '100%',
    paddingHorizontal: 10,
    marginLeft: 7,
  },
  accordian: {
    padding: 10,
  },
  headerTypo: {
    marginLeft: 5,
  },
  accordionContainer: {
    paddingVertical: 5,
  },
  typo: {
    textAlign: 'left',
    fontWeight: '600',
  },
  accordinHeader: {
    width: '90%',
    paddingBottom: 0,
  },
  accordinBody: {
    width: '70%',
    paddingHorizontal: 10,
    paddingBottom: 15,
  },
  bodySubTypo: {
    marginTop: 15,
    fontWeight: '500',
  },
  ctaTypo: {
    marginTop: 10,
    fontWeight: '800',
  },
  bottomSheetBody: {
    padding: 0,
  },
  justifyContent: {
    justifyContent: 'center',
  },
  coomplteContainer: {
    paddingVertical: 5,
    width: '70%',
    paddingLeft: 10,
    paddingBottom: 15,
  },
  jcfs: {
    justifyContent: 'flex-start',
  },
  inputContainer: {
    marginLeft: 15,
  },
});
