/* eslint-disable no-shadow */
export enum MainScreens {
  HomeBeforeLogin = 'HomeBeforeLogin',
  Hiring = 'Hiring',
  Home = 'Home',
  Tabs = 'Tabs',
  Common = 'Common',
  Lockout = 'Lockout',
  Shopper = 'Shopper', // For Deeplink
  DownTime = 'DownTime',
}

export enum TabScreens {
  YourSchedule = 'YourSchedule',
  ActiveOrders = 'ActiveOrders',
  Support = 'Support',
  Profile = 'Profile',
  Memos = 'Memos',
}
