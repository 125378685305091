import {DeliveryLocationStatus} from '../types/common';
import {GET_ORDER_FOR_ORDER_DETAILS} from '../../../src/config/graphQL/documentNode/queries';
import {GetOrderForOrderDetailsResponse} from '../../../src/config/graphQL/type';
import {OrderForOrderDetails} from '../types/orderDetails';
import {SET_DELIVERY_LOCATION_STATUS} from '../../../src/config/graphQL/documentNode/mutation';
import {ToastType} from '../../components/composites/notification/type';
import {gqlService} from '../../../src/config/graphQL';
import listener from '../../../src/config/listener';
import {useState} from 'react';
import {useToastMessage} from '../../../src/components/composites/notification';

export function useGetOrdersDetails() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState<
    OrderForOrderDetails | undefined
  >();
  const getOrderDetails = async (orderId: string, runId: number) => {
    try {
      setLoading(true);
      const res = await gqlService?.query<GetOrderForOrderDetailsResponse>({
        query: GET_ORDER_FOR_ORDER_DETAILS,
        fetchPolicy: 'network-only',
        variables: {
          orderId: orderId,
          runId: runId,
        },
      });
      const orderInfo = res?.data?.getOrder;
      setOrderData(orderInfo);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {getOrderDetails, orderData, loading};
}

export function useSetDeliveryLocationStatusForDriver() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [loading, setLoading] = useState(false);
  const setDeliveryLocationStatusForDriver = async (
    deliveryLocationId: number,
    status: DeliveryLocationStatus,
    runId: number,
  ) => {
    try {
      setLoading(true);
      await gqlService?.mutation({
        mutation: SET_DELIVERY_LOCATION_STATUS,
        fetchPolicy: 'network-only',
        variables: {
          deliveryLocationId: deliveryLocationId,
          status: status,
          runId,
        },
      });
      listener.emit('onTheWay');
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {setDeliveryLocationStatusForDriver, loading};
}

export function useSetDeliveryLocationAsFailed() {
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [showSuccessToast] = useToastMessage(ToastType.Success);
  const [loading, setLoading] = useState(false);
  const setDeliveryLocationStatusAsFailed = async (
    deliveryLocationId: number,
    status: DeliveryLocationStatus,
    deliveryFailureReason: string,
    runId: number,
  ) => {
    try {
      setLoading(true);
      await gqlService?.mutation({
        mutation: SET_DELIVERY_LOCATION_STATUS,
        fetchPolicy: 'network-only',
        variables: {
          deliveryLocationId: deliveryLocationId,
          status: status,
          deliveryFailureReason: deliveryFailureReason,
          runId,
        },
      });
      showSuccessToast('Delivery marked as failed');
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {setDeliveryLocationStatusAsFailed, loading};
}
