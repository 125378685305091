import {HiringApplicationState} from '@buncha/common/types/common';
import {HiringScreens} from '@buncha/hiring/navigation/type';
import {HiringStatusesForApplicant} from '@buncha/utils/common';
import {MainScreens} from '../types';
import {OnBoardingScreens} from '../../onBoarding/navigation/types';
import {UserStub} from '../../onBoarding/types';

export function getScreenToNavigate(userInfo: UserStub | undefined) {
  let screen: string = OnBoardingScreens.Login;
  let subScreen = '';
  const applicationStatus =
    userInfo?.fullfillmentHiringApplicationState?.status ||
    HiringApplicationState.submitted;
  const userTypes = userInfo?.userTypes || '';
  const isAllowed =
    !userInfo?.userTypes.length ||
    userTypes?.includes('driver') ||
    userTypes?.includes('shopper') ||
    (userTypes.includes('user') &&
      applicationStatus !== HiringApplicationState.notSubmitted);
  if (!isAllowed) {
    screen = MainScreens.Lockout;
    return {screen: screen, subScreen: subScreen};
  }

  if (HiringStatusesForApplicant.includes(applicationStatus)) {
    screen = MainScreens.Hiring;
    subScreen = HiringScreens.ApplicationStatus;
  } else if (applicationStatus === HiringApplicationState.accountSetup) {
    screen = MainScreens.Hiring;
    subScreen = HiringScreens.OnboardingPhases;
  } else if (applicationStatus === HiringApplicationState.completed) {
    screen = MainScreens.Tabs;
  }
  return {screen: screen, subScreen: subScreen};
}
