import {Box} from '../../../components/core/view';
import {H5Text} from '../../../components/core/text';
import React from 'react';
import {StyleSheet} from 'react-native';
import {useFontTheme} from '../../../appStyles/hooks/theme';

export interface WIWDisclaimerProps {
  onSignInAgain?: () => void;
}
const WhenIWorkDisclaimer = (props: WIWDisclaimerProps) => {
  const muliFont = useFontTheme('muli');

  return (
    <Box style={styles.boxContainer}>
      <H5Text
        alignText="left"
        style={[
          styles.mainText,
          {
            fontFamily: muliFont[700].normal,
          },
        ]}>
        Looks like you’ve been signed out from "When I Work". To see your
        assigned shift again. Please{' '}
        <H5Text
          onPress={props.onSignInAgain}
          style={[styles.linkText, {fontFamily: muliFont[700].normal}]}>
          Sign in again.
        </H5Text>
      </H5Text>
    </Box>
  );
};

export default React.memo(WhenIWorkDisclaimer);

const styles = StyleSheet.create({
  linkText: {
    textDecorationLine: 'underline',
  },
  mainText: {
    flex: 1,
  },
  boxContainer: {
    flex: 1,
  },
});
