import {Box, HBox} from '@buncha/components/core/view';
import {CustomText, H6Text} from '@buncha/components/core/text';
import {Dimensions, StyleSheet} from 'react-native';
import {HomeScreens, ShopperScreens} from '../navigation/types';
import React, {useCallback, useEffect, useState} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';
import {usePackedItemCount, useStartRunnerTutorial} from '../hooks/Tutorials';

import {BottomButton} from '@buncha/components/composites/Button';
import {Divider} from 'native-base';
import {DriverScreens} from '@buncha/driver/navigation/type';
import Header from '@buncha/components/composites/Header/Header';
import If from '@buncha/components/conditional/If';
import ScreenHeader from '@buncha/common/components/screenHeader';
import ShopIcon from '@buncha/components/core/icon/ShopIcon';
import {UserTypes} from '@buncha/onBoarding/types';
import {getWidth} from '@buncha/utils/common';
import {navigation} from '@buncha/config/navigation';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {TutorialsMode} from '../types';

const Tutorials = () => {
  const muliFont = useFontTheme('muli');
  const theme = useColorThemeType();

  const tutorials = useAppSelector(
    gState => gState.onboarding.currentUser?.tutorials,
  );

  const [tutorialMode, setTutorialMode] = useState({
    shopper: false,
    driver: false,
  });
  const {loading, startRunnerTutorial} = useStartRunnerTutorial();
  const {getPackedItemsCount, countLoading} = usePackedItemCount();

  const startTutorial = useCallback(
    async (userType: UserTypes) => {
      const res = await startRunnerTutorial(userType);
      if (!res?.runId) return;
      if (userType === UserTypes.shopper) {
        const count = await getPackedItemsCount(res.runId);
        if (count && count?.[0])
          return navigation.navigate(HomeScreens.Shopper, {
            screen: ShopperScreens.OrderPackingPage,
            params: {runId: res.runId, tutorial: 'true'},
          });
        navigation.navigate(HomeScreens.Shopper, {
          screen: ShopperScreens.BatchDetailsPage,
          params: {runId: res.runId, tutorial: 'true'},
        });
      } else if (userType === UserTypes.driver && res.orderId) {
        navigation.navigate(HomeScreens.Driver, {
          screen: DriverScreens.OrderDetails,
          params: {orderId: res.orderId, tutorial: 'true', runId: res.runId},
        });
      }
    },
    [getPackedItemsCount, startRunnerTutorial],
  );

  useEffect(() => {
    if (tutorials)
      tutorials.forEach(tutorial => {
        setTutorialMode({
          shopper: tutorial.name === TutorialsMode.shopperGuide,
          driver: tutorial.name === TutorialsMode.driverGuide,
        });
      });
  }, [tutorials]);

  const Cell = useCallback(
    (
      title: string,
      subtitle: string,
      buttonText: string,
      userType: UserTypes,
      instructions: string[],
    ) => {
      const textStyle = [
        styles.textAlignLeft,
        {
          color: theme?.text.gray[100],
          fontFamily: muliFont[600].normal,
        },
      ];
      return (
        <Box
          style={[
            styles.containerBox,
            {
              borderColor: theme?.text.gray[300],
            },
          ]}>
          <HBox style={styles.justifyStart}>
            <ShopIcon
              width={Number(getWidth(27))}
              fill={theme?.icon.blue[300]}
            />
            <Box style={styles.cellContainer}>
              <H6Text
                numberOfLines={1}
                style={[
                  styles.textAlignLeft,
                  {
                    color: theme?.text?.blue?.[300],
                    fontFamily: muliFont?.[700]?.normal,
                  },
                ]}>
                {title}
              </H6Text>
              <CustomText
                numberOfLines={1}
                style={[
                  styles.textAlignLeft,
                  {
                    color: theme?.text?.gray?.[100],
                    fontFamily: muliFont?.[600]?.normal,
                  },
                ]}>
                {subtitle}
              </CustomText>
            </Box>
          </HBox>
          <Box style={styles.runDetails}>
            <CustomText style={textStyle}>This demo covers:</CustomText>
            {instructions.map((instruction, index) => (
              <CustomText key={instruction} style={textStyle}>{`${
                index + 1
              }. ${instruction}`}</CustomText>
            ))}
          </Box>
          <BottomButton
            title={buttonText}
            isLoading={loading || countLoading}
            disabled={loading || countLoading}
            loadingText={buttonText}
            onPress={async () => await startTutorial(userType)}
            bgColor={theme?.background?.green?.[100]}
            style={[
              styles.buttonHeight,
              {
                width: getWidth(Dimensions.get('window').width * 0.8),
              },
            ]}
          />
        </Box>
      );
    },
    [
      countLoading,
      loading,
      muliFont,
      startTutorial,
      theme?.background?.green,
      theme?.icon.blue,
      theme?.text?.blue,
      theme?.text.gray,
    ],
  );

  return (
    <Header showDefaultLeftHeader>
      <ScreenHeader title="Tutorial" />
      <Divider />
      <Box style={styles.container}>
        <H6Text
          style={[
            styles.textAlignLeft,
            {
              fontFamily: muliFont[600].normal,
              color: theme?.text.gray[100],
            },
          ]}>
          To start the demo please select the role that you are going to play
          for this application.
        </H6Text>
        <Box>
          <If condition={tutorialMode.shopper}>
            {Cell(
              'How to Pick/Pack ',
              'Buncha Shopper Demo',
              'Start Shopper Demo',
              UserTypes.shopper,
              [
                'Picking and packing groceries',
                'Requesting substitutes',
                'Transferring orders to drivers',
              ],
            )}
          </If>
          <If condition={tutorialMode.driver}>
            <Divider
              style={[
                styles.divider,
                {
                  backgroundColor: theme?.text.gray[200],
                },
              ]}
            />
            {Cell(
              'How to Deliver Order',
              'Buncha Driver Demo',
              'Start Driver Demo',
              UserTypes.driver,
              ['Marking orders as ‘on the way’', 'Delivering orders'],
            )}
          </If>
        </Box>
      </Box>
    </Header>
  );
};

const styles = StyleSheet.create({
  buttonHeight: {
    height: 52,
  },
  runDetails: {
    marginHorizontal: 10,
    marginVertical: 10,
  },
  divider: {
    marginTop: 10,
    marginBottom: 5,
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  container: {
    marginHorizontal: getWidth(Dimensions.get('window').width * 0.06),
    flex: 1,
    marginTop: 15,
  },
  marginVer: {
    marginVertical: 10,
  },
  containerBox: {
    borderWidth: 1,
    paddingHorizontal: 15,
    paddingTop: 15,
    borderRadius: 20,
    width: getWidth(Dimensions.get('screen').width * 0.88),
    marginTop: 10,
    marginBottom: 5,
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  cellContainer: {
    maxWidth: getWidth(Dimensions.get('screen').width * 0.6),
    marginLeft: 5,
    width: '100%',
  },
});

export default React.memo(Tutorials);
