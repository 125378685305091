import {Box, Divider} from 'native-base';
import {
  Buyers,
  DynamicTextsProps,
} from '../../../../itemDetails/types/itemDetails';
import {H3Text, H6Text} from '../../../../components/core/text';
import {
  ItemDetailsAction,
  useCatalogItemToFetchUpdatedQuantity,
  useUpdateOrderItemQuantity,
} from '@buncha/itemDetails/hooks/ItemDetails';
import React, {useCallback, useMemo, useState} from 'react';
import {getWidth, isWeb} from '../../../../utils/common';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../appStyles/hooks/theme';

import {BottomButton} from '../../../../components/composites/Button';
import {CommonScreens} from '@buncha/common/navigation/types';
import ContactBottomSheet from '../contactBottomSheet';
import {CustomItemMode} from '../../../../itemDetails/types/CustomItem';
import CustomSubModal from '../CustomSubModal';
import DropItem from '../DropItem/DropItem';
import {DynamicTextType} from '../../../../batchDetails/types';
import {HBox} from '@buncha/components/core/view';
import If from '../../../../components/conditional/If';
import {ItemDetailsScreen} from '../../../../itemDetails/navigation/types';
import ItemNotesModal from '../itemNotesModal';
import {MainScreens} from '../../../../navigation/types';
import {OrderItem} from '@buncha/home/types';
import ProfileIcon from '../../../../components/core/icon/ProfileIcon';
import {ShopperScreens} from '../../../../home/navigation/types';
import {StyleSheet} from 'react-native';
import {SubstitutionChoice} from '../../../../itemDetails/types/itemReplacement';
import SubstitutionInfo from '../SubstitutionInfo/SubstitutionInfo';
import {Touchable} from '@buncha/components/core/touchable';
import UpdateQuantitySheet from '../UpdateQuantitySheet';
import UserNameUpperPill from '@buncha/common/components/VipProfileIcon';
import font from '../../../../appStyles/style/font';
import {navigation} from '../../../../config/navigation';
import {useRoute} from '@react-navigation/native';

interface RouteParamsType {
  runId?: number;
}
interface BuyerInfoProps {
  buyers: Buyers;
  isMenu: boolean;
  isCustomItem: boolean;
  chatEnable: boolean;
  dispatch?: React.Dispatch<any> | null;
}

const BuyerInfo = (props: BuyerInfoProps) => {
  const route = useRoute();
  const routeParams: RouteParamsType | undefined = route?.params;
  const runId = routeParams?.runId || 0;
  const orderId = props?.buyers?.orderId;
  const chatEnable = props.chatEnable;
  const theme = useColorThemeType('main');
  const [showContactSheet, setShowContactSheet] = useState(false);
  const [contactSheetProps, setContactSheetProps] = useState<
    DynamicTextsProps | undefined
  >(undefined);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showCustomSubModal, setShowCustomSubModal] = useState(false);
  const [showQtyUpdateSheet, setShowQtyUpdateSheet] = useState(false);
  const orderItemId = Number(props?.buyers.orderItemId) || 0;
  const toggleContactSheet = useCallback(() => {
    if (contactSheetProps) return setContactSheetProps(undefined);
    setShowContactSheet(!showContactSheet);
  }, [contactSheetProps, showContactSheet]);
  const toggleNotesModal = useCallback(() => {
    setShowNotesModal(!showNotesModal);
  }, [showNotesModal]);
  const muliFont = useFontTheme('muli');

  const {updateOrderItemQuantity, info} = useUpdateOrderItemQuantity();
  const {getCatalogItemToFetchUpdatedQty} =
    useCatalogItemToFetchUpdatedQuantity(props?.dispatch || null);
  const substitutionChoice = props?.buyers?.substitutionChoice;
  const hasNoSubstitute =
    substitutionChoice === SubstitutionChoice.noSubstitution;
  const hasSubstitute = substitutionChoice === SubstitutionChoice.byBuyer;

  const toggleCustomModal = useCallback(() => {
    setShowCustomSubModal(!showCustomSubModal);
  }, [showCustomSubModal]);

  const navigateToCustom = useCallback(() => {
    navigation.navigate(ShopperScreens.ItemDetails, {
      screen: ItemDetailsScreen.CustomItem,
      params: {
        orderId,
        orderItemId,
        mode: CustomItemMode.substituteItem,
        maxQuantity: props?.buyers?.quantity,
        runId: runId,
      },
    });
  }, [orderId, orderItemId, props?.buyers?.quantity, runId]);

  const handelSubstituteNav = useCallback(() => {
    if (props?.isMenu) return toggleCustomModal();
    navigation.navigate(ShopperScreens.ItemDetails, {
      screen: ItemDetailsScreen.ItemReplacement,
      params: {
        orderItemId: orderItemId,
        runId: runId,
        orderId: orderId,
        hasSubstitute: hasSubstitute,
      },
    });
  }, [orderItemId, props?.isMenu, runId, toggleCustomModal]);

  const handleContactSheetClose = useCallback((func: Function) => {
    return () => {
      setContactSheetProps(undefined);
      setShowContactSheet(false);
      func();
    };
  }, []);

  const handleContact = useCallback(() => {
    const buyerChatInfo = props.buyers.order?.buyerChatInfo;
    if (
      isWeb() ||
      !buyerChatInfo?.conversationId ||
      !buyerChatInfo.isVisible ||
      !chatEnable
    )
      return toggleContactSheet();

    navigation.navigate(MainScreens.Common, {
      screen: CommonScreens.Chat,
      params: {
        orderItemId: orderItemId,
        conversationId: buyerChatInfo.conversationId,
        phoneNumber: props.buyers.phoneNumber,
        name:
          (props?.buyers?.firstName || '') +
          ' ' +
          (props?.buyers?.lastName || ''),
        runId: runId,
      },
    });
  }, [
    chatEnable,
    orderItemId,
    props.buyers?.firstName,
    props.buyers?.lastName,
    props.buyers.order?.buyerChatInfo,
    props.buyers.phoneNumber,
    runId,
    toggleContactSheet,
  ]);

  const getSubstitutionType = useCallback(() => {
    switch (substitutionChoice) {
      case SubstitutionChoice.byBuyer:
        return DynamicTextType.recommendSubs;
      case SubstitutionChoice.noSubstitution:
        return DynamicTextType.noSubs;
      case SubstitutionChoice.byShopper:
        return DynamicTextType.addSubstitute;
      case SubstitutionChoice.none:
        return DynamicTextType.addSubstitute;
      default:
        return DynamicTextType.addSubstitute;
    }
  }, [substitutionChoice]);

  const handleSubstitution = useCallback(() => {
    if (hasSubstitute) return handelSubstituteNav();
    const params: DynamicTextsProps = {
      buttonText: 'Add Substitute',
      onButtonClick: handleContactSheetClose(handelSubstituteNav),
      type: getSubstitutionType(),
    };
    setContactSheetProps(params);
  }, [getSubstitutionType, handelSubstituteNav, handleContactSheetClose]);

  const handleShowContactSheet = useCallback(
    (func: () => Promise<any>) => {
      const params: DynamicTextsProps = {
        buttonText: 'Drop',
        onButtonClick: handleContactSheetClose(func),
        type: DynamicTextType.dropItem,
      };
      setContactSheetProps(params);
    },
    [handleContactSheetClose],
  );

  const getText = useMemo(() => {
    switch (substitutionChoice) {
      case SubstitutionChoice.byBuyer:
        return 'Replacement added by customer';
      case SubstitutionChoice.noSubstitution:
        return "Don't substitute for customer";
      default:
        return 'Select Replacement for customer';
    }
  }, [substitutionChoice]);

  const toggleEditQuantity = useCallback(() => {
    setShowQtyUpdateSheet(!showQtyUpdateSheet);
  }, [showQtyUpdateSheet]);

  const handleUpdateQuantity = useCallback(
    async (updatedQty: number) => {
      await updateOrderItemQuantity(
        {
          orderItemId: orderItemId,
          itemQuantity: Number(updatedQty),
        },
        async (updateOrderItemQuantityRes: OrderItem) => {
          if (updateOrderItemQuantityRes && props?.dispatch) {
            await getCatalogItemToFetchUpdatedQty(orderItemId);
            props.dispatch(
              ItemDetailsAction.updateQtyByOrderItemId({
                orderItemId: orderItemId,
                updatedQty:
                  updateOrderItemQuantityRes?.quantity || Number(updatedQty),
              }),
            );
          }
        },
      );
      toggleEditQuantity();
    },
    [
      getCatalogItemToFetchUpdatedQty,
      orderItemId,
      props,
      toggleEditQuantity,
      updateOrderItemQuantity,
    ],
  );

  return (
    <Box style={[styles.parentContainer]}>
      <Box style={[styles.Container]}>
        <Box style={[styles.rowFlex]}>
          <Box style={[styles.jcc]}>
            <ProfileIcon viewBox="-3 -2 25 25" fill={theme?.text.blue[300]} />
          </Box>
          <Box style={[styles.jcc, styles.flexBox]}>
            <If condition={props?.buyers?.order?.buyer?.isVip}>
              <UserNameUpperPill text={'VIP Customer'} />
            </If>
            <H3Text style={[styles.nameTypo, {color: theme?.text.blue[300]}]}>
              {props?.buyers?.firstName} {props?.buyers?.lastName}
            </H3Text>
          </Box>
        </Box>
        <Box style={[styles.jcc]}>
          <BottomButton
            title={'Contact'}
            style={[styles.buttonStyle]}
            titleStyle={[styles.nameTypo]}
            onPress={handleContact}
          />
        </Box>
      </Box>
      <Divider />
      <HBox style={styles.editQtyBox}>
        <H6Text
          numberOfLines={2}
          style={[
            styles.childTypo,
            {
              fontFamily: muliFont[600].normal,
            },
          ]}>
          No. of Quantity Ordered:{' '}
          {props?.buyers?.updatedQuantity || props?.buyers?.quantity}
          <If condition={props?.buyers?.updatedQuantity}>
            {` | Original Qty: ${props?.buyers?.quantity}`}
          </If>
        </H6Text>
        <Touchable onPress={toggleEditQuantity} style={styles.editQtyContainer}>
          <H6Text
            style={[
              styles.editQyt,
              {color: theme?.text.blue[100], fontFamily: muliFont[700].normal},
            ]}>
            edit quantity
          </H6Text>
        </Touchable>
      </HBox>
      <If condition={props?.buyers?.substitutionNote}>
        <Divider />
        <H6Text
          style={[
            styles.childTypo,
            styles.textStyle,
            {
              color: theme?.text?.orange[100],
              fontFamily: font.fonts?.muliSemiBold,
            },
          ]}>
          Notes added by the {props.buyers?.firstName}:{' '}
          <H6Text
            style={[
              {
                color: theme?.text?.blue[300],
                fontFamily: font.fonts?.muliSemiBold,
              },
            ]}>
            {props?.buyers?.substitutionNote?.trim()}
          </H6Text>
        </H6Text>
      </If>
      <Divider />
      <If condition={!props?.isCustomItem}>
        <SubstitutionInfo
          title={getText}
          onClick={handleSubstitution}
          disabled={hasNoSubstitute}
          hasSubstitute={hasSubstitute}
          substitutionChoice={props?.buyers?.substitutionChoice}
        />
      </If>
      <SubstitutionInfo title="Add notes" onClick={toggleNotesModal} />
      <Divider />
      <DropItem
        orderItemId={orderItemId}
        showContactSheet={handleShowContactSheet}
      />
      {/* <Divider /> */}
      <If condition={showContactSheet || Boolean(contactSheetProps)}>
        <ContactBottomSheet
          isOpen={showContactSheet || Boolean(contactSheetProps)}
          onClose={toggleContactSheet}
          // buyerInfo={props?.buyers}
          showSMSTemplates={true}
          buttonText={contactSheetProps?.buttonText}
          onButtonClick={contactSheetProps?.onButtonClick}
          type={contactSheetProps?.type}
          runId={runId}
          orderId={orderId}
          hasSubstitute={hasSubstitute}
        />
      </If>
      <UpdateQuantitySheet
        open={showQtyUpdateSheet}
        onClose={toggleEditQuantity}
        orderItemId={orderItemId}
        quantity={props?.buyers?.updatedQuantity || props?.buyers?.quantity}
        handleUpdateQuantity={handleUpdateQuantity}
        loading={info?.loading}
      />
      <ItemNotesModal
        isOpen={showNotesModal}
        onClose={toggleNotesModal}
        orderItemId={orderItemId}
      />
      <CustomSubModal
        isOpen={showCustomSubModal}
        onClose={toggleCustomModal}
        onSubmit={navigateToCustom}
      />
    </Box>
  );
};

export default BuyerInfo;

const styles = StyleSheet.create({
  parentContainer: {
    paddingBottom: 10,
  },
  Container: {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  rowFlex: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  nameTypo: {
    ...font.FontSize.fontH5,
    textAlign: 'left',
    alignItems: 'center',
  },
  childTypo: {
    textAlign: 'left',
    flex: 1,
  },
  textStyle: {
    marginVertical: 10,
  },
  editQyt: {
    paddingLeft: 10,
    paddingRight: 5,
    fontFamily: font.fonts.muliSemiBold,
    textDecorationLine: 'underline',
  },
  jcc: {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 2,
  },
  buttonStyle: {
    height: 37,
    width: getWidth(87),
    marginBottom: 0,
  },
  flexBox: {flex: 1, alignItems: 'flex-start', marginLeft: 10},
  editQtyBox: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 10,
  },
  editQtyContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
});
