import {ActivityIndicator, ScrollView, StyleSheet} from 'react-native';
import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../config/redux/hooks';

import {Analytics} from '../../config/analytics/analytics';
import {BottomButton} from '../../components/composites/Button';
import {Divider} from 'native-base';
import {EventType} from '../../config/analytics/type';
import Header from '../../components/composites/Header/Header';
import KeyboardAvoidLayout from '../../components/composites/keyboardAvoidLayout';
import {OnBoardingScreens} from '../navigation/types';
import PhoneNumber from '../components/PhoneNumberInput/PhoneNumber';
import TermAndPrivacy from '../components/LoginComponent/TermAndPrivacy';
import {navigation} from '@buncha/config/navigation';
import {useAuthentication} from '../hooks';
import {useColorThemeType} from '../../appStyles/hooks/theme';

export default function Login() {
  const onBoardingState = useAppSelector(gState => gState.onboarding);
  const authData = onBoardingState.userAuthData;
  const [clearPhoneNumber, setClearPhoneNumber] = useState(false);
  const dispatch = useAppDispatch();
  const [isValidate, setIsValidate] = useState(false);
  const theme = useColorThemeType();

  const {sendingSMSCode, sendSMSCode, verifyingOTP} =
    useAuthentication(dispatch);

  const clearPhoneNumberFunc = useCallback((val: boolean) => {
    setClearPhoneNumber(val);
  }, []);

  useEffect(() => {
    Analytics.event('Enter Phone Number');
  }, []);

  const onLogin = useCallback(async () => {
    Analytics.eventWithProps('Login and Verify OTP', {
      type: EventType.cta,
      phoneNumber: authData?.phoneNumber,
    });
    await sendSMSCode(authData?.phoneNumber || '');
    navigation.navigate(OnBoardingScreens.VerificationCode);
  }, [authData?.phoneNumber, sendSMSCode]);

  return (
    <KeyboardAvoidLayout>
      <Header showDefaultLeftHeader={true} hideUploadFile>
        <ScrollView style={[styles.container]}>
          <PhoneNumber
            clearPhoneNumber={clearPhoneNumber}
            hasOTPSend={authData?.otpSent || verifyingOTP || false}
            toggleParentState={() => clearPhoneNumberFunc(false)}
            setIsValidate={(val: any) => {
              setIsValidate(val);
            }}
          />
          <TermAndPrivacy />
        </ScrollView>

        <Divider />
        <BottomButton
          showLegalInfo={true}
          title={'Log In'}
          style={styles.buttonStyle}
          disabledBgColor={theme?.background.green[200]}
          isLoading={verifyingOTP || sendingSMSCode}
          loadingIndicator={
            (verifyingOTP || sendingSMSCode) && <ActivityIndicator />
          }
          onPress={onLogin}
          disabled={!isValidate || sendingSMSCode}
        />
      </Header>
    </KeyboardAvoidLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
  },
  headerTypo: {
    textAlign: 'left',
  },
  buttonStyle: {
    marginTop: 15,
  },
  keyboardView: {
    flex: 1,
  },
});
