import {Box, HBox} from '../../components/core/view';
import {MainScreens, TabScreens} from '@buncha/navigation/types';
import React, {useCallback, useEffect, useState} from 'react';
import {useColorThemeType, useFontTheme} from '../../appStyles/hooks/theme';

import ApplicantHeader from '../components/OnboardingPhases/ApplicantHeader';
import {BottomButton} from '@buncha/components/composites/Button';
import {CustomText} from '@buncha/components/core/text';
import Header from '../../components/composites/Header/Header';
import {HiringApplicationState} from '@buncha/common/types/common';
import If from '@buncha/components/conditional/If';
import {Info} from '@buncha/components/core/icon';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import PhaseList from '../components/OnboardingPhases/PhaseList/PhaseList';
import {SpinnerComponent} from '@buncha/components/core/loader';
import {isWeb} from '../../utils/common';
import {navigation} from '@buncha/config/navigation';
import {styles} from '../styles/onboardingPhases';
import {useAppSelector} from '@buncha/config/redux/hooks';
import {useGetAccountSetupInfo} from '../hooks/onboardingPhases';
import {useIsFocused} from '@react-navigation/native';
import {useSetApplicationStatus} from '../hooks/applicationStatus';

type Props = NativeStackScreenProps<any, any>;

const OnboardingPhases: React.FC<Props> = () => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const user = useAppSelector(gState => gState?.onboarding?.currentUser);
  const userName = user?.firstName || '';
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const {setApplicationStatus} = useSetApplicationStatus();
  const isFocused = useIsFocused();
  const {loading, getAccountSetupInfo, phaseData} = useGetAccountSetupInfo();

  const isWebMode = isWeb();

  const id = user?.fullfillmentHiringApplicationState?.id || 0;

  const navigateToAccountSetup = useCallback(async () => {
    if (id) {
      await setApplicationStatus([{id: id}], HiringApplicationState.completed);
      navigation.reset([
        {
          name: MainScreens.Tabs,
          params: {
            screen: TabScreens.YourSchedule,
          },
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    if (isFocused) getAccountSetupInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  useEffect(() => {
    let isCompleted = true;
    if (!loading)
      phaseData.find(section => {
        if (section.completed === false && section.isRequired === true) {
          setButtonDisabled(true);
          isCompleted = false;
        }
      });
    if (isCompleted) setButtonDisabled(false);
  }, [loading, phaseData]);

  return (
    <Header
      headerColor={theme?.background?.blue?.[300]}
      headerLeftComponent={isWebMode && <Box style={styles.webHeader} />}>
      <ApplicantHeader
        applicantName={userName}
        refreshScreen={getAccountSetupInfo}
      />
      <If condition={loading}>
        <SpinnerComponent containerStyle={styles.spinnerContainer} />
      </If>
      <If condition={!loading}>
        <Box style={styles.parentBox}>
          <CustomText style={[styles.title, {fontFamily: muli?.[600]?.normal}]}>
            Before we start training, we’ll finalize employment details.
          </CustomText>
          <PhaseList refreshScreen={getAccountSetupInfo} sections={phaseData} />
        </Box>
        <Box
          style={[
            styles.bottomBox,
            {
              borderColor: theme?.icon?.gray?.[200],
            },
          ]}>
          <HBox style={styles.footerBox}>
            <Info
              height={18}
              width={18}
              style={styles.infoIcon}
              viewBox="-3 2 25 6.5"
              fill={theme?.icon?.gray?.[100]}
            />
            <CustomText
              style={[
                styles.bottomText,
                {
                  color: theme?.text?.gray?.[100],
                },
              ]}>
              Finish the above to be eligible for your first shift
            </CustomText>
          </HBox>
          <BottomButton
            showLegalInfo={true}
            title={'Continue'}
            style={styles.buttonStyle}
            disabledBgColor={theme?.background.green[200]}
            disabled={buttonDisabled}
            onPress={navigateToAccountSetup}
          />
        </Box>
      </If>
    </Header>
  );
};

export default OnboardingPhases;
