import {ApiState} from '../../common/types/common';
import {GET_PDF_RECEIPT} from '../../config/graphQL/documentNode/queries';
import {GetPDFReceiptResponse} from '../types';
import {gqlService} from '../../config/graphQL';
import {useState} from 'react';
export const useGetPDFReceipt = () => {
  const [apiState, setApiState] = useState<ApiState<string>>({
    loading: false,
    error: undefined,
    data: undefined,
  });

  const getPDF = async (runId: number) => {
    try {
      setApiState({...apiState, loading: true});
      const res = await gqlService?.query<GetPDFReceiptResponse>({
        query: GET_PDF_RECEIPT,
        variables: {
          runId: runId,
        },
        fetchPolicy: 'network-only',
      });

      if (res?.data)
        setApiState({
          ...apiState,
          loading: false,
          data: res.data?.getPdfReceipt,
        });
    } catch (error: any) {
      setApiState({...apiState, loading: false, error: new Error(error)});
    }
  };

  return {getPDF, apiState};
};
