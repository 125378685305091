import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    marginTop: 15,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  UPCmainContainer: {
    marginTop: 15,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    padding: 10,
    justifyContent: 'flex-start',
  },
  subContainer: {
    marginHorizontal: 20,
    marginVertical: 10,
  },
  sortText: {
    textAlign: 'left',
  },
  sortContainer: {
    justifyContent: 'flex-start',
  },
  sortIcon: {
    marginTop: -4,
  },
});
