import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  modal: {
    backgroundColor: '#00000066',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBackground: {
    marginVertical: 20,
    borderRadius: 10,
    // maxWidth: '90%',
    width: '90%',
  },
  modalHeader: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 15,
    // marginBottom: 10,
    marginHorizontal: 20,
  },
  modalData: {
    textAlign: 'center',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
  },
  modalFooter: {
    // marginBottom: 5,
  },
});
