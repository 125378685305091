import {Dimensions, Linking, Platform} from 'react-native';
import {H2Text, H6Text} from '@buncha/components/core/text';
import React, {useCallback} from 'react';
import {getWidth, isWeb} from '@buncha/utils/common';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {Analytics} from '@buncha/config/analytics/analytics';
import {BottomButton} from '@buncha/components/composites/Button';
import {Box} from '@buncha/components/core/view';
import {VersionCheck} from '@buncha/home/types';
import {styles} from './styles';

interface VersionCheckProps {
  versionInfo?: VersionCheck;
}

function VersionCheckSheet(props: VersionCheckProps) {
  const {versionInfo} = props;
  const muliFont = useFontTheme('muli');
  const width = Number(getWidth(Dimensions.get('screen').width));
  const theme = useColorThemeType();

  const onClick = useCallback(() => {
    Analytics.eventWithProps('hp_app_update', {
      CTA: 'hp_app_update_cta',
    });
    if (Platform.OS === 'ios')
      Linking.openURL(
        'https://apps.apple.com/us/app/pikup-pickup-with-friends/id6448009115?l=is',
      );
    else
      Linking.openURL(
        'https://play.google.com/store/apps/details?id=com.bunchashopper',
      );
  }, []);

  return (
    <Box>
      <H2Text
        style={[
          styles.text,
          {
            color: theme?.text.blue[300],
            fontFamily: muliFont[600].normal,
          },
        ]}>
        {versionInfo?.data.header}
      </H2Text>
      <H6Text
        style={[
          styles.text,
          {
            color: theme?.text.blue[300],
            fontFamily: muliFont[600].normal,
          },
        ]}>
        {versionInfo?.data.subHeader}
      </H6Text>
      <BottomButton
        style={[
          styles.buttonContainer,
          {width: isWeb() ? width * 0.75 : width * 0.95},
        ]}
        title="Update"
        onPress={onClick}
      />
    </Box>
  );
}

export default React.memo(VersionCheckSheet);
