import {Linking, Platform} from 'react-native';

import {GET_ACCOUNT_SETUP_INFO} from '@buncha/config/graphQL/documentNode/queries';
import {GetAccountSetupInfoResponse} from '@buncha/config/graphQL/type';
import {PageDetails} from '../types/onboardingPhases';
import {ToastType} from '@buncha/components/composites/notification/type';
import {gqlService} from '@buncha/config/graphQL';
import {useState} from 'react';
import {useToastMessage} from '@buncha/components/composites/notification';

export function useGetAccountSetupInfo() {
  const [loading, setLoading] = useState(true);
  const [showErrorToast] = useToastMessage(ToastType.Error);
  const [phaseData, setPhaseData] = useState<PageDetails[]>([]);

  const getAccountSetupInfo = async () => {
    setLoading(true);
    try {
      const response = await gqlService?.query<GetAccountSetupInfoResponse>({
        query: GET_ACCOUNT_SETUP_INFO,
        fetchPolicy: 'network-only',
      });
      const data = response?.data?.getAccountSetupInfo?.pageDetails || [];
      setPhaseData(data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showErrorToast(err?.message);
    }
  };

  return {
    getAccountSetupInfo,
    loading,
    phaseData,
  };
}

export const openLink = (link: {android: string; ios: string; web: string}) => {
  if (Platform.OS === 'ios') Linking.openURL(link.ios);
  else if (Platform.OS === 'android') Linking.openURL(link.android);
  else Linking.openURL(link.web);
};
