import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  useGetMasterRunForDriver,
  useVanLoadAtReducer,
} from '../hooks/VanLoadAt';

import {Analytics} from '@buncha/config/analytics/analytics';
import {BottomButton} from '@buncha/components/composites/Button';
import {Box} from '@buncha/components/core/view';
import BuyerOrderList from '../components/vanLoadAt/BuyerOrderList';
import {DeliveryLocationStatus} from '../types/common';
import {Divider} from 'native-base';
import {EventType} from '@buncha/config/analytics/type';
import If from '@buncha/components/conditional/If';
import LoaderComponents from '@buncha/components/composites/CustomLoader/LoaderComponents';
import OnFleetBanner from '@buncha/common/components/OnFleetBanner';
import {SafeAreaView} from 'react-native-safe-area-context';
import SequenceBottomSheet from '../components/vanLoadAt/BuyerOrderList/SequenceBottomSheet';
import {SpinnerComponent} from '@buncha/components/core/loader';
import {StyleSheet} from 'react-native';
import {VanLoadAtContext} from '../context/VanLoadAt';
import VanLoadAtHeader from '../components/vanLoadAt/VanLoadAtHeader';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';

const LoadVanAt = () => {
  const theme = useColorThemeType();
  const [state, dispatch] = useVanLoadAtReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);

  const route = useRoute();
  const routeParams: {runId?: number} | undefined = route.params;
  const runId = routeParams?.runId || 0;

  const run = state?.run;
  const masterRun = run?.masterRun;
  const masterStore = masterRun?.store;

  const [sequanceModal, setSequanceModal] = useState(false);

  const toggleBottomSheet = useCallback(() => {
    setSequanceModal(!sequanceModal);
  }, [sequanceModal]);

  const {getMasterRun, loading} = useGetMasterRunForDriver(dispatch);

  const onPressHandler = useCallback(() => {
    toggleBottomSheet();
  }, [toggleBottomSheet]);

  useEffect(() => {
    if (runId) getMasterRun(runId);
    Analytics.eventWithProps('Van Load At', {
      type: EventType.page,
      runId: runId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId]);

  const disabledButton = useMemo(() => {
    let hasEnabled = false;
    if (state.groupedOrders?.length)
      state.groupedOrders.forEach(groupedOrder => {
        groupedOrder.orders.forEach(order => {
          const orderStatus = order.deliveryLocation.deliveryStatus;
          if (orderStatus !== DeliveryLocationStatus.driverAssigned)
            hasEnabled = true;
        });
      });
    return hasEnabled;
  }, [state.groupedOrders]);

  return (
    <SafeAreaView
      mode="padding"
      edges={['bottom', 'top']}
      style={[styles.flexBox, {backgroundColor: theme?.text.white[100]}]}>
      <VanLoadAtContext.Provider value={provider}>
        <If condition={loading}>
          <LoaderComponents showBottomText={false} />
        </If>
        <If condition={!loading}>
          <Box style={styles.flexBox}>
            <VanLoadAtHeader
              storeLogoUrl={masterStore?.storeLogoUrl || ''}
              storeName={masterStore?.name || ''}
              address={masterStore?.address || ''}
              startRoute={masterRun?.dropOffStartTime || ''}
            />
            <Box style={styles.banner}>
              <OnFleetBanner />
            </Box>

            <BuyerOrderList runId={runId} infoButtonPress={toggleBottomSheet} />
          </Box>
          <Box style={styles.bottomButtonBox}>
            <Divider />
            <BottomButton
              title={'Start Route'}
              disabled={disabledButton}
              onPress={onPressHandler}
              isLoading={false}
              loadingIndicator={<SpinnerComponent />}
            />
          </Box>
        </If>
        <SequenceBottomSheet
          open={sequanceModal}
          onClose={toggleBottomSheet}
          runId={runId}
          routeStarted={!disabledButton}
        />
      </VanLoadAtContext.Provider>
    </SafeAreaView>
  );
};

export default React.memo(LoadVanAt);

export const styles = StyleSheet.create({
  flexBox: {
    flex: 1,
  },
  bottomButtonBox: {marginBottom: 10},
  banner: {
    marginVertical: 10,
    alignSelf: 'center',
  },
});
