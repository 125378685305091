import * as React from 'react';

import {ClipPath, Defs, G, Path} from 'react-native-svg';

import Icon from './Icon';
import {IconProps} from './type';

const HandPointDown: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <G clipPath="url(#a)">
        <Path
          fill={props.fill}
          d="M2.63 13.275v-4.08c-.236.16-.474.288-.713.386C1.005 9.954 0 9.311 0 8.375c0-.509.312-.88.833-1.094.81-.331 1.843-2.15 2.215-2.94a1.38 1.38 0 0 1 1.256-.778h4.914c.338 0 .627.235.679.554.207 1.28 1.102 1.688 1.097 3.383 0 .074.006.362.006.438 0 1.385-.634 2.23-2.041 1.985-.267.508-1.131.84-1.785.45-.607.673-1.544.619-2.033.172v2.73c0 .66-.578 1.225-1.255 1.225-.667 0-1.256-.584-1.256-1.225Zm.578-10.806V1.156c0-.362.308-.656.688-.656h5.5c.38 0 .687.294.687.656V2.47c0 .362-.307.656-.687.656h-5.5c-.38 0-.688-.294-.688-.656Zm6.073-.656a.56.56 0 0 0-.573-.547.56.56 0 0 0-.573.546.56.56 0 0 0 .573.547.56.56 0 0 0 .573-.546Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill={props.fill} d="M0 .5h11v14H0z" />
        </ClipPath>
      </Defs>
    </Icon>
  );
};

export default HandPointDown;
