import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  dataContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    // flexGrow: 1,
  },
  rowFlex: {
    flexDirection: 'row',
  },
  iconStyle: {
    marginRight: 15,
  },
  jCC: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  mainContainer: {flex: 1},
  iconBox: {marginRight: 5},
  buttonStyle: {marginBottom: 10},
  bottomContainer: {
    paddingTop: 5,
    borderTopWidth: 1,
  },
});
