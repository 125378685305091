import {Box, HBox} from '../../../components/core/view';
import {H3Text, H5Text, H6Text} from '../../../components/core/text';

import {BottomButton} from '../../../components/composites/Button';
import React from 'react';
import SampleImage from '../SampleImage/SampleImage';
import {styles} from './Styles';
import {useColorThemeType} from '../../../appStyles/hooks/theme';
import If from '@buncha/components/conditional/If';
import {Touchable} from '@buncha/components/core/touchable';
import {ReceiptBypassMode} from '@buncha/batchDetails/types';
import Scrollview from '@buncha/components/composites/scrollview';

const UploadTutorial = (props: {
  onTakePhoto: any;
  hasSkip?: ReceiptBypassMode;
  skipReceiptHandler?: () => void;
}) => {
  const theme = useColorThemeType();
  return (
    <Box style={[styles.body]}>
      <Scrollview style={styles.scrollStyles}>
        <Box>
          <If condition={props?.hasSkip === ReceiptBypassMode.showSkip}>
            <Touchable activeOpacity={0.5} onPress={props?.skipReceiptHandler}>
              <H5Text
                style={[styles.skipText, {color: theme?.text.green[100]}]}>
                Skip
              </H5Text>
            </Touchable>
          </If>
          <HBox style={[styles.centerBox]}>
            <Box style={[styles.typoBox]}>
              <H3Text style={{color: theme?.text.blue[300]}}>
                Help us verify in-store prices
              </H3Text>
              <H6Text style={[styles.subTitle]}>
                Clearly capture the item names and prices on the receipt
                pictures, can take multiple images
              </H6Text>
            </Box>
          </HBox>
          <HBox style={[styles.imageContainer]}>
            <SampleImage header="Do ✅" isDo={true} />
            <SampleImage header="Don't ❌" isDo={false} />
          </HBox>
        </Box>
      </Scrollview>
      <BottomButton
        style={[styles.buttonStyle]}
        onPress={() => props.onTakePhoto()}
        title="Upload Receipt"
      />
    </Box>
  );
};

export default React.memo(UploadTutorial);
