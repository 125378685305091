import {Dimensions, StyleSheet} from 'react-native';
import React, {useMemo} from 'react';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../src/appStyles/hooks/theme';

import {Box} from '../../../src/components/core/view';
import Calender from '../../../src/components/core/icon/Calender';
import {H5Text} from '../../../src/components/core/text';
import {isWeb} from '@buncha/utils/common';

const NoAvailableOpenShifts = () => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const isWebMode = isWeb();

  const viewBox = useMemo(() => {
    if (isWebMode) return '10 -10 210 210';
    return '-2 4.6 245 10';
  }, [isWebMode]);

  return (
    <Box style={styles.parentBox}>
      <Calender height={199} width={237} viewBox={viewBox} />
      <H5Text
        style={[
          {
            color: theme?.text?.blue?.[300],
            fontFamily: muli?.[700]?.normal,
          },
          styles.text,
        ]}>
        Hmm... it seems like there are no shifts available at the moment.
      </H5Text>
    </Box>
  );
};

export default React.memo(NoAvailableOpenShifts);

const styles = StyleSheet.create({
  parentBox: {
    alignItems: 'center',
    alignSelf: 'center',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    height: Dimensions.get('window').height * 0.5,
  },
  text: {
    width: '80%',
    marginTop: 10,
  },
  imageStyle: {
    height: Dimensions.get('window').height * 0.5,
    width: '100%',
  },
  imageStyleWeb: {
    height: Dimensions.get('window').height * 0.3,
    width: '100%',
  },
});
