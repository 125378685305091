import {Box} from 'native-base';
import {H5Text} from '@buncha/components/core/text';
import React from 'react';
import {StyleSheet} from 'react-native';
import font from '@buncha/appStyles/style/font';
import {getWidth} from '@buncha/utils/common';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';

function VerifyUPCPill() {
  const theme = useColorThemeType('main');
  return (
    <Box
      style={[
        styles.ParentContainer,
        {backgroundColor: theme?.background?.yellow[300]},
      ]}>
      <H5Text style={[styles.typo, {color: theme?.text?.blue[300]}]}>
        Verify UPC
      </H5Text>
    </Box>
  );
}

export default React.memo(VerifyUPCPill);

const styles = StyleSheet.create({
  ParentContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 2,
    height: 27,
    width: getWidth(70),
    borderRadius: 20,
  },
  typo: {
    ...font.FontSize.fontP10,
    fontFamily: font.fonts.muliBold,
  },
});
