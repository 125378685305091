import * as React from 'react';

import Svg, {Path, SvgProps} from 'react-native-svg';

const DollarSearch = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      fill="#100E3A"
      d="M12.25.25c-4.133 0-7.5 3.367-7.5 7.5 0 1.796.63 3.44 1.687 4.735L.461 18.46l1.078 1.08 5.976-5.977a7.442 7.442 0 0 0 4.735 1.687c4.133 0 7.5-3.367 7.5-7.5s-3.367-7.5-7.5-7.5Zm0 1.5c3.322 0 6 2.678 6 6s-2.678 6-6 6c-3.323 0-6-2.678-6-6s2.677-6 6-6ZM11.5 4v.797c-.844.141-1.5.857-1.5 1.736a1.485 1.485 0 0 0 .82 1.335l2.18 1.1a.266.266 0 0 1-.281.281h-.938a.267.267 0 0 1-.281-.281V8.5H10v.469c0 .879.656 1.594 1.5 1.735v.796H13v-.797c.844-.141 1.5-.857 1.5-1.736 0-.565-.314-1.08-.82-1.335l-2.18-1.1c0-.167.114-.281.281-.281h.938c.167 0 .28.114.28.281V7h1.5v-.469c0-.879-.655-1.593-1.5-1.734V4h-1.5Z"
    />
  </Svg>
);
export default DollarSearch;
