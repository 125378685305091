import {OrderItemStatus, OrderItems} from '../../../batchDetails/types';
import React, {useCallback, useState} from 'react';

import AppModal from '../../../common/components/CustomModal/AppModal';
import BatchOrderItem from '../BatchOrderItem';
import {CustomFlatList} from '../../../components/core/list';
import {Divider} from 'native-base';
import MinusTickIcon from '../../../components/core/icon/MinusTickIcon';
import RightTickIcon from '../../../components/core/icon/RightTickIcon';
import {SpinnerComponent} from '../../../components/core/loader';
import {Touchable} from '../../../components/core/touchable';
import {isWeb} from '@buncha/utils/common';
import {styles} from './styles';
import {useColorThemeType} from '../../../appStyles/hooks/theme';

interface BatchOrderItemListProps {
  orderItems: OrderItems[];
  onClick: (orderItem: OrderItems) => void;
  disableOnclick?: boolean;
  esIndex: string;
}

function DoneOrderItemList(props: BatchOrderItemListProps) {
  const {orderItems, onClick} = props;
  const theme = useColorThemeType();
  const [itemId, setItemId] = useState(0);
  const [dropItem, setDropItem] = useState<OrderItems>();
  const [showModal, setShowModal] = useState(false);

  const dropItemModal = useCallback((itemClicked: OrderItems) => {
    setDropItem(itemClicked);
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const setStatus = useCallback(
    (orderItem: OrderItems) => {
      setItemId(orderItem.id);
      onClick(orderItem);
      if (orderItem.itemStatus === OrderItemStatus.dropped) closeModal();
    },
    [closeModal, onClick],
  );

  const onItemClick = useCallback(
    (orderItem: OrderItems) => {
      if (orderItem.itemStatus === OrderItemStatus.dropped)
        return dropItemModal(orderItem);
      setStatus(orderItem);
    },
    [dropItemModal, setStatus],
  );

  const getRightIcon = useCallback(
    (item: OrderItems) => {
      if (itemId === item.id) return <SpinnerComponent size={'small'} />;
      switch (item.itemStatus) {
        case OrderItemStatus.dropped:
          return (
            <MinusTickIcon
              width={24}
              height={24}
              viewBox="-3 -1 25 25"
              fill={theme?.background.red[100]}
            />
          );
        case OrderItemStatus.picked:
          if (item.substitutedBy)
            return (
              <RightTickIcon
                width={24}
                height={24}
                viewBox="-3 -1 25 25"
                fill={theme?.background.yellow[100]}
              />
            );
          return (
            <RightTickIcon
              width={24}
              height={24}
              viewBox="-3 -1 25 25"
              fill={theme?.background.green[100]}
            />
          );
        default:
          return (
            <RightTickIcon
              width={24}
              height={24}
              viewBox="-3 -1 25 25"
              fill={theme?.background.green[100]}
            />
          );
      }
    },
    [
      itemId,
      theme?.background.green,
      theme?.background.red,
      theme?.background.yellow,
    ],
  );

  const renderItem = useCallback(
    ({item}: {item: OrderItems; index: number}) => {
      return (
        <BatchOrderItem
          esIndex={props.esIndex}
          orderItem={item}
          rightIcon={
            <Touchable
              style={[
                styles.touchableStyle,
                // eslint-disable-next-line react-native/no-inline-styles
                props?.disableOnclick && {
                  opacity: 0.5,
                },
              ]}
              disabled={props?.disableOnclick}
              onPress={() => onItemClick(item)}>
              {getRightIcon(item)}
            </Touchable>
          }
          onClick={onItemClick}
          disableOnclick={!isWeb()}
        />
      );
    },
    [getRightIcon, onItemClick, props?.disableOnclick, props.esIndex],
  );

  const Separator = useCallback(() => <Divider style={styles.divider} />, []);
  return (
    <React.Fragment>
      <CustomFlatList
        data={orderItems}
        renderItem={renderItem}
        ItemSeparatorComponent={Separator}
      />
      <AppModal
        isOpen={showModal}
        onClose={closeModal}
        topCTAHandelr={() => {
          if (dropItem) setStatus(dropItem);
        }}
        title={'Pick Item Again?'}
        subTitle={
          'Are you sure you want to pick this item again? It appears to be currently marked as “dropped.”'
        }
        ctaBottomTitle={'Cancel'}
        ctaTopTitle={'Yes, undrop item'}
        topCTAColor={theme?.background.green[100]}
      />
    </React.Fragment>
  );
}

export default React.memo(DoneOrderItemList);
