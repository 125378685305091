import {HomeScreens, ShopperScreens} from './types';

import AboutShift from '../../AboutShift/screen/AboutShift';
import AddItemPage from '../../batchDetails/screens/AddItemPage';
import AttachmentsPage from '@buncha/memos/screens/AttachmentsPage';
import BatchDetailsPage from '../../batchDetails/screens/BatchDetailsPage';
import {DriverNavigator} from '../../../src/driver/navigation';
import {ItemDetails} from '../../itemDetails/navigation';
import MemoDetails from '@buncha/memos/screens/MemoDetails';
import NavigateVanLoadPage from '@buncha/batchDetails/screens/NavigateVanLoadPage';
import OpenShifts from '../../openShifts/screens/OpenShifts';
import OrderPackingPage from '../../batchDetails/screens/OrderPackingPage';
import PastOrders from '../screens/PastOrders';
import PastRuns from '../screens/PastRuns';
import PdfDetailPage from '../../batchDetails/screens/PdfDetailPage';
import React from 'react';
import Tutorials from '../screens/Tutorials';
import UPCShifts from '../../UPCShifts/screens/UPCShifts';
import YourSchedule from '../screens/YourSchedule';
import {createNativeStackNavigator} from '@react-navigation/native-stack';

const Stack = createNativeStackNavigator();

const HomeNavigator: React.FC = () => {
  const navigationOption = {
    gestureEnabled: false,
  };
  return (
    <Stack.Navigator
      screenOptions={{headerShown: false, gestureEnabled: false}}>
      <Stack.Screen
        name={HomeScreens.YourSchedule}
        component={YourSchedule}
        options={navigationOption}
      />
      <Stack.Screen
        name={HomeScreens.AboutShift}
        component={AboutShift}
        options={navigationOption}
      />
      <Stack.Screen
        name={HomeScreens.OpenShifts}
        component={OpenShifts}
        options={navigationOption}
      />
      <Stack.Screen
        name={HomeScreens.PastRuns}
        component={PastRuns}
        options={navigationOption}
      />
      <Stack.Screen
        name={HomeScreens.PastOrders}
        component={PastOrders}
        options={navigationOption}
      />
      <Stack.Screen
        name={HomeScreens.Tutorials}
        component={Tutorials}
        options={navigationOption}
      />
      <Stack.Screen
        name={HomeScreens.Driver}
        component={DriverNavigator}
        options={navigationOption}
      />
      <Stack.Screen
        name={HomeScreens.Shopper}
        component={ShopperNavigator}
        options={navigationOption}
      />
      <Stack.Screen
        name={HomeScreens.MemoDetails}
        component={MemoDetails}
        options={navigationOption}
      />
      <Stack.Screen
        name={HomeScreens.AttachmentsPage}
        component={AttachmentsPage}
        options={{...navigationOption, animation: 'fade'}}
      />
      <Stack.Screen
        name={HomeScreens.UPCShifts}
        component={UPCShifts}
        options={navigationOption}
      />
    </Stack.Navigator>
  );
};

const ShopperNavigator: React.FC = () => {
  const navigationOption = {
    gestureEnabled: false,
  };
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen
        name={ShopperScreens.BatchDetailsPage}
        component={BatchDetailsPage}
        options={navigationOption}
      />
      <Stack.Screen
        name={ShopperScreens.ItemDetails}
        component={ItemDetails}
        options={navigationOption}
      />
      <Stack.Screen
        name={ShopperScreens.PdfDetailScreen}
        component={PdfDetailPage}
        options={navigationOption}
      />
      <Stack.Screen
        name={ShopperScreens.OrderPackingPage}
        component={OrderPackingPage}
        options={navigationOption}
      />
      <Stack.Screen
        name={ShopperScreens.UPCShifts}
        component={UPCShifts}
        options={navigationOption}
      />
      <Stack.Screen
        name={ShopperScreens.AddItemPage}
        component={AddItemPage}
        options={navigationOption}
      />
      <Stack.Screen
        name={ShopperScreens.VanLoadSite}
        component={NavigateVanLoadPage}
        options={navigationOption}
      />
    </Stack.Navigator>
  );
};

export {HomeNavigator, ShopperNavigator};
