import {
  AddItemActions,
  useAddItemContext,
} from '../../../batchDetails/hooks/AddItem';
import React, {useCallback} from 'react';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {AddItemCell} from '../../../batchDetails/types';
import {Box} from 'native-base';
import {CustomImage} from '../../../components/core/Image';
import {H5Text} from '../../../components/core/text';
import {HBox} from '../../../components/core/view';
import If from '../../../components/conditional/If';
import {PButton} from '../../../components/core/Button';
import QuantitySelector from '../../../components/composites/QuantitySelector';
import {formatCurrency} from '../../../utils/common';
import {scaleFonts} from '../../../appStyles/style/font';
import {styles} from './styles';

interface ReplacementItemCellProps {
  itemInfo: AddItemCell;
}

const ItemAddCell: React.FC<ReplacementItemCellProps> = props => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const {state, dispatch} = useAddItemContext();
  const {itemInfo} = props;
  const name = itemInfo?.name || '';
  const imageUrl = itemInfo?.itemImageUrl || '';
  const price = formatCurrency(itemInfo?.price);

  const {selectedQuantity, selectedItem} = state;

  const selectedItemId = selectedItem?.id;

  const handleSelect = useCallback(() => {
    if (dispatch) dispatch(AddItemActions.setSelectedItem(itemInfo));
  }, [dispatch, itemInfo]);

  const handleQuantityUpdate = useCallback(
    (qty: number) => {
      if (dispatch) {
        dispatch(AddItemActions.setSelectedQuantity(qty));
        if (!qty) dispatch(AddItemActions.setSelectedItem(undefined));
      }
    },
    [dispatch],
  );

  return (
    <HBox style={styles.parentBox}>
      <Box style={styles.imageBox}>
        <CustomImage style={styles.image} source={imageUrl} />
      </Box>
      <Box style={styles.textBox}>
        <HBox>
          <Box style={styles.namePriceBox}>
            <H5Text
              numberOfLines={2}
              alignText="left"
              style={{
                color: theme?.text?.blue?.[300],
                fontFamily: muli?.[700]?.normal,
              }}>
              {name}
            </H5Text>
            <H5Text
              alignText="left"
              style={{
                color: theme?.text?.blue?.[300],
                fontFamily: muli?.[700]?.normal,
              }}>
              {price}
            </H5Text>
          </Box>
          <If condition={selectedItemId !== itemInfo?.id}>
            <PButton
              style={styles.replaceCTA}
              title="Add"
              titleStyle={{fontSize: scaleFonts(12)}}
              onPress={handleSelect}
            />
          </If>
          <If condition={selectedItemId === itemInfo?.id}>
            <QuantitySelector
              height={40}
              width={100}
              quantity={selectedQuantity}
              onQuantityUpdate={handleQuantityUpdate}
              minQuantity={0}
            />
          </If>
        </HBox>
      </Box>
    </HBox>
  );
};

export default React.memo(ItemAddCell);
