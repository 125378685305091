import {Divider, Skeleton} from 'native-base';
import React, {useMemo} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {Box} from '../../../../components/core/view';
import BuyerInfo from '../BuyerInfo/BuyerInfo';
import {Buyers} from '../../../types/itemDetails';
import {H6Text} from '@buncha/components/core/text';
import If from '@buncha/components/conditional/If';
import {styles} from './styles';
import {useItemDetailsContext} from '../../../hooks/ItemDetails';

interface BuyerDetailsProps {
  isMenu: boolean;
  isCustomItem: boolean;
  loadingBuyer?: boolean;
}

const BuyerDetails = (props: BuyerDetailsProps) => {
  const {isMenu, isCustomItem, loadingBuyer} = props;
  const {state, dispatch} = useItemDetailsContext();
  const {constants} = state;
  const isEnabled = constants?.CHAT_ENABLE === 'true';
  const muliFont = useFontTheme('muli');
  const theme = useColorThemeType();

  const renderBuyers = useMemo(() => {
    if (loadingBuyer)
      return (
        <Box style={{flex: 1, marginTop: 10}}>
          <Skeleton.Text px="4" />
          <Skeleton px="4" my="4" rounded="md" startColor="primary.100" />
        </Box>
      );
    return state?.buyersForItem?.map((buyer: Buyers) => {
      return (
        <BuyerInfo
          key={buyer.id}
          buyers={buyer}
          isMenu={isMenu}
          isCustomItem={isCustomItem}
          chatEnable={isEnabled}
          dispatch={dispatch}
        />
      );
    });
  }, [
    isEnabled,
    dispatch,
    isCustomItem,
    isMenu,
    state?.buyersForItem,
    loadingBuyer,
  ]);

  return (
    <Box style={[styles.container]}>
      <Divider />
      <If condition={state?.buyersForItem?.length}>
        <H6Text
          style={[
            styles.textStyle,
            {
              fontFamily: muliFont[600].normal,
              color: theme?.text.blue[300],
            },
          ]}>
          Total Customers who purchased this item:{' '}
          {state?.buyersForItem?.length}
        </H6Text>
      </If>
      {/* <ContactDisclaimer /> */}
      {renderBuyers}
    </Box>
  );
};

export default React.memo(BuyerDetails);
