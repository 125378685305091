import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    padding: 10,
    marginHorizontal: 10,
  },
  headerText: {
    marginTop: 10,
    marginBottom: 5,
  },
  buttonStyle: {
    width: '100%',
  },
});
