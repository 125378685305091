import React, {useCallback, useState} from 'react';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../../../src/appStyles/hooks/theme';

import {Analytics} from '../../../../../../src/config/analytics/analytics';
import BottomSheet from '../../../../../../src/components/composites/bottomSheet';
import {Box} from '../../../../../../src/components/core/view';
import {CustomFlatList} from '../../../../../../src/components/core/list';
import DeliveryFailedFooter from './deliveryFailedFooter';
import {DeliveryLocationStatus} from '../../../../../../src/driver/types/common';
import DeliveyFailedReasonCell from './deliveyFailedReasonCell';
import {Divider} from 'native-base';
import {DriverScreens} from '../../../../../../src/driver/navigation/type';
import {FailureReason} from '../../../../../../src/driver/types/orderDetails';
import {H3Text} from '../../../../../../src/components/core/text';
import {HomeScreens} from '../../../../../../src/home/navigation/types';
import {MainScreens} from '@buncha/navigation/types';
import {PTextInput} from '../../../../../../src/components/core/input/TextInput';
import {SpinnerComponent} from '../../../../../../src/components/core/loader';
import {TutorialName} from '@buncha/batchDetails/types';
import {navigation} from '../../../../../../src/config/navigation';
import {styles} from './style';
import {useFinishTutorialForRunner} from '@buncha/home/hooks/Tutorials';
import {useRoute} from '@react-navigation/native';
import {useSetDeliveryLocationAsFailed} from '../../../../../../src/driver/hooks/orderDetails';

interface DeliveryFailedBottomSheetProps {
  open: boolean;
  onClose: () => void;
  deliveryLocationId: number;
  runId: number;
}

const DeliveryFailedBottomSheet: React.FC<
  DeliveryFailedBottomSheetProps
> = props => {
  const theme = useColorThemeType('main');
  const mortise = useFontTheme('mortise');
  const runId = props?.runId || 0;
  const {setDeliveryLocationStatusAsFailed, loading} =
    useSetDeliveryLocationAsFailed();
  const {finishTutorialForRunner, tutorialLoading} =
    useFinishTutorialForRunner();
  const [selectedReasonId, setSelectedReasonId] = useState<number>(0);
  const [selectedReason, setSelectedReason] = useState<string>('');
  const [otherReason, setOtherReason] = useState<string>('');
  const deliveryLocationId = props?.deliveryLocationId || 0;
  const routeParams: any = useRoute().params;
  const tutorial = routeParams?.tutorial === 'true';

  const faliureOptions = [
    {
      reasonIndex: 1,
      reason: 'Not able to contact customer',
    },
    {
      reasonIndex: 2,
      reason: 'Customer not at home',
    },
    {
      reasonIndex: 3,
      reason: 'Other (please specify)',
    },
  ];
  let buttonDisabled: boolean =
    selectedReasonId === 0 ||
    (otherReason === '' && selectedReasonId === faliureOptions?.length);

  const changeFailedReason = useCallback(
    (option: FailureReason) => {
      setSelectedReasonId(option?.reasonIndex);
      if (option?.reasonIndex !== faliureOptions?.length)
        setSelectedReason(option?.reason);
      else setSelectedReason(otherReason);
    },
    [faliureOptions?.length, otherReason],
  );

  const renderItem = useCallback(
    (item: {item: FailureReason}) => {
      const reasonItem = item?.item;
      return (
        <DeliveyFailedReasonCell
          option={reasonItem}
          changeReason={changeFailedReason}
          selected={reasonItem.reasonIndex === selectedReasonId}
        />
      );
    },
    [changeFailedReason, selectedReasonId],
  );
  const handleItemNotesChange = useCallback(
    (text: string) => setOtherReason(text),
    [],
  );

  const navigateToDriverOrders = useCallback(() => {
    navigation.navigate(HomeScreens.Driver, {
      screen: DriverScreens.DriverOrders,
      params: {runId: runId},
    });
  }, [runId]);

  const markOrderAsFailed = useCallback(async () => {
    if (tutorial) {
      await finishTutorialForRunner(TutorialName.driverGuide);
      return navigation.navigate(MainScreens.Home, {
        screen: HomeScreens.Tutorials,
      });
    }
    if (deliveryLocationId && runId) {
      await setDeliveryLocationStatusAsFailed(
        deliveryLocationId,
        DeliveryLocationStatus.deliveryFailed,
        selectedReason || otherReason,
        runId,
      );
      props?.onClose();
      Analytics.eventWithProps('Mark delivery as failed', {
        type: 'CTA',
        deliveryLocationId: deliveryLocationId,
        reason: selectedReason || otherReason,
        deliveryStatus: DeliveryLocationStatus.deliveryFailed,
        userType: 'driver',
      });
      navigateToDriverOrders();
    }
  }, [
    deliveryLocationId,
    finishTutorialForRunner,
    navigateToDriverOrders,
    otherReason,
    props,
    runId,
    selectedReason,
    setDeliveryLocationStatusAsFailed,
    tutorial,
  ]);

  const separator = useCallback(() => <Divider />, []);

  if (loading || tutorialLoading)
    return <SpinnerComponent containerStyle={styles.spinner} />;
  return (
    <React.Fragment>
      <BottomSheet open={props?.open} onClose={() => props?.onClose()}>
        <Box style={styles.parentBox}>
          <H3Text
            alignText="left"
            style={{
              color: theme?.text?.blue?.[300],
              fontFamily: mortise?.[700]?.normal,
            }}>
            Reason for delivery failure
          </H3Text>
          <CustomFlatList
            scrollEnabled={false}
            data={faliureOptions}
            renderItem={renderItem}
            style={styles?.flatList}
            ItemSeparatorComponent={separator}
          />
          <PTextInput
            editable={selectedReasonId === faliureOptions?.length}
            value={otherReason}
            numberOfLines={10}
            placeholder="Please specify any other reason"
            textAlignVertical={'top'}
            multiline={true}
            debounce={false}
            returnKeyType="done"
            style={[styles.input, {color: theme?.text?.blue?.[300]}]}
            onChangeText={handleItemNotesChange}
            containerStyle={[
              styles.inputContainer,
              {
                borderColor: theme?.text?.gray?.[100],
              },
            ]}
          />
          <Divider />
          <DeliveryFailedFooter
            markAsFailed={markOrderAsFailed}
            disabled={buttonDisabled || loading}
            closeSheet={props?.onClose}
            tutorial={tutorial}
          />
        </Box>
      </BottomSheet>
    </React.Fragment>
  );
};

export default React.memo(DeliveryFailedBottomSheet);
