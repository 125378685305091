import React, {useCallback} from 'react';

import AppModal from '../../../../common/components/CustomModal/AppModal';
import {useColorThemeType} from '../../../../../src/appStyles/hooks/theme';

interface CustomSubModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const CustomSubModal: React.FC<CustomSubModalProps> = props => {
  const theme = useColorThemeType('main');

  const onClick = useCallback(() => {
    props?.onSubmit();
    props?.onClose();
  }, [props]);

  return (
    <AppModal
      isOpen={props?.isOpen}
      onClose={() => props?.onClose()}
      topCTAHandelr={onClick}
      title={'Important information!'}
      subTitle={
        'You can not create the custom item request for substituting item. Please follow the below steps.\n\n 1. Coordinate with your customer.\n 2. Directly substitute custom item.'
      }
      ctaBottomTitle={'Cancel'}
      ctaTopTitle={'Ok'}
      topCTAColor={theme?.background.green[100]}
      // loading={loading}
    />
  );
};

export default React.memo(CustomSubModal);
