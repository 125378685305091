import {Dimensions, StyleSheet} from 'react-native';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  useDeliveryDetailsReducer,
  useGetDeliveryDetails,
} from '../hooks/deliveryDetails';

import {Analytics} from '@buncha/config/analytics/analytics';
import BagInputComponent from '../components/BagInputComponent';
import {Box} from '../../../src/components/core/view';
import ConfirmDeliveryButton from '../components/deliveryDetails/confirmDeliveryButton';
import {CustomFlatList} from '../../components/core/list';
import {DeliveryDetailsContext} from '../context/deliveryDetails';
import DriverDeliveryNotes from '../components/deliveryDetails/driverDeliveryNotes';
import Header from '../../../src/components/composites/Header/Header';
import If from '../../../src/components/conditional/If';
import NameAndAddressDetails from '../components/common/nameAndAddressDetails';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import PhotoAndSignatureComponent from '../components/deliveryDetails/photoAndSignatureComponent';
import ScreenHeader from '../../../src/common/components/screenHeader';
import {SpinnerComponent} from '../../../src/components/core/loader';
import {isWeb} from '../../../src/utils/common';
import {useColorThemeType} from '../../../src/appStyles/hooks/theme';
import {useIsFocused} from '@react-navigation/native';

type Props = NativeStackScreenProps<any, any>;

const DeliveryDetails: React.FC<Props> = props => {
  const theme = useColorThemeType('main');
  const [state, dispatch] = useDeliveryDetailsReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);

  const [pageButtonDisabled, setPageButtonDisabled] = useState(true);
  const [bagReturnCount, setBagReturnCount] = useState(0);
  const [bagUsedCount, setBagUsedCount] = useState(0);
  const orderId = String(props?.route?.params?.orderId) || '';
  const runId = Number(props?.route?.params?.runId) || 0;
  const {getDeliveryDetails, loading, deliveryData} =
    useGetDeliveryDetails(dispatch);
  const buyerInfo = deliveryData?.buyer;
  const isFocused = useIsFocused();
  const deliveryLocationData = deliveryData?.deliveryLocation;
  const buyerAddress = deliveryLocationData?.userAddress;
  const deliveryLocationId = deliveryLocationData?.id || 0;
  const note = deliveryLocationData?.note || '';
  const deliveryNote = deliveryLocationData?.deliveryNote;
  const containsAlcoholicItems = deliveryData?.orderHasAlcoholicItems || false;
  const deliveryInstruction =
    deliveryData?.deliveryInstruction ||
    buyerAddress?.deliveryInstruction ||
    '';

  const getDeliveryInfo = useCallback(() => {
    if (orderId) {
      getDeliveryDetails(orderId, runId);
      Analytics.eventWithProps('Delivery Details', {
        type: 'Page',
        orderId: orderId,
        userType: 'driver',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    if (isFocused) getDeliveryInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const globalDisabled = useCallback((val: boolean) => {
    setPageButtonDisabled(val);
  }, []);

  return (
    <Header
      loading={loading}
      headerColor={theme?.background?.blue?.[300]}
      showDefaultLeftHeader>
      <DeliveryDetailsContext.Provider value={provider}>
        <ScreenHeader title="Delivery Details" />
        <If condition={loading}>
          <SpinnerComponent containerStyle={styles.spinnerContainer} />
        </If>
        <If condition={!loading}>
          <CustomFlatList
            ListHeaderComponent={
              <Box style={styles.parentBox}>
                <NameAndAddressDetails
                  buyerAddress={buyerAddress}
                  buyerInfo={buyerInfo}
                  forOrderDetails={true}
                  deliveryInstructions={deliveryInstruction}
                  orderId={Number(orderId)}
                  deliveryNote={deliveryNote}
                  runId={runId}
                />
                <If condition={!isWeb()}>
                  <PhotoAndSignatureComponent
                    deliveryLocationData={deliveryLocationData}
                    orderId={orderId}
                    containsAlcoholicItems={containsAlcoholicItems}
                    runId={runId}
                  />
                </If>
                <DriverDeliveryNotes
                  deliveryLocationId={deliveryLocationId}
                  driverNotes={note}
                />
                <If condition={!deliveryData?.run?.isExternalRun}>
                  <BagInputComponent
                    setGlobalDisabled={globalDisabled}
                    setBagReturnCount={setBagReturnCount}
                    setBagUsedCount={setBagUsedCount}
                    bagReturnCount={bagReturnCount}
                    bagUsedCount={bagUsedCount}
                  />
                </If>
              </Box>
            }
            data={undefined}
            renderItem={undefined}
            contentContainerStyle={styles.scrollView}
            showsVerticalScrollIndicator={false}
          />
          <Box>
            <ConfirmDeliveryButton
              runId={runId}
              orderId={props?.route?.params?.orderId}
              deliveryLocationId={deliveryLocationId}
              globalDisabled={
                pageButtonDisabled && !deliveryData?.run?.isExternalRun
              }
              bagReturnCount={bagReturnCount}
              bagUsedCount={bagUsedCount}
              isExternalRun={deliveryData?.run?.isExternalRun}
            />
          </Box>
        </If>
      </DeliveryDetailsContext.Provider>
    </Header>
  );
};

export default DeliveryDetails;

const styles = StyleSheet.create({
  parentBox: {
    paddingHorizontal: 20,
    flex: 1,
    position: 'relative',
  },
  webHeader: {
    padding: 30,
  },
  spinnerContainer: {
    justifyContent: 'center',
    height: Dimensions?.get('window').height * 0.8,
  },
  scrollView: {flexGrow: 1},
});
