import {
  ActivityIndicator,
  Dimensions,
  Keyboard,
  StyleSheet,
} from 'react-native';
import {HomeScreens, ShopperScreens} from '@buncha/home/navigation/types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  useUPCShiftContext,
  useUPCShifts,
} from '@buncha/UPCShifts/hooks/UPCShiftsCreation';

import AvailableUPCShifts from '../AvailableUPCShifts';
import {BottomButton} from '@buncha/components/composites/Button';
import {Box} from '@buncha/components/core/view';
import {Divider} from 'native-base';
import If from '@buncha/components/conditional/If';
import MinimumItemTextBox from './MinimumItemTextBox';
import {StoreData} from '@buncha/UPCShifts/types';
import StoreSelectorSection from './StoreSelectorSection';
import {navigation} from '@buncha/config/navigation';
import {useIsFocused} from '@react-navigation/native';

const UPCShiftComponent = () => {
  const {state, dispatch} = useUPCShiftContext();
  const isFocused = useIsFocused();

  const {getPendingUPCOrders, createUPCShift, creatingShift} =
    useUPCShifts(dispatch);

  useEffect(() => {
    if (isFocused) getPendingUPCOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const [selectedStore, setSelectedStore] = useState<StoreData>();
  const [itemCount, setItemCount] = useState<string>('');

  const selectStoreValue = useCallback((storeData: StoreData) => {
    setSelectedStore(storeData);
  }, []);

  const navigateToBatchPage = useCallback((id: number) => {
    setSelectedStore(undefined);
    setItemCount('');
    navigation.navigate(HomeScreens.Shopper, {
      screen: ShopperScreens.BatchDetailsPage,
      params: {isUPCMode: true, upcOrderId: id, runId: 0},
    });
  }, []);

  const createShiftHandler = useCallback(async () => {
    Keyboard.dismiss();
    await createUPCShift(
      selectedStore as StoreData,
      Number(itemCount),
      (id: number) => navigateToBatchPage(id),
    );
  }, [createUPCShift, itemCount, navigateToBatchPage, selectedStore]);

  const pendingUPCOrders = useMemo(() => {
    return state?.pendingUPCOrders;
  }, [state?.pendingUPCOrders]);

  const getTitle = useMemo(() => {
    if (selectedStore && Number(itemCount) !== 0) return 'Start this Shift';
    else if (selectedStore && Number(itemCount) === 0)
      return 'Enter number of items';
    return 'Select store';
  }, [itemCount, selectedStore]);

  return (
    <Box style={[styles.header]}>
      <Box style={[styles.parentContainer]}>
        <Box style={[styles.storeSectionStyle]}>
          <StoreSelectorSection
            selectedValue={selectedStore as StoreData}
            selectStoreValue={(val: StoreData) => selectStoreValue(val)}
          />
        </Box>
        <Box style={[styles.minimumItemCell]}>
          <Divider />
        </Box>
        <MinimumItemTextBox
          itemCount={itemCount}
          setItemCount={(val: string) => setItemCount(val)}
        />
        <BottomButton
          onPress={createShiftHandler}
          disabled={!selectedStore || Number(itemCount) === 0 || creatingShift}
          style={{width: Dimensions.get('screen').width * 0.9}}
          title={getTitle}
          isLoading={creatingShift}
          loadingIndicator={<ActivityIndicator />}
        />
      </Box>
      <If condition={pendingUPCOrders?.length}>
        <Box style={[styles.dividerContainer]}>
          <Divider />
        </Box>
        <Box style={[styles.pendingOrdersContainer]}>
          <AvailableUPCShifts />
        </Box>
      </If>
    </Box>
  );
};

export default React.memo(UPCShiftComponent);

const styles = StyleSheet.create({
  header: {
    flex: 1,
  },
  parentContainer: {
    paddingHorizontal: 20,
    position: 'relative',
    zIndex: 100,
  },
  pendingOrdersContainer: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flex: 1,
  },
  storeSectionStyle: {
    position: 'relative',
    zIndex: 100,
  },
  minimumItemCell: {
    marginTop: 20,
    marginBottom: 5,
  },
  dividerContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
});
