import {Box, HBox} from '@buncha/components/core/view';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {Animated} from 'react-native';
import {AttachmentsPageParams} from '../types';
import If from '@buncha/components/conditional/If';
import {isImage} from '../util';
import {styles} from './style';
import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';

interface Props {
  videoCounter: Animated.Value;
  currentIndex: number;
  goNext: () => void;
  paused: boolean;
}

const Pills = (props: Props) => {
  const {videoCounter, currentIndex, goNext, paused} = props;
  const theme = useColorThemeType();
  const route = useRoute();
  const routeParams: any = {
    ...route,
  };

  const attachmentParams: AttachmentsPageParams = routeParams?.params;

  const counter = useRef(new Animated.Value(0)).current;
  const countInterval = useRef<any>(null);
  const [count, setCount] = useState(0);
  const load = useCallback(
    (value: number) => {
      const counterValue = counter as any;
      Animated.timing(counter, {
        toValue: value,
        duration: counterValue?.__getValue() > value ? 0 : 1000,
        useNativeDriver: false,
      }).start();
    },
    [counter],
  );

  const width = counter.interpolate({
    inputRange: [0, 100],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  const videoWidth = videoCounter.interpolate({
    inputRange: [0, 100],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  const mediaUrls = useMemo(
    () => attachmentParams?.mediaUrls ?? [],
    [attachmentParams?.mediaUrls],
  );

  const mediaLength = mediaUrls?.length;

  const currentUrl = mediaUrls?.[currentIndex];

  const intervalCallback = useCallback(() => {
    setCount(prev => prev + 10);
  }, []);

  useEffect(() => {
    if (!paused) countInterval.current = setInterval(intervalCallback, 1000);
    else clearInterval(countInterval.current);
    return () => {
      clearInterval(countInterval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paused]);

  useEffect(() => {
    if (paused) return;
    load(count);
    if (count >= 100) {
      if (isImage(currentUrl)) goNext();
      setCount(100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    setCount(0);
  }, [currentIndex]);

  return (
    <HBox style={styles.pillContainer}>
      {Array.from({length: mediaLength}, (_, i) => i).map(i => {
        const val = isImage(mediaUrls[i]) ? width : videoWidth;
        return (
          <Box
            key={i}
            style={[
              styles.pillSubContainer,
              {backgroundColor: theme?.text.gray[300]},
            ]}>
            <If condition={currentIndex === i}>
              <Animated.View
                style={[
                  styles.pillBody,
                  {backgroundColor: theme?.text?.blue?.[100], flex: val},
                ]}
              />
            </If>
            <If condition={currentIndex !== i}>
              <Animated.View
                style={[
                  styles.pillBody,
                  // eslint-disable-next-line react-native/no-inline-styles
                  {
                    backgroundColor: theme?.text?.blue?.[100],
                    flex: i < currentIndex ? 1 : 0,
                  },
                ]}
              />
            </If>
          </Box>
        );
      })}
    </HBox>
  );
};

export default React.memo(Pills);
