import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useGetConstants, useItemDetailsReducer} from '../hooks/ItemDetails';

import {BottomButton} from '../../components/composites/Button';
import {Box} from '../../components/core/view';
import Header from '../../components/composites/Header/Header';
import If from '../../components/conditional/If';
import ItemDetailsBody from '../components/ItemDetail/ItemDetailsBody';
import {ItemDetailsContext} from '../context/ItemDetails';
import {ItemDetailsScreen} from '../navigation/types';
import LocationInStore from '../components/ItemDetail/locationInStore';
import QRCode from '../../components/core/icon/QRCode';
import ReminderModal from '../components/ItemDetail/ReminderModal';
import {ShopperScreens} from '@buncha/home/navigation/types';
import {isWeb} from '@buncha/utils/common';
import {navigation} from '@buncha/config/navigation';
import {styles} from '../styles/ItemDetails';
import {useColorThemeType} from '../../appStyles/hooks/theme';
import {useRoute} from '@react-navigation/native';

interface RouteParamsType {
  storeIndex?: string;
  catalogItemId?: number;
  orderItemId?: number;
  runId?: number;
  isUPCMode?: boolean;
}

const ItemDetailsPage = () => {
  const theme = useColorThemeType();
  const [state, dispatch] = useItemDetailsReducer();
  const provider = useMemo(() => ({state, dispatch}), [dispatch, state]);
  const {getConstants, constantsLoading} = useGetConstants(dispatch);
  const [reminderModalOpen, setReminderModalOpen] = useState(false);
  const route = useRoute();
  const routeParams: RouteParamsType | undefined = route?.params;
  const orderItemId = Number(routeParams?.orderItemId) || 0;
  const isUPCMode = routeParams?.isUPCMode;
  const storeIndex = routeParams?.storeIndex;
  const runId = routeParams?.runId || '';

  const [loadingData, setLoadingData] = useState(false);

  const quantity =
    state.item?.totalupdatedQuantityByShopper || state.item?.quantity;

  const bottomButtonLoading = loadingData || constantsLoading;

  const isCatalogItem = state.item?.id !== orderItemId;
  const hasScannerEnabled = useMemo(() => {
    return isCatalogItem && !isWeb();
  }, [isCatalogItem]);

  const getButtonText = useMemo(() => {
    if (hasScannerEnabled) return 'Found Item';
    return 'Pick Item';
  }, [hasScannerEnabled]);

  const navigateToScanPage = useCallback(() => {
    navigation.navigate(ShopperScreens.ItemDetails, {
      screen: ItemDetailsScreen.ItemScanner,
      params: {
        orderItemId,
        storeIndex,
        runId,
        quantity,
        isUpcMode: isUPCMode,
      },
    });
  }, [isUPCMode, orderItemId, quantity, runId, storeIndex]);

  const handleFoundItem = useCallback(() => {
    if (isUPCMode && hasScannerEnabled) navigateToScanPage();
    else setReminderModalOpen(true);
  }, [hasScannerEnabled, isUPCMode, navigateToScanPage]);

  const reminderModalClose = useCallback(() => {
    setReminderModalOpen(false);
  }, []);

  const handleLoadingData = useCallback((loading: boolean) => {
    setLoadingData(loading);
  }, []);

  useEffect(() => {
    getConstants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ItemDetailsContext.Provider value={provider}>
      <Header
        showDefaultLeftHeader={true}
        hideBottomSafeArea
        headerRightComponent={
          <If condition={state.item?.category}>
            <LocationInStore />
          </If>
        }
        headerColor={theme?.background.blue[300]}>
        <Box style={styles.mainContainer}>
          <ItemDetailsBody
            isUPCMode={isUPCMode}
            setLoading={handleLoadingData}
            loading={constantsLoading}
          />
          <BottomButton
            disabled={bottomButtonLoading}
            showDividers={true}
            showAlcoholicItemDisclaimer={!isCatalogItem}
            onPress={handleFoundItem}
            isLoading={bottomButtonLoading}
            loadingIndicator
            loadingText="Loading ..."
            title={getButtonText}
            style={styles.buttonStyle}
            leftComponent={
              <If condition={hasScannerEnabled}>
                <QRCode viewBox="-5 -2 22 17" style={styles.iconBox} />
              </If>
            }
          />
        </Box>
      </Header>
      <ReminderModal
        onClose={reminderModalClose}
        quantity={quantity}
        open={reminderModalOpen}
        isCatalogItem={hasScannerEnabled}
        orderItemIds={state?.orderItemIds}
      />
    </ItemDetailsContext.Provider>
  );
};

export default React.memo(ItemDetailsPage);
