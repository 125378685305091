import CustomItem from '../screens/CustomItem';
import ItemDetailsPage from '../screens/ItemDetailsPage';
import {ItemDetailsScreen} from './types';
import ItemReplacement from '../screens/ItemReplacement';
import ItemScanner from '../screens/ItemScanner';
import ItemUpdate from '../screens/ItemUpdate';
import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import ItemLocationPage from '../screens/ItemLocationPage';

const Stack = createNativeStackNavigator();

const MainNavigator: React.FC = () => {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen
        name={ItemDetailsScreen.ItemDetailsPage}
        component={ItemDetailsPage}
        options={{
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name={ItemDetailsScreen.ItemReplacement}
        component={ItemReplacement}
        options={{
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name={ItemDetailsScreen.ItemScanner}
        component={ItemScanner}
        options={{
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name={ItemDetailsScreen.ItemUpdate}
        component={ItemUpdate}
        options={{
          gestureEnabled: false,
        }}
      />

      <Stack.Screen
        name={ItemDetailsScreen.CustomItem}
        component={CustomItem}
        options={{
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name={ItemDetailsScreen.ItemLocationPage}
        component={ItemLocationPage}
        options={{
          gestureEnabled: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default MainNavigator;
