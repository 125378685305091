import {Box, HBox} from '../../components/core/view';
import {H2Text, H6Text} from '../../components/core/text';
import React, {useCallback} from 'react';
import {useColorThemeType, useFontTheme} from '../../appStyles/hooks/theme';

import {HomeScreens} from '../navigation/types';
import If from '../../components/conditional/If';
import {MainScreens} from '../../navigation/types';
import PluseIcon from '../../components/core/icon/PluseIcon';
import {StyleSheet} from 'react-native';
import {Touchable} from '../../components/core/touchable';
import {navigation} from '../../config/navigation';
import {useAppSelector} from '../../config/redux/hooks';

interface HomeHeaderProps {
  openWIWModal?: () => void;
  headerTitle: string;
  nameOnly?: boolean;
}

function HomeHeader(props: HomeHeaderProps) {
  const defaultTheme = useColorThemeType();
  const mortiseFont = useFontTheme('mortise');
  const muliFont = useFontTheme('muli');

  const onBoardingState = useAppSelector(gstate => gstate.onboarding);
  const whenIWorkData = onBoardingState.currentUser?.WhenIWorkInfo;

  const navigateToOpenShifts = useCallback(() => {
    if (whenIWorkData?.login?.isLogin)
      navigation.navigate(MainScreens.Home, {
        screen: HomeScreens.OpenShifts,
      });
    else if (props.openWIWModal) props.openWIWModal();
  }, [props, whenIWorkData?.login?.isLogin]);

  return (
    <Box
      style={[
        styles.mainContainer,
        {
          backgroundColor: defaultTheme?.background.blue[300],
        },
      ]}>
      <HBox
        style={[
          styles.mainSubContainer,
          {
            backgroundColor: defaultTheme?.background.blue[300],
          },
        ]}>
        <H2Text
          style={[
            {
              color: defaultTheme?.text.white[100],
              fontFamily: mortiseFont[700].normal,
            },
          ]}>
          {props.headerTitle}
        </H2Text>
        <If condition={!props.nameOnly}>
          <Touchable
            onPress={navigateToOpenShifts}
            style={[
              styles.shiftBox,
              {
                backgroundColor: defaultTheme?.background.blue[300],
              },
            ]}>
            <H6Text
              style={[
                styles.subText,
                {
                  color: defaultTheme?.text.white[100],
                  fontFamily: muliFont[700].normal,
                },
              ]}>
              Add Shifts
            </H6Text>
            <PluseIcon
              fill={defaultTheme?.background.white[100]}
              style={styles.iconStyle}
            />
          </Touchable>
        </If>
      </HBox>
    </Box>
  );
}

export default React.memo(HomeHeader);

const styles = StyleSheet.create({
  mainContainer: {
    justifyContent: 'flex-end',
  },
  mainSubContainer: {
    paddingVertical: 15,
    paddingHorizontal: 20,
  },
  iconStyle: {
    alignSelf: 'flex-end',
  },
  subText: {
    marginRight: 5,
    alignSelf: 'flex-start',
  },
  shiftBox: {
    marginBottom: -10,
    flexDirection: 'row',
  },
});
