import {
  Image,
  ImageErrorEventData,
  ImageSourcePropType,
  NativeSyntheticEvent,
  StyleSheet,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import {getDefaultImageUrl, isWeb} from '../../../utils/common';

import {Box} from '../view';
import {CustomImageProps} from './type';
import If from '../../conditional/If';

const CustomImage: React.FC<CustomImageProps> = React.forwardRef(
  (props, ref) => {
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState({uri: props.source as string});
    useEffect(() => {
      if (props.source) setUrl({uri: props.source as string});
      else setUrl({uri: getDefaultImageUrl()});
    }, [props.onErrorSource, props.source]);
    const func = {
      onError: (e: NativeSyntheticEvent<ImageErrorEventData>) => {
        if (typeof props.onError === 'function') props.onError(e);
        if (url.uri !== props.onErrorSource)
          return setUrl({uri: props.onErrorSource as string});
        return setUrl({uri: getDefaultImageUrl()});
      },
      onLoadEnd: () => {
        if (!isWeb()) setLoading(false);
        if (typeof props.onLoadEnd === 'function') props.onLoadEnd();
      },
      onLoadStart: function () {
        if (!loading && !isWeb()) setLoading(true);
        if (typeof props.onLoadStart === 'function') props.onLoadStart();
      },
    };
    const loadingSkeleton = props.loadingSkeleton;
    let uri: ImageSourcePropType = url;
    if (typeof url.uri === 'number') uri = url.uri;
    return (
      <Box
        style={[styles.container, props.containerStyle]}
        colorThemeType={props.colorThemeType}>
        <If condition={loading}>
          <Box style={styles.skeletonStyle}>{loadingSkeleton}</Box>
        </If>
        <Image key={uri.uri} {...props} source={uri} {...func} ref={ref} />
      </Box>
    );
  },
);

export default React.memo(CustomImage);

const styles = StyleSheet.create({
  skeletonStyle: {
    position: 'absolute',
  },
  container: {zIndex: 3},
});
