import {Box, HBox} from '../../../components/core/view';
import {CustomText, H5Text} from '../../../components/core/text';
import {Store, TaskInfo} from '../../../AboutShift/types';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import DeliveryVanIcon from '../../../components/core/icon/DeliveryVanIcon';
import If from '../../../components/conditional/If';
import React from 'react';
import ShopIcon from '../../../components/core/icon/ShopIcon';
import {getWidth} from '../../../utils/common';
import {styles} from './styles';

interface BatchRunInfoProps {
  store: Store;
  taskCount?: TaskInfo;
  hideStoreInfo?: boolean;
  hideDriverInfo?: boolean;
}

export function BatchRunInfo(props: BatchRunInfoProps) {
  const {store, taskCount} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');

  return (
    <Box style={styles.mainContainer}>
      <If condition={!props?.hideStoreInfo}>
        <HBox style={styles.subContainer}>
          <ShopIcon width={Number(getWidth(27))} fill={theme?.icon.blue[300]} />
          <Box style={styles.textContainer}>
            <H5Text
              style={[
                styles.text,
                {
                  fontFamily: muliFont[700].normal,
                  color: theme?.text.blue[300],
                },
              ]}>
              {`Shop at ${store.name}`}
            </H5Text>
            <CustomText
              style={[
                styles.text,
                {
                  fontFamily: muliFont[600].normal,
                  color: theme?.text.gray[100],
                },
              ]}>
              {taskCount?.orderCount} Orders | {taskCount?.itemCount} Items
              Total
            </CustomText>
          </Box>
        </HBox>
      </If>
      <If condition={!props?.hideDriverInfo}>
        <HBox style={styles.subContainer}>
          <DeliveryVanIcon
            width={Number(getWidth(28))}
            viewBox="0 0 35 35"
            fill={theme?.icon.blue[300]}
          />
          <Box style={styles.textContainer}>
            <H5Text
              style={[
                styles.text,
                {
                  fontFamily: muliFont[700].normal,
                  color: theme?.text.blue[300],
                },
              ]}>
              Pass Orders to Delivery Van
            </H5Text>
            <CustomText
              style={[
                styles.text,
                {
                  fontFamily: muliFont[600].normal,
                  color: theme?.text.gray[100],
                },
              ]}>
              Finish shopping then meet to load the Van
            </CustomText>
          </Box>
        </HBox>
      </If>
    </Box>
  );
}
