export interface DownTime {
  webOrder: boolean;
  buyerApp: boolean;
  shopperApp: boolean;
}

// eslint-disable-next-line no-shadow
export enum ModuleName {
  web = 'web',
  buyerApp = 'buyerApp',
  shopperApp = 'shopperApp',
}
