import {ChevronDownIcon, ChevronUpIcon, Divider} from 'native-base';
import React, {useCallback, useEffect} from 'react';

import {Accordian} from '../../../components/composites';
import AccordionTextHeader from './AccordionTextHeader';
import {AssignedShift} from '../../../home/types';
import {Box} from '../../../components/core/view';
import {CustomFlatList} from '../../../components/core/list';
import ShiftBatchList from './ShiftBatchList';
import {StyleSheet} from 'react-native';
import {useAppSelector} from '../../../config/redux/hooks';
import {useColorThemeType} from '../../../appStyles/hooks/theme';
import {Analytics} from '../../../config/analytics/analytics';
import {EventType} from '../../../config/analytics/type';

function AssignedShiftAccordion() {
  const defaultTheme = useColorThemeType();
  const state = useAppSelector(gState => gState.home.nextShiftData);
  const assignedShifts = state.upcoming;

  useEffect(() => {
    if (assignedShifts?.length)
      Analytics.eventWithProps('Assigned Shifts', {
        type: EventType.page,
        assignedShiftsCount: assignedShifts?.length,
      });
  }, [assignedShifts?.length]);

  const renderItem = useCallback(
    ({item}: {item: AssignedShift; index: number}) => {
      return (
        <Box>
          <Accordian
            isExpanded={false}
            header={
              <AccordionTextHeader
                titleText={`${item.key}`}
                count={item.shifts.length}
              />
            }
            children={<ShiftBatchList shiftList={item.shifts} />}
            iconEnable={<ChevronUpIcon color={defaultTheme?.text.blue[300]} />}
            iconDisable={
              <ChevronDownIcon color={defaultTheme?.text.blue[300]} />
            }
          />
        </Box>
      );
    },
    [defaultTheme?.text],
  );
  const Separator = useCallback(() => <Divider style={styles.divider} />, []);
  return (
    <CustomFlatList
      data={assignedShifts}
      renderItem={renderItem}
      ItemSeparatorComponent={Separator}
      ListFooterComponent={Separator}
    />
  );
}

export default React.memo(AssignedShiftAccordion);

const styles = StyleSheet.create({
  divider: {
    marginVertical: 15,
  },
});
