import {Box, HBox} from '@buncha/components/core/view';
import {Linking, StyleSheet} from 'react-native';
import React, {useCallback} from 'react';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {CustomText} from '@buncha/components/core/text';
import LinkOut from '@buncha/components/core/icon/LinkOut';
import OnFleetIcon from '@buncha/components/core/icon/OnFleetIcon';
import {Touchable} from '@buncha/components/core/touchable';

const OnFleetBanner = () => {
  const theme = useColorThemeType('main');
  const muli = useFontTheme('muli');
  const openOnFleet = useCallback(() => {
    Linking.openURL('https://www.onfleet.app');
  }, []);

  return (
    <Touchable onPress={openOnFleet}>
      <HBox
        style={[
          styles.bannerBox,
          {backgroundColor: theme?.background?.blue?.[100]},
        ]}>
        <HBox style={styles.textBox}>
          <OnFleetIcon
            height={14}
            width={26}
            fill={theme?.text?.white?.[100]}
            viewBox="2 -3 10 20"
          />
          <Box>
            <CustomText
              style={[
                styles.text,
                {
                  color: theme?.text?.white?.[100],
                  fontFamily: muli?.[700]?.normal,
                },
              ]}>
              Unable to retreive the route sequence from Onfleet.
            </CustomText>
            <CustomText
              style={[
                styles.text,
                {
                  color: theme?.text?.white?.[100],
                  fontFamily: muli?.[700]?.normal,
                },
              ]}>
              Please check with Onfleet for the van load sequence.
            </CustomText>
          </Box>
        </HBox>
        <LinkOut viewBox="1 -1 20 22" />
      </HBox>
    </Touchable>
  );
};

export default React.memo(OnFleetBanner);

const styles = StyleSheet.create({
  bannerBox: {
    borderRadius: 5,
    justifyContent: 'space-between',
    padding: 5,
    gap: 5,
    paddingVertical: 10,
  },
  textBox: {
    gap: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    textAlign: 'left',
  },
});
