import {Box, HBox} from '@buncha/components/core/view';
import {CustomText, H3Text} from '@buncha/components/core/text';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import {BackHeaderLeft} from '@buncha/common/components/BackHeaderLeft';
import BunchaYellowVan from '@buncha/components/core/icon/BunchaYellowVan';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {StatusBar} from 'react-native';
import StoreLogo from '@buncha/common/components/StoreLogo';
import {VanLoadAtHeaderStyle} from './styles';
import font from '@buncha/appStyles/style/font';

interface Props {
  storeLogoUrl: string;
  storeName: string;
  address: string;
  startRoute: string;
}

const VanLoadAtHeader = (props: Props) => {
  const {storeLogoUrl, storeName, address, startRoute} = props;
  const theme = useColorThemeType();
  const fontThemeMortise = useFontTheme('mortise');
  const fontThemeMuli = useFontTheme('muli');

  return (
    <Box>
      <SafeAreaView
        mode="padding"
        edges={['top']}
        style={{
          backgroundColor: theme?.background?.blue?.[300],
        }}
      />
      <StatusBar
        barStyle={'light-content'}
        backgroundColor={theme?.background?.blue?.[300]}
      />
      <Box
        style={[
          VanLoadAtHeaderStyle.headerBox,
          {backgroundColor: theme?.background.blue[300]},
        ]}>
        <HBox style={VanLoadAtHeaderStyle.headerChildBox}>
          <BackHeaderLeft
            containerStyle={{
              ...VanLoadAtHeaderStyle.backHeaderLeftContainer,
              backgroundColor: theme?.background.blue[300],
            }}
            iconColor={theme?.text.white[100]}
          />
          <Box style={VanLoadAtHeaderStyle.startRouteContainer}>
            <StoreLogo storeLogoUrl={storeLogoUrl} />
            <BunchaYellowVan
              viewBox="30 -5 150 150"
              width={57}
              height={26}
              style={VanLoadAtHeaderStyle.bunchaYellowVan}
            />
          </Box>
          <Box style={VanLoadAtHeaderStyle.storeDetailsContainer}>
            <H3Text
              style={[
                VanLoadAtHeaderStyle.LoadVanText,
                {
                  fontFamily: fontThemeMortise[700].normal,
                  color: theme?.text.white[100],
                },
              ]}>
              Load Van at
            </H3Text>
            <CustomText
              style={[
                VanLoadAtHeaderStyle.titleText,
                {
                  fontFamily: fontThemeMuli[700].normal,
                  color: theme?.text.white[100],
                },
              ]}>
              {storeName} | {address}
            </CustomText>
          </Box>
        </HBox>
      </Box>
      <Box
        style={[
          VanLoadAtHeaderStyle.routeContainer,
          {
            backgroundColor: theme?.background.yellow[100],
          },
        ]}>
        <CustomText
          style={{
            color: theme?.text.blue[300],
            fontFamily: font.fonts.muliSemiBold,
          }}>{`Start Route at ${startRoute}`}</CustomText>
      </Box>
    </Box>
  );
};

export default React.memo(VanLoadAtHeader);
