import {Dimensions, StyleSheet, TextStyle} from 'react-native';
import {H3Text, H6Text} from '../../../components/core/text';
import React, {ReactNode, useCallback, useEffect, useMemo} from 'react';
import {getWidth, isWeb} from '../../../utils/common';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {Analytics} from '../../../config/analytics/analytics';
import {BottomButton} from '../../../components/composites/Button';
import {Box} from '../../../components/core/view';
import CustomModal from './CustomModal';
import {EventType} from '../../../config/analytics/type';
import If from '../../../components/conditional/If';
import {SpinnerComponent} from '../../../components/core/loader';

interface AppModalProps {
  isOpen: boolean;
  onClose: () => void;
  topCTAHandelr: () => void;
  bottomCTAHandelr?: () => void;
  loading?: boolean;
  title: string;
  titleStyle?: TextStyle;
  subTitle?: string;
  subTitleStyle?: TextStyle;
  ctaTopTitle: string;
  ctaBottomTitle?: string;
  topCTAColor?: string;
  modalBody?: ReactNode;
  hideTopCta?: boolean;
  hideBottomCta?: boolean;
  disableCtaTop?: boolean;
  ctaTopLeftComponent?: ReactNode;
}

const AppModal: React.FC<AppModalProps> = props => {
  const theme = useColorThemeType();
  const fontTheme = useFontTheme('mortise');

  const {
    isOpen,
    onClose,
    loading,
    title,
    subTitle,
    topCTAHandelr,
    ctaTopTitle,
    ctaBottomTitle,
    modalBody,
    disableCtaTop,
    hideBottomCta,
    hideTopCta,
    ctaTopLeftComponent,
  } = props;

  const boxBody = useMemo(() => {
    if (modalBody) return modalBody;
    return (
      <Box>
        <H6Text style={[props?.subTitleStyle]}>{subTitle}</H6Text>
      </Box>
    );
  }, [modalBody, props?.subTitleStyle, subTitle]);

  const topCTAHandlerFun = useCallback(() => {
    Analytics.eventWithProps(`${ctaTopTitle} click`, {
      title: title,
      subTitle: subTitle,
      type: EventType.cta,
    });
    topCTAHandelr();
  }, [ctaTopTitle, subTitle, title, topCTAHandelr]);

  const bottomCTAHandelr = useCallback(() => {
    Analytics.eventWithProps(`${ctaBottomTitle} click`, {
      title: title,
      subTitle: subTitle,
      type: EventType.cta,
    });
    if (props?.bottomCTAHandelr) props?.bottomCTAHandelr();
    else props?.onClose();
  }, [ctaBottomTitle, props, subTitle, title]);

  useEffect(() => {
    if (isOpen)
      Analytics.eventWithProps('show modal', {
        title: title,
        subTitle: subTitle,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <React.Fragment>
      <CustomModal open={isOpen} onClose={onClose}>
        <Box style={[styles.parentContainer]}>
          <H3Text
            style={[
              styles.header,
              {
                fontFamily: fontTheme[900].normal,
                color: theme?.text.blue[300],
              },
              props?.titleStyle,
            ]}>
            {title}
          </H3Text>
          <Box style={[styles.childContainer]}>{boxBody}</Box>
          <Box>
            <If condition={!hideTopCta}>
              <BottomButton
                onPress={topCTAHandlerFun}
                style={[styles.buttonStyle]}
                bgColor={props?.topCTAColor || theme?.background.red[100]}
                disabled={disableCtaTop}
                title={ctaTopTitle}
                isLoading={loading}
                containerStyle={styles.CTALeft}
                loadingIndicator={
                  <SpinnerComponent
                    size={'small'}
                    color={theme?.icon.white[100]}
                  />
                }
                leftComponent={ctaTopLeftComponent}
              />
            </If>
            <If condition={!hideBottomCta}>
              <BottomButton
                onPress={bottomCTAHandelr}
                containerStyle={styles.CTARight}
                bgColor={theme?.background.white[100]}
                titleColor={theme?.background.gray[100]}
                borderColor={theme?.background.gray[100]}
                style={[styles.cancelButton]}
                title={ctaBottomTitle}
              />
            </If>
          </Box>
        </Box>
      </CustomModal>
    </React.Fragment>
  );
};

export default React.memo(AppModal);

const styles = StyleSheet.create({
  header: {
    marginBottom: 5,
  },
  modalBox: {
    width: getWidth(290),
  },
  body: {
    textAlign: 'left',
  },
  CTALeft: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 5,
  },
  CTARight: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  cancelButton: {
    borderWidth: 1,
    width: getWidth('100%'),
  },
  parentContainer: {
    padding: 20,
    width: isWeb() ? '100%' : getWidth(Dimensions.get('screen').width - 40),
  },
  childContainer: {
    marginVertical: 10,
  },
  buttonStyle: {
    width: getWidth('100%'),
  },
});
