import React, {useCallback, useMemo} from 'react';
import {Dimensions, StyleSheet} from 'react-native';
import {Box, HBox} from '../../../components/core/view';
import {getWidth} from '../../../utils/common';
import {PastOrder, PastRunData} from '../../../home/types';
import StoreLogo from '../../../common/components/StoreLogo';
import {CustomText, H5Text, H6Text} from '../../../components/core/text';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';
import {BottomButton} from '../../../components/composites/Button';
import {navigation} from '../../../config/navigation';
import {HomeScreens} from '../../../home/navigation/types';
import LightGreyPill from '../LightGreyPill';

interface RunCellProps {
  run: PastRunData;
  orders: PastOrder[];
}

const RunCell = (props: RunCellProps) => {
  const {run, orders} = props;
  const muliFont = useFontTheme('muli');
  const theme = useColorThemeType();

  const totalItemCount = useMemo(() => {
    return orders
      .map(order => order.items.length)
      .reduce((acc, itemCount) => Number(acc) + Number(itemCount), 0);
  }, [orders]);

  const getField = useCallback(
    (field: string, value: string) => {
      return (
        <HBox style={styles.fieldBox}>
          <H5Text
            style={{
              fontFamily: muliFont[700].normal,
              color: theme?.text.gray[100],
            }}>
            {`${field}: `}
            <H5Text
              style={{
                color: theme?.text.blue[300],
                fontFamily: muliFont[700].normal,
              }}>{`${value}`}</H5Text>
          </H5Text>
        </HBox>
      );
    },
    [muliFont, theme?.text.blue, theme?.text.gray],
  );

  const goToRunDetailPage = useCallback(() => {
    navigation.navigate(HomeScreens.PastOrders, {
      runId: run.id,
    });
  }, [run.id]);

  return (
    <Box style={styles.vBoxFlexStart}>
      <H5Text
        style={{
          color: theme?.text.blue[300],
          fontFamily: muliFont[700].normal,
        }}>
        Delivery time: {run.deliveryDateData.arrivalDate}
      </H5Text>
      <Box
        style={[
          styles.containerBox,
          {
            borderColor: theme?.text.gray[100],
          },
        ]}>
        <HBox style={styles.marginBot5}>
          <HBox
            style={[
              styles.statusCell,
              {backgroundColor: theme?.background.gray[200]},
            ]}>
            <Box
              style={[
                styles.dot,
                {
                  backgroundColor: theme?.text.green[100],
                },
              ]}
            />
            <H6Text
              style={{
                fontFamily: muliFont?.[700]?.normal,
                color: theme?.text.green[100],
              }}>
              Order Completed
            </H6Text>
          </HBox>
          <LightGreyPill text={`Run ID: ${run.id}`} />
        </HBox>
        <HBox style={styles.justifyStart}>
          <StoreLogo storeLogoUrl={run.store.storeLogoUrl} />
          <Box style={styles.storeContainer}>
            <H6Text
              numberOfLines={1}
              style={[
                styles.textAlignLeft,
                {
                  color: theme?.text?.blue?.[300],
                  fontFamily: muliFont?.[700]?.normal,
                },
              ]}>
              {run.storeName}
            </H6Text>
            <CustomText
              numberOfLines={1}
              style={[
                styles.textAlignLeft,
                {
                  color: theme?.text?.gray?.[100],
                  fontFamily: muliFont?.[600]?.normal,
                },
              ]}>
              {run.store.address}
            </CustomText>
          </Box>
        </HBox>
        <Box style={styles.runDetails}>
          {getField('Time', `${run.dropOffStartTime} - ${run.dropOffEndTime}`)}
          <HBox style={styles.hBoxFlexStart}>
            {getField('Total Items', `${totalItemCount}`)}
            {getField('Orders Assigned', `${orders.length}`)}
          </HBox>
        </Box>
        <BottomButton
          title={'View Task'}
          onPress={goToRunDetailPage}
          bgColor={theme?.background?.green?.[100]}
          style={[
            styles.buttonHeight,
            {
              width: getWidth(Dimensions.get('window').width * 0.8),
            },
          ]}
        />
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  containerBox: {
    borderWidth: 1,
    paddingHorizontal: 15,
    paddingTop: 15,
    borderRadius: 20,
    width: getWidth(Dimensions.get('screen').width * 0.88),
    marginTop: 10,
    marginBottom: 5,
  },
  storeContainer: {
    maxWidth: getWidth(Dimensions.get('screen').width * 0.6),
    marginLeft: 5,
    width: '100%',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  boxStretch: {
    alignSelf: 'stretch',
  },
  statusCell: {
    borderRadius: 20,
    paddingHorizontal: 7,
    paddingVertical: 3,
  },
  marginBot5: {marginBottom: 5},
  runTextBox: {
    borderRadius: 20,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  runDetails: {
    marginHorizontal: 10,
    marginVertical: 10,
  },
  hBoxFlexStart: {
    justifyContent: 'space-between',
    marginTop: 5,
  },
  vBoxFlexStart: {
    alignItems: 'flex-start',
    marginHorizontal: getWidth(Dimensions.get('window').width * 0.06),
  },
  fieldBox: {
    marginRight: 10,
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  dot: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 5,
  },
  buttonHeight: {
    height: 52,
  },
});

export default React.memo(RunCell);
