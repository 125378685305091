import React, {useCallback, useState} from 'react';

import AppModal from '../CustomModal/AppModal';
import {BottomButton} from '@buncha/components/composites/Button';
import BottomSheet from '@buncha/components/composites/bottomSheet';
import {Box} from '@buncha/components/core/view';
import If from '@buncha/components/conditional/If';
import ImageSection from './ImageSection';
import {styles} from './styles';

interface Props {
  bottomSheetOpen: boolean;
  title: string;
  subtitle: string;
  loading: boolean;
  imageList: string[];
  showCaptureModeFunc: (data?: any) => Promise<void>;
  closeBottomSheet: () => void;
  handleImageDelete: Function;
  uploadButtonHandler?: any;
  hasShowBottomButton?: boolean;
  hasDeleteModal?: boolean;
  isUPCMode?: boolean;
}

function ImageBottomSheet(props: Props) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUrlForDelete, setSelectedUrlForDelete] = useState('');

  const onDeleteHandler = useCallback(
    (url: string) => {
      setSelectedUrlForDelete(url);
      if (props?.hasDeleteModal) return setOpenDeleteModal(true);
      return props?.handleImageDelete(url);
    },
    [props],
  );

  return (
    <BottomSheet
      open={props?.bottomSheetOpen}
      onClose={props?.closeBottomSheet}>
      <Box style={styles.parentContainer}>
        <ImageSection
          showCaptureModeFunc={props?.showCaptureModeFunc}
          uploadLoading={props?.loading}
          title={props?.title}
          subtitle={props?.subtitle}
          imageList={props?.imageList}
          closeBottomSheet={props?.closeBottomSheet}
          handleImageDelete={onDeleteHandler}
          isUPCMode={props?.isUPCMode}
        />
        <If condition={props?.hasDeleteModal}>
          <AppModal
            isOpen={openDeleteModal}
            onClose={() => {
              setOpenDeleteModal(false);
            }}
            title={'Delete Image?'}
            subTitle="Are you sure you want to delete this image ?"
            topCTAHandelr={async () => {
              await props?.handleImageDelete(selectedUrlForDelete);
              setOpenDeleteModal(false);
            }}
            loading={props?.loading}
            ctaTopTitle={'Yes, Delete'}
            ctaBottomTitle={'Cancel'}
          />
        </If>
        <If condition={props?.hasShowBottomButton}>
          <BottomButton
            title={'Upload Images'}
            loadingText={'Uploading...'}
            isLoading={props?.loading}
            disabled={props?.loading || !props?.imageList.length}
            onPress={props?.uploadButtonHandler}
            style={styles.bottomButton}
          />
        </If>
      </Box>
    </BottomSheet>
  );
}

export default React.memo(ImageBottomSheet);
