import {DefaultContext} from '../../utils/types';
import React from 'react';
import {ItemLocationState} from '../types/ItemLocation';

export const initialState: ItemLocationState = {
  item: undefined,
  locationData: [],
  itemLocationInformation: undefined,
};

const defaultVal: DefaultContext<ItemLocationState> = {
  state: initialState,
  dispatch: null,
};

export const ItemLocationContext = React.createContext(defaultVal);
