import {useColorThemeType} from '@buncha/appStyles/hooks/theme';
import {CustomFlatList} from '../../core/list';
import React from 'react';

interface Props {
  children?: any;
  style?: any;
}

const Scrollview: React.FC<Props> = props => {
  const theme = useColorThemeType();

  return (
    <CustomFlatList
      {...props}
      showsVerticalScrollIndicator={false}
      data={[]}
      renderItem={undefined}
      keyboardShouldPersistTaps="always"
      ListHeaderComponent={props.children}
      style={[
        {
          backgroundColor: theme?.background?.white[100],
        },
        props.style || {},
      ]}
    />
  );
};

export default React.memo(Scrollview);
