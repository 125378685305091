import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import {Analytics} from '@buncha/config/analytics/analytics';
import AppModal from '../../../common/components/CustomModal/AppModal';
import {Box} from '../../../components/core/view';
import {EventType} from '@buncha/config/analytics/type';
import {H5Text} from '../../../components/core/text';
import {PTextInput} from '../../../components/core/input/TextInput';
import {checkPriceDecimals} from '../../../utils/common';
import {styles} from './styles';
import {useSubmitStartingBagCount} from '../../../AboutShift/hooks/BagTracking';

interface BagTrackingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  runId: number;
}

function BagTrackingModal(props: BagTrackingModalProps) {
  const {isOpen, onClose, onSubmit, runId} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const [bagCount, setBagCount] = useState<number>(0);
  const [submitStartingBagCount] = useSubmitStartingBagCount();

  const getBorderColor = useMemo(() => {
    if (!bagCount) return theme?.text?.gray?.[200];
    return theme?.text?.blue?.[300];
  }, [bagCount, theme?.text?.blue, theme?.text?.gray]);

  const handleChange = useCallback((count: string) => {
    if (checkPriceDecimals(count) && Number(count) <= 200)
      setBagCount(Number(count));
  }, []);

  const submitCount = useCallback(() => {
    submitStartingBagCount(runId, bagCount);
    onSubmit();
  }, [bagCount, onSubmit, runId, submitStartingBagCount]);

  useEffect(() => {
    Analytics.eventWithProps('Bag Tracking Modal', {
      type: EventType.modal,
    });
  }, []);

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      topCTAHandelr={submitCount}
      title={'Starting Bag Number'}
      hideBottomCta
      modalBody={
        <Box>
          <H5Text
            style={{
              fontFamily: muliFont[700].normal,
              color: theme?.text.gray[100],
            }}>
            Enter the # of bags you’re starting with
          </H5Text>
          <PTextInput
            value={bagCount ? `${bagCount}` : ''}
            placeholder="0"
            textAlignVertical={'top'}
            clearInput
            debounce={false}
            returnKeyType="done"
            style={[styles.inputBox, {color: theme?.text?.blue?.[300]}]}
            onChangeText={handleChange}
            containerStyle={[
              styles.inputContainer,
              {
                borderColor: getBorderColor,
              },
            ]}
            keyboardType="number-pad"
          />
        </Box>
      }
      ctaTopTitle={'Add'}
      disableCtaTop={!bagCount}
      topCTAColor={theme?.background.green[100]}
    />
  );
}

export default React.memo(BagTrackingModal);
