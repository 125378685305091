import {DownTime, ModuleName} from '../types/downtime';

import {Analytics} from '@buncha/config/analytics/analytics';
import {Config} from '@buncha/utils/types';
import {getConfig} from '@buncha/utils/appInfo';
import {onBoardingActions} from '@buncha/onBoarding/reducer';
import {useAppDispatch} from '@buncha/config/redux/hooks';
import {useState} from 'react';

export function useFetchDownTimeInfo() {
  const [downTime, setDownTime] = useState(false);
  const [loading, setLoading] = useState(true);
  const appDispatch = useAppDispatch();
  const lambdaMaintenanceUrl = getConfig(Config.awsLambdaMaintenanceUrl);
  const fetchDownTimeInfo = async () => {
    return await fetch(lambdaMaintenanceUrl)
      .then(async res => {
        const parsedData: {downTime: DownTime} = await res.json();
        const dTime = parsedData?.downTime?.shopperApp ?? false;
        if (dTime) {
          setDownTime(dTime);
          appDispatch(onBoardingActions?.setDownTime(dTime));
        }
        Analytics.eventWithProps('AwsMaintenenace', {
          awsMaintenenace: dTime,
          module: ModuleName.shopperApp,
        });
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        // eslint-disable-next-line no-console
        console.log('error', err);
      });
  };
  return {downTime, fetchDownTimeInfo, loading};
}
