import React, {useCallback, useEffect} from 'react';
import {RefreshControl, StyleSheet} from 'react-native';

import {Analytics} from '../../../../../../src/config/analytics/analytics';
import {CustomFlatList} from '../../../../../../src/components/core/list';
import {DeliveryLocationStatus} from '@buncha/driver/types/common';
import DriverOrderCell from '../../driverOrderCell';
import LoaderComponents from '@buncha/components/composites/CustomLoader/LoaderComponents';
import NoOrderForDriver from '../../noOrderForDriver';
import {OrdersForDelivery} from '../../../../../../src/driver/types/driverOrders';
import {isWeb} from '../../../../../../src/utils/common';
import {useColorThemeType} from '../../../../../../src/appStyles/hooks/theme';
import {useGetLocation} from '@buncha/utils/commonWithoutWeb';
import {useGetOrdersForDelivery} from '../../../../../../src/driver/hooks/driverOrders';
import {useIsFocused} from '@react-navigation/native';

interface Props {
  runId: number;
}

const AllOrders = (props: Props) => {
  const theme = useColorThemeType('main');
  const isFocused = useIsFocused();
  const {coordinates, getLocationWithPermission} = useGetLocation();
  const {getOrdersForDelivery, loading, ordersData} = useGetOrdersForDelivery();
  const renderItem = useCallback((order: {item: OrdersForDelivery}) => {
    const assignedOrder = order?.item;
    return <DriverOrderCell order={assignedOrder} isFirstItem={true} />;
  }, []);

  useEffect(() => {
    getLocationWithPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((isFocused && coordinates) || isWeb())
      getOrdersForDelivery(coordinates?.lat, coordinates?.lon, props.runId, [
        DeliveryLocationStatus.delivered,
      ]);
    Analytics.eventWithProps('All orders for driver', {
      userType: 'driver',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, coordinates]);
  if (loading) return <LoaderComponents showBottomText={false} />;
  return (
    <CustomFlatList
      ListEmptyComponent={<NoOrderForDriver />}
      contentContainerStyle={styles.content}
      refreshControl={
        <RefreshControl
          refreshing={loading}
          onRefresh={async () =>
            await getOrdersForDelivery(
              coordinates?.lat,
              coordinates?.lon,
              props.runId,
              [DeliveryLocationStatus.delivered],
            )
          }
          tintColor={theme?.background.gray[100]}
          style={styles.refreshControl}
        />
      }
      renderItem={renderItem}
      data={ordersData}
      showsVerticalScrollIndicator={false}
    />
  );
};

export default React.memo(AllOrders);

const styles = StyleSheet.create({
  refreshControl: {zIndex: 100},
  spinnerContainer: {
    justifyContent: 'center',
    flex: 1,
  },
  content: {
    flexGrow: 1,
  },
});
