import {
  AddDeliveryNote,
  OrderForDeliveryDetails,
  UploadDeliveryLocationImage,
} from '../../../src/driver/types/deliveryDetails';
import {
  RecommendedItemCell,
  ReplacementItemDetails,
  SearchCatalog,
} from '../../../src/itemDetails/types/itemReplacement';

import {BuyersOnMap} from '../../../src/driver/types/mapOverview';
import {GetAccountSetupInfo} from '@buncha/hiring/types/onboardingPhases';
import {OrderForOrderDetails} from '../../../src/driver/types/orderDetails';
import {OrdersForDelivery} from '../../../src/driver/types/driverOrders';
import {ShiftByDate} from '../../../src/openShifts/types/openShifts';

export interface WhenIWorkLoginType {
  isLogin: boolean;
  refreshToken: string;
  inf: {
    email: string;
    password: string;
  };
}

export interface WhenIWorkApiResponse {
  data: {
    user: {
      email?: string;
      first_name?: string;
      last_name?: string;
      phone_number?: string;
      positions?: number[];
    };
  };
}

export interface WhenIWorkData {
  login: WhenIWorkLoginType;
  apiResponse: WhenIWorkApiResponse;
}

export interface GetAvailableShiftsResponse {
  getAvailableShifts: {
    days: ShiftByDate[];
  };
}

export interface TakeShiftResponse {
  takeShift: {
    availableShift: {
      days: ShiftByDate[];
    };
  };
}
// eslint-disable-next-line no-shadow
export enum NetworkConfiguration {
  developement1 = 'developement1',
  developement2 = 'developement2',
  developement3 = 'developement3',
  staging = 'staging',
  qa = 'qa',
  qanew = 'qanew',
  preproduction = 'preproduction',
  production = 'production',
}
export interface AddNotesForOrderItemResponse {
  addNoteForOrderItem: {
    note: string;
  };
}

export interface RecommendItemsForSubstitutesResponse {
  recommendItemsForSubstitutes: {
    items: RecommendedItemCell[];
  };
}

export interface GetOrderItemResponse {
  getOrderItemById: ReplacementItemDetails;
}

export interface SearchCatalogResponse {
  searchCatalog: SearchCatalog;
}

export interface GetOrdersForDeliveryResponse {
  getOrdersForDelivery: OrdersForDelivery[];
}

export interface GetOrderForOrderDetailsResponse {
  getOrder: OrderForOrderDetails;
}

export interface GetOrderForDeliveryDetailsResponse {
  getOrder: OrderForDeliveryDetails;
}
export interface UploadDeliveryLocationImageResponse {
  uploadDeliveryImage: UploadDeliveryLocationImage;
}

export interface AddDeliveryNoteResponse {
  addNoteForDeliveryLocation: AddDeliveryNote;
}

export interface GetBuyersOnMapResponse {
  getOrdersForDelivery: BuyersOnMap[];
}

export interface GetAccountSetupInfoResponse {
  getAccountSetupInfo: GetAccountSetupInfo;
}

// eslint-disable-next-line no-shadow
export enum SessionIdOperation {
  createNew = 'createNewSessionId',
  clearSessionId = 'createSessionId',
}
