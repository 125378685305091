import React, {useEffect, useState} from 'react';

import {Analytics} from '@buncha/config/analytics/analytics';
import {Buffer} from 'buffer';
import CustomScrollView from '../../components/composites/CustomScrollView/CustomScrollView';
import {EventType} from '@buncha/config/analytics/type';
import Header from '../../components/composites/Header/Header';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {StyleSheet} from 'react-native';
import {useColorThemeType} from '../../appStyles/hooks/theme';
import {useGetPDFReceipt} from '../hooks/PdfDetails';

type Props = NativeStackScreenProps<any, any>;

function PdfDetailPage(props: Props) {
  const theme = useColorThemeType();
  const params = props.route.params;
  const runId = params?.runId ?? '';

  const [pdfUrl, setpdfUrl] = useState('');

  const {getPDF, apiState} = useGetPDFReceipt();

  useEffect(() => {
    Analytics.eventWithProps('PDF Screen', {
      type: EventType.page,
      runId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (runId) getPDF(runId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId]);

  useEffect(() => {
    if (!apiState.loading && apiState?.data) setpdfUrl(apiState?.data);
  }, [apiState?.data, apiState.loading]);

  const decodedData = Buffer.from(pdfUrl, 'base64').toString();

  return (
    <Header
      showDefaultLeftHeader
      hideBottomSafeArea
      loading={apiState?.loading}
      headerColor={theme?.background.blue[300]}>
      <CustomScrollView style={styles.scrollView}>
        <div dangerouslySetInnerHTML={{__html: decodedData}} />
      </CustomScrollView>
    </Header>
  );
}

export default React.memo(PdfDetailPage);

export const styles = StyleSheet.create({
  scrollView: {padding: 10},
});
