import {Box, HBox} from '../../../../components/core/view';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  Divider,
} from 'native-base';
import {CustomText, H4Text, H6Text} from '../../../../components/core/text';
import {
  ItemScannerAction,
  useItemScannerContext,
} from '@buncha/itemDetails/hooks/ItemScanner';
import {OrderItem, UpdatePage} from '../../../../itemDetails/types/ItemScanner';
import React, {useCallback, useEffect, useMemo} from 'react';
import {
  useColorThemeType,
  useFontTheme,
} from '../../../../appStyles/hooks/theme';

import {Accordian} from '@buncha/components/composites';
import {CustomImage} from '../../../../components/core/Image';
import If from '@buncha/components/conditional/If';
import {Info} from '../../../../components/core/icon';
import {SpinnerComponent} from '@buncha/components/core/loader';
import StepText from '../StepText';
import {Touchable} from '../../../../components/core/touchable';
import {UpcStatus} from '@buncha/batchDetails/types';
import UpdateSection from '../updateSection';
import VerifyUPCPill from '../../ItemDetail/VerifyUPCPill';
import font from '../../../../appStyles/style/font';
import {formatCurrency} from '../../../../utils/common';
import {styles} from './style';
import {useRoute} from '@react-navigation/native';

interface ItemDetailsProps {
  title: string;
  item?: OrderItem;
  scanned?: boolean;
  openImagesBottomSheet?: Function;
  showCaptureModeFunc?: () => Promise<void>;
  imageUploading?: boolean;
  isUPCMode?: boolean;
}

const ItemDetails = (props: ItemDetailsProps) => {
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const {item, scanned, openImagesBottomSheet} = props;
  const {state, dispatch} = useItemScannerContext();
  const name = item?.name;
  const price =
    item?.shopperItemChangesForShopper?.updatedPricePerQuantity || item?.price;
  const itemImageUrl = item?.itemImageUrl || '';

  const scannerExpanded = state.scannerExpanded;
  const buyerData = state.buyerData;
  const allFields = buyerData?.[0]?.whatPageToShow === UpdatePage.carousel;

  const imageSaved = item?.upcStatus === UpcStatus.imageSaved;

  const expandedColor = scannerExpanded
    ? theme?.text.blue[300]
    : theme?.text.gray[100];

  const unexpandedColor = !scannerExpanded
    ? theme?.text.blue[300]
    : theme?.text.gray[100];

  const justifyContent = scannerExpanded ? 'space-between' : 'flex-start';

  const routeParams: any = useRoute().params;
  const quantity = routeParams?.quantity;

  const singleQuantiy = state?.buyerData?.[0]?.isItemPickedOnScan;

  const toggleAccordian = useCallback(() => {
    if ((!scanned && item?.catalogItemId) || !dispatch) return;
    if (!item?.catalogItemId)
      dispatch(ItemScannerAction.setScannerExpanded(false));
    else dispatch(ItemScannerAction.setScannerExpanded(!scannerExpanded));
  }, [dispatch, item?.catalogItemId, scanned, scannerExpanded]);

  const handleImageOnPress = useCallback(() => {
    if (!props?.isUPCMode && !state.scannerExpanded && item?.catalogItemId)
      return;
    if (
      (item?.catalogItemId && openImagesBottomSheet) ||
      (props?.isUPCMode && openImagesBottomSheet)
    )
      openImagesBottomSheet();
    else if (props?.showCaptureModeFunc) props.showCaptureModeFunc();
  }, [
    item?.catalogItemId,
    openImagesBottomSheet,
    props,
    state.scannerExpanded,
  ]);

  const step2Text = useMemo(() => {
    if (!scannerExpanded) return 'Item weight(s)';
    if (!allFields) return 'Update Quantity';
    return 'Update Price & Quantity';
  }, [allFields, scannerExpanded]);

  useEffect(() => {
    if (dispatch && ((scanned && !singleQuantiy) || !item?.catalogItemId))
      dispatch(ItemScannerAction.setScannerExpanded(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.catalogItemId]);

  const getText = useMemo(() => {
    if (props?.isUPCMode) return 'Step 2 - Help other Shoppers find this item';
    else if (item?.catalogItemId) return `Step 2 - ${step2Text}`;
    return step2Text;
  }, [item?.catalogItemId, props?.isUPCMode, step2Text]);

  return (
    <Box
      style={[
        styles.containerStyles,
        {
          justifyContent,
        },
      ]}>
      <Box
        style={{
          backgroundColor: theme?.background.white[100],
        }}>
        <HBox style={[styles.scanHeader]}>
          <H4Text
            style={[
              styles.scanText,
              {
                color: theme?.text.blue[300],
                fontFamily: muliFont[700].normal,
              },
            ]}
          />
        </HBox>
        <HBox style={styles.detailsContainer}>
          <If condition={!props.imageUploading}>
            <Box style={styles.imageBox}>
              <CustomImage
                style={styles.image}
                source={itemImageUrl}
                resizeMode="cover"
                onErrorSource={''}
              />
              <HBox
                style={[
                  styles.highlightText,
                  {
                    backgroundColor: theme?.background.yellow[100],
                  },
                ]}>
                <CustomText
                  style={{
                    fontFamily: muliFont[700].normal,
                    color: theme?.text.blue[300],
                  }}>{`Qty: ${quantity || 1} `}</CustomText>
              </HBox>
            </Box>
          </If>
          <If condition={props.imageUploading}>
            <SpinnerComponent size={'sm'} height={75} width={75} />
          </If>
          <Box style={styles.textContainer}>
            <H6Text
              style={[
                styles.itemNameText,
                {
                  fontFamily: muliFont[700].normal,
                  color: theme?.text.blue[300],
                },
              ]}>
              {name}
            </H6Text>
            <H6Text
              style={{
                fontFamily: muliFont[700].normal,
                color: theme?.text.blue[300],
              }}>
              {formatCurrency(price || 0)}
            </H6Text>
          </Box>
        </HBox>
        <HBox style={styles.unableToScanBox}>
          <Touchable style={styles.flexBox} onPress={handleImageOnPress}>
            <CustomText
              style={[
                styles.unableToScanText,
                {
                  color: theme?.text.red[100],
                  fontFamily: font.fonts.muliBold,
                },
              ]}
              numberOfLines={2}>
              <If condition={item?.catalogItemId}>Unable to scan barcode</If>
              <If condition={!item?.catalogItemId}>
                <If condition={item?.itemImageUrl}>Update Photo</If>
                <If condition={!item?.itemImageUrl}>Take Item Photo</If>
              </If>
            </CustomText>
          </Touchable>
          <If condition={props?.isUPCMode}>
            <VerifyUPCPill />
          </If>
          <If condition={!props?.isUPCMode}>
            <HBox
              style={[
                styles.infoBox,
                {
                  backgroundColor: theme?.background.yellow[300],
                },
              ]}>
              <Info
                height={16}
                width={16}
                viewBox="0 0 20 20"
                fill={theme?.icon.blue[300]}
              />
              <H6Text
                style={[
                  styles.infoText,
                  {
                    color: theme?.text.blue[300],
                    fontFamily: muliFont[700].normal,
                  },
                ]}>{`Pick Qty: ${quantity || 0}`}</H6Text>
            </HBox>
          </If>
        </HBox>
        <If condition={item?.catalogItemId}>
          <Accordian
            disabled={
              !scanned ||
              state?.upcCrowdSourceProcessCompleted ||
              (singleQuantiy && !props.isUPCMode)
            }
            style={styles.accordian}
            onPress={toggleAccordian}
            header={
              <StepText
                imageSaved={imageSaved}
                scanned={Boolean(scanned)}
                active={scannerExpanded}
                text={`${singleQuantiy ? '' : 'Step 1 - '}Scan Barcode`}
              />
            }
            isExpanded={scannerExpanded || singleQuantiy}
            children={<Box />}
            iconDisable={
              !props.isUPCMode && <ChevronDownIcon color={expandedColor} />
            }
            iconEnable={
              !props.isUPCMode &&
              item?.catalogItemId &&
              !singleQuantiy && <ChevronUpIcon color={expandedColor} />
            }
          />
        </If>
      </Box>
      <If condition={!scannerExpanded}>
        <Divider />
      </If>
      <If condition={!singleQuantiy}>
        <Box
          style={[
            styles.bottomCta,
            {
              backgroundColor: theme?.background.white[100],
              borderTopColor: theme?.text.gray[200],
            },
          ]}>
          <Accordian
            disabled={!scanned || state?.upcCrowdSourceProcessCompleted}
            style={styles.accordian}
            onPress={toggleAccordian}
            header={
              <StepText
                scanned={false}
                active={!scannerExpanded}
                imageSaved={imageSaved}
                text={getText}
              />
            }
            isExpanded={!scannerExpanded}
            children={<UpdateSection isUPCMode={props?.isUPCMode || false} />}
            iconEnable={
              !props.isUPCMode && <ChevronUpIcon color={unexpandedColor} />
            }
            iconDisable={
              !props.isUPCMode && <ChevronRightIcon color={unexpandedColor} />
            }
          />
        </Box>
      </If>
    </Box>
  );
};

export default React.memo(ItemDetails);
