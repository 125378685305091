import {HTouchable, Touchable} from '@buncha/components/core/touchable';
import {useColorThemeType, useFontTheme} from '@buncha/appStyles/hooks/theme';

import BorderCrossIcon from '@buncha/components/core/icon/BorderCrossIcon';
import {Box} from '@buncha/components/core/view';
import {H6Text} from '@buncha/components/core/text';
import HandPointDown from '@buncha/components/core/icon/HandPointDown';
import React from 'react';
import {scaleFonts} from '@buncha/appStyles/style/font';
import {styles} from './styles';

interface ViewReplacementBannerProps {
  onPress: () => void;
  toggle: () => void;
}

const ViewReplacementBanner: React.FC<ViewReplacementBannerProps> = props => {
  const {onPress, toggle} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  return (
    <Box style={styles.container}>
      <HTouchable
        style={[
          styles.mainContainer,
          {backgroundColor: theme?.background.blue[300]},
        ]}
        onPress={onPress}>
        <HandPointDown
          width={scaleFonts(18)}
          height={scaleFonts(18)}
          viewBox="0 -1 16 16"
          fill={theme?.background.white[100]}
        />
        <H6Text
          style={[
            {
              color: theme?.text.white[100],
              fontFamily: muliFont[700].normal,
            },
          ]}>
          View replacements added
        </H6Text>
        <Touchable
          style={{backgroundColor: theme?.background.blue[300]}}
          onPress={toggle}>
          <BorderCrossIcon
            width={scaleFonts(18)}
            height={scaleFonts(18)}
            viewBox="0 -1 16 16"
            fill={theme?.background.white[100]}
          />
        </Touchable>
      </HTouchable>
    </Box>
  );
};

export default React.memo(ViewReplacementBanner);
