import {Dimensions, StyleSheet} from 'react-native';

const {width, height} = Dimensions.get('screen');

const BuyerOrderListStyle = StyleSheet.create({
  skeletonContainer: {
    marginHorizontal: 20,
  },
  divider: {paddingHorizontal: 20},
});

const LoadSequenceStyle = StyleSheet.create({
  imageMainContainer: {
    flex: 1,
    height: 'auto',
    width: '100%',
  },
  imageBox: {width: '100%', paddingVertical: 15},
  imageContainer: {
    height: height * 0.2,
    width: '100%',
    alignSelf: 'center',
  },
  title: {
    textAlign: 'left',
    fontSize: 18,
  },
  subTitle: {
    textAlign: 'left',
    fontSize: 14,
  },
  topBox: {
    gap: 10,
    marginBottom: 10,
  },
  bottomBox: {
    gap: 10,
    marginTop: 10,
  },
  parentBox: {
    alignItems: 'flex-start',
    padding: 10,
    width: '100%',
  },
});

const BuyerInfoStyle = StyleSheet.create({
  mainContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: 1,
  },
  nameStyle: {
    marginTop: -5,
    marginBottom: 4,
    textAlign: 'left',
  },
  addressStyle: {
    textAlign: 'left',
    marginBottom: 4,
  },
  shopperText: {
    textAlign: 'left',
  },
});

const BuyerOrderListCellStyle = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingHorizontal: 20,
    marginVertical: 10,
  },
});

const CheckBoxCountStyle = StyleSheet.create({
  container: {
    borderWidth: 1,
    width: 20,
    height: 20,
    borderRadius: 4,
    marginRight: 10,
  },
});

const ListHeaderStyle = StyleSheet.create({
  container: {
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  headerText: {
    textAlign: 'left',
    paddingRight: 8,
  },
  sequenceBox: {
    marginVertical: 10,
  },
  divider: {
    width: width,
    flex: 1,
    marginLeft: -20,
  },
  headerBox: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

export {
  BuyerOrderListStyle,
  LoadSequenceStyle,
  BuyerInfoStyle,
  BuyerOrderListCellStyle,
  CheckBoxCountStyle,
  ListHeaderStyle,
};
