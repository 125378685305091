import {Dimensions, ScrollView, StyleSheet} from 'react-native';
import React, {useCallback, useEffect} from 'react';

import {Analytics} from '@buncha/config/analytics/analytics';
import {Box} from '../../../src/components/core/view';
import DeliveryButtonComponent from '../components/orderDetails/deliveryButtonComponent';
import {EventType} from '@buncha/config/analytics/type';
import Header from '../../../src/components/composites/Header/Header';
import If from '../../../src/components/conditional/If';
import MapComponent from '../components/orderDetails/mapComponent';
import NameAndAddressDetails from '../components/common/nameAndAddressDetails';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import OrderInfo from '../components/orderDetails/orderInfo';
import ScreenHeader from '../../common/components/screenHeader';
import {SpinnerComponent} from '../../../src/components/core/loader';
import listener from '../../../src/config/listener';
import {useColorThemeType} from '../../../src/appStyles/hooks/theme';
import {useGetOrdersDetails} from '../hooks/orderDetails';

type Props = NativeStackScreenProps<any, any>;

const OrderDetails: React.FC<Props> = props => {
  const orderId = String(props?.route?.params?.orderId) || '';
  const runId = Number(props?.route?.params?.runId) || '';
  const theme = useColorThemeType('main');
  const {getOrderDetails, loading, orderData} = useGetOrdersDetails();
  const buyerInfo = orderData?.buyer;
  const buyerAddress = orderData?.deliveryLocation?.userAddress;
  const deliveryInstruction =
    orderData?.deliveryInstruction || buyerAddress?.deliveryInstruction || '';
  const deliveryLocation = orderData?.deliveryLocation;
  const deliveryNote = deliveryLocation?.deliveryNote;
  const runInfo = orderData?.run;
  const orderItems = orderData?.itemsForDriver || [];

  const getOrderInfo = useCallback(() => {
    if (orderId) getOrderDetails(orderId, runId || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    Analytics.eventWithProps('Order Details', {
      type: EventType.page,
      orderId: orderId,
      userType: 'driver',
    });
    const unsubscribe = listener.addListener('onTheWay', getOrderInfo);
    getOrderInfo();
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Header
      loading={loading}
      headerColor={theme?.background?.blue?.[300]}
      showDefaultLeftHeader>
      <ScreenHeader title="About This Order" />
      <If condition={loading}>
        <SpinnerComponent containerStyle={styles.spinnerContainer} />
      </If>
      <If condition={!loading}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <MapComponent buyerAddress={buyerAddress} />
          <Box style={styles.parentBox}>
            <NameAndAddressDetails
              buyerAddress={buyerAddress}
              deliveryInstructions={deliveryInstruction}
              deliveryNote={deliveryNote}
              buyerInfo={buyerInfo}
              forOrderDetails={true}
              orderId={Number(orderId)}
              runId={runInfo?.id}
            />
            <OrderInfo runInfo={runInfo} orderItems={orderItems} />
          </Box>
        </ScrollView>
        <DeliveryButtonComponent
          deliveryLocation={deliveryLocation}
          orderId={orderId}
        />
      </If>
    </Header>
  );
};

export default OrderDetails;

const styles = StyleSheet.create({
  parentBox: {
    paddingHorizontal: 20,
  },
  webHeader: {
    padding: 30,
  },
  spinnerContainer: {
    justifyContent: 'center',
    height: Dimensions?.get('window').height * 0.8,
  },
});
