import React from 'react';
import {StyleSheet} from 'react-native';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';
import {H6Text} from '../../../components/core/text';
import {Box} from '../../../components/core/view';

interface PillProps {
  text: string;
}

const LightGreyPill = (props: PillProps) => {
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  return (
    <Box
      style={[
        styles.runTextBox,
        {
          backgroundColor: theme?.background.gray[200],
        },
      ]}>
      <H6Text
        style={{
          color: theme?.text.gray[100],
          fontFamily: muliFont[700].normal,
        }}>
        {props.text}
      </H6Text>
    </Box>
  );
};

const styles = StyleSheet.create({
  runTextBox: {
    borderRadius: 20,
    paddingHorizontal: 15,
    paddingVertical: 3,
  },
});

export default React.memo(LightGreyPill);
