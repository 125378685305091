import {MostRecentAddress} from '../../../../../src/driver/types/common';
import React from 'react';

interface MapComponentProps {
  buyerAddress: MostRecentAddress | undefined;
}

const MapComponent: React.FC<MapComponentProps> = () => {
  return <></>;
};

export default React.memo(MapComponent);
