import * as React from 'react';

import Icon from './Icon';
import {IconProps} from './type';
import {G, Path} from 'react-native-svg';

const MemosIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <G clip-path="url(#clip0_135_3128)">
        <Path
          d="M15.2969 21.2584H6.79685C5.60023 21.2584 4.63019 20.3224 4.63019 19.1679V5.98169C4.63019 4.82713 5.60023 3.89119 6.79685 3.89119H8.63019V3.56958C8.63019 1.97095 9.97335 0.675049 11.6302 0.675049C13.287 0.675049 14.6302 1.97099 14.6302 3.56958V3.89119H19.7969C20.9935 3.89119 21.9635 4.82713 21.9635 5.98169V14.8261H21.9512C21.8987 15.2987 21.6805 15.7424 21.3289 16.0816L16.5981 20.6461C16.2466 20.9853 15.7866 21.1959 15.2969 21.2465V21.2584ZM8.63019 5.17765H6.79685C6.33556 5.17866 5.96352 5.53826 5.96352 5.98169V19.1679C5.96352 19.612 6.3366 19.9719 6.79685 19.9719H13.9635V16.1125C13.9635 14.6916 15.1574 13.5396 16.6302 13.5396H20.6302V5.98169C20.6302 5.53826 20.2581 5.17866 19.7969 5.17765H9.96352V11.2883C9.96352 11.8212 10.4112 12.2532 10.9635 12.2532C11.5158 12.2532 11.9635 11.8212 11.9635 11.2883V6.46411H13.2969V11.2883C13.2969 12.5317 12.2522 13.5396 10.9635 13.5396C9.67485 13.5396 8.63019 12.5317 8.63019 11.2883V5.17765ZM15.2969 19.9405C15.4311 19.9031 15.5547 19.8335 15.6553 19.7364L20.3861 15.1719C20.4867 15.0749 20.5589 14.9556 20.5976 14.8261H16.6302C15.8938 14.8261 15.2969 15.4021 15.2969 16.1125V19.9405ZM13.2969 3.89119V3.56958C13.2969 2.68148 12.5506 1.96151 11.6302 1.96151C10.7097 1.96151 9.96352 2.68148 9.96352 3.56958V3.89119H13.2969Z"
          fill={props.fill}
          stroke={props.fill}
          stroke-width="0.5"
        />
      </G>
    </Icon>
  );
};

export default MemosIcon;
