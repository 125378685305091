import {DefaultContext} from '../../utils/types';
import React from 'react';
import {State} from '../types/itemDetails';

export const initialState: State = {
  item: undefined,
  buyersForItem: [],
  reasonsForDropping: [],
  itemNotes: '',
  itemLocationInformation: undefined,
  constants: undefined,
  hasAnyBuyerInstructions: false,
  orderItemIds: [],
};

const defaultVal: DefaultContext<State> = {
  state: initialState,
  dispatch: null,
};

export const ItemDetailsContext = React.createContext(defaultVal);
