import {gql} from '@apollo/client';

export const UserAddress = gql`
  fragment UserAddress on UserAddress {
    id
    address
    name
    placeId
    lon
    lat
    isInsideZone
    dropPoint
    deliveryInstruction
    neighborhood {
      name
    }
  }
`;

export const User = gql`
  fragment User on User {
    id
    firstName
    lastName
    avatar
    isDriver
    email
    isNewUser
    phoneNumber
    isGuestUser
    hasAddress
    isActive
  }
`;

export const WhenIWorkLogin = gql`
  fragment WhenIWorkLogin on WhenIWorkLogin {
    login {
      isLogin
      refreshToken
    }
    apiResponse {
      data {
        token
      }
    }
  }
`;

export const FullUser = gql`
  fragment FullUser on User {
    ...User
    isLockedOut
    userTypes
    createdAt
    mostRecentAddress {
      ...UserAddress
    }
    chatUser {
      id
      name
      email
      phone
      photoUrl
      role
    }
    WhenIWorkInfo {
      login {
        isLogin
        refreshToken
        info {
          email
          password
        }
      }
    }
    mapChoice
    completedOrderCount
    fullfillmentHiringApplicationState {
      id
      status
    }
    tutorials {
      id
      name
    }
  }
  ${User}
  ${UserAddress}
`;

export const StoreFragment = gql`
  fragment StoreFragment on Store {
    id
    storeLogoUrl
    name
    address
    elasticSearchIndex
    isMerchant
    storeMaster {
      id
    }
    getLatLongFromAddress {
      lat
      lng
    }
  }
`;

export const ShiftsFragment = gql`
  fragment ShiftsFragment on Shift {
    time
    shiftId
    endTime
    startTime
    position
    date
    store {
      ...StoreFragment
    }
  }
  ${StoreFragment}
`;

export const ShiftByStoreFragment = gql`
  fragment ShiftByStoreFragment on ShiftByStore {
    storeMasterId
    activeShiftIndex
    shiftIds
    batches
  }
`;

export const ShiftsListFragment = gql`
  fragment ShiftsListFragment on AssignedShift {
    id
    key
    shifts {
      ...ShiftsFragment
    }
    shiftByStore {
      ...ShiftByStoreFragment
    }
  }
  ${ShiftsFragment}
  ${ShiftByStoreFragment}
`;

export const CatalogItemForShopper = gql`
  fragment catalogItem on catalogItem {
    name
    id
    itemQuantity
    price
    quantity
    itemImageUrls {
      largeImages
    }
    category
  }
`;

export const TaskInfoFragment = gql`
  fragment TaskInfoFragment on TaskInfo {
    orderCount
    itemCount
  }
`;

export const BatchTaskFragment = gql`
  fragment BatchTaskFragment on BatchTask {
    shopperTask {
      ...TaskInfoFragment
    }
    driverTask {
      ...TaskInfoFragment
    }
  }
  ${TaskInfoFragment}
`;

export const RunFragment = gql`
  fragment RunFragment on Run {
    id
    dropOffPoint
    locationAddress
    dropOffStartTime
    store {
      ...StoreFragment
    }
    isMaster
    runType
    masterRun {
      id
      dropOffPoint
      locationAddress
      dropOffStartTime
      store {
        ...StoreFragment
      }
    }
  }
  ${StoreFragment}
`;

export const ShopperSessionFragment = gql`
  fragment ShopperSessionFragment on ShopperSession {
    id
    shiftId
    syncStatus
    appStatus
    sessionStatus
    lastSyncTime
    syncFrequency
  }
`;

export const BatchInfoFragment = gql`
  fragment BatchInfoFragment on BatchInfo {
    run {
      ...RunFragment
    }
    shift {
      ...ShiftsFragment
    }
    task {
      ...BatchTaskFragment
    }
    shopperSession {
      ...ShopperSessionFragment
    }
    state
    activeShiftIndex
    ctaName
  }
  ${RunFragment}
  ${ShiftsFragment}
  ${BatchTaskFragment}
  ${ShopperSessionFragment}
`;

export const CatalogItem = gql`
  fragment CatalogItem on catalogItem {
    id
    name
    itemImageUrl
    price
  }
`;

export const SubstitutionRequest = gql`
  fragment SubstitutionRequest on SubstitutionRequest {
    id
    createdAt
    quantity
    catalogItem {
      ...CatalogItem
    }
  }
  ${CatalogItem}
`;

export const OrderItems = gql`
  fragment OrderItems on OrderItem {
    id
    name
    price
    quantity
    note
    orderId
    itemImageUrl
    isCustomItem
    totalQuantityInThisRun
    itemStatus
    position
    substitutedBy
    catalogItemId
    substitutionRequest {
      ...SubstitutionRequest
    }
    itemLocationInformation {
      aisle
      storeContext
    }
    shopperItemChangesForShopper {
      updatedQuantity
      weightInfo {
        id
        unit
        weight
      }
      updatedPricePerQuantity
    }
    orderId
    upcStatus
    order {
      buyer {
        firstName
        phoneNumber
      }
      id
      run {
        id
        store {
          id
          elasticSearchIndex
        }
      }
    }
    options
    tags
  }
  ${SubstitutionRequest}
`;

export const RunDetail = gql`
  fragment RunDetail on Run {
    id
    receiptImageUrls
    locationImageUrl
    locationAddress
    storeName
    store {
      ...StoreFragment
    }
    ordersForShopper {
      id
      buyer {
        id
      }
      deliveryLocation {
        id
        deliveryStatus
      }
    }
    isMaster
    masterRun {
      id
    }
    runType
  }
  ${StoreFragment}
`;

export const MostRecentAddress = gql`
  fragment MostRecentAddress on UserAddress {
    address
    name
    lon
    lat
    deliveryInstruction
    dropPoint
  }
`;

export const Buyer = gql`
  fragment Buyer on UserStub {
    id
    firstName
    lastName
    phoneNumber
    mostRecentAddress {
      ...MostRecentAddress
    }
    isVip
  }
  ${MostRecentAddress}
`;

export const DeliveryNote = gql`
  fragment DeliveryNote on DeliveryNote {
    building
    address
    parking
    entrance
    access
    pikupPoint
  }
`;

export const DeliveryLocationForOrderDetails = gql`
  fragment DeliveryLocationForOrderDetails on DeliveryLocation {
    id
    runId
    deliveryStatus
    userAddress {
      ...MostRecentAddress
    }
    deliveryNote {
      ...DeliveryNote
    }
  }
  ${MostRecentAddress}
  ${DeliveryNote}
`;

export const DeliveryLocationForDeliveryDetails = gql`
  fragment DeliveryLocationForDeliveryDetails on DeliveryLocation {
    id
    runId
    deliveryStatus
    signature
    idProof
    userAddress {
      ...MostRecentAddress
    }
    deliveryNote {
      ...DeliveryNote
    }
    deliveryImages
    note
  }
  ${MostRecentAddress}
  ${DeliveryNote}
`;

export const DriverItem = gql`
  fragment DriverItem on OrderItem {
    id
    name
    quantity
    price
    itemImageUrl
    isCustomItem
    shopperItemChangesForShopper {
      updatedQuantity
      weightInfo {
        id
        unit
        weight
      }
      updatedPricePerQuantity
    }
  }
`;

export const RunForDriver = gql`
  fragment RunForDriver on Run {
    id
    lat
    lon
    dropOffStartTime
    dropOffEndTime
    deliveryDateData {
      arrivalDate
    }
    store {
      elasticSearchIndex
    }
  }
`;

export const Store = gql`
  fragment Store on Store {
    isScraped
    address
    id
    storeLogoUrl
    isMerchant
    storeMaster {
      deliveryType
    }
  }
`;

export const PastOrdersRun = gql`
  fragment PastOrdersRun on Run {
    id
    deliveryDate
    dropOffStartTime
    dropOffEndTime
    storeName
    deliveryDateData {
      arrivalDate
    }
    uncancelledOrdersCount
    uncancelledOrdersItemCount
    getTotalItemCount
    store {
      ...Store
    }
  }
  ${Store}
`;

export const PastOrdersOrder = gql`
  fragment PastOrdersOrder on Order {
    id
    buyer {
      id
      firstName
      lastName
      isVip
    }
    runBudget
    deliveryLocation {
      id
      deliveryStatus
    }
    deliveryInstruction
  }
`;

export const Order = gql`
  fragment Order on Order {
    id
    bagsUsed
    deliveryLocation {
      id
      deliveryStatus
    }
  }
`;

export const PastOrders = gql`
  fragment PastOrders on ShoppedOrders {
    run {
      ...PastOrdersRun
    }
    orders {
      items {
        id
      }
      ...PastOrdersOrder
    }
  }
  ${PastOrdersRun}
  ${PastOrdersOrder}
`;

export const Item = gql`
  fragment Item on OrderItem {
    id
    name
    quantity
    refundQuantity
    price
    itemImageUrl
    isCustomItem
    options
    itemStatus
    substitutedBy
  }
`;

export const PastOrderData = gql`
  fragment PastOrderData on ShoppedOrders {
    run {
      ...PastOrdersRun
    }
    orders {
      items {
        ...Item
      }
      ...PastOrdersOrder
    }
  }
  ${Item}
  ${PastOrdersRun}
  ${PastOrdersOrder}
`;

export const Memo = gql`
  fragment Memo on Memo {
    id
    formatedCreatedAt
    memoConfig {
      content
      property
      props
    }
    createdAt
    createdUser {
      firstName
      lastName
      avatar
    }
  }
`;

export const SearchRelevance = gql`
  fragment SearchRelevance on searchResultRelevanceObject {
    key
    name
    length
  }
`;

export const SeaSpiderOrders = gql`
  fragment SeaSpiderOrders on Order {
    id
    bagsUsed
    buyer {
      id
      firstName
      lastName
    }
    orderAssignee {
      assignee {
        lastName
        firstName
        id
        phoneNumber
      }
      assigneeSecondary {
        id
        firstName
        lastName
        phoneNumber
      }
    }
  }
`;

export const SeaSpiderRun = gql`
  fragment SeaSpiderRun on Run {
    id
    ordersForShopper {
      ...SeaSpiderOrders
    }
    masterRun {
      id
      dropOffStartTime
      store {
        ...StoreFragment
      }
    }
  }
  ${SeaSpiderOrders}
  ${StoreFragment}
`;

export const Participant = gql`
  fragment Participant on ChatParticipant {
    access
    user {
      id
      lastName
      firstName
      phoneNumber
    }
  }
`;
