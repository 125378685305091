import {aboutShiftRouts, homeRouts} from '../home/navigation/linking';

import {itemDetailRouts} from '../itemDetails/navigation/linking';
import {onBoardingRouts} from '../onBoarding/navigation/linking';

const origin = (window && window.location && window.location.origin) || '';
const prefixes = ['buncha-shopper://', 'shopper://', origin];

export const linking = {
  prefixes,
  config: {
    screens: {
      ...onBoardingRouts,
      ...homeRouts,
      ...aboutShiftRouts,
      ...itemDetailRouts,
    },
  },
};
