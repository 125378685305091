import {Dimensions, StyleSheet} from 'react-native';

import {getWidth} from '../../../../../src/utils/common';

export const styles = StyleSheet.create({
  modalBox: {
    width: getWidth(0.9 * Dimensions?.get('window').width),
    borderRadius: 20,
  },
  CTA: {
    borderRadius: 50,
    width: '100%',
    alignSelf: 'center',
    marginBottom: 10,
  },
  CTATop: {
    borderWidth: 0,
  },
  CTABottom: {
    borderWidth: 1,
  },
  buttonBox: {
    marginTop: -20,
    paddingHorizontal: 20,
  },
  bodyText: {
    marginVertical: 10,
  },
  icon: {
    marginRight: 5,
  },
  modalBody: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});
