import {Analytics, AnalyticsPlatform} from '../../config/analytics/analytics';
import React, {useEffect} from 'react';

import {Box} from '../../../src/components/core/view';
import BunchaWithName from '../../../src/components/core/icon/BunchaWithName';
import {MainScreens} from '../../navigation/types';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {OnBoardingScreens} from '../navigation/types';
import {SpinnerComponent} from '../../../src/components/core/loader';
import {StyleSheet} from 'react-native';
import {navigation} from '../../config/navigation';
import {scaleFonts} from '../../../src/appStyles/style/font';
import {useAppSelector} from '../../config/redux/hooks';
import {useCheckUserDetail} from '../hooks/user';
import {useColorThemeType} from '../../../src/appStyles/hooks/theme';
import {useFetchDownTimeInfo} from '@buncha/maintenance/hooks/downtime';

type Props = NativeStackScreenProps<any, any>;

const Splash: React.FC<Props> = () => {
  const theme = useColorThemeType();
  const onboardingState = useAppSelector(gstate => gstate.onboarding);
  const token = onboardingState.userAuthData?.loginToken;
  const user = onboardingState.currentUser;
  const {fetchDownTimeInfo, loading} = useFetchDownTimeInfo();
  const {checkingUserDetails} = useCheckUserDetail(0);
  const initializeAnalytics = () => {
    const res = Analytics.setupForServerConfiguration();
    if (res) Analytics.event('Open App', AnalyticsPlatform.all);
  };

  useEffect(() => {
    fetchDownTimeInfo();
    initializeAnalytics();
  }, []);

  useEffect(() => {
    if (!token?.length && !user && !checkingUserDetails && !loading)
      setTimeout(() => {
        navigation.replace(MainScreens.HomeBeforeLogin, {
          screen: OnBoardingScreens.Onboarding,
        });
      }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkingUserDetails, loading]);

  return (
    <Box
      style={[
        styles.parentBox,
        {
          backgroundColor: theme?.background?.blue?.[300],
        },
      ]}>
      <BunchaWithName
        height={scaleFonts(65, 'height')}
        width={scaleFonts(265)}
        viewBox="0 0 135 25"
      />
      <SpinnerComponent
        style={styles.loader}
        size={'lg'}
        color={theme?.background.white[100]}
        loadingText="Loading.. please be patient"
        showBottomLoadingText
        textStyle={{
          color: theme?.text.white[100],
        }}
      />
    </Box>
  );
};

export default React.memo(Splash);

const styles = StyleSheet.create({
  parentBox: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    height: 50,
    width: 50,
    alignSelf: 'center',
  },
  image: {
    alignSelf: 'center',
    height: 100,
    width: 100,
    marginBottom: 20,
  },
});
