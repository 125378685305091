import 'react-native-get-random-values';

import {BrowseNames, CONSTANTS} from './types';
import {
  DimensionValue,
  Linking,
  PermissionsAndroid,
  Platform,
} from 'react-native';

import {HiringApplicationState} from '@buncha/common/types/common';
import {LatLong} from '../AboutShift/types';
import {MapChoice} from '../onBoarding/types';
import moment from 'moment';
import {storage} from '../config/storage';

export function getFormattedPhoneNumber(phoneNumber: number) {
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  let cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

export function inputFormattedNumber(value: any) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  else if (phoneNumberLength < 7)
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, 10)}`;
}

export function extractNumber(value: string) {
  let arr = value.match(/[\d.]+/g);
  let temp;
  let phoneNumber;
  if (arr) temp = arr.filter(n => n !== '.');
  if (temp) phoneNumber = temp.join('');
  return phoneNumber;
}

export const isValidPhoneNumber = (value: string) => {
  const hasPrefix = value.startsWith('1');
  if (hasPrefix) return value.length === 15;
  return value.length === 14;
};

export const isDisabledHandler = (
  firstName: string,
  lastName: string,
  email: string,
) => {
  if (firstName.length >= 3 && lastName.length >= 1 && email.length >= 1)
    return false;
  return true;
};

export const isWeb = () => Platform.OS === 'web';

export const getOS = () => {
  return Platform.OS;
};

export function useDetectBrowser() {
  const detectBrowser = () => {
    const browserWindow = (global as any).window;
    const regexTest = function (regexp: RegExp) {
      return regexp.test(browserWindow.navigator.userAgent);
    };
    switch (true) {
      case regexTest(/edg/i):
        return BrowseNames.edge;
      case regexTest(/trident/i):
        return BrowseNames.ie;
      case regexTest(/firefox|fxios/i):
        return BrowseNames.firefox;
      case regexTest(/opr\//i):
        return BrowseNames.opera;
      case regexTest(/chrome|chromium|crios/i):
        return BrowseNames.chrome;
      case regexTest(/safari/i):
        return BrowseNames.safari;
      default:
        return BrowseNames.other;
    }
  };
  return {detectBrowser};
}

export function generateUUID() {
  let d = new Date().getTime();
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      // eslint-disable-next-line no-bitwise
      let r = (d + getRandomNumbers() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    },
  );
  return uuid;
}

export async function getUUid(): Promise<string | undefined> {
  let uuid = await storage.getItem('uuid');
  if (uuid) return uuid;
  uuid = generateUUID();
  storage.setItem('uuid', uuid);
  return uuid;
}

export const getWidth = (width: DimensionValue | undefined) => {
  if (Number(width) > CONSTANTS.maxWidth) return CONSTANTS.maxWidth;
  return width;
};

export const formatCurrency = (
  pennies: number,
  withDecimal?: boolean,
): string => {
  const money = pennies?.toFixed(2);
  let formattedValue = String(Number(money) / 100);
  if (withDecimal) formattedValue = String((Number(money) / 100).toFixed(2));
  return '$' + formattedValue;
};

export function getMapUrl(userMapChoice: MapChoice, latLong: LatLong): string {
  if (userMapChoice === MapChoice.appleMaps)
    return `https://maps.apple.com?saddr=My+Location&daddr=${latLong.lat},${latLong.lng}`;
  return `https://maps.google.com?saddr=My+Location&daddr=${latLong.lat},${latLong.lng}`;
}

export const sendSMS = async (phoneNumber: number, message: string) => {
  try {
    const separator = Platform.OS === 'ios' ? '&' : '?';
    const url = `sms:${phoneNumber}${separator}body=${message}`;
    await Linking.openURL(url);
  } catch (err: any) {
    // errorService.report(err);
    // eslint-disable-next-line no-console
    console.log('Error while sending SMS message: ', err);
  }
};

export const findUpc = (codes: {[key: string]: number}) => {
  const keys = Object.keys(codes);
  const totalScans = keys.reduce((a, b) => a + codes[b], 0);
  if (keys.length > 1 ? totalScans < 20 : totalScans < 8) return false;
  const mostOccurringCode = keys.reduce((a, b) =>
    codes[a] > codes[b] ? a : b,
  );
  const mostOccurringValue = codes[mostOccurringCode];
  delete codes[mostOccurringCode];
  const secondMostOccurringCode = keys.reduce((a, b) =>
    codes[a] > codes[b] ? a : b,
  );
  const secondMostOccurringvalue = codes[secondMostOccurringCode] || 1;
  if (mostOccurringValue / secondMostOccurringvalue > 4)
    return mostOccurringCode;
  return false;
};

export const getDefaultImageUrl = () => {
  return 'https://catalogue.gobuncha.com/errorImage.webp';
};

export const requestPermissions = async () => {
  if (Platform.OS === 'android') {
    const granted = await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.CAMERA,
      {
        title: 'Camera Permission',
        message:
          'Allow camera access to quickly upload images of receipts you need for better accuracy.',
        buttonPositive: 'Ok',
      },
    );
    return granted === PermissionsAndroid.RESULTS.GRANTED;
  }
  return true;
};

const expression = new RegExp(/^\d*(\.\d{0,2})?$/);

export const checkPriceDecimals = (value: string) => {
  try {
    return expression.test(value);
  } catch (error: any) {
    return false;
  }
};

export function getTimeFromDate(date: Date) {
  return moment(date).format('hh:mm a');
}

export const HiringStatusesForApplicant = [
  HiringApplicationState.submitted,
  HiringApplicationState.interview,
  HiringApplicationState.rejected,
  HiringApplicationState.offered,
];

export const convertBase64 = async (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error: any) => {
      reject(error);
    };
  });
};

export function IsUrlValid(url: string) {
  const regex =
    '((http|https)://)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)';
  const match = regex.match(url);
  if (match) return true;
  return false;
}

export function getRandomNumbers(length = 1) {
  const array = new Uint32Array(length);
  if (window.crypto) window.crypto.getRandomValues(array);
  const FileId = array[0];
  return FileId;
}
