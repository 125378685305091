import React, {useCallback, useMemo, useRef, useState} from 'react';

import AllOrders from './allOrders';
import {DriverOrdersTab} from '../../../../../src/driver/types/driverOrders';
import RunOrders from './runOrders';
import {StyleSheet} from 'react-native';
import Tab from '../../../../../src/components/composites/Tabs/Tab';
import {TabData} from '../../../../../src/components/composites/Tabs/type';
import TabHeaderNode from './tabHeaderNode';
import {useRoute} from '@react-navigation/native';

interface RouteParamsType {
  runId?: number;
}

const DriverTabs: React.FC = () => {
  const route = useRoute();
  const routeParams: RouteParamsType | undefined = route?.params;
  const runId = Number(routeParams?.runId) || 0;
  const keyRef = useRef('');
  const tabHeader = [DriverOrdersTab.RunOrders, DriverOrdersTab.AllOrders];
  const sections: {[key: string]: React.ReactElement} = {};
  const tabNode = useCallback((info: TabData<any>, activeKey: string) => {
    return <TabHeaderNode info={info} activeKey={activeKey} />;
  }, []);

  const [loading, setLoading] = useState(false);

  const [ordersCount, setOrdersCount] = useState(0);

  tabHeader.forEach(tab => {
    switch (tab) {
      case DriverOrdersTab.RunOrders:
        sections[tab] = (
          <RunOrders
            runId={runId}
            setOrdersCount={setOrdersCount}
            setLoading={setLoading}
          />
        );
        break;
      case DriverOrdersTab.AllOrders:
        sections[tab] = <AllOrders runId={runId} />;
        break;
      default:
        sections[DriverOrdersTab.RunOrders] = (
          <RunOrders runId={runId} setOrdersCount={setOrdersCount} />
        );
    }
  });

  const tabTitle = useMemo(() => {
    if (ordersCount) return `Pending Deliveries(${ordersCount})`;
    else if (loading) return 'Loading ...';
    return 'Pending Deliveries';
  }, [loading, ordersCount]);

  return (
    <Tab
      key={keyRef.current}
      tabData={[
        {
          key: DriverOrdersTab.RunOrders,
          data: tabTitle,
        },
        {
          key: DriverOrdersTab.AllOrders,
          data: 'Delivered',
        },
      ]}
      tabNode={tabNode}
      sections={sections}
      scrollEnabled={false}
      style={styles.tab}
    />
  );
};

export default React.memo(DriverTabs);

const styles = StyleSheet.create({
  tab: {
    marginBottom: 5,
  },
});
