import * as React from 'react';

import {Icon} from '.';
import {IconProps} from './type';
import {Path} from 'react-native-svg';

const TransferArrowIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <Path d="M3.964 15.878c-.666-.665-.24-1.784.662-1.872L4.741 14H20.5a1 1 0 0 1 .117 1.993L20.5 16H6.914l2.293 2.293a1 1 0 0 1-1.32 1.497l-.094-.083-3.83-3.83.001.001ZM3.5 9a1 1 0 0 1 .883-.993L4.5 8h13.586l-2.293-2.293a1 1 0 0 1 1.32-1.497l.094.083 3.83 3.83c.665.664.239 1.783-.663 1.871l-.115.006H4.5a1 1 0 0 1-1-1Z" />
    </Icon>
  );
};

export default TransferArrowIcon;
