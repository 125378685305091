import {Box, HBox} from '../../../components/core/view';
import {CustomText, H5Text} from '../../../components/core/text';
import React, {useMemo} from 'react';
import {RunForBatchDetail, UPCOrder} from '../../../batchDetails/types';
import {useColorThemeType, useFontTheme} from '../../../appStyles/hooks/theme';

import If from '@buncha/components/conditional/If';
import StoreLogo from '../../../common/components/StoreLogo';
import {styles} from './styles';
import {useAppSelector} from '@buncha/config/redux/hooks';

interface BatchDetailHeaderProps {
  runDetail?: RunForBatchDetail;
  showRunPill: boolean;
  upcOrderData?: UPCOrder;
}

export default function BatchDetailHeader(props: BatchDetailHeaderProps) {
  const {runDetail, showRunPill, upcOrderData} = props;
  const theme = useColorThemeType();
  const muliFont = useFontTheme('muli');
  const shopperTaskInfo = useAppSelector(
    gbState => gbState.batchDetail.batchTaskInfo,
  )?.shopperTask;

  const storeName = useMemo(() => {
    if (showRunPill) return runDetail?.storeName;
    return upcOrderData?.storeName;
  }, [runDetail?.storeName, showRunPill, upcOrderData?.storeName]);
  const storeLogo = useMemo(() => {
    if (showRunPill) return runDetail?.store?.storeLogoUrl;
    return upcOrderData?.storeLogo;
  }, [runDetail?.store?.storeLogoUrl, showRunPill, upcOrderData?.storeLogo]);

  return (
    <HBox style={[styles.mainContainer]}>
      <HBox>
        <StoreLogo storeLogoUrl={storeLogo ?? ''} />
        <Box style={[styles.runInfoContainer]}>
          <H5Text
            numberOfLines={1}
            style={[
              styles.runName,
              {
                fontFamily: muliFont[900].normal,
                color: theme?.text.blue[300],
              },
            ]}>
            {storeName}
          </H5Text>
          <If condition={runDetail?.locationAddress.length}>
            <CustomText
              numberOfLines={1}
              style={[
                styles.runName,
                {
                  fontFamily: muliFont[600].normal,
                  color: theme?.text.gray[100],
                },
              ]}>
              {runDetail?.locationAddress}
            </CustomText>
          </If>
          <If condition={!showRunPill}>
            <CustomText
              style={[
                styles.runName,
                {
                  fontFamily: muliFont[600].normal,
                  color: theme?.text.gray[100],
                },
              ]}>
              {upcOrderData?.esIndex}
            </CustomText>
          </If>
          <If condition={showRunPill}>
            <CustomText
              style={[
                styles.runName,
                {
                  fontFamily: muliFont[600].normal,
                  color: theme?.text.gray[100],
                },
              ]}>
              {shopperTaskInfo?.orderCount} Orders Assigned |{' '}
              {shopperTaskInfo?.itemCount} Items Total
            </CustomText>
          </If>
        </Box>
      </HBox>
      <If condition={showRunPill}>
        <Box
          style={[
            styles.runIdContainer,
            {
              backgroundColor: theme?.background.gray[200],
            },
          ]}>
          <CustomText
            style={[
              styles.runIdText,
              {
                fontFamily: muliFont[600].normal,
                color: theme?.text.gray[100],
              },
            ]}>
            Run ID: {runDetail?.id}
          </CustomText>
        </Box>
      </If>
    </HBox>
  );
}
